<template>
  <SkeletalLoading class="loading" height="252" width="335" v-if="isLoading" />
  <button
    :class="[
      'claim-chest',
      chestData.balance === 0 || isClaiming ? 'disabled' : '',
      isClaiming ? 'claiming' : '',
      `id-${chestData.id}`,
    ]"
    :disabled="chestData.balance === 0 || isClaiming || isTurnHappening"
    v-else-if="chestData"
    @click="claimChest"
  >
    <span class="image" :style="`background-image: url(${chestData.image})`">
      <p>
        x <span :class="['balance']">{{ chestData.balance }}</span>
      </p>
    </span>

    <h5 class="name">{{ chestData.name }}</h5>
    <GenericLoading class="generic-loading" v-if="isClaiming" />
  </button>
</template>

<script>
import { ethers } from "ethers";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useUser } from "../../composables/user";
import apiConnector from "../../game/apiConnector";
import { getDCGLootChestsContract } from "../../utils/getContract";
import { parseError } from "../../utils/helpers";
import GenericLoading from "../LoadingComponents/GenericLoading.vue";
import SkeletalLoading from "../LoadingComponents/SkeletalLoading.vue";

export default {
  name: "LootChests",
  components: {
    SkeletalLoading,
    GenericLoading,
  },
  // emits: ["refresh", "chestOpened"],
  props: {
    chest: {},
  },
  setup(props) {
    const chestData = ref(null);
    const isLoading = ref(true);
    const isClaiming = ref(false);

    const store = useStore();
    const { address, signer } = useUser();
    const DCGLootChestsContract = getDCGLootChestsContract(signer.value);

    const metadata = {
      1: {
        name: "Warrior's Starter Kit",
        image: "https://cdn.dragoncrypto.io/equip/Loot_106.png",
        external_url: "https://aurumdraconis.dragoncrypto.io/lootchests/1",
      },
      2: {
        name: "Crafter's Starter Kit",
        image: "https://cdn.dragoncrypto.io/equip/Blacksmith_55_iron_chest.png",
        external_url: "https://aurumdraconis.dragoncrypto.io/lootchests/2",
      },
      3: {
        name: "Mage's Starter Kit",
        image: "https://cdn.dragoncrypto.io/equip/Loot_107.png",
        external_url: "https://aurumdraconis.dragoncrypto.io/lootchests/3",
      },
      4: {
        name: "Ranger's Starter Kit",
        image: "https://cdn.dragoncrypto.io/equip/Loot_102.png",
        external_url: "https://aurumdraconis.dragoncrypto.io/lootchests/4",
      },
      5: {
        name: "Loot Chest",
        image: "../../assets/ui/loot-chest.png",
        external_url: "",
      },
    };

    onMounted(async () => {
      // await fetchMetadata();
      getLocalMetaData();
    });

    const claimChest = async () => {
      const chest = props.chest;
      isClaiming.value = true;
      store.commit("setTurnHappening", true);

      try {
        const sessionId = localStorage.getItem("sessionId");
        const heroId = character.value.number;

        const updateStatus = await apiConnector.claimLootChestStatus(
          address.value,
          sessionId,
          heroId
        );

        if (!updateStatus.success) {
          isClaiming.value = false;
          store.commit("setTurnHappening", false);
          store.commit(
            "setNotification",
            "Status update during chest claim failed."
          );
          return;
        }

        const tx = await DCGLootChestsContract.burn(
          address.value,
          ethers.utils.parseUnits(`${chest.id}`, 0),
          1
        );
        const receipt = await tx.wait();
        if (receipt.status === 1) {
          // TODO: CAll api to reveal chest

          const result = await apiConnector.claimLootChest(
            address.value,
            sessionId,
            heroId,
            chest.id,
            1,
            receipt.blockNumber
          );
          if (result.success) {
            store.commit("lootChests/setLoot", result.lootResult);
            store.commit("lootChests/setOpenedChest", chestData.value);
            store.commit("lootChests/setIsChestOpened", true);
          }
        }
        isClaiming.value = false;
        store.commit("setTurnHappening", false);
      } catch (error) {
        console.log(error);
        isClaiming.value = false;
        store.commit("setTurnHappening", false);
        store.commit("setNotification", parseError(error));
      }
    };

    const getLocalMetaData = () => {
      chestData.value = metadata[props.chest.id];
      chestData.value["id"] = props.chest.id;
      chestData.value["balance"] = props.chest.balance;
      isLoading.value = false;
    };
    // const fetchMetadata = async () => {
    //   try {
    //     isLoading.value = true;
    //     const response = await fetch(
    //       `https://heroes.dragoncrypto.io/metadata/lootchests/${props.chest.id}`,
    //       {
    //         method: "GET",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     );

    //     const result = await response.json();
    //     if (result) {
    //       chestData.value = result;
    //       chestData.value["id"] = props.chest.id;
    //       chestData.value["balance"] = props.chest.balance;
    //     }
    //     isLoading.value = false;
    //   } catch (error) {
    //     isLoading.value = false;
    //     console.log(error);
    //   }
    // };

    const isTurnHappening = computed(() => {
      return store.state.isTurnHappening;
    });

    const character = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });

    return {
      // Computed
      isTurnHappening,
      // Data
      chestData,
      isLoading,
      isClaiming,
      // Methods
      claimChest,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.loading {
  opacity: 0.15;
  margin-bottom: 2px;
}
.claim-chest {
  background: none;
  border: none;
  position: relative;
  cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
    auto;
  border-radius: 2px;
  &:hover,
  &.claiming {
    background: #2c2523;
    box-shadow: 0 0 5px rgb(0 0 0 / 52%);
  }
  &.claiming {
    opacity: 1 !important;
  }
  .generic-loading {
    position: absolute;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    p .balance {
      background-color: #919191;
      color: #000;
    }
  }
  &.id-5 .image {
    background-image: url("../../assets/ui/loot-chest.png") !important;
  }
  .image {
    width: 200px;
    height: 200px;
    background-size: cover;
    display: block;
    margin: 0 auto 10px;
    position: relative;
    background-position: center center;
  }
  p {
    color: #fff;
    display: grid;
    grid-template-columns: 18px 1fr;
    align-items: center;
    width: 66px;
    justify-items: center;
    font-size: 1.3rem;
    margin: 0 auto 20px;
    position: absolute;
    bottom: 20px;
    right: 0;
    .balance {
      background-color: red;
      color: #fff;
      width: 30px;
      height: 30px;
      display: grid;
      border-radius: 100%;
      align-items: center;
      font-weight: bold;
      font-size: 1rem;
      position: absolute;
    }
  }
  h5 {
    font-family: "IM Fell English", serif;
    color: #e5c356;
    font-size: 1.2rem;
    margin: 0 auto 20px;
    width: 80%;
  }
}
</style>
