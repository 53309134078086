<template>
  <div class="intro-video">
    <video id="hero-video" preload="auto" ref="video" @ended="skip">
      <!-- <source src="/dcg-video.mp4" type="video/mp4" /> -->
      <source :src="videoUrl" type="video/mp4" />
    </video>
    <button class="skip" @click="skip">Skip</button>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
// import Constants from "../../consts/constants";

export default {
  name: "IntroVideo",
  setup() {
    // Data and Refs
    const video = ref(null);
    const store = useStore();

    onMounted(() => {
      // Muting all game music
      store.commit("setVolume", 0);
      video.value.volume = 1;
      //   video.value.currentTime = 140; // TODO Remove

      video.value.play();
    });

    // Computed Methods
    const videoUrl = computed(() => {
      // return Constants.apiUrl + "/videos/lore-trailer.mp4";
      return "https://cdn.dragoncrypto.io/assets/LOAD-Lore-v2.m4v";
    });
    // Methods
    const skip = () => {
      store.commit("intro/setIsVideoOpen", false);
      store.commit("intro/setIsGuideOpen", true);
      video.value.pause();
      video.value.currentTime = 0;
      store.commit("setVolume", 0.3);
    };

    return {
      // data
      video,
      //methods
      skip,
      //computed
      videoUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.intro-video {
  z-index: 20;
  display: block;
  width: 100%;
  height: 100%;
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  video {
    width: 100%;
    height: 100%;
  }
  .skip {
    position: absolute;
    bottom: 50px;
    right: 0;
    z-index: 21;
    background: #0000007f;
    border: none;
    font-size: 1.2rem;
    color: #fff;
    cursor: pointer;
    padding: 10px 20px;
    text-transform: uppercase;
    &:hover {
      color: #ffc107;
    }
  }
}
</style>
