// initial state
const state = () => ({
  loot: null,
  isChestOpened: false,
  isLootPopupOpen: false,
  openedChest: null,
});

// // getters
// const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  setLoot(state, data) {
    state.loot = data;
  },
  setIsChestOpened(state, status) {
    state.isChestOpened = status;
  },
  setIsLootPopupOpen(state, status) {
    state.isLootPopupOpen = status;
  },
  setOpenedChest(state, data) {
    state.openedChest = data;
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  actions,
  mutations,
};
