<template>
  <div :class="'craft-sell-wrapper ' + currentClass">
    <div class="craft-sell-menu">
      <div class="close-button button-small" @click="hide"><span>x</span></div>
      <div
        v-if="currentItem && currentItem.rarity"
        :class="'title ' + currentItem.rarity.toLowerCase()"
      >
        <div class="img-wrapper">
          <img :src="currentImage" :alt="currentItem.name" />
        </div>
        <h2 :class="currentItem.rarity.toLowerCase()">
          {{ currentItem.name }}
          <span class="broken" v-if="itemStats && itemStats.isBroken"
            >[Broken]</span
          >
        </h2>
      </div>
      <div class="successMessage" v-if="successContract">
        <h3>Crafting Bounty Created!</h3>
        <DialogButton buttonSize="small" buttonText="Continue" @click="hide" />
      </div>
      <template v-else>
        <div class="sell-price-listing dcar">
          <label for="base-price">Base Craft Cost: {{ basePrice }}</label>
        </div>
        <div class="control-item">
          <div :class="['sell-price', 'inputs', 'dcar']">
            <label for="sell-price"
              >Craft Bounty<input
                type="number"
                name="sell-price"
                :min="basePrice + 1"
                step="1"
                v-model="currentPrice"
                @keyup="updateCurrentPriceDollar"
            /></label>
          </div>
        </div>
        <div class="control-item">
          <div class="sub-header">Amount to craft:</div>
          <div class="craft-amount-selector">
            <div>
              <RoundButton
                size="small"
                class="quantity-button"
                :disabled="!(amountToCraft > 1)"
                @click="decrementCraft"
              >
                -
              </RoundButton>
            </div>
            <span class="amount-to-craft">
              {{ amountToCraft }}
            </span>

            <div>
              <RoundButton
                size="small"
                class="quantity-button"
                :disabled="
                  !(
                    amountToCraft <
                    currentItem.numberCrafts - currentItem.craftsUsed
                  )
                "
                @click="incrementCraft"
              >
                +
              </RoundButton>
            </div>
          </div>
        </div>
        <div
          class="control-item"
          v-if="
            !isConsumableBluePrint &&
            getCraftingResources.length > 0 &&
            hasMaterialUpgrades
          "
        >
          <div class="sub-header">Upgrade Material (optional)</div>
          <div class="crafting-slot-wrapper">
            <template
              v-for="(item, index) in getCraftingResources"
              :key="index"
            >
              <div>
                <div class="craft-item">
                  <EquipmentTile
                    :item="item"
                    :isCraftingGuild="true"
                    :size="'small'"
                    @click="chooseUpgradeMaterial(item)"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="control-item" v-if="!isConsumableBluePrint">
          <div class="sub-header">Enchanted Stones (optional)</div>
          <div class="crafting-slot-wrapper">
            <template
              v-for="(item, index) in getEnchantedResources"
              :key="index"
            >
              <div>
                <div class="craft-item">
                  <EquipmentTile
                    :item="item"
                    :isCraftingGuild="true"
                    :size="'small'"
                    @click="chooseEnchantedMaterial()"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="sell-price-listing dcar">
          <label for="base-price">Total Cost: {{ totalPrice }}</label>
        </div>
        <label class="dollar-value">${{ currentPriceDollar }} USD</label>
        <div class="buttons" v-if="!successContract">
          <DialogButton
            v-if="!approvedCraftNft"
            :buttonText="`Approve All Equipment`"
            @click="craftApprovalNft"
            :isLoading="approvingCraftNft"
            :disabled="isLoading"
          />
          <DialogButton
            v-else-if="parseFloat(dcarApprovalAmount) < parseFloat(totalPrice)"
            :buttonText="`Approve DCAR`"
            @click="craftApprovalDcar"
            :isLoading="approvingCraftDcar"
            :disabled="isLoading"
          />
          <DialogButton
            v-else
            :buttonText="'Create Craft Bounty'"
            @click="createCraftContract"
            :isLoading="isLoading"
          />
        </div>
      </template>
    </div>
  </div>
  <BasicPopup
    v-if="showUpgradePopup"
    :header="'Upgrade ' + upgradeMaterial.name"
  >
    <button :class="['close-button', 'button-small']" @click="hideUpgradePopup">
      <span>x</span>
    </button>
    <div class="upgrade-description">
      <p>Choose a material to replace {{ upgradeMaterial.name }} with.</p>

      <div class="crafting-slot-wrapper">
        <template
          v-for="(item, index) in upgradeMaterial.upgrades"
          :key="index"
        >
          <div>
            <div class="craft-item">
              <EquipmentTile
                :item="item"
                :isCraftingGuild="true"
                :size="'medium'"
                @click="upgradeMaterialSelect(item)"
                @mouseover="hoverHandler = true"
              />
              <div v-if="hoverHandler" class="hover-item-info">
                <div class="item-title">{{ item.name }}</div>
                <div v-if="item.bonuses" class="item-stats">
                  <p v-for="(bonus, key, index) in item.bonuses" :key="index">
                    +{{ bonus }} {{ key }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </BasicPopup>
  <BasicPopup
    class="select-enchanted-stones"
    v-if="showEnchantedPopup"
    :header="'Enchanted Stones'"
  >
    <button
      :class="['close-button', 'button-small']"
      @click="hideEnchantedPopup"
    >
      <span>x</span>
    </button>
    <div class="upgrade-description">
      <p>Choose up to 5 enchanted stones to upgrade your blueprint item.</p>

      <div class="crafting-slot-wrapper">
        <template v-for="(item, index) in getStones" :key="index">
          <div>
            <div class="craft-item">
              <EquipmentTile
                :item="item"
                :isCraftingGuild="true"
                :size="'medium'"
                :class="{
                  'selected-stone': extraResources.includes(item.staticId),
                }"
                @click="toggleStone(item)"
                @mouseover="hoverHandler = true"
              />
              <div v-if="hoverHandler" class="hover-item-info">
                <div class="item-title">{{ item.name }}</div>
                <div v-if="item.bonuses" class="item-stats">
                  <p v-for="(bonus, key, index) in item.bonuses" :key="index">
                    +{{ bonus }} {{ key }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <DialogButton
        buttonSize="small"
        :buttonText="extraResources.length > 0 ? `Confirm` : `Close`"
        @click="hideEnchantedPopup"
      />
      <DialogButton
        v-if="extraResources.length > 0"
        buttonSize="small"
        :buttonText="'Clear'"
        @click="clearSelection"
      />
    </div>
  </BasicPopup>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import DialogButton from "../../DialogButton.vue";
import RoundButton from "../../Buttons/RoundButton.vue";
import EquipmentTile from "../../EquipmentTile.vue";
import BasicPopup from "../../Popup/BasicPopup.vue";
import Constants from "../../../consts/constants";

import { useUser } from "../../../composables/user";
import { usePrice } from "../../../composables/price";
import { parseError } from "../../../utils/helpers";
import { ethers } from "ethers";
import {
  getDCGEquipmentContract,
  getLoadCraftingGuildContract,
  getDCARContract,
} from "../../../utils/getContract";

export default {
  name: "ListCraftItem",
  components: {
    DialogButton,
    RoundButton,
    EquipmentTile,
    BasicPopup,
  },
  props: {},
  setup() {
    /**
     * Data
     */
    const store = useStore();
    const isVisible = ref(true);
    const isInventoryOpen = ref(false);
    const currentPrice = ref(1);
    const basePrice = ref(1);
    const currentPriceDollar = ref(0);
    const dcarPerDollar = ref(0);
    const successContract = ref(false);
    const approvedCraftNft = ref(false);
    const approvingCraftNft = ref(false);
    const approvedCraftDcar = ref(false);
    const approvingCraftDcar = ref(false);
    const hasMaterialUpgrades = ref(false);
    const showUpgradePopup = ref(false);
    const showEnchantedPopup = ref(false);
    const dcarApprovalAmount = ref(0);
    const hoverHandler = ref(false);
    const amountToCraft = ref(1);
    const extraResources = ref([]);
    const upgradeResources = ref([]);
    const upgradeMaterial = ref(null);

    const { address, signer } = useUser();
    const { getDCARPricePerDollar } = usePrice();

    const EquipmentContract = getDCGEquipmentContract(signer.value);
    const CraftersGuildContract = getLoadCraftingGuildContract(signer.value);
    const DCARContract = getDCARContract(signer.value);

    /**
     * Methods
     */
    // Helper method to delay stuff
    const delay = async (time) => new Promise((res) => setTimeout(res, time));

    const incrementCraft = async () => {
      if (
        amountToCraft.value <
        currentItem.value.numberCrafts - currentItem.value.craftsUsed
      ) {
        amountToCraft.value++;
      }
      calculateBasePrice();
    };

    const decrementCraft = async () => {
      if (amountToCraft.value > 1) {
        amountToCraft.value--;
      }
      calculateBasePrice();
    };

    const hideUpgradePopup = async () => {
      showUpgradePopup.value = false;
    };

    const hideEnchantedPopup = async () => {
      showEnchantedPopup.value = false;
    };

    const createCraftSignature = async () => {
      const sessionId = localStorage.getItem("sessionId");
      const heroId = character.value.number;
      const tokenId = currentItem.value.id;
      const price = currentPrice.value;
      const extra = extraResources.value;

      const response = await fetch(
        Constants.apiUrl + "contract/sign-crafters-guild-contract",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId,
            heroId,
            tokenId,
            extra,
            upgradedResources: upgradeResources.value,
            price,
            account: address.value,
            amountToCraft: amountToCraft.value,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log(data);
      return {
        tokenContract: data.equipmentContract,
        dcarAmount: data.dcarAmount,
        price: data.price,
        quantityToCraft: data.quantityToCraft,
        resourceCount: data.resourceCount,
        resourceIds: data.resourceIds,
        quantities: data.quantities,
        signature: data.signature,
        nonce: data.nonce,
      };
    };

    const createCraftContract = async () => {
      store.commit("setTurnHappening", true);

      if (currentPrice.value < 0) {
        store.commit("setNotification", "Price cannot be less than 0");
        store.commit("setTurnHappening", false);
        return;
      }

      if (parseFloat(currentPrice.value) <= parseFloat(basePrice.value)) {
        store.commit(
          "setNotification",
          "Bounty reward cannot be less than base craft cost."
        );
        store.commit("setTurnHappening", false);
        return;
      }

      const {
        tokenContract,
        dcarAmount,
        price,
        quantityToCraft,
        resourceCount,
        resourceIds,
        quantities,
        signature,
        nonce,
      } = await createCraftSignature();

      const priceConverted = ethers.utils.formatEther(price);
      const dcarAmountConverted = ethers.utils.formatEther(dcarAmount);

      console.log(priceConverted, dcarAmountConverted);

      if (parseFloat(priceConverted) <= parseFloat(dcarAmountConverted)) {
        store.commit(
          "setNotification",
          "Bounty reward cannot be less than base craft cost."
        );
        store.commit("setTurnHappening", false);
        return;
      }

      try {
        // Executing Craft Transaction
        const tx = await CraftersGuildContract.saleCraftContract(
          tokenContract,
          currentItem.value.id,
          quantityToCraft,
          price,
          dcarAmount,
          resourceCount,
          resourceIds,
          quantities,
          signature,
          nonce
        );
        const receipt = await tx.wait();
        // Only process Craft if the transaction was successful!
        if (receipt.status === 1) {
          playOnList();
          successContract.value = true;
          const payload = {
            blockNumber: receipt.blockNumber,
            craftItem: currentItem.value.id,
          };
          await store.commit("createCraftContract", payload);
          if (currentMarketInventory.value.length > 0) {
            while (
              currentMarketInventory.value[0].tokenId !== currentItem.value.id
            ) {
              await store.dispatch("craft/updateCraftItems");
              await store.dispatch("craft/updateMyListedItems");
              await sleep(1000);
            }
          } else {
            while (currentMarketInventory.value.length == 0) {
              await store.dispatch("craft/updateCraftItems");
              await store.dispatch("craft/updateMyListedItems");
              await sleep(1000);
            }
          }
          store.commit("hideCraftListing");
        }
        store.commit("setTurnHappening", false);
      } catch (error) {
        console.log(error);
        store.commit("setTurnHappening", false);
      }
    };

    const checkCraftApprovalNft = async () => {
      let isCraftApproved = await EquipmentContract.isApprovedForAll(
        address.value,
        CraftersGuildContract.address
      );

      if (isCraftApproved) {
        approvedCraftNft.value = true;
      }
    };

    const toggleStone = (stone) => {
      if (extraResources.value.includes(stone.staticId)) {
        const index = extraResources.value.indexOf(stone.staticId);
        if (index > -1) {
          extraResources.value.splice(index, 1);
        }
      } else {
        if (extraResources.value.length >= 5) {
          store.commit(
            "setNotification",
            "You can only add up to 5 enchanted stones."
          );
          return;
        }
        extraResources.value.push(stone.staticId);
      }

      calculateBasePrice();
      updateCurrentPriceDollar();
    };

    const checkCraftApprovalDcar = async () => {
      let allowanceCraft = await DCARContract.allowance(
        address.value,
        CraftersGuildContract.address
      );

      allowanceCraft = ethers.utils.formatUnits(allowanceCraft);

      if (parseFloat(allowanceCraft) > 0) {
        dcarApprovalAmount.value = allowanceCraft;
        approvedCraftDcar.value = true;
      }
    };

    const craftApprovalNft = async () => {
      try {
        store.commit("setTurnHappening", true);
        approvingCraftNft.value = true;
        const txApproveEquipment = await EquipmentContract.setApprovalForAll(
          CraftersGuildContract.address,
          true
        );
        await txApproveEquipment.wait();
        store.commit("setTurnHappening", false);
        approvingCraftNft.value = false;
        await checkCraftApprovalNft();
      } catch (e) {
        store.commit("setTurnHappening", false);
        approvingCraftNft.value = false;
        store.commit("setNotification", parseError(e));
        console.log(e);
      }
    };

    const craftApprovalDcar = async () => {
      try {
        store.commit("setTurnHappening", true);
        approvingCraftDcar.value = true;
        const txApproveDcar = await DCARContract.approve(
          CraftersGuildContract.address,
          ethers.utils.parseEther("1000000000")
        );
        await txApproveDcar.wait();
        store.commit("setTurnHappening", false);
        approvingCraftDcar.value = false;
        await sleep(1000);
        await checkCraftApprovalDcar();
      } catch (e) {
        store.commit("setTurnHappening", false);
        approvingCraftDcar.value = false;
        store.commit("setNotification", parseError(e));
        console.log(e);
      }
    };

    const clearSelection = () => {
      extraResources.value = [];
      calculateBasePrice();
      updateCurrentPriceDollar();
    };

    const updateCurrentPriceDollar = async () => {
      await delay(500);

      const total = currentPrice.value + basePrice.value;

      currentPriceDollar.value = (total / dcarPerDollar.value).toFixed(3);
    };

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const playOnList = () => {
      const sound = new Audio(
        "https://cdn.dragoncrypto.io/sound/shop-bell.mp3"
      );
      sound.volume = store.state.soundVolume;
      sound.play();
    };
    const hovered = () => {
      store.commit("setTooltipHovered");
    };
    const unhovered = () => {
      store.commit("setTooltipUnhovered");
    };
    const hide = () => {
      store.commit("hideCraftListing");
    };

    /**
     * Computed Methods
     */
    const isLoading = computed(() => {
      return store.state.isTurnHappening;
    });

    const character = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });

    const getStones = computed(() => {
      const resourceList = store.state.resources;
      const stones = resourceList.filter((r) => r.baseTypeId == 20025);
      return stones;
    });

    const calculateBasePrice = () => {
      let baseCraftCost = Constants.craftBaseCost;
      const baseStoneCost = Constants.craftStoneBaseCost;
      const baseMaterialCost = Constants.craftMaterialBaseCost;

      let totalStoneCost = 0;
      let totalMaterialCost = 0;

      const rarities = Constants.rarities;
      const rarityKeys = Object.keys(rarities);
      const rarityIndex = rarityKeys.indexOf(
        currentItem.value.rarity.toLowerCase()
      );

      if (extraResources.value.length > 0) {
        extraResources.value.forEach((stone) => {
          const stoneData = store.state.resources.find(
            (s) => s.staticId == stone
          );
          const rarityIndexStone = rarityKeys.indexOf(
            stoneData.rarity.toLowerCase()
          );
          totalStoneCost += baseStoneCost * (rarityIndexStone + 1);
        });
      }

      if (upgradeResources.value.length > 0) {
        upgradeResources.value.forEach((material) => {
          const resourceList = store.state.resources;
          const materialData = resourceList.find((s) => s.staticId == material);
          const rarityIndexMaterial = rarityKeys.indexOf(
            materialData.rarity.toLowerCase()
          );
          totalMaterialCost += baseMaterialCost * (rarityIndexMaterial + 1);
        });
      }

      baseCraftCost =
        (baseCraftCost * (rarityIndex + 1) +
          totalStoneCost +
          totalMaterialCost) *
        amountToCraft.value;

      currentPrice.value = baseCraftCost + 1;
      basePrice.value = baseCraftCost;
      updateCurrentPriceDollar();
    };

    const totalPrice = computed(() => {
      return basePrice.value + currentPrice.value;
    });

    const isWandering = computed(() => {
      return store.state.gameState == Constants.gamemodes.wandering;
    });

    const chooseUpgradeMaterial = async (item) => {
      upgradeMaterial.value = item;
      showUpgradePopup.value = true;
    };

    const chooseEnchantedMaterial = async () => {
      showEnchantedPopup.value = true;
    };

    const inputBaseMaterial = async () => {
      if (itemStats.value.resources && itemStats.value.resources.length > 0) {
        itemStats.value.resources.forEach((resource) => {
          upgradeResources.value.push(resource);
        });
      }
      console.log(upgradeResources.value);
    };

    const upgradeMaterialSelect = async (item) => {
      if (upgradeResources.value.length > 0) {
        const resourceList = store.state.resources;
        upgradeResources.value.forEach((resource) => {
          const baseTypeId = resourceList.find(
            (r) => r.staticId == resource
          ).baseTypeId;
          if (baseTypeId == item.baseTypeId) {
            upgradeResources.value = upgradeResources.value.filter(
              (r) => r != resource
            );
            upgradeResources.value.push(item.staticId);
          } else {
            if (!upgradeResources.value.includes(item.staticId)) {
              upgradeResources.value.push(item.staticId);
            }
          }
        });
      } else {
        upgradeResources.value.push(item.staticId);
      }

      getCraftingResources;
      calculateBasePrice();
      updateCurrentPriceDollar();
      hideUpgradePopup();
    };

    const getCraftingResources = computed(() => {
      if (currentItem.value.craftedType == "consumable") {
        return [];
      }

      const itemResources = [];
      const resourceList = store.state.resources;

      currentItem.value.stats.resources.forEach((resource) => {
        let resourceData;
        if (upgradeResources.value.length > 0) {
          resourceData = resourceList.find((r) => r.staticId == resource);
          upgradeResources.value.forEach((upgrade) => {
            const upgradeData = resourceList.find((r) => r.staticId == upgrade);
            if (resourceData.baseTypeId == upgradeData.baseTypeId) {
              resourceData = upgradeData;
            }
          });
        } else {
          resourceData = resourceList.find((r) => r.staticId == resource);
        }

        if (resourceData.baseTypeId) {
          const upgrades = resourceList.filter(
            (r) =>
              r.baseTypeId == resourceData.baseTypeId &&
              r.staticId != resourceData.staticId
          );

          resourceData.upgrades = upgrades;
          if (upgrades && upgrades.length > 0) {
            hasMaterialUpgrades.value = true;
            itemResources.push(resourceData);
          }
        }
      });

      return itemResources;
    });

    const getEnchantedResources = computed(() => {
      if (currentItem.value.craftedType == "consumable") {
        return [];
      }

      const itemResources = [];
      const resourceList = store.state.resources;

      if (extraResources.value.length > 0) {
        extraResources.value.forEach((resource) => {
          const resourceData = resourceList.find((r) => r.staticId == resource);
          itemResources.push(resourceData);
        });
      } else {
        const resourceData = resourceList.find((r) => r.staticId == 20025);
        itemResources.push(resourceData);
      }

      return itemResources;
    });

    const isConsumableBluePrint = computed(() => {
      return currentItem.value.craftedType == "consumable";
    });

    const currentClass = computed(() => {
      return store.state.isCraftListing ? "show" : "hide";
    });
    const currentItem = computed(() => {
      return store.state.hoveredItem;
    });

    const currentImage = computed(() => {
      if (!currentItem.value || !currentItem.value.imageName) {
        return "";
      }
      return (
        "https://ik.imagekit.io/dcg/equip/" +
        currentItem.value.imageName +
        "?tr=w-90"
      );
    });

    const currentMarketInventory = computed(() => {
      let results = store.state.craft.marketData;
      return results;
    });

    const itemStats = computed(() => {
      if (!currentItem.value) {
        return null;
      }
      return currentItem.value.stats;
    });

    /**
     * Watchers
     */

    watch(currentPrice, () => {
      store.commit("setCurrentSellPrice", currentPrice.value);
    });

    onMounted(async () => {
      await checkCraftApprovalDcar();
      await checkCraftApprovalNft();
      dcarPerDollar.value = await getDCARPricePerDollar();
      await inputBaseMaterial();
      await calculateBasePrice();
      await updateCurrentPriceDollar();
    });

    return {
      /* Data */
      isVisible,
      isInventoryOpen,
      currentPrice,
      basePrice,
      currentPriceDollar,
      approvedCraftNft,
      approvingCraftNft,
      approvedCraftDcar,
      approvingCraftDcar,
      successContract,
      extraResources,
      amountToCraft,
      hasMaterialUpgrades,
      showUpgradePopup,
      upgradeMaterial,
      upgradeResources,
      hoverHandler,
      showEnchantedPopup,
      dcarApprovalAmount,

      /* Methods */
      hovered,
      unhovered,
      toggleStone,
      hide,
      createCraftContract,
      updateCurrentPriceDollar,
      craftApprovalDcar,
      craftApprovalNft,
      incrementCraft,
      decrementCraft,
      chooseUpgradeMaterial,
      chooseEnchantedMaterial,
      hideUpgradePopup,
      hideEnchantedPopup,
      upgradeMaterialSelect,
      clearSelection,

      /* Computed Methods */
      character,
      isWandering,
      getStones,
      isLoading,
      totalPrice,
      currentClass,
      currentItem,
      currentImage,
      itemStats,
      getCraftingResources,
      getEnchantedResources,
      isConsumableBluePrint,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/globals.scss";

.item-title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 20px;
  color: #d79867;
}

.selected-stone {
  box-shadow: 0px 0px 2px 5px #f1d7c2;
}
.close-button {
  top: -80px;
  right: -80px;
  position: absolute;
  border: none;
  font-size: 1rem;
  &.loading {
    filter: grayscale(100);
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.item-stats {
  padding: 0;
  margin: 0;
}
.item-stats p {
  padding-bottom: 5px;
  margin: 0;
}
.craft-item {
  border: 1px solid #3d3d40;
  background: #151413;
  border-radius: 2px;
  margin-bottom: 3px;
  display: flex;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  flex-wrap: nowrap;
  align-items: flex-start;
  position: relative;
  &:hover {
    .hover-item-info {
      display: block;
    }
  }
  .hover-item-info {
    display: none;
    background-color: rgb(72 21 17);
    z-index: 9;
    padding: 5px;
    position: absolute;
    min-width: 200px;
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);
    bottom: 68px;
    line-height: 1.8rem;
    height: auto !important;
    color: #fff;
    font-size: 0.8rem;
    box-shadow: 0 1px 4px #000;
    border: 1px #6a0c05 solid;
    border-radius: 5px;
    &:after {
      content: "";
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #481511 transparent;
      transform: translateX(-50%) rotate(180deg);
      position: absolute;
      left: 50%;
      bottom: -11px;
    }
  }
  &.result {
    display: none;
    margin-left: 16px;

    .color {
      background-blend-mode: luminosity;
      background-size: contain;
    }

    .tint,
    .color {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
    }

    &.visible {
      display: flex;

      &::before {
        position: absolute;
        content: "=";
        font-size: 130%;
        top: calc(50% - 12px);
        color: #d79867;
        left: -19px;
      }
    }

    flex-wrap: wrap;
    position: relative;

    img {
      max-width: 56px;
    }

    span {
      position: absolute;
      width: 100%;
      text-align: right;
      font-size: 0.8rem;
      text-shadow: 0 0 2px #000, 0 1px 3px #000;
      right: -24px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.sub-header {
  padding-bottom: 15px;
  font-size: 90%;
  font-family: "IM Fell English", serif;
  color: #efefef;
}
.select-enchanted-stones {
  min-height: 150px;
  .upgrade-description {
    margin-top: calc(-1 * $popup-border-offset);
  }
  .crafting-slot-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(46px, 1fr));
    margin-bottom: 10px;
  }
}
.crafting-slot-wrapper {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
}

.control-item {
  border-bottom: 1px solid #3d3d40;
  margin: 10px 15px;
  padding-bottom: 15px;
}

.craft-amount-selector {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  min-height: 36px;
  align-items: center;
}

.successMessage {
  font-size: 80%;
  width: 90%;
  margin: 16px auto;
  font-family: "IM Fell English", serif;
}

.stone-image {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid transparent;
}

.stone-image:hover,
.enchanted {
  grid-template-columns: repeat(6, minmax(0, 1fr));
  width: 100%;
  display: grid;
  row-gap: 4px;
  -moz-column-gap: 4px;
  column-gap: 4px;
}

.craft-sell-wrapper {
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;

  &.show {
    opacity: 1;
    pointer-events: all;

    transition: opacity 0.35s linear;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.35s linear;
  }
}

.craft-sell-menu {
  width: 298px;
  height: auto;
  position: relative;
  background-color: #130f0e;
  border: 2px solid #3d3d40;

  .close-button {
    position: absolute;
    top: -20px;
    right: -20px;
  }

  .broken {
    color: $dmg;
  }

  .title {
    padding-top: 8px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: center;
    border-bottom: 1px solid #3d3d40;

    &.legendary {
      // .img-wrapper{

      // }

      h2 {
        color: $legendary;
      }
    }

    &.fabled {
      h2 {
        color: $fabled;
      }
    }

    &.unique {
      h2 {
        color: $unique;
      }
    }

    &.uncommon {
      h2 {
        color: $uncommon;
      }
    }

    &.shoddy {
      h2 {
        color: $shoddy;
      }
    }

    &.rare {
      h2 {
        color: $rare;
      }
    }

    &.mythical {
      h2 {
        color: $mythical;
      }
    }

    &.epic {
      h2 {
        color: $epic;
      }
    }

    &.artefact {
      h2 {
        color: $artefact;
      }
    }

    img {
      max-width: 45px;
      margin-right: 12px;
    }

    h2 {
      color: #fff;
      text-align: left;
      font-family: "IM Fell English", serif;
      font-size: 90%;
      text-transform: none;
      width: 75%;
    }
  }

  .inputs,
  .sell-price-listing {
    width: 90%;
    margin: 16px auto;
    font-family: "IM Fell English", serif;
    font-size: 90%;

    &:after {
      background: url("../../../assets/ui/dcar.png") no-repeat left center;
      background-size: contain;
    }

    label {
      &:after {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 0;
        margin-top: -8px;
        padding: 0;
        top: 7px;
        position: relative;
        margin-left: 4px;
      }
    }

    input {
      width: 64px;
      font-size: 105%;
      margin-left: 16px;
    }

    &.dcar {
      label {
        &:after {
          background: url("../../../assets/ui/dcar.png") no-repeat left center;
          background-size: contain;
        }
      }
    }
  }

  .dollar-value {
    font-size: 0.7rem;
    color: #ffffff85;
    position: relative;
    top: 0px;
    width: 80%;
    margin: 0 auto;
    display: block;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 9px;
    margin-bottom: 15px;
    .dialog-button {
      margin-top: 0;
    }
  }
}
</style>
