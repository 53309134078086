import apiConnector from "../../game/apiConnector";

// initial state
const state = () => ({
  rankingsOpenStatus: false,
  currentRankings: [],
});

// actions
const actions = {
  async openRankingsWindow({ commit }) {
    commit("setRankingsOpenStatusState", true);
  },
};

// mutations
const mutations = {
  setRankingsOpenStatusState(state, status) {
    state.rankingsOpenStatus = status;
  },
  setFilterTypeState(state, type) {
    state.filterType = type;

    apiConnector.callRankings(type).then((response) => {
      state.currentRankings = response;
    });
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  actions,
  mutations,
};
