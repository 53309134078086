<template>
  <VOnboardingWrapper ref="wrapper" :steps="steps" :options="options">
    <template #default="{ previous, next, step, isFirst, isLast, index }">
      <!-- <template #default="{ previous, next, step, exit, isFirst, isLast, index }"> -->
      <div v-if="hasButtons" class="cover-steps"></div>
      <VOnboardingStep class="guide-step">
        <div id="arrow" data-popper-arrow></div>
        <div class="box-container">
          <div class="step-box">
            <button class="finish" @click="endTour">×</button>
            <div>
              <div v-if="step.content">
                <h3 class="title" v-if="step.content.title">
                  {{ step.content.title }}
                </h3>
                <div
                  v-if="step.content.description"
                  class="mt-2 max-w-xl text-sm text-gray-500"
                >
                  <p>{{ step.content.description }}</p>
                </div>
              </div>
              <div
                class="mt-5 space-x-4 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center relative"
              ></div>
            </div>
          </div>
          <div v-if="hasButtons" class="buttons">
            <template v-if="!isFirst">
              <BlueButton
                @click="processPrevious(index, previous)"
                size="extra-small"
              >
                Previous
              </BlueButton>
            </template>
            <BlueButton v-if="isLast" @click="endTour" size="extra-small">
              Finish
            </BlueButton>
            <BlueButton
              v-else
              @click="processNext(index, next)"
              size="extra-small"
            >
              Next
            </BlueButton>
          </div>
          <div class="step-count">
            <div
              class="progress"
              :style="`width: ${((index + 1) / steps.length) * 100}%`"
            ></div>
            <span>
              {{ `${index + 1}/${steps.length}` }}
            </span>
          </div>
        </div>
      </VOnboardingStep>
    </template>
  </VOnboardingWrapper>
</template>
<script>
import {
  useVOnboarding,
  VOnboardingStep,
  VOnboardingWrapper,
} from "v-onboarding";
import "v-onboarding/dist/style.css";
import { onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import BlueButton from "../Buttons/BlueButton.vue";

export default {
  name: "GuideWizard",
  emits: ["ended"],
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    // BasicPopup,
    BlueButton,
    VOnboardingWrapper,
    VOnboardingStep,
  },
  setup(props, { emit }) {
    /**
     * Highlight the element in the step being passed
     * @param number index of the step being passed
     */
    const store = useStore();
    const wrapper = ref(null);
    const { start, finish } = useVOnboarding(wrapper);
    const hasButtons = ref(true);

    onMounted(() => {
      console.log(props.steps);
      if (props.steps.length > 0) {
        start();
        highlightStep(0);
      }
    });

    onUnmounted(() => {
      const item = document.querySelector(".animate-highlighted");
      if (item !== null) {
        item.classList.remove("animate-highlighted");
      }
    });

    const endTour = () => {
      store.commit("intro/setIsGuideOpen", false);
      const prevItem = document.querySelector(".animate-highlighted");
      if (prevItem !== null) {
        prevItem.classList.remove("animate-highlighted");
      }
      finish();
      emit("ended");
    };

    const processNext = (index, next) => {
      highlightStep(index + 1);
      if (props.steps[index].closeInventory) {
        // HIDING INVENTORY FOR CERTAIN CASES
        store.dispatch("selectedInventory/resetItems");
        store.commit("setInventoryVisible", false);
        store.commit("marketplace/setShowInventoryState", false); // Hiding in mobile view as well
        store.dispatch("selectedInventory/resetItems");
      }
      next();
    };

    const processPrevious = (index, previous) => {
      highlightStep(index - 1);
      previous();
    };
    const highlightStep = (index) => {
      if (index >= 0 || index <= props.steps.length) {
        // Removing all previously highlighted elements
        // But ignoring if it's the first element
        if (index !== 0) {
          const prevItem = document.querySelector(".animate-highlighted");
          if (prevItem !== null) {
            prevItem.classList.remove("animate-highlighted");
          }
        }

        // Adding class to the next element to be highlighted
        // But ignoring if it's the last element
        if (index !== props.steps.length) {
          const element = document.querySelector(
            props.steps[index].attachTo.element
          );
          if (element) {
            element.classList.add("animate-highlighted");
          }
        }

        console.log(props.steps[index]);

        // checking if button present
        if (props.steps && props.steps[index].hasButtons !== undefined) {
          hasButtons.value = props.steps[index].hasButtons;
          console.log(hasButtons.value);
        }
      }
    };

    return {
      //data
      wrapper,
      hasButtons,
      // methods
      endTour,
      processNext,
      processPrevious,
    };
  },
};
</script>
<style lang="scss">
.animate-highlighted {
  animation: pulse 1s alternate infinite ease-in-out;
  z-index: 20;
  &.popup {
    animation: pulsePopup 1s alternate infinite ease-in-out;
  }
}
@keyframes pulsePopup {
  0% {
    transform: scale(0.95) translate(-50%, -50%);
  }
  100% {
    transform: scale(1.05) translate(-50%, -50%);
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1.05);
  }
}
</style>
<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.cover {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 19;
  background: rgba(0, 0, 0, 0.449);
  top: 0;
  backdrop-filter: blur(2px);
  left: 0;
}
.cover-steps {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 19;
  background-color: rgb(0 0 0 / 25%);
}

.guide-step {
  div[data-popper-placement="left"] {
    #arrow {
      right: 0 !important;
    }
  }
  div[data-popper-placement="right"] {
    #arrow {
      left: -16px !important;
    }
  }
  #arrow {
    visibility: hidden;
    position: absolute;
    width: 16px;
    height: 16px;
    background: inherit;
    &::before {
      position: absolute;
      width: 16px;
      height: 16px;
      background: inherit;
      visibility: visible;
      content: "";
      transform: rotate(45deg);
      background-color: #000;
    }
  }
  .box-container {
    border-radius: 5px;
    overflow: hidden;
  }
  div[data-popper-placement^="top"] > #arrow {
    bottom: -8px;
  }

  div[data-popper-placement^="bottom"] > #arrow {
    top: -8px;
  }

  div[data-popper-placement^="left"] > #arrow {
    right: -8px;
  }

  div[data-popper-placement^="right"] > #arrow {
    left: -8px;
  }
  .step-box {
    width: 20rem;
    background: #000;
    padding: 1rem;
    font-family: "IM Fell English", serif;
    h3 {
      margin-top: 0;
      text-transform: capitalize;
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 0.5rem;
    }
  }
  .buttons {
    background: #000000e3;
    padding: 1rem 0.5rem;
    border-top: 1px #262626 solid;
  }
  .step-count {
    position: relative;
    height: 2px;
    background: #000;
    transition: height 0.3s ease-in-out;
    overflow: hidden;
    &:hover {
      height: 15px;
      span {
        display: block;
      }
    }
    span {
      display: none;
      width: 100%;
      text-align: center;
      font-size: 0.8rem;
      z-index: 1;
      position: relative;
      color: rgb(91, 91, 91);
    }
    .progress {
      display: block;
      position: absolute;
      left: 0;
      height: 100%;
      background-color: #e5c355;
    }
  }
}
.finish {
  border-radius: 100%;
  font-size: 1.3rem;
  padding: 0px 6px;
  box-shadow: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  color: #ffffffad;
  &:hover {
    background: #636363;
    color: #fff;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
  }
}
</style>
