// initial state
const state = () => ({
  allCharacters: [],
  selectedCharacters: [],
  isSelectScreenOpen: false,
});

// getters
const getters = {
  isCharacterSelected: (state) => (number) => {
    const isPresentIndex = state.selectedCharacters.findIndex((character) => {
      return character.number === number;
    });
    return isPresentIndex !== -1 ? true : false;
  },
};

// actions
const actions = {
  setActiveCharacters({ state, commit }) {
    // Only set active and current characters if there are selected characters
    if (state.selectedCharacters.length > 0) {
      // Re-assigning ids so that current character is successfully allocated
      // TODO: Removing this as no idea what its for and seems to do nothing, for Neo to fix
      /*state.selectedCharacters.map(
        (character, index) => (character.id = index)
      );*/

      //Setting local storage so refresh keeps the heroes (As long as they don't clear the cache)
      localStorage.setItem(
        "selectedCharacters",
        JSON.stringify(state.selectedCharacters, ["id"])
      );
      // );

      /**
       * Setting the first character in the selected characters array as the current character
       * Adding {root: true} so that the dispatch method can call the mutation of the root state
       */

      commit("setCharacters", state.selectedCharacters, { root: true });
      commit("setCurrentCharacter", state.selectedCharacters[0].id, {
        root: true,
      });
      commit(
        "setCurrentCharacterByNumber",
        state.selectedCharacters[0].number,
        { root: true }
      );
    }
  },
};

// mutations
const mutations = {
  setSelectedCharacters(state, payload) {
    state.selectedCharacters = payload;
  },
  setAllCharacters(state, payload) {
    state.allCharacters = payload;
  },
  addToSelectedCharacters(state, payload) {
    if (state.selectedCharacters.length < 5) {
      state.selectedCharacters.push(payload);
    }
  },
  removeFromSelectedCharacters(state, numberToBeRemoved) {
    const indexToBeRemoved = state.selectedCharacters.findIndex((character) => {
      return character.number === numberToBeRemoved;
    });
    if (indexToBeRemoved !== -1)
      state.selectedCharacters.splice(indexToBeRemoved, 1);
  },
  setDefaultSelectedCharacters(state) {
    // By default setting upto 5 heroes as selected
    if (localStorage.getItem("selectedCharacters")) {
      let characterPlacement = JSON.parse(
        localStorage.getItem("selectedCharacters")
      );

      if (
        characterPlacement[0] === undefined ||
        characterPlacement[0] === null
      ) {
        state.selectedCharacters = state.allCharacters.slice(0, 5);
        return;
      }

      try {
        for (let i = 0; i < characterPlacement.length; i++) {
          state.selectedCharacters[i] =
            state.allCharacters[characterPlacement[i].id];
        }
      } catch (error) {
        state.selectedCharacters = state.allCharacters.slice(0, 5);
        console.log(error);
      }
    } else {
      state.selectedCharacters = state.allCharacters.slice(0, 5);
    }
  },
  closeSelectionScreen(state, payload) {
    state.isSelectScreenOpen = payload;
  },
  openSelectionScreen(state, payload) {
    state.isSelectScreenOpen = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
