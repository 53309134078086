<template>
  <div class="market-item" v-if="item.data">
    <div class="product-info">
      <ItemTile
        :source="'inv'"
        :size="'large'"
        :item="item.data"
        :isMarketplace="true"
      />
      <div class="details">
        <h3 :class="item.rarity.toLowerCase()">
          {{ item.name }}
        </h3>
        <span :class="['price', 'DCAR']"
          ><span class="reward">REWARD</span> {{ rewardAmount }}
          <span>DCAR</span></span
        >
        <template v-if="!isResourcesApproved">
          <DialogButton
            :buttonSize="'med'"
            :buttonText="'Approve Resource'"
            @click="approveResources"
            :isLoading="isApprovingResources"
            :disabled="isLoading"
          />
        </template>
        <template v-else>
          <template v-if="!isOwner">
            <DialogButton
              :buttonSize="'med'"
              :buttonText="'Repair Item'"
              @click="processRepair"
              :isLoading="isRepairingItem"
              :disabled="isLoading || !canRepair"
            />
          </template>
          <template v-else>
            <DialogButton
              :buttonSize="'med'"
              :buttonText="'Cancel Listing'"
              @click="cancelListing"
              :isLoading="isLoading"
            />
          </template>
        </template>
      </div>
    </div>
    <div class="product-meta">
      <span v-if="!isOwner" class="address">{{ sellerAddress }}</span>
      <span class="listed-time">{{ fromTime }}</span>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
//import Constants from "../../consts/constants";
import DialogButton from "../DialogButton.vue";
import ItemTile from "./ItemTile.vue";
import { useStore } from "vuex";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { parseError } from "../../utils/helpers";
import { useUser } from "../../composables/user";
import apiConnector from "../../game/apiConnector";
import {
  getRepairGuildContract,
  getDCGResourcesContract,
} from "../../utils/getContract";

dayjs.extend(relativeTime);

export default {
  name: "ListedItem",

  props: {
    item: {},
    isOwner: { type: Boolean, default: false },
  },
  components: {
    DialogButton,
    ItemTile,
  },
  setup(props) {
    const { address, signer } = useUser();
    const ResourcesContract = getDCGResourcesContract(signer.value);
    const RepairGuildContract = getRepairGuildContract(signer.value);
    const isRepairingItem = ref(false);
    const store = useStore();
    const commissionRate = ref(0);
    const isApprovingDcar = ref(false);
    const isApprovingResources = ref(false);

    onMounted(async () => {
      await isApprovedResources();
      await getCommissionRate();
    });

    /**
     * Methods
     */
    /*const playOnBuy = () => {
      const sound = new Audio(
        "https://cdn.dragoncrypto.io/sound/cash-register.mp3"
      );
      sound.volume = store.state.soundVolume;
      sound.play();
    };*/

    const getCommissionRate = async () => {
      const rate = await RepairGuildContract.commissionRate();
      commissionRate.value = rate;
    };

    const approveResources = async () => {
      store.commit("setTurnHappening", true);
      isApprovingResources.value = true;

      try {
        const txApproveResources = await ResourcesContract.setApprovalForAll(
          RepairGuildContract.address,
          true
        );

        const receipt = await txApproveResources.wait();
        if (receipt.status) {
          await sleep(500);
          await isApprovedResources();
        }
        store.commit("setTurnHappening", false);
        isApprovingResources.value = false;
      } catch (error) {
        store.commit("setTurnHappening", false);
        isApprovingResources.value = false;
        store.commit("setNotification", parseError(error));
        console.log(error);
      }
    };

    const isApprovedResources = async () => {
      const isApproved = await ResourcesContract.isApprovedForAll(
        address.value,
        RepairGuildContract.address
      );
      if (isApproved) {
        store.commit("repair/setResourcesApproved", true);
      } else {
        store.commit("repair/setResourcesApproved", false);
      }
    };

    const processRepair = async () => {
      store.commit("setTurnHappening", true);
      isRepairingItem.value = true;
      try {
        const txRepair = await RepairGuildContract.completeSaleNFTContract(
          repairItem.value.saleIndex
        );

        if (txRepair?.hash) {
          await apiConnector.handleTransactionHash(
            store.state.account,
            localStorage.getItem("sessionId"),
            character.value.number,
            "repair",
            txRepair.hash
          );
        }

        const receipt = await txRepair.wait();
        if (receipt.status) {
          const payload = {
            repairItem: repairItem.value.data,
            guildRepair: true,
            saleIndex: repairItem.value.saleIndex,
            txHash: txRepair.hash,
          };
          await sleep(500);
          store.commit("setRepair", payload);

          let found = false;
          do {
            if (currentMarketInventory.value.length > 0) {
              if (
                currentMarketInventory.value.find(
                  (item) => item.saleIndex == payload.saleIndex
                )
              ) {
                await store.dispatch("repair/updateRepairItems");
                await store.dispatch("repair/updateMyListedItems");
                await sleep(1500);
              } else {
                found = true;
              }
            } else {
              found = true;
              await store.dispatch("repair/updateRepairItems");
              await store.dispatch("repair/updateMyListedItems");
            }
          } while (!found);
          await store.dispatch("updateInventory");
        }
        store.commit("setTurnHappening", false);
        isRepairingItem.value = false;
      } catch (error) {
        store.commit("setTurnHappening", false);
        isRepairingItem.value = false;
        console.log(error);
        store.commit("setNotification", parseError(error));
      }
    };

    const cancelListing = async () => {
      store.commit("setTurnHappening", true);
      try {
        const txCancelListing = await RepairGuildContract.removeSaleContract(
          repairItem.value.saleIndex
        );

        const currentSales = repairItem.value.saleIndex;

        const receipt = await txCancelListing.wait();
        if (receipt.status) {
          let found = false;
          do {
            if (currentMyListedItems.value.length > 0) {
              if (
                currentMyListedItems.value.find(
                  (item) => item.saleIndex == currentSales
                )
              ) {
                await store.dispatch("repair/updateRepairItems");
                await store.dispatch("repair/updateMyListedItems");
                await sleep(1000);
              } else {
                found = true;
              }
            } else {
              found = true;
              await store.dispatch("repair/updateRepairItems");
              await store.dispatch("repair/updateMyListedItems");
            }
          } while (!found);
          await store.dispatch("updateInventory");
        }
        store.commit("setTurnHappening", false);
      } catch (error) {
        store.commit("setTurnHappening", false);
        console.log(error);
        store.commit("setNotification", parseError(error));
      }
    };

    const avvyNameForAddress = (address) => {
      store.commit("avvynames/getOrSetAvvyName", address);
      return store.state.avvynames.addressToNames[address];
    };

    const sleep = (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };

    /**
     * Computed Methods
     */
    const isDcarApproved = computed(() => {
      return store.state.repair.dcarApproved;
    });

    const isResourcesApproved = computed(() => {
      return store.state.repair.resourcesApproved;
    });

    const currentItem = computed(() => {
      return props.item.data;
    });

    const itemStats = computed(() => {
      if (!currentItem.value) {
        return null;
      }
      return currentItem.value.stats;
    });

    const character = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });

    const hasEnoughCrafting = computed(() => {
      const characterCraftingBonus = store.state.equipmentStats().crafting;
      const characterCrafting = character.value.stats.crafting;

      return (
        itemStats.value &&
        itemStats.value.craftingRequired <=
          characterCraftingBonus + characterCrafting
      );
    });

    const canRepair = computed(() => {
      return (
        store.state.hasAllRequiredResources(currentItem.value) &&
        hasEnoughCrafting.value &&
        hasTurns.value &&
        !isDead.value
      );
    });

    const hasTurns = computed(() => {
      return (
        store.state.characters[store.state.currentCharacter].stats.turns > 0
      );
    });

    const isDead = computed(() => {
      return store.state.characters[store.state.currentCharacter].isDead;
    });

    const computedItemData = computed(() => {
      return repairItem.value;
    });

    const sellerAddress = computed(() => {
      return avvyNameForAddress(props.item.seller);
    });

    const currentMyListedItems = computed(() => {
      let results = store.state.repair.myListedItemsData;
      return results;
    });

    const currentMarketInventory = computed(() => {
      let results = store.state.repair.marketData;
      return results;
    });

    const fromTime = computed(() => {
      const d = new Date(props.item.createdAt);
      return dayjs(d).fromNow();
    });

    const repairItem = computed(() => {
      return props.item;
    });

    const rewardAmount = computed(() => {
      return parseFloat(
        props.item.rewardPrice -
          props.item.rewardPrice * (commissionRate.value / 10000)
      );
    });

    const isLoading = computed(() => {
      return store.state.isTurnHappening;
    });

    return {
      /**
       * Data
       */
      computedItemData,
      isRepairingItem,
      isApprovingDcar,
      isApprovingResources,
      /**
       * Methods
       */
      processRepair,
      avvyNameForAddress,
      isApprovedResources,
      approveResources,
      cancelListing,
      /**
       * Computed
       */
      isResourcesApproved,
      isDcarApproved,
      canRepair,
      isLoading,
      sellerAddress,
      fromTime,
      rewardAmount,
    };
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/globals.scss";
.reward {
  color: #f2c94c;
}

.market-item {
  padding: 10px;
  background: #171110;
  border-radius: 5px;

  .product-info {
    display: grid;
    grid-template-columns: 72px 1fr;
    grid-gap: 1rem;
    align-items: start;
  }
  .product-meta {
    display: grid;
    grid-template-columns: 1fr auto;
    font-size: 0.8rem;
    color: #ffffff59;
    padding: 5px 0 0;
    border-top: 1px solid #ffffff17;
    margin-top: 5px;
    justify-items: baseline;
    .address {
      text-align: left;
      &:before {
        background: url("https://cdn.dragoncrypto.io/uiassets/user.svg")
          no-repeat left center;
        background-size: contain;
        margin-right: 4px;
        content: "";
        opacity: 0.4;
        height: 15px;
        width: 15px;
        display: inline-block;
        margin-top: -3px;
        top: 3px;
        position: relative;
      }
    }
    .listed-time {
      text-align: right;
      &:before {
        background: url("https://cdn.dragoncrypto.io/uiassets/clock.svg")
          no-repeat left center;
        background-size: contain;
        margin-right: 4px;
        content: "";
        opacity: 0.4;
        height: 14px;
        width: 14px;
        display: inline-block;
        margin-top: -3px;
        top: 3px;
        position: relative;
      }
    }
  }

  &:hover {
    background: #251c1a;
  }
  .details {
    justify-items: left;
    span.price {
      font-size: 0.7rem;
      font-weight: bold;
      display: block;
      text-align: left;
      margin-left: 10px;
      span {
        opacity: 0.5;
      }
      &:after {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 0;
        margin-top: -8px;
        padding: 0;
        top: 7px;
        position: relative;
        margin-left: 4px;
      }
      &.DCAR:after {
        background: url("../../assets/ui/dcar.png") no-repeat left center;
        background-size: contain;
      }
      &.DCAU:after {
        background: url("../../assets/ui/dcau.png") no-repeat left center;
        background-size: contain;
      }
    }
    .dialog-button {
      @media only screen and (max-width: 576px) {
        transform: scale(0.8);
      }
    }
    h3 {
      color: #fff;
      text-align: left;
      font-family: "IM Fell English", serif;
      font-size: 90%;
      text-transform: none;
      margin: 3px 3px 3px 10px;

      &.rare {
        color: $rare;
      }

      &.epic {
        color: $epic;
      }

      &.mythical {
        color: $mythical;
      }

      &.legendary {
        color: $legendary;
      }

      &.fabled {
        color: $fabled;
      }

      &.unique {
        color: $unique;
      }

      &.artefact {
        color: $artefact;
      }

      &.uncommon {
        color: $uncommon;
      }
    }
  }
}

.skeleton-box {
  position: relative;
  display: grid;
  grid-template-columns: 72px 1fr;
  grid-gap: 1rem;
  .square,
  .rectangle {
    display: block;
    position: relative;
    overflow: hidden;
    background: #464646;
    animation: skeleton 1s ease-in-out forwards infinite;
    animation-direction: alternate;
  }
  .details {
    display: grid;
    align-items: center;
  }

  /* .loading::after {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      animation: 2s shimmer linear 0.5s infinite;
      background: linear-gradient(90deg, transparent, #ededed, transparent);
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      transform: translateX(-100%);
      z-index: 1;
    } */

  @keyframes skeleton {
    0% {
      opacity: 0.3;
      transform: translateY(3px) scale(0.98);
    }
    100% {
      opacity: 0.6;
      transform: translateY(0px) scale(1);
    }
  }
}
</style>
