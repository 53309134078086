<template>
  <div :class="['notification']">
    <div class="text">
      <span class="time">[{{ fromTime }}]</span
      ><span :class="['icon', type]"></span>
      <span v-html="text" />
    </div>
  </div>
</template>

<script>
// import Avatar from "./Avatar.vue";
import { ref, onMounted } from "vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useStore } from "vuex";

dayjs.extend(relativeTime);

export default {
  //   components: { Avatar },
  props: {
    notificationId: { type: String, default: "" },
    nftid: { type: String, default: "" },
    walletAddress: { type: String, default: "" },
    type: { type: String, default: "" },
    createdAt: [Object, String],
    text: { type: String, default: "" },
    // sender: { type: Boolean, default: false },
  },
  setup(props) {
    const fromTime = ref(null);
    const store = useStore();

    onMounted(() => {
      updateTimestamp();
    });

    const updateTimestamp = () => {
      if (typeof props.createdAt === "string") {
        // fromTime.value = dayjs(props.createdAt).fromNow();
        const d = new Date(props.createdAt);
        fromTime.value = dayjs(d).fromNow();
      } else {
        const d = new Date(props.createdAt * 1000);
        fromTime.value = dayjs(d).fromNow();
      }
    };

    store.watch(
      (state) => state.gameHours,
      () => {
        // updating timestamp when game hour changes
        updateTimestamp();
      }
    );

    return { fromTime };
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid #838282;
  box-shadow: 0 0 0px 3px #00000059;
}
.notification {
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  align-items: center;
  grid-gap: 0.5rem;
  margin: 8px 0;
  position: relative;
  padding: 0 15px;
  .icon {
    background-repeat: no-repeat;
    background-position: left top;
    width: 15px;
    height: 15px;
    display: inline-block;
    background-size: cover;
    position: relative;
    top: 3px;
    opacity: 0.5;
    margin-right: 5px;
    &.leveling {
      background-image: url("https://cdn.dragoncrypto.io/uiassets/leveling.svg");
    }

    &.skillIncrease {
      background-image: url("https://cdn.dragoncrypto.io/uiassets/leveling.svg");
    }

    &.negative {
      background-image: url("https://cdn.dragoncrypto.io/uiassets/negative.svg");
    }

    &.positive {
      background-image: url("https://cdn.dragoncrypto.io/uiassets/positive.svg");
    }

    &.info {
      background-image: url("https://cdn.dragoncrypto.io/uiassets/information.svg");
    }
  }
  .text {
    font-size: 0.9rem;
    line-height: 1.3rem;
    .name {
      font-weight: bold;
      display: block;
      margin-bottom: 0.2rem;
      margin-top: 0;
      span {
        font-size: 0.8rem;
        opacity: 0.6;
      }
    }
    .time {
      margin: 0 5px 0 0;
      opacity: 0.5;
    }
  }
  &:hover {
    button.delete {
      opacity: 0.5;
    }
  }
  button.delete {
    position: absolute;
    right: 0;
    border: none;
    border-radius: 100%;
    background: #fff;
    color: #000;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    font-size: 1.2rem;
    &:hover {
      cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
        auto;
      background-color: red;
      color: #fff;
      opacity: 1;
    }
  }
}
</style>
