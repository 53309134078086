import Constants from "../../consts/constants";
// initial state
const state = () => ({
  selectedInventoryEquipments: [],
  filterType: Constants.filterTypes.equipments,
});
// // getters
// const getters = {};

// actions
const actions = {
  /**
   * Toggle the selected status of an item and fill respective selected inventory category
   */
  toggleItem({ commit, state, rootState }, item) {
    let equipmentsSelected = state.selectedInventoryEquipments;
    if (
      item.type != null &&
      item.type != Constants.useable.consumable &&
      item.type != Constants.useable.resource
    ) {
      if (equipmentsSelected.includes(item.id)) {
        equipmentsSelected = equipmentsSelected.filter((i) => {
          return i !== item.id;
        });
      } else {
        equipmentsSelected.push(item.id);
      }
      commit("setSelectedInventoryEquipments", equipmentsSelected);
    } else if (item.type != null && item.type == Constants.useable.consumable) {
      let notification = "You cannot breakdown a consumable just yet";
      rootState.doNotification(notification);

      //TODO - You cannot currently break these down. As such we should keep this commented out and add an alert.
      /*let consumablesSelected = state.selectedConsumables;
      if (consumablesSelected.includes(item.id)) {
        consumablesSelected = consumablesSelected.filter((i) => {
          return i !== item.id;
        });
      } else {
        if (consumablesSelected.length < 10) {
          consumablesSelected.push(item.id);
        }
      }
      commit("setSelectedConsumables", consumablesSelected);
      */
    } else if (item.type != null && item.type == Constants.useable.resource) {
      if (
        rootState.gameState !== Constants.gamemodes.forge &&
        rootState.gameState !== Constants.gamemodes.crafting
      ) {
        let notification = "You cannot breakdown a resource";
        rootState.doNotification(notification);
      }

      //TODO - Not needed just yet, but keep code for easy of later use and add an alert
      /*let resourcesSelected = state.selectedResources;
      if (resourcesSelected.includes(item.id)) {
        resourcesSelected = resourcesSelected.filter((i) => {
          return i !== item.id;
        });
      } else {
        if (resourcesSelected.length < 10) {
          resourcesSelected.push(item.id);
        }
      }
      commit("setSelectedResources", resourcesSelected);
      */
    }
  },
  /**
   * Reset all selected states in stash
   */
  resetItems({ commit }) {
    commit("setSelectedInventoryEquipments", []);
  },
};

// mutations
const mutations = {
  setSelectedInventoryEquipments(state, items) {
    state.selectedInventoryEquipments = items;
  },
  setFilterType(state, filterType) {
    state.filterType = filterType;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
