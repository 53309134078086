<template>
  <BasicPopup
    header="Update List"
    :class="['update-list', isGuideOpen ? 'hide' : '']"
  >
    <div class="close-button button-small" @click="hide">
      <span>x</span>
    </div>
    <div class="inner-wrapper">
      <div class="update-container">
        <!-- Loop through updates and create sections -->
        <div
          v-for="(updateGroup, index) in updateGroups"
          :key="index"
          class="update-group"
        >
          <h3>{{ updateGroup.updateTime }}</h3>
          <div
            v-for="(categoryUpdates, idx) in updateGroup.updates"
            :key="idx"
            class="category-section"
          >
            <h4>{{ categoryUpdates.category }}</h4>
            <ul class="list">
              <li
                v-for="(update, updateIdx) in categoryUpdates.list"
                :key="updateIdx"
              >
                {{ update }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </BasicPopup>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { updates } from "../utils/updateList";
import BasicPopup from "../components/Popup/BasicPopup.vue";

export default {
  name: "UpdateList",
  props: {
    size: {
      // small || extra-small || default large
      type: String,
    },
  },
  components: {
    BasicPopup,
  },
  setup() {
    const store = useStore();

    const hide = () => {
      store.commit("intro/setHasSeenUpdateList", true);
      localStorage.setItem("seenUpdateList", true);
    };

    // Process the updateList object into an array of update groups
    const updateGroups = updates;
    console.log(updateGroups);

    const isGuideOpen = computed(() => {
      return store.state.intro.isGuideOpen;
    });
    return {
      // computed
      isGuideOpen,
      // methods
      hide,
      // data
      updateGroups, // Provide the processed updateGroups to the template
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.update-list {
  width: calc(30% - $popup-border-size - $popup-border-size);
  height: calc(600px - $popup-border-size - $popup-border-size);
  z-index: 15;
  min-width: 600px;
  @media only screen and (max-width: 576px) {
    min-width: calc(100% - $popup-border-size - $popup-border-size);
  }
  &.hide {
    display: none !important;
  }
  .inner-wrapper {
    width: calc(100% + $popup-border-offset + $popup-border-offset);
    height: calc(100% + $popup-border-offset + $popup-border-offset);
    @media only screen and (max-width: 576px) {
      height: calc(100% + $popup-border-offset + $popup-border-offset);
      overflow-y: scroll;
    }
    left: -$popup-border-offset;
    overflow-y: scroll;
    position: relative;
    top: -35px;
    &::-webkit-scrollbar-track {
      /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000000f2;
    }
    .update-container {
      .update-group {
        h3 {
          font-size: 1.5rem;
          margin: 0 0 1rem 0;
          padding: 5px 0;
          background: #0000003b;

          position: relative;
        }
        h4 {
          text-align: left;
          width: calc(95% - 1.5rem);
          padding-left: 1.5rem;
        }
        ul.list {
          width: calc(95% - 1.5rem);
          margin: 0 auto 2rem;
          padding-left: 1.5rem;
          li {
            text-align: left;
            margin-bottom: 0.5rem;
            line-height: 1.4rem;
            opacity: 0.7;
          }
        }
      }
    }
  }
}
.close-button {
  position: absolute;
  top: -$popup-border-size;
  right: -$popup-border-size;
}
</style>
