export const CombatSteps = [
  {
    attachTo: { element: "#hero" },
    content: {
      title: "Your Hero",
      description:
        "Your hero in battle. The health bar is shown at the top and the stats at the bottom.",
    },
  },
  {
    attachTo: { element: "#enemy" },
    content: {
      title: "Your Opponent",
      description:
        "The enemy you are fighting. Their health bar is in red above their portrait and their stats, armour type and element type (if any) in the brown box above.",
    },
  },
  {
    attachTo: { element: "#select-characters" },
    content: {
      title: "Swap Your Hero In Battle",
      description:
        "You can swap your hero mid-battle by clicking on the small hero thumbnails. It helps to have multiple heroes with different strengths to fight powerful enemies.",
    },
  },
  {
    attachTo: { element: "#attack-button-left" },
    content: {
      title: "Attack",
      description:
        "This is your primary attack button. When you click it, your hero attacks with the weapon in their left hand.",
    },
  },
  {
    attachTo: { element: "#flee-button" },
    content: {
      title: "Flee / Secondary Attack",
      description:
        "If you think the defeat is imminent, you can always flee the battle. Become stronger, there is always a next time. This button also acts as a secondary attack button for weapons in your right hand.",
    },
  },
  {
    attachTo: { element: "#menu-open-step" },
    content: {
      title: "Consumables",
      description:
        "This bag can contain upto 6 different consumable types. You can use them in battle to recover your health",
    },
  },
  {
    attachTo: { element: "#autoplay-button" },
    content: {
      title: "Autoplay",
      description:
        "Enable Autoplay to have your hero attack automatically. You can disable it at any time.",
    },
  },
  {
    attachTo: { element: "#notification-button-guide" },
    content: {
      title: "Combat Log",
      description:
        "Get a running commentary of the fight in the combat log. Click the bell icon to show your combat log",
    },
  },
];

export const InnSteps = [
  {
    attachTo: { element: "#get-a-room-button" },
    content: {
      title: "Rent a Room",
      description:
        "Rent a room at the Inn to completely recover your health and turns.",
    },
  },
  {
    attachTo: { element: "#bard-song-button" },
    content: {
      title: "Bard's Song",
      description:
        "Request a song from the Bard. Depending on your luck, you may partially or fully recover your turns.",
    },
  },
  {
    attachTo: { element: "#head-to-backroom-button" },
    content: {
      title: "The Inn Backroom",
      description: "Coming Soon!",
    },
  },
];

export const EquipmentSteps = [
  {
    attachTo: { element: "#points-to-spend" },
    content: {
      title: "Your Stats Points",
      description:
        "The total number of stat points you have. You can spend them on your stats below depending on the type of hero you want to be. You get more points to spend with each level up.",
    },
  },
  {
    attachTo: { element: "#allocate-stats" },
    content: {
      title: "Allocate Stat Points",
      description:
        "Increase your stats by clicking on the + button. You can only spend the total available stat points shown above. Tip: Having some endurance early on helps.",
    },
  },
  {
    attachTo: { element: "#apply-stats-button" },
    content: {
      title: "Save Your Stats",
      description:
        "Once you have allocated all the points, you can save your stats using this button. Remember, you cannot change the stats once applied.",
    },
  },
  {
    attachTo: { element: "#revert-stats-button" },
    content: {
      title: "Reset Points",
      description:
        "You can reset the stat points you just assigned to start over again. Only the current available stat points can be reset.",
    },
  },
  {
    attachTo: { element: "#equipment-step" },
    content: {
      title: "Wear Your Equipment",
      description:
        "This is your equipment screen. Your equipped items are shown here. Hover over different areas to find out what equipment you can wear!",
    },
  },
];

export const InventorySteps = [
  {
    attachTo: { element: "#inventory-items" },
    content: {
      title: "Welcome to your Inventory",
      description:
        "Here, you can view all of your in-game items such as equipment, consumables, resources, and miscellaneous items, and use them whenever you need them.",
    },
  },
  {
    attachTo: { element: "#show-all" },
    content: {
      title: "Show All Items",
      description: "Show all your inventory items in a single place.",
    },
  },
  {
    attachTo: { element: "#show-armour" },
    content: {
      title: "Your Armour",
      description: "Filter and show only your armour.",
    },
  },
  {
    attachTo: { element: "#show-weapons" },
    content: {
      title: "Your Weapons",
      description: "Filter and show only your weapons.",
    },
  },
  {
    attachTo: { element: "#show-consumables" },
    content: {
      title: "Your Consumables",
      description:
        "Filter and show only your consumable items like healing pots, bandages and so on.",
    },
  },
  {
    attachTo: { element: "#show-resources" },
    content: {
      title: "Your Resources",
      description:
        "Filter and show all your resources. Resources are used for various tasks - like crafting & repairing - and can be attained from The Mines, Deepwood or by breaking down your equipment.",
    },
  },
  {
    attachTo: { element: "#show-quest-items" },
    content: {
      title: "Your Quest Items",
      description: "Filter and show all your quest items.",
    },
  },
  {
    attachTo: { element: "#inventory-items" },
    content: {
      title: "Explore More",
      description: "Hover over items to see more detail or to use them.",
    },
  },
];

// export const BankSteps = [
//   {
//     attachTo: { element: "#groms-bank" },
//     content: {
//       title: "Grom's Bank",
//       description:
//         "Welcome to your bank in Dragon Haven. This is where you visit to buy bonds, claim rewards, see your $DCAU/$DCAR passbook and view recent transactions as well.",
//     },
//   },
//   {
//     attachTo: { element: "#sale-status" },
//     content: {
//       title: "Bond Sale Status",
//       description:
//         "This shows the status whether the bond sale is live or not.",
//     },
//   },
//   {
//     attachTo: { element: "#bond-details" },
//     content: {
//       title: "Round Details",
//       description: "The current round details are shown here.",
//     },
//   },
//   {
//     attachTo: { element: "#purchase-bond" },
//     content: {
//       title: "Purchase Bonds",
//       description:
//         "You can purchase bonds from here. You can only buy a bond when the sale is live.",
//     },
//   },
//   {
//     attachTo: { element: "#claim-rewards" },
//     content: {
//       title: "Claim Your Rewards",
//       description: "You can claim your accrued rewards from here.",
//     },
//   },
//   {
//     attachTo: { element: "#dcau-balance" },
//     content: {
//       title: "Your DCAU Balance",
//       description: "This shows your DCAU balance in your wallet.",
//     },
//   },
//   {
//     attachTo: { element: "#dcar-balance" },
//     content: {
//       title: "Your DCAR Balance",
//       description: "This shows your DCAR balance in your wallet.",
//     },
//   },
//   {
//     attachTo: { element: "#recent-transactions" },
//     content: {
//       title: "Your Recent Transactions",
//       description: "Your 4 latest DCAU and DCAR transactions.",
//     },
//   },
// ];

export const BankSteps = [
  {
    attachTo: { element: "#groms-bank" },
    content: {
      title: "Grom's Bank",
      description:
        "Welcome to your bank in Dragon Haven. This is where you visit to stake tokens and claim rewards.",
    },
  },
  {
    attachTo: { element: "#dcau-balance" },
    content: {
      title: "Your DCAU Balance",
      description: "This shows your DCAU balance in your wallet.",
    },
  },
  {
    attachTo: { element: "#dcar-balance" },
    content: {
      title: "Your DCAR Balance",
      description: "This shows your DCAR balance in your wallet.",
    },
  },
  {
    attachTo: { element: "#copper-balance" },
    content: {
      title: "Your Copper Balance",
      description:
        "This shows your Copper balance in your wallet. You earn copper only from staking DCAR.",
    },
  },
  {
    attachTo: { element: "#stake-tab" },
    content: {
      title: "Stake Your DCAR",
      description: "Visit this tab to stake your DCAR at Grom's bank.",
    },
  },
  {
    attachTo: { element: "#unstake-tab" },
    content: {
      title: "Unstake Your DCAR",
      description: "Visi this tab to unstake your DCAR.",
    },
  },
  {
    attachTo: { element: "#locked-tab" },
    content: {
      title: "Locked DCAR",
      description: "Visit this tab to see your locked DCAR details.",
    },
  },
  {
    attachTo: { element: "#claim-vault-rewards" },
    content: {
      title: "Claim Your Rewards",
      description: "You can claim your accrued rewards from here.",
    },
  },
  {
    attachTo: { element: "#staked-meta" },
    content: {
      title: "Staking Details",
      description:
        "Get details about your staked and locked tokens as well as APR",
    },
  },
];

export const StashSteps = [
  {
    attachTo: { element: "#your-stash-steps" },
    content: {
      title: "Your Stash",
      description:
        "Your loot and crafted items are stored in Your Stash. Once claimed, they are minted as NFTs in your wallet",
    },
  },
  {
    attachTo: { element: "#stash-filters" },
    content: {
      title: "Items Category",
      description:
        "You can claim items from the stash in any one of these categories. You can only claim items in the same category at one time - equipment, consumables, resources or tokens.",
    },
  },
  {
    attachTo: { element: "#stash-items" },
    content: {
      title: "Stash Items",
      description:
        "The items in your stash appear here. Select the items you'd like to claim by clicking on them or by hovering over the item and clicking the select button. You can only select 10 items per claim.",
    },
  },
  {
    attachTo: { element: "#stash-claim-button" },
    content: {
      title: "Claim Stash",
      description:
        "Once you have selected items to claim, a claim button will appear here. Click on that button to claim your items. You can claim tokens in the tokens section.",
    },
  },
];

export const GuildQuestSteps = [
  {
    attachTo: { element: "#guild-quest-list-0" },
    content: {
      title: "Your Guild Quests",
      description:
        "All the quests in the guild are shown here. Quests have rarity. Rarer quests are harder to complete but give amazing rewards. Each quest has an expiry time as well so you need to complete it before that time.",
    },
  },
  {
    attachTo: { element: "#your-quest-points" },
    content: {
      title: "Your Quest Points",
      description:
        "Accepting each quest consumes guild quest points. These regenerate over time. Rarer quests cost more quest points.",
    },
  },
  {
    attachTo: { element: "#your-influence-points" },
    content: {
      title: "Your Influence Points",
      description:
        "You get influence points from completing quests. Influence points are associated with the Defender's or Crafter's guild. A certain amount of influence points is required to accept rarer quests.",
    },
  },
  {
    attachTo: { element: "#your-accepted-quests" },
    content: {
      title: "Your Accepted Quests",
      description:
        "All the quests you accept are listed here. Once they are complete, come back here to mark them complete and receive rewards.",
    },
  },
];

export const SingleStep = {
  dead: [
    {
      attachTo: { element: "#go-to-healers" },
      content: {
        title: "Oops! you are dead!",
        description:
          "Don't worry, there's a way back to the land of the living. Click here to go to the healer's hut, or use the map to find it.",
      },
      hasButtons: false,
    },
  ],
  resurrect: [
    {
      attachTo: { element: "#resurrect-button" },
      content: {
        title: "Rise From The Dead",
        description:
          "Here, you can ask the healer to work their magic and resurrect you. It will cost you a certain amount of DCAU, but life is priceless.",
      },
      hasButtons: false,
    },
  ],
  turns: [
    {
      attachTo: { element: "#turns-bar" },
      content: {
        title: "Out of Turns",
        description:
          "Oh no! It seems like you've run out of turns. Your turns regenerate automatically, but very slowly. Let's visit the inn to rest and recover turns immediately.",
      },
      hasButtons: true,
    },
    {
      attachTo: { element: "#inn" },
      content: {
        title: "The Inn",
        description:
          "Let's enter the inn and rest to recover your turn points. Click on it to enter.",
      },
      hasButtons: false,
    },
  ],
  heal: [
    {
      attachTo: { element: "#hp-bar-container" },
      content: {
        title: "You Got Hurt",
        description:
          "The last battle took its toll on you. Let's head to the healer's hut to recover your HP.",
      },
      hasButtons: true,
    },
    {
      attachTo: { element: "#healers-hut" },
      content: {
        title: "Healer's Hut",
        description:
          "Here, you can ask the healer to use it's vast knowledge and heal you instantly. It will cost you a certain amount of DCAU.",
      },
      hasButtons: false,
    },
  ],
  blueprint: [
    {
      attachTo: { element: "#your-inventory" },
      content: {
        title: "What do we have here?",
        description:
          "Looks like you received an interesting item. Close your stash and let's check it out by opening your inventory.",
      },
      hasButtons: false,
    },
  ],
  blueprintInventory: [
    {
      attachTo: { element: "#show-all" },
      content: {
        title: "It's a Blueprint!",
        description:
          "With your crafting skills, you can create epic items using blueprints. Let's head over to the Crafter's Guild!",
      },
      hasButtons: true,
      closeInventory: true,
    },
    {
      attachTo: { element: "#crafters-guild" },
      content: {
        title: "Crafter's Guild",
        description:
          "At the Crafter's Guild, you can craft your own items using blueprints, or take up jobs from the Crafter's Market and Repair Market.",
      },
      hasButtons: false,
    },
  ],
  selfCrafting: [
    {
      attachTo: { element: "#self-crafting-button" },
      content: {
        title: "Self Crafting",
        description:
          'You can use blueprints and resources to mint new, never-before-seen equipment! Let\'s see how it works by clicking on "Crafting".',
      },
      hasButtons: false,
    },
  ],
  dragBluePrint: [
    {
      attachTo: { element: "#drag-blueprint-here" },
      content: {
        title: "Blueprint Slot",
        description:
          "To initiate the crafting process, simply drag your blueprint from the inventory and drop it into this designated area.",
      },
      hasButtons: false,
    },
  ],
  blueprintResources: [
    {
      attachTo: { element: "#blueprint-resources" },
      content: {
        title: "Add Resources",
        description:
          "To complete the crafting process, drag and drop the required resources here. If you don't have any, you'll need to find some.",
      },
      hasButtons: true,
    },
    {
      attachTo: { element: "#create-item-button" },
      content: {
        title: "Create the item",
        description:
          "Once you have all the required resources, you can create the new item by approving the DCAR and clicking the 'Craft' button.",
      },
      hasButtons: true,
    },
  ],
  setStats: [
    {
      attachTo: { element: "#your-character" },
      content: {
        title: "Set Your Stats",
        description: "Assign your starting stat points to begin your journey.",
      },
      hasButtons: false,
    },
  ],
  letsGoToBattle: [
    {
      attachTo: { element: "#stats-close-button" },
      content: {
        title: "Try Out Your Hero!",
        description:
          "Now that your stats are assigned, close this window and try out your hero!",
      },
      hasButtons: false,
    },
  ],
  findMonsters: [
    {
      attachTo: { element: "#search-the-plains" },
      content: {
        title: "Search the plains",
        description:
          "Lets put your hero to the test by engaging in a battle against various monsters lurking in the plains.",
      },
      hasButtons: false,
    },
  ],
  gatherWoods: [
    {
      attachTo: { element: "#deepwood" },
      content: {
        title: "Gather resources",
        description:
          "This is where you can gather resources. But you need an axe to do it.",
      },
      hasButtons: true,
    },
  ],
  lootClaim: [
    {
      attachTo: { element: "#loot-step" },
      content: {
        title: "Congratulations! Your rewards",
        description:
          "This is your loot from the fight. Let's go claim it now. Click Next.",
      },
      hasButtons: true,
    },
    {
      attachTo: { element: "#claim-stash-step" },
      content: {
        title: "Let's Go To Stash",
        description:
          "Let's go to your Stash. Click the Claim Stash button above.",
      },
      hasButtons: false,
    },
  ],
};
