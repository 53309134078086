<template>
  <div :class="getClass"></div>
</template>
<script>
import { useStore } from "vuex";
import Constants from "../consts/constants";

export default {
  name: "Sleep",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    getClass() {
      return (
        "sleep-wrapper " +
        (this.$store.state.gameState == Constants.gamemodes.sleeping
          ? "show"
          : "hide")
      );
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;
}

.sleep-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  pointer-events: none;

  &.show {
    animation: sleep 5s 1;
  }
}

@keyframes sleep {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: 0.2;
  }
  35% {
    opacity: 1;
  }
  70% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
</style>
