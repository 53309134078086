<template>
  <BasicPopup class="session-expired-popup" :header="'Session Expired'">
    <div class="session-expired">
      <div class="session-expired__content">
        <div class="session-expired__content__title">
          Your session has expired.
        </div>
        <div class="session-expired__content__desc">
          <DialogButton @click="login" :buttonText="'Login Again'" />
        </div>
      </div>
    </div>
  </BasicPopup>
</template>

<script>
import { useStore } from "vuex";
import BasicPopup from "./Popup/BasicPopup.vue";
import DialogButton from "./DialogButton.vue";

export default {
  name: "SessionExpired",
  components: {
    BasicPopup,
    DialogButton,
  },
  props: {},
  setup() {},
  data() {
    return {};
  },
  methods: {
    login() {
      this.$store.commit("sessionExpired", false);
      localStorage.removeItem("sessionId");
      this.$router.go();
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
.session-expired-popup {
  z-index: 9;
}
</style>
