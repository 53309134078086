<template>
  <div class="mini-popup" v-if="isVisible">
    <slot />
    <button class="close-button button-small" @click="closePopup">×</button>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "BasicPopup",
  props: {
    popupType: {
      type: String,
    },
    header: {
      type: String,
    },
  },
  setup() {
    const store = useStore();
    const closePopup = () => {
      store.commit("setPopupActiveState", false);
    };
    const isVisible = computed(() => {
      return store.state.popupActive;
    });
    return {
      isVisible,
      closePopup,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/globals.scss";
// Popup Border Size is in globals as needed in other places too

.mini-popup {
  min-width: 250px;
  min-height: 300px;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 12;
  transform: translate(-50%, -50%);
  background: #000000d1;
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: none;
    border: none;
  }
  h3 {
    background: url("~@/assets/popup/header-repeat.webp") center center repeat-x;
    height: 48px;
    position: absolute;
    top: -67px;
    width: calc(100% + 76px);
    left: -38px;
    display: grid;
    align-items: center;
    text-transform: capitalize;
    @media only screen and (max-width: 576px) {
      font-size: 1.2rem;
    }
    span {
      z-index: 5;
    }
    &:after,
    &:before {
      content: "";
      width: 161px;
      height: 48px;
      display: block;
      position: absolute;
      background: url("~@/assets/popup/header-sides.webp") center center
        no-repeat;
      top: 0;
    }
    &:after {
      left: 0;
    }
    &:before {
      right: 0;
      transform: scaleX(-1);
    }
  }
  &.full {
    width: calc(100% - 134px);
    height: calc(
      100% - 194px
    ); // 100% -  67px border + 67px border + 60px padding top
  }
}
</style>
