<template>
  <div :class="'repair-sell-wrapper ' + currentClass">
    <div class="repair-sell-menu">
      <div class="close-button button-small" @click="hide"><span>x</span></div>
      <div
        v-if="currentItem && currentItem.rarity"
        :class="'title ' + currentItem.rarity.toLowerCase()"
      >
        <div class="img-wrapper">
          <img :src="currentImage" :alt="currentItem.name" />
        </div>
        <h2 :class="currentItem.rarity.toLowerCase()">
          {{ currentItem.name }}
          <span class="broken" v-if="itemStats && itemStats.isBroken"
            >[Broken]</span
          >
        </h2>
      </div>
      <div class="sell-price-listing dcar">
        <label for="base-price">Base Repair Cost: {{ basePrice }}</label>
      </div>
      <div :class="['sell-price', 'inputs', 'dcar']">
        <label for="sell-price"
          >Repair Bounty<input
            type="number"
            name="sell-price"
            :min="basePrice + 1"
            step="1"
            v-model="currentPrice"
            @keyup="updateCurrentPriceDollar"
        /></label>
      </div>
      <div class="sell-price-listing dcar">
        <label for="base-price">Total Cost: {{ totalPrice }}</label>
      </div>
      <label class="dollar-value">${{ currentPriceDollar }} USD</label>
      <div class="buttons" v-if="!successContract">
        <DialogButton
          v-if="!approvedRepairNft"
          :buttonText="`Approve All Equipment`"
          @click="repairApprovalNft"
          :isLoading="approvingRepairNft"
          :disabled="isLoading"
        />
        <DialogButton
          v-else-if="parseFloat(totalPrice) > parseFloat(dcarApprovalAmount)"
          :buttonText="`Approve DCAR`"
          @click="repairApprovalDcar"
          :isLoading="approvingRepairDcar"
          :disabled="isLoading"
        />
        <DialogButton
          v-else
          :buttonText="'Create Repair Bounty'"
          @click="createRepairContract"
          :isLoading="isLoading"
        />
      </div>
      <div class="successMessage" v-if="successContract">
        <h3>Repair Contract Created!</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import DialogButton from "../DialogButton.vue";
import Constants from "../../consts/constants";

import { useUser } from "../../composables/user";
import { usePrice } from "../../composables/price";
import { parseError } from "../../utils/helpers";
import { ethers } from "ethers";
import apiConnector from "../../game/apiConnector";
import {
  getDCGEquipmentContract,
  getRepairGuildContract,
  getDCARContract,
  getLoadRepair,
} from "../../utils/getContract";

export default {
  name: "ListRepairItem",
  components: {
    DialogButton,
  },
  props: {},
  setup() {
    /**
     * Data
     */
    const store = useStore();
    const isVisible = ref(true);
    const isInventoryOpen = ref(false);
    const currentPrice = ref(1);
    const basePrice = ref(1);
    const currentPriceDollar = ref(0);
    const dcarPerDollar = ref(0);
    const successContract = ref(false);
    const approvedRepairNft = ref(false);
    const approvingRepairNft = ref(false);
    const approvedRepairDcar = ref(false);
    const dcarApprovalAmount = ref(0);
    const approvingRepairDcar = ref(false);

    const { address, signer } = useUser();
    const { getDCARPricePerDollar } = usePrice();

    const EquipmentContract = getDCGEquipmentContract(signer.value);
    const RepairGuildContract = getRepairGuildContract(signer.value);
    const DCARContract = getDCARContract(signer.value);
    const LoadRepair = getLoadRepair(signer.value);

    /**
     * Methods
     */
    // Helper method to delay stuff
    const delay = async (time) => new Promise((res) => setTimeout(res, time));

    const createRepairSignature = async () => {
      const sessionId = localStorage.getItem("sessionId");
      const heroId = character.value.number;
      const tokenId = currentItem.value.id;
      const price = currentPrice.value;

      const response = await fetch(
        Constants.apiUrl + "contract/sign-repair-guild-contract",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId,
            heroId,
            tokenId,
            price,
            account: address.value,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();

      if (!data.success) {
        store.commit("setNotification", data.message);
        store.commit("setTurnHappening", false);
        return;
      }

      return {
        dcarAmount: data.dcarAmount,
        price: data.price,
        resourceCount: data.resourceCount,
        resourceIds: data.resourceIds,
        quantities: data.quantities,
        signature: data.signature,
        nonce: data.nonce,
      };
    };

    const createRepairContract = async () => {
      store.commit("setTurnHappening", true);

      if (currentPrice.value < 0) {
        store.commit("setNotification", "Price cannot be less than 0");
        store.commit("setTurnHappening", false);
        return;
      }

      if (parseFloat(currentPrice.value) <= parseFloat(basePrice.value)) {
        store.commit(
          "setNotification",
          "Bounty reward cannot be less than base repair cost."
        );
        store.commit("setTurnHappening", false);
        return;
      }

      const {
        dcarAmount,
        price,
        resourceCount,
        resourceIds,
        quantities,
        signature,
        nonce,
      } = await createRepairSignature();

      if (!price || !dcarAmount) {
        console.log(
          `Error code: repair contract ${price}, ${dcarAmount}, ${currentItem.value.id}`
        );
        store.commit("setNotification", "Error creating repair contract.");
        store.commit("setTurnHappening", false);
        return;
      }

      const priceConverted = ethers.utils.formatEther(price);
      const dcarAmountConverted = ethers.utils.formatEther(dcarAmount);

      if (parseFloat(priceConverted) <= parseFloat(dcarAmountConverted)) {
        store.commit(
          "setNotification",
          "Bounty reward cannot be less than base repair cost."
        );
        store.commit("setTurnHappening", false);
        return;
      }

      try {
        // Executing Repair Transaction
        const tx = await RepairGuildContract.saleRepairContract(
          currentItem.value.id,
          price,
          dcarAmount,
          resourceCount,
          resourceIds,
          quantities,
          signature,
          nonce
        );

        if (tx?.hash) {
          await apiConnector.handleTransactionHash(
            store.state.account,
            localStorage.getItem("sessionId"),
            character.value.number,
            "repairGuild",
            tx.hash
          );
        }

        const receipt = await tx.wait();
        // Only process Repair if the transaction was successful!
        if (receipt.status === 1) {
          playOnList();
          successContract.value = true;
          const payload = {
            blockNumber: receipt.blockNumber,
            repairItem: currentItem.value.id,
            txHash: receipt.transactionHash,
          };
          await store.commit("createRepairContract", payload);

          let foundToken = false;
          while (!foundToken) {
            await store.dispatch("repair/updateRepairItems");
            await store.dispatch("repair/updateMyListedItems");
            await sleep(1000);

            foundToken = currentMarketInventory.value.some(
              (item) => item.tokenId === currentItem.value.id
            );
          }
          store.commit("hideRepairListing");
        }
        store.commit("setTurnHappening", false);
      } catch (error) {
        console.log(error);
        store.commit("setTurnHappening", false);
      }
    };

    const checkRepairApprovalNft = async () => {
      let isRepairApproved = await EquipmentContract.isApprovedForAll(
        address.value,
        RepairGuildContract.address
      );

      if (isRepairApproved) {
        approvedRepairNft.value = true;
      }
    };

    const checkRepairApprovalDcar = async () => {
      let allowanceRepair = await DCARContract.allowance(
        address.value,
        RepairGuildContract.address
      );

      allowanceRepair = ethers.utils.formatUnits(allowanceRepair);

      if (parseFloat(allowanceRepair) > 0) {
        dcarApprovalAmount.value = allowanceRepair;
        approvedRepairDcar.value = true;
      }
    };

    const repairApprovalNft = async () => {
      try {
        store.commit("setTurnHappening", true);
        approvingRepairNft.value = true;
        const txApproveEquipment = await EquipmentContract.setApprovalForAll(
          RepairGuildContract.address,
          true
        );
        await txApproveEquipment.wait();
        store.commit("setTurnHappening", false);
        approvingRepairNft.value = false;
        await checkRepairApprovalNft();
      } catch (e) {
        store.commit("setTurnHappening", false);
        approvingRepairNft.value = false;
        store.commit("setNotification", parseError(e));
        console.log(e);
      }
    };

    const repairApprovalDcar = async () => {
      try {
        store.commit("setTurnHappening", true);
        approvingRepairDcar.value = true;
        const txApproveDcar = await DCARContract.approve(
          RepairGuildContract.address,
          ethers.utils.parseEther("1000000000")
        );
        await txApproveDcar.wait();
        store.commit("setTurnHappening", false);
        approvingRepairDcar.value = false;
        await sleep(1000);
        await checkRepairApprovalDcar();
      } catch (e) {
        store.commit("setTurnHappening", false);
        approvingRepairDcar.value = false;
        store.commit("setNotification", parseError(e));
        console.log(e);
      }
    };

    const updateCurrentPriceDollar = async () => {
      await delay(500);

      const total = currentPrice.value + basePrice.value;

      currentPriceDollar.value = (total / dcarPerDollar.value).toFixed(3);
    };

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const playOnList = () => {
      const sound = new Audio(
        "https://cdn.dragoncrypto.io/sound/shop-bell.mp3"
      );
      sound.volume = store.state.soundVolume;
      sound.play();
    };
    const hovered = () => {
      store.commit("setTooltipHovered");
    };
    const unhovered = () => {
      store.commit("setTooltipUnhovered");
    };
    const hide = () => {
      store.commit("hideRepairListing");
    };

    /**
     * Computed Methods
     */
    const isLoading = computed(() => {
      return store.state.isTurnHappening;
    });

    const character = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });

    const calculateBasePrice = async () => {
      const totalDurability = itemStats.value.totalDurability;
      const currentDurability = itemStats.value.durability;
      const durabilityNeeded = totalDurability - currentDurability;
      const rarity = currentItem.value.rarity.toLowerCase();
      console.log(`durability needed ${durabilityNeeded}`);
      const repairCost = await LoadRepair.priceForRepair(
        rarity,
        durabilityNeeded
      );
      basePrice.value =
        Math.round(ethers.utils.formatEther(repairCost) * 100) / 100;
      currentPrice.value = basePrice.value + 1;
    };

    const totalPrice = computed(() => {
      return basePrice.value + currentPrice.value;
    });

    const isWandering = computed(() => {
      return store.state.gameState == Constants.gamemodes.wandering;
    });

    const currentClass = computed(() => {
      return store.state.isRepairListing ? "show" : "hide";
    });
    const currentItem = computed(() => {
      return store.state.hoveredItem;
    });

    const currentImage = computed(() => {
      if (!currentItem.value || !currentItem.value.imageName) {
        return "";
      }
      return (
        "https://ik.imagekit.io/dcg/equip/" +
        currentItem.value.imageName +
        "?tr=w-90"
      );
    });

    const itemStats = computed(() => {
      if (!currentItem.value) {
        return null;
      }
      return currentItem.value.stats;
    });

    const currentMarketInventory = computed(() => {
      let results = store.state.repair.marketData;
      return results;
    });

    /**
     * Watchers
     */

    watch(
      currentPrice,
      // (messages, prevMessages) => {
      () => {
        store.commit("setCurrentSellPrice", currentPrice.value);
      }
    );

    onMounted(async () => {
      await checkRepairApprovalDcar();
      await checkRepairApprovalNft();
      dcarPerDollar.value = await getDCARPricePerDollar();
      await calculateBasePrice();
      await updateCurrentPriceDollar();
    });

    return {
      /* Data */
      isVisible,
      isInventoryOpen,
      currentPrice,
      basePrice,
      currentPriceDollar,
      approvedRepairNft,
      approvingRepairNft,
      approvedRepairDcar,
      approvingRepairDcar,
      successContract,
      dcarApprovalAmount,

      /* Methods */
      hovered,
      unhovered,
      hide,
      createRepairContract,
      updateCurrentPriceDollar,
      repairApprovalDcar,
      repairApprovalNft,

      /* Computed Methods */
      character,
      isWandering,
      isLoading,
      totalPrice,
      currentClass,
      currentItem,
      currentImage,
      itemStats,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.successMessage {
  font-size: 80%;
  width: 90%;
  margin: 16px auto;
  font-family: "IM Fell English", serif;
}

.repair-sell-wrapper {
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;

  &.show {
    opacity: 1;
    pointer-events: all;

    transition: opacity 0.35s linear;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.35s linear;
  }
}

.repair-sell-menu {
  background: url("https://cdn.dragoncrypto.io/uiassets/tooltip.png") no-repeat
    top left;
  width: 298px;
  height: 334px;
  position: relative;

  .close-button {
    position: absolute;
    top: -2px;
    right: -2px;
  }

  .broken {
    color: $dmg;
  }

  .title {
    padding-top: 8px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: center;
    border-bottom: 1px solid #3d3d40;

    &.legendary {
      // .img-wrapper{

      // }

      h2 {
        color: $legendary;
      }
    }

    &.fabled {
      h2 {
        color: $fabled;
      }
    }

    &.unique {
      h2 {
        color: $unique;
      }
    }

    &.uncommon {
      h2 {
        color: $uncommon;
      }
    }

    &.shoddy {
      h2 {
        color: $shoddy;
      }
    }

    &.rare {
      h2 {
        color: $rare;
      }
    }

    &.mythical {
      h2 {
        color: $mythical;
      }
    }

    &.epic {
      h2 {
        color: $epic;
      }
    }

    &.artefact {
      h2 {
        color: $artefact;
      }
    }

    img {
      max-width: 45px;
      margin-right: 12px;
    }

    h2 {
      color: #fff;
      text-align: left;
      font-family: "IM Fell English", serif;
      font-size: 90%;
      text-transform: none;
      width: 75%;
    }
  }

  .inputs,
  .sell-price-listing {
    width: 90%;
    margin: 16px auto;
    font-family: "IM Fell English", serif;
    font-size: 90%;

    &:after {
      background: url("../../assets/ui/dcar.png") no-repeat left center;
      background-size: contain;
    }

    label {
      &:after {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 0;
        margin-top: -8px;
        padding: 0;
        top: 7px;
        position: relative;
        margin-left: 4px;
      }
    }

    input {
      width: 64px;
      font-size: 105%;
      margin-left: 16px;
    }

    &.dcar {
      label {
        &:after {
          background: url("../../assets/ui/dcar.png") no-repeat left center;
          background-size: contain;
        }
      }
    }
  }

  .dollar-value {
    font-size: 0.7rem;
    color: #ffffff85;
    position: relative;
    top: 0px;
    width: 80%;
    margin: 0 auto;
    display: block;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 9px;

    .dialog-button {
      margin-top: 0;
    }
  }
}
</style>
