<template>
  <div :class="'wrapper ' + getClass">
    <div class="quests-panel">
      <div class="close-button button-small" @click="hide">
        <span>x</span>
      </div>
      <div class="content">
        <div class="left">
          <div
            v-on:click="selectQuest(index)"
            v-for="(quest, index) in currentQuests"
            :key="quest.id"
          >
            <h3 :class="quest.isFinished ? 'completedQuest' : ''">
              {{ quest.name }}
            </h3>
          </div>
        </div>
        <div class="right">
          <div v-if="currentQuests && currentQuests.length > 0">
            <h3>
              {{ currentQuests[currentSelectedQuest].name }}
              <template v-if="currentQuests[currentSelectedQuest].isFinished"
                >- <span class="completed">Completed</span></template
              >
            </h3>
            <h4>Steps:</h4>
            <h5
              :class="
                index < currentQuests[currentSelectedQuest].currentStep ||
                currentQuests[currentSelectedQuest].steps[index].isFinished
                  ? 'complete'
                  : 'incomplete'
              "
              v-for="(questStep, index) in currentQuests[currentSelectedQuest]
                .steps"
              :key="questStep.description"
            >
              {{ questStep.description }}
              <template v-if="questStep.remaining && questStep.amount">
                ({{ questStep.amount - questStep.remaining }}/{{
                  questStep.amount
                }})
              </template>
            </h5>
            <h4 class="desc">Description:</h4>
            <div class="desc-text">
              <p>{{ currentQuests[currentSelectedQuest].descLine2 }}</p>
              <p>{{ currentQuests[currentSelectedQuest].steps[0].clue }}</p>
            </div>
            <h4>Rewards:</h4>
            <div class="rewards">
              <div
                v-for="item in currentQuests[currentSelectedQuest].rewards"
                :key="item"
              >
                <img :src="this.getRewardImage(item)" :alt="item" />
              </div>
            </div>
            <DialogButton
              v-if="
                currentQuests &&
                currentQuests.length > 0 &&
                currentQuests[currentSelectedQuest].isFinished &&
                !currentQuests[currentSelectedQuest].hasClaimed
              "
              @click="claimQuest(currentQuests[currentSelectedQuest].id)"
              :buttonText="'Claim Rewards'"
              :buttonSize="'med'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import Constants from "../consts/constants";
import DialogButton from "./DialogButton.vue";

export default {
  name: "Quests",
  components: { DialogButton },
  props: {},
  data() {
    return {};
  },
  methods: {
    hide() {
      this.$store.commit("setQuestsVisible", false);
    },
    selectQuest(index) {
      this.$store.commit("setSelectedQuest", index);
    },
    claimQuest(id) {
      const payload = {
        questId: id,
        number:
          this.$store.state.characters[this.$store.state.currentCharacter]
            .number,
      };
      this.$store.commit("setClaimQuest", payload);
    },
    getRewardImage(type) {
      switch (type) {
        case Constants.questRewards.exp:
          return "https://cdn.dragoncrypto.io/icons/exp-bonus.png";
        case Constants.questRewards.loot:
          return "https://ik.imagekit.io/dcg/equip/Loot_102.png?tr=w-90";
        default:
          break;
      }

      return "";
    },
  },
  computed: {
    getClass() {
      return this.$store.state.isQuestsVisible ? "show" : "hide";
    },
    currentSelectedQuest() {
      return this.$store.state.currentSelectedQuest;
    },
    currentQuests() {
      if (
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          null ||
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          "undefined"
      ) {
        return [];
      }
      // console.log(
      //   this.$store.state.characters[this.$store.state.currentCharacter].quests
      // );
      return this.$store.state.quests;
    },
  },
  mounted() {
    this.$store = useStore();
    this.$store.watch(
      (state) =>
        state.characters[state.currentCharacter] != null
          ? state.characters[state.currentCharacter].stats
          : null,
      () => {
        this.selectQuest(0);
      }
    );
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: all;

    transition: opacity 0.35s linear;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.35s linear;
  }

  .quests-panel {
    width: 738px;
    height: 641px;
    background: url("https://cdn.dragoncrypto.io/uiassets/quest-log.png")
      no-repeat top left;
    position: relative;

    .close-button {
      position: absolute;
      top: 48px;
      right: 0;
    }

    .content {
      position: absolute;
      top: 117px;
      left: 25px;
      width: 699px;
      height: 504px;

      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      h3,
      h4 {
        font-family: "IM Fell English", serif;
        text-transform: none;
        text-align: left;
      }

      h3 {
        font-size: 95%;
      }

      .left {
        width: 305px;
        height: 430px;
        margin-right: 10px;
        overflow: scroll;
        &::-webkit-scrollbar {
          width: 15px;
          height: 0px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-button {
          background-color: transparent;
          width: 15px;
          height: 0px;
        }

        &::-webkit-scrollbar-thumb {
          background: none;
        }

        h3 {
          color: #af8670;
          background: url("https://cdn.dragoncrypto.io/uiassets/quest-header-bg.png")
            no-repeat top left;
          margin: 6px 4px;
          line-height: 32px;
          padding-left: 38px;
        }

        h3.completedQuest {
          color: greenyellow;
        }
      }

      .right {
        width: 373px;

        h3,
        h4,
        h5,
        p {
          color: #360907;
          text-align: left;
        }

        h3 {
          display: inline-block;
          font-weight: bold;
          text-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
          font-size: 130%;
          margin: 16px;
          padding-bottom: 16px;
          width: 335px;
          background: url("https://cdn.dragoncrypto.io/uiassets/inventory-header-div.png")
            no-repeat bottom center;
          margin-bottom: 8px;
        }

        h4 {
          background: url("https://cdn.dragoncrypto.io/uiassets/quest-bullet.png")
            no-repeat center left;
          font-size: 100%;
          margin: 0 16px 8px 16px;
          padding-left: 24px;
          text-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
          font-weight: bold;
          display: inline-block;
          width: 335px;

          &.desc {
            margin-top: 16px;
            margin-bottom: 0;
          }
        }

        h5 {
          margin: 4px 24px;
          padding-left: 28px;
          line-height: 24px;

          &.incomplete {
            background: url("https://cdn.dragoncrypto.io/uiassets/quest-step-incomplete.png")
              no-repeat center left;
          }

          &.complete {
            background: url("https://cdn.dragoncrypto.io/uiassets/quest-step-complete.png")
              no-repeat center left;
          }
        }

        .desc-text {
          margin: 16px;
          border: 1.5px solid rgba(0, 0, 0, 0.15);
          border-radius: 3px;
          padding: 10px;
          background: rgba(0, 0, 0, 0.05);

          p {
            font-family: "Lato", sans-serif;
            font-size: 90%;
            margin-top: 0;
          }
        }

        .rewards {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 6px 16px 16px 16px;

          div {
            background: #000;
            border-radius: 2px;
            margin-right: 12px;
            padding: 0;

            display: flex;
            align-items: center;
            justify-content: center;
            border: 1.5px solid $uncommon;
            box-shadow: 0 0 3px rgba(0, 0, 0, 1), 0 0 3px rgba(0, 0, 0, 1);
          }

          img {
            max-width: 52px;
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
