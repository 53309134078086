<template>
  <div :class="getClass">
    <div class="inner-dialog-wrapper">
      <GameMessage :heading="'The Wild Boar Inn'" :icon="'drunkenness_nobg'">
        <p>
          You push through the doors and are overwhelmed by smell of stale booze
          and sweaty warriors.
        </p>
        <p>"This is my kind of place!"</p>
      </GameMessage>
      <div class="buttons">
        <div id="get-a-room-button">
          <DialogButton
            v-if="!isDCAUApprovedForInn"
            @click="approveDCAU()"
            :buttonText="'Approve DCAU For Room'"
            :isLoading="isApprovingDCAU"
          />
          <DialogButton
            v-else
            @click="processInnRest()"
            :buttonText="
              innRestPrice
                ? `Get a Room (${innRestPrice} DCAU)`
                : 'Calculating Price...'
            "
            :isLoading="isResting || isFetchingRestPrice"
            :disabled="isResting || isSinging || isFetchingRestPrice"
          />
        </div>
        <div id="bard-song-button">
          <DialogButton
            v-if="!isDCAUApprovedForBard"
            @click="approveDCAU()"
            :buttonText="'Approve DCAU For Song'"
            :isLoading="isApprovingDCAU"
          />
          <DialogButton
            v-else
            @click="processBardSong()"
            :buttonText="
              bardSongPrice
                ? `Buy a Song (${bardSongPrice} DCAU)`
                : 'Calculating Price...'
            "
            :isLoading="isSinging || isFetchingSongPrice"
            :disabled="isResting || isSinging || isFetchingSongPrice"
          />
        </div>

        <DialogButton
          v-if="hasQuest"
          @click="$emit('doQuestStepBard')"
          :buttonText="'Talk to the Bard (QUEST)'"
          :disabled="isResting || isSinging"
        />
        <router-link class="link" to="/mint-games/dragon-fire-lottery"
          ><DialogButton
            :buttonText="'Head to the Back Room'"
            id="head-to-backroom-button"
            title="Head To The Backroom"
        /></router-link>

        <!-- <DialogButton
          @click="$emit('den')"
          :buttonText="'Head to the Back Room'"
          id="head-to-backroom-button"
          title="Head To The Backroom"
        /> -->
        <DialogButton
          @click="$emit('leaveInn')"
          :disabled="isResting || isSinging"
          :buttonText="'Leave'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import Constants from "../consts/constants";
import DialogButton from "./DialogButton.vue";
import GameMessage from "./GameMessage.vue";

import { ethers } from "ethers";
import { usePrice } from "../composables/price";
import { useUser } from "../composables/user";
import {
  getDCAUContract,
  getLoadInnContract,
  getLoadPriceHandlerContract,
} from "../utils/getContract";
import { parseError } from "../utils/helpers";

export default {
  name: "Inn",
  components: {
    GameMessage,
    DialogButton,
  },
  emits: ["song", "rest", "doQuestStepBard", "den", "leaveInn"],
  props: {},
  setup(props, { emit }) {
    /**
     * Data
     */
    const store = useStore();
    const { provider, address, signer } = useUser();
    const innRestPrice = ref(0);
    const bardSongPrice = ref(0);
    const refreshTimer = ref(null);
    const isResting = ref(false);
    const isSinging = ref(false);
    const isApprovingDCAU = ref(false);
    const isDCAUApprovedForInn = ref(true);
    const isDCAUApprovedForBard = ref(true);
    const { updateBalances } = usePrice();
    const isFetchingRestPrice = ref(true);
    const isFetchingSongPrice = ref(true);

    const DCAUContract = getDCAUContract(signer.value);
    const InnContract = getLoadInnContract(signer.value);

    // store unwatchers
    let unWatchCharacterId = null;

    onMounted(async () => {
      await checkIsDCAUApproved();

      unWatchCharacterId = store.watch(
        (state) => state.currentCharacterId,
        async (value) => {
          if (value) {
            await checkIsDCAUApproved();
            await getInnRestPrice();
            await getBardSongPrice();
          }
        }
      );

      // Timer to refresh values every minute
      refreshTimer.value = setTimeout(async () => {
        await getInnRestPrice();
        await getBardSongPrice();
      }, 60000);
    });

    onUnmounted(() => {
      clearTimeout(refreshTimer.value);
      unWatchCharacterId();
    });

    /**
     * Methods
     */
    /**
     * Method to check if DCAU is approved
     */
    const checkIsDCAUApproved = async () => {
      await getInnRestPrice();
      await getBardSongPrice();

      let allowanceInn = await DCAUContract.allowance(
        address.value,
        InnContract.address
      );
      allowanceInn = ethers.utils.formatEther(allowanceInn);

      isDCAUApprovedForInn.value =
        parseFloat(allowanceInn) >= parseFloat(innRestPrice.value);
      isDCAUApprovedForBard.value =
        parseFloat(allowanceInn) >= parseFloat(bardSongPrice.value);
      // if (parseFloat(allowanceInn) >= parseFloat(innRestPrice.value)) {
      //   isDCAUApproved.value = false;
      // } else {
      //   isDCAUApproved.value = true;
      // }
    };

    /**
     * Approve DCAU Method
     */
    const approveDCAU = async () => {
      isApprovingDCAU.value = true;
      try {
        let txApproveInnSpend = await DCAUContract.approve(
          InnContract.address,
          ethers.utils.parseEther("1000000000")
        );
        const receipt = await txApproveInnSpend.wait();
        if (receipt.status === 1) {
          const allowanceInn = ethers.utils.formatEther(
            receipt.events[0].args.value
          );
          isDCAUApprovedForInn.value =
            parseFloat(allowanceInn) >= parseFloat(innRestPrice.value);
          isDCAUApprovedForBard.value =
            parseFloat(allowanceInn) >= parseFloat(bardSongPrice.value);
        }
      } catch (error) {
        console.log(error);
      }
      isApprovingDCAU.value = false;
    };

    /**
     * Fetching inn rest price via server cause level is needed
     */
    const getInnRestPrice = async () => {
      isFetchingRestPrice.value = true;
      const sessionId = localStorage.getItem("sessionId");
      const heroId = character.value.number;
      const response = await fetch(
        Constants.apiUrl + "contract/get-inn-rest-price",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId,
            heroId,
            account: address.value,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        innRestPrice.value = parseFloat(data.price).toFixed(4);
      }
      isFetchingRestPrice.value = false;
    };

    /**
     * Fetching from the contract directly
     */
    const getBardSongPrice = async () => {
      isFetchingSongPrice.value = true;
      try {
        const PriceHandler = getLoadPriceHandlerContract(provider.value);
        const bardSongCost = await PriceHandler.bardSongCost();
        bardSongPrice.value = parseFloat(
          ethers.utils.formatEther(bardSongCost)
        ).toFixed(4);
      } catch (error) {
        console.log(error);
      }
      isFetchingSongPrice.value = false;
    };

    const getSignedDataForBardSong = async () => {
      const sessionId = localStorage.getItem("sessionId");
      const heroId = character.value.number;
      const songId = "1";
      const response = await fetch(
        Constants.apiUrl + "contract/sign-inn-bard-song",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId,
            heroId,
            songId,
            account: address.value,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      return { heroId, songId, signature: data.signature, nonce: data.nonce };
    };

    const getSignedDataForInnRest = async () => {
      const sessionId = localStorage.getItem("sessionId");
      const heroId = character.value.number;
      const restId = "1";
      const response = await fetch(
        Constants.apiUrl + "contract/sign-inn-rest",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId,
            heroId,
            restId,
            account: address.value,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log(character.value.stats.level);
      return {
        level: character.value.stats.level,
        heroId,
        restId,
        signature: data.signature,
        nonce: data.nonce,
      };
    };

    /**
     * Method to interact with contract to use bard song
     */
    const processBardSong = async () => {
      isSinging.value = true;
      const { heroId, songId, signature, nonce } =
        await getSignedDataForBardSong();
      console.log(heroId, songId, signature, nonce);
      try {
        // Executing Bard Song Transaction
        const tx = await InnContract.bardSong(heroId, songId, signature, nonce);
        const receipt = await tx.wait();

        // Only process bard song if the transaction was successful!
        if (receipt.status === 1) {
          updateBalances(); //Asynchronously updating token balances;
          // TODO: MOVE THE BARD SONG API CALL TO SERVER, VERIFY TRANSACTION AND THEN PROCESS
          // emit("song");
          store.commit("setBardsSong", receipt.blockNumber);
        }
        isSinging.value = false;
      } catch (error) {
        console.log(error);
        store.commit("setNotification", parseError(error));
        isSinging.value = false;
      }
    };

    /**
     * Method to interact with contract to use inn rest
     */
    const processInnRest = async () => {
      isResting.value = true;
      const { level, heroId, restId, signature, nonce } =
        await getSignedDataForInnRest();
      console.log(heroId, restId, signature, nonce);
      try {
        // Executing Inn Rest Transaction
        const tx = await InnContract.rest(
          level,
          heroId,
          restId,
          signature,
          nonce
        );
        const receipt = await tx.wait();

        // Only process Inn rest if the transaction was successful!
        if (receipt.status === 1) {
          updateBalances(); //Asynchronously updating token balances;
          // TODO: MOVE THE INN REST API CALL TO SERVER, VERIFY TRANSACTION AND THEN PROCESS
          emit("rest");
          const payload = {
            heroId,
            blockNumber: receipt.blockNumber,
          };
          store.commit("setSleeping", payload);
        }
        isResting.value = false;
      } catch (error) {
        console.log(error);
        store.commit("setNotification", parseError(error));
        isResting.value = false;
      }
    };

    /**
     * Computed Methods
     */
    const getClass = computed(() => {
      return (
        "inn-wrapper wrap-content " +
        (store.state.gameState == Constants.gamemodes.inn ? "show" : "hide")
      );
    });
    const hasQuest = computed(() => {
      const currentCharacter =
        store.state.characters[store.state.currentCharacter];

      if (currentCharacter == null || currentCharacter == "undefined") {
        return false;
      }

      if (currentCharacter.quests && currentCharacter.quests.length > 0) {
        const questsForInnNpcs = currentCharacter.quests.filter((q) =>
          q.details.steps.find(
            (s) =>
              s.type == Constants.questTypes.talkNpc &&
              s.talkToNpc == Constants.questNpcs.theBard
          )
        );

        for (let q = 0; q < questsForInnNpcs.length; q++) {
          const questForInnNpc = questsForInnNpcs[q];

          if (!questForInnNpc.isFinished) {
            const questStep =
              questForInnNpc.details.steps[questForInnNpc.currentStep];

            if (
              !questForInnNpc.isFinished &&
              questStep.type == Constants.questTypes.talkNpc &&
              questStep.talkToNpc == Constants.questNpcs.theBard
            ) {
              if (
                questStep.requiresItemId &&
                store.state.inventory.find(
                  (i) => i.id == questStep.requiresItemId
                )
              ) {
                return true;
              }
            }
          }
        }
      }

      return false;
    });
    const character = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });
    return {
      // Data
      innRestPrice,
      bardSongPrice,
      isResting,
      isSinging,
      isApprovingDCAU,
      isDCAUApprovedForInn,
      isDCAUApprovedForBard,
      isFetchingRestPrice,
      isFetchingSongPrice,

      // Methods
      processBardSong,
      processInnRest,
      approveDCAU,

      // Computed
      getClass,
      hasQuest,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.inn-wrapper {
  background: no-repeat url("../assets/locationBackgrounds/InnBG.webp") center
    center;
  background-size: cover;
  background-color: #b04621;
}

.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;
}

.highlight {
  font-weight: bold;
  color: $dmg;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  font-size: 90%;
}
</style>
