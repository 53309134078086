<template>
  <div class="mobile-btn-container">
    <DialogButton
      :buttonSize="'med'"
      :buttonText="
        isInventoryVisibleMobile ? 'Hide Inventory' : 'Show Inventory'
      "
      class="toggle-inventory"
      @click="toggleInventory"
      v-if="buttonVisible"
    />
    <DialogButton
      :buttonSize="'med'"
      :buttonText="viewMyItems ? 'Go Back' : 'My Listings'"
      class="toggle-mobile-marketplace"
      @click="toggleMarketplaceView"
      v-if="buttonVisible"
    />
  </div>
  <div :class="currentClass">
    <div class="inner-dialog-wrapper">
      <BasicPopup
        header="Marketplace"
        :class="'marketplace-content ' + (isInventoryOpen ? 'inventory' : '')"
      >
        <div class="close-button button-small" @click="hide">
          <span>x</span>
        </div>
        <div class="filters-content">
          <img
            :class="getClassAll"
            src="https://cdn.dragoncrypto.io/uiassets/inventory-filter-all.png"
            alt="All"
            @click="filterAll"
          />
          <img
            :class="getClassArmour"
            src="https://cdn.dragoncrypto.io/uiassets/inventory-filter-armour.png"
            alt="Armour"
            @click="filterArmour"
          />
          <img
            :class="getClassWeapons"
            src="https://cdn.dragoncrypto.io/uiassets/inventory-filter-weapons.png"
            alt="Weapons"
            @click="filterWeapons"
          />
          <img
            :class="getClassConsumables"
            src="https://cdn.dragoncrypto.io/uiassets/inventory-filter-consumables.png"
            alt="Consumables"
            @click="filterConsumables"
          />
          <img
            :class="getClassResources"
            src="https://cdn.dragoncrypto.io/uiassets/inventory-filter-resources.png"
            alt="Resources"
            @click="filterResources"
          />
          <img
            :class="getClassQuest"
            src="https://cdn.dragoncrypto.io/uiassets/inventory-filter-quest.png"
            alt="Resources"
            @click="filterQuest"
          />
          <!-- <img src="https://cdn.dragoncrypto.io/uiassets/inventory-filter-quest.png" alt="Quest" /> -->
        </div>
        <div class="sorting-filters">
          <button
            :class="['toggle-marketplace', viewMyItems ? 'back' : '']"
            @click="toggleMarketplaceView"
          >
            {{ viewMyItems ? "Go Back" : "My Listings" }}
          </button>
          <div class="order">
            <label for="order-by">
              Order by
              <select v-model="searchSorting" id="order-by" name="order-by">
                <option>Recent</option>
                <option>Oldest</option>
                <option>Price Desc</option>
                <option>Price Asc</option>
                <option>Rarity Desc</option>
                <option>Rarity Asc</option>
                <option>Attack Desc</option>
                <option>Defense Desc</option>
                <option>Magic Desc</option>
                <option>Speed Desc</option>
                <option>Crafting Desc</option>
                <option>Gathering Desc</option>
                <option>Health Desc</option>
                <option>Crit Chance Desc</option>
              </select>
            </label>
          </div>
          <div class="search">
            <label for="search">
              <span>Search</span>
              <input
                v-model="currentSearchTerm"
                name="search"
                id="search"
                type="text"
                @keyup="searchTimeOut"
                placeholder="Search By Name"
              />
            </label>
          </div>
        </div>
        <div v-if="viewMyItems" class="items">
          <h3 v-if="currentMyListedItems.length === 0">No Items Listed</h3>
          <div class="grid">
            <ListedItem
              v-for="marketItem in currentMyListedItems"
              :item="marketItem"
              :key="marketItem.id"
              :isOwner="true"
            />
          </div>
          <DialogButton
            v-if="
              currentMyListedItems.length !== 0 &&
              currentMyListedItems.length >= 16
            "
            :buttonSize="'med'"
            :buttonText="loadedAllItems ? 'No More Items' : 'Load More'"
            class="load-more"
            :disabled="loadedAllItems"
            @click="loadMoreMyListedItems"
            :isLoading="loadingMoreItems"
          />
        </div>
        <div v-else :class="['items']">
          <template v-if="!loading">
            <h3 v-if="currentMarketInventory.length === 0">No Items Listed</h3>
            <div class="grid">
              <ListedItem
                v-for="marketItem in currentMarketInventory"
                :item="marketItem"
                :key="marketItem.id"
              />
            </div>
            <DialogButton
              v-if="
                currentMarketInventory.length !== 0 &&
                currentMarketInventory.length >= 16
              "
              :buttonSize="'med'"
              :buttonText="loadedAllItems ? 'No More Items' : 'Load More'"
              class="load-more"
              :disabled="loadedAllItems"
              @click="loadMoreMarketItems"
              :isLoading="loadingMoreItems"
            />
          </template>
          <GenericLoading v-else />
        </div>
        <router-link to="/marketplace" custom v-slot="{ navigate }">
          <DialogButton
            class="advanced-button"
            :buttonText="'Advanced'"
            buttonSize="small"
            @click="navigate"
            role="link"
          />
        </router-link>
      </BasicPopup>
    </div>
  </div>
  <ListMarketItem v-if="isMarketplaceSelling" />
</template>

<script>
import { io } from "socket.io-client";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";
import Constants from "../../consts/constants";
import DialogButton from "../DialogButton.vue";
import GenericLoading from "../LoadingComponents/GenericLoading.vue";
import BasicPopup from "../Popup/BasicPopup.vue";
import ListMarketItem from "./ListMarketItem.vue";
import ListedItem from "./ListedItem.vue";

// TODO need to improve marketplace
export default {
  name: "Marketplace",
  components: {
    ListedItem,
    BasicPopup,
    DialogButton,
    GenericLoading,
    ListMarketItem,
  },
  setup() {
    const store = useStore();
    const character = ref(null);
    const account = ref("");
    const socket = io(Constants.apiUrl, { transports: ["websocket"] });

    // const filterType = ref("");
    const currentSearchTerm = ref("");
    const searchSorting = ref("Recent");
    const marketplaceItems = ref([]);
    const loading = ref(false);
    const loadingMoreItems = ref(false);
    const page = ref(1);
    const loadedAllItems = ref(false);
    const viewMyItems = ref(false);
    const searchDelayTimer = ref(null);

    /**
     * Computed Methods
     */
    const filterType = computed(() => {
      return store.state.marketplace.marketFilter;
    });
    const sortBy = computed(() => {
      return store.state.marketplace.sortBy;
    });
    const order = computed(() => {
      return store.state.marketplace.order;
    });
    const isInventoryOpen = computed(() => {
      return store.state.isInventoryVisible;
    });

    const isInventoryVisibleMobile = computed(() => {
      return store.state.marketplace.showInventory;
    });
    const getClassAll = computed(() => {
      if (filterType.value == Constants.filterTypes.all) {
        return "selected";
      }

      return "";
    });
    const getClassArmour = computed(() => {
      if (filterType.value == Constants.filterTypes.armour) {
        return "selected";
      }
      return "";
    });
    const getClassWeapons = computed(() => {
      if (filterType.value == Constants.filterTypes.weapons) {
        return "selected";
      }

      return "";
    });
    const getClassConsumables = computed(() => {
      if (filterType.value == Constants.filterTypes.consumables) {
        return "selected";
      }

      return "";
    });
    const getClassResources = computed(() => {
      if (filterType.value == Constants.filterTypes.resources) {
        return "selected";
      }

      return "";
    });
    const getClassQuest = computed(() => {
      if (filterType.value == Constants.filterTypes.quest) {
        return "selected";
      }

      return "";
    });
    const buttonVisible = computed(() => {
      return store.state.gameState == Constants.gamemodes.marketplace;
    });
    const currentClass = computed(() => {
      return (
        "marketplace-wrapper wrap-content " +
        (store.state.gameState == Constants.gamemodes.marketplace
          ? "show"
          : "hide")
      );
    });
    const marketData = computed(() => {
      return store.state.marketData;
    });

    const marketItemList = computed(() => {
      return marketplaceItems.value;
    });

    const currentMarketInventory = computed(() => {
      let results = store.state.marketplace.marketData;
      return results;
    });
    const currentMyListedItems = computed(() => {
      let results = store.state.marketplace.myListedItemsData;
      return results;
    });

    const isMarketplaceSelling = computed(() => {
      return store.state.isMarketSelling;
    });

    /**
     * Watchers
     */
    /**
     * Watching messages.
     * After every change in message, scrolling to the bottom of the message area.
     * Also assigning read message to the latest message count if chat open
     */
    watch(
      searchSorting,
      (value) => {
        store.dispatch("marketplace/processSorting", value);
        getMarketItems();
      },
      { deep: true }
    );

    /**
     * Methods
     */
    /**
     * By default always fetching page 1 of the respective type but maintaing the sort type
     */
    const searchTimeOut = () => {
      if (searchDelayTimer.value) {
        clearTimeout(searchDelayTimer.value);
        searchDelayTimer.value = null;
      }
      searchDelayTimer.value = setTimeout(() => {
        console.log(currentSearchTerm.value);
        store.commit("marketplace/setSearchTerm", currentSearchTerm.value);
        getMarketItems();
      }, 500);
    };
    const myListedItems = async () => {
      loading.value = true;
      await store.dispatch("marketplace/updateMyListedItems"); // Moved this to store cause need to call it from list market item as well
      loading.value = false;
      resetFilter();
    };

    /**
     * By default always fetching page 1 of the respective type but maintaing the sort type
     */
    const getMarketItems = async () => {
      loading.value = true;
      await store.dispatch("marketplace/updateMarketplaceItems");
      loading.value = false;
      resetFilter();
    };

    /**
     * Fetching the respective next page and maintaing everything else.
     * Then processing the data and adding it to existing data
     */
    const loadMoreMarketItems = async () => {
      loadingMoreItems.value = true;
      const marketplaceApiQuery = `marketplace/get-items?type=${
        filterType.value
      }&search=${currentSearchTerm.value}&page=${++page.value}&sortBy=${
        sortBy.value
      }&order=${order.value}`;
      const response = await fetch(Constants.apiUrl + marketplaceApiQuery);
      const data = await response.json();
      if (data.success) {
        if (data.marketplaceItems.length === 0) {
          loadedAllItems.value = true;
        } else {
          store.dispatch(
            "marketplace/loadMoreMarketItems",
            data.marketplaceItems
          );
        }
      }
      loadingMoreItems.value = false;
    };
    const loadMoreMyListedItems = async () => {
      loadingMoreItems.value = true;

      const marketplaceApiQuery = `marketplace/my-listed-items?type=${
        filterType.value
      }&page=${++page.value}&sortBy=${sortBy.value}&order=${order.value}`;
      const response = await fetch(Constants.apiUrl + marketplaceApiQuery, {
        method: "POST",
        body: JSON.stringify({
          account: account.value,
          sessionId: localStorage.getItem("sessionId"),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (data.success) {
        if (data.marketplaceItems.length === 0) {
          loadedAllItems.value = true;
        } else {
          store.dispatch(
            "marketplace/loadMoreMyListedItems",
            data.marketplaceItems
          );
        }
      }

      loadingMoreItems.value = false;
    };

    const toggleMarketplaceView = async () => {
      viewMyItems.value = !viewMyItems.value;
      if (viewMyItems.value) {
        console.log("Switching to listed items");
        await myListedItems();
      } else {
        console.log("Switching to marketplace");
        await getMarketItems();
      }
      resetFilter();
    };
    const resetFilter = () => {
      loadedAllItems.value = false;
      page.value = 1;
    };
    const hideInventory = () => {
      store.commit("setInventoryVisible", false);
    };
    const filterAll = async () => {
      await filterItems(Constants.filterTypes.all);
    };
    const filterArmour = async () => {
      await filterItems(Constants.filterTypes.armour);
    };
    const filterWeapons = async () => {
      await filterItems(Constants.filterTypes.weapons);
    };
    const filterConsumables = async () => {
      await filterItems(Constants.filterTypes.consumables);
    };
    const filterResources = async () => {
      await filterItems(Constants.filterTypes.resources);
    };
    const filterQuest = async () => {
      await filterItems(Constants.filterTypes.quest);
    };
    const filterItems = async (filter) => {
      // filterType.value = filter;
      store.commit("marketplace/setMarketFilter", filter);
      if (viewMyItems.value) {
        await myListedItems();
      } else {
        await getMarketItems();
      }
    };
    const hide = () => {
      store.commit("hideMarketPlace");
      store.commit("marketplace/setShowInventoryState", false); // Hiding in mobile view as well
    };
    const toggleInventory = () => {
      const inventoryOpen = store.state.isInventoryVisible;
      if (!inventoryOpen) {
        store.commit("setInventoryVisible", true);
        store.commit("marketplace/setShowInventoryState", true);
      } else {
        store.commit(
          "marketplace/setShowInventoryState",
          !store.state.marketplace.showInventory
        ); // not showing inventory by default for mobile
      }
    };

    onUnmounted(() => {
      socket.removeAllListeners("new-message");
      socket.disconnect();
    });

    onMounted(() => {
      // Set the character to the current character from store
      currentCharacter();
      account.value = store.state.account;
      // filterType.value = store.state.marketplace.marketFilter;

      /**
       * Pooling server to catch any new item list event being generated
       * Refetch market items if a new item is listed
       */
      socket.on("new-listing", () => {
        getMarketItems();
      });

      socket.io.on("error", () => {
        console.log(
          "Unable to connect to server. Please try again or let the team know"
        );
      });

      getMarketItems();
    });

    const currentCharacter = () => {
      character.value = store.state.characters[store.state.currentCharacter];
    };

    return {
      /* All the computed values */
      isInventoryOpen,
      isInventoryVisibleMobile,
      getClassAll,
      getClassArmour,
      getClassWeapons,
      getClassConsumables,
      getClassResources,
      getClassQuest,
      currentClass,
      marketData,
      currentMarketInventory,
      currentMyListedItems,
      marketItemList,
      buttonVisible,

      /* All the methods */
      hideInventory,
      filterAll,
      filterArmour,
      filterWeapons,
      filterConsumables,
      filterResources,
      filterQuest,
      filterItems,
      hide,
      toggleInventory,
      loadMoreMarketItems,
      loadMoreMyListedItems,
      toggleMarketplaceView,
      myListedItems,
      searchTimeOut,

      /* All the data values */
      filterType,
      searchSorting,
      currentSearchTerm,
      loading,
      loadedAllItems,
      loadingMoreItems,
      viewMyItems,
      isMarketplaceSelling,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.marketplace-wrapper {
  background: no-repeat
    url("../../assets/locationBackgrounds/MarketplaceBG.webp") center center;
  background-size: cover;
}
.advanced-button {
  position: absolute;
  top: -41px;
  right: -36px;
  z-index: 5;
}
.wrap-content {
  z-index: 5 !important; // making marketplace on top of everything
}
.toggle-inventory {
  display: none;
  @media only screen and (max-width: 576px) {
    position: fixed;
    bottom: 20px;
    left: 10px;
    transform: scale(1) !important;
    opacity: 1 !important;
    z-index: 6;
    display: block;
  }
}
.toggle-mobile-marketplace {
  display: none;
  @media only screen and (max-width: 576px) {
    position: fixed;
    bottom: 20px;
    right: 10px;
    transform: scale(1) !important;
    opacity: 1 !important;
    z-index: 6;
    display: block;
  }
}
.marketplace-content {
  width: calc(756px - $popup-border-size - $popup-border-size);
  height: calc(600px - $popup-border-size - $popup-border-size);
  transition: left 0.3s ease-out;
  @media only screen and (max-width: 576px) {
    width: calc(100% - 134px);
    height: 60%;
  }

  &.inventory {
    left: 64%;
    transition: left 0.3s ease-out;
    @media only screen and (max-width: 576px) {
      left: 50%;
    }
  }
  button.toggle-marketplace {
    border: none;
    background-color: rgb(45 13 9);
    color: #d89967;
    padding: 7px;
    box-shadow: 0 0 1px 2px #000000a6;
    font-family: "IM Fell English", serif;
    font-size: 0.9rem;
    z-index: 6;
    width: 100px;
    font-weight: bold;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    &.back {
      background-color: #d89967;
      color: #000;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: rgb(82, 82, 82);
      color: #fff;
    }
    @media only screen and (max-width: 576px) {
      display: none;
    }
  }

  .items {
    width: calc(100% + $popup-border-offset);
    margin: 20px auto 0;
    left: -$popup-border-offset;
    position: relative;
    padding: 0 20px;
    overflow-y: scroll;
    height: 100%;
    align-items: start;
    align-content: start;
    .grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-gap: 1rem;
    }
    .load-more {
      margin: 20px 0;
    }
    &.loading {
      display: grid;
      align-items: center;
      justify-items: center;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 50px;
        background: red;
      }
    }
    @media only screen and (max-width: 576px) {
      height: 88%;
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 10px;
    }
  }
  .sorting-filters {
    display: grid;
    grid-gap: 1rem;
    position: absolute;
    grid-template-columns: 200px auto 1fr;
    height: 44px;
    width: calc(100% + $popup-border-offset * 2 - 20px);
    padding: 0 10px;
    left: -$popup-border-offset;
    top: 9px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    background: rgba(0, 0, 0, 0.5);
    font-size: 0.9rem;
    font-family: "IM Fell English", sans-serif;
    @media only screen and (max-width: 576px) {
      grid-template-columns: 1fr 1fr;
    }

    .search {
      width: 100%;
      span {
        @media only screen and (max-width: 576px) {
          display: none;
        }
      }
    }
    select,
    input {
      margin-left: 10px;
      font-family: "IM Fell English", sans-serif;
      font-size: 105%;
      padding: 4px;
    }

    input {
      background: rgba(0, 0, 0, 0.4);
      color: $game-heading;
      border: 1px solid transparentize($color: $game-heading, $amount: 0.5);
      border-radius: 2px;
    }

    select {
      background: transparent;
      border: none;
      color: $game-heading;

      option {
        color: #000;
        border: none;
      }
    }

    div + div {
      margin-left: 12px;
    }
  }

  .close-button {
    position: absolute;
    top: -$popup-border-size;
    right: -$popup-border-size;
  }
}

.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;
}

/* .filters { */
/* position: absolute;
  right: -54px;
  width: 58px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 576px) {
    position: absolute;
    width: calc(100% + 38px + 38px);
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    bottom: -38px;
    z-index: 20;
  } */

.filters-content {
  border: 1px solid #393939;
  background: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 58px;
  position: absolute;
  right: -127px;
  top: 50%;
  transform: translateY(-50%);

  @media only screen and (max-width: 576px) {
    border: 1px solid #393939;
    background: #00000080;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    width: calc(100% + 38px + 38px);
    height: 58px;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -38px;
    z-index: 20;
    top: auto;
  }
  /* } */

  img {
    cursor: pointer;
  }

  .selected {
    filter: brightness(2);
  }
}
</style>
