import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Game from "../views/Game.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Game,
  },
  {
    path: "/play-beta",
    name: "Play",
    redirect: "/",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/mint-games",
    name: "MintGames",
    component: () => import("../views/MintGames/MintGamesBase.vue"),
    children: [
      {
        path: "dcar-burn-battle",
        name: "DCARBurnBattle",
        component: () => import("../views/MintGames/DCARBattle.vue"),
      },
      {
        path: "dragon-fire-lottery",
        name: "DragonFireLottery",
        component: () => import("../views/MintGames/DragonFireLottery.vue"),
      },
      {
        path: "heroes-from-distant-lands-mint",
        name: "HeroesMint",
        component: () => import("../views/MintGames/MintVikings.vue"),
      },
      {
        path: "reveal",
        name: "RevealDistantHeroes",
        component: () => import("../views/MintGames/RevealDistantHeroes.vue"),
      },
      {
        path: "reveal-part-2",
        name: "RevealDistantHeroes2",
        component: () =>
          import("../views/MintGames/RevealDistantHeroesPart2.vue"),
      },
    ],
  },
  {
    path: "/mint",
    name: "Mint",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Mint.vue"),
  },
  {
    path: "/mintloot",
    name: "MintStarterKit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MintLoot.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/heroes",
    name: "Heroes",
    component: () => import("../views/Heroes.vue"),
  },
  {
    path: "/reveal",
    name: "Reveal",
    component: () => import("../views/Reveal.vue"),
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    component: () => import("../views/AdvancedMarketplace.vue"),
    children: [
      {
        path: "",
        name: "Marketplace Home",
        component: () =>
          import("../components/AdvancedMarketplace/MarketplaceHome.vue"),
      },
      {
        path: "/marketplace/:id",
        name: "Single Marketplace Item",
        component: () =>
          import("../components/AdvancedMarketplace/SingleMarketplaceItem.vue"),
      },
      {
        path: "/marketplace/seller/:address",
        name: "Marketplace Seller",
        component: () =>
          import("../components/AdvancedMarketplace/ItemsBySeller.vue"),
      },
      {
        path: "activity",
        name: "Marketplace Activity",
        component: () =>
          import("../components/AdvancedMarketplace/Activity.vue"),
      },
    ],
  },

  // {
  //   path: "/heroes/:id",
  //   name: "Heroes",
  //   component: () => import("../views/Heroes.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
