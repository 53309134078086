<template>
  <button
    id="fullscreen-button"
    :class="[fullScreen ? 'active' : '']"
    @click="toggleFullScreen"
    :title="fullScreen ? 'Exit Full Screen' : 'Go Full Screen'"
  >
    {{ fullScreen ? "Fullscreen" : "Exit Fullscreen" }}
  </button>
</template>
<script>
import { ref } from "vue";
import { useMixpanel } from "../composables/mixpanel";

export default {
  name: "VolumeButton",
  components: {},
  setup() {
    const fullScreen = ref(false);

    // Mixpanel Tracking
    const { trackEvent } = useMixpanel();

    const toggleFullScreen = async () => {
      const app = document.getElementById("app");
      if (fullScreen.value) {
        await document.exitFullscreen();
        fullScreen.value = false;
      } else {
        if (app.requestFullscreen) {
          app.requestFullscreen();
        } else if (app.webkitRequestFullscreen) {
          /* Safari */
          app.webkitRequestFullscreen();
        } else if (app.msRequestFullscreen) {
          /* IE11 */
          app.msRequestFullscreen();
        }
        fullScreen.value = true;
      }
      // Mixpanel tracking full screen
      trackEvent("Went Fullscreen", {
        state: fullScreen.value,
      });
    };
    return {
      //data
      fullScreen,
      //methods
      toggleFullScreen,
    };
  },
  props: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";
#fullscreen-button {
  position: fixed;
  z-index: 20;
  top: 5px;
  right: 7px;
  width: 40px;
  height: 40px;
  text-indent: -9999px;
  background: url("https://cdn.dragoncrypto.io/uiassets/fullscreen.svg") center
    center no-repeat;
  background-size: 70%;
  border: none;
  opacity: 0.7;
  border-radius: 100%;
  &.active {
    background: url("https://cdn.dragoncrypto.io/uiassets/fullscreen-exit.svg")
      center center no-repeat;
    background-size: 70%;
  }
  &:hover {
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    background-size: 70%;
    /* background-color: #140e09; */
    opacity: 1;
  }
}
</style>
