<template>
  <div
    :style="{ top: currentLocationY + 'px', left: currentLocationX + 'px' }"
    @mouseenter="hovered"
    @mouseleave="unhovered"
    :class="'popup-wrapper ' + currentClass + repairClass + guildContractClass"
  >
    <div
      v-if="currentItem && currentItem.rarity"
      :class="'title ' + currentItem.rarity.toLowerCase()"
    >
      <div class="img-wrapper">
        <img :src="currentImage" :alt="currentItem.name" />
      </div>
      <div class="right-wrapper">
        <h2 :class="currentItem.rarity.toLowerCase()">
          {{ currentItem.name }}
          <span class="broken" v-if="itemStats && itemStats.isBroken"
            >[Broken]</span
          >
        </h2>
        <div
          v-if="
            itemStats &&
            (itemStats.mastery || itemStats.weaponType || itemStats.element)
          "
          class="icons"
        >
          <img
            v-if="itemStats.element"
            class="icon"
            :src="
              'https://cdn.dragoncrypto.io/elements/' +
              itemStats.element +
              '.png'
            "
            :alt="itemStats.element"
            :title="'Element type of ' + itemStats.element"
          />
          <img
            class="icon"
            v-for="currentMastery in itemStats.mastery"
            :key="currentMastery"
            :src="
              'https://cdn.dragoncrypto.io/mastery/' + currentMastery + '.png'
            "
            :alt="currentMastery"
            :title="'Uses the ' + currentMastery + ' weapon mastery skill'"
          />
          <img
            v-if="itemStats.weaponType"
            class="icon"
            :src="
              'https://cdn.dragoncrypto.io/weapontypes/' +
              itemStats.weaponType +
              '.png'
            "
            :alt="itemStats.weaponType"
            :title="'Weapon type ' + itemStats.weaponType"
          />
        </div>
      </div>
    </div>
    <div class="blueprint" v-if="currentItem && currentItem.isBlueprint">
      <div class="blueprintSpan">
        <template v-if="currentItem.isBlueprint">
          <span class="desc">Crafts remaining</span>
          <span class="num">{{
            currentItem.numberCrafts - currentItem.craftsUsed
          }}</span>
        </template>
      </div>
    </div>
    <div class="stats" v-if="itemStats">
      <div class="stat">
        <template v-if="currentItem.rarity && itemStats.type != 'consumable'">
          <span class="desc">Rarity</span>
          <span class="num">{{ currentItem.rarity }}</span>
        </template>
        <template
          v-if="
            (itemStats.durability || itemStats.durability == 0) && !isInCraft
          "
        >
          <span class="desc">Durability</span>
          <span class="num"
            ><span
              class="addDurability"
              v-if="canUpgradeDurability"
              @click="showDurabilityUpgrade()"
              ><img src="@/assets/plus.png" alt="plus" />{{
                itemStats.durability
              }}/{{ itemStats.totalDurability }}</span
            >
            <span v-else>
              {{ itemStats.durability }}/{{ itemStats.totalDurability }}
            </span>
          </span>
        </template>
        <template v-if="!isInCraft">
          <span class="desc">Repair Limit</span>
          <span class="num">{{ currentBreakage }}%</span>
        </template>
        <template v-if="isWeapon">
          <span class="desc">Attack</span>
          <span class="num">
            {{ itemStats.attack }}
            <span
              class="compare"
              v-if="compareItemStats && compareItemStats.attack"
            >
              <span
                :class="
                  itemStats.attack - compareItemStats.attack < 0 ? 'red' : ''
                "
              >
                ({{ itemStats.attack - compareItemStats.attack }})
              </span>
            </span>
            <template v-if="isTwoHanded">
              <span class="two-handed"> (Two Handed) </span>
            </template>
          </span>
        </template>
        <template v-else-if="itemStats.defense && !isHoveredCraftItem">
          <span class="desc">Defense</span>
          <span class="num">
            {{ itemStats.defense }}
            <span
              class="compare"
              v-if="compareItemStats && compareItemStats.defense"
            >
              <span
                :class="
                  itemStats.defense - compareItemStats.defense < 0 ? 'red' : ''
                "
              >
                ({{ itemStats.defense - compareItemStats.defense }})
              </span>
            </span>
          </span>
        </template>
        <template v-if="currentItem.craftsRemaining">
          <span class="desc">Crafts Remaining</span>
          <span class="num">{{ currentItem.craftsRemaining }}</span>
        </template>
        <template v-if="healing">
          <span class="desc healing">Healing</span>
          <span class="num healing">{{ healing }}% of HP</span>
        </template>
        <template v-if="itemStats.craftingRequired">
          <span class="desc craft">Crafting Required</span>
          <span class="num craft"
            >{{ itemStats.craftingRequired }}
            <span
              class="compare"
              v-if="isHoveredRepairItem || isHoveredCraftItem"
            >
              <span
                :class="
                  characterCrafting - itemStats.craftingRequired < 0
                    ? 'red'
                    : ''
                "
              >
                ({{ characterCrafting - itemStats.craftingRequired }})
              </span>
            </span></span
          >
        </template>
        <template
          v-if="
            itemStats &&
            itemStats.craftingSkills &&
            itemStats.craftingSkills.length > 0
          "
        >
          <template v-for="skill in itemStats.craftingSkills" :key="skill">
            <span class="desc skill-required">{{ skill }} Required</span>
            <span class="num">{{ itemStats.craftingRequired }}</span>
          </template>
        </template>
        <template v-if="itemStats.gatheringRequired">
          <span class="desc gathering">Gathering Required</span>
          <span class="num gathering">{{ itemStats.gatheringRequired }}</span>
        </template>
        <template v-if="isResource && baseMaterial">
          <span class="desc">Upgrades</span>
          <span class="num">{{
            baseMaterial.name.replace("Enchanted", "")
          }}</span>
        </template>
        <template v-if="isResource && upgradeMaterial">
          <span class="desc">Crafts Into</span>
          <span class="num">{{ upgradeMaterial }}</span>
        </template>
        <template v-if="isResource && craftingResources">
          <span class="desc">Resources</span>
          <span class="num">{{ craftingResources }}</span>
        </template>
      </div>
    </div>
    <div class="set" v-if="itemSet">
      <span class="desc">{{ itemSet.desc }} Set</span>
      <span class="num">{{ itemSet.bonus }}% Per Piece</span>
    </div>
    <div class="set" v-if="isQuest">
      <span class="desc">Quest</span>
      <span class="num">{{ itemStats.quest }}</span>
    </div>
    <div class="bonuses" v-if="itemStats && !isHoveredRepairItem && !isInCraft">
      <span class="bonus" v-if="itemStats.magic">
        +{{ itemStats.magic }} Magic
        <span class="compare" v-if="compareItemStats && compareItemStats.magic">
          <span
            :class="itemStats.magic - compareItemStats.magic < 0 ? 'red' : ''"
          >
            ({{ itemStats.magic - compareItemStats.magic }})
          </span>
        </span>
      </span>
      <template v-if="!isWeapon">
        <span class="bonus" v-if="itemStats.attack">
          +{{ itemStats.attack }} Attack
          <span
            class="compare"
            v-if="compareItemStats && compareItemStats.attack"
          >
            <span
              :class="
                itemStats.attack - compareItemStats.attack < 0 ? 'red' : ''
              "
            >
              ({{ itemStats.attack - compareItemStats.attack }})
            </span>
          </span>
        </span>
      </template>
      <template v-else>
        <span class="bonus" v-if="itemStats.defense">
          +{{ itemStats.defense }} Defense
          <span
            class="compare"
            v-if="compareItemStats && compareItemStats.defense"
          >
            <span
              :class="
                itemStats.defense - compareItemStats.defense < 0 ? 'red' : ''
              "
            >
              ({{ itemStats.defense - compareItemStats.defense }})
            </span>
          </span>
        </span>
      </template>
      <span class="bonus" v-if="itemStats.totalHp">
        +{{ itemStats.totalHp }} HP
        <span
          class="compare"
          v-if="compareItemStats && compareItemStats.totalHp"
        >
          <span
            :class="
              itemStats.totalHp - compareItemStats.totalHp < 0 ? 'red' : ''
            "
          >
            ({{ itemStats.totalHp - compareItemStats.totalHp }})
          </span>
        </span>
      </span>
      <span class="bonus" v-if="itemStats.speed">
        +{{ itemStats.speed }} Speed
        <span class="compare" v-if="compareItemStats && compareItemStats.speed">
          <span
            :class="itemStats.speed - compareItemStats.speed < 0 ? 'red' : ''"
          >
            ({{ itemStats.speed - compareItemStats.speed }})
          </span>
        </span>
      </span>
      <span class="bonus" v-if="itemStats.luck">
        +{{ itemStats.luck }} Luck
        <span class="compare" v-if="compareItemStats && compareItemStats.luck">
          <span
            :class="itemStats.luck - compareItemStats.luck < 0 ? 'red' : ''"
          >
            ({{ itemStats.luck - compareItemStats.luck }})
          </span>
        </span>
      </span>
      <span class="bonus" v-if="itemStats.criticalChance">
        +{{ itemStats.criticalChance }}% Crit Hit
        <span
          class="compare"
          v-if="compareItemStats && compareItemStats.criticalChance"
        >
          <span
            :class="
              itemStats.criticalChance - compareItemStats.criticalChance < 0
                ? 'red'
                : ''
            "
          >
            ({{ itemStats.criticalChance - compareItemStats.criticalChance }})
          </span>
        </span>
      </span>
      <span class="bonus" v-if="itemStats.gathering">
        +{{ itemStats.gathering }} Gathering
        <span
          class="compare"
          v-if="compareItemStats && compareItemStats.gathering"
        >
          <span
            :class="
              itemStats.gathering - compareItemStats.gathering < 0 ? 'red' : ''
            "
          >
            ({{ itemStats.gathering - compareItemStats.gathering }})
          </span>
        </span>
      </span>
      <span class="bonus" v-if="itemStats.crafting">
        +{{ itemStats.crafting }} Crafting
        <span
          class="compare"
          v-if="compareItemStats && compareItemStats.crafting"
        >
          <span
            :class="
              itemStats.crafting - compareItemStats.crafting < 0 ? 'red' : ''
            "
          >
            ({{ itemStats.crafting - compareItemStats.crafting }})
          </span>
        </span>
      </span>
      <template v-if="isResource && resourceStats">
        <span
          class="bonus"
          v-for="(value, key, index) in resourceStats"
          :key="index"
        >
          +{{ value }} {{ key }}
        </span>
      </template>
    </div>
    <div class="blueprint" v-if="currentItem && currentItem.isBlueprint">
      <div class="resources">
        <div
          :class="'item ' + (item.hasRequiredResource ? '' : 'not-enough')"
          v-for="item in craftResources"
          :key="item"
          :title="
            (item.hasRequiredResource
              ? 'You have enough '
              : 'You do not have the required ') +
            item.name +
            ' to craft this'
          "
        >
          <img :src="item.image" :alt="item.name" />
          <span
            >{{ item.name }}
            <template v-if="item.quantity">×{{ item.quantity }}</template></span
          >
        </div>
      </div>
    </div>
    <div class="blueprint" v-if="isHoveredRepairItem">
      <span class="requiredResources">Required for Repair</span>
      <div class="resources">
        <div
          :class="'item ' + (item.hasRequiredResource ? '' : 'not-enough')"
          v-for="item in repairResources"
          :key="item"
          :title="
            (item.hasRequiredResource
              ? 'You have enough '
              : 'You do not have the required ') +
            item.name +
            ' to craft this'
          "
        >
          <img :src="item.image" :alt="item.name" />
          <span
            >{{ item.name }}
            <template v-if="item.quantity">×{{ item.quantity }}</template></span
          >
        </div>
      </div>
    </div>
    <div class="blueprint" v-if="isHoveredRepairItem">
      <span class="requiredResources">Inventory Items</span>
      <div class="resources">
        <div class="item" v-for="item in repairResources" :key="item">
          <img :src="item.image" :alt="item.name" />
          <span
            >{{ item.name }}
            <template v-if="item.userQuantity"
              >X{{ item.userQuantity }}</template
            ></span
          >
        </div>
      </div>
    </div>
    <div class="buttons" v-if="currentItem && !isHoveredLootChestItem">
      <template v-if="isConsumable && isResurrecting && isDead">
        <DialogButton
          :buttonSize="'med'"
          :buttonText="'Use'"
          @click="$emit('use')"
        />
      </template>
      <template v-if="isWandering && !isResurrecting">
        <template v-if="isConsumable">
          <DialogButton
            :disabled="isDead"
            :buttonSize="'med'"
            :buttonText="'Use'"
            @click="$emit('use')"
          />
        </template>
        <template
          v-else-if="
            !isResource && !isQuest && !itemStats.isBroken && !isLootbox
          "
        >
          <template v-if="isEquippedItem">
            <DialogButton
              :disabled="isDead"
              :buttonSize="'med'"
              :buttonText="'Unequip'"
              @click="$emit('unequip')"
              :isLoading="isUnEquipping"
            />
          </template>
          <template v-else>
            <div class="button-grid">
              <DialogButton
                v-if="!currentItem.isBlueprint"
                :disabled="
                  isDead || isSelectedForInventoryBreakdown || isEquipping
                "
                :buttonSize="
                  (currentItem.type === 'hand1' ||
                    currentItem.type === 'hand2') &&
                  !currentItem.isTwoHanded
                    ? 'small'
                    : 'med'
                "
                :buttonText="
                  (currentItem.type === 'hand1' ||
                    currentItem.type === 'hand2') &&
                  !currentItem.isTwoHanded
                    ? 'Equip Left'
                    : 'Equip'
                "
                @click="$emit('equip')"
                :class="
                  (currentItem.type === 'hand1' ||
                    currentItem.type === 'hand2') &&
                  !currentItem.isTwoHanded
                    ? 'two-buttons'
                    : ''
                "
                :isLoading="isEquipping"
              />
              <DialogButton
                v-if="
                  !currentItem.isBlueprint &&
                  (currentItem.type === 'hand1' ||
                    currentItem.type === 'hand2') &&
                  !currentItem.isTwoHanded
                "
                :disabled="
                  isDead || isSelectedForInventoryBreakdown || isEquipping
                "
                :buttonSize="'small'"
                :buttonText="'Equip Right'"
                :class="
                  currentItem.type === 'hand1' || currentItem.type === 'hand2'
                    ? 'two-buttons'
                    : ''
                "
                @click="$emit('equipHand2')"
                :isLoading="isEquipping"
              />
            </div>
          </template>
        </template>
        <template v-if="isLootbox">
          <DialogButton
            :buttonSize="'med'"
            :buttonText="'Open'"
            @click="$emit('openlootbox')"
          />
        </template>
        <template v-if="isResources && !isResource && !isConsumable">
          <DialogButton
            v-if="!currentItem.isBlueprint"
            :disabled="isDead || isSelectedForInventoryBreakdown"
            :buttonSize="'med'"
            :buttonText="'Crafting Menu'"
            @click="$emit('crafting')"
          />
        </template>
      </template>
      <template v-if="isInMarketplace && !isHoveredMarketItem">
        <DialogButton
          :buttonSize="'med'"
          :buttonText="'Sell'"
          @click="$emit('marketSell')"
        />
      </template>
      <template v-if="isInRepair && !isHoveredRepairItem">
        <DialogButton
          :buttonSize="'large'"
          :buttonText="'Create Repair Bounty'"
          @click="$emit('repairList')"
        />
      </template>
      <template v-if="isInCraft && !isHoveredCraftItem && isBlueprint">
        <DialogButton
          :buttonSize="'large'"
          :buttonText="'Create Craft Bounty'"
          @click="$emit('craftList')"
        />
      </template>
      <template v-if="isInStash">
        <DialogButton
          :buttonSize="'med'"
          :buttonText="isStashItemSelected ? 'Deselect' : 'Select'"
          @click="toggleStashItem()"
          :disabled="isMaxStashLimitSelected && !isStashItemSelected"
        />
        <QuantitySelect
          :id="currentItem.id"
          :maxQuantity="currentItem.quantity"
          :type="currentItem.type"
          v-if="
            currentClass === 'show' &&
            isStashItemSelected &&
            stashFilterType !== 'equipment'
          "
        />
      </template>
    </div>
    <template
      v-if="!isInStash && !isInMarketplace && !isInCraft && !isInRepair"
    >
      <RoundButton size="extra-small" class="share-item" @click="shareItem">
      </RoundButton>
    </template>
  </div>
  <!--Show Share Image Popup only If It Exists -->
  <div v-if="shareImage !== '' && isShareOpen" class="share-wrapper">
    <div class="share-image">
      <img :src="shareImage" alt="Share" />
      <p>
        Right click and copy image. Then click on tweet to open twitter compose.
        Paste image and share.
      </p>
      <a
        class="twitter-share-button"
        :href="getShareLink()"
        data-size="large"
        data-text="Check out this cool item I just got in #AurumDraconis by @DCGGameFi! #BlockchainGaming. Going live soon: https://aurumdraconis.dragoncrypto.io/"
        :data-url="shareImage"
        data-hashtags="aurumdraconis,dcg,load"
        data-via="load"
        data-related="gamefi,crypto,twitter"
        target="_blank"
      >
        Tweet
      </a>
      <button class="close" @click="isShareOpen = false">Close</button>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Constants from "../consts/constants";
import RoundButton from "./Buttons/RoundButton.vue";
import DialogButton from "./DialogButton.vue";
import QuantitySelect from "./Stash/QuantitySelect.vue";

export default {
  name: "PopupInfo",
  components: {
    DialogButton,
    QuantitySelect,
    RoundButton,
  },
  props: {},
  emits: [
    "use",
    "equip",
    "equipHand2",
    "unequip",
    "openlootbox",
    "crafting",
    "marketSell",
    "repairList",
    "craftList",
  ],
  data() {
    return {
      $store: {},
      isVisible: true,
      isInventoryOpen: false,
      shareImage: "",
      isShareOpen: false,
    };
  },
  methods: {
    hovered() {
      this.$store.commit("setTooltipHovered");
    },
    unhovered() {
      this.$store.commit("setTooltipUnhovered");
    },
    getShareLink() {
      const share = [
        "https://twitter.com/intent/tweet?text=Scored%20some%20awesome%20loot%20in%20%23AurumDraconis%20by%20%40DCGGameFi!%20%F0%9F%94%A5%0A%0ACan%E2%80%99t%20wait%20to%20put%20it%20to%20use!%20Join%20the%20adventure%3A%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
        "https://twitter.com/intent/tweet?text=Just%20found%20a%20valuable%20treasure%20in%20%23AurumDraconis%20by%20%40DCGGameFi%20after%20a%20thrilling%20battle!%20%F0%9F%90%89%0A%0ABegin%20your%20own%20legendary%20quest%3A%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
        "https://twitter.com/intent/tweet?text=Uncovered%20some%20epic%20loot%20in%20%23AurumDraconis%20by%20%40DCGGameFi!%20%F0%9F%8E%81%0A%0AReady%20to%20power%20up%20my%20hero!%20Start%20your%20journey%3A%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
        "https://twitter.com/intent/tweet?text=Snagged%20a%20fantastic%20piece%20of%20loot%20after%20an%20intense%20fight%20in%20%23AurumDraconis%20by%20%40DCGGameFi!%20%F0%9F%8E%AE%0A%0AJoin%20me%20in%20the%20game%3A%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
        "https://twitter.com/intent/tweet?text=Secured%20a%20precious%20treasure%20in%20%23AurumDraconis%20by%20%40DCGGameFi!%20%F0%9F%8E%89%0A%0ATime%20to%20level%20up%20my%20game!%20Embark%20on%20your%20own%20adventure%3A%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
        "https://twitter.com/intent/tweet?text=Looted%20something%20amazing%20in%20%23AurumDraconis%20by%20%40DCGGameFi!%20%F0%9F%8F%B9%0A%0ADiscover%20your%20own%20path%20to%20greatness%3A%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
        "https://twitter.com/intent/tweet?text=Picked%20up%20a%20fantastic%20find%20in%20%23AurumDraconis%20by%20%40DCGGameFi!%20%F0%9F%8E%AE%0A%0AJoin%20the%20action%3A%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
        "https://twitter.com/intent/tweet?text=Claimed%20a%20valuable%20prize%20after%20a%20tough%20battle%20in%20%23AurumDraconis%20by%20%40DCGGameFi!%20%F0%9F%90%B2%0A%0AStart%20your%20own%20epic%20journey%3A%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
        "https://twitter.com/intent/tweet?text=Just%20got%20my%20hands%20on%20some%20sweet%20loot%20in%20%23AurumDraconis%20by%20%40DCGGameFi!%20%F0%9F%9A%80%0A%0ADive%20into%20the%20adventure%2C%20too%3A%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
        "https://twitter.com/intent/tweet?text=Acquired%20an%20exciting%20piece%20of%20loot%20in%20%23AurumDraconis%20by%20%40DCGGameFi!%20%F0%9F%9B%A1%EF%B8%8F%0A%0ATime%20to%20gear%20up%20and%20conquer!%20Begin%20your%20own%20heroic%20adventure%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
      ];
      const index = Math.floor(Math.random() * (share.length - 1));
      return share[index];
    },
    hide() {},
    toggleStashItem() {
      this.$store.dispatch("stash/toggleItem", this.currentItem);
    },
    async delay(time) {
      return new Promise((res) => setTimeout(res, time));
    },
    async shareItem() {
      const id = this.currentItem.id
        ? this.currentItem.id
        : this.currentItem.staticId;
      const response = await fetch(Constants.apiUrl + "helper/share", {
        method: "POST",
        body: JSON.stringify({
          heroId: this.character.number,
          account: this.$store.state.account,
          itemId: id,
          sessionId: localStorage.getItem("sessionId"),
          type: this.currentItem.type,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (result.success) {
        this.shareImage = result.image;
        this.isShareOpen = true;
      }
    },
    showDurabilityUpgrade() {
      this.$store.commit("setDurability");
    },
  },
  computed: {
    //TODO: For now I am reusing functionality that exists for repair
    // - If this gets more complex will need to create another method for creation
    isStashItemSelected() {
      // TODO: CAN BE REFACTORED IN A BETTER MANNER
      // TODO: Need to change the highlight style as well
      if (
        this.$store.state.stash.selectedEquipments.includes(
          this.currentItem.id
        ) ||
        this.$store.state.stash.selectedConsumables.includes(
          this.currentItem.id
        ) ||
        this.$store.state.stash.selectedResources.includes(this.currentItem.id)
      ) {
        return true;
      }
      return false;
    },
    isSelectedForInventoryBreakdown() {
      const items =
        this.$store.state.selectedInventory.selectedInventoryEquipments;
      return items.includes(this.currentItem.id);
    },
    isMaxStashLimitSelected() {
      let status = false;
      switch (this.stashFilterType) {
        case Constants.filterTypes.equipments:
          status = this.$store.state.stash.selectedEquipments.length >= 10;
          break;
        case Constants.filterTypes.consumables:
          status = this.$store.state.stash.selectedConsumables.length >= 10;
          break;
        case Constants.filterTypes.resources:
          status = this.$store.state.stash.selectedResources.length >= 10;
          break;
        default:
          break;
      }
      return status;
    },
    stashFilterType() {
      return this.$store.state.stash.filterType;
    },
    repairResources() {
      return this.$store.state.repairResources();
    },
    craftResources() {
      return this.$store.state.craftResources();
    },
    isEquipping() {
      return this.$store.state.isEquipping;
    },
    isUnEquipping() {
      return this.$store.state.isUnEquipping;
    },
    isHoveredMarketItem() {
      return this.$store.state.isHoveredMarketItem;
    },
    isHoveredLootChestItem() {
      return this.$store.state.isHoveredLootChestItem;
    },
    isHoveredRepairItem() {
      return this.$store.state.isHoveredRepairItem;
    },
    isHoveredCraftItem() {
      return this.$store.state.isHoveredCraftItem;
    },
    isBlueprint() {
      return this.currentItem.type == "blueprint";
    },
    baseMaterial() {
      if (
        this.isResource &&
        this.currentItem.baseTypeId &&
        this.currentItem.staticId &&
        this.currentItem.staticId != this.currentItem.baseTypeId
      ) {
        const baseMaterial = this.$store.state.resources.find(
          (r) => r.staticId == this.currentItem.baseTypeId
        );
        return baseMaterial;
      }
      return "";
    },
    upgradeMaterial() {
      if (this.isResource) {
        let materials;
        const upgradeMaterial = this.$store.state.resources.filter((r) => {
          if (
            r.craftingResources &&
            r.craftingResources.includes(this.currentItem.staticId)
          ) {
            return r;
          }
        });
        if (upgradeMaterial.length > 0) {
          for (let i = 0; i < upgradeMaterial.length; i++) {
            if (materials) {
              materials += ", " + upgradeMaterial[i].name;
            } else {
              materials = upgradeMaterial[i].name;
            }
          }
          return materials;
        }
      }
      return "";
    },
    craftingResources() {
      if (this.isResource) {
        let resources;
        const base = this.$store.state.resources.find(
          (r) => r.staticId == this.currentItem.staticId
        );
        if (!base) {
          return;
        }
        const resourceMaterial = this.$store.state.resources.filter((r) => {
          if (base.craftingResources) {
            return base.craftingResources.includes(r.staticId);
          }
        });
        if (resourceMaterial.length > 0) {
          for (let i = 0; i < resourceMaterial.length; i++) {
            if (resources) {
              resources += ", " + resourceMaterial[i].name;
            } else {
              resources = resourceMaterial[i].name;
            }
          }
          return resources;
        }
      }
      return "";
    },
    resourceStats() {
      if (!this.currentItem && !this.isResource && !this.currentItem.bonuses) {
        return null;
      }

      return this.$store.state.hoveredItem.bonuses;
    },
    itemSet() {
      if (!this.itemStats || !this.itemStats.set) {
        return null;
      }

      const setDesc = this.$store.state.sets.find(
        (s) => s.id == this.itemStats.set
      );

      return setDesc
        ? { desc: setDesc.desc, bonus: this.itemStats.setBonus }
        : null;
    },
    healing() {
      if (this.isConsumable) {
        if (
          this.itemStats &&
          this.itemStats.action &&
          this.itemStats.action == Constants.consumableActions.heal
        )
          return this.itemStats.amount;
      }

      return null;
    },
    isResurrecting() {
      return (
        this.isConsumable &&
        this.itemStats &&
        this.itemStats.action &&
        this.itemStats.action == Constants.consumableActions.resurrection
      );
    },
    character() {
      return this.$store.state.characters[this.$store.state.currentCharacter];
    },
    isInGuildContracts() {
      return this.$store.state.gameState === Constants.gamemodes.defenderguild;
    },
    isInStash() {
      return this.$store.state.gameState === Constants.gamemodes.stash;
    },
    isWandering() {
      return this.$store.state.gameState == Constants.gamemodes.wandering;
    },
    isWeapon() {
      return this.$store.state.hoveredItem.type == Constants.slots.hand1;
    },
    isTwoHanded() {
      return this.$store.state.hoveredItem.isTwoHanded;
    },
    isDead() {
      return this.character.stats.isDead;
    },
    isConsumable() {
      return (
        this.$store.state.hoveredItem.type &&
        this.$store.state.hoveredItem.type == Constants.useable.consumable
      );
    },
    canUpgradeDurability() {
      return (
        !this.isConsumable &&
        !this.isResource &&
        !this.currentItem.isBlueprint &&
        !this.isMarketplaceItem
      );
    },
    isMarketplaceItem() {
      return this.$store.state.gameState == Constants.gamemodes.marketplace;
    },
    isResource() {
      return (
        this.$store.state.hoveredItem.type &&
        this.$store.state.hoveredItem.type == Constants.useable.resource
      );
    },
    isQuest() {
      return (
        this.$store.state.hoveredItem &&
        this.$store.state.hoveredItem.type &&
        this.$store.state.hoveredItem.type == "quest"
      );
    },
    isLootbox() {
      return (
        this.$store.state.hoveredItem &&
        this.$store.state.hoveredItem.type &&
        this.$store.state.hoveredItem.type == "lootbox"
      );
    },
    isResources() {
      if (this.itemStats && this.itemStats.resources) {
        return true;
      }

      return false;
    },
    currentBreakage() {
      const breakage = this.itemStats.currentBreakage
        ? this.itemStats.currentBreakage
        : 0;
      const result = parseFloat((100 - breakage).toFixed(2));
      return result < 0 ? 0 : result;
    },
    currentClass() {
      return this.$store.state.isItemHovered ||
        this.$store.state.isTooltipHovered
        ? "show"
        : "hide";
    },
    repairClass() {
      return this.isInRepair || this.isInCraft ? " repair-class" : "";
    },
    guildContractClass() {
      return this.isInGuildContracts ? " guild-contract" : "";
    },
    characterCrafting() {
      const craftingSkill = this.character.stats.crafting;
      const characterEquipment = this.$store.state.equipmentStats().crafting;
      return craftingSkill + characterEquipment;
    },
    isInMarketplace() {
      return this.$store.state.gameState == Constants.gamemodes.marketplace;
    },
    isInRepair() {
      return this.$store.state.gameState == Constants.gamemodes.repair;
    },
    isInCraft() {
      return this.$store.state.gameState == Constants.gamemodes.crafters;
    },
    isEquippedItem() {
      for (const key in this.character.equipped) {
        if (Object.hasOwnProperty.call(this.character.equipped, key)) {
          const slotItem = this.character.equipped[key];

          if (
            this.$store.state.hoveredItem &&
            this.$store.state.hoveredItem.id == slotItem.id
          ) {
            return true;
          }
        }
      }

      return false;
    },
    currentLocationX() {
      return this.$store.state.hoverStartLocation.x;
    },
    currentLocationY() {
      return this.$store.state.hoverStartLocation.y;
    },
    currentItem() {
      return this.$store.state.hoveredItem;
    },
    // currentSelectedItemQuantity() {
    //   const item = this.$store.state.hoveredItem;
    //   let quantity = 0;
    //   if (this.isInStash && this.isStashItemSelected) {
    //     let indexOfId = null;
    //     if (this.isConsumable) {
    //       indexOfId = this.$store.state.stash.selectedConsumables.indexOf(
    //         item.id
    //       );
    //       quantity =
    //         this.$store.state.stash.selectedConsumablesQuantities[indexOfId];
    //     } else if (this.isResources) {
    //       indexOfId = this.$store.state.stash.selectedResources.indexOf(
    //         item.id
    //       );
    //       quantity =
    //         this.$store.state.stash.selectedResourcesQuantities[indexOfId];
    //     }
    //   }
    //   return quantity;
    // },
    currentImage() {
      if (!this.currentItem || !this.currentItem.imageName) {
        return "";
      }
      if (this.currentItem.isBlueprint) {
        return (
          "https://ik.imagekit.io/dcg/blueprints/" +
          this.currentItem.imageName +
          "?tr=w-90"
        );
      }
      return (
        "https://ik.imagekit.io/dcg/equip/" +
        this.currentItem.imageName +
        "?tr=w-90"
      );
    },
    compareItemStats() {
      if (!this.currentItem || this.isEquippedItem) {
        return null;
      }

      let itemType = this.currentItem.type;

      if (itemType == "ring") {
        itemType = "ring1";
      }

      if (!itemType && this.isHoveredMarketItem) {
        itemType = this.currentItem.itemType;
      }

      const equippedItem = this.character.equipped[itemType];

      if (!equippedItem) {
        return null;
      }

      return equippedItem.stats;
    },
    itemStats() {
      if (!this.currentItem) {
        return null;
      }

      // console.log(this.$store.state.hoveredItem.stats);

      // if (this.$store.state.isHoveredMarketItem) {
      //   const marketItemStats = this.$store.state.marketData.find(
      //     (m) => m.itemStats.id == this.$store.state.hoveredItem.id
      //   );

      //   if (marketItemStats) return marketItemStats.itemStats;
      // }
      return this.$store.state.hoveredItem.stats;
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.popup-wrapper .red {
  color: #ff0000 !important;
}

.addDurability {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex !important;
  gap: 5px;
  width: 100% !important;
  justify-content: right;
  align-items: center;
}

.addDurability img {
  width: 12px;
  height: 12px;
}

.addDurability:hover {
  text-decoration: underline;
}

.requiredResources {
  padding-top: 8px;
  font-size: 70%;
}
.share-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  display: grid;
  align-items: center;
  justify-items: center;
  background: #000000db;
  p {
    max-width: 600px;
    font-size: 1.2rem;
    color: #ffffffb5;
  }
  .twitter-share-button {
    background-color: #5081ff;
    display: inline-block;
    width: 75px;
    padding: 5px 0;
    font-weight: bold;
    text-decoration: none;
    border-radius: 6px;
    color: #fff;
    margin: 10px auto;
  }
  .close {
    background-color: #777777;
    display: inline-block;
    width: 75px;
    padding: 8px 0;
    font-weight: bold;
    text-decoration: none;
    border-radius: 6px;
    color: #fff;
    margin: 10px 5px;
    border: none;
    top: -2px;
    position: relative;
  }
}

.popup-wrapper {
  position: fixed;
  background: url("https://cdn.dragoncrypto.io/uiassets/tooltip.png") no-repeat
    top left;
  width: 298px;
  height: 334px;
  z-index: 12;

  &.guild-contract {
    z-index: 16;
  }
  .broken {
    color: $dmg;
  }

  .desc {
    text-align: left;
    width: 44%;

    &.skill-required {
      text-transform: capitalize;
    }

    &.healing {
      color: $text-highlight;
    }

    &.craft {
      color: $legendary;
    }

    &.gathering {
      color: $uncommon;
    }
  }

  .num {
    text-align: right;
    width: 56%;

    &.healing {
      color: $text-highlight;
    }

    &.craft {
      color: $legendary;
    }
  }

  .compare {
    color: $text-highlight;
  }

  .bonuses {
    border-top: 1px solid #3d3d40;
    width: 85%;
    margin: 0 auto;
    margin-top: 12px;
    padding-top: 10px;
    font-size: 80%;
    color: #68b563;
    display: flex;
    flex-wrap: wrap;

    span {
      width: 33%;
    }
  }

  .stats {
    margin-top: 10px;
    color: #cac5c4;
    font-size: 80%;

    .stat {
      margin: 0 auto;
      width: 85%;
    }

    span {
      display: inline-block;
    }
  }

  .set {
    margin-top: 8px;
    color: $artefact;
    width: 85%;
    font-size: 80%;
    margin: 0 auto;

    span {
      width: 49%;
      display: inline-block;
    }
  }

  .blueprint {
    margin-top: 8px;
    color: $artefact;
    font-size: 80%;
    margin: 8px auto 0 auto;

    .blueprintSpan {
      margin-top: 8px;
    }
    span {
      margin: 0 auto;
      display: inline-block;
    }
  }

  .title {
    padding: 12px 0 0 0;
    margin: 0 auto;
    display: flex;
    width: 90%;
    font-size: 95%;
    justify-content: center;
    border-bottom: 1px solid #3d3d40;

    .icons {
      text-align: right;

      .icon {
        max-height: 24px;
        border: 1px solid #3d3d40;
        border-radius: 12px;
        margin-right: 0;
        margin-left: 4px;
      }
    }

    &.legendary {
      // .img-wrapper{

      // }

      h2 {
        color: $legendary;
      }
    }

    &.fabled {
      h2 {
        color: $fabled;
      }
    }

    &.unique {
      h2 {
        color: $unique;
      }
    }

    &.uncommon {
      h2 {
        color: $uncommon;
      }
    }

    &.shoddy {
      h2 {
        color: $shoddy;
      }
    }

    &.rare {
      h2 {
        color: $rare;
      }
    }

    &.mythical {
      h2 {
        color: $mythical;
      }
    }

    &.epic {
      h2 {
        color: $epic;
      }
    }

    &.artefact {
      h2 {
        color: $artefact;
      }
    }

    img {
      max-width: 90px;
      margin-right: 12px;
    }

    h2 {
      color: #fff;
      text-align: left;
      font-family: "IM Fell English", serif;
      font-size: 100%;
      text-transform: none;
      margin-bottom: 8px;
    }
  }

  &.show {
    opacity: 1;
    pointer-events: all;

    transition: opacity 0.35s linear;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.35s linear;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 9px;

    .dialog-button {
      margin-top: 0;
    }
  }
  .button-grid {
    button {
      transition: none;
    }
    .two-buttons {
      transform: scale(0.9);
      margin: 0;
      &:first-child {
        margin-right: 0;
      }
      &:last-child {
        margin-left: 0;
      }
    }
  }

  .blueprint {
    flex-direction: column;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 22px;
    margin-top: 0;

    .desc.craft {
      text-align: left;
      width: 100%;
      padding-bottom: 6px;
      margin-bottom: 4px;
    }

    .resources {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .item {
        border-radius: 3px;
        border: 1px solid #3d3d40;
        padding: 4px;
        margin: 3px;
        font-size: 65%;
        display: flex;
        align-items: center;
        flex-grow: 1;
        width: calc(50% - 16px);

        &.not-enough {
          border: 1px solid $dmg;
          background: transparentize($color: $dmg, $amount: 0.5);
        }

        img {
          max-width: 20px;
        }
      }
    }
  }

  .share-item {
    &:before {
      content: "";
      background: none;
      text-indent: -9999px;
      background-image: url("../assets/ui/share.svg");
      display: block;
      width: 14px;
      height: 14px;
      background-size: cover;
    }
    position: absolute;
    bottom: 15px;
    right: 15px;
    display: grid;
    align-items: center;
    justify-items: center;
    background-position: center bottom;
    &:hover {
      background-position: center top;
    }
    /* background: none;
    text-indent: -9999px;
    background-image: url("../assets/ui/share.svg");
    display: block;
    width: 20px;
    height: 20px;
   
    border: none;
    padding: 0;
    background-size: cover;
    opacity: 0.5;
    &:hover {
      opacity: 0.7;
      cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
        auto;
    } */
  }
}

.repair-class {
  border: 1px solid #3d3d40;
  background: #151413;
  border-radius: 2px;
  height: auto;
  min-height: 334px;
}
</style>
