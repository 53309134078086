// initial state
const state = () => ({
  isNotificationsOpen: false,
  readNotifications: true,
  lastReadNotificationId: null,
});

// // getters
// const getters = {};

// actions
const actions = {
  loadInitialReadStatus({ commit }, messageId) {
    // Loading the last localstorage saved message id and setting read status based on it
    if (messageId) {
      const prevReadId = localStorage.getItem("lastReadNotificationId");
      if (prevReadId && prevReadId === messageId) {
        commit("setLastReadNotificationId", messageId);
        commit("setReadNotifications", true);
      }
    }
  },
};

// mutations
const mutations = {
  setNotificationsOpenState(state, status) {
    state.isNotificationsOpen = status;
  },
  setReadNotifications(state, status) {
    state.readNotifications = status;
  },
  setLastReadNotificationId(state, id) {
    state.lastReadNotificationId = id;
    // SAving latest read message id to localstorage
    localStorage.setItem("lastReadNotificationId", id);
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  actions,
  mutations,
};
