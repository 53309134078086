// initial state

const state = () => ({
  isPetsOpen: false,
  selectedPet: null,
  pets: [],
});

// // getters
// const getters = {};

// // actions
const actions = {};

// mutations
const mutations = {
  setIsPetsOpen(state, value) {
    state.isPetsOpen = value;
  },
  setSelectedPet(state, value) {
    state.selectedPet = value;
  },
  setPets(state, value) {
    state.pets = value;
  },
};

export default {
  namespaced: true,
  state,
  //   getters,
  actions,
  mutations,
};
