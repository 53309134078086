<template>
  <div :class="['pet', isEquippedByPlayer ? 'selected' : '']">
    <div
      class="image-holder"
      @click="selectNft(pet)"
      :style="`background-image: url(${pet.image})`"
    ></div>
    <p>{{ pet.name }}</p>
    <div class="hero-stats">
      <h5 class="small-header">Pet Stats</h5>
      <div class="grid">
        <div class="stats main-stats">
          <div class="stat-item">
            <span class="stat-name">Level</span>
            <span class="stat-value">{{ pet.level }}</span>
          </div>
          <div class="stat-item">
            <span class="stat-name">Base Attack</span>
            <span class="stat-value">{{ pet.baseAttack }}</span>
          </div>
          <div class="stat-item">
            <span class="stat-name">Attack</span>
            <span class="stat-value">{{ pet.attack }}</span>
          </div>
          <div class="stat-item">
            <span class="stat-name">Base Defense</span>
            <span class="stat-value">{{ pet.baseDefense }}</span>
          </div>
          <div class="stat-item">
            <span class="stat-name">Defense</span>
            <span class="stat-value">{{ pet.defense }}</span>
          </div>
          <div class="stat-item">
            <span class="stat-name">Rank</span>
            <span class="stat-value">{{
              pet.rank > -1 ? pet.rank : "N.A."
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="button-container toggle-button">
      <DialogButton
        v-if="isEquippedByPlayer"
        buttonText="Unequip"
        buttonSize="small"
        @click="unequipPet()"
        :isLoading="isEquipping"
        :disabled="isTurnHappening"
      />

      <DialogButton
        v-else-if="!isEquippedByPlayer && !pet.isEquipped"
        buttonText="Equip"
        buttonSize="small"
        @click="equipPet(pet)"
        :isLoading="isEquipping"
        :disabled="isTurnHappening"
      />
      <p v-else>This pet is accompanying your other hero</p>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import apiConnector from "../../game/apiConnector";
// import Constants from "../../consts/constants";
import DialogButton from "../DialogButton.vue";
import { parseError } from "../../utils/helpers";

// import { useUser } from "../../composables/user";
// import { ethers } from "ethers";

// import { getERC721Contract } from "../../utils/getContract";
// import { ethers } from "ethers";
export default {
  name: "PetCard",
  props: {
    pet: {},
  },
  components: {
    // BasicPopup,
    DialogButton,
    // SkeletalLoading,
  },
  emits: ["updatePets"],
  setup(props, { emit }) {
    const store = useStore();
    const isEquipping = ref(false);

    const equipPet = (pet) => {
      try {
        let nftId = pet.id; // TODO - Get id for NFT being equipped
        let currentAccount = store.state.account;
        let sessionId = store.state.getSessionId();
        let number = character.value.number;
        isEquipping.value = true;
        store.commit("setTurnHappening", true);
        apiConnector
          .callEquipNft(currentAccount, sessionId, number, nftId, pet.address)
          .then((result) => {
            if (!result.success) {
              isEquipping.value = false;
              store.commit("setTurnHappening", false);

              store.commit("setNotification", result.message);
              return;
            }
            if (result.success) {
              store.commit("pets/setSelectedPet", result.equippedNft);
              isEquipping.value = false;
              store.commit("setTurnHappening", false);

              emit("updatePets");
            }
          })
          .catch((e) => {
            isEquipping.value = false;
            store.commit("setTurnHappening", false);

            console.log(e);
          });
      } catch (error) {
        isEquipping.value = false;
        store.commit("setTurnHappening", false);

        console.log(error);
        store.commit("setNotification", parseError(error));
      }
    };

    const unequipPet = () => {
      try {
        let currentAccount = store.state.account;
        let sessionId = store.state.getSessionId();
        let number = character.value.number;
        isEquipping.value = true;
        store.commit("setTurnHappening", true);
        apiConnector
          .callUnequipNft(currentAccount, sessionId, number)
          .then((result) => {
            console.log(result);
            if (!result.success) {
              isEquipping.value = false;
              store.commit("setTurnHappening", false);

              store.commit("setNotification", result.message);
              return;
            }
            if (result.success) {
              store.commit("pets/setSelectedPet", null);
              isEquipping.value = false;
              store.commit("setTurnHappening", false);

              emit("updatePets");
            }
          })
          .catch((e) => {
            isEquipping.value = false;
            store.commit("setTurnHappening", false);

            console.log(e);
          });
      } catch (error) {
        isEquipping.value = false;
        store.commit("setTurnHappening", false);

        console.log(error);
        store.commit("setNotification", parseError(error));
      }
    };

    const selectedPet = computed(() => {
      return store.state.pets.selectedPet;
    });
    const isEquippedByPlayer = computed(() => {
      return (
        selectedPet.value &&
        props.pet.id === selectedPet.value.id &&
        props.pet.address === selectedPet.value.address
      );
    });
    const isTurnHappening = computed(() => {
      return store.state.isTurnHappening;
    });
    const character = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });
    return {
      // Computed
      selectedPet,
      isEquippedByPlayer,
      isTurnHappening,
      // Methods
      unequipPet,
      equipPet,
      // Data
      isEquipping,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.pet {
  width: 200px;
  grid-gap: 0.5rem;
  align-items: self-start;
  background-color: #0000003d;
  padding: 0.5rem;
  border: 5px solid #ffffff1c;
  box-shadow: 0 0 5px #000;
  border-radius: 2px;
  position: relative;
  margin: 10px 0;
  cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
    auto;
  transition: scale 0.1s ease-out;
  &.selected {
    background-color: #151111;
    box-shadow: 0 0 5px #000;
    border-color: #e3bf55bd;

    /* &::before {
        content: "";
        position: absolute;
        left: -5px;
        top: -5px;
        filter: blur(7px);
        background: #f7ff00;
        background: linear-gradient(to right, #fcffa8, #ffd500);

        background-size: 400% 400%;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        z-index: -1;
        animation: animateGlow 3s ease-in-out infinite;
      } */
  }
  .image-holder {
    height: 175px;
    width: 175px;
    position: relative;
    background-size: cover;
    background-position: center center;
    span {
      position: absolute;
      width: 100%;
      background: rgba(0, 0, 0, 0.329);
      bottom: 6px;
      font-size: 1rem;
      padding: 0.3rem 0;
    }
  }
  img {
    width: 100%;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }

  h5.small-header {
    height: 30px;
    background: url("https://cdn.dragoncrypto.io/uiassets/small-header-bg.webp")
      repeat center center;
    display: grid;
    align-items: center;
    box-shadow: inset 0 0 10px 0 #000;
    border: 2px #4e4134 solid;
    margin: 5px 0;
    color: #be9177;
    font-family: "Lato", sans-serif;
    text-transform: capitalize;
    font-weight: bold;
    text-shadow: 0 0 2px #000;
  }
  .toggle-button {
    position: absolute;
    top: 174px;
    left: 0;
    z-index: 1;
    display: none;
    width: 100%;
    transform: translateY(-50%);
    background: #000000d6;
    grid-gap: 15px;
    padding: 15px 0;
    button {
      width: 200px;
      &:hover {
        background-position: top center;
      }
    }

    @media only screen and (max-width: 576px) {
      display: block;
      background: none;
      top: 0;
      left: 0;
      margin-top: 20px;
      transform: none;
      position: relative;
    }
  }
  &.selected {
    .toggle-button {
      display: block;
    }
  }
  &:hover {
    .toggle-button {
      display: block;
    }
    @media only screen and (max-width: 576px) {
      transform: none;
      box-shadow: none;
    }
    box-shadow: 0 0 5px #000;
    transform: scale(1.02);
  }
  .hero-stats {
    div.grid {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      margin-top: 10px;
      @media only screen and (max-width: 576px) {
        display: none;
      }
      .stats {
        width: 80%;
      }
    }
  }
  .stat-item {
    display: grid;
    grid-template-columns: 80% 1fr;
    grid-gap: 5px;
    align-items: center;
    margin: 5px;
    align-items: center;
    padding: 0 3px 3px;
    font-family: "Lato", sans-serif;
    color: #8a796e;
    border-bottom: 1px solid rgba(255, 255, 255, 0.143);
    font-size: 0.8rem;
    &:last-child {
      border-bottom: none;
    }
    .stat-name {
      text-align: left;
    }
    .stat-value {
      text-align: right;
      color: #fff;
    }
  }
  .meta-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 5px;
    .hero-details {
      span {
        display: block;
      }
    }
    .bars {
      position: relative;
      .hp {
        width: 100%;
        height: 18px;
        position: absolute;
        left: 0;
        top: 3px;
      }

      .turns {
        position: absolute;
        width: 100%;
        height: 18px;
        left: 0;
        top: 22px;
      }

      .turns-stat,
      .hp-stat {
        font-size: 70%;
        top: -1px;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 2px #000;
        font-weight: bold;
        line-height: 18px;
      }

      .hp-bar {
        background: url("https://cdn.dragoncrypto.io/uiassets/selected-player-hpbar.png")
          no-repeat top left;
      }

      .turns-bar {
        background: url("https://cdn.dragoncrypto.io/uiassets/selected-player-turnbar.png")
          no-repeat top left;
      }

      .hp-bar,
      .turns-bar {
        position: absolute;
        top: 3px;
        left: -3px;
        width: 100%;
        height: 13px;
        transition: width 0.35s ease-out;
        background-size: cover;
      }
    }
  }
}
/* .pet {
  align-items: self-start;
  background-color: #0000003d;
  padding: 0.5rem;
  border: 5px solid #ffffff1c;
  box-shadow: 0 0 5px #000;
  border-radius: 2px;
  position: relative;
  margin: 10px 0;
  .image {
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 200px;
  }
  background-color: #3f3735;
  border: none;
  cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
    auto;
  .hero-stats {
    div.grid {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      margin-top: 10px;
      @media only screen and (max-width: 576px) {
        display: none;
      }
      .stats {
        width: 80%;
        margin: 0 auto 10px;
        .stat-item {
          display: grid;
          grid-template-columns: 2fr 1fr;
          grid-gap: 5px;
          .stat-name {
            text-align: left;
          }
          .stat-value {
            text-align: right;
          }
        }
      }
    }
  }
} */
</style>
