<template>
  <span class="bonus">
    <template v-if="stat && stat > 0">
      +{{ stat }}
      <template v-if="hasPercentage">%</template>
    </template>
  </span>
</template>

<script>
export default {
  name: "BonusStat",
  props: {
    stat: {
      type: Number,
    },
    hasPercentage: {
      type: Boolean,
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.bonus {
  color: $text-highlight;
}
</style>
