<template>
  <!-- <div :class="['stash-inventory wrapper', isStashVisible]"> -->
  <BasicPopup
    :class="[isStashVisible, 'stash-inventory']"
    :header="`Your ${filterType} Stash`"
    id="your-stash-steps"
  >
    <div :class="['stash-panel']">
      <div
        :class="['close-button', 'button-small', isLoading ? 'loading' : '']"
        @click="closeStash"
        :title="isLoading ? 'Complete transaction first' : 'Close'"
      >
        <span>x</span>
      </div>
      <div class="filters" id="stash-filters">
        <div class="filters-content">
          <img
            :class="getClassEquipments"
            src="../../assets/ui/stash-filter-equipments.png"
            alt="Weapons"
            @click="filterEquipments"
            title="Equipment"
          />
          <img
            :class="getClassConsumables"
            src="https://cdn.dragoncrypto.io/uiassets/inventory-filter-consumables.png"
            alt="Consumables"
            @click="filterConsumables"
            title="Consumables"
          />
          <img
            :class="getClassResources"
            src="https://cdn.dragoncrypto.io/uiassets/inventory-filter-resources.png"
            alt="Resources"
            @click="filterResources"
            title="Crafting and Resources"
          />
          <img
            :class="getClassTokens"
            src="../../assets/ui/tokens.png"
            alt="Tokens"
            @click="filterTokens"
            title="DCAU and DCAR"
          />
          <img
            :class="getClassChests"
            src="../../assets/ui/chests.png"
            alt="Chests"
            @click="filterChests"
            title="Chests"
          />
        </div>
      </div>
      <div class="stash-type" id="stash-items">
        <template v-if="!isLoadingStash">
          <div v-if="getClassTokens !== 'selected'" class="items-container">
            <div :class="['items', isLoading ? 'loading' : '']">
              <template v-for="stashItem in currentStash" :key="stashItem.id">
                <EquipmentTile
                  @click="toggleItem(stashItem)"
                  :source="'inv'"
                  :item="stashItem"
                  :class="isItemSelected(stashItem.id) ? 'selected' : ''"
                />
              </template>
            </div>
          </div>
          <div id="pending-claim" v-if="showPendingBox">
            <div>
              <h4>You have a pending {{ filterType }} claim</h4>
              <p>
                You started a claim previously but didn't complete it. Please
                proceed with claiming those items first or reject the previous
                claim.
              </p>
              <DialogButton
                class="claim-pending"
                @click="claimItems"
                buttonText="Continue Pending Claim"
                :isLoading="turnHappening"
              />
              <DialogButton
                class="claim-pending"
                buttonText="Reject Pending Claim"
                title="Reject Pending Claim"
                @click="rejectPendingClaim"
                :isLoading="turnHappening"
              />
            </div>
          </div>
          <div id="stash-claim-button">
            <template v-if="getClassEquipments === 'selected'">
              <!-- <p
                class="select-item-message"
                v-if="equipmentsSelected.length === 0"
                id="stash-claim-button"
              >
                Select Items Above To Claim
              </p> -->
              <div class="equip-buttons">
                <div class="tabs">
                  <button
                    :class="equipButtonTab === 'claim' ? 'active' : ''"
                    @click="changeToClaim"
                  >
                    Claim
                  </button>
                  <button
                    :class="equipButtonTab === 'breakdown' ? 'active' : ''"
                    @click="changeToBreakdown"
                  >
                    Breakdown
                  </button>
                </div>
                <DialogButton
                  v-if="equipButtonTab === 'claim'"
                  :disabled="
                    equipmentsSelected.length === 0 ||
                    isLoading ||
                    equipmentsSelected.length > 10
                  "
                  class="claim-button"
                  :buttonText="`Claim Equipment (${equipmentsSelected.length}/10)`"
                  @click="claimItems"
                  :isLoading="isLoading"
                />
                <template v-else-if="equipButtonTab === 'breakdown'">
                  <DialogButton
                    :disabled="
                      equipmentSelectedBreakdown.length === 0 ||
                      equipmentSelectedBreakdown.length > 50 ||
                      turnHappening
                    "
                    v-if="!confirmationDisplay"
                    class="claim-button"
                    :buttonText="`Breakdown equipment (${equipmentSelectedBreakdown.length}/50)`"
                    @click="displayConfirmationButtons"
                    :isLoading="turnHappening"
                  />
                  <DialogButton
                    :buttonSize="'small'"
                    :disabled="
                      equipmentSelectedBreakdown.length === 0 ||
                      equipmentSelectedBreakdown.length > 50 ||
                      turnHappening
                    "
                    v-if="
                      equipmentSelectedBreakdown.length !== 0 &&
                      confirmationDisplay
                    "
                    class="claim-button"
                    :buttonText="`Confirm`"
                    @click="breakdownStash"
                    :isLoading="turnHappening"
                  />
                  <DialogButton
                    :buttonSize="'small'"
                    :disabled="
                      equipmentSelectedBreakdown.length === 0 ||
                      equipmentSelectedBreakdown.length > 50 ||
                      turnHappening
                    "
                    v-if="
                      equipmentSelectedBreakdown.length !== 0 &&
                      confirmationDisplay
                    "
                    class="claim-button"
                    :buttonText="`Cancel`"
                    @click="cancelBreakdown"
                    :isLoading="turnHappening"
                  />
                </template>
              </div>
            </template>
          </div>
          <template v-if="getClassConsumables === 'selected'">
            <p
              class="select-item-message"
              v-if="consumablesSelected.length === 0"
            >
              Select Items Above To Claim
            </p>
            <DialogButton
              v-else
              :disabled="consumablesSelected.length === 0 || isLoading"
              class="claim-button"
              :buttonText="`Claim Consumables (${consumablesSelected.length}/10)`"
              @click="claimItems"
              :isLoading="isLoading"
            />
            <!--
            //TODO - Cannot break down consumables just yet, so leave this out for now
            <DialogButton
              :disabled="consumablesSelected.length === 0 || isLoading"
              v-if="consumablesSelected.length !== 0"
              class="claim-button"
              :buttonText="`Breakdown Consumables (${consumablesSelected.length})`"
              @click="breakdownStash"
              :isLoading="isLoading"
            />
            -->
          </template>
          <template v-if="getClassResources === 'selected'">
            <p
              class="select-item-message"
              v-if="resourcesSelected.length === 0"
            >
              Select Items Above To Claim
            </p>
            <DialogButton
              v-else
              :disabled="resourcesSelected.length === 0 || isLoading"
              class="claim-button"
              :buttonText="`Claim Resources (${resourcesSelected.length}/10)`"
              @click="claimItems"
              :isLoading="isLoading"
            />
          </template>
          <template v-if="getClassTokens === 'selected'">
            <div class="grid balances">
              <div class="token-balance" id="dcau-balance">
                <p class="dcau">
                  {{ dcauAmount }}
                  <span v-if="!isLoadingDollarPrice" class="dollar-value"
                    >(${{ dcauAmountDollar }})</span
                  >
                  <SkeletalLoading v-else width="40" />
                </p>
                <DialogButton
                  :disabled="
                    isLoading ||
                    parseFloat(dcauAmount) === 0.0 ||
                    hasDcarPendingClaim
                  "
                  class="claim-button"
                  buttonText="Claim DCAU"
                  @click="claimTokenDcau"
                  :isLoading="isLoading"
                />
              </div>
              <div class="token-balance" id="dcar-balance">
                <p class="dcar">
                  {{ dcarAmount }}
                  <span v-if="!isLoadingDollarPrice" class="dollar-value"
                    >(${{ dcarAmountDollar }})</span
                  >
                  <SkeletalLoading v-else width="40" />
                </p>
                <DialogButton
                  :disabled="
                    isLoading ||
                    parseFloat(dcarAmount) === 0.0 ||
                    hasDcauPendingClaim
                  "
                  class="claim-button"
                  buttonText="Claim DCAR"
                  @click="claimTokenDcar"
                  :isLoading="isLoading"
                />
              </div>
            </div>
          </template>
          <template v-if="getClassChests === 'selected'">
            <div class="grid balances"></div>
          </template>
        </template>
        <GenericLoading v-else />
      </div>
      <!-- <span class="dcau-amount">{{ dcauAmount }} <span>DCAU</span></span> -->
    </div>
  </BasicPopup>
  <!-- </div> -->
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import stashClose from "../../assets/sound/character_open.wav";
import stashOpen from "../../assets/sound/inventory_open.wav";
import { useSound } from "../../composables/sound";
import Constants from "../../consts/constants";
import apiConnector from "../../game/apiConnector";
import DialogButton from "../DialogButton.vue";
import EquipmentTile from "../EquipmentTile.vue";
import GenericLoading from "../LoadingComponents/GenericLoading.vue";
import SkeletalLoading from "../LoadingComponents/SkeletalLoading.vue";
import BasicPopup from "../Popup/BasicPopup.vue";

import { ethers } from "ethers";
import { usePrice } from "../../composables/price";
import { useUser } from "../../composables/user";
import {
  getDCARContract,
  getDCAUContract,
  getDCGConsumablesContract,
  getDCGEquipmentContract,
  getDCGResourcesContract,
  getLoadRewardHandlerContract,
} from "../../utils/getContract";
import { parseError } from "../../utils/helpers";

export default {
  name: "StashInventory",
  components: {
    EquipmentTile,
    DialogButton,
    BasicPopup,
    GenericLoading,
    SkeletalLoading,
  },
  props: {},
  setup() {
    const store = useStore();
    const sessionId = ref(null);
    const dcauAmount = ref(0.0);
    const dcauAmountDollar = ref(0);
    const dcarAmount = ref(0.0);
    const dcarAmountDollar = ref(0);
    const dcauActual = ref(0.0);
    const dcarActual = ref(0.0);
    const isLoading = ref(false);
    const isLoadingDollarPrice = ref(false);
    // const hasEquipmentPendingClaim = ref(false);
    // const hasConsumablesPendingClaim = ref(false);
    // const hasResourcesPendingClaim = ref(false);
    // const hasTokensPendingClaim = ref(false);
    const pendingClaim = ref(null);
    const equipButtonTab = ref("claim");

    const { address, signer } = useUser();
    const { updateBalances, getDCAUPricePerDollar, getDCARPricePerDollar } =
      usePrice();

    const LoadRewardContract = getLoadRewardHandlerContract(signer.value);
    const DCAUContract = getDCAUContract(signer.value);
    const DCARContract = getDCARContract(signer.value);

    const openStashSound = useSound(stashOpen, {
      interrupt: true,
    });
    const closeStashSound = useSound(stashClose, {
      interrupt: true,
    });

    onMounted(async () => {
      openStashSound.play();
      sessionId.value = localStorage.getItem("sessionId");

      // store.watch(
      //   (state) => state.dcauInWallet,
      //   (value) => {
      //     dcauAmount.value = value;
      //   }
      // );

      // dcauAmount.value = store.state.dcauInWallet;

      // await getStash();
      await fetchPlayerTokens();
      store.dispatch("stash/updateStash");
      await fetchPendingClaim();
    });

    /**
     * Methods
     */
    /**
     * Methods to interact with Reward Contract and claim tokens
     */
    const getSignedClaimData = async (tokenAddress) => {
      let claimAmount;

      if (tokenAddress == DCAUContract.address) {
        claimAmount = ethers.utils.parseEther(
          dcauActual.value.toFixed(18).toString()
        );
      } else {
        claimAmount = ethers.utils.parseEther(
          dcarActual.value.toFixed(18).toString()
        );
      }

      console.log(claimAmount);

      const sessionId = localStorage.getItem("sessionId");
      const heroId = character.value.number;
      const response = await fetch(
        Constants.apiUrl + "contract/sign-gamerewards-claim",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId,
            heroId,
            claimAmount,
            account: address.value,
            tokenAddress,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (data.success === false) {
        store.commit("setNotification", `🛑 ${data.message} 🛑`);
        return data;
      } else {
        let finalClaimAmount = claimAmount;
        if (!pendingClaim.value.rewardHandler.hasUsed) {
          if (pendingClaim.value.rewardHandler.claimAmount !== 0) {
            finalClaimAmount = ethers.BigNumber.from(
              pendingClaim.value.rewardHandler.claimAmount.hex
            );
          }
        }
        return {
          success: data.success,
          tokenAddress,
          claimAmount: finalClaimAmount,
          signature: data.signature,
          nonce: data.nonce,
        };
      }
    };

    const fetchPendingClaim = async () => {
      const heroId = character.value.number;
      const sessionId = localStorage.getItem("sessionId");
      const type = filterType.value;
      const response = await fetch(
        Constants.apiUrl + "contract/has-pending-claim",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId,
            heroId,
            account: address.value,
            type,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        pendingClaim.value = data.pendingClaim;
        checkPendingClaim();
      }
      if (!data.success) {
        if (data.message && data.message == "invalid session") {
          store.commit("setSessionExpired", true);
        }
      }
    };
    const checkPendingClaim = () => {
      if (pendingClaim.value !== null) {
        switch (filterType.value) {
          case Constants.filterTypes.equipments:
            if (!pendingClaim.value.equipment.hasUsed) {
              store.commit("stash/setHasEquipmentPendingClaim", true);
            } else {
              store.commit("stash/setHasEquipmentPendingClaim", false);
            }
            break;
          case Constants.filterTypes.consumables:
            if (!pendingClaim.value.consumables.hasUsed) {
              store.commit("stash/setHasConsumablesPendingClaim", true);
            } else {
              store.commit("stash/setHasConsumablesPendingClaim", false);
            }
            break;
          case Constants.filterTypes.resources:
            if (!pendingClaim.value.resources.hasUsed) {
              store.commit("stash/setHasResourcesPendingClaim", true);
            } else {
              store.commit("stash/setHasResourcesPendingClaim", false);
            }
            break;
          case "tokens":
            if (!pendingClaim.value.rewardHandler.hasUsed) {
              // hasResourcesPendingClaim.value = true;
              const pendingClaimAmount = ethers.utils.formatEther(
                ethers.BigNumber.from(
                  pendingClaim.value.rewardHandler.claimAmount.hex
                )
              );
              if (
                pendingClaim.value.rewardHandler.tokenAddress.toLowerCase() ===
                DCAUContract.address.toLowerCase()
              ) {
                store.commit("stash/setHasDcauPendingClaim", true);
                store.commit(
                  "setNotification",
                  `🛑 You have a pending DCAU token claim of ${pendingClaimAmount} DCAU. You need to claim that first. Click on "Claim DCAU" to claim the pending amount first 🛑`
                );
              } else if (
                pendingClaim.value.rewardHandler.tokenAddress.toLowerCase() ===
                DCARContract.address.toLowerCase()
              ) {
                store.commit("stash/setHasDcarPendingClaim", true);
                store.commit(
                  "setNotification",
                  `🛑 You have a pending DCAR token claim of ${pendingClaimAmount} DCAR. You need to claim that first. Click on "Claim DCAR" to claim the pending amount first. 🛑`
                );
              }
            }
            break;
          default:
            break;
        }
      }
    };
    const rejectPendingClaim = async () => {
      if (pendingClaim.value !== null) {
        store.commit("setTurnHappening", true);
        const sessionId = localStorage.getItem("sessionId");
        const account = address.value;

        if (filterType.value == Constants.filterTypes.equipments) {
          if (!pendingClaim.value.equipment.hasUsed) {
            try {
              const DCGEquipment = getDCGEquipmentContract(signer.value);
              const tx = await DCGEquipment.claim(
                pendingClaim.value.equipment.claims,
                pendingClaim.value.equipment.isBlueprint,
                pendingClaim.value.equipment.blueprintQuantity,
                pendingClaim.value.equipment.claimCount,
                pendingClaim.value.equipment.signature,
                pendingClaim.value.equipment.nonce,
                true
              );
              const receipt = await tx.wait();
              if (receipt.status == 1) {
                const payload = {
                  sessionId,
                  account,
                  type: Constants.filterTypes.equipments,
                  blockNumber: receipt.blockNumber,
                };
                store.commit("stash/setRejectedClaim", payload);
                store.commit("setNotification", "Claim Rejected Successfully");
                store.commit("setTurnHappening", false);
              }
            } catch (error) {
              console.log(parseError(error));
              store.commit("setNotification", parseError(error));
              store.commit("setTurnHappening", false);
            }
          }
        }

        if (filterType.value == Constants.filterTypes.consumables) {
          if (!pendingClaim.value.consumables.hasUsed) {
            try {
              const DCGConsumables = getDCGConsumablesContract(signer.value);
              const tx = await DCGConsumables.claim(
                pendingClaim.value.consumables.claims,
                pendingClaim.value.consumables.quantities,
                pendingClaim.value.consumables.signature,
                pendingClaim.value.consumables.nonce,
                true
              );
              const receipt = await tx.wait();
              if (receipt.status == 1) {
                const payload = {
                  sessionId,
                  account,
                  type: Constants.filterTypes.consumables,
                  blockNumber: receipt.blockNumber,
                };
                store.commit("stash/setRejectedClaim", payload);
                store.commit("setNotification", "Claim Rejected Successfully");
                store.commit("setTurnHappening", false);
              }
            } catch (error) {
              console.log(parseError(error));
              store.commit("setNotification", parseError(error));
              store.commit("setTurnHappening", false);
            }
          }
        }

        if (filterType.value == Constants.filterTypes.resources) {
          if (!pendingClaim.value.resources.hasUsed) {
            try {
              const DCGResources = getDCGResourcesContract(signer.value);
              const tx = await DCGResources.claim(
                pendingClaim.value.resources.claims,
                pendingClaim.value.resources.quantities,
                pendingClaim.value.resources.signature,
                pendingClaim.value.resources.nonce,
                true
              );
              const receipt = await tx.wait();
              if (receipt.status == 1) {
                const payload = {
                  sessionId,
                  account,
                  type: Constants.filterTypes.resources,
                  blockNumber: receipt.blockNumber,
                };
                store.commit("stash/setRejectedClaim", payload);
                store.commit("setNotification", "Claim Rejected Successfully");
                store.commit("setTurnHappening", false);
              }
            } catch (error) {
              console.log(parseError(error));
              store.commit("setNotification", parseError(error));
              store.commit("setTurnHappening", false);
            }
          }
        }
      }
    };
    const claimTokenDcau = async () => {
      await claimToken(DCAUContract.address);
    };
    const claimTokenDcar = async () => {
      await claimToken(DCARContract.address);
    };
    const claimToken = async (address) => {
      isLoading.value = true;
      store.commit("setTurnHappening", true);
      const { success, tokenAddress, claimAmount, signature, nonce } =
        await getSignedClaimData(address);

      if (!success) {
        store.commit("setTurnHappening", false);
        isLoading.value = false;
        return;
      }

      try {
        // Executing Claim Token Transaction
        const tx = await LoadRewardContract.claimTokens(
          tokenAddress,
          claimAmount,
          signature,
          nonce
        );

        if (tx?.hash) {
          const txHash = tx.hash;
          await apiConnector.handleTransactionHash(
            store.state.account,
            sessionId.value,
            character.value.number,
            "rewardHandler",
            txHash
          );
        }

        const receipt = await tx.wait();
        // Only process if the transaction was successful!
        if (receipt.status === 1) {
          const sessionId = localStorage.getItem("sessionId");
          store.commit("setTurnHappening", false);
          updateBalances(); // Aynschronously updating token balances. No need to await as we don't need to show the balances on screen
          // Verifying if the transaction was correct and syncing the db
          const response = await fetch(
            Constants.apiUrl + "claim-game-rewards",
            {
              method: "POST",
              body: JSON.stringify({
                sessionId,
                account: store.state.account,
                tokenAddress,
                claimAmount,
                blockNumber: receipt.blockNumber,
                txHash: receipt.transactionHash,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const result = await response.json();
          console.log(result);
          if (
            tokenAddress.toLowerCase() === DCAUContract.address.toLowerCase()
          ) {
            store.commit("stash/setHasDcauPendingClaim", false);
          } else if (
            tokenAddress.toLowerCase() === DCARContract.address.toLowerCase()
          ) {
            store.commit("stash/setHasDcarPendingClaim", false);
          }

          await fetchPlayerTokens(); // Updating tokens shown in stash
          await fetchPendingClaim(); // Updating pending claim

          // TODO: Can directly be set to 0 but need to confirm if the flow is correct
        }
        isLoading.value = false;
      } catch (error) {
        console.log(error);
        store.commit("setNotification", parseError(error));
        await fetchPendingClaim();
        isLoading.value = false;
        store.commit("setTurnHappening", false);
      }
    };
    const changeToBreakdown = async () => {
      equipButtonTab.value = "breakdown";
    };
    const changeToClaim = async () => {
      equipButtonTab.value = "claim";
    };
    const fetchPlayerTokens = async () => {
      const response = await fetch(Constants.apiUrl + "playertokens", {
        method: "POST",
        body: JSON.stringify({
          account: store.state.account,
          sessionId: sessionId.value,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (result.success) {
        dcauActual.value = parseFloat(result.playerTokens.stashedDCAU);
        dcauAmount.value = parseFloat(result.playerTokens.stashedDCAU).toFixed(
          4
        );
        dcarActual.value = parseFloat(result.playerTokens.stashedDCAR);
        dcarAmount.value = parseFloat(result.playerTokens.stashedDCAR).toFixed(
          4
        );
        await fetchDollarValueOfTokens();
      }
    };

    // Helper method to delay stuff
    const delay = async (time) => new Promise((res) => setTimeout(res, time));

    const fetchDollarValueOfTokens = async () => {
      isLoadingDollarPrice.value = true;
      await delay(250);
      const dcauPerDollar = await getDCAUPricePerDollar();
      const dcarPerDollar = await getDCARPricePerDollar();
      dcauAmountDollar.value = (dcauActual.value / dcauPerDollar).toFixed(2);
      dcarAmountDollar.value = (dcarActual.value / dcarPerDollar).toFixed(2);
      isLoadingDollarPrice.value = false;
    };

    const closeStash = () => {
      if (!isLoading.value) {
        closeStashSound.play();
        store.commit("stash/setStashOpenStatusState", false);
        store.commit("hideStashWindow");
        store.dispatch("stash/resetItems");
      }
    };
    /**
     * Toggle item selection based on type of item
     * Update state accordingly
     */
    const toggleItem = (item) => {
      if (
        equipButtonTab.value === "breakdown" &&
        filterType.value === Constants.filterTypes.equipments
      ) {
        store.dispatch("stash/toggleItemBreakdown", item);
      } else {
        store.dispatch("stash/toggleItem", item);
      }
    };
    const isItemSelected = (id) => {
      // TODO: CAN BE REFACTORED IN A BETTER MANNER
      // TODO: Need to change the highlight style as well

      if (
        equipButtonTab.value === "breakdown" &&
        filterType.value === Constants.filterTypes.equipments
      ) {
        if (
          equipmentSelectedBreakdown.value.includes(id) ||
          consumablesSelected.value.includes(id) ||
          resourcesSelected.value.includes(id)
        ) {
          return true;
        }
      } else {
        if (
          equipmentsSelected.value.includes(id) ||
          consumablesSelected.value.includes(id) ||
          resourcesSelected.value.includes(id)
        ) {
          return true;
        }
      }
      return false;
    };

    /**
     * Method to interact with respective type contract
     * and mint the NFTs
     */
    const claimItems = async () => {
      // TODO: Interact with contract to mint NFTS
      // Wait for transaction to be completed
      // Once completed, call API to verify block and update the database for equips/resources/consumables
      isLoading.value = true;
      let items = [];
      let itemQuantities = [];

      if (filterType.value === Constants.filterTypes.equipments) {
        if (
          !pendingClaim.value?.equipment?.hasUsed &&
          pendingClaim.value.equipment.claims.length > 0
        ) {
          pendingClaim.value.equipment.claims.map((id) => {
            items.push(id);
          });
        } else {
          items = equipmentsSelected.value;
        }
        itemQuantities = null;
      } else if (filterType.value === Constants.filterTypes.consumables) {
        if (
          !pendingClaim.value?.consumables?.hasUsed &&
          pendingClaim.value.consumables.claims.length > 0
        ) {
          pendingClaim.value.consumables.claims.map((id, index) => {
            items.push(id);
            itemQuantities.push(
              pendingClaim.value.consumables.quantities[index]
            );
          });
        } else {
          items = consumablesSelected.value;
          itemQuantities = consumablesSelectedQuantities.value;
        }
      } else if (filterType.value === Constants.filterTypes.resources) {
        if (
          !pendingClaim.value?.resources?.hasUsed &&
          pendingClaim.value.resources.claims.length > 0
        ) {
          pendingClaim.value.resources.claims.map((id, index) => {
            items.push(id);
            itemQuantities.push(pendingClaim.value.resources.quantities[index]);
          });
        } else {
          items = resourcesSelected.value;
          itemQuantities = resourcesSelectedQuantities.value;
        }
      }

      const transactionStatus = await callClaimStashViaContract(
        items,
        itemQuantities,
        filterType.value
      );

      // let quantities = transactionStatus.quantities || 1;
      if (transactionStatus && transactionStatus.success) {
        const quantities = itemQuantities || 1;
        const results = await apiConnector.callClaimStash(
          store.state.account,
          sessionId.value,
          filterType.value,
          items,
          quantities,
          transactionStatus.blockNumber,
          transactionStatus.txHash
        );
        console.log(results);
        // // Refreshing the stash after claiming items
        // // TODO: Need to update the inventory too
        store.dispatch("stash/updateStash");
        await updateInventory();
        await fetchPendingClaim();
      }

      // TODO: Reset respective claimed Selection
      store.dispatch("stash/resetItems");
      isLoading.value = false;
    };

    const displayConfirmationButtons = async () => {
      store.state.stash.confirmationDisplay = true;
    };
    const cancelBreakdown = async () => {
      store.state.stash.confirmationDisplay = false;
    };
    /**
     * Method to bulk breakdown the stash
     * and update the DB
     */
    const breakdownStash = async () => {
      const heroId = character.value.number;
      // Allow users to breakdown stash before claiming the equipment
      // Once completed, call API to update the database for equips/resources/consumables
      store.commit("setTurnHappening", true);
      let items = [];
      let isStash = true;

      if (filterType.value === Constants.filterTypes.equipments) {
        items = equipmentSelectedBreakdown.value;
      } else if (filterType.value === Constants.filterTypes.consumables) {
        items = consumablesSelected.value;
      } else if (filterType.value === Constants.filterTypes.resources) {
        items = resourcesSelected.value;
      }

      const results = await apiConnector
        .breakdownItem(
          store.state.account,
          sessionId.value,
          heroId,
          Constants.apiURLs.breakdownItem,
          items,
          isStash,
          filterType.value
        )
        .then((result) => {
          store.state.doNotification(result.message);
          store.state.stash.confirmationDisplay = false;

          store.state.soundToPlay = Constants.sounds.breakdown;
          store.state.playSound = true;
        })
        .catch(function (e) {
          store.commit("setTurnHappening", false);
          store.state.stash.confirmationDisplay = false;
          // Handle rejection instead of erroring plus log the exception, plus put it back to wandering state, need to add an error message here
          console.log("Call has failed, please try again", e);
          store.state.gameState = Constants.gamemodes.wandering;
          return e;
        });

      console.log(results);
      // // Refreshing the stash after breaking down items
      store.dispatch("stash/updateStash");
      await updateInventory();

      store.dispatch("stash/resetItems");
      store.commit("setTurnHappening", false);
    };

    const callVerifyEquipmentApi = async (items) => {
      //  function claim(uint256[] memory claims, bool[] memory isBlueprint, uint256[] memory blueprintQuantity, uint256 amount, bytes memory signature, string memory nonce)
      const claims = [];
      const isBlueprint = [];
      const blueprintQuantity = [];
      const heroId = character.value.number;

      for (let i = 0; i < items.length; i++) {
        claims.push(items[i]);
        isBlueprint.push(true);
        blueprintQuantity.push(1);
      }
      const response = await fetch(
        Constants.apiUrl + "contract/sign-equipment-claim",
        {
          method: "POST",
          body: JSON.stringify({
            heroId,
            account: store.state.account,
            claims,
            isBlueprint,
            blueprintQuantity,
            amount: claims.length,
            sessionId: sessionId.value,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.success) {
        return {
          claims: result.claims,
          isBlueprint: result.isBlueprint,
          blueprintQuantity: result.blueprintQuantity,
          claimCount: result.claims.length,
          signature: result.signature,
          nonce: result.nonce,
        };
      } else {
        console.log(result.message);
        return null;
      }
    };

    const callVerifyResourceApi = async (items, itemQuantities) => {
      const claims = [];
      const quantities = [];
      const heroId = character.value.number;

      for (let i = 0; i < currentStash.value.length; i++) {
        if (items.includes(currentStash.value[i].id)) {
          claims.push(currentStash.value[i].id);
          const indexOfId = items.indexOf(currentStash.value[i].id);
          quantities.push(itemQuantities[indexOfId]); //Adding actual selected quantities instead of total stash quantities
        }
      }

      const response = await fetch(
        Constants.apiUrl + "contract/sign-resource-claim",
        {
          method: "POST",
          body: JSON.stringify({
            heroId,
            account: store.state.account,
            claims,
            quantities,
            amount: claims.length,
            sessionId: sessionId.value,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.success) {
        return {
          claims,
          quantities,
          signature: result.signature,
          nonce: result.nonce,
        };
      } else {
        console.log(result.message);
        return null;
      }
    };

    const callVerifyConsumableApi = async (items, itemQuantities) => {
      const claims = [];
      const quantities = [];
      const heroId = character.value.number;

      for (let i = 0; i < currentStash.value.length; i++) {
        if (items.includes(currentStash.value[i].id)) {
          claims.push(currentStash.value[i].id);
          // quantities.push(currentStash.value[i].quantity);
          const indexOfId = items.indexOf(currentStash.value[i].id);
          quantities.push(itemQuantities[indexOfId]); //Adding actual selected quantities instead of total stash quantities
        }
      }
      const response = await fetch(
        Constants.apiUrl + "contract/sign-consumable-claim",
        {
          method: "POST",
          body: JSON.stringify({
            heroId,
            account: store.state.account,
            claims,
            quantities,
            amount: claims.length,
            sessionId: sessionId.value,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      if (result.success) {
        return {
          claims,
          quantities,
          signature: result.signature,
          nonce: result.nonce,
        };
      } else {
        console.log(result.message);
        return null;
      }
    };

    const callClaimStashViaContract = async (items, itemQuantities, type) => {
      let receipt = null;
      let status = false;
      let blockNumber = 0;
      let txHash;
      store.commit("setTurnHappening", true);

      if (type === Constants.filterTypes.equipments) {
        const data = await callVerifyEquipmentApi(items);
        if (data !== null) {
          try {
            const DCGEquipment = getDCGEquipmentContract(signer.value);
            // Executing claim transaction
            const tx = await DCGEquipment.claim(
              data.claims,
              data.isBlueprint,
              data.blueprintQuantity,
              data.claimCount,
              data.signature,
              data.nonce,
              false
            );

            if (tx?.hash) {
              const txHash = tx.hash;
              await apiConnector.handleTransactionHash(
                store.state.account,
                sessionId.value,
                character.value.number,
                Constants.filterTypes.equipments,
                txHash
              );
            }

            receipt = await tx.wait();
            if (receipt.status === 1) {
              status = true;
              blockNumber = receipt.blockNumber;
              txHash = receipt.transactionHash;
              store.commit("setTurnHappening", false);
            }
          } catch (error) {
            console.log(parseError(error));
            store.commit("setNotification", parseError(error));
            await fetchPendingClaim(); // Checking if pending signature
            isLoading.value = true;
            store.commit("setTurnHappening", false);
          }
        }
        return { success: status, blockNumber, txHash };
      } else if (type === Constants.filterTypes.resources) {
        const data = await callVerifyResourceApi(items, itemQuantities);
        if (data !== null) {
          try {
            const DCGResources = getDCGResourcesContract(signer.value);
            const tx = await DCGResources.claim(
              data.claims,
              data.quantities,
              data.signature,
              data.nonce,
              false
            );

            if (tx?.hash) {
              const txHash = tx.hash;
              await apiConnector.handleTransactionHash(
                store.state.account,
                sessionId.value,
                character.value.number,
                Constants.filterTypes.resources,
                txHash
              );
            }

            receipt = await tx.wait();
            if (receipt.status === 1) {
              status = true;
              blockNumber = receipt.blockNumber;
              txHash = receipt.transactionHash;
              store.commit("setTurnHappening", false);
            }
          } catch (error) {
            console.log(parseError(error));
            store.commit("setNotification", parseError(error));
            await fetchPendingClaim(); // Checking if pending signature
            isLoading.value = true;
            store.commit("setTurnHappening", false);
          }
        }
        return {
          success: status,
          blockNumber,
          quantities: data.quantities,
          txHash,
        };
      } else if (type === Constants.filterTypes.consumables) {
        const data = await callVerifyConsumableApi(items, itemQuantities);
        if (data !== null) {
          try {
            const DCGConsumables = getDCGConsumablesContract(signer.value);
            const tx = await DCGConsumables.claim(
              data.claims,
              data.quantities,
              data.signature,
              data.nonce,
              false
            );

            if (tx?.hash) {
              const txHash = tx.hash;
              await apiConnector.handleTransactionHash(
                store.state.account,
                sessionId.value,
                character.value.number,
                Constants.filterTypes.consumables,
                txHash
              );
            }

            receipt = await tx.wait();
            if (receipt.status === 1) {
              status = true;
              blockNumber = receipt.blockNumber;
              txHash = receipt.transactionHash;
              store.commit("setTurnHappening", false);
            }
          } catch (error) {
            console.log(parseError(error));
            store.commit("setNotification", parseError(error));
            await fetchPendingClaim(); // Checking if pending signature
            isLoading.value = true;
            store.commit("setTurnHappening", false);
          }
        }
        return {
          success: status,
          blockNumber,
          txHash,
          quantities: data.quantities,
        };
      }
    };

    const filterEquipments = () => {
      if (store.state.isTurnHappening) {
        store.commit(
          "setNotification",
          "Please wait for the current transaction to complete."
        );
        return;
      }
      filterItems(Constants.filterTypes.equipments);
    };
    const filterConsumables = () => {
      if (store.state.isTurnHappening) {
        store.commit(
          "setNotification",
          "Please wait for the current transaction to complete."
        );
        return;
      }
      filterItems(Constants.filterTypes.consumables);
    };
    const filterResources = () => {
      if (store.state.isTurnHappening) {
        store.commit(
          "setNotification",
          "Please wait for the current transaction to complete."
        );
        return;
      }
      filterItems(Constants.filterTypes.resources);
    };
    const filterTokens = () => {
      if (store.state.isTurnHappening) {
        store.commit(
          "setNotification",
          "Please wait for the current transaction to complete."
        );
        return;
      }
      filterItems("tokens");
    };
    const filterChests = () => {
      if (store.state.isTurnHappening) {
        store.commit(
          "setNotification",
          "Please wait for the current transaction to complete."
        );
        return;
      }
      filterItems("chests");
    };
    const filterItems = async (filter) => {
      store.commit("stash/setFilterType", filter);
      checkPendingClaim();
    };

    const updateInventory = async () => {
      const inventoryResult = await apiConnector.callGetInventory(
        store.state.account,
        sessionId.value
      );
      store.commit("setInventory", inventoryResult.inventory);
    };

    /**
     * Computed
     */
    const hasEquipmentPendingClaim = computed(() => {
      return store.state.stash.hasEquipmentPendingClaim;
    });
    const hasConsumablesPendingClaim = computed(() => {
      return store.state.stash.hasConsumablesPendingClaim;
    });
    const hasResourcesPendingClaim = computed(() => {
      return store.state.stash.hasResourcesPendingClaim;
    });
    const hasDcauPendingClaim = computed(() => {
      return store.state.stash.hasDcauPendingClaim;
    });
    const hasDcarPendingClaim = computed(() => {
      return store.state.stash.hasDcarPendingClaim;
    });
    const confirmationDisplay = computed(() => {
      return store.state.stash.confirmationDisplay;
    });
    const showPendingBox = computed(() => {
      if (
        filterType.value === Constants.filterTypes.equipments &&
        hasEquipmentPendingClaim.value
      ) {
        return true;
      } else if (
        filterType.value === Constants.filterTypes.consumables &&
        hasConsumablesPendingClaim.value
      ) {
        return true;
      } else if (
        filterType.value === Constants.filterTypes.resources &&
        hasResourcesPendingClaim.value
      ) {
        return true;
      }
      return false;
    });
    const isLoadingStash = computed(() => {
      return store.state.stash.isLoadingStash;
    });
    const character = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });
    const filterType = computed(() => {
      return store.state.stash.filterType;
    });
    const equipmentsSelected = computed(() => {
      return store.state.stash.selectedEquipments;
    });
    const equipmentSelectedBreakdown = computed(() => {
      return store.state.stash.selectedEquipmentBreakdown;
    });
    const consumablesSelected = computed(() => {
      return store.state.stash.selectedConsumables;
    });
    const resourcesSelected = computed(() => {
      return store.state.stash.selectedResources;
    });
    const resourcesSelectedQuantities = computed(() => {
      return store.state.stash.selectedResourcesQuantities;
    });
    const consumablesSelectedQuantities = computed(() => {
      return store.state.stash.selectedConsumablesQuantities;
    });
    const isStashVisible = computed(() => {
      return store.state.stash.stashOpenStatus ? "show" : "";
    });
    const getClassEquipments = computed(() => {
      if (filterType.value == Constants.filterTypes.equipments) {
        return "selected";
      }
      return "";
    });
    const getClassConsumables = computed(() => {
      if (filterType.value == Constants.filterTypes.consumables) {
        return "selected";
      }
      return "";
    });
    const getClassResources = computed(() => {
      if (filterType.value == Constants.filterTypes.resources) {
        return "selected";
      }
      return "";
    });
    const turnHappening = computed(() => {
      return store.state.isTurnHappening;
    });
    const getClassTokens = computed(() => {
      if (filterType.value == "tokens") {
        return "selected";
      }
      return "";
    });
    const getClassChests = computed(() => {
      if (filterType.value == "chests") {
        return "selected";
      }
      return "";
    });
    const currentStash = computed(() => {
      let results = [];
      const stash = store.state.stash.stash;
      if (typeof stash !== "undefined") {
        switch (filterType.value) {
          case Constants.filterTypes.all:
            results = stash;
            break;
          case Constants.filterTypes.equipments:
            for (let i = 0; i < stash.length; i++) {
              const inventoryItem = stash[i];
              if (
                store.state.isArmourSlot(inventoryItem.type) ||
                inventoryItem.type === Constants.slots.hand2 ||
                inventoryItem.type === Constants.slots.hand1 ||
                inventoryItem.type === "blueprint"
              ) {
                results.push(inventoryItem);
              }
            }
            break;
          case Constants.filterTypes.consumables:
            for (let i = 0; i < stash.length; i++) {
              const inventoryItem = stash[i];
              if (inventoryItem.type === Constants.useable.consumable) {
                results.push(inventoryItem);
              }
            }
            break;
          case Constants.filterTypes.resources:
            for (let i = 0; i < stash.length; i++) {
              const inventoryItem = stash[i];
              if (inventoryItem.type === Constants.useable.resource) {
                results.push(inventoryItem);
              }
            }
            break;
          default:
            break;
        }
      }
      return results;
    });

    return {
      // data
      dcauAmount,
      dcauAmountDollar,
      dcarAmount,
      dcarAmountDollar,
      isLoadingDollarPrice,
      filterType,
      equipmentsSelected,
      equipmentSelectedBreakdown,
      consumablesSelected,
      resourcesSelected,
      isLoading,
      showPendingBox,
      equipButtonTab,
      // methods
      closeStash,
      filterEquipments,
      filterConsumables,
      filterResources,
      filterTokens,
      filterChests,
      toggleItem,
      changeToBreakdown,
      changeToClaim,
      isItemSelected,
      claimItems,
      displayConfirmationButtons,
      cancelBreakdown,
      breakdownStash,
      claimTokenDcau,
      claimTokenDcar,
      rejectPendingClaim,
      // computed
      isStashVisible,
      getClassEquipments,
      getClassConsumables,
      getClassResources,
      getClassTokens,
      getClassChests,
      currentStash,
      isLoadingStash,
      turnHappening,
      hasDcarPendingClaim,
      hasDcauPendingClaim,
      confirmationDisplay,
    };
  },
};
</script>

<style lang="scss">
.stash-inventory {
  &.popup h3 {
    height: 48px;
    font-size: 1.2rem;
    top: -60px;
  }
  .item {
    &.selected {
      img {
        background: #e6c356;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.stash-inventory {
  width: calc(388px - $popup-border-size - $popup-border-size);
  height: calc(450px - $popup-border-size - $popup-border-size);
  display: none;
  z-index: 4;
  &.show {
    display: block;
  }
  .close-button {
    top: -80px;
    right: -80px;
    position: absolute;
    &.loading {
      filter: grayscale(100);
      opacity: 0.5;
    }
  }
  .equip-buttons {
    .tabs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      button {
        background: #000;
        padding: 10px 0;
        border: none;
        color: #ffffff80;
        text-transform: uppercase;
        font-size: 0.8rem;
        border-bottom: 1px solid #ffffff46;
        &:hover {
          cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
            auto;
        }
        &.active {
          background: #181716;
          border-bottom: none;
          color: #fff;
        }
      }
    }
  }
  .stash-type {
    position: absolute;
    left: -37px;
    top: 20px;
    width: 330px;
    height: 100%;
    .items-container {
      width: 321px;
      height: 300px;
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 15px;
        height: 10px;
      }

      ::-webkit-scrollbar-track {
        background: transparent;
      }

      ::-webkit-scrollbar-button {
        background-color: transparent;
        width: 15px;
        height: 15px;
      }

      &::-webkit-scrollbar-thumb {
        border-top: 26px solid transparent;
        background: url("https://cdn.dragoncrypto.io/uiassets/scrollbar-slider.png")
          repeat-y;
        border-bottom: 32px solid transparent;
        background-clip: content-box;
      }
      .items {
        background: url("../../assets/ui/equipment-tile-bg.png") repeat;
        width: 295px;
        min-height: 294px;
        background-size: auto;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(44px, 1fr));
        grid-gap: 5.5px 5px;
        align-content: start;
        padding: 3px 2px;
        &.loading {
          &:after {
            content: "";
            display: block;
            width: 91%;
            height: 80%;
            background: transparent;
            top: 0;
            left: 0;
            position: absolute;
            z-index: 2;
          }
        }
      }
    }
  }
  p.select-item-message {
    font-size: 1rem;
    color: #beafaf;
    margin: 24px 0 0;
  }
  div#dcau-balance {
    border-bottom: 1px #fff3 solid;
  }
  .balances {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    .token-balance {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1px;
      align-items: center;
      justify-items: center;
      font-size: 1.2rem;
      height: 100%;
      p {
        font-size: 2rem;
        margin-bottom: 0;
        &:before {
          background-size: contain;
          content: "";
          display: inline-block;
          width: 46px;
          height: 46px;
          line-height: 0;
          margin-top: -8px;
          padding: 0;
          top: 14px;
          position: relative;
          margin-right: 4px;
        }
        &.dcau {
          &:before {
            background: url("../../assets/ui/dcau.png") no-repeat left center;
          }
        }
        &.dcar {
          &:before {
            background: url("../../assets/ui/dcar.png") no-repeat left center;
          }
        }
      }
      span {
        font-size: 0.7rem;
        display: block;
        margin: 2px 0 0;
        color: #ffffff85;
        &.skeleton-box {
          margin: 2px auto;
        }
      }
    }
    button {
      margin-bottom: 30px;
      margin-top: 0;
    }
  }
  .filters-content {
    display: grid;
    width: 56px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -121px;
    grid-gap: 0;
    img {
      &:hover {
        cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
          auto;
      }
    }
  }
}
div#pending-claim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(24 23 22);
  display: grid;
  align-items: center;
  z-index: 100;
  h4 {
    font-size: 1.4rem;
    text-transform: capitalize;
    margin: 0 0 10px;
    /* font-family: "IM Fell English", serif; */
    font-weight: normal;
  }
  p {
    width: 90%;
    margin: 30px auto;
  }
}
</style>
