<template>
  <!-- <SkeletalLoading class="loading" height="252" width="335" v-if="isLoading" /> -->
  <div class="loot-container-wrapper">
    <p>
      You opened <span>{{ chest.name }}</span> and received the following items
    </p>
    <div class="loot">
      <div class="content-wrapper">
        <div
          :class="[
            'loot-wrapper',
            loot.length > 4 ? 'high-quantity' : 'low-quantity',
          ]"
        >
          <div class="loot-item" v-for="lootItem in loot" :key="lootItem.id">
            <EquipmentTile :isLootChestItem="true" :item="lootItem" />
            <span :class="'desc ' + lootItem.rarity.toLowerCase()">{{
              lootItem.name
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <DialogButton
        buttonSize="med"
        @click="claimStash"
        :buttonText="'Claim stash'"
      />
      <DialogButton
        buttonSize="med"
        @click="continueLoot"
        :buttonText="'Continue'"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
// import { ethers } from "ethers";
// import { useUser } from "../../composables/user";
// import { getDCGLootChestsContract } from "../../utils/getContract";
// import { parseError } from "../../utils/helpers";
import { useStore } from "vuex";
import revealSound from "../../assets/sound/reveal.mp3";
import { useSound } from "../../composables/sound";
import DialogButton from "../DialogButton.vue";
import EquipmentTile from "../EquipmentTile.vue";

// import apiConnector from "../../game/apiConnector";

export default {
  name: "ShowLoot",
  components: {
    EquipmentTile,
    DialogButton,
  },
  emits: ["refresh"],
  props: {},
  setup(props, { emit }) {
    const store = useStore();

    onMounted(async () => {
      // await fetchMetadata();
      //   getLocalMetaData();
      await delay(200);
      playRevealSound.play();
    });

    const continueLoot = () => {
      store.commit("lootChests/setIsChestOpened", false);
      store.commit("lootChests/setOpenedChest", null);
      emit("refresh");
    };

    const playRevealSound = useSound(revealSound, {
      volume: store.state.soundVolume > 0 ? 1 : 0,
      interrupt: true,
    });
    // Helper method to delay stuff
    const delay = async (time) => new Promise((res) => setTimeout(res, time));

    const claimStash = () => {
      store.commit("lootChests/setIsChestOpened", false);
      store.commit("lootChests/setOpenedChest", null);
      store.commit("lootChests/setIsLootPopupOpen", false);
      store.commit("setInventoryVisible", false);
      store.dispatch("stash/openStashWindow");
    };

    const chest = computed(() => {
      return store.state.lootChests.openedChest;
    });
    const loot = computed(() => {
      if (
        store.state.lootChests.loot &&
        store.state.lootChests.loot.consumables &&
        store.state.lootChests.loot.consumables &&
        store.state.lootChests.loot.equipment
      ) {
        return [
          ...store.state.lootChests.loot.equipment,
          ...store.state.lootChests.loot.consumables,
          ...store.state.lootChests.loot.resources,
        ];
      }
      return [];
    });
    return {
      // Computed
      loot,
      chest,
      // Data
      // Methods
      continueLoot,
      claimStash,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.loot-container-wrapper {
  .buttons {
    margin-bottom: 2rem;
  }
  p {
    margin: 10px 0 0 !important;
    font-size: 1.1rem;
    color: #fff;
    span {
      color: $game-heading;
    }
  }
  .loot {
    width: 95%;
    height: 400px;
    position: relative;
    background: #171312;
    margin: 2rem auto;

    .content-wrapper {
      padding: 0;
      height: 100%;

      .loot-wrapper {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(279px, 1fr));
        grid-gap: 0.5rem;
        align-content: center;
        align-items: center;
        padding: 1% 0;

        &.low-quantity {
          grid-template-columns: 1fr;
          width: 300px;
          margin: 0 auto;
          display: grid !important;
          align-content: center;
          align-items: center;
        }

        &::-webkit-scrollbar {
          width: 15px;
          height: 10px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-button {
          background-color: transparent;
          width: 15px;
          height: 1px;
        }

        &::-webkit-scrollbar-thumb {
          background: url("https://cdn.dragoncrypto.io/uiassets/scrollbar-slider.png")
            repeat-y;
        }
      }

      .desc {
        font-size: 90%;
        width: 235px;
        text-align: left;
        padding: 6px;
        font-family: "IM Fell English", serif;

        &.rare {
          color: $rare;
        }

        &.epic {
          color: $epic;
        }

        &.mythical {
          color: $mythical;
        }

        &.legendary {
          color: $legendary;
        }

        &.fabled {
          color: $fabled;
        }

        &.unique {
          color: $unique;
        }

        &.artefact {
          color: $artefact;
        }

        &.uncommon {
          color: $uncommon;
        }
      }

      .loot-item {
        border: 1px solid #3d3d40;
        background: #151413;
        border-radius: 2px;
        margin-bottom: 3px;
        width: calc(100% - 2px);
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        max-height: 48px;
      }
    }
  }
}
</style>
