<template>
  <div class="hotbar">
    <div class="wrapper">
      <img
        class="ui"
        src="https://cdn.dragoncrypto.io/uiassets/hotbar.png"
        alt="Hotbar"
      />
      <div class="items-wrapper">
        <div
          :class="[
            'hotbar-item ',
            isTurnHappening || (hotbarItem.stats.action == 'heal' && isDead)
              ? 'disabled'
              : '',
            isTurnHappening ||
            (hotbarItem.stats.action == 'resetLevelPoints' && isDead)
              ? 'disabled'
              : '',
            !isAllowedToUseResurrection && isResurrectionItem(hotbarItem)
              ? ' disabled'
              : '',
            hotbarItem.stats.action === 'heal' ? 'consumable' : '',
          ]"
          v-for="(hotbarItem, index) in getHotbarItems"
          :key="hotbarItem.id"
          @click="useHotbar(index, 1)"
          @mouseover="showPopup = index"
          @mouseleave="showPopup = -1"
        >
          <span class="number">{{ index + 1 }}</span>
          <img
            :class="hotbarItem.rarity.toLowerCase()"
            :src="getHotbarImage(hotbarItem)"
            :alt="hotbarItem.name"
          />
          <div v-if="hotbarItem.quantity" class="quantity">
            {{ hotbarItem.quantity }}
          </div>

          <div
            :class="[
              'heal-popup',
              calculateHealing(hotbarItem) > 1 && hotbarItem.quantity > 1
                ? 'has-button'
                : '',
            ]"
            v-if="
              hotbarItem.stats.action == 'heal' &&
              showPopup === index &&
              !isDead
            "
            @mouseover="showPopup = index"
            @mouseleave="showPopup = -1"
          >
            <div class="consumable-details">
              <p class="title">{{ hotbarItem.name }}</p>
              <p>Heals {{ hotbarItem.stats.amount }}% of HP</p>
              <DialogButton
                :buttonSize="'small'"
                v-if="
                  calculateHealing(hotbarItem) > 1 && hotbarItem.quantity > 1
                "
                :buttonText="
                  hotbarItem.quantity < calculateHealing(hotbarItem)
                    ? `Use All (${hotbarItem.quantity})`
                    : `Max Heal (${calculateHealing(hotbarItem)})`
                "
                @click="
                  useHotbar(
                    index,
                    hotbarItem.quantity < calculateHealing(hotbarItem) // Passing wallet quantity if it's less than max calculated quantity
                      ? hotbarItem.quantity
                      : calculateHealing(hotbarItem)
                  )
                "
                id="consumable-button"
                title="Use more than one consumable at a time to heal fully"
                :isLoading="isTurnHappening"
              />
              <DialogButton
                :buttonSize="'small'"
                v-if="
                  calculateHealing(hotbarItem) > 1 && hotbarItem.quantity > 1
                "
                :buttonText="`Use (${healingPotionQuantityToUse})`"
                @click="useHotbar(index, healingPotionQuantityToUse)"
                id="consumable-button"
                title="Use more than one consumable at a time"
                :isLoading="isTurnHappening"
              />
              <div class="button-group">
                <RoundButton
                  size="small"
                  class="inlineleft quantity-button"
                  v-if="
                    calculateHealing(hotbarItem) > 1 && hotbarItem.quantity > 1
                  "
                  :buttonText="`+`"
                  @click.stop="incrementHealingPotion(hotbarItem)"
                  id="consumable-button-plus"
                  title="Use more than one consumable at a time"
                  :isLoading="isTurnHappening"
                >
                  +
                </RoundButton>
                <RoundButton
                  size="small"
                  class="inlineright quantity-button"
                  v-if="
                    calculateHealing(hotbarItem) > 1 && hotbarItem.quantity > 1
                  "
                  :buttonText="`-`"
                  @click.stop="decrementHealingPotion()"
                  id="consumable-button-minus"
                  title="Use more than one consumable at a time"
                  :isLoading="isTurnHappening"
                >
                  -
                </RoundButton>
              </div>
            </div>
          </div>
          <div
            class="heal-popup"
            v-else-if="
              hotbarItem.stats.action == 'resetLevelPoints' &&
              showPopup === index &&
              !isDead
            "
            @mouseover="showPopup = index"
            @mouseleave="showPopup = -1"
          >
            <div class="consumable-details">
              <p class="title">{{ hotbarItem.name }}</p>
              <p>Resets level stat points.</p>
            </div>
          </div>
          <div
            class="heal-popup"
            v-else-if="
              hotbarItem.stats.action == 'resurrection' &&
              showPopup === index &&
              !isDead
            "
            @mouseover="showPopup = index"
            @mouseleave="showPopup = -1"
          >
            <div class="consumable-details">
              <p class="title">{{ hotbarItem.name }}</p>
              <p>Resurrects hero when dead.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="exp-bar">
        <div class="bar" :style="{ width: expPercentage + '%' }"></div>
        <div class="text">
          {{ Math.floor(currentExp) }}/{{ Math.floor(expToLevel) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import constants from "../consts/constants";
import DialogButton from "./DialogButton.vue";
import RoundButton from "@/components/Buttons/RoundButton.vue";

export default {
  name: "Hotbar",
  components: {
    RoundButton,
    DialogButton,
  },
  data() {
    return {
      $store: {},
      showPopup: -1,
      healingPotionAmount: 1,
    };
  },
  methods: {
    useHotbar(index, quantity) {
      if (this.isTurnHappening) {
        return;
      }

      const hotbarItems = this.getHotbarItems;

      if (
        this.isResurrectionItem(hotbarItems[index]) &&
        !this.isAllowedToUseResurrection
      ) {
        this.$store.commit(
          "setNotification",
          "💀 You can only use this when dead. 💀"
        );
        return;
      }

      if (
        this.isHealingItem(hotbarItems[index]) &&
        !this.isAllowedToUseHealing
      ) {
        this.$store.commit(
          "setNotification",
          "🙈 You can only use this when not at full health."
        );
        return;
      }

      // add payload of amount and index here
      this.$store.commit("setUseHotbarItem", { index, quantity });
    },
    getHotbarImage(item) {
      return "https://ik.imagekit.io/dcg/equip/" + item.imageName + "?tr=w-90";
    },
    calculateHealing(item) {
      const maxHeal = item.stats.amount;
      const currentHealth =
        this.$store.state.characters[this.$store.state.currentCharacter].stats
          .hp;
      const maxHealth =
        this.$store.state.characters[this.$store.state.currentCharacter].stats
          .totalHp;

      const healPerUse = Math.ceil(
        (maxHealth - currentHealth) / ((maxHeal / 100) * maxHealth)
      );
      return healPerUse;
    },
    isResurrectionItem(item) {
      return item.stats.action == constants.consumableActions.resurrection;
    },
    isHealingItem(item) {
      return item.stats.action == constants.consumableActions.heal;
    },
    incrementHealingPotion(item) {
      if (
        this.healingPotionAmount < item.quantity &&
        this.healingPotionAmount < this.calculateHealing(item)
      ) {
        this.healingPotionAmount++;
      }
    },
    decrementHealingPotion() {
      if (this.healingPotionAmount > 1) {
        this.healingPotionAmount--;
      }
    },
  },

  computed: {
    isDead() {
      return this.$store.state.characters[this.$store.state.currentCharacter]
        .stats.isDead;
    },
    getHotbarItems() {
      return this.$store.state.hotbar();
    },
    currentExp() {
      //Need to handle what happens if the user has no character
      if (
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          null ||
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          "undefined"
      ) {
        return 0;
      }
      return this.$store.state.characters[this.$store.state.currentCharacter]
        .stats.exp;
    },
    expToLevel() {
      if (
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          null ||
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          "undefined"
      ) {
        return 0;
      }
      return this.$store.state.characters[this.$store.state.currentCharacter]
        .stats.expNextLevel;
    },
    expPercentage() {
      return (this.currentExp / this.expToLevel) * 100;
    },
    isTurnHappening() {
      return this.$store.state.isTurnHappening;
    },
    isAllowedToUseResurrection() {
      return this.$store.state.characters[this.$store.state.currentCharacter]
        .stats.isDead;
    },
    isAllowedToUseHealing() {
      return (
        this.$store.state.characters[this.$store.state.currentCharacter].stats
          .hp <
        this.$store.state.characters[this.$store.state.currentCharacter].stats
          .totalHp
      );
    },
    healingPotionQuantityToUse() {
      return this.healingPotionAmount;
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.hotbar {
  position: fixed;
  bottom: 0;
  height: 99px;
  width: 823px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  @media only screen and (max-width: 576px) {
    display: none; /* TODO: REMOVE DISPLAY NONE AND OPTIMISE FOR MOBILE */
  }
  .hotbar-item {
    &.consumable {
      cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
        auto;
    }
  }
  .exp-bar {
    position: absolute;
    width: 666px;
    height: 10px;
    bottom: 11px;
    left: 78px;
    overflow-x: hidden;

    .bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      box-shadow: 0 0 3px inset rgba(1, 91, 148, 1);

      background: rgb(1, 136, 186);
      background: linear-gradient(
        180deg,
        rgba(1, 136, 186, 1) 0%,
        rgba(0, 148, 225, 1) 5%,
        rgba(60, 205, 255, 1) 20%,
        rgba(0, 143, 223, 1) 39%,
        rgba(0, 101, 171, 1) 68%,
        rgba(1, 91, 148, 1) 92%,
        rgba(1, 111, 186, 1) 100%
      );

      transition: width 0.5s ease-out;
    }

    .text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 60%;
      line-height: 10px;
      text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 2px #000;
    }
  }

  .wrapper {
    width: 823px;
    margin: 0 auto;
    height: 100%;
    position: relative;

    .ui {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .items-wrapper {
      top: 19px;
      left: 74px;
      position: absolute;
      height: 47px;
    }

    .hotbar-item {
      width: 47px;
      height: 47px;
      display: inline-block;
      position: relative;
      margin-right: 10px;
      transition: opacity 0.25s linear;
      opacity: 1;

      &.disabled {
        transition: opacity 0.25s linear;
        filter: grayscale(100);
        opacity: 0.5;
      }

      .quantity {
        position: absolute;
        bottom: 5px;
        left: 10px;
        width: 100%;
        text-align: left;
        height: 12px;
        color: $text-highlight;
        z-index: 100;
        font-size: 80%;
        font-weight: bold;
        text-shadow: 0 0 2px #000, 0 0 2px #000, 0 1px 3px #000;
      }

      .number {
        position: absolute;
        top: 5px;
        right: 4px;
        font-size: 65%;
      }

      .artefact {
        border: 2px solid $artefact;
        border-radius: 1.5px;
      }

      .shoddy {
        border: 2px solid $shoddy;
        border-radius: 1.5px;
      }

      .epic {
        border: 2px solid $epic;
        border-radius: 1.5px;
      }

      .legendary {
        border: 2px solid $legendary;
        border-radius: 1.5px;
      }

      .fabled {
        border: 2px solid $fabled;
        border-radius: 1.5px;
      }

      .unique {
        border: 2px solid $unique;
        border-radius: 1.5px;
      }

      .rare {
        border: 2px solid $rare;
        border-radius: 1.5px;
      }

      .mythical {
        border: 2px solid $mythical;
        border-radius: 1.5px;
      }

      .uncommon {
        border: 2px solid $uncommon;
        border-radius: 1.5px;
      }

      .common,
      .normal {
        border: 2px solid transparent;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .heal-popup {
    position: absolute;
    display: grid;
    padding: 10px;
    border-radius: 10px;
    transition: all 0.5s;
    background: rgba(0, 0, 0, 0.9);
    top: -195%;
    left: 50%;
    min-width: 170px;
    transform: translateX(-50%);
    z-index: 5;
    &.has-button {
      top: -408%;
    }
    .consumable-details {
      &:after {
        display: block;
        content: "";
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        border-top: 30px solid rgba(0, 0, 0, 0.9);
        width: 0;
        height: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -20px;
      }
      h6 {
        margin: 0 0 10px;
      }
      p {
        font-size: 0.9rem;
        margin: 0 0 10px;
        &.title {
          color: #d79867;
        }
      }
      button {
        margin-top: 0;
      }
    }
  }
}
</style>
