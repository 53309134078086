<template>
  <div :class="getClass">
    <div class="inner-dialog-wrapper">
      <GameMessage
        :heading="currentEvent.event.description"
        :icon="'Priestskill_29_nobg'"
      >
        <template v-if="!currentEvent.hasChosen">
          <p>What will you do?</p>
        </template>
        <template v-else>
          <template v-if="currentEvent.hasWon">
            <p>{{ currentEvent.event.successMessage }}</p>
          </template>
          <template v-else>
            <p>{{ currentEvent.event.failMessage }}</p>
          </template>
        </template>
      </GameMessage>
      <div class="buttons">
        <template v-if="!currentEvent.hasChosen">
          <DialogButton
            :disabled="isTurnHappening"
            :isLoading="isTurnHappening"
            @buttonClick="$emit('choice1')"
            :buttonText="currentEvent.event.choice1"
          />
          <DialogButton
            :disabled="isTurnHappening"
            :isLoading="isTurnHappening"
            @click="$emit('leave')"
            :buttonText="currentEvent.event.choice2"
          />
          <DialogButton
            :disabled="isTurnHappening"
            :isLoading="isTurnHappening"
            @buttonClick="$emit('searchAbandon')"
            :requiresTurn="true"
            :buttonText="'Search the Plains'"
          />
        </template>
        <template v-else>
          <template v-if="currentEvent.hasWon">
            <DialogButton
              :disabled="isTurnHappening"
              :isLoading="isTurnHappening"
              @click="$emit('leaveFinished')"
              :buttonText="'Leave'"
            />
            <DialogButton
              :disabled="isTurnHappening"
              :isLoading="isTurnHappening"
              @buttonClick="$emit('search')"
              :requiresTurn="true"
              :buttonText="'Search the Plains'"
            />
          </template>
          <template v-else>
            <DialogButton
              :disabled="isTurnHappening"
              :isLoading="isTurnHappening"
              @click="$emit('leaveFinished')"
              :buttonText="'Flee the scene'"
            />
            <DialogButton
              :disabled="isTurnHappening"
              :isLoading="isTurnHappening"
              @buttonClick="$emit('search')"
              :requiresTurn="true"
              :buttonText="'Search the Plains'"
            />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import GameMessage from "./GameMessage.vue";
import DialogButton from "./DialogButton.vue";
import Constants from "../consts/constants";

export default {
  name: "HealersHut",
  components: {
    GameMessage,
    DialogButton,
  },
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    isTurnHappening() {
      return this.$store.state.isTurnHappening;
    },
    currentEvent() {
      return this.$store.state.currentMultichoice;
    },
    getClass() {
      return (
        "multichoice-wrapper wrap-content " +
        (this.$store.state.gameState == Constants.gamemodes.multichoice
          ? "show"
          : "hide")
      );
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;
}

.highlight {
  font-weight: bold;
  color: $dmg;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  font-size: 90%;
}
</style>
