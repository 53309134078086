<template>
  <div :class="'market-sell-wrapper ' + currentClass">
    <div class="market-sell-menu">
      <div class="close-button button-small" @click="hide"><span>x</span></div>
      <div
        v-if="currentItem && currentItem.rarity"
        :class="'title ' + currentItem.rarity.toLowerCase()"
      >
        <div class="img-wrapper">
          <img :src="currentImage" :alt="currentItem.name" />
        </div>
        <h2 :class="currentItem.rarity.toLowerCase()">
          {{ currentItem.name }}
          <span class="broken" v-if="itemStats && itemStats.isBroken"
            >[Broken]</span
          >
        </h2>
      </div>
      <div class="quantity inputs" v-if="currentItem && currentItem.quantity">
        <label for="quantity"
          >Quantity<input
            type="number"
            name="quantity"
            :max="currentItem.quantity"
            min="1"
            v-model="currentQuantity"
        /></label>
      </div>
      <div :class="['sell-price', 'inputs', currentCategory]">
        <label for="sell-price"
          >Sell Price<input
            type="number"
            name="sell-price"
            min="0.1"
            step="0.1"
            v-model="currentPrice"
            @keyup="updateCurrentPriceDollar"
        /></label>
      </div>
      <label class="dollar-value">${{ currentPriceDollar }} USD</label>
      <div class="buttons">
        <template v-if="!isApprovedForAll">
          <DialogButton
            :buttonText="'Approve This Item'"
            @click="approveCurrentItem"
            :isLoading="isApprovingForOne"
            :disabled="isApprovingForOne || isApprovingForAll"
          />
          <DialogButton
            :buttonText="`Approve All ${currentCategory}`"
            @click="approveAllCurrentItemType"
            :isLoading="isApprovingForAll"
            :disabled="isApprovingForOne || isApprovingForAll"
          />
        </template>
        <DialogButton
          v-else
          :buttonText="'Sell'"
          @click="sellCurrentItem"
          :isLoading="isLoading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import DialogButton from "../DialogButton.vue";
import Constants from "../../consts/constants";
import { useMixpanel } from "../../composables/mixpanel";

import { useUser } from "../../composables/user";
import { usePrice } from "../../composables/price";
import { parseError } from "../../utils/helpers";
import { ethers } from "ethers";
import {
  getLoadMarketplaceContract,
  getDCGEquipmentContract,
  getDCGResourcesContract,
  getDCGConsumablesContract,
} from "../../utils/getContract";

export default {
  name: "ListMarketItem",
  components: {
    DialogButton,
  },
  props: {},
  setup() {
    /**
     * Data
     */
    const store = useStore();
    const isVisible = ref(true);
    const isInventoryOpen = ref(false);
    const currentQuantity = ref(1);
    const currentPrice = ref(0);
    const currentPriceDollar = ref(0);
    const isLoading = ref(false);
    const isApprovingForOne = ref(false);
    const isApprovingForAll = ref(false);
    const dcauPerDollar = ref(0);
    const dcarPerDollar = ref(0);

    const isApprovedForAll = ref(false);
    const { trackEvent } = useMixpanel();
    const { address, signer } = useUser();
    const { getDCAUPricePerDollar, getDCARPricePerDollar } = usePrice();

    const MarketplaceContract = getLoadMarketplaceContract(signer.value);
    const EquipmentsContract = getDCGEquipmentContract(signer.value);
    const ResourcesContract = getDCGResourcesContract(signer.value);
    const ConsumablesContract = getDCGConsumablesContract(signer.value);

    /**
     * Methods
     */
    // Helper method to delay stuff
    const delay = async (time) => new Promise((res) => setTimeout(res, time));

    const updateCurrentPriceDollar = async () => {
      await delay(500);
      console.log("currentPrice", currentPrice.value);
      console.log("dcauPerDollar.value", dcauPerDollar.value);
      console.log("dcarPerDollar.value", dcarPerDollar.value);
      if (currentCategory.value === "equipment") {
        currentPriceDollar.value = (
          currentPrice.value / dcauPerDollar.value
        ).toFixed(3);
      } else if (
        currentCategory.value === "resource" ||
        currentCategory.value === "consumable"
      ) {
        currentPriceDollar.value = (
          currentPrice.value / dcarPerDollar.value
        ).toFixed(3);
      }
    };
    const playOnList = () => {
      const sound = new Audio(
        "https://cdn.dragoncrypto.io/sound/shop-bell.mp3"
      );
      sound.volume = store.state.soundVolume;
      sound.play();
    };
    const hovered = () => {
      store.commit("setTooltipHovered");
    };
    const unhovered = () => {
      store.commit("setTooltipUnhovered");
    };
    const hide = () => {
      store.commit("hideMarketSelling");
    };
    const listItemToMarketplace = async (
      itemId,
      listPrice,
      quantity,
      itemType,
      rarity,
      name,
      blockNumber
    ) => {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const account = accounts.length > 0 ? accounts[0] : "";
      const sessionId = localStorage.getItem("sessionId");
      const nftid = character.value.number;

      if (!account || !sessionId || !nftid) {
        return;
      }

      const response = await fetch(
        Constants.apiUrl +
          "marketplace/list-item?type=" +
          store.state.marketFilter +
          "&page=1" +
          "&sortBy=createdAt&sortOrder=desc",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId,
            nftid,
            itemId,
            account,
            listPrice,
            itemType,
            rarity,
            name,
            quantity,
            blockNumber,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      playOnList();
      const result = await response.json();

      store.commit("marketplace/setMarketData", result.marketplaceItems);
      await store.dispatch("marketplace/updateMyListedItems");
      store.commit("setInventory", result.inventory);

      if (result.success) {
        store.commit("setNotification", "Item Listed Successfully!");
      } else {
        store.commit("setNotification", `Item not listed: ${result.message}`);
      }

      store.commit("setRemoveTooltip");
      store.commit("hideMarketSelling");
      return result;
    };

    const sellCurrentItem = async () => {
      const itemId = currentItem.value.id;
      const listPrice = currentPrice.value;
      const quantity = currentQuantity.value;
      const itemType = currentItem.value.type;
      const rarity = currentItem.value.rarity || "";
      const name = currentItem.value.name;
      const category = currentCategory.value;
      isLoading.value = true;

      if (quantity <= 0) {
        store.commit("setNotification", "Quantity cannot be 0");
        isLoading.value = false;
        return;
      }
      if (quantity > currentItem.value.quantity) {
        store.commit(
          "setNotification",
          "Quantity cannot be greater than the item's quantity"
        );
        isLoading.value = false;
        return;
      }
      if (listPrice < 0) {
        store.commit("setNotification", "Price cannot be less than 0");
        isLoading.value = false;
        return;
      }

      /**
       * TODO: only for equipments at present
       */
      let status = null;
      if (category === "equipment") {
        status = await listEquipmentItem(itemId, listPrice, quantity);
      } else if (category === "resource") {
        status = await listResourceItem(itemId, listPrice, quantity);
      } else if (category === "consumable") {
        status = await listConsumableItem(itemId, listPrice, quantity);
      }
      // If transaction successful, update game data
      if (status.success) {
        const result = await listItemToMarketplace(
          itemId,
          listPrice,
          quantity,
          itemType,
          rarity,
          name,
          status.blockNumber
        );
        isLoading.value = false;

        if (result.success) {
          await store.dispatch("updateInventory");
        }

        // store.commit("setSellItem");

        trackEvent("Marketplace - List Item", {
          itemId,
          listPrice,
          quantity,
          itemType,
          rarity,
          name,
        });
      }
      isLoading.value = false;
    };

    /**
     * Method to interact with marketplace contract
     * to list an equipment item
     */
    const listEquipmentItem = async (itemId, listPrice, quantity) => {
      try {
        // Doing the contract transaction

        // Executing Marketplace Listing Transaction
        const tx = await MarketplaceContract.saleNFT(
          EquipmentsContract.address,
          ethers.utils.parseUnits(`${itemId}`, 0),
          ethers.utils.parseUnits(`${listPrice}`, "ether"),
          ethers.utils.parseUnits(`${quantity}`, 0)
        );
        const receipt = await tx.wait();
        console.log(receipt);
        if (receipt.status === 1) {
          return { success: true, blockNumber: receipt.blockNumber };
        } else {
          return { success: false };
        }
      } catch (error) {
        isLoading.value = false;
        console.log(error);
        return { success: false };
      }
    };

    /**
     * Method to interact with marketplace contract
     * to list a resource item
     */
    const listResourceItem = async (itemId, listPrice, quantity) => {
      try {
        // Doing the contract transaction

        // Executing Marketplace Listing Transaction
        const tx = await MarketplaceContract.saleNFT(
          ResourcesContract.address,
          ethers.utils.parseUnits(`${itemId}`, 0),
          ethers.utils.parseUnits(`${listPrice}`, "ether"),
          ethers.utils.parseUnits(`${quantity}`, 0)
        );
        const receipt = await tx.wait();
        console.log(receipt);
        if (receipt.status === 1) {
          return { success: true, blockNumber: receipt.blockNumber };
        } else {
          return { success: false };
        }
      } catch (error) {
        //TODO: SHOW ERRORS IN NOTIFICATION POPUP
        isLoading.value = false;
        console.log(error);
        return { success: false };
      }
    };

    /**
     * Method to interact with marketplace contract
     * to list a consumable item
     */
    const listConsumableItem = async (itemId, listPrice, quantity) => {
      try {
        // Executing Marketplace Listing Transaction
        const tx = await MarketplaceContract.saleNFT(
          ConsumablesContract.address,
          ethers.utils.parseUnits(`${itemId}`, 0),
          ethers.utils.parseUnits(`${listPrice}`, "ether"),
          ethers.utils.parseUnits(`${quantity}`, 0)
        );
        const receipt = await tx.wait();
        console.log(receipt);
        if (receipt.status === 1) {
          return { success: true, blockNumber: receipt.blockNumber };
        } else {
          return { success: false };
        }
      } catch (error) {
        isLoading.value = false;
        console.log(error);
        return { success: false };
      }
    };

    const checkIsApprovedForAll = async () => {
      const category = currentCategory.value;
      if (category === "equipment") {
        const result = await EquipmentsContract.isApprovedForAll(
          address.value,
          MarketplaceContract.address
        );
        isApprovedForAll.value = result;
      } else if (category === "resource") {
        const result = await ResourcesContract.isApprovedForAll(
          address.value,
          MarketplaceContract.address
        );
        isApprovedForAll.value = result;
      } else if (category === "consumable") {
        const result = await ConsumablesContract.isApprovedForAll(
          address.value,
          MarketplaceContract.address
        );
        isApprovedForAll.value = result;
      }
    };

    const approveCurrentItem = async () => {
      const category = currentCategory.value;
      isApprovingForOne.value = true;
      try {
        const itemId = currentItem.value.id;
        if (category === "equipment") {
          const approveEquipmentIdTx = await EquipmentsContract.approve(
            MarketplaceContract.address,
            itemId
          );
          const receiptOfApproval = await approveEquipmentIdTx.wait();

          if (receiptOfApproval.status !== 1) {
            isApprovingForOne.value = false;
            return;
          }
        } else if (category === "resource") {
          const approveResourcetIdTx =
            await ResourcesContract.setApprovalForAll(
              MarketplaceContract.address,
              true
            );
          const receiptOfApproval = await approveResourcetIdTx.wait();

          if (receiptOfApproval.status !== 1) {
            isApprovingForOne.value = false;
            return;
          }
        } else if (category === "consumable") {
          const approveConsumabletIdTx =
            await ConsumablesContract.setApprovalForAll(
              MarketplaceContract.address,
              true
            );
          const receiptOfApproval = await approveConsumabletIdTx.wait();

          if (receiptOfApproval.status !== 1) {
            isApprovingForOne.value = false;
            return;
          }
        }

        isApprovedForAll.value = true;
        isApprovingForOne.value = false;
      } catch (error) {
        isApprovingForOne.value = false;
        store.commit("setNotification", parseError(error));
      }
    };

    const approveAllCurrentItemType = async () => {
      const category = currentCategory.value;
      try {
        isApprovingForAll.value = true;
        if (category === "equipment") {
          const approveEquipmentIdTx =
            await EquipmentsContract.setApprovalForAll(
              MarketplaceContract.address,
              true
            );
          const receiptOfApproval = await approveEquipmentIdTx.wait();

          // If marketplace contract not approved to transfer the token id
          if (receiptOfApproval.status !== 1) {
            isApprovingForAll.value = false;
            return;
          }
        } else if (category === "resource") {
          const approveResourcetIdTx =
            await ResourcesContract.setApprovalForAll(
              MarketplaceContract.address,
              true
            );
          const receiptOfApproval = await approveResourcetIdTx.wait();

          if (receiptOfApproval.status !== 1) {
            isApprovingForAll.value = false;
            return;
          }
        } else if (category === "consumable") {
          const approveConsumabletIdTx =
            await ConsumablesContract.setApprovalForAll(
              MarketplaceContract.address,
              true
            );
          const receiptOfApproval = await approveConsumabletIdTx.wait();

          if (receiptOfApproval.status !== 1) {
            isApprovingForAll.value = false;
            return;
          }
        }
        isApprovedForAll.value = true;
        isApprovingForAll.value = false;
      } catch (error) {
        store.commit("setNotification", parseError(error));
        isApprovingForAll.value = false;
      }
    };

    /**
     * Computed Methods
     */
    const character = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });

    const isWandering = computed(() => {
      return store.state.gameState == Constants.gamemodes.wandering;
    });

    const isWeapon = computed(() => {
      return store.state.hoveredItem.type == Constants.slots.hand1;
    });
    const isResources = computed(() => {
      if (itemStats.value && itemStats.value.resources) {
        return true;
      }

      return false;
    });
    const currentClass = computed(() => {
      return store.state.isMarketSelling ? "show" : "hide";
    });
    const currentItem = computed(() => {
      return store.state.hoveredItem;
    });

    const currentCategory = computed(() => {
      if (currentItem.value.type === "consumable") return "consumable";
      else if (currentItem.value.type === "resource") return "resource";
      else return "equipment";
    });
    const currentImage = computed(() => {
      if (!currentItem.value || !currentItem.value.imageName) {
        return "";
      }
      return (
        "https://ik.imagekit.io/dcg/equip/" +
        currentItem.value.imageName +
        "?tr=w-90"
      );
    });

    const itemStats = computed(() => {
      if (!currentItem.value) {
        return null;
      }
      return currentItem.value.stats;
    });

    /**
     * Watchers
     */
    watch(
      currentQuantity,
      // (messages, prevMessages) => {
      () => {
        store.commit("setCurrentSellQuantity", currentQuantity.value);
      }
    );

    watch(
      currentPrice,
      // (messages, prevMessages) => {
      () => {
        store.commit("setCurrentSellPrice", currentPrice.value);
      }
    );

    // OnMounted not needed till now
    onMounted(async () => {
      await checkIsApprovedForAll();
      dcauPerDollar.value = await getDCAUPricePerDollar();
      dcarPerDollar.value = await getDCARPricePerDollar();
    });

    return {
      /* Data */
      isVisible,
      isInventoryOpen,
      currentQuantity,
      currentPrice,
      currentPriceDollar,
      isLoading,
      isApprovedForAll,
      isApprovingForOne,
      isApprovingForAll,

      /* Methods */
      hovered,
      unhovered,
      hide,
      sellCurrentItem,
      approveCurrentItem,
      approveAllCurrentItemType,
      updateCurrentPriceDollar,

      /* Computed Methods */
      character,
      isWandering,
      isWeapon,
      isResources,
      currentClass,
      currentItem,
      currentImage,
      itemStats,
      currentCategory,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.market-sell-wrapper {
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  &.show {
    opacity: 1;
    pointer-events: all;

    transition: opacity 0.35s linear;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.35s linear;
  }
}

.market-sell-menu {
  background: url("https://cdn.dragoncrypto.io/uiassets/tooltip.png") no-repeat
    top left;
  width: 298px;
  height: 334px;
  position: relative;

  .close-button {
    position: absolute;
    top: -2px;
    right: -2px;
  }

  .broken {
    color: $dmg;
  }

  .title {
    padding-top: 8px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: center;
    border-bottom: 1px solid #3d3d40;

    &.legendary {
      // .img-wrapper{

      // }

      h2 {
        color: $legendary;
      }
    }

    &.fabled {
      h2 {
        color: $fabled;
      }
    }

    &.unique {
      h2 {
        color: $unique;
      }
    }

    &.uncommon {
      h2 {
        color: $uncommon;
      }
    }

    &.shoddy {
      h2 {
        color: $shoddy;
      }
    }

    &.rare {
      h2 {
        color: $rare;
      }
    }

    &.mythical {
      h2 {
        color: $mythical;
      }
    }

    &.epic {
      h2 {
        color: $epic;
      }
    }

    &.artefact {
      h2 {
        color: $artefact;
      }
    }

    img {
      max-width: 45px;
      margin-right: 12px;
    }

    h2 {
      color: #fff;
      text-align: left;
      font-family: "IM Fell English", serif;
      font-size: 90%;
      text-transform: none;
      width: 75%;
    }
  }

  .inputs {
    width: 90%;
    margin: 16px auto;
    font-family: "IM Fell English", serif;
    font-size: 90%;
    label {
      &:after {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 0;
        margin-top: -8px;
        padding: 0;
        top: 7px;
        position: relative;
        margin-left: 4px;
      }
    }

    input {
      width: 64px;
      font-size: 105%;
      margin-left: 16px;
    }
    &.equipment {
      label {
        &:after {
          background: url("../../assets/ui/dcau.png") no-repeat left center;
          background-size: contain;
        }
      }
    }
    &.consumable,
    &.resource {
      label {
        &:after {
          background: url("../../assets/ui/dcar.png") no-repeat left center;
          background-size: contain;
        }
      }
    }
  }
  .dollar-value {
    font-size: 0.7rem;
    color: #ffffff85;
    position: relative;
    top: 0px;
    width: 80%;
    margin: 0 auto;
    display: block;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 9px;

    .dialog-button {
      margin-top: 0;
    }
  }
}
</style>
