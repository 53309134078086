<template>
  <div
    class="volume-button button"
    :class="[buttonToggleClass, buttonMuteClass]"
    @click="toggle = !toggle"
  >
    <div class="volume-manager">
      <button
        class="mute"
        :class="muteToggle ? 'muted' : 'unmuted'"
        @click="muteSound"
      >
        <template v-if="muteToggle">Off</template>
        <template v-if="!muteToggle">On</template>
      </button>
      <input
        type="range"
        min="1"
        max="100"
        class="slider"
        id="volume"
        v-model="soundVol"
      />
      <span>{{ soundVol }} %</span>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { ref, watch, onMounted, computed } from "vue";
import { useMixpanel } from "../composables/mixpanel";

// import Constants from "../consts/constants";

export default {
  name: "VolumeButton",
  components: {},
  setup() {
    const store = useStore();
    const soundVol = ref(null);
    const toggle = ref(false);
    const muteToggle = ref(false);

    const buttonToggleClass = computed(() => (toggle.value ? "active" : ""));
    const buttonMuteClass = computed(() => (muteToggle.value ? "muted" : ""));

    // Mixpanel Tracking
    const { trackEvent } = useMixpanel();

    const setSoundVolume = (newVol) => {
      let decimalVol = 0;
      if (newVol == 0) {
        decimalVol = 0;
      } else {
        decimalVol = newVol / 100;
      }
      store.commit("setVolume", decimalVol);

      localStorage.setItem("soundVolume", decimalVol);
    };

    onMounted(() => {
      const savedVolume = localStorage.getItem("soundVolume");
      if (savedVolume !== null) {
        store.commit("setVolume", savedVolume);
      }
      soundVol.value = (parseFloat(store.state.soundVolume) * 100).toFixed(0);
    });

    watch(soundVol, (newVol) => {
      if (newVol > 0) {
        muteToggle.value = false;
      }
      setSoundVolume(newVol);
    });

    const muteSound = () => {
      if (!muteToggle.value) {
        soundVol.value = 0;
        // store.commit("setVolume", 0);
        muteToggle.value = true;

        // Mixpanel tracking mute
        trackEvent("Muted", {
          state: store.state.gameState,
        });
      } else {
        soundVol.value = 50;
        // store.commit("setVolume", 0.5);
        muteToggle.value = false;

        // Mixpanel tracking mute
        trackEvent("Unmuted", {
          state: store.state.gameState,
        });
      }
    };

    return {
      setSoundVolume,
      soundVol,
      toggle,
      muteSound,
      muteToggle,
      buttonToggleClass,
      buttonMuteClass,
    };
  },
  props: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";
.volume-button {
  display: block;
  width: 44px;
  height: 44px;
  position: fixed;
  bottom: 7px;
  left: 20px;
  z-index: 9;
  @media only screen and (max-width: 576px) {
    z-index: 4;
  }
  background-image: url("https://cdn.dragoncrypto.io/uiassets/volume.png");
  background-repeat: no-repeat;
  background-size: cover;
  &.muted:after {
    content: "×";
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: #f00;
  }

  &.active {
    opacity: 1;
    .volume-manager {
      display: block;
    }
  }
  &:hover {
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
  }
  .volume-manager {
    display: none;
    top: -180px;
    position: absolute;
    background: #191818;
    border: 4px solid #3e3e3e;
    height: 170px;
    width: 60px;
    left: -7px;
    z-index: 2;
    button.mute {
      border: 4px solid #3e3e3e;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -30px;
      text-transform: uppercase;
      width: 68px;
      box-shadow: none;
      &.muted {
        background: #840000;
        color: #fff;
      }
      &.unmuted {
        background: #018130;
        color: #fff;
      }
    }
    span {
      bottom: 5px;
      position: absolute;
      width: 100%;
      text-align: center;
      left: 0;
    }
    #volume-2 {
      transform: rotate(-90deg);
      position: absolute;
      left: -38px;
      bottom: 85px;
      background: transparent;
      appearance: none;
    }
    #volume {
      margin: auto;
      transform: rotate(-90deg);
      -webkit-appearance: none;
      position: absolute;
      overflow: hidden;
      height: 40px;
      width: 130px;
      left: -35px;
      bottom: 75px;
      border-radius: 0; /* iOS */
      cursor: url("https://cdn.dragoncrypto.io/uiassets/normal_cursor_gray.png"),
        auto;
    }
  }
}
::-webkit-slider-runnable-track {
  background: #191818;
}
/*
 * 1. Set to 0 width and remove border for a slider without a thumb
 */
::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px; /* 1 */
  height: 40px;
  background: #ddba88;
  box-shadow: -100vw 0 0 100vw #611a19;
  border: 1px solid #615950; /* 1 */
  &:active {
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_grabby_cursor_gray.png"),
      auto;
  }
}
::-moz-range-track {
  height: 40px;
  background: #191818;
}
::-moz-range-thumb {
  background: #ddba88;
  height: 40px;
  width: 20px;
  border: 1px solid #615950;
  border-radius: 0 !important;
  box-shadow: -100vw 0 0 100vw #611a19;
  box-sizing: border-box;
  &:active {
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_grabby_cursor_gray.png"),
      auto;
  }
}

::-ms-fill-lower {
  background: #611a19;
}

::-ms-thumb {
  background: #ddba88;
  border: 2px solid #999;
  height: 40px;
  width: 20px;
  box-sizing: border-box;
  &:active {
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_grabby_cursor_gray.png"),
      auto;
  }
}
</style>
