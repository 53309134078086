<template>
  <div
    :class="['guild-widget', togglePin ? 'pin' : '']"
    @click="togglePin = !togglePin"
  >
    <div class="grid">
      <template v-if="defendersIp > craftersIp">
        <Popper arrow :show="showDefendersLeader" placement="left">
          <div
            @mouseenter="showDefendersLeader = true"
            @mouseleave="showDefendersLeader = false"
            :class="[
              'guild-type',
              'defenders',
              defendersIp != craftersIp ? 'leader' : '',
            ]"
          >
            <span class="icon"></span>
            <span class="ip">{{ formatNumberInK(defendersIp) }}</span>
          </div>
          <template v-if="defendersIp != craftersIp" #content>
            <div class="popup-content">
              <h4>Defender's Guild is Leading</h4>
              <p>Defender's guild members get 10% bonus damage during combat</p>
            </div>
          </template>
        </Popper>

        <span class="vs"></span>
        <div :class="['guild-type', 'crafters']">
          <span class="icon"></span>
          <span class="ip">{{ formatNumberInK(craftersIp) }}</span>
        </div>
      </template>
      <template v-else>
        <Popper arrow :show="showCraftersLeader" placement="left">
          <div
            @mouseenter="showCraftersLeader = true"
            @mouseleave="showCraftersLeader = false"
            :class="[
              'guild-type',
              'crafters',
              defendersIp != craftersIp ? 'leader' : '',
            ]"
          >
            <span class="icon"></span> <span class="ip">{{ craftersIp }}</span>
          </div>
          <template v-if="defendersIp != craftersIp" #content>
            <div class="popup-content">
              <h4>Crafter's Guild is Leading</h4>
              <p>
                Crafter's guild members get 10% crafting bonus for crafting from
                blueprints
              </p>
            </div>
          </template>
        </Popper>

        <span class="vs"></span>
        <div :class="['guild-type', 'defenders']">
          <span class="icon"></span> <span class="ip">{{ defendersIp }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, onUnmounted } from "vue";
import { io } from "socket.io-client";
import { useStore } from "vuex";
import { useSound } from "../../composables/sound";
import Constants from "../../consts/constants";
import finishQuest from "../../assets/sound/quest-complete.wav";
import Popper from "vue3-popper";

export default {
  name: "GuildWidget",
  components: { Popper },
  props: {},
  setup() {
    const store = useStore();
    const socket = io(Constants.apiUrl, { transports: ["websocket"] });
    const togglePin = ref(true);
    const defendersIp = ref(0);
    const craftersIp = ref(0);
    const showDefendersLeader = ref(false);
    const showCraftersLeader = ref(false);
    const playOnLeaderChange = useSound(finishQuest, {
      volume: store.state.soundVolume,
      interrupt: true,
    });

    onMounted(async () => {
      await getInitialGuildIPs();
      socket.emit("join", { sessionId: localStorage.getItem("sessionId") });
      socket.on("connect", () => {
        console.log("guild widget connected to socket");
      });
      socket.io.on("error", () => {
        console.log(
          "Unable to connect to server. Please try again or let the team know"
        );
      });

      /**
       * Setting up socket and listening to total-ip-updated event to updated
       * guild influence points in real time
       */
      socket.on("total-ip-updated", (result) => {
        console.log("CAPTURED TOTAL IP UPDATED", result);
        if (result.success) {
          assignIpsAndCheckLeader(result, true);
        }
      });
    });

    /**
     * Disconnecting sockets and removing all event listeners
     */
    onUnmounted(() => {
      socket.removeAllListeners(["connect", "total-ip-updated", "error"]);
      socket.disconnect();
    });

    /**
     * Getting initial total ip using rest api
     */
    const getInitialGuildIPs = async () => {
      try {
        const response = await fetch(
          Constants.apiUrl + "current-leading-guild",
          {
            method: "POST",
            body: JSON.stringify({
              sessionId: localStorage.getItem("sessionId"),
              account: store.state.account,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const result = await response.json();
        if (result.success) {
          assignIpsAndCheckLeader(result, false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    /**
     * Formating numbers
     */
    const formatNumberInK = (num) => {
      if (num < 1000) {
        return num.toString();
      } else if (num < 1000000) {
        return (num / 1000).toFixed(1) + "k";
      } else {
        return (num / 1000000).toFixed(1) + "m";
      }
    };

    /**
     *
     * @param {} result - contains total guild points
     * Assign respective guild points to data variables
     * Play sound if leader has changed.
     */
    const assignIpsAndCheckLeader = (result, playSound) => {
      const prevLeader =
        defendersIp.value > craftersIp.value ? "defenders" : "crafters";
      defendersIp.value = result.totalInfluencePoints.totalDefendersGuild;
      craftersIp.value = result.totalInfluencePoints.totalCraftersGuild;
      const newLeader =
        defendersIp.value > craftersIp.value ? "defenders" : "crafters";
      if (newLeader != prevLeader && playSound) {
        playOnLeaderChange.play();
      }
    };

    return {
      // methods
      formatNumberInK,
      //data
      togglePin,
      defendersIp,
      craftersIp,
      showDefendersLeader,
      showCraftersLeader,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.guild-widget :deep(.popper) {
  background: #212121;
  padding: 15px;
  border-radius: 20px;
  color: #fff;
  font-weight: normal;
  font-family: Lato, sans-serif;
  font-size: 0.9rem;
  width: 300px !important;
  line-height: 1.2rem;
  z-index: 15;
}
.guild-widget :deep(.popper #arrow) {
  z-index: -1;
}

.guild-widget :deep(.popper #arrow::before) {
  background: #212121;
  padding: 12px;
  left: -12px;
  z-index: 14;
}

.guild-widget :deep(.popper:hover),
.guild-widget :deep(.popper:hover > #arrow::before) {
  background: #212121;
}
.popup-content {
  h4 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    margin-top: 0;
  }
  p {
    margin-bottom: 0;
  }
}

.guild-widget {
  display: block;
  position: fixed;
  top: 50%;
  right: 0;
  /* backdrop-filter: blur(2px); */
  background-color: #171110;
  box-shadow: 0 0px 5px 3px rgba(0, 0, 0, 0.3294117647);
  min-width: 35px;

  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transform: translateY(-50%);
  cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
    auto;
  &.pin {
    .grid {
      .guild-type {
        grid-template-columns: 23px 1fr;
        padding: 10px;
        /* opacity: 0.7; */
        &.leader {
          font-weight: bold;
          opacity: 1;
          background: none;
        }
        span.ip {
          display: block;
        }
      }
    }
  }
  .grid {
    display: grid;
    grid-template-rows: 1fr 15px 1fr;
    align-items: center;
    .guild-type {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      text-align: left;
      grid-gap: 2px;
      padding: 10px 0;
      opacity: 1;
      justify-content: center;
      justify-items: center;
      &.leader {
        /* background-color: rgb(166 255 4 / 17%); */
      }
      span.icon {
        content: "";
        height: 25px;
        width: 23px;
        position: relative;
        display: block;
      }
      span.ip {
        display: none;
      }
      &.defenders {
        span.icon {
          background: url("../../assets/ui/defenders-influence-points.png")
            no-repeat left center;
          background-size: contain;
        }
      }
      &.crafters {
        span.icon {
          background: url("../../assets/ui/crafters-guild-influence-points.png")
            no-repeat left center;
          background-size: contain;
        }
      }
    }
    span.vs {
      font-size: 0.8rem;
      display: block;
      padding: 0 10px;
      background: #000;
      height: 15px;
      &:before {
        content: "vs";
        display: block;
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);

        opacity: 0.7;
      }
    }
  }
}
</style>
