<template>
  <div
    :id="id"
    :class="['location' + (isActive ? '' : ' disabled'), locationName]"
  >
    <h3
      class="button"
      @click="clickLocation"
      @mouseenter="checkToNotify"
      @mouseleave="clearNotify"
    >
      {{ locationName }}
    </h3>
    <span @click="showHelp = !showHelp">?</span>
  </div>
  <BasicPopup v-if="showHelp" :class="['help-video']" header="">
    <GenericLoading />
    <video
      v-if="embedLink !== null && embedLink !== ''"
      controls
      autoplay
      controlsList="nodownload"
      preload="auto"
      ref="video"
      @ended="skip"
    >
      <source :src="embedLink" type="video/mp4" />
    </video>
    <p class="coming-soon" v-else>Video Coming Soon</p>

    <div class="close-button button-small" @click="showHelp = !showHelp">
      <span>x</span>
    </div>
  </BasicPopup>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import BasicPopup from "./Popup/BasicPopup.vue";
import GenericLoading from "./LoadingComponents/GenericLoading.vue";

export default {
  name: "WorldMapLocation",
  components: {
    BasicPopup,
    GenericLoading,
  },
  emits: ["clickLocation"],
  data() {
    return {
      hoverTimeout: 0,
      gromsBank: "grom's bank",
      marketPlace: "marketplace",
    };
  },
  props: {
    canUseDead: {
      type: Boolean,
    },
    requiresTurns: {
      type: Boolean,
    },
    locationName: {
      type: String,
    },
    mapX: {
      type: Number,
    },
    mapY: {
      type: Number,
    },
    requiresTurnNotHappening: {
      type: Boolean,
      default: false,
    },
    requiresItemEquippedName: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const showHelp = ref(false);
    const embedLink = ref("");
    const video = ref(null);
    const youtubeList = {
      "search-the-plains":
        "https://cdn.dragoncrypto.io/video/Plains%20Actions.mp4",
      "healers-hut": "https://cdn.dragoncrypto.io/video/Healers%20Hut.mp4",
      bank: "",
      forge:
        "https://cdn.dragoncrypto.io/video/Forge%20Making%20Runite%20Bars.mp4",
      inn: "https://cdn.dragoncrypto.io/video/Inn%20Song%20And%20Rest.mp4",
      marketplace: "",
      mines:
        "https://cdn.dragoncrypto.io/video/Equip%20Pickaxe%20And%20Mines.mp4",
      deepwood: "https://cdn.dragoncrypto.io/video/Deepwoods.mp4",
      "crafters-guild":
        "https://cdn.dragoncrypto.io/video/Crafters%20Guild.mp4",
      "your-stash": "https://cdn.dragoncrypto.io/video/Stash%20Claiming.mp4",
    };

    onMounted(() => {
      getEmbedLink();
    });

    const getEmbedLink = () => {
      embedLink.value = youtubeList[props.id];
    };

    return {
      //Data
      showHelp,
      embedLink,
      video,
      //Methods
      //Computed
    };
  },
  methods: {
    clickLocation() {
      if (
        this.isActive ||
        this.locationName.toLowerCase() === this.gromsBank ||
        this.locationName.toLowerCase() === this.marketPlace
      ) {
        this.$emit("clickLocation");
      }
    },
    checkToNotify() {
      if (!this.hasCharacter) {
        let reason =
          "🛑You have no character, please buy one before being able to access locations, for the Beta, this must be a 'Genesis' hero🛑";
        this.hoverTimeout = this.$store.commit("setNotification", reason);
      } else if (
        !this.isActive &&
        this.locationName.toLowerCase() !== this.gromsBank &&
        this.locationName.toLowerCase() !== this.marketPlace
      ) {
        let reason = this.isDead
          ? "☠ You are dead and cannot do that. Visit the Healer's Hut to resurrect. ☠"
          : "🛑 You have run out of turns, stay in a room in The Inn to refresh them. 🛑";

        if (this.needsItem && !this.hasRequiredItem) {
          let message = "a " + this.requiresItemEquippedName;
          if (this.requiresItemEquippedName.length > 1) {
            message = "one of the following: " + this.requiresItemEquippedName;
          }
          reason =
            "🛑 You need to have " + message + " equipped to do this. 🛑";
        }

        if (this.turnProcessing && this.isTurnHappening) {
          reason = "🛑 You cannot do that while the turn is processing. 🛑";
        }

        this.hoverTimeout = setTimeout(() => {
          this.$store.commit("setNotification", reason);
        }, 250);
      }
    },
    clearNotify() {
      clearTimeout(this.hoverTimeout);
    },
  },
  computed: {
    isDead() {
      const currentChar =
        this.$store.state.characters[this.$store.state.currentCharacter];

      if (currentChar == null || currentChar == undefined) {
        return false;
      }

      return currentChar.stats.isDead;
    },
    needsItem() {
      return (
        this.requiresItemEquippedName &&
        this.requiresItemEquippedName.length > 0
      );
    },
    turnProcessing() {
      return this.requiresTurnNotHappening;
    },
    isTurnHappening() {
      return this.$store.state.isTurnHappening;
    },
    hasRequiredItem() {
      if (this.turnProcessing) {
        if (this.isTurnHappening) {
          return false;
        }
      }
      const currentChar =
        this.$store.state.characters[this.$store.state.currentCharacter];

      for (const equippedItemKey in currentChar.equipped) {
        if (Object.hasOwnProperty.call(currentChar.equipped, equippedItemKey)) {
          const equippedItem = currentChar.equipped[equippedItemKey];
          //Due to Axe and pickaxe containing the same substring (Axe), we need a regex expression to check full words and not just partial words so pickaxe does not trigger axe checks
          if (equippedItem) {
            for (let i = 0; i < this.requiresItemEquippedName.length; i++) {
              if (
                new RegExp(
                  "\\b" + this.requiresItemEquippedName[i].toLowerCase() + "\\b"
                ).test(equippedItem.name.toLowerCase())
              ) {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    hasCharacter() {
      const currentChar =
        this.$store.state.characters[this.$store.state.currentCharacter];
      if (!currentChar) {
        return false;
      } else {
        return true;
      }
    },
    isActive() {
      const currentChar =
        this.$store.state.characters[this.$store.state.currentCharacter];

      if (currentChar == null || currentChar == undefined) {
        return false;
      }
      if (currentChar.stats.isDead) {
        if (this.canUseDead) {
          return true;
        } else {
          return false;
        }
      }

      if (this.needsItem) {
        if (this.requiresTurns && currentChar.stats.turns > 0) {
          return this.hasRequiredItem;
        }
      }

      if (this.turnProcessing) {
        if (this.isTurnHappening) {
          return false;
        }
      }

      if (this.requiresTurns) {
        return currentChar.stats.turns > 0;
      }

      return true;
    },
    topX() {
      return this.mapX * 1.72 + "px";
    },
    topY() {
      return this.mapY * 1.66 + "px";
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.help-video {
  padding: 0;
  width: 600px;
  height: 307px !important;
  min-height: 248px;
  video {
    margin-left: -$popup-border-size / 2;
    margin-top: -$popup-border-size / 2;
    z-index: 10;
    position: relative;
    width: calc(100% + $popup-border-size);
  }
  .close-button {
    top: -80px;
    right: -80px;
    position: absolute;
  }
  p.coming-soon {
    background: #1c1716;
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 12;
    padding: 40px;
    font-size: 1.7rem;
    text-transform: uppercase;
    color: #ffffff52;
    width: 100%;
  }
}
#app .location {
  transform: scale(0.75);
  transform-origin: center;
  position: absolute;
  width: 311px;
  height: 92px;
  background: url("https://cdn.dragoncrypto.io/map/location-scroll.png")
    no-repeat top left;
  top: v-bind(topY);
  left: v-bind(topX);
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &.disabled {
    filter: grayscale(100);
    opacity: 0.7;
    cursor: not-allowed;

    &:hover {
      transform: scale(0.75);
    }
  }

  &:hover {
    transform: scale(0.85);
    transition: transform 0.2s ease-in-out;
  }
  &.Bank {
    h3 {
      &:hover {
        cursor: url("https://cdn.dragoncrypto.io/uiassets/bank_cursor.png"),
          auto !important;
      }
    }
  }
  &.Forge {
    h3 {
      &:hover {
        cursor: url("https://cdn.dragoncrypto.io/uiassets/forge_cursor.png"),
          auto !important;
      }
    }
  }

  h3 {
    color: #000;
    font-weight: 700;
    margin: 0;
    line-height: 84px;
    font-size: 100%;
    font-family: "IM Fell English", serif;
  }
  span {
    display: none;
  }
  &:after {
    content: "";
    width: 100px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -50px;
    z-index: -1;
  }
  &:hover {
    span {
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      line-height: 1.3rem;
      border-radius: 100%;
      background-color: rgb(203 153 93);
      border: 1px solid #000;
      font-size: 1rem;
      right: -22px;
      top: 50%;
      transform: translateY(-50%);
      color: #000;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
        auto;
    }
  }
}
</style>
