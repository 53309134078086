<template>
  <div :class="getClass">
    <GameMessage :heading="getHeading" :icon="getIcon">
      <p v-if="isPlains">Searching the vast plains</p>
    </GameMessage>
  </div>
</template>
<script>
import { useStore } from "vuex";
import GameMessage from "../components/GameMessage.vue";
import Constants from "../consts/constants";

export default {
  name: "Searching",
  components: {
    GameMessage,
  },
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    getIcon() {
      if (this.isPlains) {
        return "Paladinskill_30_nobg";
      }

      return "";
    },
    getHeading() {
      if (this.isPlains) {
        return "Searching the plains";
      }

      return "Searching";
    },
    isPlains() {
      return this.$store.state.gameState == Constants.gamemodes.searching;
    },

    getClass() {
      let final =
        "search-wrapper wrap-content " + (this.isPlains ? "show" : "hide");
      if (this.isPlains) {
        final += " plains";
      }
      return final;
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.search-wrapper {
  &.show {
    opacity: 1;
    pointer-events: all;

    transition: opacity 0.35s linear;
  }
  &.hide {
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.35s linear;
  }
}
</style>
