<template>
  <div :class="currentClass">
    <ActionButton
      :buttonName="'character'"
      id="your-character"
      @click="toggleEquipment"
      :glowEffect="hasLevelUp"
      :title="'Your Character' + (hasLevelUp ? ' (Level up Available)' : '')"
    />
    <ActionButton
      :buttonName="'inventory'"
      @click="toggleInventory"
      title="Your Inventory"
      id="your-inventory"
    />
    <ActionButton
      :alert="hasCompletedQuests"
      :buttonName="'quests'"
      @click="toggleQuests"
      title="Quest Log"
      id="your-quests"
    />
    <ActionButton
      :buttonName="'rankings'"
      @click="toggleRankings"
      title="Rankings"
      id="rankings"
    />
  </div>
</template>

<script>
import ActionButton from "./ActionButton.vue";
import { useStore } from "vuex";
import Constants from "../consts/constants";

export default {
  name: "ActionButtonBar",
  components: { ActionButton },
  data() {
    return {
      $store: {},
    };
  },
  props: {},
  computed: {
    currentClass() {
      if (this.$store.state.characters.length > 0)
        // TODO: ONLY ENABLING ACTION BAR BUTTONS IF YOU HAVE A HERO
        return "action-bar" + (!this.isEnabled ? " disabled" : "");
      else return "action-bar disabled";
    },
    hasCompletedQuests() {
      const quests = this.$store.state.quests;
      let alertQuests = false;
      if (quests && quests.length > 0) {
        quests.forEach((quest) => {
          if (quest.hasClaimed == false && quest.isFinished == true) {
            alertQuests = true;
          }
        });
      }
      return alertQuests;
    },
    isDead() {
      return this.$store.state.characters[this.$store.state.currentCharacter]
        .stats.isDead;
    },
    isEnabled() {
      if (this.isDead) return false;
      return (
        this.$store.state.gameState === Constants.gamemodes.wandering ||
        this.$store.state.gameState === Constants.gamemodes.forge
      );
    },
    hasLevelUp() {
      if (
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          null ||
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          "undefined"
      ) {
        return false;
      }
      return (
        this.$store.state.characters[this.$store.state.currentCharacter].stats
          .levelStatPoints > 0
      );
    },
  },
  methods: {
    toggleEquipment() {
      if (this.isEnabled) this.$emit("toggleEquipment");
    },
    toggleInventory() {
      if (this.isEnabled) this.$emit("toggleInventory");
    },
    toggleQuests() {
      if (this.isEnabled) this.$emit("toggleQuests");
    },
    toggleRankings() {
      if (this.isEnabled) this.$emit("toggleRankings");
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.action-bar {
  position: fixed;
  right: 6px;
  bottom: 6px;
  z-index: 5;
  &.disabled {
    opacity: 0.8;
    filter: grayscale(100%);
  }
}
</style>
