<template>
  <div :class="['wrapper', 'character-inventory', isInventoryVisible]">
    <div :class="currentClass" id="inventory-items">
      <div class="close-button button-small" @click="hide"><span>x</span></div>
      <div class="filters">
        <div class="filters-content">
          <img
            :class="getClassAll"
            src="https://cdn.dragoncrypto.io/uiassets/inventory-filter-all.png"
            alt="All"
            @click="filterAll"
            title="All"
            id="show-all"
          />
          <img
            :class="getClassArmour"
            src="https://cdn.dragoncrypto.io/uiassets/inventory-filter-armour.png"
            alt="Armour"
            @click="filterArmour"
            title="Armour"
            id="show-armour"
          />
          <img
            :class="getClassWeapons"
            src="https://cdn.dragoncrypto.io/uiassets/inventory-filter-weapons.png"
            alt="Weapons"
            @click="filterWeapons"
            title="Weaponry"
            id="show-weapons"
          />
          <img
            :class="getClassConsumables"
            src="https://cdn.dragoncrypto.io/uiassets/inventory-filter-consumables.png"
            alt="Consumables"
            @click="filterConsumables"
            title="Consumables"
            id="show-consumables"
          />
          <img
            :class="getClassResources"
            src="https://cdn.dragoncrypto.io/uiassets/inventory-filter-resources.png"
            alt="Resources"
            @click="filterResources"
            title="Crafting and Resources"
            id="show-resources"
          />
          <img
            :class="getClassQuest"
            src="https://cdn.dragoncrypto.io/uiassets/inventory-filter-quest.png"
            alt="Quest Items"
            @click="filterQuest"
            title="Quest Items"
            id="show-quest-items"
          />
          <img
            src="../assets/ui/chests.png"
            alt="Chests"
            @click="openLootChests"
            title="Chests"
          />
          <PetPopup location="inventory" />
          <!-- <img src="https://cdn.dragoncrypto.io/uiassets/inventory-filter-quest.png" alt="Quest" /> -->
        </div>
      </div>
      <div class="items">
        <template
          v-for="inventoryItem in currentInventory"
          :key="inventoryItem.id"
        >
          <EquipmentTile
            @click="toggleItem(inventoryItem)"
            :source="'inv'"
            :item="inventoryItem"
            :class="isItemSelected(inventoryItem.id) ? 'selected' : ''"
          />
        </template>
      </div>

      <div
        class="breakdown-button"
        v-if="selectedInventoryEquipments.length !== 0 && approvedEquipment"
      >
        <span class="buttons">
          <DialogButton
            :disabled="
              selectedInventoryEquipments.length === 0 ||
              isTurnHappening ||
              selectedInventoryEquipments.length > 10
            "
            class="claim-button"
            :buttonText="`Breakdown (${selectedInventoryEquipments.length}/10)`"
            @click="breakdownInventory"
            :isLoading="isTurnHappening"
          />
        </span>
      </div>
      <div
        class="breakdown-button"
        v-else-if="
          selectedInventoryEquipments.length !== 0 && !approvedEquipment
        "
      >
        <span class="buttons">
          <DialogButton
            :disabled="
              selectedInventoryEquipments.length === 0 || isTurnHappening
            "
            class="claim-button"
            :buttonText="`Approve Item Breakdown`"
            @click="equipmentApproval"
            :isLoading="isTurnHappening"
          />
        </span>
      </div>
      <div v-else class="tokens">
        <Popper
          arrow
          placement="top"
          :show="showAboutBonus"
          class="popper-container"
          content="You need to hold a certain amount of $DCAR to receive a 100% bonus in rewards. This displays the current percentage of the bonus you are receiving and the minimum amount of $DCAR you need to hold in order to reach the 100% bonus."
        >
          <div class="bonus-details-container">
            <div class="bonus-details">
              <div class="progress-bar-container">
                <ProgressBar :progress="bonusPercentage" />
              </div>
              <div
                class="details"
                @mouseenter="showAboutBonus = true"
                @mouseleave="showAboutBonus = false"
              >
                <div class="percentage">
                  {{ bonusPercentage }}% Bonus<span class="desc"
                    >Bonus Percentage</span
                  >
                </div>

                <div class="tokens-required">
                  <span class="dcar-amount">
                    {{ Math.ceil(tokensRequired).toFixed(3) }}
                  </span>
                  <span class="desc">Required Tokens</span>
                </div>
              </div>
            </div>
          </div>
        </Popper>

        <span class="dcau-amount">
          {{ dcauAmount }}
        </span>
        <span class="dcar-amount">
          {{ dcarAmount }}
        </span>
      </div>
    </div>
  </div>
  <LootChestsPopup v-if="isLootPopupOpen" />
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import PetPopup from "../components/Pets/PetPopup.vue";
import { usePrice } from "../composables/price";
import { useUser } from "../composables/user";
import Constants from "../consts/constants";
import { parseError } from "../utils/helpers";
import DialogButton from "./DialogButton.vue";
import EquipmentTile from "./EquipmentTile.vue";
import LootChestsPopup from "./LootChests/LootChestsPopup.vue";
import ProgressBar from "./ProgressBar.vue";
import Popper from "vue3-popper";

import {
  getDCGEquipmentContract,
  getEquipmentBurnContract,
} from "../utils/getContract";

export default {
  name: "CharacterInventory",
  components: {
    EquipmentTile,
    DialogButton,
    LootChestsPopup,
    PetPopup,
    ProgressBar,
    Popper,
  },
  props: {},
  setup() {
    const {
      getDCAUBalance,
      getDCARBalance,
      getBonusDetails,
      bonusPercentage,
      tokensRequired,
    } = usePrice();
    const { address, signer } = useUser();
    const store = useStore();
    const isVisible = ref(true);
    const showAboutBonus = ref(false);
    const isEquipmentVisible = ref(store.state.isEquipmentVisible);
    const dcauAmount = ref(0.0);
    const dcarAmount = ref(0.0);
    const approvedEquipment = ref(false);
    const filterType = ref(Constants.filterTypes.all);
    const EquipmentBurnContract = getEquipmentBurnContract(signer.value);
    const EquipmentContract = getDCGEquipmentContract(signer.value);

    onMounted(() => {
      store.watch(
        (state) => state.isEquipmentVisible,
        (value) => {
          isEquipmentVisible.value = value;
        }
      );

      store.watch(
        (state) => state.isInventoryVisible,
        async (value) => {
          isVisible.value = value;
        }
      );

      store.watch(
        (state) => state.dcauInWallet,
        (value) => {
          dcauAmount.value = parseFloat(value).toFixed(3);
        }
      );

      store.watch(
        (state) => state.dcarInWallet,
        (value) => {
          dcarAmount.value = parseFloat(value).toFixed(3);
        }
      );

      store.watch(
        (state) => state.gameState,
        (value) => {
          if (value == Constants.gamemodes.repair) {
            filterType.value = Constants.filterTypes.all;
          }

          if (
            value == Constants.gamemodes.crafting ||
            value == Constants.gamemodes.forge
          ) {
            filterType.value = Constants.filterTypes.resources;
          }
        }
      );

      if (
        store.state.gameState == Constants.gamemodes.crafting ||
        store.state.gameState == Constants.gamemodes.forge
      ) {
        filterType.value = Constants.filterTypes.resources;
      } else if (store.state.gameState == Constants.gamemodes.repair) {
        filterType.value = Constants.filterTypes.weapons;
      } else {
        filterType.value = Constants.filterTypes.all;
      }

      dcauAmount.value = store.state.dcauInWallet;
      dcarAmount.value = store.state.dcarInWallet;
      isVisible.value = store.state.isInventoryVisible;
      getDCAUBalance();
      getDCARBalance();
      getBonusDetails();
      checkEquipmentApproval();
    });

    /**
     * Methods
     */
    const openLootChests = () => {
      store.commit("lootChests/setIsLootPopupOpen", true);
    };
    const hide = () => {
      store.dispatch("selectedInventory/resetItems");
      store.commit("setInventoryVisible", false);
      store.commit("marketplace/setShowInventoryState", false); // Hiding in mobile view as well
      store.dispatch("selectedInventory/resetItems");
    };
    const filterAll = () => {
      filterItems(Constants.filterTypes.all);
    };
    const filterArmour = () => {
      filterItems(Constants.filterTypes.armour);
    };
    const filterWeapons = () => {
      filterItems(Constants.filterTypes.weapons);
    };
    const filterQuest = () => {
      filterItems(Constants.filterTypes.quest);
    };
    const filterConsumables = () => {
      filterItems(Constants.filterTypes.consumables);
    };
    const filterResources = () => {
      filterItems(Constants.filterTypes.resources);
    };
    const filterItems = (filter) => {
      filterType.value = filter;
    };
    const toggleItem = (item) => {
      store.dispatch("selectedInventory/toggleItem", item);
    };

    const isItemSelected = (id) => {
      if (
        selectedInventoryEquipments.value != null &&
        selectedInventoryEquipments.value.includes(id)
      ) {
        return true;
      }
      return false;
    };

    const checkEquipmentApproval = async () => {
      let isBurningApproved = await EquipmentContract.isApprovedForAll(
        address.value,
        EquipmentBurnContract.address
      );

      if (isBurningApproved) {
        approvedEquipment.value = true;
      }
    };

    const equipmentApproval = async () => {
      try {
        store.commit("setTurnHappening", true);
        const txApproveEquipment = await EquipmentContract.setApprovalForAll(
          EquipmentBurnContract.address,
          true
        );
        await txApproveEquipment.wait();
        store.commit("setTurnHappening", false);
        await checkEquipmentApproval();
      } catch (e) {
        store.commit("setTurnHappening", false);
        store.commit("setNotification", parseError(e));
        console.log(e);
      }
    };

    const breakdownInventory = async () => {
      try {
        store.commit("setTurnHappening", true);

        const items = selectedInventoryEquipments.value;

        const equippedItemsFound = [];

        characters.value.some((character) => {
          if (Object.keys(character.equipped).length > 0) {
            const equippedArray = Object.entries(character.equipped);
            return equippedArray.some((item) => {
              if (item && item[1] && items.includes(item[1].id)) {
                equippedItemsFound.push(item[1].id);
                return true;
              }
            });
          }
        });

        if (equippedItemsFound.length > 0) {
          store.state.doNotification(
            "You can't breakdown equipped items, we are unselecting them for you"
          );
          store.dispatch("selectedInventory/resetItems");
          store.commit("setTurnHappening", false);
          return;
        }

        const blueprints = items.filter((item) => {
          const inventoryItem = store.state.inventory.filter(
            (i) => i.id === item
          )[0];
          return inventoryItem.type === "blueprint";
        });

        if (blueprints.length > 0) {
          blueprints.forEach(() => {
            store.state.doNotification(
              "You can't breakdown blueprints, please deselect these items"
            );
          });
          store.commit("setTurnHappening", false);
          return;
        }

        const burnEquipmentItemsTransaction =
          await EquipmentBurnContract.burnEquipmentBatch(items);

        const receipt = await burnEquipmentItemsTransaction.wait();

        if (receipt.status) {
          await store.commit("setInventoryBreakdown", items);
          // Moved reseting selected inventory to within the above commit as commit mutations are synchronous and
          // do not support async await.
          // TODO: refactor code to bring entire breakdown inventory method here instead of using in store index.
          // store.dispatch("selectedInventory/resetItems");
        }
      } catch (e) {
        store.commit("setTurnHappening", false);
        store.commit("setNotification", parseError(e));
        console.log(e);
      }
    };

    /**
     * Computed Methods
     */
    const isLootPopupOpen = computed(() => {
      return store.state.lootChests.isLootPopupOpen;
    });
    const selectedInventoryEquipments = computed(() => {
      return store.state.selectedInventory.selectedInventoryEquipments;
    });
    const isLoading = computed(() => {
      return store.state.isLoading;
    });
    const isMarket = computed(() => {
      return store.state.gameState == Constants.gamemodes.marketplace;
    });
    const isForge = computed(() => {
      return store.state.gameState == Constants.gamemodes.forge;
    });
    const isCrafting = computed(() => {
      return store.state.gameState == Constants.gamemodes.crafting;
    });
    const isRepairing = computed(() => {
      return store.state.gameState == Constants.gamemodes.repair;
    });
    const isCraftersGuild = computed(() => {
      return store.state.gameState == Constants.gamemodes.crafters;
    });
    const isInventoryVisible = computed(() => {
      return store.state.marketplace.showInventory ? "show" : "";
    });
    const characters = computed(() => {
      return store.state.characters;
    });
    const equipmentUsed = computed((character) => {
      return store.state.characters[character].equipped;
    });
    const getClassAll = computed(() => {
      if (filterType.value == Constants.filterTypes.all) {
        return "selected";
      }
      return "";
    });
    const getClassQuest = computed(() => {
      if (filterType.value == Constants.filterTypes.quest) {
        return "selected";
      }

      return "";
    });
    const getClassArmour = computed(() => {
      if (filterType.value == Constants.filterTypes.armour) {
        return "selected";
      }

      return "";
    });
    const getClassWeapons = computed(() => {
      if (filterType.value == Constants.filterTypes.weapons) {
        return "selected";
      }

      return "";
    });
    const getClassConsumables = computed(() => {
      if (filterType.value == Constants.filterTypes.consumables) {
        return "selected";
      }

      return "";
    });
    const getClassResources = computed(() => {
      if (filterType.value == Constants.filterTypes.resources) {
        return "selected";
      }

      return "";
    });
    const currentCharacter = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });
    const currentClass = computed(() => {
      return (
        "inventory-panel" +
        (!isVisible.value ? " hidden" : "") +
        (isMarket.value ? " market" : "") +
        (isForge.value ? " forge" : "") +
        (isEquipmentVisible.value ? " equipment" : "") +
        (isCrafting.value ? " crafting" : "") +
        (isRepairing.value ? " market" : "") +
        (isCraftersGuild.value ? " crafting" : "")
      );
    });

    const currentInventory = computed(() => {
      let results = [];
      if (store.state.inventoryEquipFilter === "") {
        switch (filterType.value) {
          case Constants.filterTypes.all:
            results = store.state.inventory;
            break;
          case Constants.filterTypes.armour:
            for (let i = 0; i < store.state.inventory.length; i++) {
              const inventoryItem = store.state.inventory[i];
              if (
                store.state.isArmourSlot(inventoryItem.type) ||
                inventoryItem.type == Constants.slots.hand2
              ) {
                results.push(inventoryItem);
              }
            }
            break;
          case Constants.filterTypes.weapons:
            for (let i = 0; i < store.state.inventory.length; i++) {
              const inventoryItem = store.state.inventory[i];
              if (inventoryItem.type == Constants.slots.hand1) {
                results.push(inventoryItem);
              }
            }
            break;
          case Constants.filterTypes.consumables:
            for (let i = 0; i < store.state.inventory.length; i++) {
              const inventoryItem = store.state.inventory[i];
              if (inventoryItem.type == Constants.useable.consumable) {
                results.push(inventoryItem);
              }
            }
            break;
          case Constants.filterTypes.resources:
            for (let i = 0; i < store.state.inventory.length; i++) {
              const inventoryItem = store.state.inventory[i];
              if (
                inventoryItem.type == Constants.useable.resource ||
                inventoryItem.isBlueprint
              ) {
                results.push(inventoryItem);
              }
            }
            break;
          case Constants.filterTypes.quest:
            for (let i = 0; i < store.state.inventory.length; i++) {
              const inventoryItem = store.state.inventory[i];
              if (inventoryItem.type == "quest") {
                results.push(inventoryItem);
              }
            }
            break;
          default:
            break;
        }
      } else {
        if (store.state.inventoryEquipFilter == Constants.slots.hand2) {
          for (let i = 0; i < store.state.inventory.length; i++) {
            const inventoryItem = store.state.inventory[i];
            if (
              (inventoryItem.type == Constants.slots.hand2 ||
                inventoryItem.type == Constants.slots.hand1) &&
              inventoryItem.stats.durability > 0
            ) {
              results.push(inventoryItem);
            }
          }
        } else {
          for (let i = 0; i < store.state.inventory.length; i++) {
            const inventoryItem = store.state.inventory[i];
            if (
              inventoryItem.type == store.state.inventoryEquipFilter &&
              inventoryItem.stats.durability > 0
            ) {
              results.push(inventoryItem);
            }
          }
        }
      }

      return results;
    });

    const isTurnHappening = computed(() => {
      if (
        store.state.isTurnHappening ||
        store.state.isUsingConsumable ||
        store.state.isFleeing ||
        store.state.combat.isHeroAttacking ||
        store.state.combat.isEnemyAttacking
      ) {
        return true;
      } else {
        return false;
      }
    });

    return {
      /* State Data */
      isVisible,
      showAboutBonus,
      isEquipmentVisible,
      selectedInventoryEquipments,
      dcauAmount,
      dcarAmount,
      filterType,
      approvedEquipment,
      bonusPercentage,
      tokensRequired,
      /* Methods */
      hide,
      filterAll,
      filterArmour,
      filterWeapons,
      filterQuest,
      filterConsumables,
      filterResources,
      filterItems,
      toggleItem,
      isItemSelected,
      breakdownInventory,
      equipmentApproval,
      openLootChests,
      /* Computed Methods */
      isLootPopupOpen,
      isMarket,
      isLoading,
      isForge,
      isInventoryVisible,
      getClassAll,
      getClassQuest,
      getClassArmour,
      getClassWeapons,
      getClassConsumables,
      getClassResources,
      currentCharacter,
      currentClass,
      currentInventory,
      isTurnHappening,
      equipmentUsed,
      characters,
    };
  },
};
</script>

<style lang="scss">
.item {
  &.selected {
    .item-container > img {
      background: #e6c356;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.character-inventory :deep(.popper) {
  background: #2c2c2c;
  padding: 20px;
  border-radius: 5px;
  color: #fff;
  z-index: 20;
  font-size: 0.9rem;
  font-family: "Lato", sans-serif;
}

.character-inventory :deep(.popper #arrow::before) {
  background: #2c2c2c;
}

.character-inventory :deep(.popper:hover),
.character-inventory :deep(.popper:hover > #arrow::before) {
  background: #2c2c2c;
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 6;
  @media only screen and (max-width: 576px) {
    z-index: 4;
  }
  &.show {
    @media only screen and (max-width: 576px) {
      right: 0;
      z-index: 5;
      background: #000000bf;
    }
    .inventory-panel.market {
      @media only screen and (max-width: 576px) {
        right: 0;
      }
    }
  }

  .inventory-panel {
    width: 388px;
    height: 564px;
    background: url("../assets/ui/inventory.png") no-repeat top left;
    position: relative;

    transition: all 0.25s linear;
    opacity: 1;
    pointer-events: all;

    &.hidden {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.25s linear;
    }

    &.equipment {
      margin-right: 750px;
      transition: all 0.25s ease-in-out;
    }

    &.market,
    &.crafting {
      margin-right: 770px;
      transition: all 0.25s ease-in-out;
      @media only screen and (max-width: 576px) {
        margin-right: 0;
        right: 100%;
      }
    }

    &.forge {
      margin-right: 400px;
    }

    &.crafters-guild {
      margin-right: 400px;
    }

    .filters {
      position: absolute;
      left: -58px;
      width: 58px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .filters-content {
        border: 1px solid #393939;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
      }

      img {
        cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
          auto;
      }
    }

    .items {
      position: absolute;
      top: 74px;
      left: 27px;
      padding-left: 2px;
      display: flex;
      width: 321.5px;
      flex-wrap: wrap;
      overflow-y: auto;
      max-height: 396px;
      padding-top: 2px;

      &::-webkit-scrollbar {
        width: 15px;
        height: 10px;
      }

      ::-webkit-scrollbar-track {
        background: transparent;
      }

      ::-webkit-scrollbar-button {
        background-color: transparent;
        width: 15px;
        height: 15px;
      }

      &::-webkit-scrollbar-thumb {
        border-top: 26px solid transparent;
        background: url("https://cdn.dragoncrypto.io/uiassets/scrollbar-slider.png")
          repeat-y;
        border-bottom: 32px solid transparent;
        background-clip: content-box;
      }

      .item {
        .item-container {
          position: absolute;
        }
        margin-right: 6px;
        margin-bottom: 6px;
      }
    }
    .breakdown-button {
      position: absolute;
      width: 100%;
      bottom: 32px;
    }
    .tokens {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-gap: 6px;
      position: absolute;
      bottom: 46px;
      width: 85%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      .dcau-amount,
      .dcar-amount {
        display: block;
        &:before {
          margin: 0 5px 0 0;
          top: 10px;
          position: relative;
        }
      }
      .dcau-amount {
        &:before {
          content: "";
          display: inline-block;
          width: 30px;
          height: 30px;
          background: url("../assets/ui/dcau.png") no-repeat;
          background-size: cover;
        }
      }
      .dcar-amount {
        &:before {
          content: "";
          display: inline-block;
          width: 30px;
          height: 30px;
          background: url("../assets/ui/dcar.png") no-repeat;
          background-size: cover;
        }
      }
      .popper-container {
        width: 100%;
        position: absolute;
      }
      .bonus-details-container {
        position: absolute;
        width: calc(100% + 29px);
        height: 62px;
        overflow: hidden;
        left: -15px;
        .bonus-details {
          position: absolute;
          display: block;
          bottom: -3px;
          background: red;
          width: calc(100% + 8px);
          left: -4px;
          height: 11px;
          /* cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
            auto; */
          .progress-bar-container {
            left: -15px;
            position: absolute;
            width: calc(100% + 29px);
            top: -3px;
            z-index: 4;
          }
          .details {
            transition: all 0.2s ease-in-out;
            transform: translateY(65px);
            display: grid;
            width: calc(100% - 29px);
            opacity: 1;
            position: absolute;
            left: 14px;
            height: 51px;
            bottom: 15px;
            z-index: 1;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            background: #0c0c0c;
            box-shadow: inset 0 0 4px 1px #ffffff29;
            .desc {
              display: block;
              font-size: 0.6rem;
              text-transform: uppercase;
              margin-top: 5px;
              color: #ffffff82;
            }
            .dcar-amount {
              &:before {
                width: 18px;
                height: 18px;
                margin-top: -2px;
                position: relative;
                top: 3px;
              }
            }
          }
          &:hover {
            .details {
              transform: translateY(0);
            }
          }
        }
      }
    }

    .close-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
</style>
