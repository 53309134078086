<template>
  <div class="guild-inventory-wrapper">
    <BasicPopup :class="['guild-inventory']" :header="inventoryTitle">
      <div :class="['stash-panel']">
        <div
          :class="['close-button', 'button-small', isLoading ? 'loading' : '']"
          @click="hide"
          :title="isLoading ? 'Complete transaction first' : 'Close'"
        >
          <span>x</span>
        </div>
        <div class="items-wrapper">
          <div class="items-container">
            <div :class="['items', isLoading ? 'loading' : '']">
              <template
                v-for="inventoryItem in currentInventory"
                :key="inventoryItem.id"
              >
                <EquipmentTile
                  @click="toggleItem(inventoryItem)"
                  :source="'inv'"
                  :item="inventoryItem"
                  :class="isItemSelected(inventoryItem.id) ? 'selected' : ''"
                />
              </template>
            </div>
          </div>
        </div>
        <!-- <span class="dcau-amount">{{ dcauAmount }} <span>DCAU</span></span> -->
        <div class="buttons-container">
          <DialogButton
            v-if="!approvedItems"
            class="submit-items"
            :buttonText="
              isDefenderType ? 'Approve Equipment' : 'Approve Resources'
            "
            title="Approve Equipment"
            :isLoading="isTurnHappening"
            @click="approveItems"
          />
          <DialogButton
            v-else
            class="submit-items"
            buttonText="Submit Selected Items"
            title="Submit Selected Items"
            :isLoading="isTurnHappening"
            :disabled="isTurnHappening || isNotEnoughSelected"
            @click="submitItems"
          />
          <p>
            Note: All your submitted items will be burned and can't be used.
          </p>
        </div>
      </div>
    </BasicPopup>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useUser } from "../../composables/user";
import Constants from "../../consts/constants";
import { parseError } from "../../utils/helpers";
import DialogButton from "../DialogButton.vue";
import EquipmentTile from "../EquipmentTile.vue";
import BasicPopup from "../Popup/BasicPopup.vue";
import apiConnector from "../../game/apiConnector";

import {
  getDCGEquipmentContract,
  getDCGResourcesContract,
  getQuestItemBurnContract,
} from "../../utils/getContract";

export default {
  name: "GuildItemInventory",
  components: {
    EquipmentTile,
    BasicPopup,
    DialogButton,
  },
  emits: ["refreshContracts"],
  setup(props, { emit }) {
    const { address, signer } = useUser();
    const store = useStore();
    const approvedItems = ref(false);
    const QuestItemBurnContract = getQuestItemBurnContract(signer.value);
    const EquipmentContract = getDCGEquipmentContract(signer.value);
    const ResourcesContract = getDCGResourcesContract(signer.value);

    onMounted(() => {
      if (isDefenderType.value) {
        checkEquipmentApproval();
      } else if (isCrafterType.value) {
        checkResourcesApproval();
      }
    });

    /**
     * Methods
     */

    const hide = () => {
      store.commit("guild/setIsGuildInventoryOpen", false);
      store.commit("guild/setSelectedQuestForSubmit", null);
      store.dispatch("stash/resetItems");
      emit("refreshContracts");
    };
    const isItemSelected = (id) => {
      if (
        equipmentSelected.value.includes(id) ||
        // consumablesSelected.value.includes(id) ||
        resourcesSelected.value.includes(id)
      ) {
        return true;
      }
      return false;
    };

    const submitItems = async () => {
      store.commit("setTurnHappening", true);
      const sessionId = localStorage.getItem("sessionId");
      try {
        if (isDefenderType.value) {
          const burnEquipmentItemsTransaction =
            await QuestItemBurnContract.burnEquipmentBatch(
              equipmentSelected.value,
              character.value.number,
              selectedQuestForSubmit.value.id
            );
          console.log("transaction hash", burnEquipmentItemsTransaction?.hash);
          if (burnEquipmentItemsTransaction?.hash) {
            const txHash = burnEquipmentItemsTransaction.hash;
            //TODO: need to pass ids and quantities
            await apiConnector.handleTransactionHash(
              store.state.account,
              sessionId,
              character.value.number,
              "contractQuestItemsBurn",
              txHash
            );
          }

          const receipt = await burnEquipmentItemsTransaction.wait();
          if (receipt.status === 1) {
            console.log(receipt);
            console.log("Items Burned!");
            const finishQuest =
              await apiConnector.callFinishContractEquipmentQuest(
                store.state.account,
                sessionId,
                character.value.number,
                selectedQuestForSubmit.value.id,
                receipt.transactionHash
              );
            if (finishQuest.success) {
              store.commit("setInventory", finishQuest.inventory);
              store.commit("guild/setGuildQuestRewards", finishQuest.reward);
              store.commit("guild/setIsGuildRewardsOpen", true);
              store.commit("setCurrentPlayerStats", finishQuest.stats);
              hide();
            } else {
              store.commit("setInventory", finishQuest.inventory);
              store.commit("setNotification", finishQuest.message);
            }
            // await store.commit("setInventoryBreakdown", items);
            // Moved reseting selected inventory to within the above commit as commit mutations are synchronous and
            // do not support async await.
            // TODO: refactor code to bring entire breakdown inventory method here instead of using in store index.
            // store.dispatch("selectedInventory/resetItems");
          }
        } else if (isCrafterType.value) {
          const burnResourceItemsTransaction =
            await QuestItemBurnContract.burnResource(
              resourcesSelected.value[0],
              selectedResourcesQuantity.value,
              character.value.number,
              selectedQuestForSubmit.value.id
            );
          if (burnResourceItemsTransaction?.hash) {
            const txHash = burnResourceItemsTransaction.hash;
            //TODO: need to pass id and quantity
            await apiConnector.handleTransactionHash(
              store.state.account,
              sessionId,
              character.value.number,
              "contractQuestResourceBurn",
              txHash
            );
          }
          const receipt = await burnResourceItemsTransaction.wait();
          if (receipt.status === 1) {
            console.log(receipt);
            console.log("Items Burned!");
            const finishQuest =
              await apiConnector.callFinishContractResourceQuest(
                store.state.account,
                sessionId,
                character.value.number,
                selectedQuestForSubmit.value.id,
                receipt.transactionHash
              );
            if (finishQuest.success) {
              store.commit("setInventory", finishQuest.inventory);
              store.commit("setCurrentPlayerStats", finishQuest.stats);
              store.commit("guild/setGuildQuestRewards", finishQuest.reward);
              store.commit("guild/setIsGuildRewardsOpen", true);
              hide();
            } else {
              store.commit("setInventory", finishQuest.inventory);
              store.commit("setNotification", finishQuest.message);
            }
          }
          console.log(receipt);
        }
        store.commit("setTurnHappening", false);
      } catch (error) {
        store.commit("setTurnHappening", false);
        store.commit("setNotification", parseError(error));
        console.log(error);
      }
    };

    const checkEquipmentApproval = async () => {
      let isBurningApproved = await EquipmentContract.isApprovedForAll(
        address.value,
        QuestItemBurnContract.address
      );

      if (isBurningApproved) {
        approvedItems.value = true;
      }
    };
    const checkResourcesApproval = async () => {
      let isBurningApproved = await ResourcesContract.isApprovedForAll(
        address.value,
        QuestItemBurnContract.address
      );

      if (isBurningApproved) {
        approvedItems.value = true;
      }
    };
    const approveItems = async () => {
      if (isDefenderType.value) {
        await equipmentApproval();
      } else if (isCrafterType.value) {
        await resourcesApproval();
      }
    };

    const equipmentApproval = async () => {
      try {
        store.commit("setTurnHappening", true);
        const txApproveEquipment = await EquipmentContract.setApprovalForAll(
          QuestItemBurnContract.address,
          true
        );
        await txApproveEquipment.wait();
        store.commit("setTurnHappening", false);
        await checkEquipmentApproval();
      } catch (e) {
        store.commit("setTurnHappening", false);
        store.commit("setNotification", parseError(e));
        console.log(e);
      }
    };
    const resourcesApproval = async () => {
      try {
        store.commit("setTurnHappening", true);
        const txApproveResources = await ResourcesContract.setApprovalForAll(
          QuestItemBurnContract.address,
          true
        );
        await txApproveResources.wait();
        store.commit("setTurnHappening", false);
        await checkResourcesApproval();
      } catch (e) {
        store.commit("setTurnHappening", false);
        store.commit("setNotification", parseError(e));
        console.log(e);
      }
    };

    const toggleItem = (item) => {
      if (isDefenderType.value) {
        store.dispatch("guild/toggleItem", {
          item,
          itemType: Constants.filterTypes.equipments,
        });
      } else if (isCrafterType.value) {
        store.dispatch("guild/toggleItem", {
          item,
          itemType: Constants.filterTypes.resources,
        });
      }
    };

    /**
     * Computed Methods
     */
    const isLoading = computed(() => {
      return store.state.isLoading;
    });

    const currentInventory = computed(() => {
      let results = [];
      console.log("Selected Name", selectedQuestForSubmit.value);
      if (store.state.inventory) {
        if (isDefenderType.value) {
          /**
           * Checking if item type is armour of weapon and also satisfies the quest targetItemName
           */
          results = store.state.inventory.filter(
            (item) =>
              (store.state.isArmourSlot(item.type) ||
                item.type == Constants.slots.hand2 ||
                item.type == Constants.slots.hand1) &&
              item.name.includes(
                selectedQuestForSubmit.value.targetItemNameIncludes
              )
          );
        } else if (isCrafterType.value) {
          results = store.state.inventory.filter(
            (item) =>
              (item.type == Constants.useable.resource || item.isBlueprint) &&
              item.id === selectedQuestForSubmit.value.targetItem.id
          );
        }
      }

      console.log(results);

      return results;
    });

    const isTurnHappening = computed(() => {
      if (
        store.state.isTurnHappening ||
        store.state.isUsingConsumable ||
        store.state.isFleeing ||
        store.state.combat.isHeroAttacking ||
        store.state.combat.isEnemyAttacking
      ) {
        return true;
      } else {
        return false;
      }
    });

    const inventoryTitle = computed(() => {
      let quantity = 0;
      console.log(selectedQuestForSubmit.value);
      if (isDefenderType.value) {
        quantity = equipmentSelected.value.length;
      } else if (isCrafterType.value) {
        quantity = selectedResourcesQuantity.value;
      }
      return `Selected ${quantity}/${selectedQuestForSubmit.value.quantity}`;
    });
    const selectedQuestForSubmit = computed(() => {
      return store.state.guild.selectedQuestForSubmit;
    });

    const equipmentSelected = computed(() => {
      return store.state.guild.selectedEquipment;
    });
    // const consumablesSelected = computed(() => {
    //   return store.state.stash.selectedConsumables;
    // });
    const resourcesSelected = computed(() => {
      return store.state.guild.selectedResources;
    });
    const selectedResourcesQuantity = computed(() => {
      // only selecting one resource and returning its quantity
      return store.state.guild.selectedResourcesQuantities[0] === undefined
        ? 0
        : store.state.guild.selectedResourcesQuantities[0];
    });
    const character = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });
    const isNotEnoughSelected = computed(() => {
      let quantity = 0;
      if (isDefenderType.value) {
        quantity = equipmentSelected.value.length;
      } else if (isCrafterType.value) {
        quantity = selectedResourcesQuantity.value;
      }
      return quantity < selectedQuestForSubmit.value.quantity;
    });

    const isDefenderType = computed(() => {
      if (
        selectedQuestForSubmit.value.goalType ===
        Constants.contractQuestsGoalTypes.equipmentCollection
      ) {
        return true;
      }
      return false;
    });

    const isCrafterType = computed(() => {
      if (
        selectedQuestForSubmit.value.goalType ===
        Constants.contractQuestsGoalTypes.resourceCollection
      ) {
        return true;
      }
      return false;
    });
    return {
      /* State Data */
      approvedItems,
      /* Methods */
      hide,
      toggleItem,
      isItemSelected,
      equipmentApproval,
      resourcesApproval,
      submitItems,
      approveItems,
      /* Computed Methods */
      isLoading,
      currentInventory,
      isTurnHappening,
      selectedQuestForSubmit,
      inventoryTitle,
      isNotEnoughSelected,
      isDefenderType,
      isCrafterType,
    };
  },
};
</script>

<style lang="scss">
.item {
  &.selected {
    .item-container > img {
      background: #e6c356;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.guild-inventory-wrapper {
  &:after {
    content: "";
    display: block;
    background: #000000d6;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 13;
  }
}
.guild-inventory {
  width: calc(388px - $popup-border-size - $popup-border-size);
  height: calc(450px - $popup-border-size - $popup-border-size);
  z-index: 14;
  display: block;
  .close-button {
    top: -80px;
    right: -80px;
    position: absolute;
    &.loading {
      filter: grayscale(100);
      opacity: 0.5;
    }
  }
  .items-wrapper {
    position: absolute;
    left: -37px;
    top: 20px;
    width: 330px;
    height: 100%;
    .items-container {
      width: 321px;
      height: 300px;
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 15px;
        height: 10px;
      }

      ::-webkit-scrollbar-track {
        background: transparent;
      }

      ::-webkit-scrollbar-button {
        background-color: transparent;
        width: 15px;
        height: 15px;
      }

      &::-webkit-scrollbar-thumb {
        border-top: 26px solid transparent;
        background: url("https://cdn.dragoncrypto.io/uiassets/scrollbar-slider.png")
          repeat-y;
        border-bottom: 32px solid transparent;
        background-clip: content-box;
      }
      .items {
        background: url("../../assets/ui/equipment-tile-bg.png") repeat;
        width: 295px;
        min-height: 294px;
        background-size: auto;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(44px, 1fr));
        grid-gap: 5.5px 5px;
        align-content: start;
        padding: 3px 2px;
        &.loading {
          &:after {
            content: "";
            display: block;
            width: 91%;
            height: 80%;
            background: transparent;
            top: 0;
            left: 0;
            position: absolute;
            z-index: 2;
          }
        }
      }
    }
  }

  .buttons-container {
    position: absolute;
    bottom: calc(-1 * calc($popup-border-size / 2));
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    width: calc(388px - $popup-border-size - $popup-border-size);
    p {
      margin: 5px 0;
      font-size: 0.6rem;
      color: #ffffff8a;
    }
  }
}
</style>
