<template>
  <div class="success-message">
    <h2>Congratulations!</h2>
    <p class="message">
      You have successfully crafted <span>{{ item.name }}</span
      >! You can claim the item from the stash.
    </p>
    <div
      :style="`background-image:url('https://ik.imagekit.io/dcg/equip/${item.imageName}?tr=w-200')`"
      class="item-image"
    >
      <GenericLoading class="loading" />
    </div>
    <p class="rarity">
      <span :class="item.rarity.toLowerCase()">{{ item.rarity }}</span>
    </p>
    <BlueButton @click="resetSuccessMessage" size="extra-small"
      >Continue</BlueButton
    >
    <Celebrate type="stars" />
  </div>
</template>

<script>
import { computed } from "vue";
import BlueButton from "./Buttons/BlueButton.vue";
import { useStore } from "vuex";
import GenericLoading from "./LoadingComponents/GenericLoading.vue";
import Celebrate from "./Celebrate/Celebrate.vue";

export default {
  name: "CraftingSuccess",
  components: {
    BlueButton,
    GenericLoading,
    Celebrate,
  },
  props: {
    item: {},
  },
  setup() {
    const store = useStore();

    const resetSuccessMessage = () => {
      if (isCraftingComplete.value) {
        store.commit("setResetCraftSuccessMessage");
      }
    };
    const isCraftingComplete = computed(() => {
      return store.state.isCraftingComplete;
    });

    return {
      // Data
      // Methods
      resetSuccessMessage,
      // Computed
      isCraftingComplete,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";
.success-message {
  div.item-image {
    width: 200px;
    height: 200px;
    display: block;
    margin: 15px auto;
    background-size: cover;
    position: relative;
    .loading {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      position: absolute;
      animation: hideAnimation 0s ease-in 5s; /** hiding loading after 5 seconds in case it's visible in transparent image */
      animation-fill-mode: forwards;
    }
  }
  p.message {
    line-height: 1.4rem;
    width: 70%;
    margin: 0 auto 10px;
    span {
      color: $gold;
    }
  }
  p.rarity {
    margin: 20px auto 40px;
    span {
      padding: 5px 10px;
      font-weight: bold;
      &.shoddy {
        border: 1px solid $shoddy;
        padding: 5px 10px;
      }

      &.epic {
        background-color: $epic;
        color: #000;
      }

      &.legendary {
        background-color: $legendary;
        color: #000;
      }

      &.mythical {
        background-color: $mythical;
      }

      &.fabled {
        background-color: $fabled;
      }

      &.unique {
        background-color: $unique;
      }

      &.rare {
        background-color: $rare;
      }

      &.artefact {
        background-color: $artefact;
        color: #000;
      }

      &.junk {
        border: 1px solid #000;
      }

      &.uncommon {
        border: 1px solid $uncommon;
      }
    }
  }
}
@keyframes hideAnimation {
  to {
    opacity: 0;
  }
}
</style>
