import { Howl, Howler } from "howler";
import { onMounted, ref, unref, watch } from "vue";
import { useStore } from "vuex";

function useSound(
  url,
  {
    volume = 1,
    playbackRate = 1,
    soundEnabled = true,
    interrupt = false,
    onload,
    ...delegated
  } = {}
) {
  const HowlConstructor = ref(Howl);
  const isPlaying = ref(false);
  const store = useStore();

  let duration = ref(null);
  let sound = ref(null);

  onMounted(() => {
    sound.value = new HowlConstructor.value({
      src: [url],
      volume: unref(volume),
      rate: unref(playbackRate),
      onload: handleLoad,
      ...delegated,
    });
  });
  const handleLoad = function () {
    if (typeof onload === "function") {
      // @ts-ignore
      onload.call(this);
    }
    duration.value = duration.value ? duration.value * 1000 : 0;
  };
  watch(
    () => [url],
    () => {
      if (
        HowlConstructor.value &&
        HowlConstructor.value &&
        sound &&
        sound.value
      ) {
        sound.value = new HowlConstructor.value({
          src: [url],
          volume: unref(volume),
          rate: unref(playbackRate),
          onload: handleLoad,
          ...delegated,
        });
      }
    }
  );
  watch(
    () => [unref(volume), unref(playbackRate)],
    () => {
      if (sound.value) {
        sound.value.volume(unref(volume));
        sound.value.rate(unref(playbackRate));
      }
    }
  );
  const play = (options) => {
    if (typeof options === "undefined") {
      options = {};
    }
    if (!sound.value || (!soundEnabled && !options.forceSoundEnabled)) {
      return;
    }
    if (interrupt) {
      sound.value.stop();
    }
    if (options.playbackRate) {
      sound.value.rate(options.playbackRate);
    }
    sound.value.play(options.id);
    sound.value.once("end", () => {
      if (sound && sound.value && !sound.value.playing()) {
        isPlaying.value = false;
      }
    });
    isPlaying.value = true;
  };
  const stop = (id) => {
    if (!sound.value) {
      return;
    }
    sound.value.stop(typeof id === "number" ? id : undefined);
    isPlaying.value = false;
  };
  const pause = (id) => {
    if (!sound.value) {
      return;
    }
    sound.value.pause(typeof id === "number" ? id : undefined);
    isPlaying.value = false;
  };
  store.watch(
    (state) => state.soundVolume,
    (value) => {
      Howler.volume(value);
    }
  );
  const returnedValue = {
    play,
    sound,
    isPlaying,
    duration,
    pause,
    stop,
  };
  return returnedValue;
}

export { useSound };
