<template>
  <div class="stash-quantity" v-if="maxQuantity !== stashItemQuantity">
    <input
      v-model="stashItemQuantity"
      :max="maxQuantity"
      @keypress="setStashItemSelectedQuantity"
      type="number"
      disabled
    />
    / {{ maxQuantity }}
    <p>Quantity</p>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import Constants from "../../consts/constants";
export default {
  name: "QuantitySelect",
  props: {
    id: {},
    maxQuantity: {},
    type: {},
  },
  setup(props) {
    const store = useStore();
    const item = ref(null);
    const stashItemQuantity = ref(0);
    const setStashItemSelectedQuantity = async () => {
      // adding a bit of delay for typing to complete
      await delay(500);

      // validations to ensure input value is always within required upper/lower limits
      if (stashItemQuantity.value < 0) stashItemQuantity.value = 0;
      if (stashItemQuantity.value > props.maxQuantity)
        stashItemQuantity.value = props.maxQuantity;

      if (isConsumable.value) {
        // Setting the quantity for the current consumable id
        store.commit("stash/setStashConsumablesSelectedQuantity", {
          id: props.id,
          quantity: parseInt(stashItemQuantity.value),
        });
      } else if (isResource.value) {
        // Setting the quantity for the current consumable id
        store.commit("stash/setStashResourcesSelectedQuantity", {
          id: props.id,
          quantity: parseInt(stashItemQuantity.value),
        });
      }
    };
    /**
     * Ensure that the input box always reflects
     * the quantity for that particular item id.
     */
    const getSelectedQuantityForCurrentItem = (id) => {
      let indexOfId = null;
      if (isConsumable.value) {
        indexOfId = store.state.stash.selectedConsumables.indexOf(id);
        stashItemQuantity.value =
          store.state.stash.selectedConsumablesQuantities[indexOfId];
      } else if (isResource.value) {
        indexOfId = store.state.stash.selectedResources.indexOf(id);
        stashItemQuantity.value =
          store.state.stash.selectedResourcesQuantities[indexOfId];
      }
    };

    const delay = async (time) => {
      return new Promise((res) => setTimeout(res, time));
    };

    const isConsumable = computed(() => {
      return props.type === Constants.useable.consumable;
    });
    const isResource = computed(() => {
      return props.type === Constants.useable.resource;
    });

    onMounted(() => {
      item.value = store.state.hoveredItem;
      /**
       * setting the input value on mounting of quantity select
       * this will ideally be the default max quantity of the item, set when the item is selected.
       */
      getSelectedQuantityForCurrentItem(props.id);

      /**
       * Also monitoring the change in hovered item in main state.
       * This is to ensure that the input field quantity also updates
       * when one moves the cursor from one item to another.
       *
       * It also works when the QuantitySelect is unmounted and mounted again. But as there is a slight delay in the hovered
       * item change due to setTimeout, it never worked if the hovering was fast. Hence, monitoring the state is vital too.
       */
      store.watch(
        (state) => state.hoveredItem,
        (item) => {
          getSelectedQuantityForCurrentItem(item.id);
        }
      );
    });
    return {
      // Data
      item,
      stashItemQuantity,
      // Methods
      setStashItemSelectedQuantity,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.stash-quantity {
  display: block;
  width: 100%;
  margin: 5px 0 0;
  p {
    margin-top: 5px;
  }
  input {
    width: 50px;
    margin-bottom: 0;
    text-align: center;
  }
}
</style>
