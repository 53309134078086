<template>
  <div
    :class="[getClass, enemyAttackingClass, abilityAttackingClass]"
    v-if="combatOpenState"
  >
    <h2>{{ `Battle with ${enemyName} the ${enemySuffix}!` }}</h2>

    <div class="inner-dialog-wrapper">
      <div class="combat-container">
        <div>
          <CombatCharacter
            :ref="heroSection"
            type="hero"
            :character="getCharacter"
          />
          <HeroPet v-if="selectedPet" type="pet" :pet="selectedPet" />
        </div>
        <div></div>
        <CombatCharacter :ref="enemySection" type="enemy" :enemy="enemy" />
      </div>
      <div class="buttons after-combat">
        <GameMessage :class="isHeroDead || isEnemyDead ? '' : 'hide-messages'">
          <div id="combat-log" class="combat-log">
            <p
              v-for="combatLogEntry in combatLog"
              v-html="combatLogEntry"
              :key="combatLogEntry"
            ></p>
          </div>
        </GameMessage>
        <DialogButton
          v-if="isEnemyDead"
          @click="lootEnemy"
          :buttonText="'Examine loot'"
        />
        <div v-else-if="!(getCharacter != null && !player.stats.isDead)">
          <DialogButton
            v-if="player.stats.isDead && hasResurrectionElixir"
            @click="useResurrectionElixir"
            :buttonText="'Use Resurrection Elixir'"
            :isLoading="isHealing"
          />
          <DialogButton
            id="go-to-healers"
            @click="healersHutDead"
            :buttonText="'Healer\'s Hut'"
            :isLoading="isHealing"
          />
          <DialogButton
            @click="leavePlainsDead"
            :buttonText="'Leave Plains'"
            :isLoading="isHealing"
          />
        </div>
      </div>
      <template v-if="!isEnemyDead">
        <template v-if="getCharacter != null && !player.stats.isDead">
          <CombatButtons />
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import { io } from "socket.io-client";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";
import * as XorShift from "xorshift";
import { useSound } from "../../composables/sound";
import Constants from "../../consts/constants";
import DialogButton from "../DialogButton.vue";
import CombatButtons from "./CombatButtons.vue";
import CombatCharacter from "./CombatCharacter.vue";
import GameMessage from "./GameMessage.vue";
import HeroPet from "./HeroPet.vue";

import bowHit from "../../assets/sound/bowhit.mp3";
import closeCombat from "../../assets/sound/closecombat.wav";
import dragonhit from "../../assets/sound/dragon-hit.mp3";
import grunt from "../../assets/sound/grunt.mp3";
import heavyhit from "../../assets/sound/heavyhit.wav";
import hit from "../../assets/sound/hitsound.mp3";
import magichit from "../../assets/sound/magichit.mp3";
import mobdeath from "../../assets/sound/mobdeath.wav";
import pistolhit from "../../assets/sound/pistolhit.wav";
import polearmhit from "../../assets/sound/polearmhit.wav";
import riflehit from "../../assets/sound/riflehit.mp3";
import lighteningBold from "../../assets/sound/lightening-bolt.mp3";

export default {
  name: "Combat",
  components: {
    GameMessage,
    DialogButton,
    CombatCharacter,
    CombatButtons,
    HeroPet,
  },
  emits: ["useSlot", "lootEnemy", "healersHutDead", "leavePlainsDead"],
  props: {},
  setup(props, { emit }) {
    const store = useStore();
    const heroSection = ref(null);
    const enemySection = ref(null);
    const socket = io(Constants.apiUrl, { transports: ["websocket"] });

    /**
     * Defining sound files using sound composable
     * The sprites are a tuple of starting point and duration. Both in milliseconds
     */

    const bowHitSound = useSound(bowHit, {
      sprite: {
        bowhit1: [0, 280],
        bowhit2: [290, 560],
        bowhit3: [540, 240],
        bowhit4: [810, 1050],
      },
    });
    const hitSound = useSound(hit, {
      sprite: {
        hit1: [30, 620],
        hit2: [660, 570],
        hit3: [1230, 620],
        hit4: [1880, 610],
      },
    });
    const closeCombatSound = useSound(closeCombat, {
      sprite: {
        closecombat1: [30, 560],
        closecombat2: [1240, 820],
        closecombat3: [2555, 580],
        closecombat4: [3450, 290],
      },
    });
    const heavyHitSound = useSound(heavyhit, {
      sprite: {
        heavyhit1: [30, 560],
        heavyhit2: [1240, 820],
        heavyhit3: [2860, 640],
        heavyhit4: [3790, 780],
      },
    });
    const magicHitSound = useSound(magichit, {
      sprite: {
        magichit1: [10, 1450],
        magichit2: [1500, 1160],
        magichit3: [2690, 1680],
        magichit4: [4440, 2090],
      },
    });
    const pistolHitSound = useSound(pistolhit, {
      sprite: {
        pistolhit1: [50, 1000],
        pistolhit2: [2250, 900],
        pistolhit3: [4330, 860],
        pistolhit4: [5720, 680],
      },
    });
    const polearmHitSound = useSound(polearmhit, {
      sprite: {
        polearmhit1: [10, 340],
        polearmhit2: [1030, 660],
        polearmhit3: [1880, 830],
        polearmhit4: [3000, 740],
      },
    });
    const rifleHitSound = useSound(riflehit, {
      sprite: {
        riflehit1: [0, 460],
        riflehit2: [480, 1870],
        riflehit3: [2390, 2010],
        riflehit4: [4440, 1230],
      },
    });
    const gruntSound = useSound(grunt, {
      sprite: {
        grunt1: [140, 310],
        grunt2: [1020, 290],
        grunt3: [1530, 430],
        grunt4: [2420, 320],
        grunt5: [2660, 720],
      },
    });
    const mobdeathSound = useSound(mobdeath, {
      sprite: {
        mobdeath1: [0, 980],
        mobdeath2: [990, 2110],
        mobdeath3: [3970, 2510],
        mobdeath4: [6940, 3370],
        mobdeath5: [10970, 1560],
      },
    });

    const dragonHitSound = useSound(dragonhit, {
      sprite: {
        dragonhit1: [60, 860],
        dragonhit2: [1410, 1160],
        dragonhit3: [3060, 1330],
        dragonhit4: [4820, 920],
      },
    });

    const lighteningHit = useSound(
      // "https://cdn.dragoncrypto.io/sound/break.mp3",
      lighteningBold,
      {
        volume: store.state.soundVolume,
        interrupt: true,
      }
    );

    // Joining socket but only listening to this particular session ID
    socket.emit("join", { sessionId: localStorage.getItem("sessionId") });

    let spacebarlistener;

    onMounted(() => {
      // Resetting old combat data on new combat start
      store.dispatch("combat/reset");

      socket.on("first-hit", async (data) => {
        if (typeof data.hit.enemyDamage !== "undefined") {
          await delay(200); // Added slight delay so that the enemy doesn't attack instantly after game start lol
          await processAttack(data.hit);
          if (store.state.combat.isAutoplayOn) {
            store.dispatch("combat/autoplayAttack");
          }
        }
      });
      socket.on("combat-hit", async (data) => {
        if (data.hit !== typeof undefined) {
          await processAttack(data.hit);
          if (store.state.combat.isAutoplayOn) {
            if (!isHeroDead.value && !isEnemyDead.value) {
              store.dispatch("combat/autoplayAttack");
            }
          }
        }
      });
      socket.on(
        "combat-heal",
        async ({ consumeResult, enemyHit, heroStats }) => {
          await animateHeal(consumeResult, enemyHit, heroStats);
        }
      );
      socket.on("combat-flee-fail", async (response) => {
        if (response) {
          if (!response.fleeSuccess) {
            await animateFleeFail(response.enemyHit, response.heroStats);
          } else {
            playCombatSound("flee");
          }
        }
      });
      socket.on("combat-switch-damage", async (response) => {
        if (response) {
          await processAttack(response);
        }
      });

      spacebarlistener = window.addEventListener("keydown", (event) => {
        if (inCombat.value && isEnemyDead.value && event.key === " ") {
          event.preventDefault();
        }
      });
    });

    const useResurrectionElixir = async () => {
      await emit("useSlot", resurrectionElixirSlot.value);
    };

    const lootEnemy = () => {
      emit("lootEnemy");
    };

    const healersHutDead = () => {
      emit("healersHutDead");
    };

    const leavePlainsDead = () => {
      emit("leavePlainsDead");
    };

    onUnmounted(() => {
      removeEventListener("keydown", spacebarlistener);
      socket.removeAllListeners("combat-hit");
      socket.removeAllListeners("first-hit");
      socket.removeAllListeners("combat-heal");
      socket.removeAllListeners("combat-flee-fail");
      socket.disconnect();
    });

    /**
     * Watching game mode and when game mode changes to combat,
     * getting the encounter details. If the enemy has first hit
     * and autoplay is on, we activate the autoplay hit.
     * TODO: This should ideally be called onMounting. But need to refactor code to mount
     * combat component only when game mode is combat
     */
    store.watch(
      (state) => state.gameState,
      (value) => {
        if (value === Constants.gamemodes.combat) {
          if (
            store.state.currentEncounter.playerHasFirstShot &&
            store.state.combat.isAutoplayOn
          ) {
            store.dispatch("combat/autoplayAttack");
          }
        }
      }
    );

    /**
     * Methods
     */
    const processAttack = async (hit) => {
      console.log(hit);
      if (hit.playerDamage && !hit.playerDamage.weaponType) {
        hit.playerDamage.weaponType = "closeCombat";
      }

      if (hit.enemyDamage && !hit.enemyDamage.weaponType) {
        hit.enemyDamage.weaponType = "closeCombat";
      }

      // // TODO REMOVE THIS. ONLY FOR TESTING
      // hit.enemyDamage.weaponType = Constants.weaponType.heavy;
      // hit.playerDamage.weaponType = Constants.weaponType.magic;
      console.log(hit);
      // hit.abilityHit = true;

      // hit.enemyDamage.weaponType = "closeCombat";
      // hit.playerDamage.weaponType = "closeCombat";

      // Setting special ability active
      if (hit.abilityHit) {
        store.commit("combat/setIsAbilityAttack", hit.abilityHit);
        lighteningHit.play();
      }
      /**
       * Hero and Pet Attack Animations
       */
      if (hit.petHit) {
        await animateAttackPet(hit.playerDamage, "pet");
      } else if (hit.playerDamage) {
        switch (hit.playerDamage.weaponType) {
          case Constants.weaponType.axe:
            await animateAttackBlade(
              hit.playerDamage,
              hit.enemyDamage,
              hit.heroStats,
              "hero"
            );
            break;
          case Constants.weaponType.bow:
            await animateAttackBow(
              hit.playerDamage,
              hit.enemyDamage,
              hit.heroStats,
              "hero"
            );
            break;
          case Constants.weaponType.pistol:
            await animateAttackBullet(
              hit.playerDamage,
              hit.enemyDamage,
              hit.heroStats,
              "hero"
            );
            break;
          case Constants.weaponType.rifle:
            await animateAttackRifle(
              hit.playerDamage,
              hit.enemyDamage,
              hit.heroStats,
              "hero"
            );
            break;
          case Constants.weaponType.blade:
            await animateAttackBlade(
              hit.playerDamage,
              hit.enemyDamage,
              hit.heroStats,
              "hero"
            );
            break;
          case Constants.weaponType.heavy:
            await animateAttackHeavy(
              hit.playerDamage,
              hit.enemyDamage,
              hit.heroStats,
              "hero"
            );
            break;
          case Constants.weaponType.poleArm:
            await animateAttackPolearm(
              hit.playerDamage,
              hit.enemyDamage,
              hit.heroStats,
              "hero"
            );
            break;
          case Constants.weaponType.magic:
            await animateAttackMagic(
              hit.playerDamage,
              hit.enemyDamage,
              hit.heroStats,
              "hero"
            );
            break;
          default:
            hit.playerDamage.weaponType = "closeCombat";
            await animateAttackCloseCombat(
              hit.playerDamage,
              hit.enemyDamage,
              hit.heroStats,
              "hero"
            );
        }
      }
      // Load win messages if enemy dead
      if (hit.playerDamage && hit.playerDamage.isKillShot) {
        mobdeathSound.play({
          id: `mobdeath${1 + Math.floor(XorShift.random() * 5)}`,
        });
        store.dispatch("combat/reset");
        await delay(1000);
        doScroll();
      } else {
        /**
         * Enemy Attack Animations
         */
        if (hit.enemyDamage) {
          switch (hit.enemyDamage.weaponType) {
            case Constants.weaponType.axe:
              await animateAttackBlade(
                hit.playerDamage,
                hit.enemyDamage,
                hit.heroStats,
                "enemy"
              );
              break;
            case Constants.weaponType.bow:
              await animateAttackBow(
                hit.playerDamage,
                hit.enemyDamage,
                hit.heroStats,
                "enemy"
              );
              break;
            case Constants.weaponType.pistol:
              await animateAttackBullet(
                hit.playerDamage,
                hit.enemyDamage,
                hit.heroStats,
                "enemy"
              );
              break;
            case Constants.weaponType.rifle:
              await animateAttackRifle(
                hit.playerDamage,
                hit.enemyDamage,
                hit.heroStats,
                "enemy"
              );
              break;
            case Constants.weaponType.blade:
              await animateAttackBlade(
                hit.playerDamage,
                hit.enemyDamage,
                hit.heroStats,
                "enemy"
              );
              break;
            case Constants.weaponType.heavy:
              await animateAttackHeavy(
                hit.playerDamage,
                hit.enemyDamage,
                hit.heroStats,
                "enemy"
              );
              break;
            case Constants.weaponType.poleArm:
              await animateAttackPolearm(
                hit.playerDamage,
                hit.enemyDamage,
                hit.heroStats,
                "enemy"
              );
              break;
            case Constants.weaponType.magic:
              await animateAttackMagic(
                hit.playerDamage,
                hit.enemyDamage,
                hit.heroStats,
                "enemy"
              );
              break;
            default:
              hit.enemyDamage.weaponType = "closeCombat";
              await animateAttackCloseCombat(
                hit.playerDamage,
                hit.enemyDamage,
                hit.heroStats,
                "enemy"
              );
          }
        }
      }
    };

    const getSlotDesc = (number) => {
      if (getHotbar.value[number]) {
        return getHotbar.value[number].name;
      }
      return "Fists";
    };

    const doScroll = () => {
      const logElement = document.getElementById("combat-log");
      logElement.scroll({ top: logElement.scrollHeight, behavior: "smooth" });
    };

    // Helper method to delay stuff
    const delay = async (time) => new Promise((res) => setTimeout(res, time));

    /**
     * Animate in close combat fight
     */
    const animateAttackCloseCombat = async (
      playerDamage,
      enemyDamage,
      heroStats,
      attackBy
    ) => {
      if (attackBy === "hero") {
        // Animating Hero Attack with sound
        if (typeof playerDamage !== undefined) {
          store.dispatch("combat/attackHero", playerDamage);
        }
        gruntSound.play({
          id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
        });
        await delay(500); // Waiting for hero to reach enemy first
        closeCombatSound.play({
          id: `closecombat${1 + Math.floor(XorShift.random() * 4)}`,
        });

        await delay(1000); // waiting for Hero attack to finish
      } else if (attackBy === "enemy") {
        // Animating Enemy attack after hero attack ends;
        // Only attack if enemy is alive
        if (typeof enemyDamage !== "undefined") {
          store.dispatch("combat/attackEnemy", enemyDamage);
          gruntSound.play({
            id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
          });

          await delay(500); // Waiting for enemy to reach enemy first
          closeCombatSound.play({
            id: `closecombat${1 + Math.floor(XorShift.random() * 4)}`,
          });
        }

        if (heroStats.isDead) {
          onDeadMessage(enemyDamage);
          mobdeathSound.play({
            id: `mobdeath${1 + Math.floor(XorShift.random() * 5)}`,
          });
        }

        await delay(1000); // waiting for Enemy attack to finish

        // Resetting attack states
        store.dispatch("combat/reset");

        // await delay(250);
      }
    };

    /**
     * Animate pet attack
     */
    const animateAttackPet = async (playerDamage, attackBy) => {
      if (attackBy === "pet") {
        // Animating Hero Attack with sound
        if (typeof playerDamage !== undefined) {
          store.dispatch("combat/attackPet", playerDamage);
        }
        dragonHitSound.play({
          id: `dragonhit${1 + Math.floor(XorShift.random() * 4)}`,
        });
        await delay(500); // Waiting for hero to reach enemy first
        hitSound.play({
          id: `hit${1 + Math.floor(XorShift.random() * 4)}`,
        });

        await delay(1000); // waiting for Hero attack to finish
      }
    };

    /**
     * Animate when using a blade weapon
     */
    const animateAttackBlade = async (
      playerDamage,
      enemyDamage,
      heroStats,
      attackBy
    ) => {
      if (attackBy === "hero") {
        // Animating Hero Attack with sound
        if (typeof playerDamage !== undefined) {
          store.dispatch("combat/attackHero", playerDamage);
        }
        gruntSound.play({
          id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
        });
        await delay(500); // Waiting for hero to reach enemy first
        hitSound.play({
          id: `hit${1 + Math.floor(XorShift.random() * 4)}`,
        });

        await delay(1000); // waiting for Hero attack to finish
      } else if (attackBy === "enemy") {
        // Animating Enemy attack after hero attack ends;
        // Only attack if enemy is alive
        if (typeof enemyDamage !== "undefined") {
          store.dispatch("combat/attackEnemy", enemyDamage);
          gruntSound.play({
            id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
          });
          await delay(500); // Waiting for enemy to reach enemy first
          hitSound.play({
            id: `hit${1 + Math.floor(XorShift.random() * 4)}`,
          });
        }
        if (heroStats.isDead) {
          onDeadMessage(enemyDamage);
          mobdeathSound.play({
            id: `mobdeath${1 + Math.floor(XorShift.random() * 5)}`,
          });
        }
        await delay(1000); // waiting for Enemy attack to finish
        // Resetting attack states
        store.dispatch("combat/reset");
        // await delay(250);
      }
    };

    /**
     * Animate when using a polearm weapon
     */
    const animateAttackPolearm = async (
      playerDamage,
      enemyDamage,
      heroStats,
      attackBy
    ) => {
      if (attackBy === "hero") {
        // Animating Hero Attack with sound
        if (typeof playerDamage !== undefined) {
          store.dispatch("combat/attackHero", playerDamage);
        }
        gruntSound.play({
          id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
        });
        await delay(500); // Waiting for hero to reach enemy first
        // playCombatSound("bowhit");
        polearmHitSound.play({
          id: `polearmhit${1 + Math.floor(XorShift.random() * 4)}`,
        });

        await delay(1000); // waiting for Hero attack to finish
      } else if (attackBy === "enemy") {
        // Animating Enemy attack after hero attack ends;
        // Only attack if enemy is alive
        if (typeof enemyDamage !== "undefined") {
          store.dispatch("combat/attackEnemy", enemyDamage);
          gruntSound.play({
            id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
          });
          await delay(500); // Waiting for enemy to reach enemy first
          polearmHitSound.play({
            id: `polearmhit${1 + Math.floor(XorShift.random() * 4)}`,
          });
        }
        if (heroStats.isDead) {
          onDeadMessage(enemyDamage);
          mobdeathSound.play({
            id: `mobdeath${1 + Math.floor(XorShift.random() * 5)}`,
          });
        }
        await delay(1000); // waiting for Enemy attack to finish
        // Resetting attack states
        store.dispatch("combat/reset");
        // await delay(250);
      }
    };
    /**
     * Animate when using a heavy weapon
     */
    const animateAttackHeavy = async (
      playerDamage,
      enemyDamage,
      heroStats,
      attackBy
    ) => {
      if (attackBy === "hero") {
        // Animating Hero Attack with sound
        if (typeof playerDamage !== undefined) {
          store.dispatch("combat/attackHero", playerDamage);
        }
        gruntSound.play({
          id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
        });
        await delay(500); // Waiting for hero to reach enemy first
        heavyHitSound.play({
          id: `heavyhit${1 + Math.floor(XorShift.random() * 4)}`,
        });

        await delay(1000); // waiting for Hero attack to finish
      } else if (attackBy === "enemy") {
        // Animating Enemy attack after hero attack ends;
        // Only attack if enemy is alive
        if (typeof enemyDamage !== "undefined") {
          store.dispatch("combat/attackEnemy", enemyDamage);
          gruntSound.play({
            id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
          });
          await delay(500); // Waiting for enemy to reach enemy first
          heavyHitSound.play({
            id: `heavyhit${1 + Math.floor(XorShift.random() * 4)}`,
          });
        }

        if (heroStats.isDead) {
          onDeadMessage(enemyDamage);
          mobdeathSound.play({
            id: `mobdeath${1 + Math.floor(XorShift.random() * 5)}`,
          });
        }

        await delay(1000); // waiting for Enemy attack to finish

        // Resetting attack states
        store.dispatch("combat/reset");

        // await delay(250);
      }
    };

    /**
     * Animate when using a bow and arraow
     */
    const animateAttackBow = async (
      playerDamage,
      enemyDamage,
      heroStats,
      attackBy
    ) => {
      if (attackBy === "hero") {
        // Animating Hero Attack with sound
        if (typeof playerDamage !== undefined) {
          store.dispatch("combat/attackHero", playerDamage);
        }
        // playCombatSound("bowhit");
        bowHitSound.play({
          id: `bowhit${1 + Math.floor(XorShift.random() * 4)}`,
        });
        await delay(500); // Waiting for hero to reach enemy first
        gruntSound.play({
          id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
        });

        await delay(1000); // waiting for Hero attack to finish
      } else if (attackBy === "enemy") {
        // Animating Enemy attack after hero attack ends;
        // Only attack if enemy is alive
        if (typeof enemyDamage !== "undefined") {
          store.dispatch("combat/attackEnemy", enemyDamage);
          bowHitSound.play({
            id: `bowhit${1 + Math.floor(XorShift.random() * 4)}`,
          });
          await delay(500); // Waiting for enemy to reach enemy first
          gruntSound.play({
            id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
          });
        }

        if (heroStats.isDead) {
          onDeadMessage(enemyDamage);
          mobdeathSound.play({
            id: `mobdeath${1 + Math.floor(XorShift.random() * 5)}`,
          });
        }

        await delay(1000); // waiting for Enemy attack to finish
        // Resetting attack states
        store.dispatch("combat/reset");
        // await delay(250);
      }
    };

    /**
     * Aniamte when using a pistol weapon
     */
    const animateAttackBullet = async (
      playerDamage,
      enemyDamage,
      heroStats,
      attackBy
    ) => {
      if (attackBy === "hero") {
        // Animating Hero Attack with sound
        if (typeof playerDamage !== undefined) {
          store.dispatch("combat/attackHero", playerDamage);
        }
        pistolHitSound.play({
          id: `pistolhit${1 + Math.floor(XorShift.random() * 4)}`,
        });
        await delay(500); // Waiting for hero to reach enemy first
        gruntSound.play({
          id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
        });

        await delay(1000); // waiting for Hero attack to finish
      } else if (attackBy === "enemy") {
        // Animating Enemy attack after hero attack ends;
        // Only attack if enemy is alive
        if (typeof enemyDamage !== "undefined") {
          store.dispatch("combat/attackEnemy", enemyDamage);
          pistolHitSound.play({
            id: `pistolhit${1 + Math.floor(XorShift.random() * 4)}`,
          });
          await delay(500); // Waiting for enemy to reach enemy first
          gruntSound.play({
            id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
          });
        }

        if (heroStats.isDead) {
          onDeadMessage(enemyDamage);
          mobdeathSound.play({
            id: `mobdeath${1 + Math.floor(XorShift.random() * 5)}`,
          });
        }

        await delay(1000); // waiting for Enemy attack to finish

        // Resetting attack states
        store.dispatch("combat/reset");

        // await delay(250);
      }
    };

    /**
     * Animate when using a rifle
     */
    const animateAttackRifle = async (
      playerDamage,
      enemyDamage,
      heroStats,
      attackBy
    ) => {
      if (attackBy === "hero") {
        // Animating Hero Attack with sound
        if (typeof playerDamage !== undefined) {
          store.dispatch("combat/attackHero", playerDamage);
        }
        rifleHitSound.play({
          id: `riflehit${1 + Math.floor(XorShift.random() * 4)}`,
        });
        await delay(500); // Waiting for hero to reach enemy first
        gruntSound.play({
          id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
        });

        await delay(1000); // waiting for Hero attack to finish
      } else if (attackBy === "enemy") {
        // Animating Enemy attack after hero attack ends;
        // Only attack if enemy is alive
        if (typeof enemyDamage !== "undefined") {
          store.dispatch("combat/attackEnemy", enemyDamage);
          rifleHitSound.play({
            id: `riflehit${1 + Math.floor(XorShift.random() * 4)}`,
          });
          await delay(500); // Waiting for enemy to reach enemy first
          gruntSound.play({
            id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
          });
        }

        if (heroStats.isDead) {
          onDeadMessage(enemyDamage);
          mobdeathSound.play({
            id: `mobdeath${1 + Math.floor(XorShift.random() * 5)}`,
          });
        }

        await delay(1000); // waiting for Enemy attack to finish

        // Resetting attack states
        store.dispatch("combat/reset");

        // await delay(250);
      }
    };

    /**
     * Animate when using a magical weapon
     */
    const animateAttackMagic = async (
      playerDamage,
      enemyDamage,
      heroStats,
      attackBy
    ) => {
      if (attackBy === "hero") {
        // Animating Hero Attack with sound
        if (typeof playerDamage !== undefined) {
          store.dispatch("combat/attackHero", playerDamage);
        }
        magicHitSound.play({
          id: `magichit${1 + Math.floor(XorShift.random() * 4)}`,
        });
        await delay(500); // Waiting for hero to reach enemy first
        gruntSound.play({
          id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
        });

        await delay(1000); // waiting for Hero attack to finish
      } else if (attackBy === "enemy") {
        // Animating Enemy attack after hero attack ends;
        // Only attack if enemy is alive
        if (typeof enemyDamage !== "undefined") {
          store.dispatch("combat/attackEnemy", enemyDamage);
          magicHitSound.play({
            id: `magichit${1 + Math.floor(XorShift.random() * 4)}`,
          });
          await delay(1000); // Waiting for enemy to reach hero first
          gruntSound.play({
            id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
          });
        }

        if (heroStats.isDead) {
          onDeadMessage(enemyDamage);
          mobdeathSound.play({
            id: `mobdeath${1 + Math.floor(XorShift.random() * 5)}`,
          });
        }

        await delay(1000); // waiting for Enemy attack to finish

        // Resetting attack states
        store.dispatch("combat/reset");

        // await delay(250);
      }
    };

    /**
     * Animate heal and enemy attack
     */
    // TODO : Refactor enemy attack cases into an individual function to promote reusability
    const animateHeal = async (consumeResult, enemyDamage, heroStats) => {
      // Animating Hero Attack with sound
      if (typeof consumeResult !== undefined) {
        store.dispatch("combat/healHero", consumeResult);
      }
      playCombatSound("sigh"); // TODO replace with relief sound
      await delay(1000); // Waiting for heal animation to finish

      if (enemyDamage && typeof enemyDamage.weaponType === "undefined") {
        enemyDamage.weaponType = "closeCombat";
      }

      switch (enemyDamage.weaponType) {
        case Constants.weaponType.axe:
          await animateAttackBlade({}, enemyDamage, heroStats, "enemy");
          break;
        case Constants.weaponType.bow:
          await animateAttackBow({}, enemyDamage, heroStats, "enemy");
          break;
        case Constants.weaponType.pistol:
          await animateAttackBullet({}, enemyDamage, heroStats, "enemy");
          break;
        case Constants.weaponType.rifle:
          await animateAttackRifle({}, enemyDamage, heroStats, "enemy");
          break;
        case Constants.weaponType.blade:
          await animateAttackBlade({}, enemyDamage, heroStats, "enemy");
          break;
        case Constants.weaponType.heavy:
          await animateAttackHeavy({}, enemyDamage, heroStats, "enemy");
          break;
        case Constants.weaponType.poleArm:
          await animateAttackBlade({}, enemyDamage, heroStats, "enemy");
          break;
        case Constants.weaponType.magic:
          await animateAttackMagic({}, enemyDamage, heroStats, "enemy");
          break;
        default:
          await animateAttackCloseCombat({}, enemyDamage, heroStats, "enemy");
      }
    };
    /**
     * Aniamte flee fail and enemy attack
     */
    const animateFleeFail = async (enemyDamage, heroStats) => {
      // if (enemyDamage) {
      //   store.commit("combat/setEnemyAttacking", true);
      // }
      playCombatSound("flee"); // TODO replace with relief sound

      // Animating Enemy attack after hero attack ends;
      // Only attack if enemy is alive
      if (typeof enemyDamage !== "undefined") {
        store.dispatch("combat/attackEnemy", enemyDamage);
        gruntSound.play({
          id: `grunt${1 + Math.floor(XorShift.random() * 5)}`,
        });
        await delay(500); // Waiting for enemy to reach enemy first
        playCombatSound("hit");
      }

      if (heroStats.isDead) {
        onDeadMessage(enemyDamage);
        mobdeathSound.play({
          id: `mobdeath${1 + Math.floor(XorShift.random() * 5)}`,
        });
      }

      await delay(1000); // waiting for Enemy attack to finish

      // Resetting attack states
      store.dispatch("combat/reset");

      // await delay(250);
      // doScroll();
    };

    /**
     * Single function to play various sounds
     * @params {string} type - name of the sound to play
     */
    const playCombatSound = (type) => {
      let hitSoundFileName = "";
      if (type === "hit") {
        hitSoundFileName =
          "hit" + (1 + Math.floor(XorShift.random() * 3)) + ".mp3";
      } else if (type === "closecombat") {
        hitSoundFileName =
          "closecombat" + (1 + Math.floor(XorShift.random() * 4)) + ".wav";
      } else if (type === "heavyhit") {
        hitSoundFileName =
          "heavyhit" + (1 + Math.floor(XorShift.random() * 4)) + ".wav";
      } else if (type === "bowhit") {
        hitSoundFileName =
          "bowhit" + (1 + Math.floor(XorShift.random() * 4)) + ".wav";
      } else if (type === "magichit") {
        hitSoundFileName =
          "magichit" + (1 + Math.floor(XorShift.random() * 4)) + ".wav";
      } else if (type === "pistolhit") {
        hitSoundFileName =
          "pistolhit" + (1 + Math.floor(XorShift.random() * 4)) + ".wav";
      } else if (type === "riflehit") {
        hitSoundFileName =
          "riflehit" + (1 + Math.floor(XorShift.random() * 4)) + ".wav";
      } else if (type === "grunt") {
        hitSoundFileName =
          "grunt-" + (1 + Math.floor(XorShift.random() * 4)) + ".mp3";
      } else if (type === "deathsound") {
        hitSoundFileName =
          "mobdeath" + (1 + Math.floor(XorShift.random() * 5)) + ".wav";
      } else if (type === "sigh") {
        hitSoundFileName = require("../../assets/sound/sigh.mp3");
      } else if (type === "flee") {
        hitSoundFileName = require("../../assets/sound/flee.mp3");
      }
      let hitSound = null;
      if (type === "sigh" || type === "flee") {
        // TODO Remove when sigh and flee are on CDN
        hitSound = new Audio(hitSoundFileName);
      } else {
        hitSound = new Audio(
          "https://cdn.dragoncrypto.io/sound/" + hitSoundFileName
        );
      }
      hitSound.volume = store.state.soundVolume;
      hitSound.play();
    };

    const combatButtonAction = (number) => {
      if (hasHotbarWeapon.value) {
        emit("useSlot", number);
      } else {
        emit("useFists");
      }
    };

    const onDeadMessage = () => {
      store.state.combatLog.push(
        store.state.highlight(
          "You have been slain by " + enemyName.value + ".",
          true
        ),
        "You can resurrect your hero at the Healer's Hut",
        "Switch characters or leave the battle to continue."
      );
    };

    /**
     * Computed Methods
     */
    const selectedPet = computed(() => {
      return store.state.pets.selectedPet;
    });
    const enemyAttackingClass = computed(() => {
      if (store.state.combat.isEnemyAttacking) {
        if (store.state.combat.weaponTypeEnemy === Constants.weaponType.magic) {
          return "magic-hit";
        } else if (
          store.state.combat.weaponTypeEnemy === Constants.weaponType.blade ||
          store.state.combat.weaponTypeEnemy === Constants.weaponType.poleArm
        ) {
          return "blade-hit take-damage";
        } else if (
          store.state.combat.weaponTypeEnemy === Constants.weaponType.heavy
        ) {
          return "heavy-hit take-damage";
        }
        return "critical-hit take-damage";
      } else {
        return "";
      }
    });
    const abilityAttackingClass = computed(() => {
      if (
        store.state.combat.isHeroAttacking &&
        store.state.combat.isAbilityAttack
      ) {
        return "ability-hit";
      }
      return "";
    });
    const isTurnHappening = computed(() => {
      // return store.state.isTurnHappening;
      return (
        store.state.combat.isHeroAttacking ||
        store.state.combat.isEnemyAttacking ||
        store.state.combat.isPetAttacking
      );
    });
    const waitingForTurn = computed(() => {
      return store.state.isTurnHappening;
    });
    const healthPercentage = computed(() => {
      return (enemyStats.value.currentHp / enemyStats.value.totalHp) * 100;
    });
    const getHotbar = computed(() => {
      return store.state.hotbar();
    });
    const hasHotbarWeapon = computed(() => {
      if (store.state.hotbar().find((h) => h.type == Constants.slots.hand1)) {
        return true;
      }
      return false;
    });
    const getCharacter = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });
    const hasResurrectionElixir = computed(() => {
      return store.state
        .hotbar()
        .find(
          (h) => h.stats.action == Constants.consumableActions.resurrection
        );
    });
    const resurrectionElixirSlot = computed(() => {
      return store.state
        .hotbar()
        .findIndex(
          (h) => h.stats.action == Constants.consumableActions.resurrection
        );
    });
    const getClass = computed(() => {
      return (
        "combat-wrapper main-combat wrap-content " +
        (store.state.gameState == Constants.gamemodes.combat
          ? "show"
          : "hide") +
        " " +
        store.state.timeOfDay
      );
    });
    const enemy = computed(() => {
      return store.state.currentEncounter;
    });
    const isEnemyDead = computed(() => {
      return store.state.currentEncounter.isDead;
    });
    const isHealing = computed(() => {
      return getCharacter.value.isProcessing;
    });
    const isHeroDead = computed(() => {
      return getCharacter.value.stats.isDead;
    });
    const enemyName = computed(() => {
      return store.state.currentEncounter.mob.name;
    });
    const enemyIntro = computed(() => {
      return store.state.currentEncounter.mob.intro;
    });
    const enemyWeapon = computed(() => {
      return store.state.currentEncounter.mob.weapon;
    });
    const enemySuffix = computed(() => {
      return store.state.currentEncounter.suffix;
    });
    const enemyStats = computed(() => {
      return store.state.currentEncounter.stats;
    });
    const combatLog = computed(() => {
      return store.state.combatLog;
    });
    const player = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });
    const combatOpenState = computed(() => {
      return store.state.gameState === Constants.gamemodes.combat;
    });
    const inCombat = computed(() => {
      return store.state.gameState === Constants.gamemodes.combat;
    });
    watch(
      combatLog,
      () => {
        setTimeout(() => {
          // doScroll();
        }, 30);
      },
      { deep: true }
    );

    return {
      // Data
      heroSection,
      enemySection,
      store,
      // Methods
      getSlotDesc,
      combatButtonAction,
      useResurrectionElixir,
      leavePlainsDead,
      lootEnemy,
      healersHutDead,
      // Computed
      enemyAttackingClass,
      isTurnHappening,
      waitingForTurn,
      healthPercentage,
      getHotbar,
      hasHotbarWeapon,
      isHealing,
      getCharacter,
      hasResurrectionElixir,
      resurrectionElixirSlot,
      getClass,
      enemy,
      isEnemyDead,
      inCombat,
      isHeroDead,
      enemyName,
      enemyIntro,
      enemyWeapon,
      enemySuffix,
      enemyStats,
      combatLog,
      player,
      combatOpenState,
      selectedPet,
      abilityAttackingClass,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.combat-wrapper {
  padding: 0 3rem;
  width: calc(100vw - 6rem) !important;
  z-index: 2 !important;
  &.day {
    background: url("https://cdn.dragoncrypto.io/map/battle-bg.webp") no-repeat
      center center;
    background-size: cover;
  }
  &.night {
    background: url("../../assets/map/battle-bg-night.webp") no-repeat center
      center;
    background-size: cover;
  }
  h2 {
    font-family: "IM Fell English", serif;
    text-transform: none;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6909138655462185) 20%,
      rgba(0, 0, 0, 0.8057598039215687) 50%,
      rgba(0, 0, 0, 0.6881127450980392) 80%,
      rgba(0, 0, 0, 0) 100%
    );
    padding: 5px 7rem;
    margin: 5px;
    color: $game-heading;
    font-size: 1.5rem;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    @media only screen and (max-width: 576px) {
      font-size: 1rem;
      width: 57%;
      top: 158px;
    }
  }
}
.combat-container {
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  grid-gap: 1rem;
  align-items: center;
  width: 90%;
  max-width: 1728px;
  margin: 0 auto;
  justify-items: center;
  top: 54%;
  position: absolute;
  transform: translateY(-50%);
  @media only screen and (max-width: 576px) {
    grid-template-columns: 100px 1fr 100px;
  }
}
.after-combat {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inner-dialog-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  .loading {
    display: grid;
    background: linear-gradient(
      90deg,
      #0000 0%,
      #00000080 5%,
      #00000080 50%,
      #00000080 95%,
      #0000 100%
    );
    width: 100%;
    height: 50px;
    position: absolute;
    z-index: 3;
    bottom: 0;
    text-align: center;
    align-items: center;
    span {
      color: #dc9c6a;
      z-index: 5;
      font-family: "IM Fell English", serif;
      font-size: 1.2rem;
    }
    .loading-inner {
      position: absolute;
      width: 0px;
      height: 3px;
      left: 0px;
      bottom: 0px;
      background: rgb(254, 167, 15);
      background: linear-gradient(
        -90deg,
        rgba(254, 167, 15, 1) 0%,
        rgba(255, 149, 12, 1) 36%,
        rgba(217, 142, 20, 1) 56%,
        rgba(195, 79, 6, 1) 80%,
        rgba(79, 21, 1, 1) 99%
      );
      opacity: 0.4;
      z-index: 4;
      animation: 2s infinite linear loadingAnimation;
    }
  }
}
@keyframes loadingAnimation {
  0% {
    left: 0px;
    width: 0px;
  }

  25% {
    left: 0px;
    width: 100px;
  }

  70% {
    left: 70%;
    width: 56px;
  }

  90% {
    left: 100%;
    width: 0px;
  }
}

.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;
}

.combat-log {
  min-height: 160px;
  height: 160px;
  overflow-y: auto;
  width: 430px;
  margin: 16px auto;
  padding: 8px;
  font-size: 90%;
  @media only screen and (max-width: 576px) {
    width: 90%;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }
}

.inner-content {
  position: relative;
}

.encounter-status {
  display: flex;
  align-items: center;
  justify-content: center;

  &.take-damage {
    animation: shake 0.55s;
  }

  .portrait {
    position: relative;

    .name {
      position: absolute;
      top: -28px;
      left: 0;
      width: 150px;
      text-align: left;
    }
  }

  img {
    max-height: 60px;
    border-radius: 100px;
    padding: 8px;
    background: url("https://cdn.dragoncrypto.io/uiassets/message-icon-bg.png")
      no-repeat center center;
    margin-right: 12px;
  }
}

.hp-wrapper {
  background: #333;
  border: 1px solid #000;
  border-radius: 3px;
  height: 22px;
  margin-top: 12px;
  position: relative;
  width: 232px;
}

.hp-bar {
  position: absolute;
  border-radius: 3px;
  background: rgb(186, 1, 1);
  background: linear-gradient(
    180deg,
    rgba(186, 1, 1, 1) 0%,
    rgba(225, 0, 0, 1) 5%,
    rgba(255, 60, 60, 1) 20%,
    rgba(223, 0, 0, 1) 39%,
    rgba(171, 0, 0, 1) 68%,
    rgba(148, 1, 1, 1) 92%,
    rgba(186, 1, 1, 1) 100%
  );
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: width 0.35s ease-out;
  animation: opacityChange 3s infinite;
}

.hp {
  text-shadow: 0 0 2px #000, 0 1px 1px #000, 0 1px 1px #000;
  padding: 3px 3px 4px 3px;
  margin: 0;
  position: relative;
  z-index: 1;
  font-size: 80%;
}

.hide .buttons.show-buttons {
  pointer-events: none;
}
.combat-controls {
  position: absolute;
  width: 45%;
  @media only screen and (min-width: 1600px) {
    top: 25%;
  }
  top: 18%;
}
.hide-messages {
  display: none;
}
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &.show-buttons {
    opacity: 1;
    pointer-events: all;

    transition: opacity 0.15s linear;
  }

  &.hide-buttons {
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.15s linear;
  }
}

p {
  font-size: 90%;
}

$animateDelay: 0.5s;
.main-combat {
  position: relative;
  &.magic-hit {
    animation: magicHit 1s linear, shake 0.55s;
    animation-delay: $animateDelay;
  }
  &.critical-hit {
    &:before {
      content: "";
      background: url("https://cdn.dragoncrypto.io/uiassets/blood-splatter.png")
        center center no-repeat;
      background-color: rgba(255, 0, 0, 0.419);
      mix-blend-mode: darken;
      background-size: 30%;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      animation: bloodSplatter 1.5s ease-in-out;
      animation-delay: $animateDelay;
      @media only screen and (max-width: 576px) {
        background-size: 80%;
      }
    }
  }
  &.blade-hit {
    &:before {
      content: "";
      background: url("../../assets/ui/slash.webp") center center no-repeat;
      background-color: rgba(255, 0, 0, 0.419);
      /* mix-blend-mode: darken; */
      background-size: 30%;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      animation: bloodSplatter 1.5s ease-in-out;
      animation-delay: $animateDelay;
      @media only screen and (max-width: 576px) {
        background-size: 80%;
      }
    }
  }
  &.heavy-hit {
    &:before,
    &:after {
      content: "";
      background: url("../../assets/ui/slash.webp") center center no-repeat;
      background-color: rgba(255, 0, 0, 0.419);
      mix-blend-mode: darken;
      background-size: 30%;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      animation: bloodSplatter 1.5s ease-in-out;
      animation-delay: $animateDelay;
      z-index: 1;
      @media only screen and (max-width: 576px) {
        background-size: 80%;
      }
    }
    &:after {
      background: url("https://cdn.dragoncrypto.io/uiassets/blood-splatter.png")
        center center no-repeat;
      background-size: 50%;
      mix-blend-mode: soft-light;
      animation: bloodSplatter 1.7s ease-in-out;
      animation-delay: $animateDelay;
      z-index: 0;
      @media only screen and (max-width: 576px) {
        background-size: 80%;
      }
    }
  }
  &.ability-hit {
    animation: shakeCombat 0.55s;
    &:before,
    &:after {
      content: "";
      /* background: url("../../assets/ui/energy-blast-purple.png") center center
        no-repeat; */
      background-color: rgb(89 39 189 / 42%);
      mix-blend-mode: darken;
      background-size: 30%;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      animation: bloodSplatter 1.5s ease-in-out;
      animation-delay: $animateDelay;
      z-index: 1;
      @media only screen and (max-width: 576px) {
        background-size: 80%;
      }
    }
    &:after {
      background: url("../../assets/ui/lightening.png") right -35px no-repeat;
      background-size: contain;
      mix-blend-mode: normal;
      animation: bloodSplatter 1.7s ease-in-out;
      animation-delay: $animateDelay;
      z-index: 0;
      @media only screen and (max-width: 576px) {
        background-size: contain;
      }
    }
  }
  &.take-damage {
    animation: shake 0.55s;
    animation-delay: $animateDelay;
  }
}

@keyframes opacityChange {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.85;
  }
}
@keyframes bloodSplatter {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  80% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes magicHit {
  0%,
  100% {
    box-shadow: none;
  }
  50% {
    box-shadow: inset 0 0 37rem 5rem #dd0977;
  }
}
@keyframes shakeCombat {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-2deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(3deg);
  }
  30% {
    transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-2deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(4deg);
  }
  90% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
</style>
