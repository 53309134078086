<template>
  <div :class="getClass">
    <div class="blood">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#a00000"
          fill-opacity="1"
          d="M0,128L17.1,144C34.3,160,69,192,103,170.7C137.1,149,171,75,206,69.3C240,64,274,128,309,160C342.9,192,377,192,411,181.3C445.7,171,480,149,514,165.3C548.6,181,583,235,617,240C651.4,245,686,203,720,197.3C754.3,192,789,224,823,229.3C857.1,235,891,213,926,218.7C960,224,994,256,1029,272C1062.9,288,1097,288,1131,282.7C1165.7,277,1200,267,1234,224C1268.6,181,1303,107,1337,85.3C1371.4,64,1406,96,1423,112L1440,128L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"
        ></path>
      </svg>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";

export default {
  name: "Death",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    getClass() {
      if (
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          null ||
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          "undefined" ||
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          undefined
      ) {
        return "death-wrapper hide";
      }
      return (
        "death-wrapper " +
        (this.$store.state.characters[this.$store.state.currentCharacter].stats
          .isDead
          ? "show"
          : "hide")
      );
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;
}

.death-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
  .blood {
    display: block;
    width: 100%;
    background-size: contain;
    height: auto;
    opacity: 0.4;
    position: absolute;
    top: -100%;
    pointer-events: none;
  }
  &.show {
    .blood {
      top: 0;
      right: 0;
      animation: slideDown 20s linear;
      svg {
        transform: rotate(180deg);
      }
    }
  }
  &::before {
    top: 0;
    left: 0;
    backdrop-filter: grayscale(90%);
    content: "";
    width: 100vw;
    height: 100vh;
    position: absolute;
  }
}
@keyframes slideDown {
  0% {
    opacity: 1;
    transform: translateY(-77%);
    left: 0;
    right: auto;
  }

  100% {
    transform: translateY(0);
    right: 0;
    left: auto;
  }
}
</style>
