import Constants from "../../consts/constants";
import apiConnector from "../../game/apiConnector";

// initial state
const state = () => ({
  stashOpenStatus: false,
  stash: [],
  selectedEquipments: [],
  selectedEquipmentBreakdown: [],
  selectedConsumables: [],
  selectedConsumablesQuantities: [],
  selectedResources: [],
  selectedResourcesQuantities: [],
  isLoadingStash: true,
  filterType: Constants.filterTypes.equipments,
  hasEquipmentPendingClaim: false,
  hasConsumablesPendingClaim: false,
  hasResourcesPendingClaim: false,
  hasDcauPendingClaim: false,
  hasDcarPendingClaim: false,
  confirmationDisplay: false,
});

// // getters
// const getters = {};

// actions
const actions = {
  async openStashWindow({ commit, rootState }) {
    commit("setStashWindow", rootState, { root: true });
    commit("setStashOpenStatusState", true);
  },
  async updateStash({ commit, state, rootState }) {
    if (state.stash.length === 0) {
      commit("setIsLoadingStash", true);
    }

    const result = await apiConnector.callGetStash(
      rootState.account,
      localStorage.getItem("sessionId")
    );
    // console.log(result.stash);
    commit("setStash", result.stash);
    commit("setIsLoadingStash", false);
  },
  /**
   * Toggle the selected status of an item and fill respective selected stash category
   */
  toggleItemBreakdown({ commit, state }, item) {
    let equipmentsSelected = state.selectedEquipmentBreakdown;
    if (equipmentsSelected.includes(item.id)) {
      // If item is getting deselected
      equipmentsSelected = equipmentsSelected.filter((i) => {
        return i !== item.id;
      });
    } else {
      if (equipmentsSelected.length < 50) {
        equipmentsSelected.push(item.id);
      }
    }
    commit("setSelectedEquipmentBreakdown", equipmentsSelected);
  },
  /**
   * Toggle the selected status of an item and fill respective selected stash category
   */
  toggleItem({ commit, state }, item) {
    switch (state.filterType) {
      case Constants.filterTypes.equipments: {
        let equipmentsSelected = state.selectedEquipments;
        if (equipmentsSelected.includes(item.id)) {
          // If item is getting deselected
          equipmentsSelected = equipmentsSelected.filter((i) => {
            return i !== item.id;
          });
        } else {
          if (equipmentsSelected.length < 10) {
            equipmentsSelected.push(item.id);
          }
        }
        commit("setSelectedEquipments", equipmentsSelected);
        break;
      }
      case Constants.filterTypes.consumables: {
        let consumablesSelected = state.selectedConsumables;
        let consumablesQuantities = state.selectedConsumablesQuantities;
        if (consumablesSelected.includes(item.id)) {
          // If item is getting deselected
          const indexOfId = consumablesSelected.indexOf(item.id);
          consumablesSelected = consumablesSelected.filter((i) => {
            return i !== item.id;
          });
          consumablesQuantities = consumablesQuantities.filter(
            (quantity, index) => {
              return index !== indexOfId;
            }
          );
        } else {
          // If item is getting selected
          if (consumablesSelected.length < 10) {
            // By default adding the total quantity in selected quantity
            // example if an item has 10 quantity in stash, the selected quantity will be 10
            consumablesSelected.push(item.id);
            consumablesQuantities.push(item.quantity);
          }
        }
        commit("setSelectedConsumables", consumablesSelected);
        commit("setSelectedConsumablesQuantities", consumablesQuantities);

        break;
      }
      case Constants.filterTypes.resources: {
        let resourcesSelected = state.selectedResources;
        let resourcesQuantities = state.selectedResourcesQuantities;
        if (resourcesSelected.includes(item.id)) {
          // If item is getting deselected
          const indexOfId = resourcesSelected.indexOf(item.id);
          resourcesSelected = resourcesSelected.filter((i) => {
            return i !== item.id;
          });
          resourcesQuantities = resourcesQuantities.filter(
            (quantity, index) => {
              return index !== indexOfId;
            }
          );
        } else {
          // If item is getting selected
          if (resourcesSelected.length < 10) {
            // By default adding the total quantity in selected quantity
            // example if an item has 10 quantity in stash, the selected quantity will be 10
            resourcesSelected.push(item.id);
            resourcesQuantities.push(item.quantity);
          }
        }
        commit("setSelectedResources", resourcesSelected);
        commit("setSelectedResourcesQuantities", resourcesQuantities);
        break;
      }
      default:
        break;
    }
  },
  /**
   * Reset all selected states in stash
   */
  resetItems({ commit }) {
    commit("setSelectedConsumables", []);
    commit("setSelectedConsumablesQuantities", []);
    commit("setSelectedEquipments", []);
    commit("setSelectedEquipmentBreakdown", []);
    commit("setSelectedResources", []);
    commit("setSelectedResourcesQuantities", []);
  },
};

// mutations
const mutations = {
  setIsLoadingStash(state, status) {
    state.isLoadingStash = status;
  },
  setStashOpenStatusState(state, status) {
    state.stashOpenStatus = status;
  },
  setStash(state, items) {
    state.stash = items;
  },
  setSelectedEquipments(state, items) {
    state.selectedEquipments = items;
  },
  setSelectedEquipmentBreakdown(state, items) {
    state.selectedEquipmentBreakdown = items;
  },
  setSelectedConsumables(state, items) {
    state.selectedConsumables = items;
  },
  setSelectedResources(state, items) {
    state.selectedResources = items;
  },
  setSelectedResourcesQuantities(state, quantities) {
    state.selectedResourcesQuantities = quantities;
  },
  setSelectedConsumablesQuantities(state, quantities) {
    state.selectedConsumablesQuantities = quantities;
  },
  setFilterType(state, filterType) {
    state.filterType = filterType;
  },
  setHasEquipmentPendingClaim(state, status) {
    state.hasEquipmentPendingClaim = status;
  },
  setHasConsumablesPendingClaim(state, status) {
    console.log("setting pending");
    state.hasConsumablesPendingClaim = status;
    console.log(state.hasConsumablesPendingClaim);
  },
  setHasResourcesPendingClaim(state, status) {
    state.hasResourcesPendingClaim = status;
  },
  setHasDcauPendingClaim(state, status) {
    state.hasDcauPendingClaim = status;
  },
  setHasDcarPendingClaim(state, status) {
    state.hasDcarPendingClaim = status;
  },
  setRejectedClaim(state, payload) {
    const { account, sessionId, blockNumber, type } = payload;
    apiConnector
      .callRejectedClaim(account, sessionId, blockNumber, type)
      .then((result) => {
        if (result.success) {
          switch (type) {
            case Constants.filterTypes.equipments:
              state.hasEquipmentPendingClaim = false;
              break;
            case Constants.filterTypes.consumables:
              state.hasConsumablesPendingClaim = false;
              break;
            case Constants.filterTypes.resources:
              state.hasResourcesPendingClaim = false;
              break;
            default:
              console.log("Invalid claim type");
              break;
          }
        }
      })
      .catch(function (e) {
        console.log(e);
        return e;
      });
  },
  /**
   * @param {state} state
   * @param {id,quantity} item
   */
  setStashResourcesSelectedQuantity(state, item) {
    /**
     * This method sets the quantity in the quantity array
     * for the current id. Example: ids: 1,2,3 and quantities: 5,3,6
     * If item.id = 2, it will replace the quantity 3 with the new quantity
     */
    const indexOfId = state.selectedResources.indexOf(item.id);
    state.selectedResourcesQuantities[indexOfId] = item.quantity;
  },
  /**
   * @param {state} state
   * @param {id,quantity} item
   */
  setStashConsumablesSelectedQuantity(state, item) {
    /**
     * This method sets the quantity in the quantity array
     * for the current id. Example: ids: 1,2,3 and quantities: 5,3,6
     * If item.id = 2, it will replace the quantity 3 with the new quantity
     */
    const indexOfId = state.selectedConsumables.indexOf(item.id);
    state.selectedConsumablesQuantities[indexOfId] = item.quantity;
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  actions,
  mutations,
};
