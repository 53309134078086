<template>
  <div class="character-wrapper main">
    <div class="character" id="character">
      <BlueButton
        @click="popupActive = !popupActive"
        class="buy-hero-button"
        size="extra-small"
        >Get Your Hero</BlueButton
      >
    </div>
    <BasicPopup class="buy-popup" header="Get Your Own Hero" v-if="popupActive">
      <div
        class="close-button button-small"
        @click="popupActive = !popupActive"
      >
        <span>x</span>
      </div>
      <div class="inner-wrapper">
        <p>
          Get your own hero by purchasing from a vast collection of different
          heroes from the partner marketplaces below
        </p>
        <div class="inner-grid">
          <div class="joepegs">
            <a
              href="https://joepegs.com/collections/0x7a420aeff902aaa2c85a190d7b91ce8beffffe14"
              target="_blank"
              ><img
                src="@/assets/nft-marketplaces/joepegs.png"
                alt="Joepegs"
              />Joepegs</a
            >
          </div>
          <div class="nftrade">
            <a
              href="https://nftrade.com/assets/avalanche/0x7a420aeff902aaa2c85a190d7b91ce8beffffe14"
              target="_blank"
              ><img
                src="@/assets/nft-marketplaces/nftrade.png"
                alt="NFTrade"
              />NFTrade</a
            >
          </div>
          <div class="opensea">
            <a
              href="https://opensea.io/collection/dragon-crypto-hero"
              target="_blank"
              ><img
                src="@/assets/nft-marketplaces/opensea.svg"
                alt="OpenSea"
              />OpenSea</a
            >
          </div>
        </div>
      </div>
    </BasicPopup>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { useMixpanel } from "../../composables/mixpanel";
import BlueButton from "../Buttons/BlueButton.vue";
import BasicPopup from "../Popup/BasicPopup.vue";

export default {
  name: "BuyCharacter",
  components: {
    BlueButton,
    BasicPopup,
  },
  props: {},
  setup() {
    const store = useStore();
    const popupActive = ref(false);

    // Mixpanel Tracking
    const { trackEvent } = useMixpanel();

    const currentCharacters = computed(() => {
      return store.state.characters;
    });

    const currentCharacter = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });

    const toggleCharacterSelect = () => {
      store.commit("characterSelect/openSelectionScreen", true);

      // Mixpanel tracking when character select is clicked and char status
      try {
        trackEvent("Character Select", {
          state: store.state.gameState,
          hero: store.state.characters[store.state.currentCharacter].number,
          hp: store.state.characters[store.state.currentCharacter].stats.hp,
          turns:
            store.state.characters[store.state.currentCharacter].stats.turns,
        });
      } catch (e) {
        console.log(e);
      }
    };

    return {
      currentCharacters,
      currentCharacter,
      popupActive,
      toggleCharacterSelect,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.character {
  position: relative;
  width: 294px;
  height: 86px;
  background: url("https://cdn.dragoncrypto.io/uiassets/selected-player.png")
    no-repeat top left;
  .buy-hero-button {
    position: absolute;
    top: 32px;
    left: 99px;
  }
}
.character-wrapper {
  position: relative;
  &.main {
    margin-bottom: 12px;
  }
}
.buy-popup {
  width: calc(60% - $popup-border-size - $popup-border-size);
  /* height: calc(600px - $popup-border-size - $popup-border-size); */
  max-width: 600px;
  .inner-wrapper {
    width: calc(100% + $popup-border-offset + $popup-border-offset);
    left: -$popup-border-offset;
    position: relative;
    top: -35px;
    height: 100%;
    p {
      width: 70%;
      font-size: 1.2rem;
      margin: 3rem auto 4rem;
    }
  }
  .inner-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0;
    position: relative;
    a {
      text-align: center;
      img {
        display: block;
        width: 100px;
        height: 100px;
        margin: 0 auto 10px;
      }
    }
    .transactions-grid {
      text-align: center;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-gap: 1rem;
    }
  }
}

.close-button {
  position: absolute;
  top: -$popup-border-size;
  right: -$popup-border-size;
}
</style>
