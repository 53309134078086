<template>
  <div class="generic-loading">
    <span class="bounce1"></span>
    <span class="bounce2"></span>
  </div>
</template>

<script>
export default {
  name: "GenericLoading",
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.generic-loading {
  width: 100px;
  height: 100px;
  display: grid;
  align-items: center;
  justify-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  z-index: 10;
  .bounce1,
  .bounce2 {
    width: 50%;
    height: 50%;
    border-radius: 50%;
    background-color: #b98057;
    opacity: 0.6;
    position: absolute;
    animation: sk-bounce 2s infinite ease-in-out;
  }
  .bounce2 {
    animation-delay: -1s;
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
</style>
