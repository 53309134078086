<template>
  <div
    :class="getItemClass"
    @mouseenter="hovered"
    @mouseleave="unhovered"
    @dragstart="dragStart"
    @dragend="dragEnd"
    draggable="true"
    @dragover="dragOver"
    @dragenter="dragEnter"
    @dragleave="dragLeave"
    @drop="dragDrop"
  >
    <div class="damage-container" v-if="!isCurrency && isDamaged">
      <div class="repair-bar">
        <div class="percentage" :style="'width: ' + damagePercent + '%;'"></div>
      </div>
    </div>
    <img
      class="craft-possibility"
      v-if="!isCurrency && isCrafting && !isBlueprintSlot && !item"
      :src="getCraftResourceItemImage"
      :alt="getCraftResourceItemName"
      title="{{ getCraftResourceItemName }}"
    />

    <div
      :class="
        item.rarity.toLowerCase() +
        ' item-container' +
        (isCurrency ? ' currency' : '')
      "
      v-if="item && item.rarity"
    >
      <img
        :id="'equip-item' + (isCurrency ? tokenName : item.id)"
        :src="getItemImage"
        :alt="item.name"
      />

      <div v-if="item.quantity" class="quantity">
        {{ item.quantity }}
      </div>
    </div>
  </div>
</template>
<script>
// import tippy from 'tippy.js'
import { useStore } from "vuex";
import constants from "../consts/constants";

export default {
  name: "EquipmentTile",
  props: {
    item: {},
    isCurrency: Boolean,
    tokenName: String,
    types: String,
    isEquipmentSlot: Boolean,
    slotName: String,
    source: String,
    size: String,
    isMarketplace: Boolean,
    isLootChestItem: Boolean,
    isTwoHandedSlot: Boolean,
    isForge: Boolean,
    isCrafting: Boolean,
    isBlueprintSlot: Boolean,
    isCraftingGuild: Boolean,
    craftSlot: Object,
  },
  data() {
    return {
      isHidingItem: false,
      isDraggedOver: false,
      isCorrectDropTarget: false,
      $store: {},
    };
  },
  methods: {
    hovered(e) {
      if (this.isCraftingGuild) {
        return;
      }
      if (this.isCurrency) {
        return;
      }
      if (this.item)
        if (this.isLootChestItem) {
          this.$store.commit("setHovered", {
            item: this.item,
            event: e,
            isLootChestItem: this.isLootChestItem,
          });
        } else {
          this.$store.commit("setHovered", {
            item: this.item,
            event: e,
            isMarketItem: this.isMarketplace,
          });
        }
    },
    unhovered() {
      if (this.item) this.$store.commit("setUnhovered");
    },
    dragStart() {
      this.$store.commit("setTooltipUnhovered");
      this.$store.commit("setUnhovered");
      const currentStore = this.$store;
      setTimeout(() => {
        this.isHidingItem = true;
        currentStore.commit("setDragging", this.item);
        currentStore.commit("setDragSource", this.source);
        currentStore.commit("setDragFromSlot", this.slotName);
      }, 0);
    },
    dragEnd() {
      this.isHidingItem = false;
      this.$store.commit("setDragging", {});
      this.$store.commit("setDragSource", "");
      this.$store.commit("setDragFromSlot", "");
    },
    dragOver(e) {
      e.preventDefault();
    },
    dragEnter(e) {
      e.preventDefault();
      this.isDraggedOver = true;

      const dragItem = this.$store.state.draggingItem;
      const dragItemStats = dragItem.stats;

      const currentResources = this.$store.state.craftingSlots
        .filter((s) => s.currentItem)
        .map((s) => s.currentItem.id);

      if (
        this.isCrafting &&
        currentResources &&
        currentResources.length > 0 &&
        currentResources.indexOf(dragItem.id) >= 0
      ) {
        this.isCorrectDropTarget = false;
        return;
      }

      if (this.isBlueprintSlot && !dragItem.isBlueprint) {
        this.isCorrectDropTarget = false;
        return;
      }

      if (
        dragItem.type == constants.useable.resource &&
        this.isCrafting &&
        this.craftSlot &&
        (dragItem.baseTypeId == this.craftSlot.baseItemId ||
          dragItem.staticId == this.craftSlot.baseItemId)
      ) {
        this.isCorrectDropTarget = true;
        return;
      }

      if (dragItem.isBlueprint && this.isBlueprintSlot) {
        this.isCorrectDropTarget = true;
        return;
      }

      if (this.isForge) {
        if (
          this.$store.state.forgeItems.find(
            (f) => f != null && f.id == dragItem.id
          )
        ) {
          this.isCorrectDropTarget = false;
          return;
        }

        this.isCorrectDropTarget =
          dragItem.name.toLowerCase().indexOf("ore") >= 0 ||
          dragItem.name.toLowerCase().indexOf("coal") >= 0 ||
          dragItem.name.toLowerCase().endsWith(" bar") ||
          dragItem.name.toLowerCase().endsWith("wood");
        return;
      }

      if (dragItem.isTwoHanded) {
        this.isCorrectDropTarget = this.isTwoHandedSlot;
        return;
      }

      //Resources do not have a type, need to handle that here.
      if (this.types) {
        if (this.types.indexOf(dragItem.type) >= 0 && !dragItemStats.isBroken) {
          this.isCorrectDropTarget = true;
        } else {
          this.isCorrectDropTarget = false;
        }
      }
    },
    dragLeave() {
      this.isDraggedOver = false;
    },
    dragDrop() {
      if (this.isCorrectDropTarget && this.isBlueprintSlot) {
        if (this.$store.state.craftBlueprintItem) {
          this.$store.commit("setRemoveCraftingResources");
        }
        this.$store.commit("sendItemToCraft", this.$store.state.draggingItem);
      }

      if (this.isCorrectDropTarget && this.isCrafting) {
        this.$store.commit("sendResourceToCraft", {
          item: this.$store.state.draggingItem,
          slot: this.craftSlot,
        });
      }

      if (this.isCorrectDropTarget && this.isForge) {
        this.$store.commit("sendItemToForge", this.$store.state.draggingItem);
      }

      if (this.isCorrectDropTarget && this.isEquipmentSlot && !this.isDead) {
        this.$store.commit("equipItem", this.slotName);
      }

      this.isDraggedOver = false;
      this.isHidingItem = false;
    },
  },
  computed: {
    isDead() {
      return this.$store.state.characters[this.$store.state.currentCharacter]
        .stats.isDead;
    },
    getQuantity() {
      return null;
    },
    getItemClass() {
      return (
        "item" +
        (this.item ? " background" : "") +
        (this.isHidingItem ? " dragging" : "") +
        (this.isDraggedOver ? " drag-over" : "") +
        (this.isDraggedOver && !this.isCorrectDropTarget ? " invalid" : "") +
        (this.size ? " " + this.size : "") +
        (this.isHighlighted ? " highlighted" : "") +
        (this.isRepairing ? " notdamaged" : "")
      );
    },
    getCraftResourceItemImage() {
      if (!this.craftSlot) {
        return "";
      }

      return (
        "https://ik.imagekit.io/dcg/equip/" + this.craftSlot.image + "?tr=w-90"
      );
    },
    isDamaged() {
      return (
        this.item &&
        this.item.stats &&
        this.item.stats.totalDurability &&
        this.item.stats.durability < this.item.stats.totalDurability &&
        this.item.type != "blueprint"
      );
    },
    damagePercent() {
      if (this.isDamaged) {
        return Math.ceil(
          (this.item.stats.durability / this.item.stats.totalDurability) * 100
        );
      } else {
        return 100;
      }
    },
    isRepairing() {
      return (
        !this.isDamaged &&
        this.$store.state.gameState == constants.gamemodes.repair
      );
    },
    getCraftResourceItemName() {
      if (!this.craftSlot) {
        return "";
      }

      return this.craftSlot.name;
    },
    getItemImage() {
      if (this.isCurrency) {
        if (this.tokenName.toLowerCase().indexOf("dcau") >= 0) {
          return "https://cdn.dragoncrypto.io/uiassets/dcau.png";
        } else {
          return "https://cdn.dragoncrypto.io/uiassets/dcar.png";
        }
      }

      if (!this.item || !this.item.imageName) {
        return "";
      }
      if (this.item.isBlueprint) {
        return (
          "https://ik.imagekit.io/dcg/blueprints/" +
          this.item.imageName +
          "?tr=w-90"
        );
      }
      return (
        "https://ik.imagekit.io/dcg/equip/" + this.item.imageName + "?tr=w-90"
      );
    },
    isHighlighted() {
      if (this.isEquipmentSlot) {
        const dragItem = this.$store.state.draggingItem;

        if (dragItem) {
          const dragItemStats = dragItem.stats;

          return (
            this.types.indexOf(dragItem.type) >= 0 && !dragItemStats.isBroken
          );
        }
      }

      return false;
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.notdamaged {
  display: none;
}

.damage-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: red;
  z-index: 3;
}

.repair-bar {
  width: 100%;
  height: 4px;
  background: red;
  position: relative;
}

.percentage {
  background: #4caf50;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  transition: width 0.5s ease;
}

.item {
  width: 44px;
  height: 44px;
  @media only screen and (max-width: 576px) {
    width: 32px;
    height: 32px;
  }
  cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
    auto;
  position: relative;
  $borderWidth: 2px;

  &.large {
    width: 72px;
    height: 72px;
  }

  &.med {
    width: 56px;
    height: 56px;
  }
  &.small {
    width: 34px;
    height: 34px;
  }

  &.xs {
    width: 24px;
    height: 24px;
  }

  .craft-possibility {
    opacity: 0.35;
  }

  .item-container {
    width: 100%;
    height: 100%;
    border-radius: calc($borderWidth * 1.5);
    pointer-events: none;
    transition: all 50ms linear;

    &.currency {
      img {
        width: 70%;
        height: 70%;
        margin: 15%;
        background: #000;
      }
    }

    &::after {
      pointer-events: none;
      z-index: 1;
      position: absolute;
      content: "";
      top: calc(-1 * $borderWidth);
      left: calc(-1 * $borderWidth);
      width: calc(100% + $borderWidth * 2);
      height: calc(100% + $borderWidth * 2);
      background-size: 300% 300%;
      animation: moveGradient 3.5s alternate-reverse infinite;
      border-radius: calc($borderWidth * 1.5);
    }

    // .craft-possibility {
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   width: 100%;
    //   height: 100%;
    // }
  }

  .quantity {
    position: absolute;
    top: 0;
    right: 2px;
    width: 100%;
    text-align: right;
    height: 12px;
    color: $text-highlight;
    z-index: 20;
    font-size: 80%;
    font-weight: bold;
    text-shadow: 0 0 2px #000, 0 0 2px #000, 0 1px 3px #000;
  }

  &.drag-over {
    background: rgba(194, 153, 123, 0.5) !important;

    img {
      background: rgba(194, 153, 123, 0.8) !important;
    }

    &.invalid {
      background: rgba(200, 0, 0, 0.5) !important;

      img {
        background: rgba(200, 0, 0, 0.8) !important;
      }
    }
  }

  &.dragging {
    cursor: pointer;

    div {
      display: none;
    }
  }

  &.highlighted {
    box-shadow: 0 0 6px $legendary, 0 0 6px $legendary;
    transition: box-shadow 50ms linear;
  }

  &.background {
    background: rgba(0, 0, 0, 0.8);
  }

  .shoddy {
    border: 1px solid $shoddy;
  }

  .epic {
    &::after {
      background-image: linear-gradient(
        60deg,
        lighten($epic, 50%),
        $epic,
        darken($epic, 20%),
        $epic
      );
    }
  }

  .legendary {
    &::after {
      background-image: linear-gradient(
        60deg,
        lighten($legendary, 20%),
        $legendary,
        darken($legendary, 20%),
        $legendary
      );
    }
  }

  .mythical {
    &::after {
      background-image: linear-gradient(
        60deg,
        lighten($mythical, 20%),
        $mythical,
        darken($mythical, 20%),
        $mythical
      );
    }
  }

  .fabled {
    &::after {
      background-image: linear-gradient(
        60deg,
        lighten($fabled, 20%),
        $fabled,
        darken($fabled, 20%),
        $fabled
      );
    }
  }

  .unique {
    &::after {
      background-image: linear-gradient(
        60deg,
        lighten($unique, 20%),
        $unique,
        darken($unique, 20%),
        $unique
      );
    }
  }

  .rare {
    &::after {
      background-image: linear-gradient(
        60deg,
        lighten($rare, 30%),
        $rare,
        darken($rare, 30%),
        $rare
      );
    }
  }

  .artefact {
    &::after {
      background-image: linear-gradient(
        60deg,
        lighten($artefact, 50%),
        $artefact,
        darken($artefact, 30%),
        $artefact
      );
    }
  }

  .junk {
    border: 1px solid #000;
  }

  .uncommon {
    border: 1px solid $uncommon;
  }

  img {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 2;
    background: rgba(0, 0, 0, 0.8);
    pointer-events: none;
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 100%;
  }
}
</style>
