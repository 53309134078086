<template>
  <div class="quest-accepted">
    <div class="wrapper">
      <div class="header">
        <svg class="quest" viewBox="0 0 1320 300">
          <text x="50%" y="50%" dy=".35em" text-anchor="middle">Quest</text>
        </svg>
        <svg class="accepted" viewBox="0 0 1320 300">
          <text x="50%" y="50%" dy=".35em" text-anchor="middle">Accepted</text>
        </svg>
      </div>
      <div class="quest-details">
        <img :src="getQuestImage(quest)" :alt="quest.name" class="questImage" />
        <h3>{{ quest.name }}</h3>
        <DialogButton
          buttonSize="med"
          @click="$emit('toggleAccepted')"
          :buttonText="'Continue'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import Constants from "../../consts/constants";
import { useSound } from "../../composables/sound";
import acceptQuestSound from "../../assets/sound/accept-quest.mp3";
import DialogButton from "../../components/DialogButton.vue";

export default {
  name: "GuildRewards",
  components: {
    DialogButton,
  },
  emits: ["toggleAccepted"],
  props: {
    quest: {
      // small || extra-small || default large
      type: Object,
    },
  },
  setup() {
    const store = useStore();
    const reward = ref(null);
    const playOnAcceptQuest = useSound(acceptQuestSound, {
      volume: store.state.soundVolume,
      interrupt: true,
    });

    onMounted(() => {
      reward.value = store.state.guild.guildQuestRewards;
      playOnAcceptQuest.play();
    });

    const getQuestImage = (quest) => {
      if (quest.type === Constants.contractQuestTypes.defendersGuild) {
        if (quest.goalType === Constants.contractQuestsGoalTypes.monsterKill) {
          return "https://ik.imagekit.io/dcg/equip/Warriorskill_28_nobg.png?tr=w-150";
        } else if (
          quest.goalType ===
          Constants.contractQuestsGoalTypes.equipmentCollection
        ) {
          return "https://ik.imagekit.io/dcg/equip/Tailoring_44_little_bag.png?tr=w-150";
        } else if (
          quest.goalType === Constants.contractQuestsGoalTypes.raid ||
          quest.goalType === Constants.contractQuestsGoalTypes.bounty
        ) {
          return `https://cdn.dragoncrypto.io/encounters/${quest.image}?tr=w-150`;
        } else if (
          quest.goalType === Constants.contractQuestsGoalTypes.artefactRecovery
        ) {
          return `https://cdn.dragoncrypto.io/equip/${quest.image}?tr=w-150`;
        }
      } else if (quest.type === Constants.contractQuestTypes.craftersGuild) {
        return quest.image !== undefined
          ? `https://ik.imagekit.io/dcg/equip/${quest.image}?tr=w-150`
          : "https://ik.imagekit.io/dcg/equip/Tailoring_44_little_bag.png?tr=w-150";
      }
    };

    return {
      //methods
      getQuestImage,
      //data
      reward,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.quest-accepted {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000000ce;
  top: 0;
  left: 0;
  z-index: 20;
  backdrop-filter: blur(5px);
  .wrapper {
    width: 400px;
    height: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    .header {
      width: 100%;
      height: auto;
      position: absolute;
      animation: slideUp 0.5s cubic-bezier(0.22, 1, 0.36, 1) forwards;
      animation-delay: 2s;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      svg {
        font-weight: 100;
        /* font-family: "Cinzel", serif; */
        font-family: "IM Fell English", serif;

        text-transform: uppercase;
        position: relative;
        width: 100%;
        height: auto;
        left: 0;
        top: 0;
        &.accepted {
          text {
            animation: stroke 2s 1 forwards;
            animation-delay: 1s;
          }
        }
        text {
          text-transform: uppercase;
          animation: stroke 2s 1 forwards;
          stroke-width: 3;
          stroke: #c8bb2c;
          /* stroke: #e5c356; */
          font-size: 13rem;
          line-height: 1rem;
          opacity: 0;
        }
      }
    }
    .quest-details {
      text-align: center;
      animation: fadeIn 0.5s ease-in-out forwards;
      animation-delay: 2s;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      h3 {
        color: #fff;
      }
      .questImage {
        width: 200px;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideUp {
  from {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  to {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
}

@keyframes stroke {
  0% {
    fill: transparent;
    stroke: transparent;
    stroke-dashoffset: 330;
    stroke-dasharray: 330;
    stroke-width: 0;
    opacity: 1;
  }
  5% {
    fill: transparent;
    stroke: rgb(160, 142, 54);
    /* stroke: #e5c356; */
    stroke-dashoffset: 440;
    stroke-dasharray: 440;
    stroke-width: 1;
  }
  100% {
    fill: #e5c356;
    /* fill: rgb(204, 195, 72); */
    /* stroke: rgba(54, 95, 160, 0); */
    stroke: rgb(144, 125, 34);

    stroke-dashoffset: 0;
    stroke-dasharray: 440;
    stroke-width: 0;
    opacity: 1;
  }
}
</style>
