<template>
  <div :class="['tokens', barType]">
    <Popper
      arrow
      placement="top"
      :show="showAboutBonus"
      class="popper-container"
      content="You need to lock a certain amount of $DCAR to receive a 100% bonus in rewards. This displays the current percentage of the bonus you are receiving and the minimum amount of $DCAR you need to hold in order to reach the 100% bonus."
    >
      <div class="bonus-details-container">
        <div class="bonus-details">
          <div class="progress-bar-container">
            <ProgressBar :progress="bonusPercentage" />
          </div>
          <div
            class="details"
            @mouseenter="showAboutBonus = true"
            @mouseleave="showAboutBonus = false"
          >
            <div class="percentage">
              {{ bonusPercentage }}% Bonus<span class="desc"
                >Bonus Percentage</span
              >
            </div>

            <div class="tokens-required">
              <span class="dcar-amount">
                {{ Math.ceil(tokensRequired).toFixed(3) }}
              </span>
              <span class="desc">Required Tokens</span>
            </div>
          </div>
        </div>
      </div>
    </Popper>
  </div>
</template>

<script>
import { onUnmounted, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { usePrice } from "../../composables/price";
import ProgressBar from "../ProgressBar.vue";
import Popper from "vue3-popper";
export default {
  name: "TokensBar",
  components: {
    ProgressBar,
    Popper,
  },
  props: {
    barType: {
      type: String,
    },
  },
  setup() {
    const store = useStore();
    const showAboutBonus = ref(false);
    const dcauAmount = ref(0.0);
    const dcarAmount = ref(0.0);
    const {
      getDCAUBalance,
      getDCARBalance,
      getBonusDetails,
      bonusPercentage,
      tokensRequired,
    } = usePrice();

    let dcauUnWatcher = null;
    let dcarUnWatcher = null;
    onMounted(() => {
      dcauUnWatcher = store.watch(
        (state) => state.dcauInWallet,
        (value) => {
          dcauAmount.value = parseFloat(value).toFixed(3);
        }
      );

      dcarUnWatcher = store.watch(
        (state) => state.dcarInWallet,
        (value) => {
          dcarAmount.value = parseFloat(value).toFixed(3);
        }
      );

      dcauAmount.value = store.state.dcauInWallet;
      dcarAmount.value = store.state.dcarInWallet;
      getDCAUBalance();
      getDCARBalance();
      getBonusDetails();
    });

    onUnmounted(() => {
      dcauUnWatcher();
      dcarUnWatcher();
    });

    return {
      // data
      dcauAmount,
      dcarAmount,
      showAboutBonus,
      bonusPercentage,
      tokensRequired,
      // computed
      // methods
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.tokens :deep(.popper) {
  background: #2c2c2c;
  padding: 20px;
  border-radius: 5px;
  color: #fff;
  z-index: 20;
  font-size: 0.9rem;
  font-family: "Lato", sans-serif;
}

.tokens :deep(.popper #arrow::before) {
  background: #2c2c2c;
}

.tokens :deep(.popper:hover),
.tokens :deep(.popper:hover > #arrow::before) {
  background: #2c2c2c;
}
.tokens {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 6px;
  position: absolute;
  bottom: 46px;
  width: 85%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  &.full-width {
    position: relative;
    width: calc(100% - 24px);
    z-index: 2;
    transform: none;
    margin: 0 auto 1rem;
    bottom: auto;
    top: auto;
    left: auto;
    .popper-container {
      position: relative;
    }
    .bonus-details-container {
      position: relative;
      overflow: visible;
      width: calc(100% + 24px);
      left: -12px;
      height: auto;
      .bonus-details {
        position: relative;
        width: 100%;
        left: 0;
        height: auto;
        bottom: 0;
        .details {
          position: relative;
          transform: none;
          width: 100%;
          bottom: auto;
          left: 0;
        }
        .progress-bar-container {
          position: relative;
          top: 0;
          width: 100%;
          left: 0;
        }
      }
    }
  }
  .dcau-amount,
  .dcar-amount {
    display: block;
    &:before {
      margin: 0 5px 0 0;
      top: 10px;
      position: relative;
    }
  }
  .dcau-amount {
    &:before {
      content: "";
      display: inline-block;
      width: 30px;
      height: 30px;
      background: url("../../assets/ui/dcau.png") no-repeat;
      background-size: cover;
    }
  }
  .dcar-amount {
    &:before {
      content: "";
      display: inline-block;
      width: 30px;
      height: 30px;
      background: url("../../assets/ui/dcar.png") no-repeat;
      background-size: cover;
    }
  }
  .popper-container {
    width: 100%;
    position: absolute;
  }
  .bonus-details-container {
    position: absolute;
    width: calc(100% + 29px);
    height: 62px;
    overflow: hidden;
    left: -15px;
    .bonus-details {
      position: absolute;
      display: block;
      bottom: -3px;
      width: calc(100% + 8px);
      left: -4px;
      height: 11px;
      /* cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
            auto; */
      .progress-bar-container {
        left: -15px;
        position: absolute;
        width: calc(100% + 29px);
        top: -3px;
        z-index: 4;
      }
      .details {
        transition: all 0.2s ease-in-out;
        transform: translateY(65px);
        display: grid;
        width: calc(100% - 29px);
        opacity: 1;
        position: absolute;
        left: 14px;
        height: 51px;
        bottom: 15px;
        z-index: 1;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        background: #0c0c0c;
        box-shadow: inset 0 0 4px 1px #ffffff29;
        .desc {
          display: block;
          font-size: 0.6rem;
          text-transform: uppercase;
          margin-top: 5px;
          color: #ffffff82;
        }
        .dcar-amount {
          &:before {
            width: 18px;
            height: 18px;
            margin-top: -2px;
            position: relative;
            top: 3px;
          }
        }
      }
      &:hover {
        .details {
          transform: translateY(0);
        }
      }
    }
  }
}
</style>
