<template>
  <div :class="getClass">
    <div class="content">
      <div class="logo">
        <img
          src="https://cdn.dragoncrypto.io/assets/legend-of-aurum-draconis.png"
          class="ad"
          alt="The Legend of Aurum Draconis"
        />
      </div>
      <span class="version">Version: RC 1.1.0</span>
      <div class="preloader" v-if="this.currentLoaded !== this.toLoad">
        <div class="bar-bg" :style="{ width: getPercentage + '%' }"></div>
        <p>{{ currentLoaded }}/{{ toLoad }}</p>
      </div>
      <div class="enter" v-if="this.currentLoaded == this.toLoad">
        <template v-if="isConnected && isCorrectChain">
          <h3>
            Login with your Wallet
            <span>
              ( Connected as
              {{ account.substr(0, 4) }}...{{
                account.substr(account.length - 4, account.length)
              }}
              )
            </span>
          </h3>

          <BlueButton
            v-if="isLoggingIn"
            @click="autoLogin"
            :disabled="
              buttonText === 'Checking Session' || buttonText === 'Signing in'
            "
            :class="
              buttonText === 'Checking Session' || buttonText === 'Signing in'
                ? 'loading'
                : ''
            "
          >
            Click To Continue
          </BlueButton>
          <BlueButton
            v-else
            @click="login"
            :disabled="
              buttonText === 'Checking Session' || buttonText === 'Signing in'
            "
            :class="
              buttonText === 'Checking Session' || buttonText === 'Signing in'
                ? 'loading'
                : ''
            "
          >
            {{ buttonText }}
          </BlueButton>
        </template>
        <template v-else>
          <h3>Connect your Wallet</h3>
          <BlueButton v-if="isCorrectChain" @click="connect">
            Connect
          </BlueButton>
          <BlueButton
            @click="switchChain"
            v-else
            title="Switch to correct chain to continue"
          >
            {{ switchToText }}
          </BlueButton>
        </template>
      </div>
      <div class="hint">HINT: {{ getRandomHint }}</div>
      <footer>
        <img
          src="https://cdn.dragoncrypto.io/assets/dcg-logo-small.png"
          class="dcg-footer-logo"
          alt="Dragon Crypto Gaming"
        />
        <div
          class="connection-details"
          :class="isValidChain ? 'connected' : ' not-connected'"
        >
          <span class="chain-name"
            ><span class="pulsating-circle"></span>{{ getChainName() }}</span
          >
          <a
            target="_blank"
            :href="
              currentChainId === 43114
                ? `https://snowtrace.io/block/${currentBlock}`
                : `https://testnet.snowtrace.io/block/${currentBlock}`
            "
            title="Current Block Number"
            >{{ currentBlock }}</a
          >
        </div>
        <a
          class="discord-link"
          title="discord-link"
          target="_blank"
          href="https://discord.gg/jCZVYErzEG"
        >
          <svg
            fill="#000000"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24px"
            height="24px"
          >
            <path
              d="M19.952,5.672c-1.904-1.531-4.916-1.79-5.044-1.801c-0.201-0.017-0.392,0.097-0.474,0.281 c-0.006,0.012-0.072,0.163-0.145,0.398c1.259,0.212,2.806,0.64,4.206,1.509c0.224,0.139,0.293,0.434,0.154,0.659 c-0.09,0.146-0.247,0.226-0.407,0.226c-0.086,0-0.173-0.023-0.252-0.072C15.584,5.38,12.578,5.305,12,5.305S8.415,5.38,6.011,6.872 c-0.225,0.14-0.519,0.07-0.659-0.154c-0.14-0.225-0.07-0.519,0.154-0.659c1.4-0.868,2.946-1.297,4.206-1.509 c-0.074-0.236-0.14-0.386-0.145-0.398C9.484,3.968,9.294,3.852,9.092,3.872c-0.127,0.01-3.139,0.269-5.069,1.822 C3.015,6.625,1,12.073,1,16.783c0,0.083,0.022,0.165,0.063,0.237c1.391,2.443,5.185,3.083,6.05,3.111c0.005,0,0.01,0,0.015,0 c0.153,0,0.297-0.073,0.387-0.197l0.875-1.202c-2.359-0.61-3.564-1.645-3.634-1.706c-0.198-0.175-0.217-0.477-0.042-0.675 c0.175-0.198,0.476-0.217,0.674-0.043c0.029,0.026,2.248,1.909,6.612,1.909c4.372,0,6.591-1.891,6.613-1.91 c0.198-0.172,0.5-0.154,0.674,0.045c0.174,0.198,0.155,0.499-0.042,0.673c-0.07,0.062-1.275,1.096-3.634,1.706l0.875,1.202 c0.09,0.124,0.234,0.197,0.387,0.197c0.005,0,0.01,0,0.015,0c0.865-0.027,4.659-0.667,6.05-3.111 C22.978,16.947,23,16.866,23,16.783C23,12.073,20.985,6.625,19.952,5.672z M8.891,14.87c-0.924,0-1.674-0.857-1.674-1.913 s0.749-1.913,1.674-1.913s1.674,0.857,1.674,1.913S9.816,14.87,8.891,14.87z M15.109,14.87c-0.924,0-1.674-0.857-1.674-1.913 s0.749-1.913,1.674-1.913c0.924,0,1.674,0.857,1.674,1.913S16.033,14.87,15.109,14.87z"
            />
          </svg>
        </a>
      </footer>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import BlueButton from "../components/Buttons/BlueButton.vue";
import { useUser } from "../composables/user";
import Constants from "../consts/constants";
import apiConnector from "../game/apiConnector";
import characters from "../game/characters";

import Web3 from "web3";
import { useMixpanel } from "../composables/mixpanel";

export default {
  name: "LoginLoading",
  components: { BlueButton },
  props: {},
  computed: {},
  methods: {
    async isAccountConnected() {
      return this.web3.isConnected();
    },
  },
  setup() {
    // Config Variables
    // const $firebase = ref({});
    const store = ref(useStore());

    // Constants needed for template
    const toLoad = ref(0);
    const currentLoaded = ref(0);
    const loaded = ref([]);
    const timeout = ref(null);

    const account = ref("");
    const isConnected = ref(false);
    const web3 = ref(null);
    const isSigning = ref(false);
    const isCheckingSession = ref(true);
    const isLoggingIn = ref(false);
    const isValidChain = ref(true);
    const sessionDetails = ref({});

    const currentChainId = ref(0);
    const currentBlock = ref(0);
    const { activate, isCorrectChain } = useUser();

    const networks = {
      testnet: {
        chainId: `0x${Number(43113).toString(16)}`,
        chainName: "Avalanche Fuji Testnet",
        rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
        nativeCurrency: {
          name: "Avalanche",
          symbol: "AVAX",
          decimals: 18,
        },
        blockExplorerUrls: ["https://testnet.snowtrace.io"],
      },
      mainnet: {
        chainId: `0x${Number(43114).toString(16)}`,
        chainName: "Avalanche C-Chain",
        rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
        nativeCurrency: {
          name: "Avalanche",
          symbol: "AVAX",
          decimals: 18,
        },
        blockExplorerUrls: ["https://snowtrace.io"],
      },
      local: {
        chainId: `0x${Number(1337).toString(16)}`,
        chainName: "Localhost",
        nativeCurrency: {
          name: "ETH",
          symbol: "ETH",
          decimals: 18,
        },
        rpcUrl: "http://localhost:8545",
      },
    };
    const switchChain = async () => {
      console.log("Switching chain");
      let chainName = "testnet";
      if (Constants.chainId == 1337) {
        chainName = "local";
      } else if (Constants.chainId == 43114) {
        chainName = "mainnet";
      }
      try {
        if (!window.ethereum) throw new Error("No Wallet Present");
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: networks[chainName].chainId }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [{ ...networks[chainName] }],
            });
          } catch (addError) {
            // handle "add" error
          }
        }
        // handle other "switch" errors
      }
    };

    /**
     *  Mixpanel composable
     */
    const { trackIdentity } = useMixpanel();

    /**
     * @description - Checks if the code entered is correct
     */
    const checkCode = (e) => {
      console.log(e);
      // window.clearTimeout(timeout.value);

      // timeout.value = window.setTimeout(() => {
      //   const value = e.target.value;

      //   const codesRef = collection($firebase.db, "codes");
      //   const codeMatchQuery = query(
      //     codesRef,
      //     where("accessCode", "==", value)
      //   );

      //   getDocs(codeMatchQuery).then((r) => {
      //     r.forEach((i) => {
      //       $store.commit("setLogin", i.data());

      //       const walletRef = doc($firebase.db, "wallets", value);

      //       getDoc(walletRef).then((walletDocSnapshot) => {
      //         if (walletDocSnapshot.exists()) {
      //           $store.commit("setDcauInWallet", walletDocSnapshot.data().dcau);
      //         } else {
      //           setDoc(walletRef, {
      //             characterNumber:
      //               $store.state.characters[$store.state.character].number,
      //             dcau: 10,
      //           }).then();
      //         }
      //       });

      //       const playerDataDocRef = doc($firebase.db, "playerData", value);

      //       getDoc(playerDataDocRef).then((playerDocSnap) => {
      //         if (playerDocSnap.exists()) {
      //           $store.commit("setPlayerData", playerDocSnap.data());
      //           $store.commit("setCheckRefresh");
      //         } else {
      //           $store.commit("setSaving");
      //         }
      //       });
      //     });
      //   });
      // }, 200);
    };
    const buttonText = computed(() => {
      if (isSigning.value) {
        return "Signing in";
      }
      if (isLoggingIn.value) {
        return "Auto-logging in";
      }
      if (isCheckingSession.value) {
        return "Checking Session";
      }
      return "Sign in";
    });

    const switchToText = computed(() => {
      if (Constants.chainId == 43114) {
        return "Switch To Avalanche C-Chain";
      } else {
        return "Switch To Fuji C-Chain";
      }
    });

    /**
     * @description - Shows and hide wrapper based on loading game state
     */
    const getClass = computed(() => {
      const bgList = [
        "inn",
        "marketplace",
        "forge",
        "aurum-draconis",
        "dragons",
        "grohms-bank",
        "mines",
        "bank",
      ];
      return (
        `loading-wrapper ${
          bgList[Math.floor(Math.random() * bgList.length)]
        } ` +
        (useStore().state.gameState == Constants.gamemodes.loading
          ? "show"
          : "hide")
      );
    });

    const getRandomHint = computed(() => {
      const hintList = [
        "A pickaxe is needed to explore the mines",
        "You can regain your health by using the Healers Hut",
        "Out of turns? You can refresh your turns by resting at the Inn",
        "You can break down equipment for resources in the crafting menu",
        "You can take up to 5 heroes on your adventure",
        "You will need high crafting attribute skills to forge quality items",
        "You can use the chat function to talk to other players",
        "You can buy and sell your equipment in the game marketplace",
        "All staking and crypto needs can be looked after in the bank",
        "Bring your Tiny Dragon NFT along to fight and gain a power attack",
        "A blueprint will be needed to forge special materials",
        "Request a song from the Bard to see if it raises your spirits",
        "You can swap your heroes to fight a super strong enemy",
        "Try different combinations of wood, coal, ores and ingots to discover new recipes in the forge",
        "Swapping out the standard iron, leather or wood in a blueprint can result in all new items",
      ];
      return hintList[Math.floor(Math.random() * hintList.length)];
    });

    /**
     * @description - Gets the percentage of the loading bar
     */
    const getPercentage = computed(() => {
      return (currentLoaded.value / toLoad.value) * 100;
    });

    const callProcessLogin = async (signature) => {
      const response = await fetch(Constants.apiUrl + "processlogin", {
        method: "POST",
        body: JSON.stringify({ account: account.value, signature }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    };

    const callProcessAuth = async () => {
      const response = await fetch(Constants.apiUrl + "authLogin", {
        method: "POST",
        body: JSON.stringify({ address: account.value }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    };

    const callGetHeroes = async (sessionId) => {
      const response = await fetch(Constants.apiUrl + "heroesforuser", {
        method: "POST",
        body: JSON.stringify({ account: account.value, sessionId }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    };

    const callGetHeroStats = async (sessionId, heroIds) => {
      const response = await fetch(Constants.apiUrl + "statsforheroes", {
        method: "POST",
        body: JSON.stringify({ account: account.value, sessionId, heroIds }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    };

    const callGetResources = async (sessionId, heroId) => {
      const response = await fetch(Constants.apiUrl + "resources", {
        method: "POST",
        body: JSON.stringify({ account: account.value, sessionId, heroId }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    };

    const setHeroData = (heroData) => {
      let loadedCharacters = [];
      let index = 0;

      for (const heroIdxName in heroData) {
        if (Object.hasOwnProperty.call(heroData, heroIdxName)) {
          const heroDataItem = heroData[heroIdxName];
          let character = characters.generateCharacterFromData(
            index++,
            heroIdxName,
            heroDataItem
          );
          loadedCharacters.push(character);
        }
      }

      store.value.commit("setCharacters", loadedCharacters);
      store.value.commit("characterSelect/setAllCharacters", loadedCharacters);
      store.value.commit("setWandering");
      store.value.commit("characterSelect/setDefaultSelectedCharacters");
      store.value.dispatch("characterSelect/setActiveCharacters");

      return loadedCharacters;
    };

    const setupSession = async (sessionId, addressData) => {
      // console.log("setting up session", addressData);

      // Dispatch action to load intro. The auto auto deterimines when to load it.
      store.value.dispatch("intro/loadIntro");

      store.value.commit("setAccount", account.value);

      let loadedCharacters = [];
      if (addressData) {
        loadedCharacters = setHeroData(addressData.heroData);
        store.value.commit("setInventory", addressData.inventory);

        if (loadedCharacters.length > 0) {
          // Setting current character equipped, daily quests only if player owns a character
          store.value.commit(
            "setCurrentCharacterEquipped",
            addressData.equipment
          );

          store.value.commit("pets/setSelectedPet", addressData.pet);
          store.value.commit("setQuests", addressData.quests);

          const resources = await apiConnector.callGetStaticResourceList();

          store.value.commit("setStaticResourceList", resources);

          const dailyQuestStatus = await apiConnector.callGetDailyQuest(
            account.value,
            sessionId,
            store.value.state.characters[store.value.state.currentCharacter]
              .number
          );

          // const contractQuestsCraft = await apiConnector.callGetContractQuests(
          //   account.value,
          //   sessionId,
          //   store.value.state.characters[store.value.state.currentCharacter]
          //     .number,
          //   true
          // );

          // const contractQuestsWarriors =
          //   await apiConnector.callGetContractQuests(
          //     account.value,
          //     sessionId,
          //     store.value.state.characters[store.value.state.currentCharacter]
          //       .number,
          //     false
          //   );

          // console.log("crafters quests:", contractQuestsCraft);
          // console.log("defenders quests:", contractQuestsWarriors);

          if (dailyQuestStatus.hasNewQuest) {
            store.value.commit(
              "setDailyQuestStatus",
              dailyQuestStatus.newQuest
            );
          }

          const getResources = await callGetResources(
            sessionId,
            store.value.state.characters[store.value.state.currentCharacter]
              .number
          );

          if (getResources) {
            store.value.commit("setResources", getResources.resources);
          }
        }
      } else {
        const getHeroesResult = await callGetHeroes(sessionId);

        const getHeroStatsResult = await callGetHeroStats(
          sessionId,
          getHeroesResult.heroIds
        );

        loadedCharacters = setHeroData(getHeroStatsResult.heroData);

        const inventoryResult = await apiConnector.callGetInventory(
          account.value,
          sessionId
        );

        store.value.commit("setInventory", inventoryResult.inventory);

        if (store.value.state.characters.length > 0) {
          const equipmentResult = await apiConnector.callGetEquipment(
            account.value,
            sessionId,
            store.value.state.characters[store.value.state.currentCharacter]
              .number
          );

          store.value.commit(
            "setCurrentCharacterEquipped",
            equipmentResult.equipment
          );
        }

        const getResources = await callGetResources(
          sessionId,
          store.value.state.characters[store.value.state.currentCharacter]
            .number
        );

        if (getResources) {
          store.value.commit("setResources", getResources.resources);
        }
      }

      // Tracking basic Mixpanel Identity
      trackIdentity(account.value, {
        characters: loadedCharacters.length,
      });

      isSigning.value = false;
    };

    const login = async () => {
      isSigning.value = true;

      try {
        const getNonce = await callProcessAuth();

        if (getNonce.success) {
          let signature = await web3.value.eth.personal.sign(
            "Sign this message to verify ownership of your wallet and log in to dragoncrypto.io. Nonce: " +
              getNonce.data.nonce,
            account.value,
            ""
          );
          const result = await callProcessLogin(signature);

          localStorage.setItem("sessionId", result.sessionId);
          store.value.commit("setSessionExpiry", result.sessionExpiry);

          store.value.commit("setGameTime", result);

          await setupSession(result.sessionId, result);
        } else {
          console.log("error getting nonce");
          isSigning.value = false;
        }
      } catch (error) {
        console.log(error);
        isSigning.value = false;
      }
    };

    const checkWeb3 = async () => {
      if (window.ethereum) {
        web3.value = new Web3(window.ethereum);
        activate(window.ethereum, "any"); // Initialize the provider in ethers

        window.ethereum.on("accountsChanged", async () => {
          console.log("accounts changed");
          // const accounts = await window.ethereum.request({
          //   method: "eth_requestAccounts",
          // });
          // account.value = accounts.length > 0 ? accounts[0] : "";
          if (isConnected.value) window.location.reload();
          await checkConnection();
        });

        window.ethereum.on("chainChanged", (chainId) => {
          currentChainId.value = chainId;
          window.location.reload();
        });

        return true;
      } else if (window.web3) {
        web3.value = new Web3(window.web3.currentProvider);
        activate(window.web3.currentProvider, "any"); // Initialize the current provider in ethers
        return true;
      }

      return false;
    };

    const getChainName = () => {
      let chainName = "Avalanche Mainnet";
      if (currentChainId.value === 43114) {
        chainName = "Avalanche Mainnet";
        isValidChain.value = Constants.chainId == 43114;
      } else if (currentChainId.value === 43113) {
        chainName = "Avalanche FUJI C-Chain";
        isValidChain.value = Constants.chainId == 43113;
      } else if (currentChainId.value === 80001) {
        chainName = "Polygon Mumbai Testnet";
        isValidChain.value = false;
      } else if (
        currentChainId.value === 31337 ||
        currentChainId.value === 1337
      ) {
        chainName = "Local Chain";
        isValidChain.value = true;
      } else if (currentChainId.value === 0) {
        chainName = "Not Connected";
        isValidChain.value = false;
      } else {
        chainName = "Not Supported";
        isValidChain.value = false;
      }

      return chainName;
    };

    const connect = async () => {
      if (web3.value) {
        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          account.value = accounts[0];
        } catch (error) {
          console.log(error);
        }

        isConnected.value = true;
        await checkConnection();
      }
    };

    const checkConnection = async () => {
      if (web3.value) {
        const accounts = await web3.value.eth.getAccounts();

        store.value.commit("setSessionExpired", false);

        if (accounts.length > 0) {
          // User is already connect with the wallet
          account.value = accounts[0].toLowerCase();
          isConnected.value = true;
          //Finding if session already exists
          let sessionId = localStorage.getItem("sessionId");
          isCheckingSession.value = false;
          if (sessionId) {
            isCheckingSession.value = true;
            const response = await fetch(Constants.apiUrl + "sessiondetails", {
              method: "POST",
              body: JSON.stringify({ account: account.value, sessionId }),
              headers: {
                "Content-Type": "application/json",
              },
            });
            const result = await response.json();
            if (result.success) {
              isLoggingIn.value = true;
              /**
               * There was a method to setup session over here. Moved it to
               * auto login method below. Reason is to enfoce player to click atleast once to start the music
               */

              store.value.commit(
                "setSessionExpiry",
                result.session.session.loginExpiry
              );

              sessionDetails.value = result;
            } else {
              isCheckingSession.value = false;
            }
          }
        }
      }
    };

    const autoLogin = async () => {
      if (isCorrectChain.value) {
        store.value.commit("setGameTime", sessionDetails.value);
        let sessionId = localStorage.getItem("sessionId");
        let result = sessionDetails.value;
        store.value.commit("setTurnHappening", true);
        setupSession(sessionId, result).then(() => {
          const sessionDetails = result.session;
          if (sessionDetails.isInEncounter) {
            fetch(Constants.apiUrl + "getencounter", {
              method: "POST",
              body: JSON.stringify({ account: account.value, sessionId }),
              headers: {
                "Content-Type": "application/json",
              },
            }).then((response) => {
              response.json().then((result) => {
                store.value.commit(
                  "setCurrentCharacterByNumber",
                  result.encounter.heroId
                );
                store.value.commit("setLoadEncounter", result);
                store.value.commit("setTurnHappening", false);
              });
            });
          } else {
            store.value.commit("setTurnHappening", false);
          }
        });
      }
    };

    /**
     * @description - Performing music related stuff on mounted
     */
    onMounted(async () => {
      const isWeb3 = await checkWeb3();

      if (isWeb3) {
        currentChainId.value = await web3.value.eth.getChainId();
        currentBlock.value = await web3.value.eth.getBlockNumber();
        await checkConnection();
      }
    });

    // TODO: Remove window.ethereum.on listener events on unmounting

    return {
      // $firebase,
      checkCode,
      getClass,
      getPercentage,
      currentLoaded,
      toLoad,
      loaded,
      timeout,
      account,
      isConnected,
      web3,
      currentChainId,
      currentBlock,
      getChainName,
      connect,
      buttonText,
      isLoggingIn,
      autoLogin,
      login,
      getRandomHint,
      isValidChain,
      isCorrectChain,
      switchChain,
      switchToText,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  &:after {
    content: "";
    background: rgb(0, 0, 0);
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.5088410364145659) 0%,
      rgba(0, 0, 0, 0.6012780112044818) 27%,
      rgba(0, 0, 0, 0.6881127450980392) 46%,
      rgba(0, 0, 0, 0.9458158263305322) 100%
    );
    background-size: 400% 400%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: gradient 20s ease-in-out infinite;
  }
  &:before {
    content: "";
    background: rgb(0, 0, 0);
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.5088410364145659) 0%,
      rgba(0, 0, 0, 0.6012780112044818) 27%,
      rgba(0, 0, 0, 0.6881127450980392) 46%,
      rgba(0, 0, 0, 0.9458158263305322) 100%
    );
    background-size: 400% 400%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: gradient 15s ease-in-out infinite;
    opacity: 0.7;
  }
  &.marketplace {
    background: url("https://cdn.dragoncrypto.io/assets/marketplace.webp")
      no-repeat center center;
    background-size: cover;
    background-color: #000;
  }
  &.inn {
    background: url("https://cdn.dragoncrypto.io/assets/inn.webp") no-repeat
      center center;
    background-size: cover;
    background-color: #000;
  }
  &.forge {
    background: url("https://cdn.dragoncrypto.io/assets/forge.webp") no-repeat
      center center;
    background-size: cover;
    background-color: #000;
  }
  &.mines {
    background: url("https://cdn.dragoncrypto.io/assets/mines.webp") no-repeat
      center center;
    background-size: cover;
    background-color: #000;
  }
  &.grohms-bank {
    background: url("https://cdn.dragoncrypto.io/assets/grohms-bank.webp")
      no-repeat center center;
    background-size: cover;
    background-color: #000;
  }
  &.aurum-draconis {
    background: url("https://cdn.dragoncrypto.io/assets/aurum-draconis.webp")
      no-repeat center center;
    background-size: cover;
    background-color: #000;
  }
  &.dragons {
    background: url("https://cdn.dragoncrypto.io/assets/dragons.webp") no-repeat
      center center;
    background-size: cover;
    background-color: #000;
  }
  &.bank {
    background: url("https://cdn.dragoncrypto.io/assets/bank.webp") no-repeat
      center center;
    background-size: cover;
    background-color: #000;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;

  background: none !important;
  background-color: #000 !important;
  &:after,
  &:before {
    display: none;
    animation: none;
  }
}

.content {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  position: absolute;
  z-index: 12;
  align-content: center;
  @media only screen and (max-width: 576px) {
    width: 100%;
    margin: 0 auto;
  }
  .logo {
    img {
      width: 50%;
      max-width: 600px;
      @media only screen and (max-width: 576px) {
        width: 90%;
      }
    }
  }
  .hint {
    font-size: 1.2rem;
    padding: 6rem 0 0;
    width: 85%;
    text-align: center;
    display: block;
    margin: 0 auto;
    color: #ffffff96;
  }
  span.version {
    color: #e5c356;
    position: absolute;
    bottom: 60px;
    right: 20px;
  }
  .dcg-footer-logo {
    position: absolute;
    width: 200px;
    @media only screen and (max-width: 576px) {
      width: 100px;
    }
    bottom: 20px;
    left: 20px;
  }
  .discord-link {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 20px;
    left: 20px;
    svg {
      width: 30px;
      height: 30px;
      fill: rgba(239, 226, 73, 0.4);
    }
  }
  h3 {
    @media only screen and (max-width: 576px) {
      font-size: 1.2rem;
    }
    span {
      display: block;
      font-size: 1rem;
      text-transform: capitalize;
    }
  }
  .connection-details {
    position: absolute;
    right: 20px;
    bottom: 20px;
    border-radius: 4px;
    font-size: 0.8rem;
    display: grid;
    grid-template-columns: 1fr auto;
    &.connected {
      background-color: #1a781abd;
      border: 1px solid #32b932;
      .pulsating-circle {
        background-color: #0f0;
      }
    }
    &.not-connected {
      background-color: #781a1abd;
      border: 1px solid #b93232;
      .pulsating-circle {
        background-color: red;
      }
    }
    span,
    a {
      padding: 4px 6px;
      display: block;
      text-decoration: none;
      color: #fff;
      &.chain-name {
        border-right: 1px solid #ffffff52;
      }
      .pulsating-circle {
        border-radius: 100%;
        padding: 0;
        width: 8px;
        height: 8px;
        display: inline-block;
        margin: 0 5px 0 0;
        animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s
          infinite;
      }
    }
    a:hover {
      cursor: pointer;
    }
  }
}
@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

label {
  display: inline-block;
  line-height: 3vh;
  height: 3vh;
  margin: 12px 0 8px 0;
}

#access-code {
  width: 100%;
  height: 3vh;
  line-height: 3vh;
}

.button {
  display: block;
  width: 311px;
  margin: 1rem auto;
  line-height: 82px;
  color: #000;
  transition: transform 0.2s linear;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.2s linear;
  }
}

.preloader {
  margin: 0 auto;
  width: 40vw;
  height: 6vh;
  position: relative;
  margin-bottom: 12px;

  .bar-bg {
    border-radius: 10px;
    border: 2px solid #000;
    background: rgb(59, 27, 13);
    background: linear-gradient(
      180deg,
      rgba(59, 27, 13, 1) 0%,
      rgba(181, 139, 59, 1) 12%,
      rgba(255, 226, 61, 1) 31%,
      rgba(181, 139, 59, 1) 53%,
      rgba(59, 24, 10, 1) 92%,
      rgba(109, 83, 34, 1) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 0;
    transition: width 100ms ease-out;
  }

  p {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    color: #000;
    font-weight: bold;
    text-shadow: 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff;
    margin: 0;
    padding: 0;
    font-size: 120%;
    line-height: 6vh;
  }
}

.buttons {
  margin-top: -5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  div + div {
    margin-left: 12px;
  }
}

p {
  font-size: 90%;
}
</style>
