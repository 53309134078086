import { createStore } from "vuex";
import { useUser } from "../composables/user";
import Constants from "../consts/constants";
import apiConnector from "../game/apiConnector";
import Loot from "../game/loot";
import { getDCGConsumablesContract } from "../utils/getContract";
import avvynames from "./modules/avvynames";
import bank from "./modules/bank";
import characterSelect from "./modules/characterSelect";
import chat from "./modules/chat";
import combat from "./modules/combat";
import craft from "./modules/craft";
import guild from "./modules/guild";
import intro from "./modules/intro";
import selectedInventory from "./modules/inventory";
import lootChests from "./modules/lootChests";
import marketplace from "./modules/marketplace";
import notifications from "./modules/notifications";
import pets from "./modules/pets";
import rankings from "./modules/rankings";
import repair from "./modules/repair";
import stash from "./modules/stash";
/* eslint-disable */


export default createStore({
  state() {
    return {
      popupActive: false,
      isSessionActive: false,
      lastRefreshSeconds: Date.now() / 1000,
      secondsBetweenRefresh: 3600,
      healthPercentPerRefresh: 10,
      turnsPerRefresh: 1,
      unhoveredTimeout: 0,
      hoverTimeout: 0,
      currentSelectedQuest: 0,
      questFinishText: "",
      hasNotification: false,
      notificationText: "",
      marketBuyItemId: "",
      isCrafting: false,
      isDurability: false,
      craftBlueprintItem: null,
      amountToCraft: 1,
      dailyQuest: null,
      isDailyQuestVisible: false,
      craftingSlots: [],
      currentFoundQuest: null,
      isTurnHappening: false,
      isUsingConsumable: false,
      isFleeing: false,
      isMarketSelling: false,
      isRepairListing: false, 
      isCraftListing: false,
      forgeResult: null,
      craftResult: null,
      craftHasEnoughSkill: false,
      craftHasEnoughResources: false,
      isGettingCraftingSlots: false,
      isCraftingComplete: false,
      hasPlayerLeveledUp: false,
      leveledUpHeroStats: {},
      craftCost: 10,
      playSound: false,
      resources: [],
      sessionExpiry: 0,
      sessionExpired: false,
      soundVolume: 0.5,
      soundToPlay: "",
      startSaving: false,
      idTracker: 0,
      accessCode: "",
      account: "",
      marketFilter: "all",
      inventoryEquipFilter: "",
      gameState: Constants.gamemodes.loading,
      isHoveredMarketItem: false,
      isHoveredRepairItem: false,
      isHoveredCraftItem: false,
      isHoveredLootChestItem: false,
      prevState: "",
      mobSuffixes: ["Terrible", "Insufferable", "Evil", "Devious"],
      hoveredItem: null,
      currentBardSong: {},
      isItemHovered: false,
      isTooltipHovered: false,
      hoverStartLocation: { x: 0, y: 0 },
      combatLog: [],
      staticResourceList: [],
      currentSellPrice: 0,
      currentSellQuantity: 1,
      currentSellItem: {},
      currentEncounter: {
        loot: {},
        mob: {},
        suffix: "",
        stats: {},
        isTakingDamage: false,
        damageTaken: 0,
        isDead: false,
      },
      tokenPrize: 0,
      tokenPrizeName: "",
      currentEncounterId: "",
      timeOfDay: "day",
      currentMultichoice: { event: {}, hasChosen: false, hasWon: false },
      plainEventsDefs: [
        // {
        //   isQuestStart: true,
        //   description: "A Quest"
        // },
        {
          description: "You come a path leading in to a thick wood.",
          choice1: "Forge ahead",
          choice2: "Turn away now",
          success: Constants.eventWins.exp,
          fail: Constants.eventLoses.loseturn,
          successMessage:
            "You come across a inspiring shrine in the middle of the forest, you gain some experience",
          failMessage:
            "You get lost in the woods and it takes you hours to get back out, you lose a turn",
          chanceOfSuccess: 65,
          soundSuccess: Constants.sounds.discovery,
        },
        {
          description:
            "You come across a passed out drunk man, he's snoring loudly.",
          choice1: "Search him",
          choice2: "Leave him to sleep it off",
          success: Constants.eventWins.loot,
          fail: Constants.eventLoses.loseturn,
          successMessage:
            "You carefully search the old man, he stirs but doesn't wake up, phew",
          failMessage:
            "You start to search the old man, but he wakes up and yells at you for hours, you lose a turn",
          chanceOfSuccess: 30,
          soundSuccess: Constants.sounds.searching,
          soundFail: Constants.sounds.oldMan,
        },
        {
          description: "You find a strange looking chest lying in the grass.",
          choice1: "Search it",
          choice2: "Leave it alone",
          success: Constants.eventWins.loot,
          fail: Constants.eventLoses.losehp,
          successMessage:
            "You open the chest, a trap goes off but you manage to evade it, you find some loot!",
          failMessage:
            "Ouch! That thing was trapped and empty! You lose some health",
          chanceOfSuccess: 50,
          soundSuccess: Constants.sounds.openChest,
          soundFail: Constants.sounds.trappedChest,
        },
      ],
      mobDefs: [
        {
          name: "Wild Tiny Dragon",
          intro: "Aww, that looks cute",
          weapon: "cute little claws",
          death: "Feels bad man",
        },
        {
          name: "Shiller",
          intro: "A crazed person jumps out on to the path ahead of you",
          weapon: "an army of defective bots",
          death: '"SER...I...AM...PROFESSIONAL....SHILLERRRrrr"',
        },
        {
          name: "Normal Enemy",
          intro: "Oh look, it's a regular normal every day enemy!",
          weapon: "generic RPG weapon #1",
          death: "Defeated eh? A bit predictable",
        },
        {
          name: "Dark Elf",
          intro: "A fellow with pointed ears growls at you",
          weapon: "a very sharp dagger",
          death: "Phew, I'm glad that one's over!",
        },
        {
          name: "Lurker",
          intro: "Long-time lurker, even longer time griefing",
          weapon: "downvotes",
          death: "User....deleted",
        },
        {
          name: "Spammer",
          intro:
            "You are suddenly overwhelmed by thousands of unsolicited messages",
          weapon: "emails about things you don't need or want",
          death: "Call me spam filter!",
        },
        {
          name: "Scammer",
          intro: "A random individual messages you out of the blue",
          weapon: "sneaky tactics",
          death: "Now that's what I call blocked!",
        },
        {
          name: "Smelly Troll",
          intro: "You smell them before you spot them",
          weapon: "an overwhelming odour",
          death: "You gag as you get close to search for loot",
        },
        {
          name: "YouTube Crypto Trader",
          intro: "Obnoxious viral thumbnails appear in front of you",
          weapon: "1000 shitcoins you can retire off in 2025",
          death: '"This is...not...financial....advice..."',
        },
        {
          name: "Ser Goblin",
          intro:
            "A small green man emerges from the bushes. Wait..this is no man! It's a goblin!",
          weapon: "a wooden club",
          death: "Another one bites the dust!",
        },
        {
          name: "Paperhands",
          intro:
            "Paperhands, realising the token he just dumped has pumped, runs towards you enraged",
          weapon: "sell orders for 1c in profits",
          death: "Your days of dumping are over, punk!",
        },
        {
          name: "Enraged Ape",
          intro: "An Enraged Ape spots you from across the plains",
          weapon: "the same questions you hear on every DeFI chat",
          death: "I hope you see some better days, ser",
        },
        {
          name: "Fudder",
          intro: "You feel a sudden sense of fear, uncertainty and doubt",
          weapon: "a very blunt stick",
          death: "OK but I tried to warn you!",
        },
        {
          name: "Rekt Degen",
          intro: "You smell mountain dew and doritos and hear a faint sobbing",
          weapon: "his bare hands",
          death: "Lost my life savings again!",
        },
        {
          name: "Confused Ape",
          intro: "You hear a string of random mutterings ahead",
          weapon: "questions answered 4 seconds ago",
          death: 'He utters in his last breath, "admin?? am I dead???!',
        },
        {
          name: "Vain CM",
          intro:
            "You asked a genuine question, but years of mouthbreathing have taken its toll",
          weapon: "The Ban Hammer",
          death: "Looks like you got the perma-ban this time!",
        },
        {
          name: "Presaler",
          intro: "Don't invest after this guy!",
          weapon: "10% of the token supply",
          death: '"You may have defeated me.. but.. I\'ve already won!"',
        },
        {
          name: "Hungry Villager",
          intro: "A skinny villager runs towards you",
          weapon: "a rock",
          death:
            'Their final words ring out "Pay for...funeral..for my village"',
        },
        {
          name: "Diamond Hands",
          intro:
            "You see a twinkling from the corner of your eyes, it's diamond hands!",
          weapon: "them Diamond Hands, baby",
          death: "Guess those diamond hands didn't come through this time, eh?",
          bonusAttack: 10,
          bonusDefense: 10,
        },
        {
          name: "Rugger",
          intro: "You get a sinking feeling, here comes Rugger",
          weapon: "a devious plan to steal your tokens",
          death: "Finally! Justice is served, and it felt so good",
          bonusDefense: 10,
        },
      ],
      songs: [
        {
          name: "Drunken Ballad",
          description:
            'The Bard stumbles on to the stage, "This onesh for you!". The song is terrible. The bard is clearly hammered.',
          effect: Constants.songEffects.none,
          effectDescription:
            "The song just doesn't do anything for you. You throw a tomato you found on the floor.",
          chance: 40,
        },
        {
          name: "Song of Victory",
          description:
            "The Bard approaches the stage and starts singing a fairly good song about victory in battles.",
          effect: Constants.songEffects.turn,
          effectDescription:
            "You feel a little more motivated today and receive an extra turn!",
          chance: 30,
        },
        {
          name: "The Battlefield Dominator",
          description:
            "The Bard strolls confidently to stage. Upon starting the song, the whole Inn joins in.",
          effect: Constants.songEffects.fullturns,
          effectDescription:
            "You are extremely motivated to get out and fight, you have much more energy for the battlefield!",
          chance: 20,
        },
        {
          name: "The Dragon's Flight",
          description:
            "The Bard rushes to the stage excited and starts singing and playing the lute, the whole Inn starts dancing and singing along.",
          effect: Constants.songEffects.experience,
          effectDescription:
            "The tale of this song leaves you reflecting on your battles, you gain some experience!",
          chance: 10,
        },
      ],
      getForgeCurrentResult: function () {
        const currentForgeIds = this.forgeItems
          .filter((f) => f != null)
          .map((f) => f.staticId);

        if (currentForgeIds.length == 0) {
          this.forgeResult = null;
        }

        this.forgeIds = this.forgeItems
          .filter((f) => f != null)
          .map((f) => f.id);

        this.forgeQuantities = this.forgeItems
          .filter((f) => f != null)
          .map((f) => f.quantity);

        apiConnector
          .callGetSmeltResult( 
            this.account,
            this.getSessionId(),
            this.characters[this.currentCharacter].number,
            Constants.apiURLs.forgeResult,
            currentForgeIds,
          )
          .then( (result) => {
            if (!result.forgeResult) {
              this.forgeResult = null;
            }

            if (result.success) {
              this.forgeResult = { item: result.forgeResult, quantity: result.forgeResult.quantity, notSkilledEnough: !result.hasRequiredCrafting }
            }
          })
          .catch(function (e) {
            console.log('forge error', e);
          })
      },
      getCraftCurrentResult: function () {

      },
      breakdownItem: function(itemIds) {
          this.isTurnHappening = true;
          let isStash = false;
          let type = null;
          apiConnector
            .breakdownItem(
              this.account,
              this.getSessionId(),
              this.characters[this.currentCharacter].number,
              Constants.apiURLs.breakdownItem,
              itemIds,
              isStash,
              type,
            )
            .then((result) => {
              this.isTurnHappening = false;
              this.hoveredItem = null;
              this.isItemHovered = false;
              this.isTooltipHovered = false;
              this.isHoveredMarketItem = false;
              this.isHoveredRepairItem = false;
              this.isHoveredCraftItem = false;
              this.isCrafting = false;

              this.doNotification(
                result.message
              );

              this.stash.stash = result.stash;
              this.inventory = result.inventory;
              this.characters[this.currentCharacter].equipped = result.equipment;
              this.soundToPlay = Constants.sounds.breakdown;
              this.playSound = true;

              // TODO: move this method to the component where it is being used at. Mutations are synchronous and meant for updating data only
              // Manually reseting selectedInventory submodule items cause we cannot call dispatch from mutation in vuex.
              this.selectedInventory.selectedInventoryEquipments = [];
            })
            .catch(function (e) {
              // Handle rejection instead of erroring plus log the exception, plus put it back to wandering state, need to add an error message here
              console.log("Call has failed, please try again", e);
              this.gameState = Constants.gamemodes.wandering;
              this.isTurnHappening = false;
              return e;
            });
          },
      removeCraftResources: function() {
        for (let ci = 0; ci < this.craftingSlots.length; ci++) {
          const craftingSlot = this.craftingSlots[ci];

          const item = craftingSlot.currentItem;

          if (item) {
            this.inventory.push(item);  
            craftingSlot.currentItem = null;
          }
        }
      },
      craftForgeItems: function (state) {
        const currentForgeIds = this.forgeItems
            .filter((f) => f != null && f.staticId )
            .map((f) => f.staticId);

        console.log("currentForgeIds", currentForgeIds);

        if (currentForgeIds.length == 0) {
          return null;
        }

        if (this.forgeResult) {
          //(account, sessionId, heroId, api, itemsForSmeltingIds
          apiConnector
              .smelt(
                  this.account,
                  state.getSessionId(),
                  state.characters[state.currentCharacter].number,
                  Constants.apiURLs.smelt,
                  currentForgeIds
              )
              .then((result) => {

                this.inventory = result.inventory;
                this.stash.stash = result.stash;
                this.soundToPlay = Constants.sounds.smelt;
                this.playSound = true;

                for (let f = 0; f < state.forgeItems.length; f++) {
                    state.forgeItems[f] = null;
                }

                state.characters[state.currentCharacter].stats = result.heroStats;

                // this.doNotification(
                //     result.smeltResult.message
                // );

              })
              .catch(function (e) {
                // Handle rejection instead of erroring plus log the exception, plus put it back to wandering state, need to add an error message here
                console.log("Call has failed, please try again", e);
                state.gameState = Constants.gamemodes.wandering;
                return e;
              });
        }

          /*let chanceOfFailingNumber =
            forgingItem.item.craftingRequired - craftingSkill;
          if (chanceOfFailingNumber < 0) {
            chanceOfFailingNumber = 0;
          }

          let quantity = this.getForgeCurrentResult().quantity;

          if (chanceOfFailingNumber > 0) {
            quantity = Math.floor(100 / chanceOfFailingNumber);
          }

          if (quantity > 0) {
            const resourceItem = Loot.createSpecificResource(
              this.getForgeCurrentResult().item.staticId,
              quantity
            );
            const attemptedQuantity = this.getForgeCurrentResult().quantity;

            for (let f = 0; f < this.forgeItems.length; f++) {
              const forgeItem = this.forgeItems[f];

              if (forgeItem) {
                forgeItem.quantity -= attemptedQuantity;

                if (forgeItem.quantity == 0) {
                  this.forgeItems[f] = null;
                  continue;
                }

                if (!this.inventory.find((i) => i.id == forgeItem.id)) {
                  this.inventory.push(forgeItem);
                } else {
                  const foundItems = this.inventory.find(
                    (i) => i.id == forgeItem.id
                  );
                  foundItems.quantity += forgeItem.quantity;
                }

                this.forgeItems[f] = null;
              }
            }

            if (!this.itemStats.find((i) => i.id == resourceItem.item.id)) {
              this.itemStats.push(resourceItem.itemStats);
            }

            if (!this.inventory.find((i) => i.id == resourceItem.item.id)) {
              this.inventory.push(resourceItem.item);
            } else {
              const foundItems = this.inventory.find(
                (i) => i.id == resourceItem.item.id
              );
              foundItems.quantity += quantity;
            }

            this.soundToPlay = Constants.sounds.smelt;
            this.playSound = true;

            if (attemptedQuantity > quantity) {
              this.doNotification(
                "You successfully smelt some of your ore and receive " +
                  quantity +
                  " " +
                  resourceItem.item.name +
                  (quantity > 1 ? "s" : "")
              );
            } else {
              this.doNotification(
                "You successfully smelt your ore and receive " +
                  quantity +
                  " " +
                  resourceItem.item.name +
                  (quantity > 1 ? "s" : "")
              );
            }
          } else {
            this.doNotification(
              "You attempt to smelt the ore, but are left with nothing useable."
            );
          }
        }
        */
      },
      startEncounter: function (result) {
        this.combatLog = [];

        this.currentEncounterId = result.encounterId;
        const encounterType = result.encounter.type;

        if (encounterType == "multichoice") {
          this.currentMultichoice.event = result.encounter;

          this.currentMultichoice.hasWon = false;
          this.currentMultichoice.hasChosen = false;

          this.prevState = this.gameState;
          this.gameState = Constants.gamemodes.multichoice;
        } else {
          this.prevState = this.gameState;
          this.gameState = Constants.gamemodes.combat;
          
          this.currentEncounter = result.encounter;

          // this.combatLog.push(this.currentEncounter.mob.intro);
          // this.combatLog.push(
          //   "You have encountered " + this.currentEncounter.mob.name
          // );

          if (result.enemyDamage) {
            // this.combatLog.push(
            //   this.highlight(
            //     "Because of " +
            //       this.currentEncounter.mob.name +
            //       "'s speed, they get first hit",
            //     true
            //   )
            // );

            this.isTurnHappening = true;

            let mobWeaponName = result.enemyDamage.weaponName;
            this.characters[this.currentCharacter].stats = result.heroStats;

            this.doCombatGetHitMessage(
              mobWeaponName,
              this.currentEncounter.mob.name,
              result.enemyDamage.hitLocation,
              result.enemyDamage.isCritical,
              result.enemyDamage.damage
            ).then(() => {
              this.isTurnHappening = false;
            });
          } else {
            // this.combatLog.push(
            //   this.highlight("Because of your speed, you get first hit")
            // );
          }

          
        }
      },
      resumeEncounter: function (result) {
        this.currentEncounterId = result.encounterId;
        const encounterType = result.encounter.type;

        if (encounterType == "multichoice") {
          this.currentMultichoice.event = result.encounter;

          this.prevState = this.gameState;
          this.gameState = Constants.gamemodes.multichoice;
        } else if (encounterType == "mining" || encounterType == "gathering") {
          this.currentGathering = result.encounter;
          this.charLocation = encounterType === 'mining' ? Constants.resourceLocations.mines : Constants.resourceLocations.deepwood

          this.currentEncounterId = result.encounter.id;
          this.miningEncounter = result.encounter;

          this.prevState = this.gameState;
          this.gameState = Constants.gamemodes.gatheringStart;

          this.currentGathering = {
            resource: this.miningEncounter.resource,
          };
        }
        else {
          this.currentEncounter = result.encounter;

          this.prevState = this.gameState;
          this.gameState = Constants.gamemodes.combat;
        }
      },
      hasCraftingResources: function (inputIds, resource) {
        if (
          resource.craftingResources &&
          inputIds.length === resource.craftingResources.length
        ) {
          return inputIds.every((element) => {
            if (resource.craftingResources.includes(element)) {
              return true;
            }

            return false;
          });
        }

        return false;
      },
      consumeItemCombat: async function (staticId) {
        if (this.isTurnHappening) {
          return;
        }

        // this.isTurnHappening = true;
        this.isUsingConsumable = true;

        const inventoryItem = this.inventory.find(
          (i) => i.staticId && i.staticId == staticId && i.type == "consumable"
        );

        let currentAccount = this.account;
        let sessionId = this.getSessionId();
        let number = this.characters[this.currentCharacter].number;

        let character = this.characters[this.currentCharacter];
        character.isProcessing = true;

        // Calling api to update transaction status to true asynchronously.
        // Not using await so that it doesn't stop/adds delay to the usual flow
        await apiConnector.callConsumeItemStatusUpdate(currentAccount, sessionId, number, true, inventoryItem.id)

        try{

          const { signer } = useUser();
          const ConsumablesContract = getDCGConsumablesContract(signer.value);
          const tx = await ConsumablesContract.burn(currentAccount, inventoryItem.id, 1);
          
          if (tx?.hash) {
            const txHash = tx.hash;
            await apiConnector.handleTransactionHash(
              currentAccount,
              sessionId,
              number,
              "healing",
              txHash
            );
          }
          
          const receipt = await tx.wait();
          if (receipt.status == 1 && receipt.to.toLowerCase() == ConsumablesContract.address.toLowerCase()) {
            apiConnector
              .callConsumeItemCombat(
                currentAccount,
                sessionId,
                number,
                inventoryItem.id,
                receipt.transactionHash
              )
              .then((result) => {
                console.log(result);
                if (result.success) {
                  // console.log("heal success", result.consumeResult.healAmount);
                  const consumeResult = result.consumeResult;
                  if (result.inventory) {
                    this.inventory = result.inventory;
                  } else {
                    this.inventory = consumeResult.inventory;
                  }

                  if (result.equipment) {
                    this.characters[this.currentCharacter].equipped = result.equipment;
                  }

                  this.isTooltipHovered = false;
                  this.isItemHovered = false;
                  this.hoveredItem = null;
                  this.isHoveredMarketItem = false;
                  this.isHoveredRepairItem = false;
                  this.isHoveredCraftItem = false;

                  if (
                    inventoryItem.stats.action == Constants.consumableActions.heal || inventoryItem.stats.action == Constants.consumableActions.resurrection
                  ) {
                    character.isHealing = true;
                    character.healAmount = consumeResult.healAmount;
                    character.stats = consumeResult.heroStats;
  
                    this.isUsingConsumable = true;

                    this.characters[this.currentCharacter].stats =
                      result.heroStats;

                    character.isHealing = false;
                    character.isProcessing = false;
                    this.isUsingConsumable = false;
                  }
                }
            });
          }
        }catch (error) {
          console.log(error);
          this.isUsingConsumable = false;
          character.isProcessing = false;
        }
      },
      consumeItem: async function (staticId, quantity) {
        const inventoryItem = this.inventory.find(
          (i) => i.staticId && i.staticId == staticId && i.type == "consumable"
        );

        let currentAccount = this.account;
        let sessionId = this.getSessionId();
        let number = this.characters[this.currentCharacter].number;

        let character = this.characters[this.currentCharacter];
        this.isTurnHappening = true;
        await apiConnector.callConsumeItemStatusUpdate(currentAccount, sessionId, number, true, inventoryItem.id, quantity)
        try{
          const { signer } = useUser();
          const ConsumablesContract = getDCGConsumablesContract(signer.value);
          const tx = await ConsumablesContract.burn(currentAccount, inventoryItem.id, quantity);
          if (tx?.hash) {
            const txHash = tx.hash;
            await apiConnector.handleTransactionHash(
              currentAccount,
              sessionId,
              number,
              "healing",
              txHash
            );
          }

          const receipt = await tx.wait();
          if (receipt.status == 1 && receipt.to.toLowerCase() == ConsumablesContract.address.toLowerCase()) {
            apiConnector
              .callConsumeItem(currentAccount, quantity, sessionId, number, inventoryItem.id, receipt.transactionHash,)
              .then((result) => {
                if (result.success) {
                  const consumeResult = result.consumeResult;
                  this.inventory = consumeResult.inventory;
                  this.isTooltipHovered = false;
                  this.isItemHovered = false;
                  this.hoveredItem = null;
                  this.isHoveredMarketItem = false;
                  this.isHoveredRepairItem = false;
                  this.isHoveredCraftItem = false;

                  if (
                    inventoryItem.stats.action == Constants.consumableActions.heal || inventoryItem.stats.action == Constants.consumableActions.resurrection
                  ) {
                    character.healAmount = consumeResult.healAmount;

                    character.isHealing = true;
                    character.stats = consumeResult.heroStats;

                    setTimeout(() => {
                      character.isHealing = false;
                    }, 500);
                    this.isTurnHappening = false;
                  } else {
                    character.stats = consumeResult.heroStats;
                    this.isTurnHappening = false;
                  }
                }
              });
          }
        }catch(error){
          console.log(error)
          this.isTurnHappening = false;
        }
      },
      hotbar: function () {
        let hotbarItems = [];

        if (
          this.characters[this.currentCharacter] != null &&
          this.characters[this.currentCharacter] != "undefined"
        ) {
          for (const key in this.characters[this.currentCharacter].equipped) {
            if (
              Object.hasOwnProperty.call(
                this.characters[this.currentCharacter].equipped,
                key
              )
            ) {
              const element =
                this.characters[this.currentCharacter].equipped[key];

              if (element.type == "hand1") hotbarItems.push(element);
            }
          }
        }
        
        this.inventory.forEach((element) => {
          if (element && element.type == "consumable") hotbarItems.push(element);
        });

        return hotbarItems;
      },
      encounterStats: function (encounter, isBoss) {
        const statsPerLevel = 2;
        const healthMultiplier = 200;
        const currentChar = this.characters[this.currentCharacter];

        let currentAttack = 0;
        let currentDefense = 0;
        let currentEndurance = 0;
        let currentSpeed = 0;

        let extraMobLevel = 1;

        if (isBoss) {
          extraMobLevel += 8;
        }

        if (currentChar.stats.level == 1) {
          currentAttack = 1;
          currentDefense = 1;
          currentEndurance = 1;
          currentSpeed = 1;
        } else {
          for (
            let playerLevelIndex = 0;
            playerLevelIndex < currentChar.stats.level + extraMobLevel;
            playerLevelIndex++
          ) {
            currentAttack += Math.random() * statsPerLevel;
            currentDefense += Math.random() * statsPerLevel;
            currentEndurance += Math.random() * statsPerLevel;
            currentSpeed += Math.random() * statsPerLevel;

            if (currentAttack == 0) {
              currentAttack = 1;
            }

            if (currentDefense == 0) {
              currentDefense = 1;
            }

            if (currentEndurance == 0) {
              currentEndurance = 1;
            }

            if (currentSpeed == 0) {
              currentSpeed = 1;
            }
          }
        }

        if (encounter.bonusDefense) {
          currentDefense += currentDefense * (encounter.bonusDefense / 100);
        }

        if (encounter.bonusAttack) {
          currentAttack += currentAttack * (encounter.bonusAttack / 100);
        }

        if (encounter.bonusSpeed) {
          currentSpeed += currentSpeed * (encounter.bonusSpeed / 100);
        }

        if (encounter.bonusEndurance) {
          currentEndurance +=
            currentEndurance * (encounter.bonusEndurance / 100);
        }

        return {
          attack: currentAttack,
          defense: currentDefense,
          endurance: currentEndurance,
          speed: currentSpeed,
          totalHp: Math.floor(currentEndurance * healthMultiplier),
          currentHp: Math.floor(currentEndurance * healthMultiplier),
        };
      },
      giveMobDcau: function (tokenResult) {
        this.combatLog.push(
            "You find " + this.highlightSpecial(tokenResult.rewardAmount.toFixed(4) + " " + tokenResult.rewardName + ".", true)
          );

        this.tokenPrize = tokenResult.rewardAmount.toFixed(4);
        this.tokenPrizeName = tokenResult.rewardName;
      },
      doLevelUp: function (exp) {
        const leftOverExp =
          this.characters[this.currentCharacter].stats.exp +
          exp -
          this.characters[this.currentCharacter].stats.expNextLevel;

        this.characters[this.currentCharacter].stats.level++;
        this.characters[this.currentCharacter].stats.exp = leftOverExp;
        this.characters[this.currentCharacter].stats.expNextLevel =
          this.characters[this.currentCharacter].stats.level *
          (Constants.expCurve * Constants.expPerLevel) *
          (1 + this.characters[this.currentCharacter].stats.level / 100);

        this.characters[this.currentCharacter].stats.levelStatPoints +=
          Constants.levelStatPoints;
      },
      doNotification: function (text) {
        if (this.hasNotification) {
          this.hasNotification = false;
          setTimeout(() => {
            this.notificationText = text;
            this.hasNotification = true;
          }, 150);
        } else {
          this.notificationText = text;
          this.hasNotification = true;
        }

        setTimeout(() => {
          this.hasNotification = false;
        }, 6000);
      },
      giveMobExp: function () {
        const totalStats =
          this.currentEncounter.stats.attack +
          this.currentEncounter.stats.defense +
          this.currentEncounter.stats.endurance +
          this.currentEncounter.stats.speed;

        const totalExp = totalStats * Constants.expMobMultiplier;

        this.giveExp(totalExp);
      },
      giveExp: function (amount) {
        if (
          this.characters[this.currentCharacter].stats.exp + amount >=
          this.characters[this.currentCharacter].stats.expNextLevel
        ) {
          this.doLevelUp(amount);
          this.hasPlayerLeveledUp = true;
          this.doNotification(
            "✨ You have gained a level! Visit your character screen to spend your points. ✨"
          );

          this.combatLog.push(
            "You have gained " +
              this.highlightSpecial(amount + " experience and gained a level!")
          );
        } else {
          this.combatLog.push(
            "You have gained " + this.highlightSpecial(amount + " experience.")
          );
          this.characters[this.currentCharacter].stats.exp += amount;
        }
      },
      encounterKilled: function (winResult, lootResult, tokenResult) {
        // const expDetails = winResult.expDetails

        this.combatLog.push(
          this.highlight(
            "You have slain " + this.currentEncounter.mob.name + "!"
          )
        );
        this.combatLog.push(this.currentEncounter.mob.death);

        this.isTurnHappening = false;

        let currentAccount = this.account;
        let sessionId = this.getSessionId();
        // let number = this.characters[this.currentCharacter].number;
        // let callEncounterId = this.currentEncounterId;

        // apiConnector
        //   .callFinishFight(currentAccount, sessionId, number, callEncounterId)
        //   .then((result) => {
        // this.giveMobExp();

        // let fightResult = result.fightResult;

        winResult.forEach((win) => {
          const expDetails = win.expDetails;
          const heroStats = win.heroStats;
          // this.hasPlayerLeveledUp = true; // Uncomment this if you want to test levelup screen after every combat win
          if (expDetails.isLevelUp) {
            this.hasPlayerLeveledUp = true;
            this.leveledUpHeroStats = heroStats
            // this.doNotification(
            //   "✨ You have gained a level! Visit your character screen to spend your points. ✨"
            // );

            this.combatLog.push(
              "Character " + heroStats.id + " has gained " +
                this.highlightSpecial(
                  expDetails.expGain + " experience and gained a level!"
                )
            );
          } else {
            this.combatLog.push(
              "Character " + heroStats.id + " has gained " +
                this.highlightSpecial(expDetails.expGain + " experience.")
            );
            // this.characters[this.currentCharacter].stats.exp =
            //   fightResult.expStats.exp;
          }
        })


        this.giveMobDcau(tokenResult);

        this.combatLog.push("You've earned " + this.highlightSpecial(tokenResult.bonus + "%") + " bonus DCAR based on your DCAR holdings.");

        // const lootResult = Loot.generateLoot(
        //   this.characters[this.currentCharacter].stats.luck +
        //     this.equipmentStats().luck,
        //   this.characters[this.currentCharacter].stats.level
        // );

        // const questsForItems = this.characters[
        //   this.currentCharacter
        // ].quests.filter((q) =>
        //   q.details.steps.find(
        //     (s) => s.type == Constants.questTypes.findItem
        //   )
        // );

        // if (questsForItems.length > 0) {
        //   const questRandom = Math.floor(Math.random() * 1000);

        //   if (questRandom < 50) {
        //     const questForLoot =
        //       questsForItems[
        //         Math.floor(Math.random() * questsForItems.length)
        //       ];
        //     const itemStep = questForLoot.details.steps.find(
        //       (s) => s.type == Constants.questTypes.findItem
        //     );

        //     if (
        //       questForLoot.currentStep ==
        //       questForLoot.details.steps.indexOf(itemStep)
        //     ) {
        //       const questItem = quests.generateItem(itemStep.itemId);
        //       lootResult.items.push(questItem);
        //       questForLoot.currentStep++;

        //       this.doNotification(
        //         "✔ You have found " + questItem.item.name + "! ✔"
        //       );
        //     }
        //   }
        // }

        const loot = [...lootResult.equipment, ...lootResult.consumables, ...lootResult.resources];

        this.currentEncounter.loot = loot;
        // this.addLootToInventory(this.currentEncounter.loot);
        this.lootType = Constants.lootType.encounter;

        // Removing updating inventory here as its not needed. 
        // Inventory gets updated after stash is claimed
        // apiConnector
        //   .callGetInventory(currentAccount, sessionId)
        //   .then((inventoryResult) => {
        //     this.inventory = inventoryResult.inventory;
        //   });
        // });
      },
      characterKilled: function () {
        this.characters[this.currentCharacter].stats.isDead = true;
      },
      highlight: function (content, isDamage) {
        return (
          '<span class="' +
          (isDamage ? "highlight" : "highlight-good") +
          '">' +
          content +
          "</span>"
        );
      },
      highlightSpecial: function (content, isGold) {
        return (
          '<span class="' +
          (isGold ? "highlight-gold" : "highlight-success") +
          '">' +
          content +
          "</span>"
        );
      },

      resourceGatherFinished: function () {
        this.currentGathering.isDepleted = true;
        this.combatLog.push(
          this.highlight("There doesn't seem to be anything left here.", true)
        );
      },
      resourceGatherExhausted: function () {
        this.currentGathering.isExhausted = true;
        this.combatLog.push(
          this.highlight("You've exhausted all your hits. Try increasing your gathering to reach the end of the vein.", true)
        );
      },
      doCombatGetHitMessage: async function (
        weaponName,
        mobName,
        hitLocation,
        isCritical,
        damage
      ) {
        return await new Promise((resolve) => {
          const character = this.characters[this.currentCharacter];

          let combatMsg = isCritical ? "It's a critical hit! " : "";

          // combatMsg +=
          //   this.highlight(mobName, true) +
          //   " hits you with " +
          //   this.highlight(weaponName, true) +
          //   " and connects with your " +
          //   this.highlight(hitLocation, true) +
          //   " for " +
          //   this.highlight(damage + " damage.", true);

          this.combatLog.push(combatMsg);

          character.isTakingDamage = true;
          character.damageTaken = damage;

          setTimeout(() => {
            character.isTakingDamage = false;

            if (this.characters[this.currentCharacter].stats.isDead) {
              this.combatLog.push(
                this.highlight("You have been slain by " + mobName + ".", true),
                "Switch characters or leave the battle to continue."
              );
            }

            resolve();
          }, 550);
        });
      },
      doCombatDamageMessage: async function (
        weaponName,
        mobName,
        hitLocation,
        isCritical,
        damage,
        isDoubleHit
      ) {
        return await new Promise((resolve) => {
          let combatMsg = isCritical ? "It's a critical hit! " : "";

          combatMsg += isDoubleHit ? "You hit the enemy twice! " : "";

          combatMsg +=
            "You use your " +
            this.highlight(weaponName) +
            " and connect with " +
            this.highlight(mobName + "'s " + hitLocation) +
            " for " +
            this.highlight(damage + " damage.");

          this.combatLog.push(combatMsg);

          this.currentEncounter.damageTaken = damage;
          this.currentEncounter.isTakingDamage = true;

          setTimeout(() => {
            this.currentEncounter.isTakingDamage = false;
            resolve();
          }, 550);

          // let combatMsg = playerDamage.isCritical ? "It's a critical hit! " : "";

          //     combatMsg +=
          //       "You use your " +
          //       this.highlight(weaponName) +
          //       " and connect with " +
          //       this.highlight(
          //         encounter.mob.name +
          //           "'s " +
          //           playerDamage.hitLocation
          //       ) + " for " + this.highlight(playerDamage.damage + " damage.");

          //     this.combatLog.push(combatMsg);

          //     this.currentEncounter = encounter
          //     this.currentEncounter.damageTaken = playerDamage.damage;
          //     this.currentEncounter.isTakingDamage = true;

          //     setTimeout(() => {
          //       this.currentEncounter.isTakingDamage = false;
          //     }, 550)
        });
      },
      doCombatDamage: function (hotBarSlot, hasAbilityReady = false) {
        if (this.isTurnHappening) {
          return;
        }

        if (hotBarSlot != -1) {
          const hotbarItem = this.hotbar()[hotBarSlot];

          for (const equipmentItemSlot in this.characters[this.currentCharacter]
            .equipped) {
            if (
              Object.hasOwnProperty.call(
                this.characters[this.currentCharacter].equipped,
                equipmentItemSlot
              )
            ) {
              const element =
                this.characters[this.currentCharacter].equipped[
                  equipmentItemSlot
                ];
              if (element.id == hotbarItem.id) {
                hotBarSlot = equipmentItemSlot;
              }
            }
          }
        }

        let currentAccount = this.account;
        let sessionId = this.getSessionId();
        let number = this.characters[this.currentCharacter].number;
        let callEncounterId = this.currentEncounterId;

        this.isTurnHappening = true;

        apiConnector
          .callFightHit(
            currentAccount,
            sessionId,
            number,
            hotBarSlot,
            callEncounterId,
            hasAbilityReady
          )
          .then((result) => {
            console.log('fight result',result);

            let weaponName = result.hitResult.playerDamage.weaponName;
            let encounter = result.hitResult.encounter;
            let playerDamage = result.hitResult.playerDamage;
            let enemyDamage = result.hitResult.enemyDamage;

            if (result.quests) {
              this.quests = result.quests;
            }

            if (result.inventory) {
              this.inventory = result.inventory;
            }

            if (result.equipment) {
              this.characters[this.currentCharacter].equipped = result.equipment;
            }

            // else {
            //   let mobWeaponName = result.hitResult.enemyDamage.weaponName;
            //   this.doCombatGetHitMessage(
            //     mobWeaponName,
            //     encounter.mob.name,
            //     enemyDamage.hitLocation,
            //     enemyDamage.isCritical,
            //     enemyDamage.damage
            //   ).then(() => {
            //     this.isTurnHappening = false;
            //   });
            // }

            /**
             * TODO: Refactor this later
             * Fetching data of the combat move but updating stats once the combat animation is over with
             */
            setTimeout( () => {
              this.currentEncounter = encounter;
              if (this.currentEncounter.stats.currentHp <= 0) {
                this.encounterKilled(
                  result.hitResult.winResult,
                  result.hitResult.lootResult,
                  result.hitResult.tokenResult
                );

                this.characters.forEach((character) => {
                  const win = result.hitResult.winResult.find(
                    (win) => parseFloat(character.number) == win.heroStats.id
                  );
                  if (win) {
                    character.stats = win.heroStats;
                  }
                });
              }else{
                this.isTurnHappening = false;
              }
            }, 1000)



            setTimeout(() => {
              this.currentEncounter = encounter;
              if (this.currentEncounter.stats.currentHp > 0) {
                this.characters[this.currentCharacter].stats =
                result.hitResult.heroStats;
              }
            }, 2000);


            // this.doCombatDamageMessage(
            //   weaponName,
            //   encounter.mob.name,
            //   playerDamage.hitLocation,
            //   playerDamage.isCritical,
            //   playerDamage.damage
            // ).then(() => {              
              
            // });
          });
        // const character = this.characters[this.currentCharacter];

        // let characterAttack =
        //   character.stats.attack + this.nonWeaponAttackStats();

        // const mobDef = this.currentEncounter.stats.defense;

        // const hitLocation = this.getHitLocation();

        // const bonusCritical = this.equipmentStats().criticalChance
        //   ? this.equipmentStats.criticalChance
        //   : 0;

        // let weaponAttack = 0;

        // if (hotBarSlot >= 0) {
        //   const hotbarItemId = this.hotbar()[hotBarSlot].id;
        //   const hotbarItemStats = this.itemStats.find(
        //     (s) => s.id == hotbarItemId
        //   );

        //   if (hotbarItemStats.attack) {
        //     weaponAttack += hotbarItemStats.attack;
        //   }
        // }

        // characterAttack += weaponAttack;

        // const damageDone = this.calculateDamage(
        //   characterAttack,
        //   mobDef,
        //   character.stats.criticalChance + bonusCritical,
        //   hitLocation.extraDamage
        // );

        // if (damageDone.damage >= this.currentEncounter.stats.currentHp) {
        //   damageDone.damage = this.currentEncounter.stats.currentHp;
        // }

        // let combatMsg = damageDone.isCritical ? "It's a critical hit! " : "";

        // let weaponName =
        //   hotBarSlot >= 0 ? this.hotbar()[hotBarSlot].name : "Fists";

        // combatMsg +=
        //   "You use your " +
        //   this.highlight(weaponName) +
        //   " and connect with " +
        //   this.highlight(
        //     this.currentEncounter.mob.name +
        //       "'s " +
        //       this.getHitName(hitLocation.name)
        //   ) +
        //   " for " +
        //   this.highlight(damageDone.damage + " damage.");

        // this.combatLog.push(combatMsg);

        // this.currentEncounter.stats.currentHp -= damageDone.damage;

        // this.currentEncounter.isTakingDamage = true;
        // this.currentEncounter.damageTaken = damageDone.damage;

        // setTimeout(() => {
        //   this.currentEncounter.isTakingDamage = false;

        //   this.isTurnHappening = false;

        //   if (this.currentEncounter.stats.currentHp <= 0) {
        //     this.encounterKilled();
        //   } else {
        //     this.takeCombatDamage();
        //   }
        // }, 550);
      },
      doPetDamage: function () {
        if (this.isTurnHappening) {
          return;
        }
        let hotBarSlot = 0;
        if (hotBarSlot != -1) {
          const hotbarItem = this.hotbar()[hotBarSlot];

          for (const equipmentItemSlot in this.characters[this.currentCharacter]
            .equipped) {
            if (
              Object.hasOwnProperty.call(
                this.characters[this.currentCharacter].equipped,
                equipmentItemSlot
              )
            ) {
              const element =
                this.characters[this.currentCharacter].equipped[
                  equipmentItemSlot
                ];
              if (element.id == hotbarItem.id) {
                hotBarSlot = equipmentItemSlot;
              }
            }
          }
        }

        let currentAccount = this.account;
        let sessionId = this.getSessionId();
        let number = this.characters[this.currentCharacter].number;
        let callEncounterId = this.currentEncounterId;
        let pet = this.pets.selectedPet;

        this.isTurnHappening = true;

        apiConnector
          .callPetHit(
            currentAccount,
            sessionId,
            number,
            pet.id,
            pet.address,
            hotBarSlot,
            callEncounterId
          )
          .then((result) => {
            if (!result.success) {
              this.doNotification(result.message);
              this.isTurnHappening = false;
              return;
            };
            let weaponName = result.hitResult.playerDamage.weaponName;
            let encounter = result.hitResult.encounter;
            let playerDamage = result.hitResult.playerDamage;
            let enemyDamage = result.hitResult.enemyDamage;
            if (result.quests) {
              this.quests = result.quests;
            }

            /**
             * TODO: Refactor this later
             * Fetching data of the combat move but updating stats once the combat animation is over with
             */
            setTimeout( () => {
              this.currentEncounter = encounter;
              if (this.currentEncounter.stats.currentHp <= 0) {
                this.encounterKilled(
                  result.hitResult.winResult,
                  result.hitResult.lootResult,
                  result.hitResult.tokenResult
                );

                this.characters.forEach((character) => {
                  const win = result.hitResult.winResult.find(
                    (win) => parseFloat(character.number) == win.heroStats.id
                  );
                  if (win) {
                    character.stats = win.heroStats;
                  }
                });
              }else{
                this.isTurnHappening = false;
              }
            }, 1000)

            setTimeout(() => {
              this.currentEncounter = encounter;
              if (this.currentEncounter.stats.currentHp > 0) {
                this.characters[this.currentCharacter].stats =
                result.hitResult.heroStats;
              }
            }, 2000);

          });
      },
      
      getHitLocation: function () {
        const hitLocationRandom = Math.floor(Math.random() * 100);

        let hitLocationCurrentNumber = 0;

        let hitLocation = Constants.hitLocationDetails[4];

        for (
          let index = 0;
          index < Constants.hitLocationDetails.length;
          index++
        ) {
          const currentHitLocationDetails = Constants.hitLocationDetails[index];
          const currentHitLocationCeiling =
            hitLocationCurrentNumber + currentHitLocationDetails.chance;

          if (
            hitLocationRandom > hitLocationCurrentNumber &&
            hitLocationRandom <= currentHitLocationCeiling
          ) {
            hitLocation = currentHitLocationDetails;
            break;
          }

          hitLocationCurrentNumber += currentHitLocationDetails.chance;
        }

        return hitLocation;
      },
      breakItem(id) {
        const itemStats = this.itemStats.find((i) => i.id == id);

        const currentEquipment =
          this.characters[this.currentCharacter].equipped;

        let equippedItem = {};
        let equippedSlotKey = "";

        for (const key in currentEquipment) {
          if (Object.hasOwnProperty.call(currentEquipment, key)) {
            const currentEquipped = currentEquipment[key];

            if (currentEquipped.id == id) {
              equippedItem = currentEquipped;
              equippedSlotKey = key;
            }
          }
        }

        if (
          equippedItem.rarity == Constants.rarities.shoddy ||
          equippedItem.rarity == Constants.rarities.normal
        ) {
          const message =
            "Your " +
            equippedItem.name +
            " breaks from the blow. Because of its poor quality, it is destroyed.";

          this.doNotification("🗡 " + message + " 🗡");

          this.combatLog.push(this.highlight(message, true));
          this.itemStats = this.itemStats.filter((i) => i.id != id);
        } else {
          const message =
            "Your " + equippedItem.name + " breaks from the blow.";

          this.doNotification(
            "🗡 " + message + " Find it in your inventory to repair. 🗡"
          );

          this.combatLog.push(this.highlight(message, true));
          itemStats.isBroken = true;
          this.inventory.push(equippedItem);
        }

        delete currentEquipment[equippedSlotKey];
      },
      takeCombatDamage: function () {
        const mobAttack = this.currentEncounter.stats.attack;
        const character = this.characters[this.currentCharacter];
        const characterDef =
          character.stats.defense + this.nonArmourDefenseStats();

        const hitLocation = this.getHitLocation();

        let hitLocationDefense = characterDef;

        if (character.equipped[hitLocation.name]) {
          const itemStats = this.itemStats.find(
            (i) => i.id == character.equipped[hitLocation.name].id
          );

          if (itemStats && itemStats.defense) {
            hitLocationDefense += itemStats.defense;
          }
        }

        const damageDone = this.calculateDamage(
          mobAttack,
          hitLocationDefense,
          10,
          hitLocation.extraDamage
        );

        if (character.equipped[hitLocation.name]) {
          const itemStats = this.itemStats.find(
            (i) => i.id == character.equipped[hitLocation.name].id
          );

          if (itemStats && itemStats.durability) {
            const durabilityDamageAmount = Math.floor(damageDone.damage / 16);

            if (itemStats.durability - durabilityDamageAmount <= 0) {
              itemStats.durability = 0;
              this.breakItem(itemStats.id);
            } else {
              itemStats.durability -= Math.floor(durabilityDamageAmount);
            }
          }
        }

        if (damageDone.damage >= character.stats.hp) {
          damageDone.damage = character.stats.hp;
        }

        // let combatMsg = damageDone.isCritical ? "It's a critical hit! " : "";

        // combatMsg +=
        //   this.highlight(this.currentEncounter.mob.name, true) +
        //   " hits you with " +
        //   this.highlight(this.currentEncounter.mob.weapon, true) +
        //   " and connects with your " +
        //   this.highlight(this.getHitName(hitLocation.name), true) +
        //   " for " +
        //   this.highlight(damageDone.damage + " damage.", true);

        // this.combatLog.push(combatMsg);

        character.stats.hp -= damageDone.damage;

        character.isTakingDamage = true;
        character.damageTaken = damageDone.damage;

        if (character.stats.hp <= 0) {
          this.characterKilled();
        }

        setTimeout(() => {
          character.isTakingDamage = false;
        }, 550);
      },
      getHitName: function (hitLocation) {
        switch (hitLocation) {
          case Constants.hitLocations.hand2:
            return "hands";
          case Constants.hitLocations.helmet:
            return "head";
          case Constants.hitLocations.gloves:
            return "hands";
          case Constants.hitLocations.boots:
            return "feet";
          case Constants.hitLocations.belt:
            return "stomach";
          default:
            break;
        }

        return hitLocation;
      },
      destroyInventoryItem: function (id) {
        this.inventory = this.inventory.filter((i) => i.id != id);
      },
      destroyStats: function (id) {
        this.itemStats = this.itemStats.filter((i) => i.id != id);
      },
      resourceByStaticId: async function(staticId) {
        let currentAccount = this.account;
        let sessionId = this.getSessionId();
        let number = this.characters[this.currentCharacter].number;

        const resourceResult = await apiConnector
          .callResourcesByStaticId(currentAccount, sessionId, number, staticId);

        if (resourceResult.success) {
          return resourceResult.resource;
        }
      },
      repairResources: function () {
        if (!this.hoveredItem) {
          return [];
        }


        //We need to avoid this if we are looking at a resource
        let results = [];
        if(this.hoveredItem.type != "resource") {
          // const itemStats = this.inventory.find(
        //   (i) => i.id == this.hoveredItem.id
        // ).stats;
        const itemStats = this.hoveredItem.stats;

        console.log('itemStats', itemStats, 'this.hoveredItem', this.hoveredItem);

          let amountPerDurability = Math.ceil(
              (itemStats.totalDurability - itemStats.durability) / 50
          );

          if (!amountPerDurability || amountPerDurability <= 0) {
            amountPerDurability = 1;
          }


          if (itemStats.resources) {
            for (let r = 0; r < itemStats.resources.length; r++) {
              let amountPerDurabilityItem = amountPerDurability;

              const resourceId = itemStats.resources[r];
              
              const resourceDetails = this.staticResourceList.find(
                (r) => r.staticId == resourceId
              );

              const invItem = this.inventory.find((a) => a.staticId == resourceId);
              let hasEnough = false;
              let userQuantity = 0;
              let hasMaxResourceAmount = false;

              if (itemStats.maxResourceAmounts && itemStats.maxResourceAmounts[resourceId]) {
                hasMaxResourceAmount = true;
                amountPerDurabilityItem = itemStats.maxResourceAmounts[resourceId];
              }

              if (invItem) {
                hasEnough = invItem.quantity >= amountPerDurabilityItem;
                userQuantity = invItem.quantity;
              }

              results.push({
                id: resourceId,
                quantity: amountPerDurabilityItem,
                image: "https://ik.imagekit.io/dcg/equip/" +
                    resourceDetails.imageName + ".png",
                name: resourceDetails.name,
                hasRequiredResource: hasEnough,
                userQuantity,
              });
            }
          }
        }

        return results;
      },
      hasAllRequiredResources: function (item) {
        if (!item) {
          return false;
        }

        let hasEnough = true;

        if (item.quantities && item.staticResourceIds) {
          for (let i = 0; i < item.staticResourceIds.length; i++) {
            const resourceId = item.staticResourceIds[i];
            
            const invItem = this.inventory.find((a) => a.staticId == resourceId);

            if (!invItem || invItem.quantity < item.quantities[i]) {
              hasEnough = false;
            }
          }
          return hasEnough;
        }

        const itemStats = item.stats;

          let amountPerDurability = Math.floor(
              (itemStats.totalDurability - itemStats.durability) / 50
          );

          if (!amountPerDurability || amountPerDurability <= 0) {
            amountPerDurability = 1;
          }


          if (itemStats.resources) {
            for (let r = 0; r < itemStats.resources.length; r++) {
              const resourceId = itemStats.resources[r];
            
              const invItem = this.inventory.find((a) => a.staticId == resourceId);

              if (!invItem || invItem.quantity < amountPerDurability) {
                hasEnough = false;
              }
            }
          }
          return hasEnough;
      },
      hasRequiredResources: function (item) {
        if (!item) {
          return false;
        }

        if (item.quantities && item.staticResourceIds) {
          console.log("in here");
          for (let i = 0; i < item.staticResourceIds.length; i++) {
            const resourceId = item.staticResourceIds[i];
            
            const invItem = this.inventory.find((a) => a.staticId == resourceId);
            let hasEnough = false;

            if (invItem) {
              hasEnough = invItem.quantity >= item.quantities[i];
            }

            console.log(invItem);
            console.log(hasEnough);

            return hasEnough;
          }
        }

        const itemStats = item.stats;

          let amountPerDurability = Math.floor(
              (itemStats.totalDurability - itemStats.durability) / 50
          );

          if (!amountPerDurability || amountPerDurability <= 0) {
            amountPerDurability = 1;
          }


          if (itemStats.resources) {
            for (let r = 0; r < itemStats.resources.length; r++) {
              const resourceId = itemStats.resources[r];
              
              const resourceDetails = this.staticResourceList.find(
                (r) => r.staticId == resourceId
              );

              const invItem = this.inventory.find((a) => a.staticId == resourceId);
              let hasEnough = false;

              if (invItem) {
                hasEnough = invItem.quantity >= amountPerDurability;
              }

              return hasEnough;
            }
          }
      },
      craftResources: function () {
        if (!this.hoveredItem) {
          return [];
        }


        //We need to avoid this if we are looking at a resource
        let results = [];

        if (this.hoveredItem.quantities && this.hoveredItem.resourceIds) {
          for (let r = 0; r < this.hoveredItem.resourceIds.length; r++) {
            const resourceId = this.hoveredItem.resourceIds[r];

            const resourceDetails = this.staticResourceList.find(
              (r) => r.staticId == resourceId
            );

            const invItem = this.inventory.find((a) => a.staticId == resourceId);
            let hasEnough = false;
            let userQuantity = 0;


            if (invItem) {
              hasEnough = invItem.quantity >= this.hoveredItem.quantities[r];
              userQuantity = invItem.quantity;
            }

            results.push({
              id: resourceId,
              quantity: this.hoveredItem.quantities[r],
              image: "https://ik.imagekit.io/dcg/equip/" +
                  resourceDetails.imageName + ".png",
              name: resourceDetails.name,
              hasRequiredResource: hasEnough,
              userQuantity,
            });
          }
          return results;
        }



        if(this.hoveredItem.type != "resource") {
          // const itemStats = this.inventory.find(
        //   (i) => i.id == this.hoveredItem.id
        // ).stats;
        const itemStats = this.hoveredItem.stats;

          let amountPerDurability = Math.ceil(
              (itemStats.totalDurability - itemStats.durability) / 12.5
          );

          if (this.hoveredItem.isBlueprint) {
            amountPerDurability = Math.ceil(itemStats.totalDurability / 12.5)
          }

          if (!amountPerDurability || amountPerDurability <= 0) {
            amountPerDurability = 1;
          }

          if (itemStats.resources) {
            for (let r = 0; r < itemStats.resources.length; r++) {
              const resourceId = itemStats.resources[r];
              const resourceDetails = this.staticResourceList.find(
                  (r) => r.staticId == resourceId
              );

              let finalDurability = amountPerDurability;
              
              if (!resourceDetails) {
                continue;
              }
              
              let hasEnough = false;
      
              if (itemStats.maxResourceAmounts && itemStats.maxResourceAmounts[resourceId] && itemStats.maxResourceAmounts[resourceId] > 0) {
                finalDurability = itemStats.maxResourceAmounts[resourceId];
              }

              const invItem = this.inventory.find((a) => a.staticId == resourceId);

              const craftingSlotItem = this.craftingSlots.find((a) => a?.currentItem?.staticId == resourceId)?.currentItem;

              if (invItem) {
                hasEnough = invItem.quantity >= finalDurability;
              }

              if (!invItem && craftingSlotItem) {
                hasEnough = craftingSlotItem.quantity >= finalDurability;
              }

              results.push({
                id: resourceId,
                quantity: finalDurability,
                image: "https://ik.imagekit.io/dcg/equip/" +
                    resourceDetails.imageName +
                    ".png",
                name: resourceDetails.name,
                hasRequiredResource: hasEnough,
              });
            }
          }
        }

        return results;
      },
      calculateDamage: function (
        attack,
        defense,
        criticalChance,
        hitLocationBonus
      ) {
        const damageScaling = 2;
        const scaledAttack = attack * damageScaling;
        const scaledDefense = defense * damageScaling;
        const maxDamage =
          (scaledAttack / (2 ^ (scaledAttack / scaledDefense))) * 10;
        const damageRandomFactor = (80 + Math.floor(Math.random() * 20)) / 100;
        let damage = maxDamage * damageRandomFactor;

        if (hitLocationBonus > 0) {
          damage = damage * (1 + hitLocationBonus / 100);
        }

        const isCritical = Math.floor(Math.random() * 100) <= criticalChance;

        if (isCritical) {
          damage = maxDamage * 1.5;
        }

        return { damage: Math.floor(damage), isCritical };
      },
      consumeResource(id, quantity) {
        const invItem = this.inventory.find((a) => a.id == id);

        if (invItem.quantity && invItem.quantity - quantity == 0) {
          this.destroyInventoryItem(id);
          this.isTooltipHovered = false;
          this.isItemHovered = false;
          this.hoveredItem = null;
        } else {
          invItem.quantity -= quantity;
        }
      },
      //Not sure why this is looking for element.type when it already has the element, I assume its used for martketplace
      //As such adding a boolean cjoice to avoid this issue
      addLootToInventory(lootBag, multiChoice = false) {
        for (let index = 0; index < lootBag.length; index++) {
          const element = lootBag[index];
          let switchChoice = element;
          if(!multiChoice){
            switchChoice = element.item.type;
          }
          switch (switchChoice) {
            case "resource":
              {
                if (!this.inventory.find((i) => i.id == element.item.id)) {
                  this.inventory.push(element.item);
                } else {
                  const foundItems = this.inventory.find(
                    (i) => i.id == element.item.id
                  );
                  foundItems.quantity += element.item.quantity;
                }
              }
              break;
            case "consumable":
              {
                // if (!this.itemStats.find((i) => i.id == element.itemStats.id)) {
                //   this.itemStats.push(element.itemStats);
                // }

                if (!this.inventory.find((i) => i.id == element.item.id)) {
                  this.inventory.push(element.item);
                } else {
                  const foundItems = this.inventory.find(
                    (i) => i.id == element.item.id
                  );
                  foundItems.quantity += element.item.quantity;
                }
              }
              break;
            default:
              this.itemStats.push(element.itemStats);
              this.inventory.push(element.item);
              break;
          }
        }
      },
      getCurrentCharacterEquipment: function () {
        let currentAccount = this.account;
        let sessionId = this.getSessionId();
        let number = this.characters[this.currentCharacter].number;

        apiConnector
          .callGetEquipment(currentAccount, sessionId, number)
          .then((equipmentResult) => {
            this.characters[this.currentCharacter].equipped =
              equipmentResult.equipment;
          });
      },
      getCurrentCharacterPet: function () {
        let currentAccount = this.account;
        let sessionId = this.getSessionId();
        let number = this.characters[this.currentCharacter].number;

        apiConnector
          .callGetEquippedPet(currentAccount, sessionId, number)
          .then((petResult) => {
            console.log(petResult);
            if(petResult.success){
              this.pets.selectedPet = petResult.pet;
              this.pets.isPetsOpen = false; // closing pets popup
            }
          });
      },
      getDailyQuest: function () {
        let currentAccount = this.account;
        let sessionId = this.getSessionId();
        let number = this.characters[this.currentCharacter].number;

        apiConnector.callGetDailyQuest(
          currentAccount,
          sessionId,
          number
        ).then( ( dailyQuestStatus ) => {
          if (dailyQuestStatus.hasNewQuest) {
            this.dailyQuest = dailyQuestStatus.newQuest;
            this.isDailyQuestVisible = true;
          }
        });
      },
      getCurrentQuests: function () {
        let currentAccount = this.account;
        let sessionId = this.getSessionId();
        let number = this.characters[this.currentCharacter].number;

        apiConnector.callGetQuestsForHero(
          currentAccount,
          sessionId,
          number
        ).then( ( questsResult ) => {
          this.quests = questsResult.quests;
        });
      },
      nonWeaponAttackStats: function () {
        let attack = 0;

        for (const equipmentSlot in this.characters[this.currentCharacter]
          .equipped) {
          if (
            Object.hasOwnProperty.call(
              this.characters[this.currentCharacter].equipped,
              equipmentSlot
            )
          ) {
            const element =
              this.characters[this.currentCharacter].equipped[equipmentSlot];

            if (element && !this.isWeaponSlot(equipmentSlot, element.type)) {
              if (element.stats.attack) {
                attack += element.stats.attack;
              }
            }
          }
        }

        return attack;
      },
      nonArmourDefenseStats: function () {
        let defense = 0;

        for (const equipmentSlot in this.characters[this.currentCharacter]
          .equipped) {
          if (
            Object.hasOwnProperty.call(
              this.characters[this.currentCharacter].equipped,
              equipmentSlot
            )
          ) {
            const element =
              this.characters[this.currentCharacter].equipped[equipmentSlot];

            if (element && !this.isArmourSlot(equipmentSlot)) {
              const itemId = element.id;

              const equipmentStats = this.itemStats.find(
                (el) => el.id == itemId
              );

              if (equipmentStats.defense) {
                defense += equipmentStats.defense;
              }
            }
          }
        }

        return defense;
      },
      isArmourSlot: function (slotName) {
        return (
          slotName == Constants.slots.helmet ||
          slotName == Constants.slots.shoulder ||
          slotName == Constants.slots.chest ||
          slotName == Constants.slots.belt ||
          slotName == Constants.slots.legs ||
          slotName == Constants.slots.boots ||
          slotName == Constants.slots.arms ||
          slotName == Constants.slots.neck ||
          slotName == Constants.slots.back ||
          slotName == 'ring' ||
          slotName == Constants.slots.gloves
        );
      },
      isWeaponSlot: function (slotName, itemType) {
        if (slotName == Constants.slots.hand1) {
          return true;
        }

        if (
          slotName == Constants.slots.hand2 &&
          itemType == Constants.slots.hand1
        ) {
          return true;
        }

        return false;
      },
      getSessionId: function () {
        return localStorage.getItem("sessionId");
      },
      equipmentStats: function () {
        let magic = 0;
        let crafting = 0;
        let gathering = 0;
        let attack = 0;
        let defense = 0;
        let speed = 0;
        let luck = 0;
        let endurance = 0;
        let criticalChance = 0;

        // let sets = [];

        for (const equipmentSlot in this.characters[this.currentCharacter]
          .equipped) {
          if (
            Object.hasOwnProperty.call(
              this.characters[this.currentCharacter].equipped,
              equipmentSlot
            )
          ) {
            const element =
              this.characters[this.currentCharacter].equipped[equipmentSlot];

            if (element) {
              const equipmentStats = element.stats;

              if (equipmentStats.magic) {
                magic += equipmentStats.magic;
              }

              if (equipmentStats.crafting) {
                crafting += equipmentStats.crafting;
              }

              if (equipmentStats.gathering) {
                gathering += equipmentStats.gathering;
              }

              if (equipmentStats.attack) {
                attack += equipmentStats.attack;
              }

              if (equipmentStats.defense) {
                defense += equipmentStats.defense;
              }

              if (equipmentStats.speed) {
                speed += equipmentStats.speed;
              }

              if (equipmentStats.luck) {
                luck += equipmentStats.luck;
              }

              if (equipmentStats.endurance) {
                endurance += equipmentStats.endurance;
              }

              if (equipmentStats.criticalChance) {
                criticalChance += equipmentStats.criticalChance;
              }
            }
          }
        }

        return {
          magic,
          crafting,
          gathering,
          attack,
          defense,
          speed,
          luck,
          endurance,
          criticalChance,
        };
      },
      isEquipmentVisible: false,
      isInventoryVisible: false,
      gameMinutes: 0,
      gameHours: 0,
      gameTick: 1000,
      isEquipping: false,
      isUnEquipping: false,
      isQuestsVisible: false,
      draggingItem: {},
      draggingSource: "",
      dragFromSlot: "",
      targetItemSlot: {},
      isMusicOn: true,
      dcauInWallet: 0,
      dcarInWallet: 0,
      bonusPercentage: 0,
      tokensRequired: 0,
      currentCharacter: 0,
      hasPendingDcauReward: false,
      pendingDcauReward: 0,
      hasPendingDcauSpend: false,
      pendingDcauSpend: 0,
      characters: [
        // {
        //   id: 0,
        //   number: 149,
        //   image: "https://cdn.dragoncrypto.io/heroes/b722ef4ce4ee37f10111a5b6cc56_hires.jpeg",
        //   isSelected: true,
        //   isTakingDamage: false,
        //   damageTaken: 0,
        //   isHealing: false,
        //   healAmount: 0,
        //   isDead: false,
        //   equipped: {},
        //   quests: [],
        //   rank: "Apprentice",
        //   stats: {
        //     level: 1,
        //     exp: 0,
        //     expNextLevel: 125,
        //     levelStatPoints: 15,
        //     totalHp: 100,
        //     hp: 100,
        //     turns: 10,
        //     totalTurns: 10,
        //     crafting: 1,
        //     gathering: 1,
        //     attack: 1,
        //     defense: 1,
        //     speed: 1,
        //     endurance: 1,
        //     luck: 0,
        //     magic: 1,
        //     criticalChance: 0,
        //   },
        // },
      ],
      inventory: [],
      forgeItems: [null, null, null],
      forgeIds: [],
      forgeQuantities: [],
      itemStats: [],
      sets: [{ id: "bloodassassin", desc: "Blood Assassin" }],
    };
  },
  mutations: {
    setInventoryEquipFilter(state, payload){
      state.inventoryEquipFilter = payload;
    },
    setGameState(state,newState){
      state.prevState = state.gameState;
      state.gameState = newState;
    },
    setIsSessionActive(state, status){
      state.isSessionActive = status;
    },
    setPopupActiveState(state, status){
      state.popupActive = status;
    },
    setHasPlayerLeveledUp: function( state, status){
      state.hasPlayerLeveledUp = status;
    },
    setUseHotbarItem(state, payload) {
      const index = payload.index;
      const quantity = payload.quantity;
      const hotbarItem = state.hotbar()[index];

      console.log(hotbarItem);

      if (!hotbarItem) {
        return;
      }

      if (state.characters[state.currentCharacter].stats.isDead && hotbarItem.stats.action != Constants.consumableActions.resurrection) {
        return;
      }

      if (state.isTurnHappening) {
        return;
      }

      if (state.gameState == Constants.gamemodes.combat) {
        if (!state.currentEncounter.isDead) {
          if (hotbarItem.type == Constants.slots.hand1) {
            state.doCombatDamage(index);
          }

          if (hotbarItem.type == Constants.useable.consumable) {
            console.log("consume consumable", hotbarItem);
            state.consumeItemCombat(hotbarItem.staticId);
          }
        }

        return;
      }

      if (state.gameState == Constants.gamemodes.wandering) {
        if (hotbarItem.type == Constants.useable.consumable) {
          // state.doNotification("❤ You use " + hotbarItem.name + " ❤");
          state.consumeItem(hotbarItem.staticId, quantity);
        }

        if (hotbarItem.type == Constants.slots.hand1) {
          state.doNotification(
            "⚔ Wow, what a great looking " + hotbarItem.name + " ⚔"
          );
        }
      }
    },
    setAcceptQuest(state) {
      state.characters[state.currentCharacter].stats.turns--;

      state.characters[state.currentCharacter].quests.push({
        details: state.currentFoundQuest,
        currentStep: 0,
        isCompleted: false,
      });

      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.questaccepted;
    },
    clearSound(state) {
      state.playSound = false;
    },
    setCharacters(state, payload) {
      state.characters = payload;
    },
    setResources(state, payload) {
      state.resources = payload;
    },
    setStaticResourceList(state, payload) {
      state.staticResourceList = payload;
    },
    setCurrentSellQuantity(state, payload) {
      state.currentSellQuantity = payload;
    },
    setCurrentSellPrice(state, payload) {
      state.currentSellPrice = payload;
    },
    setCurrentCharacterEquipped(state, equipped) {
      state.characters[state.currentCharacter].equipped = equipped;
    },
    setSellItem(state) {
      // const inventorySellItem = state.inventory.find(
      //   (i) => i.id == state.hoveredItem.id
      // );
      const inventorySellItem = state.hoveredItem;

      // const inventorySellItemStats = state.itemStats.find(
      //   (i) => i.id == state.hoveredItem.id
      // );
      const inventorySellItemStats = state.hoveredItem.stats;


      if (state.currentSellQuantity > inventorySellItem.quantity) {
        state.currentSellQuantity = inventorySellItem.quantity;
      }
      if (inventorySellItem.quantity) {
        if (state.currentSellQuantity < inventorySellItem.quantity) {
          inventorySellItem.quantity -= state.currentSellQuantity;
        } else {
          state.destroyInventoryItem(inventorySellItem.id);
          // state.destroyStats(inventorySellItem.id);
        }
      } else {
        state.destroyInventoryItem(inventorySellItem.id);
        // state.destroyStats(inventorySellItem.id);
      }

      const sellItem = {
        item: JSON.parse(JSON.stringify(inventorySellItem)),
        itemStats: inventorySellItemStats,
        characterNumber: state.characters[state.currentCharacter].number,
        sellPrice: state.currentSellPrice,
      };

      if (inventorySellItem.quantity) {
        sellItem.quantity = state.currentSellQuantity;
        sellItem.item.quantity = state.currentSellQuantity;
      }

      state.currentSellItem = sellItem;
      state.isMarketSelling = false;
    },
    setItemOnMarket(state) {
      state.currentSellItem = {};
    },
    setInventory(state, payload) {
      state.inventory = payload;
    },
    setInventoryItemStats(state, payload) {
      if (!payload) {
        return;
      }
      if (payload.isEquipped) {
        const item = state.characters[state.currentCharacter].equipped[payload.type];
        if (item) {
          item.stats = payload.stats;
        }
      }

      if (!payload.isEquipped) {
        const item = state.inventory.find((i) => i.id == payload.id);
        if (item) {
          item.stats = payload.stats;
        }
      }
    },
    setHeroStats(state, payload) {
      state.characters[state.currentCharacter].stats = payload;
    },
    setStash(state, payload) {
      state.stash.stash = payload;
    },
    setNotification(state, payload) {
      state.doNotification(payload);
    },
    setHideNotifications(state) {
      state.hasNotification = false;
    },
    setEquipmentVisible(state, payload) {
      state.isEquipmentVisible = payload;
    },
    setInventoryVisible(state, payload) {
      state.isInventoryVisible = payload;
    },
    setCurrentCharacter(state, id) {
      /*const characterIndex =
        state.characters.findIndex(
          (element) => element.id == id
        );
      state.currentCharacter = characterIndex;*/
      state.currentCharacterId = id;

      state.getCurrentCharacterEquipment();
      state.getCurrentCharacterPet();
      state.getDailyQuest();
      state.getCurrentQuests();
    },
    setCurrentCharacterByNumber(state, payload) {
      let current = 0;

      for (let index = 0; index < state.characters.length; index++) {
        if (state.characters[index].number * 1 == payload * 1) {
          current = index;
        }
      }

      state.currentCharacter = current;
    },
    setQuestStepBardTalkTo(state) {
      const currentCharacter = state.characters[state.currentCharacter];

      if (currentCharacter.quests && currentCharacter.quests.length > 0) {
        const questsForInnNpcs = currentCharacter.quests.find((q) =>
          q.details.steps.find(
            (s) =>
              s.type == Constants.questTypes.talkNpc &&
              s.talkToNpc == Constants.questNpcs.theBard
          )
        );

        if (!questsForInnNpcs.isFinished) {
          const questStep =
            questsForInnNpcs.details.steps[questsForInnNpcs.currentStep];

          if (
            questStep.type == Constants.questTypes.talkNpc &&
            questStep.talkToNpc == Constants.questNpcs.theBard
          ) {
            state.inventory = state.inventory.filter(
              (i) => i.id != questStep.requiresItemId
            );

            state.prevState = state.gameState;
            state.gameState = Constants.gamemodes.bardquestcomplete;
            questsForInnNpcs.isFinished = true;
            questsForInnNpcs.currentStep++;

            state.questFinishText = questsForInnNpcs.details.completedText;

            if (
              questsForInnNpcs.details.rewards.find(
                (r) => r == Constants.questRewards.exp
              )
            ) {
              state.giveExp(250);
            }

            if (
              questsForInnNpcs.details.rewards.find(
                (r) => r == Constants.questRewards.loot
              )
            ) {
              state.doNotification(
                "You have received a Common Chest! Open it from your inventory."
              );

              const lootboxItem = {
                id: 100000,
                name: "Common Chest",
                rarity: "Normal",
                type: "lootbox",
                image: "https://ik.imagekit.io/dcg/equip/Loot_102.png",
              };

              const lootboxStats = {
                id: lootboxItem.id,
                luckBoost: 20,
              };

              state.inventory.push(lootboxItem);

              if (!state.itemStats.find((is) => is.id == lootboxItem.id)) {
                state.itemStats.push(lootboxStats);
              }
            }
          }
        }
      }
    },
    setQuestsVisible(state, payload) {
      state.isQuestsVisible = payload;
    },
    setSelectedQuest(state, payload) {
      state.currentSelectedQuest = payload;
    },
    setClaimQuest( state, payload) {
      let number = payload.number;
      let questId = payload.questId;
      let currentAccount = state.account;
      let sessionId = state.getSessionId();

      apiConnector
        .callClaimQuestRewards( currentAccount, sessionId, number, questId )
        .then( (response) => {
          if (response.success) {

            if (response.expDetails.isLevelUp) {
              state.hasPlayerLeveledUp = true;
            }

            if (response.lootResult) {
              state.isQuestsVisible = false;



              const loot = [...response.lootResult.equipment, ...response.lootResult.consumables ];

              state.stash.stash = response.stash;
              state.currentEncounter.loot = loot;

              state.combatLog = [];
              state.prevState = state.gameState;
              state.lootType = Constants.lootType.lootbox;
              state.gameState = Constants.gamemodes.looting;
            }

            if (response.heroStats) {
              state.characters.filter((c) => c.number == number)[0].stats = response.heroStats;
            }

            state.quests = response.quests;
          }
      })
    },
    setDcauInWallet(state, payload) {
      state.dcauInWallet = payload;
    },
    setDcarInWallet(state, payload) {
      state.dcarInWallet = payload;
    },
    setBonusPercentage(state, paylaod){
      state.bonusPercentage = payload;
    },
    setTokensRequired(state,payload){
      state.tokensRequired = payload;
    },
    setDailyQuestStatus(state, payload) {
      state.dailyQuest = payload;
      state.isDailyQuestVisible = true;
    },
    setAcceptDailyQuest(state){
      let currentAccount = state.account;
      let sessionId = state.getSessionId();
      let number = state.characters[state.currentCharacter].number;
      let questId = state.dailyQuest.id;

      apiConnector
        .callAcceptDailyQuest( currentAccount, sessionId, number, questId )
        .then( (response) => {
          if (response.success) {
            state.quests = response.quests 
          }
          state.isDailyQuestVisible = false
        })
    },
    setQuests( state, payload ) {
      state.quests = payload;
    },
    setDeclineDailyQuest(state){
      state.isDailyQuestVisible = false;
    },
    setPendingRewardClaimed(state) {
      state.hasPendingDcauReward = false;
      state.pendingDcauReward = 0;
    },
    setPendingSpendProcessed(state) {
      state.hasPendingDcauSpend = false;
      state.pendingDcauSpend = 0;
    },
    setDragging(state, payload) {
      state.draggingItem = payload;
    },
    setDragSource(state, payload) {
      state.draggingSource = payload;
    },
    setDragFromSlot(state, payload) {
      state.dragFromSlot = payload;
    },
    setBardsSong(state, blockNumber) {
      apiConnector
          .callBardAtInn(
              state.account,
              state.getSessionId(),
              state.characters[state.currentCharacter].number,
              Constants.apiURLs.bardSong,
              blockNumber
          )
          .then((result) => {

            if (result.bardSong.expDetails !== undefined && result.bardSong.expDetails.isLevelUp) {
              state.hasPlayerLeveledUp = true;
            }

            result.bardSong.pendingDcauSpend = 0.1;
            result.bardSong.hasPendingDcauSpend;
            state.characters[state.currentCharacter].stats = result.heroStats;

            state.currentBardSong = result.bardSong;

            state.prevState = state.gameState;
            state.gameState = Constants.gamemodes.song;
          })
          .catch(function (e) {
            // Handle rejection instead of erroring plus log the exception, plus put it back to wandering state, need to add an error message here
            console.log("Call has failed, please try again", e);
            state.gameState = Constants.gamemodes.wandering;
            return e;
          });

    },
    setOpenLootbox(state) {
      const lootLuckBonus = state.itemStats.find(
        (i) => i.id == state.hoveredItem.id
      ).luckBoost;

      const lootResult = Loot.generateLoot(
        state.characters[state.currentCharacter].stats.luck +
          state.equipmentStats().luck +
          lootLuckBonus,
        state.characters[state.currentCharacter].stats.level
      );

      state.currentEncounter.loot = lootResult.items;

      state.addLootToInventory(state.currentEncounter.loot);

      state.inventory = state.inventory.filter((i) => i != state.hoveredItem);

      state.combatLog = [];
      state.prevState = state.gameState;
      state.lootType = Constants.lootType.lootbox;
      state.gameState = Constants.gamemodes.looting;

      state.isInventoryVisible = false;
      state.isEquipmentVisible = false;

      state.hoveredItem = null;
      state.isItemHovered = false;
      state.isTooltipHovered = false;
      state.isHoveredMarketItem = false;
      state.isHoveredRepairItem = false;
      state.isHoveredCraftItem = false;
    },
    setRemoveTooltip(state) {
      state.isItemHovered = false;
      state.isTooltipHovered = false;
      state.hoveredItem = null;
      state.isHoveredMarketItem = false;
      state.isHoveredRepairItem = false;
      state.isHoveredCraftItem = false;
      state.isUnEquipping = false;
    },
    setMultichoice(state) {
      // state.characters[state.currentCharacter].stats.turns -= 1;
      state.isTurnHappening = true;
      let currentAccount = state.account;
      let sessionId = state.getSessionId();
      let number = state.characters[state.currentCharacter].number;

      apiConnector
        .callMakeMultichoice(currentAccount, sessionId, number)
        .then((result) => {
          if (!result.success) {
            if (result.message == 'not the right hero') {
              state.doNotification('You are not the right hero for this multichoice!');
            }
            return;
          };

          const multichoiceResult = result.multichoiceResult;
          if (multichoiceResult.isSuccess) {
            state.currentMultichoice.hasChosen = true;
            state.currentMultichoice.hasWon = true;

            if (multichoiceResult.isExp) {

              if (multichoiceResult.expResult.isLevelUp) {
                state.hasPlayerLeveledUp = true;
                state.doNotification(
                  "✨ You have gained " + multichoiceResult.expResult.expGain + " exp which caused you to gain a level! Visit your character screen to spend your points. ✨"
                );
              } else {
                state.doNotification(
                  "✨ You have gained " + multichoiceResult.expResult.expGain + " experience. ✨"
                );
              }
              console.log(result.heroStats);
              state.characters[state.currentCharacter].stats = result.heroStats;
            }
            else{
              state.combatLog = [];
              state.prevState = state.gameState;
              state.lootType = Constants.lootType.event;
              state.gameState = Constants.gamemodes.looting;

              state.currentEncounter.loot = multichoiceResult.lootArray;
              state.inventory = result.inventory;

            }
          } else {
            if (state.currentMultichoice.event.soundFail) {
              state.soundToPlay = state.currentMultichoice.event.soundFail;
              state.playSound = true;
            }

            state.currentMultichoice.hasChosen = true;
            state.currentMultichoice.hasWon = false;

            switch (state.currentMultichoice.event.fail) {
              case Constants.eventLoses.loseturn:
                if (state.characters[state.currentCharacter].stats.turns > 0)
                  state.characters[state.currentCharacter].stats.turns -= 1;
                break;
              case Constants.eventLoses.losehp:
                state.characters[state.currentCharacter].damageTaken =
                  multichoiceResult.damageDone;
                state.characters[state.currentCharacter].stats.hp -=
                  multichoiceResult.damageDone;
                state.characters[state.currentCharacter].isTakingDamage = true;

                setTimeout(() => {
                  state.characters[
                    state.currentCharacter
                  ].isTakingDamage = false;
                }, 500);
                break;
              default:
                break;
            }
          }
          state.isTurnHappening = false;
        });

      
    },
    setCrafting(state) {
      state.isCrafting = true;
    },
    setDurability(state) {
      state.isDurability = true;
    },
    setGetCraftingResult(state) {
      const currentAccount = state.account;
      const sessionId = state.getSessionId();
      const number = state.characters[state.currentCharacter].number;
      const amountToCraft = state.amountToCraft;

      const resourceIds = state.craftingSlots.filter( (s) => s.currentItem ).map((s) => s.currentItem.staticId);

      apiConnector.callGetCraftingResult(
        currentAccount,
        sessionId,
        number,
        Constants.apiURLs.craftingResult,
        state.craftBlueprintItem.id,
        resourceIds,
        amountToCraft
      ).then( result => {
        state.craftResult = result.craftingResultItem;
        state.craftHasEnoughSkill = result.isSkilledEnough;
        state.craftHasEnoughResources = result.hasEnoughResources;
        state.craftCost = result.craftingCost;
      })
    },
    setResetCraftSuccessMessage(state){
      state.craftResult = null;
      state.isCraftingComplete = false;
    },
    setRemoveCraftingResources(state) {
      state.removeCraftResources();
      state.amountToCraft = 1;

      if (state.craftBlueprintItem) {
        state.inventory.push(state.craftBlueprintItem)
        state.craftBlueprintItem = null; 
        state.craftingSlots = [];
        state.craftResult = null;
        state.craftCost = 10;
      }
    },
    incrementNumberCrafts(state) {
      state.amountToCraft ++;
    },
    decrementNumberCrafts(state) {
      state.amountToCraft --;
    },
    setSessionExpired(state, value) {
      state.sessionExpired = value;
    },
    setSessionExpiry(state, expireTime) {
      state.sessionExpired = false;
      state.sessionExpiry = expireTime;
    },
    hideCrafting(state) {
      state.isCrafting = false;
    },
    hideDurability(state) {
      state.isDurability = false;
    },
    setMarketFilter(state, payload) {
      state.marketFilter = payload;
    },
    setMarketSelling(state) {
      state.isMarketSelling = true;
    },
    hideMarketSelling(state) {
      state.isMarketSelling = false;
    },
    setRepairListing(state) {
      state.isRepairListing = true;
    },
    hideRepairListing(state) {
      state.isRepairListing = false;
    },
    setCraftListing(state) {
      state.isCraftListing = true;
    },
    hideCraftListing(state) {
      state.isCraftListing = false;
    },
    setHovered(state, data) {
      clearTimeout(state.hoverTimeout);
      state.hoverTimeout = setTimeout(() => {
        clearTimeout(state.unhoveredTimeout);

        state.isItemHovered = true;
        state.hoveredItem = data.item;

        if (data.isMarketItem) {
          state.isHoveredMarketItem = data.isMarketItem;
        } else {
          state.isHoveredMarketItem = false;
        }

        if (data.isRepairItem) {
          console.log('is repair item');
          state.isHoveredRepairItem = data.isRepairItem;
        } else {
          state.isHoveredRepairItem = false;
        }

        if (data.isCraftingItem) {
          state.isHoveredCraftItem = data.isCraftingItem;
        } else {
          state.isHoveredCraftItem = false;
        }

        if(data.isLootChestItem){
          state.isHoveredLootChestItem = data.isLootChestItem;
        }else{
          state.isHoveredLootChestItem = false;
        }

        const itemHeight = 334;
        const windowHeight = window.innerHeight;

        let pageY = data.event.pageY;

        if (pageY + itemHeight > windowHeight) {
          pageY -= itemHeight;
        }

        if (state.stash.stashOpenStatus) {
          const stashElementPos = document.getElementsByClassName('stash-inventory')[0].getBoundingClientRect();
          state.hoverStartLocation = { x: stashElementPos.right, y: stashElementPos.top };
        } else {
          state.hoverStartLocation = { x: data.event.pageX, y: pageY };
        }
      }, 250);
    },
    setTooltipHovered(state) {
      state.isTooltipHovered = true;
    },
    setTooltipUnhovered(state) {
      state.isTooltipHovered = false;
    },
    setUnhovered(state) {
      clearTimeout(state.hoverTimeout);

      clearTimeout(state.unhoveredTimeout);

      state.unhoveredTimeout = setTimeout(() => {
        state.isItemHovered = false;
      }, 350);
    },
    setHealers(state) {
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.healers;
    },
    hideRepairRoom(state) {
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.crafting;
      state.isInventoryVisible = true;
    },
    setRepairRoom(state) {
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.repair;
      state.isInventoryVisible = true;
    },
    hideCraftRoom(state) {
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.crafting;
      state.isInventoryVisible = true;
    },
    setCraftRoom(state) {
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.crafters;
      state.isInventoryVisible = true;
    },
    setMarketplace(state) {
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.marketplace;
      state.isInventoryVisible = true;
    },
    setBank(state) {
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.bank;
      state.isInventoryVisible = false;
    },
    hideBank(state){
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.wandering;
    },
    hideMarketPlace(state) {
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.wandering;
      state.isInventoryVisible = false;
    },
    hideNestRoom(state) {
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.bank;
      state.isInventoryVisible = false;
    },
    setStashWindow(state){
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.stash;
    },
    hideStashWindow(state){
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.wandering;
    },
    setHealing(state, payload) {
      let blockNumber = payload.blockNumber;
      let currentAccount = state.account;
      let sessionId = state.getSessionId();
      let number = payload.heroId;

      let currentCharacter = 0;
      let i = 0;
      state.characters.forEach((c) => {
        if (c.number == number) {
          currentCharacter = i;
        }
        i++;
      });


      apiConnector
          .callHealHero(currentAccount, sessionId, number, Constants.apiURLs.healHero, blockNumber)
          .then((result) => {
            if (result) {
              state.characters[currentCharacter].isHealing = true;
              state.characters[currentCharacter].stats = result.heroStats;

              state.pendingDcauSpend = result.costToHeal;
              state.hasPendingDcauSpend = true;

              setTimeout(() => {
                state.characters[currentCharacter].isHealing = false;
              }, 1000);
            }
          this.commit("setTurnHappening", false);
          });
    },
    setCheckRefresh(state) {
      const dateNowSeconds = Date.now() / 1000;
      const sessionExpirySeconds = state.sessionExpiry;
      const currentTimeUTC = new Date().getTime();

      if (sessionExpirySeconds < currentTimeUTC) {
        console.log("session expired");
        state.sessionExpired = true;
      }

      if (
        dateNowSeconds - state.lastRefreshSeconds >=
        state.secondsBetweenRefresh
      ) {
        const secondsSinceRefresh = dateNowSeconds - state.lastRefreshSeconds;
        const refreshesToDo = Math.floor(
          secondsSinceRefresh / state.secondsBetweenRefresh
        );

        if (refreshesToDo > 0) {
          state.lastRefreshSeconds = dateNowSeconds;
        }

        for (
          let currentRefresh = 0;
          currentRefresh < refreshesToDo;
          currentRefresh++
        ) {
          for (let char = 0; char < state.characters.length; char++) {
            const character = state.characters[char];

            if (character.stats.hp < character.stats.totalHp) {
              let healAmount =
                character.stats.totalHp / state.healthPercentPerRefresh;

              if (healAmount > character.stats.totalHp - character.stats.hp) {
                healAmount = character.stats.totalHp - character.stats.hp;
              }

              character.stats.hp += healAmount;
            }

            if (character.stats.turns < character.stats.totalTurns) {
              character.stats.turns++;
            }
          }
        }
      }
    },
    setResurrect(state, payload) {
      const { blockNumber, heroId } = payload;
      let currentAccount = state.account;
      let sessionId = state.getSessionId();

      apiConnector
        .callResurrectHero(currentAccount, sessionId, heroId, blockNumber)
        .then((result) => {
          if (result) {
            console.log(result);
            const currentCharacter = state.characters.findIndex(c => c.number == heroId);

            state.characters[currentCharacter].isHealing = true;
            state.characters[currentCharacter].stats = result.heroStats;

            state.pendingDcauSpend = 2;
            state.hasPendingDcauSpend = true;

            setTimeout(() => {
              state.characters[currentCharacter].isHealing = false;
            }, 1000);
          }
          this.commit("setTurnHappening", false);
        });
    },
    setResurrectFree(state, heroId) {
      let currentAccount = state.account;
      let sessionId = state.getSessionId();

      console.log(`resurrecting hero ${heroId} for free`);

      apiConnector
        .callResurrectHeroFree(currentAccount, sessionId, heroId)
        .then((result) => {
          if (result) {
            console.log(result);
            const currentCharacter = state.characters.findIndex(c => c.number == heroId);

            state.characters[currentCharacter].isHealing = true;
            state.characters[currentCharacter].stats = result.heroStats;

            state.pendingDcauSpend = 2;
            state.hasPendingDcauSpend = true;

            setTimeout(() => {
              state.characters[currentCharacter].isHealing = false;
            }, 1000);
          }
          this.commit("setTurnHappening", false);
        });
    },
    setCurrentCharacterCombat(state, payload) {
      console.log('set current char id',payload);

      let currentAccount = state.account;
      let sessionId = state.getSessionId();

      const characterIndex =
        state.characters.findIndex(
          (element) => element.id == payload
        );

      let number = state.characters[characterIndex].number;

      apiConnector
        .callSwitchCharactersCombat(currentAccount, sessionId, number)
        .then((result) => {
          if (result && result.success) {
            
            //This code is to maintain the selected characters in the list of 5

            const newMainChar = state.characterSelect.selectedCharacters[characterIndex];

            const charBeingReplaced = state.characterSelect.selectedCharacters[0];

            state.characterSelect.selectedCharacters[characterIndex] =
              charBeingReplaced;

            state.characterSelect.selectedCharacters[0] = newMainChar;

            localStorage.setItem(
              "selectedCharacters",
              JSON.stringify(
                state.characterSelect.selectedCharacters,
                ["id"]
              )
            );

            // this is the code that maintains the currently selected character

            let currentIndex = 0;

            for (let index = 0; index < state.characters.length; index++) {
              if (state.characters[index].number * 1 == number * 1) {
                currentIndex = index;
              }
            }

            state.currentCharacter = currentIndex;
            state.currentCharacterId = payload;
            state.characters[state.currentCharacter].equipped = result.equipped;
            state.characters[state.currentCharacter].stats = result.heroStats;
            state.pets.selectedPet = result.pet;
          } else {
            if (result && result.message) {
              state.doNotification(result.message);
            }
          }
        });
    },
    setIncreaseStat(state, statName) {
      state.characters[state.currentCharacter].stats[statName]++;
      state.characters[state.currentCharacter].stats.levelStatPoints--;

      if (statName == "endurance") {
        state.characters[state.currentCharacter].stats.hp +=
          Constants.healthPerEndurance;
        state.characters[state.currentCharacter].stats.totalHp +=
          Constants.healthPerEndurance;
      }
    },
    setSleeping(state, payload) {

      const blockNumber = payload.blockNumber;
      const heroId = payload.heroId;

      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.sleeping;

      state.soundToPlay = Constants.sounds.sleeping;
      state.playSound = true;

      apiConnector
        .callRestAtInn(
          state.account,
          state.getSessionId(),
          heroId,
          blockNumber
        )
        .then((result) => {
          state.prevState = state.gameState;
          state.gameState = Constants.gamemodes.wakeup;

          state.pendingDcauSpend = 0.5;
          state.hasPendingDcauSpend = true;

          state.characters.filter((character) => {
            if (character.number == heroId) {
              character.stats = result.heroStats;
            }
          });

        });
    },
    setTurnHappening(state, payload) {
      state.isTurnHappening = payload;
    },
    setSearchingMines(state, payload) {
      state.isInventoryVisible = false;
      state.isEquipmentVisible = false;
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.searchingMines;
      state.charLocation = Constants.resourceLocations.mines;
      state.isTurnHappening = true;

      apiConnector
        .callSearchForEncounter(
          payload,
          state.getSessionId(),
          state.characters[state.currentCharacter].number,
          Constants.apiURLs.searchMines
        )
        .then((result) => {
          if(result.success){

            state.isTurnHappening = false;
  
            state.currentEncounterId = result.encounterId;
            state.miningEncounter = result.encounter;
  
            state.prevState = state.gameState;
            state.gameState = Constants.gamemodes.gatheringStart;
  
            state.characters[state.currentCharacter].stats = result.heroStats;
  
            const currentState = state;
            currentState.currentGathering = {
              resource: state.miningEncounter.resource,
            };
          }else{
            // Else showing the error message via notification
            this.commit("setNotification", `🛑 ${result.message} 🛑`);
            state.gameState = state.prevState;
            state.isTurnHappening = false;
          }
        })
        .catch(function (e) {
          state.isTurnHappening = false;
          // Handle rejection instead of erroring plus log the exception, plus put it back to wandering state, need to add an error message here
          console.log("Call has failed, please try again", e);
          state.gameState = Constants.gamemodes.wandering;
          return e;
        });
    },
    setSearchingDeepwood(state, payload) {
      state.isInventoryVisible = false;
      state.isEquipmentVisible = false;
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.searchingDeepwood;
      state.charLocation = Constants.resourceLocations.deepwood;
      state.isTurnHappening = true;

      apiConnector
        .callSearchForEncounter(
          payload,
          state.getSessionId(),
          state.characters[state.currentCharacter].number,
          Constants.apiURLs.searchDeepwood
        )
        .then((result) => {
          if(result.success){

            if(!result.deepwoodSuccess){
              state.doNotification(
                result.returnMessage
              );
            }
  
            if(result.returnMessage){
              state.doNotification(
                result.returnMessage
              );
            }
  
            state.isTurnHappening = false;
  
            state.currentEncounterId = result.encounterId;
            state.deepwoodEncounter = result.encounter;
  
            state.prevState = state.gameState;
            state.gameState = Constants.gamemodes.gatheringStart;
  
            state.characters[state.currentCharacter].stats = result.heroStats;
  
            const currentState = state;
            currentState.currentGathering = {
              resource: state.deepwoodEncounter.resource,
            };
          }else{
            // If success is false, showing the error message via notification
            this.commit("setNotification", `🛑 ${result.message} 🛑`);
            state.gameState = state.prevState;
            state.isTurnHappening = false;
          }
        })
        .catch(function (e) {
          state.isTurnHappening = false;
          // Handle rejection instead of erroring plus log the exception, plus put it back to wandering state, need to add an error message here
          console.log("Call has failed, please try again", e);
          state.gameState = Constants.gamemodes.wandering;
          return e;
        });
    },
    craftForgeItems(state,payload) {
      state.craftForgeItems(state,payload);
    },
    setBreakdown(state) {
      if(state.hoveredItem.type != "resource") {
        let itemIds = [];
        itemIds.push(state.hoveredItem.id);
        state.breakdownItem(itemIds);
      }
    },
    setInventoryBreakdown(state,payload) {
      state.breakdownItem(payload);
    },
    setGathering(state) {
      state.combatLog = [];
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.gathering;
    },
    doGatherResource(state, payload) {
      state.isTurnHappening = true;
      let callEncounterId = state.currentEncounterId;
      apiConnector
        .callMineResource(
          payload.account,
          state.getSessionId(),
          state.characters[state.currentCharacter].number,
          Constants.apiURLs.mineResource,
          payload.itemStaticId,
          callEncounterId
        )
        .then((result) => {
          const randomSound = Math.floor(Math.random() * 3) + 1;
          state.soundToPlay = Constants.sounds["mining" + randomSound];
          state.playSound = true;

          let expGain = result.encounter.expGained;

          let weaponName = payload.weaponName
            ? payload.weaponName
            : "Fists(ouch)";

          let gatherMessage =
            "You use your " +
            state.highlight(weaponName) +
            " and gather " +
            state.highlight( 
              result.encounter.gatheredAmount +
              " " + state.currentGathering.resource.name) + 
            ". You also receive " + state.highlight(expGain + " experience");
          state.combatLog.push(gatherMessage);

          if (result.encounter.isDepleted) {
            state.resourceGatherFinished();
          }

          if (result.encounter.isExhausted) {
            state.resourceGatherExhausted();
          }


          state.characters[state.currentCharacter].stats = result.heroStats;
          state.stash.stash = result.stash;

          state.inventory = result.inventory
          state.characters[state.currentCharacter].equipped = result.equipment

          // TODO - Verify this works with actual loot
          //let weaponName = this.hotbar()[0].name;
          // Calling levelup screen on levelup
          if(result.encounter.expResult.isLevelUp){
            state.hasPlayerLeveledUp = true;
          }
          state.isTurnHappening = false;
        })
        .catch(function (e) {
          state.isTurnHappening = false;
          // Handle rejection instead of erroring plus log the exception, plus put it back to wandering state, need to add an error message here
          console.log("Call has failed, please try again", e);
          state.gameState = Constants.gamemodes.wandering;
          return e;
        });
    },

    setLoadEncounter(state, payload) {
      state.isInventoryVisible = false;
      state.isEquipmentVisible = false;

      state.resumeEncounter(payload);
    },
    setGameMinutes(state, payload) {
      state.gameMinutes = payload;
    },
    setGameHours(state, payload) {
      state.gameHours = payload;
    },
    setGameTick(state, payload) {
      state.gameTick = payload;
    },
    setGameTime(state, payload) {
      state.gameTick = payload.gameTick;
      state.gameHours = payload.gameHours;
      state.gameMinutes = payload.gameMinutes;
    },
    setTimeOfDay(state, timeOfDay) {
      state.timeOfDay = timeOfDay;
    },
    setSearching(state, payload) {
      state.isInventoryVisible = false;
      state.isEquipmentVisible = false;
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.searching;

      apiConnector
        .callSearchForEncounter(
          payload,
          state.getSessionId(),
          state.characters[state.currentCharacter].number,
          Constants.apiURLs.searchPlains
        )
        .then((result) => {
          if(result.success){
            // Allowing encounter if result is a success
            state.characters[state.currentCharacter].stats = result.heroStats;
            state.startEncounter(result);
          }else{
            // Else showing the error message via notification
            this.commit("setNotification", `🛑 ${result.message} 🛑`);
            state.gameState = state.prevState;

          }
        });
    },
    setLogin(state, payload) {
      state.characters[
        state.currentCharacter
      ].image = "https://ik.imagekit.io/dcg/" +
        payload.characterPortrait +
        ".jpeg?tr=w-224";
      state.characters[state.currentCharacter].number = payload.number;

      state.accessCode = payload.accessCode;

      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.wandering;
    },
    setPlayerData(state, payload) {
      state.itemStats = payload.itemStats;
      state.characters = payload.characters;
      state.inventory = payload.inventory;

      if (payload.lastRefreshSeconds) {
        state.lastRefreshSeconds = payload.lastRefreshSeconds;
      }

      if (payload.forgeItems) {
        state.forgeItems = payload.forgeItems;
      }

      state.startSaving = true;
      state.idTracker = payload.idTracker;
    },
    setCurrentPlayerStats(state, payload) {
      state.characters[state.currentCharacter].stats = payload;
    },
    resetStatPoints(state, payload) {
      let currentAccount = state.account;
      let sessionId = state.getSessionId();
      let currentHeroNumber = state.characters[state.currentCharacter].number;

      apiConnector
          .resetStatPoints(currentAccount, sessionId, currentHeroNumber, Constants.apiURLs.resetStatPoints)
          .then((result) => {
            if (result) {
              state.prevState = state.gameState;
              state.gameState = Constants.gamemodes.wandering;
              state.characters[state.currentCharacter].stats =
                  result.heroStats;
            }
          });
    },
    setSaving(state) {
      state.startSaving = true;
    },
    setLooting(state) {
      state.combatLog = [];
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.looting;
    },
    setWandering(state) {
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.wandering;
    },
    setNest(state) {
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.nest;
    },
    setLeaveMultichoice(state) {
      let currentAccount = state.account;
      let sessionId = state.getSessionId();
      let number = state.characters[state.currentCharacter].number;
      apiConnector
        .callLeaveMultichoice(currentAccount, sessionId, number)
        .then((result) => {
          if (!result.success) {
            state.doNotification(result.message);
            return;
          }
          if (result) {
            state.prevState = state.gameState;
            state.gameState = Constants.gamemodes.wandering;
          }
        });
    },
    setLeaveMultichoiceSearch(state) {
      let currentAccount = state.account;
      let sessionId = state.getSessionId();
      let number = state.characters[state.currentCharacter].number;

      apiConnector
        .callLeaveMultichoice(currentAccount, sessionId, number)
        .then((result) => {
          if (!result.success) {
            state.doNotification(result.message);
            return;
          }
          if (result) {
            state.isInventoryVisible = false;
            state.isEquipmentVisible = false;
            state.prevState = state.gameState;
            state.gameState = Constants.gamemodes.searching;

            apiConnector
              .callSearchForEncounter(
                currentAccount,
                sessionId,
                number,
                Constants.apiURLs.searchPlains
              )
              .then((result) => {
                state.characters[state.currentCharacter].stats =
                  result.heroStats;

                state.startEncounter(result);
              });
          }
        });
    },
    setInn(state) {
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.inn;
    },
    setForge(state) {
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.forge;
      state.isInventoryVisible = true;
    },
    setLeaveCombatDeadHealers(state) {
      let currentAccount = state.account;
      let sessionId = state.getSessionId();
      let number = state.characters[state.currentCharacter].number;

      apiConnector
        .callLeaveCombatDead(currentAccount, sessionId, number)
        .then((result) => {
          if (result.success) {
            state.prevState = state.gameState;
            state.gameState = Constants.gamemodes.healers;
          }
        });
    },
    setLeaveCombatDead(state) {
      let currentAccount = state.account;
      let sessionId = state.getSessionId();
      let number = state.characters[state.currentCharacter].number;

      apiConnector
        .callLeaveCombatDead(currentAccount, sessionId, number)
        .then((result) => {
          if (result.success) {
            state.prevState = state.gameState;
            state.gameState = Constants.gamemodes.wandering;
          }
        });
    },
    setConsumeItem(state, {staticId, quantity}) {
      state.consumeItem(staticId, quantity);
    },
    setAccount(state, account) {
      state.account = account;
    },
    setConsumeItemCombat(state, staticId) {
      state.consumeItemCombat(staticId);
    },
    setBattleFlee(state) {
      const random = Math.random() * 100;

      if (random <= 50) {
        state.gameState = Constants.gamemodes.wandering;
      } else {
        state.combatLog.push(state.highlight("You fail to flee!", true));
        state.takeCombatDamage();
      }
    },
    setCraftItemFromBlueprint(state) {
      let currentAccount = state.account;
      const amountToCraft = state.amountToCraft;

      state.isTurnHappening = true;

      const resourceIds = state.craftingSlots.filter( (s) => s.currentItem ).map((s) => s.currentItem.staticId);

      console.log(resourceIds);

      apiConnector
        .callCraftItemFromBlueprint(
          currentAccount, 
          state.getSessionId(), 
          state.characters[state.currentCharacter].number, 
          Constants.apiURLs.craft, 
          state.craftBlueprintItem.id,
          resourceIds,
          amountToCraft,
          currentAccount,
          null
        )
        .then((result) => {
          state.isTurnHappening = false;
          state.soundToPlay = Constants.sounds.repair;
          state.playSound = true;
          state.amountToCraft = 1;
          state.removeCraftResources();

          if (state.craftBlueprintItem) {
            state.inventory.push(state.craftBlueprintItem)
            state.craftBlueprintItem = null; 
            state.craftingSlots = [];
            // state.craftResult = null; // Doing this when success message is removed.
            state.craftCost = 10 * state.amountToCraft;
          }

          state.isCraftingComplete = true;

          state.inventory = result.inventory;

          state.stash.stash = result.stash;

          state.isTurnHappening = false;
          state.characters[state.currentCharacter].stats = result.heroStats;
        })
        .catch(function (e) {
          // Handle rejection instead of erroring plus log the exception, plus put it back to wandering state, need to add an error message here
          console.log("Call has failed, please try again", e);
          state.isTurnHappening = false;
          return e;
          });
    },
    setCraftItemContract(state, payload) {
      console.log(payload);
      let currentAccount = state.account;

      state.isTurnHappening = true;

      const resourceIds = payload.resourceIds;
      const targetAddress = payload.targetAddress;
      const amountToCraft = payload.amountToCraft;
      const blueprintId = payload.blueprintId;
      const saleIndex = payload.saleIndex;

      apiConnector
        .callCraftItemFromBlueprint(
          currentAccount, 
          state.getSessionId(), 
          state.characters[state.currentCharacter].number, 
          Constants.apiURLs.craft, 
          blueprintId,
          resourceIds,
          amountToCraft,
          targetAddress,
          saleIndex
        )
        .then((result) => {
          state.isTurnHappening = false;
          state.soundToPlay = Constants.sounds.repair;
          state.playSound = true;
          state.amountToCraft = 1;

          state.inventory = result.inventory;

          state.stash.stash = result.stash;

          state.isTurnHappening = false;
          state.characters[state.currentCharacter].stats = result.heroStats;
        })
        .catch(function (e) {
          // Handle rejection instead of erroring plus log the exception, plus put it back to wandering state, need to add an error message here
          console.log("Call has failed, please try again", e);
          state.isTurnHappening = false;
          return e;
          });
    },
    setRepair(state, payload) {
      let currentAccount = state.account;
      const item = payload.repairItem;
      const guildRepair = payload.guildRepair;
      const saleIndex = payload.saleIndex;
      const txHash = payload.txHash;

      state.isTurnHappening = true;
      apiConnector
        .repairItems(currentAccount, state.getSessionId(), state.characters[state.currentCharacter].number, Constants.apiURLs.repair, item, guildRepair, saleIndex, txHash)
        .then((result) => {
          state.soundToPlay = Constants.sounds.repair;
          state.playSound = true;
          state.inventory = result.inventory;
          state.characters[state.currentCharacter].stats = result.heroStats;
          state.characters[state.currentCharacter].equipped = result.equipment;
          state.doNotification(
            result.message
          );
          state.isTurnHappening = false;
        })
        .catch(function (e) {
          // Handle rejection instead of erroring plus log the exception, plus put it back to wandering state, need to add an error message here
          console.log("Call has failed, please try again", e);
          state.isTurnHappening = false;
          return e;
          });


    },
    setFightFlee(state) {
      let currentAccount = state.account;
      let sessionId = state.getSessionId();
      let number = state.characters[state.currentCharacter].number;
      let callEncounterId = state.currentEncounterId;

      // state.isTurnHappening = true;
      state.isFleeing = true;
      apiConnector
        .callFightFlee(currentAccount, sessionId, number, callEncounterId)
        .then((result) => {
          if (result.fleeSuccess) {
            state.clear;
            state.prevState = state.gameState;
            state.gameState = Constants.gamemodes.wandering;
            state.doNotification(
              "You successfully flee with your tail between your legs"
            );
            // state.isTurnHappening = false;
      state.isFleeing = false;

          } else {
            // state.isTurnHappening = false;
      state.isFleeing = false;

            // state.combatLog.push(
            //   state.highlight(
            //     "You fail to flee from " +
            //       state.currentEncounter.mob.name +
            //       ".",
            //     true
            //   )
            // );

            let mobWeaponName = result.enemyHit.weaponName;
            state.characters[state.currentCharacter].stats = result.heroStats;

            state.isFleeing = false;
            
            // state
            //   .doCombatGetHitMessage(
            //     mobWeaponName,
            //     state.currentEncounter.mob.name,
            //     result.enemyHit.hitLocation,
            //     result.enemyHit.isCritical,
            //     result.enemyHit.damage
            //   )
            //   .then(() => {
            //     // state.isTurnHappening = false;

            //   });
          }
        });
    },
    setOpenCraftersGuild(state) {
      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.crafting;

      state.isInventoryVisible = true;
    },
    setFightEncounter(state) {
      state.combatLog = [];

      state.prevState = state.gameState;
      state.gameState = Constants.gamemodes.combat;

      // state.characters[state.currentCharacter].stats.turns -= 1;

      if (!state.currentEncounter.playerHasFirstShot) {
        state.combatLog.push(
          state.highlight(
            "Because of " +
              state.currentEncounter.mob.name +
              "'s speed, they get the first hit.",
            true
          )
        );
        state.takeCombatDamage();
      } else {
        state.combatLog.push(
          state.highlight("Because of your speed, you get the first hit!")
        );
      }
    },
    unequipItem(state) {
      let targetSlot = state.hoveredItem.type;

      let equipmentId = state.hoveredItem.id;

      let currentAccount = state.account;
      let sessionId = state.getSessionId();
      let number = state.characters[state.currentCharacter].number;
      state.isUnEquipping = true;

      apiConnector
        .callUnequipitem(
          currentAccount,
          sessionId,
          number,
          targetSlot,
          equipmentId
        )
        .then((result) => {
          if (!result.success) {
            state.isUnEquipping = false;
            state.doNotification(result.message);
            return;
          }
          if (result.success) {
            state.inventory = result.inventory;
            state.characters[state.currentCharacter].equipped = result.equipped;
            state.characters[state.currentCharacter].stats = result.stats;

            state.isItemHovered = false;
            state.isTooltipHovered = false;
            state.hoveredItem = null;
            state.isHoveredMarketItem = false;
            state.isHoveredRepairItem = false;
            state.isHoveredCraftItem = false;
            state.isUnEquipping = false;
          }
        })
        .catch((e) => {
          state.isUnEquipping = false;
          console.log(e);
        });
    },
    unequipAllItem(state) {
      let currentAccount = state.account;
      let sessionId = state.getSessionId();
      let number = state.characters[state.currentCharacter].number;
      state.isUnEquipping = true;

      apiConnector
        .callUnequipAllItems(
          currentAccount,
          sessionId,
          number,
        )
        .then((result) => {
          if (!result.success) {
            state.isUnEquipping = false;
            state.doNotification(result.message);
            return;
          }
          if (result.success) {
            state.inventory = result.inventory;
            state.characters[state.currentCharacter].equipped = result.equipped;
            state.characters[state.currentCharacter].stats = result.stats;

            state.isItemHovered = false;
            state.isTooltipHovered = false;
            state.hoveredItem = null;
            state.isHoveredMarketItem = false;
            state.isHoveredRepairItem = false;
            state.isHoveredCraftItem = false;
            state.isUnEquipping = false;
          }
        })
        .catch((e) => {
          state.isUnEquipping = false;
          console.log(e);
        });
    },
    async equipInvItem(state) {
      let targetSlot = state.hoveredItem.type;

      let equipmentId = state.hoveredItem.id;


      let currentAccount = state.account;
      let sessionId = state.getSessionId();
      let number = state.characters[state.currentCharacter].number;
      state.isEquipping = true;

      apiConnector
        .callEquipitem(
          currentAccount,
          sessionId,
          number,
          targetSlot,
          equipmentId
        )
        .then((result) => {
          if (!result.success) {
            state.isEquipping = false;
            state.doNotification(result.message);
            return;
          }
          if (result.success) {
            state.inventory = result.inventory;
            state.characters[state.currentCharacter].equipped = result.equipped;
            state.characters[state.currentCharacter].stats = result.stats;

            state.isItemHovered = false;
            state.isTooltipHovered = false;
            state.hoveredItem = null;
            state.isHoveredMarketItem = false;
            state.isHoveredRepairItem = false;
            state.isHoveredCraftItem = false;
            state.isEquipping = false;
          }
        })
        .catch((e) => {
          state.isEquipping = false;
          console.log(e);
        });
    },
    async equipInvItemRight(state, slot) {
      let targetSlot = slot ? slot : state.hoveredItem.type;
      if(state.hoveredItem.isTwoHanded){
        targetSlot = Constants.slots.hand1;
      }
      let equipmentId = state.hoveredItem.id;


      let currentAccount = state.account;
      let sessionId = state.getSessionId();
      let number = state.characters[state.currentCharacter].number;
      state.isEquipping = true;

      apiConnector
        .callEquipitem(
          currentAccount,
          sessionId,
          number,
          targetSlot,
          equipmentId
        )
        .then((result) => {
          if (!result.success) {
            state.isEquipping = false;
            state.doNotification(result.message);
            return;
          }
          if (result.success) {
            state.inventory = result.inventory;
            state.characters[state.currentCharacter].equipped = result.equipped;
            state.characters[state.currentCharacter].stats = result.stats;

            state.isItemHovered = false;
            state.isTooltipHovered = false;
            state.hoveredItem = null;
            state.isHoveredMarketItem = false;
            state.isHoveredRepairItem = false;
            state.isHoveredCraftItem = false;
            state.isEquipping = false;
          }
        })
        .catch((e) => {
          state.isEquipping = false;
          console.log(e);
        });
    },

    removeForgeItems(state) {
      for (let f = 0; f < state.forgeItems.length; f++) {
        const forgeSlotItem = state.forgeItems[f];
        if (forgeSlotItem) {
          state.inventory.push(forgeSlotItem);
          state.forgeItems[f] = null;
        }
      }
    },
    sendItemToForge(state, item) {
      let isInSlot = false;

      for (let f = 0; f < state.forgeItems.length; f++) {
        const element = state.forgeItems[f];
        if (!element) {
          state.forgeItems[f] = item;
          isInSlot = true;
          break;
        }
      }

      if (isInSlot) {
        state.inventory = state.inventory.filter((i) => i != item);
      }
    },
    sendItemToCraft(state, item) {
      state.removeCraftResources();
      state.craftResult = null;

      state.craftBlueprintItem = item;
      state.inventory = state.inventory.filter((i) => i != item);

      let number = state.characters[state.currentCharacter].number;
      state.isGettingCraftingSlots = true;
      apiConnector
        .callGetCraftingSlots(
          state.account, 
          state.getSessionId(), 
          number, 
          Constants.apiURLs.craftingSlots,
          item.id,
          state.amountToCraft
          )
        .then(result => {
          state.craftingSlots = result.slots
          console.log(state.craftingSlots);
          state.craftCost = result.craftingCost;
          state.isGettingCraftingSlots = false;
        })
    },
    createRepairContract(state, payload) {
      const blockNumber = payload.blockNumber;
      const repairItem = payload.repairItem;
      const txHash = payload.txHash;
      let number = state.characters[state.currentCharacter].number;

      apiConnector
        .callGetCreateRepair(
          state.account, 
          state.getSessionId(), 
          number, 
          Constants.apiURLs.createRepair,
          blockNumber,
          repairItem,
          txHash
          )
        .then(result => {
          if (result.success) {
            state.doNotification("Repair contract created!");
            state.inventory = result.inventory;
          }
        })
    },
    createCraftContract(state, payload) {
      console.log(payload);
      console.log("in create createCraftContract");
      const blockNumber = payload.blockNumber;
      const craftItem = payload.craftItem;
      let number = state.characters[state.currentCharacter].number;

      apiConnector
        .callGetCreateCraft(
          state.account, 
          state.getSessionId(), 
          number, 
          Constants.apiURLs.createCraft,
          blockNumber,
          craftItem
          )
        .then(result => {
          if (result.success) {
            state.doNotification("Craft contract created!");
            state.inventory = result.inventory;
          }
        })
    },
    sendResourceToCraft(state, slotItemDetails) {
      const slotDetails = slotItemDetails.slot;
        
      if (slotDetails.baseItemId == slotItemDetails.item.baseTypeId || slotDetails.baseItemId == slotItemDetails.item.staticId) {
        if (slotDetails.currentItem == slotItemDetails.item) {
          return;
        } else {
          if (slotDetails.currentItem) {
            state.inventory.push(slotDetails.currentItem);  
          }
          slotDetails.currentItem = slotItemDetails.item;
          state.inventory = state.inventory.filter((i) => i != slotItemDetails.item);
        }
      }
    },
    equipItem(state, targetSlot) {
      const isTwoHanded = state.draggingItem.isTwoHanded;
      const currentCharacter = state.characters[state.currentCharacter];

      let currentAccount = state.account;
      let sessionId = state.getSessionId();
      let number = state.characters[state.currentCharacter].number;
      let equipmentId = state.draggingItem.id;
      state.isEquipping = true;

      apiConnector
        .callEquipitem(currentAccount, sessionId, number, targetSlot,equipmentId)
        .then((result) => {

          if (!result.success) {
            state.isEquipping = false;
            state.doNotification(result.message);
            return;
          }

          if (
            currentCharacter.equipped[targetSlot] &&
            currentCharacter.equipped[targetSlot].id == state.draggingItem.id
          ) {
            state.draggingSource = "";
            state.draggingItem = {};
            return;
          }

          const hand1Slot = currentCharacter.equipped[Constants.slots.hand1];

          if (
            hand1Slot &&
            hand1Slot.isTwoHanded &&
            targetSlot == Constants.slots.hand2
          ) {
            state.inventory.push(hand1Slot);

            delete currentCharacter.equipped[Constants.slots.hand1];
          }

          if (currentCharacter.equipped[targetSlot]) {
            state.inventory.push(currentCharacter.equipped[targetSlot]);
          }

          if (isTwoHanded && currentCharacter.equipped[Constants.slots.hand2]) {
            state.inventory.push(
              currentCharacter.equipped[Constants.slots.hand2]
            );

            delete currentCharacter.equipped[Constants.slots.hand2];
          }

          currentCharacter.equipped[targetSlot] = state.draggingItem;

          switch (state.draggingSource) {
            case "inv":
              state.inventory = state.inventory.filter((element) => {
                return element.id !== state.draggingItem.id;
              });
              break;
            case "char":
              delete state.characters[state.currentCharacter].equipped[
                state.dragFromSlot
              ];
              break;
            default:
              break;
          }

          state.inventory = result.inventory;
          state.characters[state.currentCharacter].equipped = result.equipped;
          state.characters[state.currentCharacter].stats = result.stats;

          state.isItemHovered = false;
          state.isTooltipHovered = false;
          state.hoveredItem = null;
          state.isHoveredMarketItem = false;
          state.isHoveredRepairItem = false;
          state.isHoveredCraftItem = false;
          state.isEquipping = false;

          state.draggingSource = "";
          state.draggingItem = {};
          state.dragFromSlot = "";
        });
    },
    setVolume(state, newVol) {
      state.soundVolume = newVol;
    },
  },

  actions: {
    leaveEncounter({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        apiConnector
          .leaveEncounter(
            payload,
            state.getSessionId(),
            state.characters[state.currentCharacter].number,
            Constants.apiURLs.leaveEncounter
          )
          .then((result) => {
            //state.prevState = state.gameState;
            //state.gameState = Constants.gamemodes.wandering;

            //Need to call a mutate function here to actually alter the state itself
            commit("setWandering");
            //Need to empty combat log, otherwise when a new resource is found, it will have previous history
            state.combatLog = [];
            resolve();
          })
          .catch(function (e) {
            // Handle rejection instead of erroring plus log the exception, plus put it back to wandering state, need to add an error message here
            console.log("Call has failed, please refresh and try again", e);
            console.log(reject);
            resolve();
          });
      });
    },
    leaveResourceEncounter({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        apiConnector
          .leaveEncounter(
            payload,
            state.getSessionId(),
            state.characters[state.currentCharacter].number,
            Constants.apiURLs.leaveEncounter
          )
          .then((result) => {
            //state.prevState = state.gameState;
            //state.gameState = Constants.gamemodes.wandering;

            //Need to call a mutate function here to actually alter the state itself
            // commit("setWandering");
            //Need to empty combat log, otherwise when a new resource is found, it will have previous history
            state.combatLog = [];
            resolve();
          })
          .catch(function (e) {
            // Handle rejection instead of erroring plus log the exception, plus put it back to wandering state, need to add an error message here
            console.log("Call has failed, please refresh and try again", e);
            console.log(reject);
            resolve();
          });
      });
    },
    async updateInventory({ commit, state }) {
      console.log("coming in to update inventory");
      const inventoryResult = await apiConnector.callGetInventory(
        state.account,
        state.getSessionId()
      );
      commit("setInventory", inventoryResult.inventory);
    },
  },
  modules: { chat, characterSelect, marketplace, craft, repair, intro, combat, notifications, stash, rankings, bank, selectedInventory, avvynames, pets, lootChests, guild },
});
