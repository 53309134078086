<template>
  <div
    v-if="type === 'hero'"
    id="hero"
    :disabled="isTurnHappening"
    @click="switchCharacter"
    :class="[
      'character-wrapper hero-container',
      heroWeaponType,
      !isMain ? 'smaller' : 'main',
      isHeroAttacking ? 'attack-hero' : '',
      isTurnHappening ? 'disabled' : '',
      isEnemyDoubleDamage ? 'double-damage' : '',
    ]"
  >
    <span v-if="heroWeaponType === 'magic'" class="magic-ball"></span>
    <div :class="['character', isEnemyAttacking ? ' take-damage' : '']">
      <div class="hp">
        <span class="hp-text"
          >{{ Math.floor(character.stats.hp) }}/{{
            character.stats.totalHp
          }}</span
        >
        <!-- <span class="current">{{ character.stats.hp }}</span>
        <span class="total">{{ character.stats.totalHp }}</span> -->
        <div class="hp-stat-container">
          <span
            class="hp-stat"
            :style="{ width: healthHeroPercentage + '%' }"
            >{{
          }}</span>
        </div>
      </div>
      <div
        :class="[
          'portrait-container',
          enemyAttackingClass,
          isHeroHealing ? 'used-healing' : ' ',
        ]"
      >
        <img
          :class="
            'portrait' +
            (hasLevelUp ? ' levelUp' : '') +
            (isDead ? ' dead' : '')
          "
          :src="character.image"
          :alt="character.number"
        />
        <div :class="'healing' + (isHeroHealing ? ' show' : ' hide')">
          +{{ heroHealAmount }}
        </div>
        <div :class="'damage' + (isEnemyAttacking ? ' show' : ' hide')">
          <template v-if="isEnemyMiss">MISSED</template>
          <template v-else>
            <template v-if="isHeroCriticalDamage">
              - {{ enemyDamage }}
              <span>Critical Hit</span>
            </template>
            <template v-else> - {{ enemyDamage }} </template>
          </template>
        </div>
      </div>

      <div class="meta">
        <span class="text"
          >#{{ character.number }} - Level {{ character.stats.level }}</span
        >
      </div>
      <div class="character-stats-container">
        <div class="character-stats">
          <div class="stats main-stats">
            <h5 class="small-header">Hero Stats</h5>
            <div class="stat-item">
              <span class="stat-name">Attack</span>
              <span class="stat-value">{{ character.stats.attack }}</span>
            </div>
            <div class="stat-item">
              <span class="stat-name">Defense</span>
              <span class="stat-value">{{ character.stats.defense }}</span>
            </div>
            <div class="stat-item">
              <span class="stat-name">Speed</span>
              <span class="stat-value">{{ character.stats.speed }}</span>
            </div>
            <div class="stat-item">
              <span class="stat-name">Endurance</span>
              <span class="stat-value">{{ character.stats.endurance }}</span>
            </div>
            <div class="stat-item">
              <span class="stat-name">Luck</span>
              <span class="stat-value">{{ character.stats.luck }}</span>
            </div>
            <div class="stat-item">
              <span class="stat-name">Magic</span>
              <span class="stat-value">{{ character.stats.magic }}</span>
            </div>
          </div>
          <div class="stats misc-stats">
            <h5 class="small-header">Misc Stats</h5>
            <div class="stat-item">
              <span class="stat-name">Crafting</span>
              <span class="stat-value">{{ character.stats.crafting }}</span>
            </div>
            <div class="stat-item">
              <span class="stat-name">Gathering</span>
              <span class="stat-value">{{ character.stats.gathering }}</span>
            </div>
            <div class="stat-item">
              <span class="stat-name">Critical Hit Chance</span>
              <span class="stat-value">{{
                character.stats.criticalChance
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="type === 'enemy'"
    id="enemy"
    class=""
    :class="[
      'character-wrapper enemy-container',
      enemyWeaponType,
      isEnemyAttacking ? 'attack-enemy' : '',
    ]"
  >
    <span v-if="enemyWeaponType === 'magic'" class="magic-ball"></span>
    <div
      :class="[
        'encounter-status',
        isHeroAttacking || isPetAttacking ? ' take-damage' : '',
      ]"
    >
      <div class="hp">
        <span class="hp-text"
          >{{ enemyStats.currentHp }} / {{ enemyStats.totalHp }}</span
        >
        <!-- <span class="current">{{ enemyStats.currentHp }}</span>
        <span class="total">{{ enemyStats.totalHp }}</span> -->
        <div class="hp-stat-container">
          <span
            class="hp-stat"
            :style="{ width: healthEnemyPercentage + '%' }"
          ></span>
        </div>
      </div>
      <div
        :class="['portrait-container', heroAttackingClass, petAttackingClass]"
      >
        <img
          :src="
            enemy.isDead
              ? 'https://cdn.dragoncrypto.io/icons/Priestskill_39_nobg.png'
              : 'https://cdn.dragoncrypto.io/encounters/' +
                enemy.mob.portrait +
                '.png'
          "
          alt="Battle"
        />
        <div
          :class="
            'damage' + (isHeroAttacking || isPetAttacking ? ' show' : ' hide')
          "
        >
          <template v-if="isHeroMiss">MISSED</template>
          <template v-else>
            <template
              v-if="
                isEnemyCriticalDamage ||
                isEnemyDoubleDamage ||
                isEnemyEffectiveness ||
                isEnemyReistance
              "
            >
              -{{ heroDamage }}
              <span v-if="isEnemyCriticalDamage">Critical Hit</span>
              <span v-if="isEnemyDoubleDamage">Double Hit</span>
              <span class="effectiveness" v-if="isEnemyEffectiveness">{{
                enemyEffectivenessMessage
              }}</span>
              <span class="resistance" v-if="isEnemyReistance">{{
                enemyResistanceMessage
              }}</span>
            </template>
            <template v-else> -{{ heroDamage }} </template>
          </template>
        </div>
      </div>
      <span class="name"
        >{{ enemyName }} The {{ enemySuffix }} - Level
        {{ enemy.stats.level }}</span
      >
      <div class="character-stats-container">
        <div class="character-stats">
          <div class="stats main-stats">
            <h5 class="small-header">Enemy Stats</h5>
            <div class="stat-item">
              <span class="stat-name">Attack</span>
              <span class="stat-value">{{ rounded(enemy.stats.attack) }}</span>
            </div>
            <div class="stat-item">
              <span class="stat-name">Defense</span>
              <span class="stat-value">{{ rounded(enemy.stats.defense) }}</span>
            </div>
            <div class="stat-item">
              <span class="stat-name">Speed</span>
              <span class="stat-value">{{ rounded(enemy.stats.speed) }}</span>
            </div>
            <div class="stat-item">
              <span class="stat-name">Endurance</span>
              <span class="stat-value">{{
                rounded(enemy.stats.endurance)
              }}</span>
            </div>
          </div>
          <div class="stats main-stats">
            <h5 class="small-header">Weapon</h5>
            <p class="weapon">{{ enemy.mob.weapon }}</p>
          </div>
        </div>
        <div class="attributes">
          <img
            class="attribute element"
            v-if="enemy.mob.element"
            :src="`https://cdn.dragoncrypto.io/elements/${enemy.mob.element}.png`"
            :alt="enemy.mob.element"
            :title="`Element: ${enemy.mob.element}`"
          />
          <img
            class="attribute armour"
            v-if="enemy.mob.armourType"
            :src="`https://cdn.dragoncrypto.io/armourtypes/${enemy.mob.armourType}.png`"
            :alt="enemy.mob.armourType"
            :title="`Armour: ${enemy.mob.armourType}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Constants from "../../consts/constants";

export default {
  name: "CombatCharacter",
  props: {
    character: {},
    enemy: {},
    type: {
      type: String,
    },
  },
  data() {
    return { $store: {}, socket: {} };
  },
  methods: {
    rounded(num) {
      return Math.round(num);
    },
  },
  computed: {
    petAttackingClass() {
      if (this.isPetAttacking) {
        return "blade-hit";
      } else {
        return "";
      }
    },
    heroAttackingClass() {
      if (this.isHeroAttacking) {
        if (this.isAbilityAttack) {
          return "ability-hit";
        } else if (this.heroWeaponType === Constants.weaponType.magic) {
          return "magic-hit";
        } else if (
          this.heroWeaponType === Constants.weaponType.blade ||
          this.heroWeaponType === Constants.weaponType.poleArm
        ) {
          return "blade-hit";
        }
        return "critical-hit";
      } else {
        return "";
      }
    },
    enemyAttackingClass() {
      if (this.isEnemyAttacking) {
        if (this.enemyWeaponType === Constants.weaponType.magic) {
          return "magic-hit";
        } else if (
          this.enemyWeaponType === Constants.weaponType.blade ||
          this.enemyWeaponType === Constants.weaponType.poleArm
        ) {
          return "blade-hit";
        }
        return "critical-hit";
      } else {
        return "";
      }
    },
    isHeroHealing() {
      return this.$store.state.combat.isHeroHealing;
    },
    heroHealAmount() {
      return this.$store.state.combat.heroHealAmount;
    },
    isPetAttacking() {
      return this.$store.state.combat.isPetAttacking;
    },
    isHeroAttacking() {
      return this.$store.state.combat.isHeroAttacking;
    },
    isAbilityAttack() {
      return (
        this.$store.state.combat.isAbilityAttack &&
        this.$store.state.combat.isHeroAttacking
      );
    },
    isEnemyAttacking() {
      return this.$store.state.combat.isEnemyAttacking;
    },
    heroDamage() {
      return this.$store.state.combat.heroDamage;
    },
    enemyDamage() {
      return this.$store.state.combat.enemyDamage;
    },
    isHeroMiss() {
      return this.$store.state.combat.isHeroMiss;
    },
    isEnemyMiss() {
      return this.$store.state.combat.isEnemyMiss;
    },
    isHeroCriticalDamage() {
      return this.$store.state.combat.isHeroCritical;
    },
    isEnemyCriticalDamage() {
      return this.$store.state.combat.isEnemyCritical;
    },
    isEnemyDoubleDamage() {
      return this.$store.state.combat.isEnemyDoubleHit;
    },
    isEnemyEffectiveness() {
      return this.$store.state.combat.isEnemyEffectiveness;
    },
    isEnemyReistance() {
      return this.$store.state.combat.isEnemyReistance;
    },
    enemyEffectivenessMessage() {
      return this.$store.state.combat.enemyEffectivenessMessage;
    },
    enemyResistanceMessage() {
      return this.$store.state.combat.enemyResistanceMessage;
    },
    isAttacking() {
      let attackClass = "";
      // if (this.$store.state.isTurnHappening) {
      //   if (this.type === "hero") {
      //     attackClass = "attack-hero";
      //   } else if (this.type === "enemy") {
      //     attackClass = "attack-enemy";
      //   }
      // }
      return attackClass;
    },
    healthHeroPercentage() {
      return (this.character.stats.hp / this.character.stats.totalHp) * 100;
    },
    turnsPercentage() {
      return (
        (this.character.stats.turns / this.character.stats.totalTurns) * 100
      );
    },
    hasLevelUp() {
      return this.character.stats.levelStatPoints > 0;
    },
    isMain() {
      return this.character.id == this.$store.state.currentCharacterId;
    },
    isDead() {
      return this.character.stats.isDead;
    },
    // Enemy Methods
    healthEnemyPercentage() {
      return (this.enemyStats.currentHp / this.enemyStats.totalHp) * 100;
    },
    isEnemyDead() {
      return this.$store.state.currentEncounter.isDead;
    },
    heroWeaponType() {
      return this.$store.state.combat.weaponTypeHero;
    },
    enemyWeaponType() {
      return this.$store.state.combat.weaponTypeEnemy;
    },
    enemyName() {
      return this.$store.state.currentEncounter.mob.name;
    },
    enemyIntro() {
      return this.$store.state.currentEncounter.mob.intro;
    },
    enemyWeapon() {
      return this.$store.state.currentEncounter.mob.weapon;
    },
    enemySuffix() {
      return this.$store.state.currentEncounter.suffix;
    },
    enemyStats() {
      return this.$store.state.currentEncounter.stats;
    },
    isTurnHappening() {
      if (
        this.$store.state.isTurnHappening ||
        this.$store.state.isUsingConsumable ||
        this.$store.state.isFleeing ||
        this.$store.state.combat.isHeroAttacking ||
        this.$store.state.combat.isEnemyAttacking ||
        this.$store.state.combat.isPetAttacking
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
@import "./combat-animations.scss";

/* $characterMoveSpeed: 1s;
$animateDelay: 0.5s;
$arrowMoveSpeed: 1s; */
$characterMoveSpeed: 0.5s;
$animateDelay: 0.5s;
$arrowMoveSpeed: 0.5s;

.character-wrapper {
  position: relative;
  .encounter-status,
  .character {
    display: grid;
    align-items: center;
    justify-items: center;
  }
  .total,
  .current,
  .hp-text {
    font-size: 0.9rem;
    text-shadow: 0 0 2px #000;
    z-index: 1;
    position: absolute;
  }
  .meta {
    .text {
      min-height: 38px;
      display: block;
    }
  }
  .hp-text {
    display: block;
    left: 50%;
    transform: translateX(-50%);
    @media only screen and (max-width: 576px) {
      top: -20px;
      width: 100%;
      font-size: 0.8rem;
    }
  }
  &.hero-container {
    .current {
      left: 32px;
    }
    .total {
      right: 32px;
    }
  }
  .hp {
    width: 273px;
    height: 28px;
    background: #161411;
    display: block;
    position: relative;
    background: url("https://cdn.dragoncrypto.io/uiassets/health-bar.png")
      center center;
    background-size: contain;
    background-repeat: no-repeat;
    display: grid;
    align-items: center;
    padding: 0 22px;
    margin: 0 0 215px;
    @media only screen and (max-width: 576px) {
      margin: 0 0 120px;
      width: 100px;
    }
    .hp-stat-container {
      width: 260px;
      height: 12px;
      position: absolute;
      left: 29px;
      @media only screen and (max-width: 576px) {
        width: 100px;
        height: 6px;
        left: 8px;
      }
    }
    .hp-stat {
      background-color: #a43908;
      position: absolute;
      height: 100%;
      left: 0;
      box-shadow: inset 0 0 10px #000000e6;
      transition: width 0.5s linear;
    }
  }
  &.enemy-container {
    .hp {
      .hp-stat-container {
        right: 29px;
        left: auto;
        @media only screen and (max-width: 576px) {
          right: 8px;
        }
      }
      .hp-stat {
        background-color: red;
        right: 0;
        left: auto;
      }
    }
    .current {
      right: 32px;
    }
    .total {
      left: 32px;
    }
  }
  .portrait-container {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    display: grid;
    align-items: center;
    justify-items: center;
    background: #311a0b;
    top: 37px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s ease-in-out;
    @media only screen and (max-width: 576px) {
      width: 100px;
      height: 100px;
    }
    &:after {
      background: url("https://cdn.dragoncrypto.io/uiassets/character-frame.png")
        center center no-repeat;
      background-size: cover;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    img {
      width: 90%;
    }
    &.magic-hit {
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 100%;
        top: 0;
        left: 0;
        animation: magicHit 1.5s linear;
        animation-delay: $animateDelay;
      }
    }
    &.critical-hit {
      &:before {
        content: "";
        background: url("https://cdn.dragoncrypto.io/uiassets/blood-splatter.png")
          center center no-repeat;
        background-color: rgba(255, 0, 0, 0.419);
        mix-blend-mode: darken;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        animation: bloodSplatter 1.5s ease-in-out;
        animation-delay: $animateDelay;
      }
    }
    &.ability-hit {
      &:before {
        content: "";
        background: url("../../assets/ui/electricity.png") center center
          no-repeat;
        background-color: rgba(162, 0, 255, 0.419);
        /* mix-blend-mode: darken; */
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        animation: energyUse 1.5s ease-in-out;
        animation-delay: $animateDelay;
      }
    }
    &.blade-hit {
      &:before {
        content: "";
        background: url("../../assets/ui/slash.webp") center center no-repeat;
        background-color: rgba(255, 0, 0, 0.419);
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        animation: bloodSplatter 1.5s ease-in-out;
        animation-delay: $animateDelay;
      }
    }
    &.used-healing {
      &:before {
        content: "";
        background-color: green;
        mix-blend-mode: soft-light;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        animation: bloodSplatter 1.5s ease-in-out;
      }
    }
  }
  .damage,
  .healing {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 0 0 2px #000, 0 2px 3px #000, 0 2px 3px #000;
    font-size: 2.5rem;
    @media only screen and (max-width: 576px) {
      font-size: 1.5rem;
    }
  }
  .healing {
    &.hide {
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
    }
    &.show {
      transition: opacity 0.3s ease-in-out;
      animation: fadeUp 2s;
    }
  }
  .damage {
    opacity: 0;
    span {
      font-size: 1.2rem;
      display: block;
      text-align: center;
      color: rgba(255, 254, 254, 0.887);
      text-shadow: 0 0 2px #000, 0 2px 3px #000, 0 2px 3px #000;
    }
    .effectiveness,
    .resistance {
      text-transform: capitalize;
      font-size: 0.8rem;
    }
    .effectiveness {
      color: $uncommon;
    }
    .resistance {
      color: $rare;
    }
    &.hide {
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
    }
    &.show {
      // transition: opacity 0.3s ease-in-out;
      animation: fadeUp 3s;
      animation-delay: $animateDelay;
    }
  }
  .damage {
    color: $dmg;
  }
  .healing {
    color: $uncommon;
  }
  .take-damage {
    animation: shakeCombat 0.55s;
    animation-delay: $animateDelay;
  }

  .character-stats-container {
    width: 250px;
    min-height: 198px;
    grid-gap: 0.5rem;
    align-items: self-start;
    background-color: #151111;
    padding: 0.5rem;
    border: 5px solid #ffffff1c;
    box-shadow: 0 0 5px #000;
    border-radius: 2px;
    position: relative;
    margin: 10px 0;
    @media only screen and (max-width: 576px) {
      display: none;
    }
    h5.small-header {
      height: 30px;
      background: url("https://cdn.dragoncrypto.io/uiassets/small-header-bg.webp")
        repeat center center;
      display: grid;
      align-items: center;
      box-shadow: inset 0 0 10px 0 #000;
      border: 2px #4e4134 solid;
      margin: 0 0 5px;
      color: #be9177;
      font-family: "Lato", sans-serif;
      text-transform: capitalize;
      font-weight: bold;
      text-shadow: 0 0 2px #000;
    }
    .attributes {
      display: grid;
      grid-template-columns: repeat(auto-fit, 32px);
      grid-gap: 10px;
      width: 100%;
      justify-items: self-start;
      padding: 10px 0;
      .attribute {
        width: 32px;
        height: 32px;
        border-radius: 5px;
        &.armour {
          background-color: rgba(0, 0, 0, 0.507);
        }
      }
    }
    .character-stats {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      margin-top: 10px;

      @media only screen and (max-width: 576px) {
        display: none;
      }
      .stats {
        width: 113px;
        padding: 0 5px;
        .weapon {
          text-transform: capitalize;
        }
      }
    }
    .stat-item {
      display: grid;
      grid-template-columns: 80% 1fr;
      grid-gap: 5px;
      align-items: center;
      margin: 5px;
      align-items: center;
      padding: 0 3px 3px;
      font-family: "Lato", sans-serif;
      color: #8a796e;
      border-bottom: 1px solid rgba(255, 255, 255, 0.143);
      font-size: 0.8rem;
      &:last-child {
        border-bottom: none;
      }
      .stat-name {
        text-align: left;
      }
      .stat-value {
        text-align: right;
        color: #fff;
      }
    }
    .meta-card {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 5px;
      .hero-details {
        span {
          display: block;
        }
      }
      .bars {
        position: relative;
        .hp {
          width: 100%;
          height: 18px;
          position: absolute;
          left: 0;
          top: 3px;
        }

        .turns {
          position: absolute;
          width: 100%;
          height: 18px;
          left: 0;
          top: 22px;
        }

        .turns-stat,
        .hp-stat {
          font-size: 70%;
          top: -1px;
          left: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 2px #000;
          font-weight: bold;
          line-height: 18px;
        }

        .hp-bar {
          background: url("https://cdn.dragoncrypto.io/uiassets/selected-player-hpbar.png")
            no-repeat top left;
        }

        .turns-bar {
          background: url("https://cdn.dragoncrypto.io/uiassets/selected-player-turnbar.png")
            no-repeat top left;
        }

        .hp-bar,
        .turns-bar {
          position: absolute;
          top: 3px;
          left: -3px;
          width: 100%;
          height: 13px;
          transition: width 0.35s ease-out;
          background-size: cover;
        }
      }
    }
  }
}
.attack-hero {
  &.closeCombat,
  &.axe,
  &.heavy,
  &.blade {
    .portrait-container {
      animation: attackHero $characterMoveSpeed;
      z-index: 5;
      @media only screen and (max-width: 576px) {
        animation: attackHeroMobile $characterMoveSpeed;
      }
      @media only screen and (min-width: 3500px) {
        animation: attackHero4k $characterMoveSpeed;
      }
    }
    &.double-damage {
      .portrait-container {
        animation: attackHeroDouble $characterMoveSpeed;
        z-index: 5;
        @media only screen and (max-width: 576px) {
          animation: attackHeroMobileDouble $characterMoveSpeed;
        }
        @media only screen and (min-width: 3500px) {
          animation: attackHero4kDouble $characterMoveSpeed;
        }
      }
    }
  }
  &.bow {
    &:before {
      content: "";
      background-image: url("../../assets/icons/arrow.webp");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      width: 220px;
      height: 39px;
      display: block;
      display: block;
      top: 143px;
      position: absolute;
      animation: shootArrow $arrowMoveSpeed cubic-bezier(0.25, 1, 0.5, 1) 0s
        forwards;
      @media only screen and (max-width: 576px) {
        animation: shootArrowMobile $arrowMoveSpeed
          cubic-bezier(0.25, 1, 0.5, 1) 0s forwards;
      }
      @media only screen and (min-width: 3500px) {
        animation: shootArrow4k $arrowMoveSpeed cubic-bezier(0.25, 1, 0.5, 1) 0s
          forwards;
      }
      z-index: 5;
      left: 0;
      @media only screen and (max-width: 576px) {
        width: 103px;
        height: 47px;
        top: 45px;
        animation: shootArrowMobile $arrowMoveSpeed
          cubic-bezier(0.25, 1, 0.5, 1) 0s forwards;
      }
    }
    &.double-damage {
      &:before {
        animation: shootArrow calc($arrowMoveSpeed / 3)
          cubic-bezier(0.25, 1, 0.5, 1) 0s 2 forwards;
        @media only screen and (max-width: 576px) {
          animation: shootArrowMobile calc($arrowMoveSpeed / 3)
            cubic-bezier(0.25, 1, 0.5, 1) 0s 2 forwards;
        }
        @media only screen and (min-width: 3500px) {
          animation: shootArrow4k calc($arrowMoveSpeed / 3)
            cubic-bezier(0.25, 1, 0.5, 1) 0s 2 forwards;
        }
        @media only screen and (max-width: 576px) {
          animation: shootArrowMobile calc($arrowMoveSpeed / 3)
            cubic-bezier(0.25, 1, 0.5, 1) 0s 2 forwards;
        }
      }
    }
  }
  &.magic {
    .magic-ball {
      width: 100px;
      height: 86px;
      display: block;
      top: 112px;
      left: 60%;
      position: absolute;
      z-index: 5;
      animation: shootMagic $arrowMoveSpeed ease-in-out 0s forwards;
      @media only screen and (max-width: 576px) {
        animation: shootMagicMobile $arrowMoveSpeed ease-in-out 0s forwards;
        top: 55px;
        left: 40%;
      }
      @media only screen and (min-width: 3500px) {
        animation: shootMagic4k $arrowMoveSpeed ease-in-out 0s forwards;
      }
      &:before {
        content: "";
        background-image: url("../../assets/icons/magic.webp");
        width: 100px;
        height: 86px;
        position: absolute;
        background-size: contain;
        left: 0;
        top: 0;
        z-index: 2;
      }
      &:after {
        content: "";
        display: block;
        box-shadow: 0 0 25px 20px #ea93c0;
        width: 20px;
        height: 20px;
        top: 30px;
        position: absolute;
        background: #fffeff;
        border-radius: 100%;
        left: 46px;
        animation: pulse 500ms ease-in-out infinite;
      }
    }
    &.double-damage {
      .magic-ball {
        animation: shootMagic calc($arrowMoveSpeed/3) ease-in-out 0s 2 forwards;
        @media only screen and (max-width: 576px) {
          animation: shootMagicMobile calc($arrowMoveSpeed/3) ease-in-out 0s 2
            forwards;
          top: 55px;
          left: 40%;
        }
        @media only screen and (min-width: 3500px) {
          animation: shootMagic4k calc($arrowMoveSpeed/3) ease-in-out 0s 2
            forwards;
        }
      }
    }
  }
  &.pistol {
    &:before {
      content: "";
      background-image: url("../../assets/icons/bullet.webp");
      background-size: contain;
      width: 25px;
      height: 24px;
      display: block;
      top: 112px;
      left: 60%;
      position: absolute;
      z-index: 5;
      animation: shootBullet $arrowMoveSpeed 0s cubic-bezier(0.25, 1, 0.5, 1)
        forwards;
      @media only screen and (max-width: 576px) {
        animation: shootBulletMobile $arrowMoveSpeed 0s
          cubic-bezier(0.25, 1, 0.5, 1) forwards;
        top: 70px;
        width: 15px;
        height: 14px;
      }
      @media only screen and (min-width: 3500px) {
        animation: shootBullet4k $arrowMoveSpeed 0s
          cubic-bezier(0.25, 1, 0.5, 1) forwards;
      }
    }
    &.double-damage {
      &:before {
        animation: shootBullet calc($arrowMoveSpeed/3) 0s
          cubic-bezier(0.25, 1, 0.5, 1) 2 forwards;
        @media only screen and (max-width: 576px) {
          animation: shootBulletMobile calc($arrowMoveSpeed/3) 0s
            cubic-bezier(0.25, 1, 0.5, 1) 2 forwards;
        }
        @media only screen and (min-width: 3500px) {
          animation: shootBullet4k calc($arrowMoveSpeed/3) 0s
            cubic-bezier(0.25, 1, 0.5, 1) 2 forwards;
        }
      }
    }
  }
  &.rifle {
    &:before {
      content: "";
      background-image: url("../../assets/icons/bullet.webp");
      background-size: contain;
      width: 40px;
      height: 39px;
      display: block;
      top: 112px;
      left: 60%;
      position: absolute;
      z-index: 5;
      animation: shootBullet $arrowMoveSpeed 0s cubic-bezier(0.25, 1, 0.5, 1)
        forwards;
      @media only screen and (max-width: 576px) {
        animation: shootBulletMobile $arrowMoveSpeed 0s
          cubic-bezier(0.25, 1, 0.5, 1) forwards;
        top: 70px;
        width: 25px;
        height: 24px;
      }
      @media only screen and (min-width: 3500px) {
        animation: shootBullet4k $arrowMoveSpeed 0s
          cubic-bezier(0.25, 1, 0.5, 1) forwards;
      }
    }
    &.double-damage {
      &:before {
        animation: shootBullet calc($arrowMoveSpeed / 3) 0s
          cubic-bezier(0.25, 1, 0.5, 1) 2 forwards;
        @media only screen and (max-width: 576px) {
          animation: shootBulletMobile calc($arrowMoveSpeed / 3) 0s
            cubic-bezier(0.25, 1, 0.5, 1) 2 forwards;
          top: 70px;
          width: 25px;
          height: 24px;
        }
        @media only screen and (min-width: 3500px) {
          animation: shootBullet4k calc($arrowMoveSpeed / 3) 0s
            cubic-bezier(0.25, 1, 0.5, 1) 2 forwards;
        }
      }
    }
  }
  &.polearm {
    .portrait-container {
      animation: usePoleArm $characterMoveSpeed;
      z-index: 5;
    }
    &.double-damage {
      .portrait-container {
        animation: usePoleArm calc($characterMoveSpeed / 3) 2 forwards;
      }
    }
  }
}
.disabled {
  cursor: not-allowed;
}
.attack-enemy {
  &.closeCombat,
  &.axe,
  &.heavy,
  &.blade {
    .portrait-container {
      animation: attackEnemy $characterMoveSpeed;
      z-index: 5;
      @media only screen and (max-width: 576px) {
        animation: attackEnemyMobile $characterMoveSpeed;
      }
      @media only screen and (min-width: 3500px) {
        animation: attackEnemy4k $characterMoveSpeed;
      }
    }
  }
  &.bow {
    &:before {
      content: "";
      background-image: url("../../assets/icons/arrow-flipped.webp");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      width: 220px;
      height: 39px;
      display: block;
      display: block;
      top: 143px;
      position: absolute;
      animation: shootArrowEnemy $arrowMoveSpeed cubic-bezier(0.25, 1, 0.5, 1)
        0s forwards;
      @media only screen and (max-width: 576px) {
        animation: shootArrowEnemyMobile $arrowMoveSpeed
          cubic-bezier(0.25, 1, 0.5, 1) 0s forwards;
      }
      @media only screen and (min-width: 3500px) {
        animation: shootArrowEnemy4k $arrowMoveSpeed
          cubic-bezier(0.25, 1, 0.5, 1) 0s forwards;
      }
      z-index: 5;
      right: 0;
      @media only screen and (max-width: 576px) {
        width: 103px;
        height: 47px;
        top: 45px;
        animation: shootArrowEnemyMobile $arrowMoveSpeed
          cubic-bezier(0.25, 1, 0.5, 1) 0s forwards;
      }
    }
  }
  &.magic {
    .magic-ball {
      width: 100px;
      height: 86px;
      display: block;
      top: 112px;
      right: 60%;
      position: absolute;
      z-index: 5;
      animation: shootMagicEnemy $arrowMoveSpeed ease-in-out 0s forwards;
      @media only screen and (max-width: 576px) {
        animation: shootMagicEnemyMobile $arrowMoveSpeed ease-in-out 0s forwards;
        top: 55px;
        right: 40%;
      }
      @media only screen and (min-width: 3500px) {
        animation: shootMagicEnemy4k $arrowMoveSpeed ease-in-out 0s forwards;
      }
      &:before {
        content: "";
        background-image: url("../../assets/icons/magic-flipped.webp");
        width: 100px;
        height: 86px;
        position: absolute;
        background-size: contain;
        left: 0;
        top: 0;
        z-index: 2;
      }
      &:after {
        content: "";
        display: block;
        box-shadow: 0 0 25px 20px #ea93c0;
        width: 20px;
        height: 20px;
        top: 30px;
        position: absolute;
        background: #fffeff;
        border-radius: 100%;
        left: 46px;
        animation: pulse 500ms ease-in-out infinite;
      }
    }
  }
  &.pistol {
    &:before {
      content: "";
      background-image: url("../../assets/icons/bullet.webp");
      background-size: contain;
      width: 25px;
      height: 24px;
      display: block;
      top: 112px;
      right: 60%;
      position: absolute;
      z-index: 5;
      animation: shootBulletEnemy $arrowMoveSpeed 0s
        cubic-bezier(0.25, 1, 0.5, 1) forwards;
      @media only screen and (max-width: 576px) {
        animation: shootBulletEnemyMobile $arrowMoveSpeed 0s
          cubic-bezier(0.25, 1, 0.5, 1) forwards;
        top: 70px;
        width: 15px;
        height: 14px;
      }
      @media only screen and (min-width: 3500px) {
        animation: shootBulletEnemy4k $arrowMoveSpeed 0s
          cubic-bezier(0.25, 1, 0.5, 1) forwards;
      }
    }
  }
  &.rifle {
    &:before {
      content: "";
      background-image: url("../../assets/icons/bullet.webp");
      background-size: contain;
      width: 40px;
      height: 39px;
      display: block;
      top: 112px;
      right: 60%;
      position: absolute;
      z-index: 5;
      animation: shootBulletEnemy $arrowMoveSpeed 0s
        cubic-bezier(0.25, 1, 0.5, 1) forwards;
      @media only screen and (max-width: 576px) {
        animation: shootBulletEnemyMobile $arrowMoveSpeed 0s
          cubic-bezier(0.25, 1, 0.5, 1) forwards;
        top: 70px;
        width: 25px;
        height: 24px;
      }
      @media only screen and (min-width: 3500px) {
        animation: shootBulletEnemy4k $arrowMoveSpeed 0s
          cubic-bezier(0.25, 1, 0.5, 1) forwards;
      }
    }
  }
  &.polearm {
    .portrait-container {
      animation: usePoleArmEnemy $characterMoveSpeed;
      z-index: 5;
    }
  }
}
</style>
