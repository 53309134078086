<template>
  <div
    @click="switchCharacter"
    :class="[
      'character-wrapper',
      !isMain ? 'smaller' : 'main',
      isTurnHappening ? 'disabled' : '',
    ]"
    :disabled="isTurnHappening"
  >
    <div
      :id="isMain ? 'character' : ''"
      :class="isMain ? 'character' : 'mini-character'"
    >
      <span v-if="isMain" class="text"
        >#{{ character.number }} Level - {{ character.stats.level }}</span
      >
      <div :class="['portrait-container', isDead ? ' dead' : '']">
        <img
          :class="
            'portrait' +
            (hasLevelUp ? ' levelUp' : '') +
            (isDead ? ' dead' : '')
          "
          :src="character.image"
          :alt="character.number"
        />

        <svg v-if="!isMain" class="exp-circle">
          <circle cx="25" cy="25" r="22"></circle>
          <circle
            cx="25"
            cy="25"
            r="22"
            :style="`--percent: ${expPercentage}`"
          ></circle>
        </svg>
      </div>
      <div class="meta">
        <span v-if="!isMain" class="text"
          >#{{ character.number }} Level - {{ character.stats.level }}</span
        >
        <div
          id="hp-bar-container"
          class="hp"
          :title="healthPercentage + '% of HP remaining'"
        >
          <div class="hp-bar" :style="{ width: healthPercentage + '%' }"></div>
          <span class="hp-stat"
            >{{ Math.floor(character.stats.hp) }}/{{
              character.stats.totalHp
            }}</span
          >
        </div>
        <div class="turns">
          <div
            class="turns-bar"
            :style="{ width: turnsPercentage + '%' }"
          ></div>
          <span id="turns-bar" class="turns-stat"
            >{{ character.stats.turns }}/{{ character.stats.totalTurns }}</span
          >
        </div>
      </div>
    </div>
    <div :class="'healing' + (character.isHealing ? ' show' : ' hide')">
      +{{ character.healAmount }}
    </div>
    <!-- <div :class="'damage' + (character.isTakingDamage ? ' show' : ' hide')">
      -{{ character.damageTaken }}
    </div> -->
    <template v-if="isMain && hasIp">
      <span
        @click="openGuild"
        v-if="typeOfGuild != ''"
        :class="['guild-type', typeOfGuild]"
        >{{ typeOfGuild }}</span
      >
    </template>
  </div>
</template>

<script>
import { useStore } from "vuex";
import constants from "../consts/constants";

export default {
  name: "Character",
  props: {
    character: {},
  },
  data() {
    return { $store: {} };
  },
  methods: {
    async switchCharacter() {
      if (this.isGatheringStart || this.isGathering) {
        this.$store.state.doNotification(
          "You can't switch characters while gathering resources!"
        );
        return;
      }

      if (!this.isMain) {
        this.$store.commit("setTurnHappening", true);

        if (this.$store.state.gameState !== constants.gamemodes.combat) {
          const characterIndex =
            this.$store.state.characterSelect.selectedCharacters.findIndex(
              (element) => element.id == this.character.id
            );

          var newMainChar =
            this.$store.state.characterSelect.selectedCharacters[
              characterIndex
            ];
          var charBeingReplaced =
            this.$store.state.characterSelect.selectedCharacters[0];

          this.$store.state.characterSelect.selectedCharacters[characterIndex] =
            charBeingReplaced;
          this.$store.state.characterSelect.selectedCharacters[0] = newMainChar;

          localStorage.setItem(
            "selectedCharacters",
            JSON.stringify(
              this.$store.state.characterSelect.selectedCharacters,
              ["id"]
            )
          );

          this.$store.commit(
            "setCurrentCharacterByNumber",
            this.character.number
          );

          this.$store.commit("setCurrentCharacter", this.character.id);
        } else {
          this.$store.commit("setCurrentCharacterCombat", this.character.id);
          // this.$store.commit(
          //   "setCurrentCharacterByNumber",
          //   this.character.number
          // );
          // this.$store.commit("setCurrentCharacter", this.character.id);
        }
        setTimeout(() => {
          this.$store.commit("setTurnHappening", false);
        }, 100);
      }
    },
    openGuild() {
      if (this.typeOfGuild === "defenders") {
        this.openDefendersGuild();
      } else if (this.typeOfGuild === "crafters") {
        this.openCraftersQuests();
      }
    },
    openDefendersGuild() {
      this.$store.commit("guild/setIsGuildOpen", true); // Hiding in mobile view as well
      this.$store.commit("guild/setGuildType", "defenders"); // Hiding in mobile view as well
    },
    openCraftersQuests() {
      this.$store.commit("guild/setIsGuildOpen", true); // Hiding in mobile view as well
      this.$store.commit("guild/setGuildType", "crafters"); // Hiding in mobile view as well
    },
  },
  computed: {
    healthPercentage() {
      return (this.character.stats.hp / this.character.stats.totalHp) * 100;
    },
    turnsPercentage() {
      return (
        (this.character.stats.turns / this.character.stats.totalTurns) * 100
      );
    },
    hasLevelUp() {
      return this.character.stats.levelStatPoints > 0;
    },
    isGatheringStart() {
      return this.$store.state.gameState == constants.gamemodes.gatheringStart;
    },
    isGathering() {
      return this.$store.state.gameState == constants.gamemodes.gathering;
    },
    isMain() {
      return this.character.id == this.$store.state.currentCharacterId;
    },
    typeOfGuild() {
      if (this.hasIp) {
        if (
          this.character.stats.influencePoints.craftersGuild >
          this.character.stats.influencePoints.defendersGuild
        ) {
          return "crafters";
        } else if (
          this.character.stats.influencePoints.craftersGuild <
          this.character.stats.influencePoints.defendersGuild
        ) {
          return "defenders";
        }
      }
      return "";
    },
    hasIp() {
      if (this.character.stats.influencePoints !== undefined) {
        return true;
      }
      return false;
    },
    expPercentage() {
      return (
        (this.character.stats.exp / this.character.stats.expNextLevel) * 100
      );
    },
    isDead() {
      return this.character.stats.isDead;
    },
    isTurnHappening() {
      if (
        this.$store.state.isTurnHappening ||
        this.$store.state.isUsingConsumable ||
        this.$store.state.isFleeing ||
        this.$store.state.combat.isHeroAttacking ||
        this.$store.state.combat.isEnemyAttacking
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.exp-circle {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
  opacity: 0.5;
}

.exp-circle circle {
  stroke-width: 3;
  fill: none;
  stroke-linecap: round;
}

.exp-circle circle:nth-child(1) {
  stroke: #f2f2f2;
}

.exp-circle circle:nth-child(2) {
  stroke: #25e829;
  stroke-dasharray: 138;
  stroke-dashoffset: calc(138 * (1 - var(--percent) / 100));
  transition: stroke-dashoffset 0.35s ease-out;
}
.character {
  position: relative;
  width: 294px;
  height: 86px;
  background: url("https://cdn.dragoncrypto.io/uiassets/selected-player.png")
    no-repeat top left;

  &.take-damage {
    animation: shake 0.55s;
  }

  span {
    position: absolute;
    line-height: 19px;
  }

  img {
    position: absolute;
    top: 16px;
    left: 23px;
    max-width: 61px;
    border-radius: 100px;

    &.dead {
      filter: grayscale(100);
      opacity: 0.25;
    }
  }

  .text {
    font-size: 80%;
    top: -5px;
    left: 84px;
    text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 2px #000;
    font-weight: bold;
  }

  .hp {
    top: 24px;
    left: 105px;
    width: 144px;
    height: 18px;
    position: absolute;
  }

  .turns {
    position: absolute;
    top: 50px;
    left: 105px;
    width: 144px;
    height: 18px;
  }

  .turns-stat,
  .hp-stat {
    font-size: 80%;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 2px #000;
    font-weight: bold;
    line-height: 18px;
  }

  .hp-bar {
    background: url("https://cdn.dragoncrypto.io/uiassets/selected-player-hpbar.png")
      no-repeat top left;
  }

  .turns-bar {
    background: url("https://cdn.dragoncrypto.io/uiassets/selected-player-turnbar.png")
      no-repeat top left;
  }

  .hp-bar,
  .turns-bar {
    position: absolute;
    top: 3px;
    left: -3px;
    width: 100%;
    height: 100%;
    transition: width 0.35s ease-out;
  }

  &.small {
    width: 173px;
    height: 58px;
    background: url("https://cdn.dragoncrypto.io/uiassets/player.png") no-repeat
      top left;
  }
}

.character-wrapper {
  position: relative;

  .guild-type {
    display: block;
    position: absolute;
    width: 30px;
    height: 33px;
    text-indent: -9999px;
    top: 4px;
    right: 15px;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    &.defenders {
      background: url("../assets/ui/defenders-influence-points.png") no-repeat
        left center;
      background-size: contain;
    }
    &.crafters {
      background: url("../assets/ui/crafters-guild-influence-points.png")
        no-repeat left center;
      background-size: contain;
    }
  }
  .mini-character {
    width: 50px;
    height: 50px;
    z-index: 10;
    position: relative;
    margin: 0 2px;
    .portrait-container {
      border: 3px #322922 solid;
      border-radius: 100%;
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      display: inline-block;
      &.dead {
        background-color: #270000;
      }
      img {
        width: calc(100% - 4px);
        border-radius: 100%;
        border: 2px #0e0c0a solid;
        box-shadow: inset 0 8px #000;
        &.dead {
          filter: grayscale(100);
          opacity: 0.25;
        }
      }
    }
    &:hover {
      .meta {
        display: block;
      }
      &:after {
        content: "";
        display: block;
        background: #27201a;
        width: calc(100% - 17px);
        height: 62px;
        position: absolute;
        left: 50%;
        top: 17px;
        z-index: -1;
        box-shadow: inset 0 0 20px #000;
        border: 4px solid #4c4c4b;
        transform: translateX(-50%);
      }
    }
    .meta {
      width: 200px;
      height: 61px;
      display: none;
      background-image: url("https://cdn.dragoncrypto.io/uiassets/mini-hero-data.png");
      background-size: contain;
      position: absolute;
      left: 0;
      top: 55px;
      .text {
        position: absolute;
        bottom: -16px;
        font-size: 80%;
        text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 2px #000;
        font-weight: bold;
        left: 50%;
        transform: translateX(-50%);
      }
      &:first-child {
        margin-left: 20px;
      }
      .hp {
        top: 13px;
        left: 28px;
        width: 142px;
        height: 13px;
        position: absolute;
      }

      .turns {
        position: absolute;
        top: 34px;
        left: 28px;
        width: 142px;
        height: 13px;
      }

      .turns-stat,
      .hp-stat {
        font-size: 80%;
        top: -2px;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 2px #000;
        font-weight: bold;
        line-height: 18px;
      }
      .hp-bar {
        background: url("https://cdn.dragoncrypto.io/uiassets/selected-player-hpbar.png")
          no-repeat top left;
      }

      .turns-bar {
        background: url("https://cdn.dragoncrypto.io/uiassets/selected-player-turnbar.png")
          no-repeat top left;
      }
      .hp-bar,
      .turns-bar {
        position: absolute;
        width: 100%;
        height: 100%;

        transition: width 0.35s ease-out;
      }
    }
  }

  &.smaller {
    /* transform: scale(0.85);
    transform-origin: top left; */
    cursor: url("https://cdn.dragoncrypto.io/uiassets/switch.png"), pointer;
    display: inline-block;
    .character {
      width: 45px;
      height: 45px;
      img {
        width: 100%;
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &.main {
    margin-bottom: 12px;
  }

  .portrait {
    &.levelUp {
      animation: glowing 5s infinite;
    }
  }

  .damage {
    position: absolute;
    bottom: 30px;
    left: 10px;
    width: 80px;
    color: $dmg;
    font-weight: bold;
    text-shadow: 0 0 2px #000, 0 2px 3px #000, 0 2px 3px #000;

    &.hide {
      transition: opacity 0.1s linear;
      opacity: 0;
    }

    &.show {
      transition: opacity 0.1s linear;
      opacity: 1;
      animation: fadeUp 1s;
    }
  }

  .healing {
    position: absolute;
    bottom: 30px;
    left: 10px;
    width: 80px;
    color: $uncommon;
    font-weight: bold;
    text-shadow: 0 0 2px #000, 0 2px 3px #000, 0 2px 3px #000;

    &.hide {
      transition: opacity 0.1s linear;
      opacity: 0;
    }

    &.show {
      transition: opacity 0.1s linear;
      opacity: 1;
      animation: fadeUp 1s;
    }
  }
}
</style>
