// initial state
const state = () => ({
  isChatOpen: false,
  readMessages: true,
  lastReadMessageId: null,
});

// // getters
// const getters = {};

// actions
const actions = {
  loadInitialReadStatus({ commit }, messageId) {
    // Loading the last localstorage saved message id and setting read status based on it
    if (messageId) {
      const prevReadId = localStorage.getItem("lastReadMessageId");
      if (prevReadId && prevReadId === messageId) {
        commit("setLastReadMessageId", messageId);
        commit("setReadMessages", true);
      }
    }
  },
};

// mutations
const mutations = {
  setChatOpenState(state, status) {
    state.isChatOpen = status;
  },
  setReadMessages(state, status) {
    state.readMessages = status;
  },
  setLastReadMessageId(state, id) {
    state.lastReadMessageId = id;
    // SAving latest read message id to localstorage
    localStorage.setItem("lastReadMessageId", id);
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  actions,
  mutations,
};
