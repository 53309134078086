<template>
  <div :class="getClass">
    <div class="inner-dialog-wrapper">
      <GameMessage
        :heading="getHeading"
        :position="'top'"
        :icon="'Archerskill_33_nobg'"
      >
        <p>{{ this.getMessage }}</p>
      </GameMessage>
      <div class="loot-container-wrapper">
        <div id="loot-step" class="loot">
          <div class="content-wrapper">
            <div class="loot-wrapper">
              <div v-if="isEncounter">
                <div class="loot-item">
                  <EquipmentTile
                    :item="{ rarity: 'Common' }"
                    :isCurrency="true"
                    :tokenName="tokenName"
                  />
                  <span :class="'desc common'">{{
                    tokenAmount + " " + tokenName.toUpperCase()
                  }}</span>
                </div>
              </div>
              <div
                class="loot-item"
                v-for="lootItem in loot"
                :key="lootItem.id"
              >
                <EquipmentTile :item="lootItem" />
                <span :class="'desc ' + lootItem.rarity.toLowerCase()">{{
                  lootItem.name
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <DialogButton
          v-if="!isLootBox"
          :requiresTurn="true"
          @buttonClick="$emit('search')"
          :buttonText="'Search Plains'"
        />
        <DialogButton
          v-if="!isLootBox"
          @click="$emit('healersHut')"
          :buttonText="'Healer\'s Hut'"
        />
        <DialogButton
          id="claim-stash-step"
          @click="claimStash"
          :buttonText="'Claim stash'"
        />
        <DialogButton
          @click="$emit('leavePlains')"
          :buttonText="isLootBox ? 'Continue' : 'Leave Plains'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import Constants from "../consts/constants";
import DialogButton from "./DialogButton.vue";
import EquipmentTile from "./EquipmentTile.vue";
import GameMessage from "./GameMessage.vue";

export default {
  name: "Loot",
  components: {
    GameMessage,
    DialogButton,
    EquipmentTile,
  },
  props: {},
  data() {
    return {};
  },
  methods: {
    claimStash() {
      this.$store.dispatch("stash/openStashWindow");
    },
  },
  computed: {
    tokenAmount() {
      return this.$store.state.tokenPrize;
    },
    tokenName() {
      return this.$store.state.tokenPrizeName;
    },
    isLootBox() {
      return this.$store.state.lootType == Constants.lootType.lootbox;
    },
    getHeading() {
      switch (this.$store.state.lootType) {
        case Constants.lootType.encounter:
          return (
            "You have defeated " +
            this.enemyName +
            " the " +
            this.enemySuffix +
            "!"
          );
        case Constants.lootType.event:
          return "Success!";
        case Constants.lootType.lootbox:
          return "Lootbox";
        default:
          break;
      }

      return "";
    },
    isEncounter() {
      return this.$store.state.lootType == Constants.lootType.encounter;
    },
    getMessage() {
      switch (this.$store.state.lootType) {
        case Constants.lootType.encounter:
          return this.enemyDeath;
        case Constants.lootType.event:
          return this.$store.state.currentMultichoice.event.successMessage;
        case Constants.lootType.lootbox:
          return "You open the lootbox and inspect your spoils.";
        default:
          break;
      }

      return "";
    },
    getClass() {
      return (
        "loot-wrapper wrap-content " +
        (this.$store.state.gameState == Constants.gamemodes.looting
          ? "show"
          : "hide")
      );
    },
    enemyName() {
      return this.$store.state.currentEncounter.mob.name;
    },
    enemyIntro() {
      return this.$store.state.currentEncounter.mob.intro;
    },
    enemyWeapon() {
      return this.$store.state.currentEncounter.mob.weapon;
    },
    enemySuffix() {
      return this.$store.state.currentEncounter.suffix;
    },
    enemyDeath() {
      return this.$store.state.currentEncounter.mob.death;
    },
    loot() {
      return this.$store.state.currentEncounter.loot;
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;
}

.highlight {
  font-weight: bold;
  color: $epic;
}

.loot-container-wrapper {
  .loot {
    width: 353px;
    height: 263px;
    position: relative;
    background: url("https://cdn.dragoncrypto.io/uiassets/loot.png") no-repeat
      top left;
    margin-top: 20px;

    .content-wrapper {
      padding: 74px 32px 38px 32px;

      .loot-wrapper {
        height: 152px;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          width: 15px;
          height: 10px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-button {
          background-color: transparent;
          width: 15px;
          height: 1px;
        }

        &::-webkit-scrollbar-thumb {
          background: url("https://cdn.dragoncrypto.io/uiassets/scrollbar-slider.png")
            repeat-y;
        }
      }

      .desc {
        font-size: 90%;
        width: 235px;
        text-align: left;
        padding: 6px;
        font-family: "IM Fell English", serif;

        &.rare {
          color: $rare;
        }

        &.epic {
          color: $epic;
        }

        &.mythical {
          color: $mythical;
        }

        &.legendary {
          color: $legendary;
        }

        &.fabled {
          color: $fabled;
        }

        &.unique {
          color: $unique;
        }

        &.artefact {
          color: $artefact;
        }

        &.uncommon {
          color: $uncommon;
        }
      }

      .loot-item {
        border: 1px solid #3d3d40;
        background: #151413;
        border-radius: 2px;
        margin-bottom: 3px;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
      }
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  font-size: 90%;
}
</style>
