export default {
  // rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc", //testnet
  rpcUrl:
    process.env.VUE_APP_CHAIN_ID == 43114
      ? "https://api.avax.network/ext/bc/C/rpc"
      : "https://api.avax-test.network/ext/bc/C/rpc",
  wsRpcUrl: "wss://api.avax.network/ext/bc/C/ws",
  // apiUrl: "https://heroes.dragoncrypto.io/",
  // apiUrl: "http://127.0.0.1:439/",
  // apiUrl: "https://load-dcg.herokuapp.com/",
  // chainId: 43113,
  apiUrl: process.env.VUE_APP_API_URL,
  chainId: process.env.VUE_APP_CHAIN_ID,

  // LIVE Contract address
  // heroMintContract: "0x7a420AEFF902AAa2c85a190D7B91Ce8BEFffFE14",
  // dcauContract: "0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30",
  liveDcarContract: "0x250bdca7D1845cd543BB55E7D82dcA24D48E9f0F",
  starterKitContract: "0x9b8aE53F9679AF63cF78ed3b5E767463DDDdB813",

  // Contract addresses
  dcauContract: process.env.VUE_APP_DCAU_ADDRESS, // TODO: Update this. At present local
  dcarContract: process.env.VUE_APP_DCAR_ADDRESS,
  loadPriceHandlerContract: process.env.VUE_APP_PRICE_HANDLER_CONTRACT,
  heroMintContract: process.env.VUE_APP_LIVE_HERO_CONTRACT,
  loadRewardHandlerContract: process.env.VUE_APP_LOAD_REWARD_HANDLER_CONTRACT,
  argentiBondContract: process.env.VUE_APP_ARGENTI_BOND_CONTRACT,
  dcgConsumablesContract: process.env.VUE_APP_CONSUMABLES_CONTRACT,
  dcgEquipmentContract: process.env.VUE_APP_EQUIPMENT_CONTRACT,
  dcgResourceContract: process.env.VUE_APP_RESOURCES_CONTRACT,
  tinyDragonContract: process.env.VUE_APP_TINY_DRAGON_CONTRACT,
  nestSupporterContract: process.env.VUE_APP_NEST_SUPPORTER_CONTRACT,
  loadMarketplaceContract: process.env.VUE_APP_MARKETPLACE_CONTRACT,
  craftingContract: process.env.VUE_APP_CRAFTING_CONTRACT,
  loadHealersHutContract: process.env.VUE_APP_HEALERS_HUT_CONTRACT,
  loadInnContract: process.env.VUE_APP_LOAD_INN_CONTRACT,
  loadCraftingGuildContract: process.env.VUE_APP_CRAFTING_GUILD_CONTRACT,
  loadRepairGuildContract: process.env.VUE_APP_REPAIR_GUILD_CONTRACT,
  nameChangeContract: process.env.VUE_APP_NAME_CHANGE_CONTRACT,
  equipmentBurnContract: process.env.VUE_APP_EQUIPMENT_BURN_CONTRACT,
  lootChestsContract: process.env.VUE_APP_LOOT_CHEST_CONTRACT,
  equipmentUpgradeContract: process.env.VUE_APP_EQUIPMENT_UPGRADE_CONTRACT,
  nftFinderContract: process.env.VUE_APP_NFT_FINDER_CONTRACT,
  contractQuestItemBurn: process.env.VUE_APP_CONTRACT_QUEST_ITEM_BURN,
  tokenHeroExchangeContract: process.env.VUE_APP_DCG_TOKEN_HERO_EXCHANGE,
  redeemTokenContract: process.env.VUE_APP_DCG_REDEEM_TOKEN,
  loadRepair: process.env.VUE_APP_REPAIR_CONTRACT,
  dcarBurnBattle: process.env.VUE_APP_DCAR_BURN_CONTRACT,
  dragonFireLottery: process.env.VUE_APP_DRAGON_FIRE_LOTTERY,
  heroesRedeemContract: process.env.VUE_APP_DCG_HEROES_REDEEM_TOKEN,
  heroesRedeemContractSecond:
    process.env.VUE_APP_DCG_HEROES_REDEEM_TOKEN_SECOND,
  distantLandsHeroesExchangeContract:
    process.env.VUE_APP_DCG_DISTAND_LAND_HEROES_EXCHANGE_CONTRACT,
  dcgTripleVaultContract: process.env.VUE_APP_TRIPLE_VAULT_CONTRACT,
  distantLandsHeroesExchangeSecondContract:
    process.env.VUE_APP_DCG_DISTAND_LAND_HEROES_EXCHANGE_SECOND_CONTRACT,

  storageVer: 8,
  updateVer: 0,
  onRampThreshold: 1,
  expPerLevel: 100,
  expCurve: 2.75,
  timeout: 55000,
  expMobMultiplier: 2,
  healCostMultiplier: 0.0000875,
  minDcauAmount: 0.01,
  gatheringBand: 5,
  levelStatPoints: 10,
  repairBaseCost: 3,
  craftBaseCost: 10,
  craftStoneBaseCost: 3,
  craftMaterialBaseCost: 5,
  maxDurabilityMultiplier: 3,
  healthPerEndurance: 100,
  iconLocation: "equip/",
  contractQuestTypes: {
    craftersGuild: "craftersGuild",
    defendersGuild: "defendersGuild",
  },
  contractQuestsGoalTypes: {
    monsterKill: "monsterKill",
    raid: "raid",
    bounty: "bounty",
    artefactRecovery: "artefactRecovery",
    resourceCollection: "resourceCollection",
    equipmentCollection: "equipmentCollection",
  },
  skills: {
    crafting: "crafting",
    gathering: "gathering",
  },
  filterTypes: {
    all: "all",
    armour: "armour",
    weapons: "weapons",
    consumables: "consumables",
    resources: "resources",
    quest: "quest",
    equipments: "equipment",
  },
  sounds: {
    trappedChest: "explosion.wav",
    openChest: "inventory_open.wav",
    discovery: "discovery.mp3",
    oldMan: "oldman.mp3",
    searching: "searching_loot.wav",
    sleeping: "snoring.wav",
    repair: "repair.wav",
    breakdown: "break.mp3",
    mining1: "Mining 1.mp3",
    mining2: "Mining 2.mp3",
    mining3: "Mining 3.mp3",
    mining4: "Mining 4.mp3",
    smelt: "smelt.wav",
  },
  masteryAbilities: {
    axe: "Devastating Blow",
    blade: "Power Slash",
    "close quarters": "1000 Cuts",
    heavy: "Deadly Bash",
    magic: "Mindblast",
    polearm: "Furious Strike",
    ranged: "Projectile Storm",
  },
  preloadMusic: [
    "battle",
    "battle1",
    "battle2",
    "death",
    "death_old",
    "inn",
    "Pirate Tavern Ambience 2",
    "wandering",
    "marketplace",
    "mines",
  ],
  preloadSound: [
    "character_open.wav",
    "healing.wav",
    "healing_old.wav",
    "hit1.mp3",
    "hit2.mp3",
    "hit3.mp3",
    "hit4.mp3",
    "inn-ambient.mp3",
    "inventory_open.wav",
    "market-ambience.mp3",
    "levelup.wav",
    "mobdeath1.mp3",
    "mobdeath1.wav",
    "mobdeath2.wav",
    "mobdeath3.wav",
    "mobdeath4.wav",
    "mobdeath5.wav",
    "mobdeath6.wav",
    "plains-ambience.mp3",
    "searching.wav",
    "swing.wav",
    "explosion.wav",
    "discovery.mp3",
    "oldman.mp3",
    "searching_loot.wav",
    "snoring.wav",
    "repair.wav",
    "break.mp3",
    "Mining 1.mp3",
    "Mining 2.mp3",
    "Mining 3.mp3",
    "Mining 4.mp3",
    "smelt.wav",
  ],
  preloadUI: [
    "button-character",
    "button-inventory",
    "button-small-md",
    "button-small",
    "button",
    "equipment",
    "hotbar",
    "inventory",
    "loot",
    "message-bottom",
    "message-icon-bg",
    "message-top-no-icon",
    "message-top",
    "player",
    "portrait-bg",
    "scrollbar-bottom",
    "scrollbar-slider",
    "scrollbar-top",
    "selected-player-hpbar",
    "selected-player-turnbar",
    "selected-player",
    "tooltip",
    "quest-log",
    "crafting-book",
    "marketplace",
    "inventory-filter-all",
    "inventory-filter-armour",
    "inventory-filter-consumables",
    "inventory-filter-quest",
    "inventory-filter-resources",
    "inventory-filter-weapons",
    "button-quests",
    "quest-bullet",
    "quest-header-bg",
    "quest-step-complete",
    "quest-step-incomplete",
  ],
  preloadIcons: [],
  preloadMap: "worldmap.jpg",
  eventLoses: {
    losehp: "losehp",
    loseturn: "loseturn",
  },
  eventWins: {
    loot: "loot",
    exp: "exp",
    questItem: "questitem",
  },
  lootType: {
    encounter: "encounter",
    event: "event",
    lootbox: "lootbox",
  },
  rarityNumber: function (rarity) {
    switch (rarity) {
      case this.rarities.shoddy:
        return 0;
      case this.rarities.normal:
      case this.rarities.common:
        return 1;
      case this.rarities.uncommon:
        return 2;
      case this.rarities.rare:
        return 3;
      case this.rarities.epic:
        return 4;
      case this.rarities.legendary:
        return 5;
      case this.rarities.artefact:
        return 6;
      case this.rarities.mythical:
        return 7;
      case this.rarities.fabled:
        return 8;
      default:
        break;
    }
  },
  gamemodes: {
    loading: "loading",
    wandering: "wandering",
    combat: "combat",
    combatstart: "combatstart",
    searching: "searching",
    multichoice: "multichoice",
    looting: "looting",
    death: "death",
    healers: "healers",
    inn: "inn",
    sleeping: "sleeping",
    wakeup: "wakeup",
    song: "song",
    marketplace: "marketplace",
    queststart: "queststart",
    questaccepted: "questaccepted",
    repair: "repair",
    crafters: "crafters",
    bardquestcomplete: "bardquestcomplete",
    searchingMines: "searchingMines",
    searchingDeepwood: "searchingDeepwood",
    gatheringStart: "gatheringStart",
    gathering: "gathering",
    forge: "forge",
    stash: "stash",
    crafting: "crafting",
    bank: "bank",
    nest: "nest",
    defenderguild: "defenderguild",
  },
  apiURLs: {
    searchPlains: "searchplains",
    searchMines: "searchMines",
    searchDeepwood: "searchDeepwood",
    mineResource: "mineResource",
    leaveEncounter: "leaveEncounter",
    healHero: "healHero",
    bardSong: "bardSong",
    smelt: "smelt",
    breakdownItem: "breakdownItem",
    repair: "repair",
    upgrade: "createEquipmentUpgrade",
    craft: "craft",
    forgeResult: "smeltresult",
    craftingSlots: "craftingslots",
    craftingResult: "craftingresult",
    createRepair: "createrepair",
    createCraft: "createcraft",
    resetStatPoints: "resetStatPoints",
  },
  positioning: {
    top: "top",
    middle: "middle",
    bottom: "bottom",
  },
  useable: {
    consumable: "consumable",
    resource: "resource",
  },
  consumableActions: {
    heal: "heal",
    exp: "exp",
    turns: "turns",
    resurrection: "resurrection",
  },
  resourceLocations: {
    combat: "combat",
    mines: "mines",
    deadlands: "deadlands",
    deepwood: "deepwood",
    forge: "forge",
  },
  songEffects: {
    none: "none",
    turn: "turn",
    fullturns: "fullturns",
    quest: "quest",
    experience: "experience",
  },
  slots: {
    hand1: "hand1",
    hand2: "hand2",
    utility: "utility",
    helmet: "helmet",
    neck: "neck",
    shoulder: "shoulder",
    chest: "chest",
    belt: "belt",
    legs: "legs",
    boots: "boots",
    arms: "arms",
    gloves: "gloves",
    back: "back",
    ring1: "ring1",
    ring2: "ring2",
  },
  hitLocations: {
    hand2: "hand2",
    helmet: "helmet",
    neck: "neck",
    shoulder: "shoulder",
    chest: "chest",
    belt: "belt",
    legs: "legs",
    boots: "boots",
    arms: "arms",
    gloves: "gloves",
  },
  rarities: {
    shoddy: "Shoddy",
    common: "Common",
    normal: "Normal",
    uncommon: "Uncommon",
    rare: "Rare",
    epic: "Epic",
    legendary: "Legendary",
    artefact: "Artefact",
    mythical: "Mythical",
    fabled: "Fabled",
  },
  hitLocationDetails: [
    { name: "hand2", chance: 15, extraDamage: 0 },
    { name: "helmet", chance: 5, extraDamage: 25 },
    { name: "shoulder", chance: 10, extraDamage: 0 },
    { name: "belt", chance: 10, extraDamage: 0 },
    { name: "chest", chance: 15, extraDamage: 0 },
    { name: "legs", chance: 15, extraDamage: 0 },
    { name: "boots", chance: 5, extraDamage: 0 },
    { name: "arms", chance: 10, extraDamage: 0 },
    { name: "gloves", chance: 10, extraDamage: 0 },
  ],
  weaponType: {
    closeCombat: "close quarters",
    axe: "axe",
    bow: "bow",
    pistol: "pistol",
    rifle: "rifle",
    blade: "blade",
    heavy: "heavy",
    poleArm: "polearm",
    magic: "magic",
  },
  weaponMastery: {
    heavy: "heavy",
    blade: "blade",
    axe: "axe",
    ranged: "ranged",
    closeQuarters: "close quarters",
    shield: "shield",
    poleArm: "polearm",
    dualWield: "dualWield",
    wrestling: "wrestling",
    magic: "magic",
  },
  elements: {
    ice: "ice",
    water: "water",
    fire: "fire",
    wind: "wind",
    earth: "earth",
    mind: "mind",
    chaos: "chaos",
    light: "light",
    venom: "venom",
  },
  armourTypes: {
    heavy: "heavy",
    normal: "normal",
    light: "light",
  },
  stats: {},
  questNpcs: {
    theBard: "thebard",
    healer: "healer",
  },
  questRewards: {
    exp: "exp",
    loot: "loot",
  },
  questTypes: {
    findItem: "finditem",
    talkNpc: "talknpc",
    killCreature: "killcreature",
  },
  questTriggers: {
    randomEvent: "randomevent",
    bardSong: "bardsong",
    killCreature: "killcreature",
  },
};
