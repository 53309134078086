<template>
  <div :class="getClass">
    <div class="inner-dialog-wrapper">
      <GameMessage
        :heading="
          'You have encountered ' + enemyName + ' the ' + enemySuffix + '!'
        "
        :icon="'Warriorskill_33_nobg'"
      >
        <p>{{ this.enemyIntro }}</p>
        <p>
          <span class="highlight">{{ this.enemyName }}</span> comes at you with
          <span class="highlight">{{ this.enemyWeapon }}</span>
        </p>
      </GameMessage>
      <div class="buttons">
        <DialogButton
          @buttonClick="$emit('fight')"
          :buttonText="'Fight (1 Turn)'"
        />
        <DialogButton
          @buttonClick="$emit('flee')"
          :buttonText="'Flee (1 Turn)'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import GameMessage from "../GameMessage.vue";
import DialogButton from "../DialogButton.vue";
import Constants from "../../consts/constants";

export default {
  name: "CombatStart",
  components: {
    GameMessage,
    DialogButton,
  },
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    getClass() {
      return (
        "combat-wrapper wrap-content " +
        (this.$store.state.gameState == Constants.gamemodes.combatstart
          ? "show"
          : "hide")
      );
    },
    enemyName() {
      return this.$store.state.currentEncounter.mob.name;
    },
    enemyIntro() {
      return this.$store.state.currentEncounter.mob.intro;
    },
    enemyWeapon() {
      return this.$store.state.currentEncounter.mob.weapon;
    },
    enemySuffix() {
      return this.$store.state.currentEncounter.suffix;
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;
}

.highlight {
  font-weight: bold;
  color: $dmg;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  font-size: 90%;
}
</style>
