<template>
  <div :class="getClass">
    <div class="inner-dialog-wrapper">
      <GameMessage
        :heading="'The Inn - ' + getSong.name"
        :icon="'29_music_nobg'"
      >
        <p>{{ getSong.description }}</p>
        <p>{{ getSong.effectDescription }}</p>
      </GameMessage>
      <div class="buttons">
        <DialogButton @click="$emit('inn')" :buttonText="'Back to the Inn'" />
        <DialogButton @click="$emit('leave')" :buttonText="'Leave'" />
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import GameMessage from "./GameMessage.vue";
import DialogButton from "./DialogButton.vue";
import Constants from "../consts/constants";

export default {
  name: "BardsSong",
  components: {
    GameMessage,
    DialogButton,
  },
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    getClass() {
      return (
        "bardsong-wrapper wrap-content " +
        (this.$store.state.gameState == Constants.gamemodes.song
          ? "show"
          : "hide")
      );
    },
    getSong() {
      return this.$store.state.currentBardSong;
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.bardsong-wrapper {
  background: no-repeat url("../assets/locationBackgrounds/InnBG.webp") center
    center;
  background-size: cover;
  background-color: #b04621;
}
.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  font-size: 90%;
}
</style>
