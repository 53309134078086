<template>
  <div class="message">
    <img class="avatar" :src="image" :alt="nftid" />
    <div class="text">
      <p class="name">
        #{{ nftid }} <span>{{ avvyNameForAddress(sender) }} </span>
      </p>
      <slot />
      <span class="time">{{ fromTime }}</span>
    </div>
    <button
      @click="$emit('deleteMessage', messageId)"
      class="delete"
      title="Delete this message"
      v-if="admin"
    >
      ×
    </button>
  </div>
</template>

<script>
// import Avatar from "./Avatar.vue";
import { ref, onMounted } from "vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useStore } from "vuex";

dayjs.extend(relativeTime);

export default {
  //   components: { Avatar },
  props: {
    messageId: { type: String, default: "" },
    nftid: { type: String, default: "" },
    image: { type: String, default: "" },
    sender: { type: String, default: "" },
    admin: { type: Boolean, default: false },
    createdAt: [String, Object],
    // sender: { type: Boolean, default: false },
  },
  setup(props) {
    // const fromTime = computed(() => {
    //   if (typeof props.createdAt === "string") {
    //     const d = new Date(props.createdAt);
    //     return dayjs(d).fromNow();
    //   } else {
    //     return dayjs().fromNow();
    //   }
    // });
    const fromTime = ref(null);
    const store = useStore();

    onMounted(() => {
      updateTimestamp();
    });

    const updateTimestamp = () => {
      if (typeof props.createdAt === "string") {
        const d = new Date(props.createdAt);
        fromTime.value = dayjs(d).fromNow();
      } else {
        const d = new Date(props.createdAt * 1000);
        fromTime.value = dayjs(d).fromNow();
      }

      if (fromTime.value === "NaN years ago") {
        fromTime.value = "just now";
      }
    };

    const avvyNameForAddress = (address) => {
      store.commit("avvynames/getOrSetAvvyName", address);
      return store.state.avvynames.addressToNames[address];
    };

    store.watch(
      (state) => state.gameHours,
      () => {
        // updating timestamp when game hour changes
        updateTimestamp();
      }
    );

    return { fromTime, avvyNameForAddress };
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid #838282;
  box-shadow: 0 0 0px 3px #00000059;
}
.message {
  display: grid;
  grid-template-columns: 50px 1fr;
  text-align: left;
  align-items: center;
  grid-gap: 0.5rem;
  margin: 1rem 0;
  position: relative;
  .text {
    font-size: 0.9rem;
    .name {
      font-weight: bold;
      display: block;
      margin-bottom: 0.2rem;
      margin-top: 0;
      span {
        font-size: 0.8rem;
        opacity: 0.6;
      }
    }
    .time {
      font-size: 0.7rem;
      display: block;
      margin: 0.2rem 0 0;
      opacity: 0.5;
    }
  }
  &:hover {
    button.delete {
      opacity: 0.5;
    }
  }
  button.delete {
    position: absolute;
    right: 0;
    border: none;
    border-radius: 100%;
    background: #fff;
    color: #000;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    font-size: 1.2rem;
    &:hover {
      cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
        auto;
      background-color: red;
      color: #fff;
      opacity: 1;
    }
  }
}
</style>
