// initial state
const state = () => ({
  isHeroHealing: false,
  isPetAttacking: false,
  isHeroAttacking: false,
  isEnemyAttacking: false,
  isHeroCritical: false,
  isEnemyMiss: false,
  isHeroMiss: false,
  isEnemyCritical: false,
  isAbilityAttack: false,
  isEnemyDoubleHit: false,
  isEnemyEffectiveness: false,
  isEnemyReistance: false,
  heroDamage: 0,
  enemyDamage: 0,
  heroHealAmount: 0,
  weaponTypeHero: "",
  weaponTypeEnemy: "",
  isAutoplayOn: false,
  enemyResistanceMessage: "",
  enemyEffectivenessMessage: "",
});

// // getters
// const getters = {};

// actions
const actions = {
  autoplayAttack({ rootState }) {
    rootState.doCombatDamage(0);
  },
  healHero({ commit }, consumeResult) {
    commit("setHeroAttacking", false);
    commit("setPetAttacking", false);
    commit("setIsHeroHealing", true);
    commit("setEnemyAttacking", false);
    commit("setHeroHealAmount", consumeResult.healAmount);
  },
  attackPet({ commit }, playerDamage) {
    if (playerDamage.weaponType) {
      commit("setWeaponTypeHero", playerDamage.weaponType);
    } else {
      commit("setWeaponTypeHero", "closeCombat");
    }
    commit("setPetAttacking", true);
    commit("setEnemyAttacking", false);
    commit("setHeroDamage", playerDamage.damage);
    if (playerDamage.isCritical) {
      commit("setIsEnemyCritical", true);
    }
    if (playerDamage.isDoubleHit) {
      commit("setIsEnemyDoubleHit", true);
    }
  },
  attackHero({ commit }, playerDamage) {
    if (playerDamage.weaponType) {
      commit("setWeaponTypeHero", playerDamage.weaponType);
    } else {
      commit("setWeaponTypeHero", "closeCombat");
    }
    commit("setHeroAttacking", true);
    commit("setPetAttacking", false);
    commit("setEnemyAttacking", false);
    commit("setHeroDamage", playerDamage.damage);

    if (playerDamage.isMiss) {
      console.log("player dmg was miss");
      commit("setIsHeroMiss", true);
    }

    if (playerDamage.isCritical) {
      commit("setIsEnemyCritical", true);
    }
    if (playerDamage.isDoubleHit) {
      commit("setIsEnemyDoubleHit", true);
    }
    if (
      playerDamage.elementalDamageMessage &&
      playerDamage.elementalDamageMessage !== "none"
    ) {
      commit("setIsEnemyReistance", true);
      commit("setEnemyResistanceMessage", playerDamage.elementalDamageMessage);
    }
    if (
      playerDamage.effectivenessMessage &&
      playerDamage.effectivenessMessage !== "none"
    ) {
      commit("setIsEnemyEffectiveness", true);
      commit("setEnemyEffectivenessMessage", playerDamage.effectivenessMessage);
    }
  },
  attackEnemy({ commit }, enemyDamage) {
    if (enemyDamage.weaponType) {
      commit("setWeaponTypeEnemy", enemyDamage.weaponType);
    } else {
      commit("setWeaponTypeEnemy", "closeCombat");
    }
    commit("setIsHeroHealing", false);
    commit("setHeroAttacking", false);
    commit("setPetAttacking", false);
    commit("setEnemyAttacking", true);
    commit("setEnemyDamage", enemyDamage.damage);
    if (enemyDamage.isCritical) {
      commit("setIsHeroCritical", true);
    }
    if (enemyDamage.isMiss) {
      commit("setIsEnemyMiss", true);
    }
  },
  reset({ commit }) {
    commit("setWeaponTypeEnemy", "");
    commit("setWeaponTypeHero", "");
    commit("setHeroAttacking", false);
    commit("setIsHeroMiss", false);
    commit("setPetAttacking", false);
    commit("setEnemyAttacking", false);
    commit("setIsHeroCritical", false);
    commit("setIsEnemyMiss", false);
    commit("setIsEnemyCritical", false);
    commit("setIsEnemyDoubleHit", false);
    commit("setIsHeroHealing", false);
    commit("setIsAbilityAttack", false);
    commit("setIsEnemyEffectiveness", false);
    commit("setIsEnemyReistance", false);
  },
};

// mutations
const mutations = {
  setIsHeroHealing(state, status) {
    state.isHeroHealing = status;
  },
  setPetAttacking(state, status) {
    state.isPetAttacking = status;
  },
  setHeroAttacking(state, status) {
    state.isHeroAttacking = status;
  },
  setEnemyAttacking(state, status) {
    state.isEnemyAttacking = status;
  },
  setHeroDamage(state, damage) {
    state.heroDamage = damage;
  },
  setIsHeroCritical(state, status) {
    state.isHeroCritical = status;
  },
  setIsEnemyMiss(state, status) {
    state.isEnemyMiss = status;
  },
  setIsHeroMiss(state, status) {
    state.isHeroMiss = status;
  },
  setIsEnemyCritical(state, status) {
    state.isEnemyCritical = status;
  },
  setIsEnemyDoubleHit(state, status) {
    state.isEnemyDoubleHit = status;
  },
  setEnemyDamage(state, damage) {
    state.enemyDamage = damage;
  },
  setIsEnemyEffectiveness(state, status) {
    state.isEnemyEffectiveness = status;
  },
  setEnemyEffectivenessMessage(state, message) {
    state.enemyEffectivenessMessage = message;
  },
  setIsEnemyReistance(state, status) {
    state.isEnemyReistance = status;
  },
  setEnemyResistanceMessage(state, message) {
    state.enemyResistanceMessage = message;
  },
  setHeroHealAmount(state, healAmount) {
    state.heroHealAmount = healAmount;
  },
  setWeaponTypeHero(state, weaponType) {
    state.weaponTypeHero = weaponType;
  },
  setWeaponTypeEnemy(state, weaponType) {
    state.weaponTypeEnemy = weaponType;
  },
  setIsAutoplayOn(state, status) {
    state.isAutoplayOn = status;
  },
  setIsAbilityAttack(state, status) {
    state.isAbilityAttack = status;
  },
};

export default {
  namespaced: true,
  state,
  //   getters,
  actions,
  mutations,
};
