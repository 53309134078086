<template>
  <template v-if="isConnected && isCorrectChain">
    <BlueButton
      v-if="isSessionActive"
      :size="size"
      @click="logout"
      :class="[
        buttonText === 'Checking Session' || buttonText === 'Signing in'
          ? 'loading'
          : '',
        'logout',
      ]"
    >
      {{ account.substr(0, 4) }}...{{
        account.substr(account.length - 4, account.length)
      }}
    </BlueButton>
    <BlueButton
      v-else
      @click="login"
      :size="size"
      :disabled="
        buttonText === 'Checking Session' || buttonText === 'Signing in'
      "
      :class="
        buttonText === 'Checking Session' || buttonText === 'Signing in'
          ? 'loading'
          : ''
      "
    >
      {{ buttonText }}
    </BlueButton>
  </template>
  <template v-else>
    <BlueButton :size="size" v-if="isCorrectChain" @click="connect">
      Connect
    </BlueButton>
    <BlueButton
      :size="size"
      @click="switchChain"
      v-else
      title="Switch to correct chain to continue"
    >
      {{ switchToText }}
    </BlueButton>
  </template>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Web3 from "web3";
import { useUser } from "../../composables/user";
import Constants from "../../consts/constants";
import apiConnector from "../../game/apiConnector";
import characters from "../../game/characters";
import BlueButton from "./BlueButton.vue";

import { useMixpanel } from "../../composables/mixpanel";

export default {
  name: "ConnectButton",
  components: {
    BlueButton,
  },
  props: {
    size: {
      // small || extra-small || default large
      type: String,
      default: "small",
    },
  },
  setup() {
    const store = useStore();
    const account = ref("");
    const { activate, isCorrectChain, address } = useUser();

    const isConnected = ref(false);
    const web3 = ref(null);
    // const isSigning = ref(false);
    const isCheckingSession = ref(true);
    const sessionDetails = ref({});
    const currentChainId = ref(0);
    const currentBlock = ref(0);
    const isSigning = ref(false);

    const networks = {
      testnet: {
        chainId: `0x${Number(43113).toString(16)}`,
        chainName: "Avalanche Fuji Testnet",
        rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
        nativeCurrency: {
          name: "Avalanche",
          symbol: "AVAX",
          decimals: 18,
        },
        blockExplorerUrls: ["https://testnet.snowtrace.io"],
      },
      mainnet: {
        chainId: `0x${Number(43114).toString(16)}`,
        chainName: "Avalanche C-Chain",
        rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
        nativeCurrency: {
          name: "Avalanche",
          symbol: "AVAX",
          decimals: 18,
        },
        blockExplorerUrls: ["https://snowtrace.io"],
      },
      local: {
        chainId: `0x${Number(1337).toString(16)}`,
        chainName: "Localhost",
        nativeCurrency: {
          name: "ETH",
          symbol: "ETH",
          decimals: 18,
        },
        rpcUrl: "http://localhost:8545",
      },
    };

    /**
     *  Mixpanel composable
     */
    const { trackIdentity } = useMixpanel();

    onMounted(async () => {
      const isWeb3 = await checkWeb3();
      if (isWeb3) {
        currentChainId.value = await web3.value.eth.getChainId();
        currentBlock.value = await web3.value.eth.getBlockNumber();
        await checkConnection();
      }
    });

    const connect = async () => {
      if (!isConnected.value && web3.value) {
        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          await activate(window.ethereum); // Initialize the provider in ethers

          account.value = accounts[0];
        } catch (error) {
          console.log(error);
        }

        isConnected.value = true;
        await checkConnection();
      }
    };

    const checkWeb3 = async () => {
      if (window.ethereum) {
        web3.value = new Web3(window.ethereum);
        await activate(window.ethereum); // Initialize the provider in ethers
        window.ethereum.on("accountsChanged", async () => {
          console.log("accounts changed");
          // const accounts = await window.ethereum.request({
          //   method: "eth_requestAccounts",
          // });
          // account.value = accounts.length > 0 ? accounts[0] : "";
          if (isConnected.value) window.location.reload();
          await checkConnection();
        });

        window.ethereum.on("chainChanged", (chainId) => {
          currentChainId.value = chainId;
          window.location.reload();
        });

        return true;
      } else if (window.web3) {
        web3.value = new Web3(window.web3.currentProvider);
        activate(window.web3.currentProvider, "any"); // Initialize the current provider in ethers
        return true;
      }

      return false;
    };
    const callProcessAuth = async () => {
      const response = await fetch(Constants.apiUrl + "authLogin", {
        method: "POST",
        body: JSON.stringify({ address: account.value }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    };

    const setHeroData = (heroData) => {
      let loadedCharacters = [];
      let index = 0;

      for (const heroIdxName in heroData) {
        if (Object.hasOwnProperty.call(heroData, heroIdxName)) {
          const heroDataItem = heroData[heroIdxName];
          let character = characters.generateCharacterFromData(
            index++,
            heroIdxName,
            heroDataItem
          );
          loadedCharacters.push(character);
        }
      }

      store.commit("setCharacters", loadedCharacters);
      store.commit("characterSelect/setAllCharacters", loadedCharacters);
      store.commit("setWandering");
      store.commit("characterSelect/setDefaultSelectedCharacters");
      store.dispatch("characterSelect/setActiveCharacters");

      return loadedCharacters;
    };

    const callGetHeroes = async (sessionId) => {
      const response = await fetch(Constants.apiUrl + "heroesforuser", {
        method: "POST",
        body: JSON.stringify({ account: account.value, sessionId }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    };

    const callGetResources = async (sessionId, heroId) => {
      const response = await fetch(Constants.apiUrl + "resources", {
        method: "POST",
        body: JSON.stringify({ account: account.value, sessionId, heroId }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    };

    const callGetHeroStats = async (sessionId, heroIds) => {
      const response = await fetch(Constants.apiUrl + "statsforheroes", {
        method: "POST",
        body: JSON.stringify({ account: account.value, sessionId, heroIds }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    };

    const setupSession = async (sessionId, addressData) => {
      // console.log("setting up session", addressData);

      // Dispatch action to load intro. The auto auto deterimines when to load it.
      store.dispatch("intro/loadIntro");

      store.commit("setAccount", account.value);

      let loadedCharacters = [];
      if (addressData) {
        loadedCharacters = setHeroData(addressData.heroData);
        store.commit("setInventory", addressData.inventory);

        if (loadedCharacters.length > 0) {
          // Setting current character equipped, daily quests only if player owns a character
          store.commit("setCurrentCharacterEquipped", addressData.equipment);

          store.commit("pets/setSelectedPet", addressData.pet);
          store.commit("setQuests", addressData.quests);

          const resources = await apiConnector.callGetStaticResourceList();

          store.commit("setStaticResourceList", resources);

          const dailyQuestStatus = await apiConnector.callGetDailyQuest(
            account.value,
            sessionId,
            store.state.characters[store.state.currentCharacter].number
          );

          if (dailyQuestStatus.hasNewQuest) {
            store.commit("setDailyQuestStatus", dailyQuestStatus.newQuest);
          }

          const getResources = await callGetResources(
            sessionId,
            store.state.characters[store.state.currentCharacter].number
          );

          if (getResources) {
            store.commit("setResources", getResources.resources);
          }
        }
      } else {
        const getHeroesResult = await callGetHeroes(sessionId);

        const getHeroStatsResult = await callGetHeroStats(
          sessionId,
          getHeroesResult.heroIds
        );

        loadedCharacters = setHeroData(getHeroStatsResult.heroData);

        const inventoryResult = await apiConnector.callGetInventory(
          account.value,
          sessionId
        );

        store.commit("setInventory", inventoryResult.inventory);

        if (store.state.characters.length > 0) {
          const equipmentResult = await apiConnector.callGetEquipment(
            account.value,
            sessionId,
            store.state.characters[store.state.currentCharacter].number
          );

          store.commit(
            "setCurrentCharacterEquipped",
            equipmentResult.equipment
          );
        }

        const getResources = await callGetResources(
          sessionId,
          store.state.characters[store.state.currentCharacter].number
        );

        if (getResources) {
          store.commit("setResources", getResources.resources);
        }
      }

      // Tracking basic Mixpanel Identity
      trackIdentity(account.value, {
        characters: loadedCharacters.length,
      });

      isSigning.value = false;
    };

    const callProcessLogin = async (signature) => {
      const response = await fetch(Constants.apiUrl + "processlogin", {
        method: "POST",
        body: JSON.stringify({ account: account.value, signature }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    };

    const switchChain = async () => {
      console.log("Switching chain");
      let chainName = "testnet";
      if (Constants.chainId == 1337) {
        chainName = "local";
      } else if (Constants.chainId == 43114) {
        chainName = "mainnet";
      }
      try {
        if (!window.ethereum) throw new Error("No Wallet Present");
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: networks[chainName].chainId }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [{ ...networks[chainName] }],
            });
          } catch (addError) {
            // handle "add" error
          }
        }
        // handle other "switch" errors
      }
    };

    const logout = () => {
      localStorage.setItem("sessionId", "");
      store.commit("setIsSessionActive", false);
      window.location.reload();
    };

    const login = async () => {
      isSigning.value = true;

      try {
        const getNonce = await callProcessAuth();

        if (getNonce.success) {
          let signature = await web3.value.eth.personal.sign(
            "Sign this message to verify ownership of your wallet and log in to dragoncrypto.io. Nonce: " +
              getNonce.data.nonce,
            account.value,
            ""
          );
          const result = await callProcessLogin(signature);

          localStorage.setItem("sessionId", result.sessionId);
          store.commit("setSessionExpiry", result.sessionExpiry);

          store.commit("setGameTime", result);

          await setupSession(result.sessionId, result);
          store.commit("setIsSessionActive", true);
        } else {
          console.log("error getting nonce");
          isSigning.value = false;
        }
      } catch (error) {
        console.log(error);
        isSigning.value = false;
      }
    };
    const checkConnection = async () => {
      if (web3.value) {
        const accounts = await web3.value.eth.getAccounts();
        store.commit("setSessionExpired", false);

        if (accounts.length > 0) {
          // User is already connect with the wallet
          account.value = accounts[0].toLowerCase();
          isConnected.value = true;
          //Finding if session already exists
          let sessionId = localStorage.getItem("sessionId");
          isCheckingSession.value = false;
          if (sessionId) {
            isCheckingSession.value = true;
            const response = await fetch(Constants.apiUrl + "sessiondetails", {
              method: "POST",
              body: JSON.stringify({ account: account.value, sessionId }),
              headers: {
                "Content-Type": "application/json",
              },
            });
            const result = await response.json();
            if (result.success) {
              store.commit("setIsSessionActive", true);

              /**
               * There was a method to setup session over here. Moved it to
               * auto login method below. Reason is to enfoce player to click atleast once to start the music
               */

              store.commit(
                "setSessionExpiry",
                result.session.session.loginExpiry
              );

              sessionDetails.value = result;
            } else {
              isCheckingSession.value = false;
            }
          }
        }
      }
    };

    const buttonText = computed(() => {
      if (isSigning.value) {
        return "Signing in";
      }
      if (isSessionActive.value) {
        return "Auto-logging in";
      }
      if (isCheckingSession.value) {
        return "Checking Session";
      }
      return "Sign in";
    });
    const switchToText = computed(() => {
      if (Constants.chainId == 43114) {
        return "Switch To Avalanche C-Chain";
      } else {
        return "Switch To Fuji C-Chain";
      }
    });

    const isSessionActive = computed(() => {
      return store.state.isSessionActive;
    });

    return {
      // data
      address,
      isConnected,
      isCorrectChain,

      account,
      web3,
      currentChainId,
      currentBlock,
      isSessionActive,

      // methods
      connect,
      login,
      logout,
      switchChain,

      //computed
      buttonText,
      switchToText,
    };
  },
};
</script>
<style lang="scss" scoped>
.logout {
  &:hover {
    &:after {
      opacity: 1;
    }
  }
  &:after {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url("../../assets/ui/exit.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    top: 3px;
    left: 3px;
  }
}
</style>
