<template>
  <div :class="getClass" v-if="currentFoundQuest">
    <div class="inner-dialog-wrapper">
      <GameMessage
        :heading="currentFoundQuest.name"
        :icon="'Enchantment_35_order_scroll'"
      >
        <p>{{ currentFoundQuest.steps[0].clue }}</p>
      </GameMessage>
      <div class="buttons">
        <DialogButton @click="$emit('leave')" :buttonText="'Leave'" />
        <DialogButton
          @buttonClick="$emit('search')"
          :requiresTurn="true"
          :buttonText="'Search the Plains'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import GameMessage from "./GameMessage.vue";
import DialogButton from "./DialogButton.vue";
import Constants from "../consts/constants";

export default {
  name: "QuestStart",
  components: {
    GameMessage,
    DialogButton,
  },
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    currentFoundQuest() {
      return this.$store.state.currentFoundQuest;
    },
    getClass() {
      return (
        "quest-wrapper wrap-content " +
        (this.$store.state.gameState == Constants.gamemodes.questaccepted
          ? "show"
          : "hide")
      );
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;
}

.highlight {
  font-weight: bold;
  color: $dmg;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  font-size: 90%;
}
</style>
