<template>
  <div
    :class="['nest-item', isActive ? 'active' : '']"
    @click.self="isActive = !isActive"
  >
    <DialogButton
      v-if="isActive"
      buttonSize="small"
      buttonText="Back"
      class="back-button"
      @click="isActive = !isActive"
    />
    <SkeletalLoading width="150" height="196" class="loading" v-if="loading" />
    <div v-else class="grid">
      <div class="details">
        <img
          @click.self="isActive = !isActive"
          class="nest-image"
          :src="item.image"
          :alt="item.name"
        />
        <span v-if="isCurrentlyStaked" class="live status">
          <span class="pulsating-circle"></span>
          STAKED
        </span>
      </div>
      <div class="manage-nest" v-if="isActive">
        <template v-if="!isCurrentlyStaked">
          <div class="right-col">
            <div class="desc">
              <h3>
                {{ item.name }}
              </h3>
              <p v-if="isCurrentlyStaked">
                Your NEST NFT is staked at Grom's Bank to earn revenue share.
                You can unstake it by clicking the unstake button
              </p>
              <p v-else>
                Approve and stake your Dragon Crypto Nest NFT to start earning
                revenue share.
              </p>
              <DialogButton
                v-if="isNFTApproved"
                class="stake-button"
                buttonSize="small"
                :buttonText="'Stake'"
                :disabled="loading || isProcessing"
                @click="stakeCurrentItem"
                :isLoading="isProcessing"
              />
              <DialogButton
                v-else
                @click="approveCurrentItem"
                class="stake-button"
                buttonSize="small"
                :buttonText="'Approve'"
                :disabled="isProcessing"
                :isLoading="isProcessing"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="right-col">
            <div class="desc">
              <h3>
                {{ item.name }}
              </h3>
              <p>
                Your NEST NFT is staked at Grom's Bank to earn revenue share.
                You can unstake it by clicking the unstake button.
              </p>
              <p>You are currently earning {{ nestPercentage }}%</p>
              <DialogButton
                class="stake-button"
                buttonSize="small"
                :buttonText="'Unstake'"
                :disabled="isProcessing"
                @click="unstakeCurrentItem"
                :isLoading="isProcessing"
              />
            </div>
            <div class="balances">
              <div class="token-balance" id="dcau-balance">
                <p class="dcau">
                  {{ pendingDCAU }}
                </p>
                <DialogButton
                  :disabled="isProcessing || pendingDCAU == 0.0"
                  class="claim-button"
                  buttonText="Claim DCAU"
                  @click="claimTokens('dcau')"
                  :isLoading="isProcessing"
                  buttonSize="small"
                />
              </div>
              <div class="token-balance" id="dcar-balance">
                <p class="dcar">
                  {{ pendingDCAR }}
                </p>
                <DialogButton
                  :disabled="isProcessing || pendingDCAR == 0.0"
                  class="claim-button"
                  buttonText="Claim DCAR"
                  @click="claimTokens('dcar')"
                  :isLoading="isProcessing"
                  buttonSize="small"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import SkeletalLoading from "../LoadingComponents/SkeletalLoading.vue";
import DialogButton from "../DialogButton.vue";
import { parseError } from "../../utils/helpers";
import { useStore } from "vuex";
import { ethers } from "ethers";
import Constants from "../../consts/constants";

import { useUser } from "../../composables/user";
import {
  getLoadRewardHandlerContract,
  getDCGNestContract,
} from "../../utils/getContract";

export default {
  name: "NestItem",
  props: {
    id: {
      type: String,
    },
    isStaked: {
      type: Boolean,
    },
  },
  emits: ["refresh"],
  components: {
    SkeletalLoading,
    DialogButton,
  },
  setup(props, { emit }) {
    const item = ref({});
    const loading = ref(true);
    const isNFTApproved = ref(false);
    const store = useStore();
    const isProcessing = ref(false);
    const pendingDCAR = ref(0);
    const pendingDCAU = ref(0);
    const isActive = ref(false);
    const nestPercentage = ref(0);
    const isCurrentlyStaked = ref(props.isStaked);

    const { signer, address } = useUser();
    const RewardHandlerContract = getLoadRewardHandlerContract(signer.value);
    const DCGNestContract = getDCGNestContract(signer.value);

    onMounted(async () => {
      console.log("ID", props.id);
      await checkIsApprovedForAll();
      await getNestNFTDetails();
      await getPendingTokensCount();
      await getNestPercentage();
    });

    const getNestPercentage = async () => {
      const percentage = await RewardHandlerContract.NEST_PERCENTAGE();
      nestPercentage.value = ethers.utils.formatUnits(percentage, 0);
    };
    const getNestNFTDetails = async () => {
      loading.value = true;
      try {
        const path = `${Constants.apiUrl}nest/metadata/${props.id}.json`;
        const response = await fetch(path, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const result = await response.json();
        item.value = result;
        loading.value = false;
      } catch (e) {
        console.log(e);
      }
    };
    const approveCurrentItem = async () => {
      try {
        isProcessing.value = true;
        const approveNFTTx = await DCGNestContract.approve(
          RewardHandlerContract.address,
          props.id
        );
        const receiptOfApproval = await approveNFTTx.wait();

        if (receiptOfApproval.status !== 1) {
          isNFTApproved.value = false;
          return;
        }
        isNFTApproved.value = true;
        isProcessing.value = false;
      } catch (e) {
        store.commit("setNotification", parseError(e));
        isNFTApproved.value = false;
        isProcessing.value = false;
      }
    };

    const stakeCurrentItem = async () => {
      try {
        isProcessing.value = true;
        const stakeNFTTx = await RewardHandlerContract.stakeNest(props.id);
        const receiptOfStake = await stakeNFTTx.wait();

        if (receiptOfStake.status !== 1) {
          return;
        }
        console.log("Successfully staked");
        // emit("refresh");
        // After staking, get pending claim details
        await getPendingTokensCount();
        await getNestPercentage();
        isCurrentlyStaked.value = true;
        isProcessing.value = false;
      } catch (e) {
        console.log(e);
        store.commit("setNotification", parseError(e));
        isProcessing.value = false;
      }
    };

    const unstakeCurrentItem = async () => {
      try {
        isProcessing.value = true;
        const stakeNFTTx = await RewardHandlerContract.withdrawNest(props.id);
        const receiptOfStake = await stakeNFTTx.wait();

        if (receiptOfStake.status !== 1) {
          return;
        }
        console.log("Successfully Unstaked");
        // emit("refresh");
        isCurrentlyStaked.value = false;
        isProcessing.value = false;
      } catch (e) {
        console.log(e);
        isProcessing.value = false;
        store.commit("setNotification", parseError(e));
      }
    };

    const claimTokens = async (type) => {
      try {
        isProcessing.value = true;
        // Todo CLaim Pending Tokens
        let tokenAddress = "";
        if (type === "dcau") {
          tokenAddress = Constants.dcauContract;
        } else if (type === "dcar") {
          tokenAddress = Constants.dcarContract;
        } else {
          return;
        }
        const claimTokensTx = await RewardHandlerContract.claimNestTokens(
          tokenAddress,
          props.id
        );
        const receiptOfClaim = await claimTokensTx.wait();
        if (receiptOfClaim.status !== 1) {
          return;
        }
        console.log("Successfully claimed");
        await getPendingTokensCount();
        emit("refresh");
        isProcessing.value = false;
      } catch (e) {
        console.log(e);
        isProcessing.value = false;
        store.commit("setNotification", parseError(e));
      }
    };

    const getPendingTokensCount = async () => {
      try {
        const pendingTokensDCAU = await RewardHandlerContract.pendingNestTokens(
          Constants.dcauContract,
          props.id
        );
        const pendingTokensDCAR = await RewardHandlerContract.pendingNestTokens(
          Constants.dcarContract,
          props.id
        );
        pendingDCAU.value = parseFloat(
          ethers.utils.formatEther(pendingTokensDCAU)
        ).toFixed(3);
        console.log(pendingDCAU.value);
        pendingDCAR.value = parseFloat(
          ethers.utils.formatEther(pendingTokensDCAR)
        ).toFixed(3);
        console.log(pendingDCAR.value);
      } catch (e) {
        console.log(e);
        store.commit("setNotification", parseError(e));
      }
    };

    const checkIsApprovedForAll = async () => {
      const result = await DCGNestContract.isApprovedForAll(
        address.value,
        RewardHandlerContract.address
      );
      isNFTApproved.value = result;
    };

    return {
      //data
      item,
      loading,
      isNFTApproved,
      isProcessing,
      pendingDCAU,
      pendingDCAR,
      isActive,
      nestPercentage,
      isCurrentlyStaked,
      //methods
      approveCurrentItem,
      stakeCurrentItem,
      unstakeCurrentItem,
      claimTokens,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.nest-item {
  position: relative;
  cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
    auto;
  background: #2e2e2e;
  padding: 0.5rem;
  border: 2px #c69b6d solid;
  .details {
    height: 100%;

    /*display: grid;
    align-items: center;
    justify-items: center; */
  }
  .back-button {
    position: absolute;
    top: -66px;
    left: 0;
  }
  .status {
    font-size: 0.8rem;
    text-transform: capitalize;
    font-family: "Lato", sans-serif;
    padding: 0 5px 1px;
    border-radius: 4px;
    margin: 0 0 0 5px;
    color: #fff;
    position: absolute;
    bottom: 15px;
    right: 15px;
    .pulsating-circle {
      border-radius: 100%;
      padding: 0;
      width: 8px;
      height: 8px;
      display: inline-block;
      margin: 0 5px 0 0;
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
    }
    &.live {
      background-color: rgb(0 133 0);
      .pulsating-circle {
        background-color: #00ff00;
      }
    }
  }
  &.active {
    position: absolute;
    z-index: 20;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/normal_cursor_gray.png"),
      auto;
    .status {
      display: inline-block;
      bottom: auto;
      top: 20px;
      right: 20px;
    }
    .grid {
      display: grid;
      width: 100%;
      align-items: center;
      height: calc(100% + 16px);
      grid-template-columns: 1fr 1fr;
      top: -16px;
      position: relative;

      .details {
        height: 100%;
        display: grid;
        align-items: center;
        justify-items: center;
        background: #2e2e2e;
        .nest-image {
          width: 68%;
          box-shadow: 0 0 15px #000000bd;
        }
      }
      .manage-nest {
        background: #0a0a0a;
        height: 100%;
      }
    }
  }
  .right-col {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    align-items: center;
    background: #171312;
    box-shadow: 0 0 8px 2px rgb(0 0 0 / 44%);
    .desc {
      width: 80%;
      margin: 0 auto 1.5rem;
    }
  }
  .balances {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    border-top: 1px #ffffff42 solid;
    border-bottom: 1px #ffffff42 solid;

    .token-balance {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1px;
      align-items: center;
      justify-items: center;
      font-size: 1.2rem;
      height: 100%;
      &:first-child {
        border-right: 1px #ffffff42 solid;
      }
      p {
        font-size: 2rem;
        margin-bottom: 0.5em;
        &:before {
          background-size: contain;
          content: "";
          display: inline-block;
          width: 46px;
          height: 46px;
          line-height: 0;
          margin-top: -8px;
          padding: 0;
          top: 14px;
          position: relative;
          margin-right: 4px;
        }
        &.dcau {
          &:before {
            background: url("../../assets/ui/dcau.png") no-repeat left center;
          }
        }
        &.dcar {
          &:before {
            background: url("../../assets/ui/dcar.png") no-repeat left center;
          }
        }
      }
      span {
        font-size: 0.7rem;
        display: block;
        margin: 2px 0 0;
        color: #ffffff85;
      }
    }
    button {
      margin-bottom: 30px;
      margin-top: 0;
    }
  }
  /* &:hover {
    .stake-button {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .stake-button {
    display: none;
  } */
  .nest-image {
    width: 150px;
    transition: all 0.1s ease-out;
  }
  h5 {
    margin: 5px 0;
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
</style>
