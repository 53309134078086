import { ethers } from "ethers";
import { ref } from "vue";
import { useStore } from "vuex";
import { useUser } from "../composables/user";
import Constants from "../consts/constants";
import {
  getDCARContract,
  getDCAUContract,
  getDCGTripleVault,
  getLoadPriceHandlerContract,
} from "../utils/getContract";

export function usePrice() {
  const store = useStore();
  const dcauBalance = ref(BigInt(0));
  const dcarBalance = ref(BigInt(0));
  const copperBalance = ref(BigInt(0));
  const bonusPercentage = ref(0);
  const tokensRequired = ref(0);

  const { address, signer } = useUser();

  const getCopperBalance = async () => {
    const DCGTripleVaultContract = getDCGTripleVault(signer.value);
    try {
      if (address.value) {
        const userInfo = await DCGTripleVaultContract.userInfo(address.value);
        copperBalance.value = parseFloat(
          ethers.utils.formatEther(userInfo.copperBalance)
        ).toFixed(2);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDCAUBalance = async () => {
    const dcau = getDCAUContract(signer.value);
    try {
      if (address.value) {
        const balance = await dcau.balanceOf(address.value);
        dcauBalance.value = parseFloat(
          ethers.utils.formatEther(balance)
        ).toFixed(3);
        // console.log(dcauBalance.value);
        store.commit("setDcauInWallet", dcauBalance.value);
      } else {
        store.commit("setDcauInWallet", 0);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDCARBalance = async () => {
    const dcau = getDCARContract(signer.value);
    try {
      if (address.value) {
        const balance = await dcau.balanceOf(address.value);
        dcarBalance.value = parseFloat(
          ethers.utils.formatEther(balance)
        ).toFixed(3);
        // console.log(dcarBalance.value);
        store.commit("setDcarInWallet", dcarBalance.value);
      } else {
        store.commit("setDcarInWallet", 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDCAUDollarBalance = async () => {
    const PriceHandlerContract = getLoadPriceHandlerContract(signer.value);
    const dcau = getDCAUContract(signer.value);
    try {
      if (address.value) {
        let pricePerDollar = await PriceHandlerContract.tokensPerDollar(
          dcau.address
        );
        pricePerDollar = parseFloat(ethers.utils.formatEther(pricePerDollar));
        const valueInDollars = dcauBalance.value / pricePerDollar;
        return valueInDollars.toFixed(3);
      }
    } catch (error) {
      console.log(error);
      return 0;
    }
  };
  const getDCARDollarBalance = async () => {
    const PriceHandlerContract = getLoadPriceHandlerContract(signer.value);
    const dcar = getDCARContract(signer.value);
    try {
      if (address.value) {
        let pricePerDollar = await PriceHandlerContract.tokensPerDollar(
          dcar.address
        );
        pricePerDollar = parseFloat(ethers.utils.formatEther(pricePerDollar));
        const valueInDollars = dcarBalance.value / pricePerDollar;
        return valueInDollars.toFixed(3);
      }
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const getDCAUPricePerDollar = async () => {
    const PriceHandlerContract = getLoadPriceHandlerContract(signer.value);
    const dcau = getDCAUContract(signer.value);
    try {
      if (address.value) {
        let pricePerDollar = await PriceHandlerContract.tokensPerDollar(
          dcau.address
        );
        pricePerDollar = parseFloat(ethers.utils.formatEther(pricePerDollar));
        return pricePerDollar.toFixed(3);
      }
    } catch (error) {
      console.log(error);
      return 0;
    }
  };
  const getDCAUPricePerDollarViaProvider = async (provider) => {
    try {
      const PriceHandlerContract = getLoadPriceHandlerContract(provider);
      const dcau = getDCAUContract(provider);
      let pricePerDollar = await PriceHandlerContract.tokensPerDollar(
        dcau.address
      );
      pricePerDollar = parseFloat(ethers.utils.formatEther(pricePerDollar));
      return pricePerDollar.toFixed(3);
    } catch (error) {
      console.log(error);
      return 0;
    }
  };
  const getDCARPricePerDollar = async () => {
    const PriceHandlerContract = getLoadPriceHandlerContract(signer.value);
    const dcar = getDCARContract(signer.value);
    try {
      if (address.value) {
        let pricePerDollar = await PriceHandlerContract.tokensPerDollar(
          dcar.address
        );
        pricePerDollar = parseFloat(ethers.utils.formatEther(pricePerDollar));
        return pricePerDollar.toFixed(3);
      }
    } catch (error) {
      console.log(error);
      return 0;
    }
  };
  const getDCARPricePerDollarViaProvider = async (provider) => {
    try {
      const PriceHandlerContract = getLoadPriceHandlerContract(provider);
      const dcar = getDCARContract(provider);
      let pricePerDollar = await PriceHandlerContract.tokensPerDollar(
        dcar.address
      );
      pricePerDollar = parseFloat(ethers.utils.formatEther(pricePerDollar));
      return pricePerDollar.toFixed(3);
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const getGasBalance = async () => {
    try {
      const gasBalance = await signer.value.getBalance();
      return ethers.utils.formatEther(gasBalance);
    } catch (error) {
      console.log(error);
    }
  };

  const getBonusDetails = async () => {
    try {
      const response = await fetch(Constants.apiUrl + "bonusrequirement", {
        method: "POST",
        body: JSON.stringify({
          account: address.value.toLowerCase(),
          sessionId: localStorage.getItem("sessionId"),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success) {
        console.log("BONUS % DATA", data);
        bonusPercentage.value = data.bonus;
        tokensRequired.value = data.tokensRequired;
      }
      // store.commit("setBonusPercentage", dcauBalance.value);
    } catch (error) {
      console.log(error);
    }
  };

  const updateBalances = () => {
    getDCARBalance();
    getDCAUBalance();
    getBonusDetails();
  };

  return {
    dcauBalance,
    dcarBalance,
    copperBalance,
    bonusPercentage,
    tokensRequired,
    getDCAUBalance,
    getDCARBalance,
    getBonusDetails,
    updateBalances,
    getDCAUDollarBalance,
    getDCARDollarBalance,
    getDCAUPricePerDollar,
    getDCAUPricePerDollarViaProvider,
    getDCARPricePerDollar,
    getDCARPricePerDollarViaProvider,
    getGasBalance,
    getCopperBalance,
  };
}
