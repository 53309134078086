<template>
  <div
    class="card-container"
    :class="flip ? 'flip' : ''"
    @mouseenter="playOnHover"
  >
    <div class="card">
      <div class="card-front">
        <div class="character-card" :class="isSelected ? 'selected' : ''">
          <div class="main-data">
            <div class="image-holder">
              <img :src="character.image" :alt="character.number" />
            </div>
            <div class="meta-card">
              <div class="hero-details">
                <span class="number"> #{{ character.number }}<br /> </span>
                <span class="level"> Level - {{ character.stats.level }} </span>
              </div>
              <div class="bars">
                <div class="hp">
                  <div
                    class="hp-bar"
                    :style="{
                      width:
                        (character.stats.hp / character.stats.totalHp) * 100 +
                        '%',
                    }"
                  ></div>
                  <span class="hp-stat"
                    >{{ character.stats.hp }}/{{
                      character.stats.totalHp
                    }}</span
                  >
                </div>
                <div class="turns">
                  <div
                    class="turns-bar"
                    :style="{
                      width:
                        (character.stats.turns / character.stats.totalTurns) *
                          100 +
                        '%',
                    }"
                  ></div>
                  <span class="turns-stat"
                    >{{ character.stats.turns }}/{{
                      character.stats.totalTurns
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="hero-stats">
            <h5 class="small-header">Character Stats</h5>
            <div class="grid">
              <div class="stats main-stats">
                <div class="stat-item">
                  <span class="stat-name">Attack</span>
                  <span class="stat-value">{{ character.stats.attack }}</span>
                </div>
                <div class="stat-item">
                  <span class="stat-name">Defense</span>
                  <span class="stat-value">{{ character.stats.defense }}</span>
                </div>
                <div class="stat-item">
                  <span class="stat-name">Speed</span>
                  <span class="stat-value">{{ character.stats.speed }}</span>
                </div>
                <div class="stat-item">
                  <span class="stat-name">Endurance</span>
                  <span class="stat-value">{{
                    character.stats.endurance
                  }}</span>
                </div>
                <div class="stat-item">
                  <span class="stat-name">Luck</span>
                  <span class="stat-value">{{ character.stats.luck }}</span>
                </div>
                <div class="stat-item">
                  <span class="stat-name">Magic</span>
                  <span class="stat-value">{{ character.stats.magic }}</span>
                </div>
              </div>
              <div class="stats misc-stats">
                <!-- <h5 class="small-header">Misc Stats</h5> -->
                <div class="stat-item">
                  <span class="stat-name">Crafting</span>
                  <span class="stat-value">{{ character.stats.crafting }}</span>
                </div>
                <div class="stat-item">
                  <span class="stat-name">Gathering</span>
                  <span class="stat-value">{{
                    character.stats.gathering
                  }}</span>
                </div>
                <div class="stat-item">
                  <span class="stat-name">Critical Hit Chance</span>
                  <span class="stat-value">{{
                    character.stats.criticalChance
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="button-container toggle-button">
          <BlueButton @click="toggleCard" size="extra-small ">{{
            isSelected ? "Deselect" : "Select"
          }}</BlueButton>
          <BlueButton @click="flip = !flip" size="extra-small "
            >More Options</BlueButton
          >
        </div>
      </div>
      <div class="card-back">
        <div class="card-back-content">
          <div class="card-back-header">
            <!-- <NameChange /> -->
            <div class="card-back-footer">
              <p>View Your Hero On</p>
              <div class="links">
                <a href="#">NFTrade</a> | <a href="#">Joepegs</a> |
                <a href="#">Kalao</a>
              </div>
            </div>
          </div>
        </div>
        <button class="close-button button-small" @click="flip = !flip">
          ×
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import { useStore } from "vuex";
import { onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import BlueButton from "../Buttons/BlueButton.vue";
// import NameChange from "./NameChange.vue";

export default {
  name: "CharacterCard",
  props: {
    character: {},
  },
  components: {
    BlueButton,
    // NameChange,
  },
  setup(props) {
    const store = useStore();
    const isSelected = ref(false);
    const flip = ref(false);
    let unwatchSelected = null;

    onMounted(() => {
      isSelected.value = isCharacterSelected();

      unwatchSelected = store.watch(
        (state) => state.characterSelect.selectedCharacters.length === 0,
        async () => {
          isSelected.value = isCharacterSelected();
        }
      );
    });

    onUnmounted(() => {
      unwatchSelected();
    });

    const playOnHover = () => {
      const onSelectSound = new Audio(
        "https://cdn.dragoncrypto.io/sound/break.mp3"
      );
      onSelectSound.volume = store.state.soundVolume;
      onSelectSound.play();
    };
    const playOnSelect = () => {
      const onSelectSound = new Audio(
        "https://cdn.dragoncrypto.io/sound/short-choir.mp3"
      );
      onSelectSound.volume = store.state.soundVolume;
      onSelectSound.play();
    };

    const toggleCard = () => {
      console.log(isSelected.value);
      if (isSelected.value) {
        store.commit(
          "characterSelect/removeFromSelectedCharacters",
          props.character.number
        );
        isSelected.value = false;
      } else {
        if (store.state.characterSelect.selectedCharacters.length < 5) {
          isSelected.value = true;
          store.commit(
            "characterSelect/addToSelectedCharacters",
            props.character
          );
          playOnSelect();
        } else {
          store.commit(
            "setNotification",
            "🛑 You are allowed to play with only 5 heroes max 🛑"
          );
        }
      }
    };

    const isCharacterSelected = () => {
      return store.getters["characterSelect/isCharacterSelected"](
        props.character.number
      );
    };

    return {
      flip,
      toggleCard,
      isSelected,
      playOnHover,
    };
    // const store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
$glowSize: 10px;

.card-container {
  position: relative;
  perspective: 300px;
  .card {
    position: relative;
    transition: transform 0.7s;
    transform-style: preserve-3d;
  }
  .card-back {
    /* transform-style: preserve-3d; */
    backface-visibility: hidden;
    transform: rotateY(180deg);
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    border: 1px solid #282828;
    top: 0;
    left: 0;
    z-index: 0;
    .close-button {
      position: absolute;
      top: -15px;
      right: -15px;
      box-shadow: none;
      border: none;
      font-size: 1.5rem;
      /* position: absolute;
      z-index: 1;
      text-align: center;
      font-size: 1.5rem;
      border-radius: 100%;
      padding: 0px 6px;
      background: #275c89;
      box-shadow: none;
      border: none;
      top: -10px;
      right: -10px;
      color: #fff; */
      &:hover {
        cursor: url("~@/assets/ui/gauntlet_pointy_cursor_gray.png"), auto;
      }
    }
    .card-back-footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 1rem;
      border-top: 1px solid #525252;
      p {
        margin-top: 0;
      }
      a {
        text-decoration: none;
      }
    }
  }
  .card-front {
    backface-visibility: hidden;
    z-index: 1;
    position: relative;
  }
  &.flip {
    .card {
      transform: rotateY(180deg);
    }
    .card-front {
      z-index: 0;
    }
    .card-back {
      display: block;
      z-index: 1;
    }
    .toggle-button {
      display: none;
    }
    &:hover {
      .toggle-button {
        display: none;
      }
    }
  }
  .toggle-button {
    position: absolute;
    top: 272px;
    left: 0;
    z-index: 1;
    display: none;
    width: 100%;
    transform: translateY(-50%);
    background: #000000d6;
    grid-gap: 15px;
    padding: 15px 0;
    button {
      width: 200px;
      height: 35px;
      font-size: 0.9rem;
      line-height: 2.4rem;
      &:hover {
        background-position: top center;
      }
    }

    @media only screen and (max-width: 576px) {
      display: block;
      background: none;
      top: 0;
      left: 0;
      margin-top: 20px;
      transform: none;
      position: relative;
    }
  }
  &:hover {
    .toggle-button {
      display: grid;
    }
    .character-card {
      @media only screen and (max-width: 576px) {
        transform: none;
        box-shadow: none;
      }
      box-shadow: 0 0 5px #000;
      transform: scale(1.02);
    }
  }
  .character-card {
    width: 250px;
    grid-gap: 0.5rem;
    align-items: self-start;
    background-color: #0000003d;
    padding: 0.5rem;
    border: 5px solid #ffffff1c;
    box-shadow: 0 0 5px #000;
    border-radius: 2px;
    position: relative;
    margin: 10px 0;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    transition: scale 0.1s ease-out;
    &.selected {
      background-color: #151111;
      box-shadow: 0 0 5px #000;
      border-color: #e3bf55bd;

      /* &::before {
        content: "";
        position: absolute;
        left: -5px;
        top: -5px;
        filter: blur(7px);
        background: #f7ff00;
        background: linear-gradient(to right, #fcffa8, #ffd500);

        background-size: 400% 400%;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        z-index: -1;
        animation: animateGlow 3s ease-in-out infinite;
      } */
    }
    .image-holder {
      min-height: 224px;
      min-width: 224px;
      position: relative;
      span {
        position: absolute;
        width: 100%;
        background: rgba(0, 0, 0, 0.329);
        bottom: 6px;
        font-size: 1rem;
        padding: 0.3rem 0;
      }
    }
    img {
      width: 100%;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
    }
    .progress {
      width: 100%;
      position: relative;
      display: grid;
      align-items: center;
      height: 18px;
      span {
        z-index: 1;
        position: relative;
        font-size: 0.7rem;
        font-weight: bold;
      }
      progress[value] {
        width: 100%;
        height: 18px;
        display: block;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: absolute;
        background: url("https://cdn.dragoncrypto.io/uiassets/selected-player-hpbar.png");
      }
    }
    h5.small-header {
      height: 30px;
      background: url("https://cdn.dragoncrypto.io/uiassets/small-header-bg.webp")
        repeat center center;
      display: grid;
      align-items: center;
      box-shadow: inset 0 0 10px 0 #000;
      border: 2px #4e4134 solid;
      margin: 5px 0;
      color: #be9177;
      font-family: "Lato", sans-serif;
      text-transform: capitalize;
      font-weight: bold;
      text-shadow: 0 0 2px #000;
    }

    .hero-stats {
      div.grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        margin-top: 10px;
        @media only screen and (max-width: 576px) {
          display: none;
        }
        .stats {
          width: 113px;
          padding: 0 5px;
        }
      }
    }
    .stat-item {
      display: grid;
      grid-template-columns: 80% 1fr;
      grid-gap: 5px;
      align-items: center;
      margin: 5px;
      align-items: center;
      padding: 0 3px 3px;
      font-family: "Lato", sans-serif;
      color: #8a796e;
      border-bottom: 1px solid rgba(255, 255, 255, 0.143);
      font-size: 0.8rem;
      &:last-child {
        border-bottom: none;
      }
      .stat-name {
        text-align: left;
      }
      .stat-value {
        text-align: right;
        color: #fff;
      }
    }
    .meta-card {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 5px;
      .hero-details {
        span {
          display: block;
        }
      }
      .bars {
        position: relative;
        .hp {
          width: 100%;
          height: 18px;
          position: absolute;
          left: 0;
          top: 3px;
        }

        .turns {
          position: absolute;
          width: 100%;
          height: 18px;
          left: 0;
          top: 22px;
        }

        .turns-stat,
        .hp-stat {
          font-size: 70%;
          top: -1px;
          left: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 2px #000;
          font-weight: bold;
          line-height: 18px;
        }

        .hp-bar {
          background: url("https://cdn.dragoncrypto.io/uiassets/selected-player-hpbar.png")
            no-repeat top left;
        }

        .turns-bar {
          background: url("https://cdn.dragoncrypto.io/uiassets/selected-player-turnbar.png")
            no-repeat top left;
        }

        .hp-bar,
        .turns-bar {
          position: absolute;
          top: 3px;
          left: -3px;
          width: 100%;
          height: 13px;
          transition: width 0.35s ease-out;
          background-size: cover;
        }
      }
    }
  }
}

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
</style>
