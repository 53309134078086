<template>
  <div class="reward-wrapper">
    <BasicPopup :class="['guild-inventory']" header="Your Rewards">
      <div :class="['stash-panel']">
        <div
          :class="['close-button', 'button-small']"
          @click="hide"
          title="Close"
        >
          <span>x</span>
        </div>
        <div class="items-wrapper">
          <div class="items-container" v-if="reward">
            <div class="grid">
              <h4>DCAR Reward</h4>
              <div class="balances">
                <div class="dcar" id="dcar-balance">
                  <p>
                    {{ reward.earning.toFixed(3) }}
                  </p>
                </div>
              </div>
            </div>
            <template
              v-if="reward.lootItem !== undefined && reward.lootItem !== null"
            >
              <div class="grid" v-if="reward.lootItem?.type == 'resource'">
                <h4>{{ reward.lootItem?.name }}</h4>
                <div class="additional-reward">
                  <span>
                    <img
                      :src="`https://ik.imagekit.io/dcg/equip/${reward.lootItem?.imageName}?tr=w-25`"
                    />
                    {{ reward.lootItem?.quantity }}
                  </span>
                </div>
              </div>
              <div
                class="grid"
                v-else-if="quest.lootItem?.type == 'consumable'"
              >
                <h4>{{ reward.lootItem?.name }}</h4>
                <div class="additional-reward">
                  <span>
                    <img
                      :src="`https://ik.imagekit.io/dcg/equip/${quest.lootItem?.imageName}?tr=w-25`"
                    />
                    {{ reward.lootItem?.quantity }}
                  </span>
                </div>
              </div>
              <div class="grid" v-else-if="quest.lootItem?.type == 'blueprint'">
                <h4>{{ reward.lootItem?.name }}</h4>
                <div class="additional-reward">
                  <span>
                    <img
                      :src="`https://ik.imagekit.io/dcg/blueprints/${quest.lootItem?.imageName}?tr=w-25`"
                    />
                    BP
                  </span>
                </div>
              </div>
            </template>
            <div class="grid">
              <h4>Influence Points</h4>
              <div class="influence-point">
                {{ reward.influenceGain }}
              </div>
            </div>
            <div class="grid">
              <h4>Exp Gained</h4>
              <div class="influence-point">
                {{ reward.exp }}
              </div>
            </div>
          </div>
        </div>
        <div class="buttons-container">
          <p>Note: Your reward are added to your stash.</p>
        </div>
      </div>
      <Celebrate type="stars" />
    </BasicPopup>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import BasicPopup from "../Popup/BasicPopup.vue";
import Celebrate from "../Celebrate/Celebrate.vue";
import { useSound } from "../../composables/sound";
import finishQuest from "../../assets/sound/quest-complete.wav";

export default {
  name: "GuildRewards",
  components: {
    BasicPopup,
    Celebrate,
  },
  props: {},
  emits: ["refreshContracts"],
  setup(props, { emit }) {
    const store = useStore();
    const reward = ref(null);
    const playOnQuestComplete = useSound(finishQuest, {
      volume: store.state.soundVolume,
      interrupt: true,
    });

    onMounted(() => {
      reward.value = store.state.guild.guildQuestRewards;
      playOnQuestComplete.play();
    });
    const hide = () => {
      store.commit("guild/setIsGuildRewardsOpen", false);
      store.commit("guild/setGuildQuestRewards", null);
      store.dispatch("guild/resetItems");
      emit("refreshContracts");
    };

    return {
      hide,
      reward,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.reward-wrapper {
  &:after {
    content: "";
    display: block;
    background: #000000d6;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 13;
  }
}
.guild-inventory {
  width: calc(400px - $popup-border-size - $popup-border-size);
  height: auto;
  min-height: auto;
  z-index: 14;
  display: block;
  .close-button {
    top: -80px;
    right: -80px;
    position: absolute;
    &.loading {
      filter: grayscale(100);
      opacity: 0.5;
    }
  }

  .buttons-container {
    position: absolute;
    bottom: calc(-1 * calc($popup-border-size / 2));
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    width: calc(388px - $popup-border-size - $popup-border-size);
    p {
      margin: 5px 0;
      font-size: 0.6rem;
      color: #ffffff8a;
    }
  }
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  align-items: center;
  padding: 0.5rem 1rem;
  background: #272727;
  margin-bottom: 20px;
  border-radius: 10px;
  h4 {
    text-align: left;
  }
  .balances {
    div {
      display: grid;
      grid-template-columns: 46px 1fr;
      grid-gap: 1px;
      align-items: center;
      justify-items: flex-start;
      font-size: 1.2rem;
      &:before {
        background-size: contain;
        content: "";
        display: inline-block;
        width: 46px;
        height: 46px;
        line-height: 0;
        margin-top: -8px;
        padding: 0;
        top: 7px;
        position: relative;
        margin-right: 4px;
      }
      p {
        margin: 0;
        span {
          font-size: 0.7rem;
          display: block;
          margin: 2px 0 0;
          color: #ffffff85;
        }
      }
    }
    .dcau {
      &:before {
        background: url("../../assets/ui/dcau.png") no-repeat left center;
      }
    }
    .dcar {
      &:before {
        background: url("../../assets/ui/dcar.png") no-repeat left center;
      }
    }
  }
  .additional-reward {
    display: grid;
    justify-content: center;
    align-items: center;
    img {
      top: 6px;
      position: relative;
    }
  }
}
</style>
