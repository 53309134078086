<template>
  <div :class="['crafting-guild-wrapper', showGuildMenu ? 'guild-menu' : '']">
    <BasicPopup
      :class="getClass"
      :header="showGuildMenu ? 'Welcome to the Crafter\'s Guild' : 'Crafting'"
    >
      <button
        :class="['close-button', 'button-small', isLoading ? 'loading' : '']"
        :disabled="isLoading"
        @click="leave"
      >
        <span>x</span>
      </button>
      <DialogButton
        v-if="!showGuildMenu"
        class="back"
        @click="showGuildMenu = !showGuildMenu"
        buttonSize="small"
        buttonText="Back"
        :disabled="isTurnHappening"
      />
      <div class="wrapper" v-if="showGuildMenu">
        <div class="grid select-option">
          <div class="crafting box">
            <h3>Self Crafting</h3>
            <p>
              Use blueprints to create new equipment and consumables using
              resources.
            </p>
            <BlueButton
              id="self-crafting-button"
              @click="openSelfCraft"
              size="small"
              >Crafting</BlueButton
            >
          </div>
          <div class="crafters box">
            <h3>Crafting Market</h3>
            <p>
              Visit the crafting market to take up craft jobs for equipment
              items and earn $DCAR.
            </p>
            <BlueButton size="small" @click="openCraft"
              >Crafting Market</BlueButton
            >
          </div>
          <div class="repair box">
            <h3>Repair Market</h3>
            <p>
              Visit the repair market to take up repair jobs for broken
              equipment and earn $DCAR.
            </p>
            <BlueButton size="small" @click="openRepair"
              >Repair Market</BlueButton
            >
          </div>
        </div>
        <div class="crafters-quest">
          <div class="crafting box">
            <div>
              <h3>Crafter's Quests</h3>
              <p>Complete exclusive crafter's quests and earn rewards!</p>
            </div>
            <BlueButton
              id="crafters-quest-button"
              @click="openCraftersQuests"
              size="small"
              >Open</BlueButton
            >
          </div>
        </div>
      </div>
      <template v-else>
        <div v-if="!isCraftingComplete" class="inner-dialog-wrapper">
          <div class="crafting-container-wrapper">
            <div class="crafting-items">
              <div class="content-wrapper">
                <div class="blueprint-input">
                  <h3>
                    {{
                      bluePrintSlot
                        ? bluePrintSlot.name.replace("Blueprint for", "")
                        : "Blueprint to Craft"
                    }}
                  </h3>
                  <p>
                    <span v-if="!bluePrintSlot"
                      >Drag a blueprint in to the slot below to create your
                      recipe.</span
                    >
                  </p>
                  <div
                    id="drag-blueprint-here"
                    :class="[
                      'craft-details',
                      bluePrintSlot ? 'has-blueprint' : '',
                    ]"
                  >
                    <div>
                      <EquipmentTile
                        :isBlueprintSlot="true"
                        :size="'large'"
                        :item="bluePrintSlot"
                      />
                      <p v-if="!bluePrintSlot">
                        Crafting an item costs
                        <span class="highlight"
                          >{{ getCraftCost * getAmountToCraft }}
                        </span>
                        DCAR (Less for shoddy equipment and consumables) .
                      </p>
                    </div>
                    <div
                      v-if="bluePrintSlot && bluePrintSlot.numberCrafts != null"
                      class="crafting-controls"
                    >
                      <div class="control-item">
                        <span class="sub-header"
                          >Max crafts:
                          {{
                            bluePrintSlot.numberCrafts -
                            bluePrintSlot.craftsUsed
                          }}</span
                        >
                        <BlueButton @click="maxNumberCrafts" size="extra-small"
                          >Max Craft</BlueButton
                        >
                      </div>
                      <div class="control-item">
                        <span class="sub-header">Amount to craft:</span>
                        <div class="craft-amount-selector">
                          <div>
                            <RoundButton
                              size="small"
                              class="quantity-button"
                              :disabled="!(getAmountToCraft > 1)"
                              @click="decrementNumberCrafts"
                            >
                              -
                            </RoundButton>
                          </div>
                          <span class="amount-to-craft">
                            {{ getAmountToCraft }}
                          </span>

                          <div>
                            <RoundButton
                              size="small"
                              class="quantity-button"
                              :disabled="
                                !(
                                  getAmountToCraft <
                                  bluePrintSlot.numberCrafts -
                                    bluePrintSlot.craftsUsed
                                )
                              "
                              @click="incrementNumberCrafts"
                            >
                              +
                            </RoundButton>
                          </div>
                        </div>
                      </div>
                      <div class="control-item">
                        <span class="sub-header highlight">Total Cost</span>
                        <span class="dcar-amount">
                          <span>{{ getCraftCost * getAmountToCraft }}</span>
                          DCAR
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="bluePrintSlot" class="bottom-area">
                  <div
                    v-if="bluePrintSlot"
                    id="blueprint-resources"
                    class="craft-wrapper"
                  >
                    <GenericLoading
                      class="loading"
                      v-if="isGettingCraftingSlots"
                    />

                    <template
                      v-else
                      v-for="(item, index) in craftingSlots"
                      :key="index"
                    >
                      <div>
                        <div class="craft-item">
                          <EquipmentTile
                            :isCrafting="true"
                            :size="'med'"
                            :item="item.currentItem"
                            :craftSlot="item"
                          />
                          <div v-if="!item.currentItem" class="hover-item-info">
                            {{ item.name }}
                          </div>
                        </div>

                        <span
                          :class="[
                            'required-quantity',
                            getSlotValidity(item) ? 'valid' : 'invalid',
                          ]"
                          >{{ getSlotItemQuantity(item) }} /
                          {{ item.quantityRequired * getAmountToCraft }}</span
                        >
                      </div>
                    </template>
                    <div
                      :class="
                        'craft-item result ' +
                        (isCraftingSlotsFilled && getCraftResult
                          ? 'visible'
                          : '')
                      "
                    >
                      <EquipmentTile
                        v-if="isCraftingSlotsFilled && getCraftResult"
                        :isCrafting="true"
                        :size="'large'"
                        :item="getCraftResult"
                      />
                      <span
                        v-if="
                          isCraftingSlotsFilled &&
                          getCraftResult &&
                          getCraftResult.quantity
                        "
                      >
                        x {{ getCraftResult.quantity * getAmountToCraft }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="warning"
                    v-if="
                      bluePrintSlot &&
                      (!getHasEnoughSkill || !getHasEnoughResources)
                    "
                  >
                    <span v-if="!getHasEnoughSkill">
                      You do not have enough skill to craft this item.
                    </span>
                    <!-- <span v-if="!getHasEnoughResources">
                    You do not have enough resources to craft this item.
                  </span> -->
                  </div>
                  <div
                    v-if="bluePrintSlot && bluePrintSlot.numberCrafts != null"
                    class="buttons"
                    id="create-item-button"
                  >
                    <DialogButton
                      v-if="parseFloat(allowance) < parseFloat(totalCraftCost)"
                      :requiresTurn="false"
                      @buttonClick="increaseAllowance"
                      :isLoading="isLoading"
                      :disabled="isLoading"
                      :buttonText="'Approve DCAR'"
                      :buttonSize="'large'"
                    />

                    <DialogButton
                      v-else-if="approvedResources == false"
                      :requiresTurn="false"
                      @buttonClick="approveResources"
                      :isLoading="isLoading"
                      :disabled="isLoading"
                      :buttonText="'Approve NFT'"
                      :buttonSize="'large'"
                    />
                    <DialogButton
                      v-else-if="
                        parseFloat(allowance) > parseFloat(totalCraftCost) &&
                        approveResources
                      "
                      :requiresTurn="true"
                      @buttonClick="craftItem"
                      :isLoading="isTurnHappening"
                      :disabled="isTurnHappening || !canCraft"
                      :buttonText="!canCraft ? reason : 'Create Item (1 TURN)'"
                      :buttonSize="'large'"
                      :title="
                        getHasEnoughResources
                          ? 'Create Item By Consuming Resources'
                          : 'You do not have enough resources to craft this item.'
                      "
                    />
                    <DialogButton
                      @click="craftRemove"
                      :buttonText="'Remove'"
                      :buttonSize="'small'"
                      :disabled="isTurnHappening || isLoading"
                      :isLoading="isLoading"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-else-if="getCraftResult && isCraftingComplete">
          <CraftingSuccess :item="getCraftResult" />
        </template>
      </template>
    </BasicPopup>
  </div>
</template>
<script>
import { io } from "socket.io-client";
import { computed, onMounted, ref, onUnmounted } from "vue";
import { useStore } from "vuex";
import Constants from "../consts/constants";
import BlueButton from "./Buttons/BlueButton.vue";
import RoundButton from "./Buttons/RoundButton.vue";
import CraftingSuccess from "./CraftingSuccess.vue";
import DialogButton from "./DialogButton.vue";
import EquipmentTile from "./EquipmentTile.vue";
import GenericLoading from "./LoadingComponents/GenericLoading.vue";
import BasicPopup from "./Popup/BasicPopup.vue";

import { ethers } from "ethers";
import { usePrice } from "../composables/price";
import { useUser } from "../composables/user";
import {
  getDCARContract,
  getDCGResourcesContract,
  getLoadCraftingContract,
} from "../utils/getContract";

export default {
  /* eslint-disable */
  name: "Forge",
  components: {
    DialogButton,
    EquipmentTile,
    BasicPopup,
    RoundButton,
    BlueButton,
    GenericLoading,
    CraftingSuccess,
  },
  props: {},
  setup() {
    const store = useStore();
    const socket = io(Constants.apiUrl, { transports: ["websocket"] });
    const showGuildMenu = ref(true);

    const { address, signer } = useUser();
    const { updateBalances } = usePrice();
    const DCARContract = getDCARContract(signer.value);
    const CraftingContract = getLoadCraftingContract(signer.value);
    const ResourcesContract = getDCGResourcesContract(signer.value);

    const allowance = ref(0);
    const allowanceShow = ref(false);
    const approvedResources = ref(false);
    const approvedResourcesShow = ref(false);
    const dcarBalance = ref(0);
    const isLoading = ref(false);

    let unwatchCraftingSlots = null;
    let unwatchcurrentCharacter = null;

    onMounted(() => {
      unwatchCraftingSlots = store.watch(
        (state) => state.craftingSlots,
        (value) => {
          if (
            value.every(
              (slot) => slot.currentItem || (!slot.currentItem && slot.isSocket)
            )
          ) {
            if (store.state.craftBlueprintItem) {
              store.commit("setGetCraftingResult");
            }
          }
        },
        { deep: true }
      );

      unwatchcurrentCharacter = store.watch(
        (state) => state.characters[state.currentCharacter],
        () => {
          if (store.state.craftBlueprintItem) {
            store.commit("sendItemToCraft", store.state.craftBlueprintItem);
            store.commit("setGetCraftingResult");
          }
        },
        { deep: true }
      );

      socket.emit("join", { sessionId: localStorage.getItem("sessionId") });

      socket.on("connect", () => {
        console.log("connected to socket");
      });

      socket.on("craft", (results) => {
        if (results.success) {
          store.commit("setNotification", `Crafted ${results.item.name}!`);
          store.commit("setInventory", results.inventory);
          store.commit("setStash", results.stash);
          store.commit("setHeroStats", results.heroStats);
          store.commit("setTurnHappening", false);
        } else {
          store.commit("setNotification", results.message);
        }
      });

      socket.io.on("error", () => {
        console.log(
          "Unable to connect to server. Please try again or let the team know"
        );
      });

      // check dcar approval
      checkAllowance();
      // check crafting approval
      checkResourceApproval();

      checkUserDcarBalance();
    });

    onUnmounted(() => {
      unwatchCraftingSlots();
      unwatchcurrentCharacter();
      socket.removeAllListeners(["connect", "craft", "error"]);
      socket.disconnect();
    });
    /**
     * Methods
     */
    const openCraftersQuests = () => {
      store.commit("guild/setIsGuildOpen", true); // Hiding in mobile view as well
      store.commit("guild/setGuildType", "crafters"); // Hiding in mobile view as well
      leave();
    };
    const getSlotItemQuantity = (item) => {
      let quantity = 0;
      if (item.currentItem) {
        quantity = item.currentItem.quantity;
      }
      return quantity;
    };

    const openRepair = () => {
      store.commit("setRepairRoom");
    };

    const openCraft = () => {
      store.commit("setCraftRoom");
    };

    const openSelfCraft = () => {
      showGuildMenu.value = !showGuildMenu.value;
      store.commit("intro/setIsInSelfCrafting", true);
    };

    const getSlotValidity = (item) => {
      let isValid = false;
      if (
        getSlotItemQuantity(item) >=
        item.quantityRequired * getAmountToCraft.value
      ) {
        isValid = true;
      }
      return isValid;
    };
    const checkAllowance = async () => {
      let allowanceCrafting = await DCARContract.allowance(
        address.value,
        CraftingContract.address
      );

      allowanceCrafting = ethers.utils.formatUnits(allowanceCrafting);

      if (parseFloat(allowanceCrafting) > 0) {
        allowance.value = parseFloat(allowanceCrafting);
        allowanceShow.value = true;
      }
    };

    const checkUserDcarBalance = async () => {
      const balance = await DCARContract.balanceOf(address.value);
      dcarBalance.value = ethers.utils.formatEther(balance);
    };

    const increaseAllowance = async () => {
      isLoading.value = true;

      try {
        const txApproveCrafting = await DCARContract.approve(
          CraftingContract.address,
          ethers.utils.parseEther("1000000000")
        );

        const receipt = await txApproveCrafting.wait();
        if (receipt.status) {
          await checkAllowance();
          isLoading.value = false;
        }
      } catch (error) {
        console.log(error);
        isLoading.value = false;
      }
    };

    const checkResourceApproval = async () => {
      let isCraftingApproved = await ResourcesContract.isApprovedForAll(
        address.value,
        CraftingContract.address
      );

      if (isCraftingApproved) {
        approvedResources.value = true;
        approvedResourcesShow.value = true;
      }
    };

    const approveResources = async () => {
      isLoading.value = true;
      try {
        const txApproveResources = await ResourcesContract.setApprovalForAll(
          CraftingContract.address,
          true
        );

        const receipt = await txApproveResources.wait();
        if (receipt.status) {
          await checkResourceApproval();
          isLoading.value = false;
        }
      } catch (error) {
        isLoading.value = false;
        console.log(error);
      }
    };
    const craftRemove = () => {
      store.commit("setRemoveCraftingResources");
    };
    const leave = () => {
      store.commit("setWandering");
      if (isCraftingComplete.value) {
        store.commit("setResetCraftSuccessMessage");
      }
    };
    const incrementNumberCrafts = () => {
      store.commit("incrementNumberCrafts");
    };
    const decrementNumberCrafts = () => {
      store.commit("decrementNumberCrafts");
    };
    const maxNumberCrafts = () => {
      store.state.amountToCraft =
        store.state.craftBlueprintItem.numberCrafts -
        store.state.craftBlueprintItem.craftsUsed;

      // Calling get Crafting Result once Max Number of Crafts is clicked
      if (bluePrintSlot.value) {
        store.commit("setGetCraftingResult");
      }
    };
    const signCraftingItem = async () => {
      const resourceIds = store.state.craftingSlots
        .filter((s) => s.currentItem)
        .map((s) => s.currentItem.staticId);
      const sessionId = localStorage.getItem("sessionId");
      const heroId = character.value.number;
      const blueprintId = bluePrintSlot.value.id;
      const amountToCraft = store.state.amountToCraft;

      const response = await fetch(
        Constants.apiUrl + "contract/sign-crafting",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId,
            heroId,
            blueprintId,
            resourceIds,
            account: address.value,
            amountToCraft,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log(data);
      return {
        craftId: data.craftId,
        dcarAmount: data.dcarAmount,
        resourceCount: data.resourceCount,
        resourceIds: data.resourceIds,
        quantities: data.quantities,
        signature: data.signature,
        nonce: data.nonce,
      };
    };
    const craftItem = async () => {
      store.commit("setTurnHappening", true);
      const {
        craftId,
        dcarAmount,
        resourceCount,
        resourceIds,
        quantities,
        signature,
        nonce,
      } = await signCraftingItem();
      try {
        // Only proceed if resoruces are approved
        // Executing Crafting Transaction
        const tx = await CraftingContract.craft(
          craftId,
          dcarAmount,
          resourceCount,
          resourceIds,
          quantities,
          signature,
          nonce
        );
        const receipt = await tx.wait();
        // Only process Heal if the transaction was successful!
        if (receipt.status === 1) {
          updateBalances(); // Aynschronously updating token balances
          store.commit("setCraftItemFromBlueprint");
        }
      } catch (error) {
        console.log(error);
        store.commit("setTurnHappening", false);
      }
    };
    /**
     * Computed
     */
    const craftResources = computed(() => {
      return store.state.craftResources();
    });
    const canCraft = computed(() => {
      return getCraftResult.value && getCraftCost.value <= dcarBalance.value;
    });
    const reason = computed(() => {
      if (getCraftResult.value) {
        if (getCraftCost.value > dcarBalance.value) {
          return "Not enough DCAR";
        }
      } else {
        return "Not enough resources";
      }
    });
    const character = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });
    const isCraftingComplete = computed(() => {
      return store.state.isCraftingComplete;
    });
    const isTurnHappening = computed(() => {
      return store.state.isTurnHappening;
    });
    const getCraftResult = computed(() => {
      return store.state.craftResult;
    });
    const getCraftCost = computed(() => {
      return store.state.craftCost;
    });
    const getHasEnoughSkill = computed(() => {
      if (store.state.craftBlueprintItem) {
        const playerCrafting =
          store.state.characters[store.state.currentCharacter].stats.crafting;
        const bonusCrafting = store.state.equipmentStats().crafting;

        const craftingRequired = store.state.craftBlueprintItem.stats
          .craftingRequired
          ? store.state.craftBlueprintItem.stats.craftingRequired
          : 1;

        return craftingRequired <= playerCrafting + bonusCrafting;
      }
      return store.state.craftHasEnoughSkill;
    });
    const getHasEnoughResources = computed(() => {
      return store.state.craftHasEnoughResources;
    });
    const getMessage = computed(() => {
      return store.state.forgeMessage;
    });
    const craftingSlots = computed(() => {
      return store.state.craftingSlots;
    });
    //const getResourceRequired = computed(() => {
    //  return store.state.craftingSlots;
    //});
    const bluePrintSlot = computed(() => {
      return store.state.craftBlueprintItem;
    });
    const isCraftingSlotsFilled = computed(() => {
      return store.state.craftingSlots.every((slot) => {
        return slot.currentItem || (!slot.currentItem && slot.isSocket);
      });
    });
    const craftingSlotPosibilities = computed(() => {
      return store.state.craftingSlotPosibilities;
    });
    const isGettingCraftingSlots = computed(() => {
      return store.state.isGettingCraftingSlots;
    });
    const getAmountToCraft = computed(() => {
      return store.state.amountToCraft;
    });
    const totalCraftCost = computed(() => {
      return getAmountToCraft.value * store.state.craftCost;
    });
    const getClass = computed(() => {
      return (
        "dialog crafting-dialog " +
        (store.state.gameState == Constants.gamemodes.crafting
          ? "show"
          : "hide")
      );
    });

    return {
      // Data
      showGuildMenu,
      // Methods
      craftRemove,
      leave,
      craftItem,
      increaseAllowance,
      approveResources,
      incrementNumberCrafts,
      decrementNumberCrafts,
      maxNumberCrafts,
      getSlotItemQuantity,
      getSlotValidity,
      openRepair,
      openCraft,
      openSelfCraft,
      openCraftersQuests,
      // Computed Methods
      isTurnHappening,
      canCraft,
      reason,
      isCraftingComplete,
      getCraftResult,
      getHasEnoughSkill,
      getHasEnoughResources,
      getMessage,
      craftingSlots,
      bluePrintSlot,
      isCraftingSlotsFilled,
      craftingSlotPosibilities,
      getAmountToCraft,
      totalCraftCost,
      getClass,
      allowance,
      isLoading,
      approvedResources,
      getCraftCost,
      isGettingCraftingSlots,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.wrapper {
  @media only screen and (max-width: 576px) {
    height: calc(100% + $popup-border-offset) !important;
    top: -$popup-border-offset;
    position: relative;
  }
}
.dialog {
  margin-left: 16%;
  width: 700px;
  z-index: 1;
  padding-top: 35px;
  min-height: 200px;
}
.close-button {
  top: -80px;
  right: -80px;
  position: absolute;
  border: none;
  font-size: 1rem;
  &.loading {
    filter: grayscale(100);
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;
}

.highlight {
  font-weight: bold;
  color: $epic;
}
.crafting-guild-wrapper {
  background: no-repeat url("../assets/locationBackgrounds/crafters-guild.webp")
    center center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
}
.back {
  position: absolute;
  top: -40px;
  left: -36px;
  z-index: 5;
}
.select-option {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  align-items: center;
  justify-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  grid-gap: 0.5rem;

  .box {
    height: calc(100% - 3rem);
    width: calc(100% - 2rem);
    padding: 1.5rem 1rem;
    background: #171312;
    transition: all 0.2s ease-in-out;
    border-radius: 4px;
    display: grid;
    align-items: center;
    @media only screen and (max-width: 576px) {
      width: 100%;
      margin: 5px 0;
      height: auto;
    }
    &:hover {
      background: #282120;
      box-shadow: 0 0 8px 2px rgb(0 0 0 / 44%);
    }
    h3 {
      margin: 0 0 10px;
      @media only screen and (max-width: 576px) {
        font-size: 1.5rem;
      }
    }
    p {
      @media only screen and (max-width: 576px) {
        display: none;
      }
    }
  }
}
.crafters-quest {
  .box {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    padding: 1.5rem 1rem;
    background: #171312;
    border-radius: 4px;
    @media only screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
    &:hover {
      background: #282120;
      box-shadow: 0 0 8px 2px rgb(0 0 0 / 44%);
    }
    h3 {
      margin: 0 0 10px;
      @media only screen and (max-width: 576px) {
        font-size: 1.5rem;
      }
    }
    p {
      @media only screen and (max-width: 576px) {
        display: none;
      }
    }
  }
}
.crafting-container-wrapper {
  width: 100%;
  .crafting-items {
    .content-wrapper {
      .blueprint-input {
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        margin-top: 0;
        h3 {
          font-family: "IM Fell English", serif;
          text-transform: none;
          margin: 0;
        }

        .craft-details {
          width: 100%;
          .crafting-controls {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            width: calc(100% + $popup-border-size);
            left: calc(-1 * calc($popup-border-size / 2));
            position: relative;
            .control-item {
              background: rgba(36, 30, 29, 0.8588235294);
              padding: 0 0 15px;
              display: grid;
              grid-template-rows: 1fr 1fr;
              align-items: center;
              border-right: 1px solid #ffffff12;
              border-top: 1px solid #ffffff12;
              &:last-child {
                border-right: none;
              }
              span.sub-header {
                display: block;
                text-transform: uppercase;
                background: #241e1ddb;
                font-size: 0.8rem;
                color: #ffffffb0;
                margin-bottom: 11px;
                border-bottom: 1px solid #ffffff12;
                padding: 7px 0 9px;
              }
            }
          }
          /* &.has-blueprint {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            justify-items: center;
          } */
          .amount-to-craft {
            font-size: 1.5rem;
          }
          .craft-amount-selector {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            min-height: 36px;
            align-items: center;
          }
          .item {
            box-shadow: 1px 1px 8px 3px rgba(255, 255, 255, 0.7);
            background: #000;
            transition: all 0.1s ease-in-out;
            margin: 0 auto 30px;
          }
          .dcar-amount {
            display: block;
            margin-top: -18px;
            .highlight {
              color: $epic;
              margin-right: 10px;
            }
            &:before {
              margin: 0 5px 0 0;
              top: 10px;
              position: relative;
              content: "";
              display: inline-block;
              width: 30px;
              height: 30px;
              background: url("../assets/ui/dcar.png") no-repeat;
              background-size: cover;
            }
          }
        }
      }
      .bottom-area {
        background: #171312;
        box-shadow: 0 0 8px 2px rgb(0 0 0 / 44%);
        width: calc(100% + $popup-border-size);
        left: calc(-1 * calc($popup-border-size / 2));
        margin-bottom: calc(-1 * calc($popup-border-size / 2));
        position: relative;
        .buttons {
          padding: 10px 0 13px 0;
          background: rgba(0, 0, 0, 0.5411764706);
        }
      }
      .craft-wrapper {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
        padding-top: 20px;
        position: relative;
        .loading {
          position: absolute;
        }
      }
      .required-quantity {
        text-shadow: 0 1px 2px #181818;
        font-size: 0.8rem;
        &.valid {
          color: #04e204;
        }
        &.invalid {
          color: red;
        }
      }
      .warning {
        color: #fff;
        font-size: 0.9rem;
        width: 100%;
        background: #a70000;
        position: relative;
        display: block;
        span {
          display: block;
          padding: 6px 0px;
        }
      }
      .craft-item {
        border: 1px solid #3d3d40;
        background: #151413;
        border-radius: 2px;
        margin-bottom: 3px;
        display: flex;
        margin-right: 9px;
        margin-left: 9px;
        flex-wrap: nowrap;
        align-items: flex-start;
        position: relative;
        &:hover {
          .hover-item-info {
            display: block;
          }
        }
        .hover-item-info {
          display: none;
          background-color: rgb(72 21 17);
          padding: 5px;
          position: absolute;
          min-width: 130px;
          opacity: 1;
          left: 50%;
          transform: translateX(-50%);
          bottom: 68px;
          line-height: 1.8rem;
          height: auto !important;
          color: #fff;
          font-size: 0.9rem;
          box-shadow: 0 1px 4px #000;
          border: 1px #6a0c05 solid;
          border-radius: 5px;
          &:after {
            content: "";
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent #481511 transparent;
            transform: translateX(-50%) rotate(180deg);
            position: absolute;
            left: 50%;
            bottom: -11px;
          }
        }
        &.result {
          display: none;
          margin-left: 16px;

          .color {
            background-blend-mode: luminosity;
            background-size: contain;
          }

          .tint,
          .color {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
          }

          &.visible {
            display: flex;

            &::before {
              position: absolute;
              content: "=";
              font-size: 130%;
              top: calc(50% - 12px);
              color: #d79867;
              left: -19px;
            }
          }

          flex-wrap: wrap;
          position: relative;

          img {
            max-width: 56px;
          }

          span {
            position: absolute;
            width: 100%;
            text-align: right;
            font-size: 0.8rem;
            text-shadow: 0 0 2px #000, 0 1px 3px #000;
            right: -24px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

p {
  font-size: 90%;
}
</style>
