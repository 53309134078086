<template>
  <div class="progress-bar">
    <div class="shine"></div>
    <div class="progress" :style="`--progress-width: ${progress}%`"></div>
  </div>
</template>

<script>
import { onMounted } from "vue";

export default {
  name: "ProgressBar",
  props: {
    progress: {
      // small || extra-small || default large
      type: Number,
      default: 0,
      required: true,
    },
  },
  setup() {
    onMounted(async () => {
      console.log("mounted");
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

:root {
  --progress-width: 0%;
}

.progress-bar {
  background: url("../assets/ui/progress-bar.png") repeat-x center center;
  width: 100%;
  height: 15px;
  display: block;
  .shine {
    background: url("../assets/ui/glossy.png") repeat-x 0px center;
    display: block;
    width: calc(100% - 8px);
    position: absolute;
    height: 15px;
    left: 4px;
    z-index: 3;
  }
  .progress {
    background: url("../assets/ui/bar.png") repeat-x top center;
    display: block;
    width: calc(var(--progress-width) - 8px);
    position: absolute;
    height: 8px;
    z-index: 2;
    left: 4px;
    top: 3px;
    transition: all 1s ease-in-out;
  }
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    background: url("../assets/ui/progress-bar-border.png") no-repeat center top;
    width: 5px;
    height: 15px;
  }
  &:before {
    left: 0;
    top: 0;
  }
  &:after {
    right: 0;
    top: 0;
    transform: scaleX(-1);
  }
}
</style>
