<template>
  <div class="popup" :class="popupType">
    <h3 v-if="header != ''">
      <span>{{ header }}</span>
    </h3>
    <slot />
  </div>
</template>

<script>
export default {
  name: "BasicPopup",
  props: {
    popupType: {
      type: String,
    },
    header: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
// Popup Border Size is in globals as needed in other places too

.popup {
  min-width: 250px;
  min-height: 300px;
  background-image: url("https://cdn.dragoncrypto.io/popup/popup-base.webp");
  background-position: center center;
  background-repeat: repeat;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 12;
  transform: translate(-50%, -50%);
  border: $popup-border-size solid;
  border-image-source: url("https://cdn.dragoncrypto.io/popup/border.webp");
  border-image-outset: 0;
  border-image-slice: 67;
  border-image-repeat: repeat;
  padding-top: 60px;
  background-color: #171110;
  h3 {
    font-family: "IM Fell English", serif;
    color: #d79867;
    background: url("https://cdn.dragoncrypto.io/popup/header-repeat.webp")
      center center repeat-x;
    height: 48px;
    position: absolute;
    top: -67px;
    width: calc(100% + 76px);
    left: -38px;
    display: grid;
    align-items: center;
    text-transform: capitalize;
    @media only screen and (max-width: 576px) {
      font-size: 1.2rem;
    }
    span {
      z-index: 5;
    }
    &:after,
    &:before {
      content: "";
      width: 161px;
      height: 48px;
      display: block;
      position: absolute;
      background: url("https://cdn.dragoncrypto.io/popup/header-sides.webp")
        center center no-repeat;
      top: 0;
    }
    &:after {
      left: 0;
    }
    &:before {
      right: 0;
      transform: scaleX(-1);
    }
  }
  &.full {
    width: calc(100% - 134px);
    height: calc(
      100% - 194px
    ); // 100% -  67px border + 67px border + 60px padding top
  }
  &.big {
    width: calc(100% - 300px);
    height: calc(
      100% - 300px
    ); // 100% -  67px border + 67px border + 60px padding top
  }
  &.rankings {
    width: 500px;
    height: 75%;
    padding-top: 22px;
  }
}
</style>
