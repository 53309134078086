<template>
  <canvas ref="canvasRef" class="stars">stars</canvas>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import * as confetti from "canvas-confetti";

export default {
  name: "Celebrate",
  components: {},
  props: {
    type: { type: String, default: "star" },
  },
  setup(props) {
    const canvasRef = ref(null);
    const starsConfetti = ref(null);
    const timer = ref(null);
    const interval = ref(null);

    /**
     * Star Effects
     */
    const starDefaults = {
      spread: 360,
      ticks: 50,
      gravity: 0,
      decay: 0.94,
      startVelocity: 30,
      shapes: ["star"],
      colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
    };

    /**
     *  Fireworks Effects
     */
    const fireworksDefaults = {
      startVelocity: 30,
      spread: 360,
      ticks: 60,
      zIndex: 0,
      // colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
    };
    const fireworksDuration = 3 * 1000;
    const sidewaysDuration = 3 * 1000;
    const stars2Duration = 3 * 1000;

    onMounted(() => {
      starsConfetti.value = confetti.create(canvasRef.value, {
        resize: true,
        useWorker: true,
      });
      /**
       * TODO: Add more celebration options to make the component reusable
       */
      if (props.type === "stars") {
        startShootingStars();
      } else if (props.type === "fireworks") {
        fireworks();
      } else if (props.type === "sideways") {
        sidewaysFireworks();
      } else if (props.type === "stars2") {
        startShootingStars2();
      }
    });
    onUnmounted(() => {
      clearTimeout(timer.value);
      clearInterval(interval.value);
    });

    const startShootingStars = () => {
      timer.value = setTimeout(shoot, 700);
      timer.value = setTimeout(shoot, 800);
      timer.value = setTimeout(shoot, 900);
      timer.value = setTimeout(shoot, 1100);
      timer.value = setTimeout(shoot, 1300);
    };

    const startShootingStars2 = () => {
      const fireworksEnd = Date.now() + stars2Duration;

      interval.value = setInterval(function () {
        const timeLeft = fireworksEnd - Date.now();
        if (timeLeft <= 0) {
          return clearInterval(interval.value);
        }
        shoot();
      }, 250);
    };

    const sidewaysFireworks = () => {
      const fireworksEnd = Date.now() + sidewaysDuration;
      interval.value = setInterval(function () {
        const timeLeft = fireworksEnd - Date.now();
        if (timeLeft <= 0) {
          return clearInterval(interval.value);
        }
        starsConfetti.value({
          particleCount: 2,
          angle: 180,
          spread: 55,
          origin: { x: 0.5, y: 0.5 },
          colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
        });
        starsConfetti.value({
          particleCount: 2,
          angle: 0,
          spread: 55,
          origin: { x: 0.5, y: 0.5 },
          colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
        });
      }, 30);
    };
    const fireworks = () => {
      const fireworksEnd = Date.now() + fireworksDuration;
      interval.value = setInterval(function () {
        const timeLeft = fireworksEnd - Date.now();
        if (timeLeft <= 0) {
          return clearInterval(interval.value);
        }

        const particleCount = 50 * (timeLeft / fireworksDuration);
        starsConfetti.value(
          Object.assign({}, fireworksDefaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
            shapes: ["circle", "circle", "square", "star"],
          })
        );
        starsConfetti.value(
          Object.assign({}, fireworksDefaults, {
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
            shapes: ["circle", "circle", "square", "star"],
          })
        );
      }, 250);
    };

    const randomInRange = (min, max) => {
      return Math.random() * (max - min) + min;
    };

    const shoot = () => {
      starsConfetti.value({
        ...starDefaults,
        particleCount: 40,
        scalar: 1.2,
        shapes: ["star"],
      });

      starsConfetti.value({
        ...starDefaults,
        particleCount: 10,
        scalar: 0.75,
        shapes: ["circle"],
      });
    };

    return {
      // Data
      canvasRef,
    };
  },
};
</script>

<style lang="scss" scoped>
.stars {
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  height: 100vh;
  z-index: -1;
}
</style>
