<template>
  <div class="tooltip-container">
    <slot />
    <div class="tooltip">
      <span class="text">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
}

.tooltip {
  color: #ffffff;
  text-align: center;
  padding: 8px;
  border-radius: 3px;

  visibility: hidden;

  width: 200px;
  bottom: 100%;
  margin-left: -80px;

  transition: opacity 1s;

  position: absolute;
  z-index: 2000;

  background: rgba(0, 0, 0, 0.8);
}

.text::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000000 transparent transparent transparent;
}
</style>
