<template>
  <div v-if="isWelcomeBoxOpen" class="welcome">
    <button class="finish" @click="endTour">×</button>

    <h3>Welcome Traveller!</h3>
    <p>
      Welcome to Dragon Haven, bold traveller! Much excitement and adventure
      awaits you, though fearsome dangers lurk outside the village walls. Many
      like you have come to seek their fortune, but few have lived to tell their
      tale.
    </p>
    <p>
      So before you run off to smack an orc or rescue a fair maiden, the Elders
      of Dragon Haven urge you to familiarize yourself with the lay of the land.
    </p>
    <div class="buttons">
      <BlueButton @click="startTour"> Show Me Around </BlueButton>
    </div>
  </div>
  <div class="cover" v-if="isWelcomeBoxOpen"></div>

  <VOnboardingWrapper ref="wrapper" :steps="steps" :options="options">
    <template #default="{ previous, next, step, isFirst, isLast, index }">
      <!-- <template #default="{ previous, next, step, exit, isFirst, isLast, index }"> -->
      <div class="cover-steps"></div>
      <VOnboardingStep class="guide-step">
        <div id="arrow" data-popper-arrow></div>
        <div class="box-container">
          <div class="step-box">
            <button class="finish" @click="endTour">×</button>
            <div>
              <div v-if="step.content">
                <h3 class="title" v-if="step.content.title">
                  {{ step.content.title }}
                </h3>
                <div
                  v-if="step.content.description"
                  class="mt-2 max-w-xl text-sm text-gray-500"
                >
                  <p>{{ step.content.description }}</p>
                </div>
              </div>
              <div
                class="mt-5 space-x-4 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center relative"
              ></div>
            </div>
          </div>
          <div class="buttons">
            <template v-if="!isFirst">
              <BlueButton
                @click="processPrevious(index, previous)"
                size="extra-small"
              >
                Previous
              </BlueButton>
            </template>
            <BlueButton
              @click="processNext(index, next, isLast)"
              size="extra-small"
            >
              {{ isLast ? "Finish" : "Next" }}
            </BlueButton>
          </div>
          <div class="step-count">
            <div
              class="progress"
              :style="`width: ${((index + 1) / steps.length) * 100}%`"
            ></div>
            <span>
              {{ `${index + 1}/${steps.length}` }}
            </span>
          </div>
        </div>
      </VOnboardingStep>
    </template>
  </VOnboardingWrapper>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
// import BasicPopup from "../Popup/BasicPopup.vue";
import {
  useVOnboarding,
  VOnboardingStep,
  VOnboardingWrapper,
} from "v-onboarding";
import BlueButton from "../Buttons/BlueButton.vue";

// import { VOnboardingWrapper, useVOnboarding } from "v-onboarding";
import "v-onboarding/dist/style.css";

export default {
  name: "IntroTour",
  components: {
    // BasicPopup,
    BlueButton,
    VOnboardingWrapper,
    VOnboardingStep,
  },
  setup() {
    //data
    const store = useStore();
    const wrapper = ref(null);
    const isWelcomeBoxOpen = ref(true);
    // const { start, goToStep, finish } = useVOnboarding(wrapper);
    const { start, finish } = useVOnboarding(wrapper);
    const options = {
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 8],
            },
          },
        ],
      },
      disableOverlay: true,
      scrollToStep: {
        enabled: true,
        options: {
          behavior: "smooth",
          block: "center",
          inline: "center",
        },
      },
    };
    let steps = [];
    if (
      store.state.characters[store.state.currentCharacter] == null ||
      store.state.characters[store.state.currentCharacter] == "undefined"
    ) {
      steps = [
        {
          attachTo: { element: "#character" },
          content: {
            title: "Get Your Hero",
            description:
              "Click this to visit any of our partner NFT Marketplaces and buy a hero you like. You need a hero to play this Legend Of Aurum Draconis.",
          },
        },
        {
          attachTo: { element: "#select-characters" },
          content: {
            title: "Select Characters",
            description:
              "Click this button to navigate to your hero select screen or quickly swap heroes by clicking on their portraits on the left",
          },
        },
        {
          attachTo: { element: "#your-stash" },
          content: {
            title: "Your Stash",
            description:
              "View your equipment, resources, consumables and other items in your stash.",
          },
        },
        {
          attachTo: { element: "#your-inventory" },
          content: {
            title: "Your Inventory",
            description:
              "View your equipment, resources, consumables and other items in your inventory.",
          },
        },
        {
          attachTo: { element: "#search-the-plains" },
          content: {
            title: "Search The Plains",
            description:
              "The vast lands outside the Township of Dragon Haven are renowned for their dangers posed by unsavoury rogues. Each encounter will use a turn so be prepared for battle!",
          },
        },
        {
          attachTo: { element: "#mines" },
          content: {
            title: "Search The Mines",
            description:
              "The ancient mines of Dragon Haven are blessed with rich veins of minerals and metals. Brave the dangers of the mines and obtain resources to fix your equipment. Make sure you bring along a pickaxe!",
          },
        },
        {
          attachTo: { element: "#healers-hut" },
          content: {
            title: "Healer's Hut",
            description:
              "Home of a retired warrior whose years of fighting taught him the skills needed to mend the wounds of his fellow adventurers. You can heal or revive a hero here.",
          },
        },
        {
          attachTo: { element: "#inn" },
          content: {
            title: "Wild Boar Inn",
            description:
              "A warm and welcoming refuge. Enjoy a drink from the master brewer, listen to the inspiring lyrics of the Bard, try your luck playing games or get a night's rest to restore your turns",
          },
        },
        {
          attachTo: { element: "#marketplace" },
          content: {
            title: "Marketplace",
            description:
              "The renowned marketplace of Dragon Haven offers a variety of weapons, armour, tools, potions and many other valuable resources. Purchase items to aid in your adventures or sell the spoils of your battles for profit.",
          },
        },
        {
          attachTo: { element: "#forge" },
          content: {
            title: "Forge",
            description:
              "Smelt various ores into high quality materials to craft and repair your equipment and weapons. Certain rare ores are known to confer special attributes to your gear.",
          },
        },
        {
          attachTo: { element: "#your-character" },
          content: {
            title: "Your Hero",
            description:
              "Your hero's stats and equipped items are displayed here. You should set your hero's stats after this to have a great start.",
          },
        },
      ];
    } else {
      steps = [
        {
          attachTo: { element: "#character" },
          content: {
            title: "Your Character",
            description:
              "This is where your hero's health points (orange bar) and turns (blue bar) are shown.",
          },
        },
        {
          attachTo: { element: "#select-characters" },
          content: {
            title: "Select Characters",
            description:
              "Click this button to navigate to your hero select screen or quickly swap heroes by click on their portraits on the left.",
          },
        },
        {
          attachTo: { element: "#your-stash" },
          content: {
            title: "Your Stash",
            description:
              "View your equipment, resources, consumables and other items in your stash.",
          },
        },
        {
          attachTo: { element: "#crafters-guild" },
          content: {
            title: "Crafter's Guild",
            description:
              "Visit the Crafters's guild to craft items, visit repair and crafting marketplaces or to accept various quests.",
          },
        },
        {
          attachTo: { element: "#your-inventory" },
          content: {
            title: "Your Inventory",
            description:
              "View your equipment, resources, consumables and other items in your inventory.",
          },
        },
        {
          attachTo: { element: "#search-the-plains" },
          content: {
            title: "Search The Plains",
            description:
              "The vast lands outside the Township of Dragon Haven are renowned for their dangers posed by unsavoury rogues. Each encounter will use a turn, so be prepared for battle!",
          },
        },
        {
          attachTo: { element: "#mines" },
          content: {
            title: "Search The Mines",
            description:
              "The ancient mines of Dragon Haven are blessed with rich veins of minerals and metals. Brave the dangers of the mines and obtain resources to fix your equipment. Make sure you bring along a pickaxe!",
          },
        },
        {
          attachTo: { element: "#healers-hut" },
          content: {
            title: "Healer's Hut",
            description:
              "Home of a retired warrior whose years of fighting taught him the skills needed to mend the wounds of his fellow adventurers. You can heal or revive a hero here.",
          },
        },
        {
          attachTo: { element: "#inn" },
          content: {
            title: "Wild Boar Inn",
            description:
              "A warm and welcoming refuge. Enjoy a drink from the master brewer, listen to the inspiring lyrics of the Bard, try your luck playing games, or get a night's rest to restore your turns.",
          },
        },
        {
          attachTo: { element: "#bank" },
          content: {
            title: "Grom's Bank",
            description:
              "Grom's Bank is where you can stake your tokens and earn rewards as they grow over time.",
          },
        },
        {
          attachTo: { element: "#defenders-guild" },
          content: {
            title: "Defender's Guild",
            description:
              "Visit the Defender's guild to accept and complete various quests.",
          },
        },
        {
          attachTo: { element: "#marketplace" },
          content: {
            title: "Marketplace",
            description:
              "The renowned marketplace of Dragon Haven offers a variety of weapons, armour, tools, potions and many other valuable resources. Purchase items to aid in your adventures or sell the spoils of your battles for profit.",
          },
        },
        {
          attachTo: { element: "#forge" },
          content: {
            title: "Forge",
            description:
              "Smelt various ores into high quality materials to craft and repair your equipment and weapons. Certain rare ores are known to confer special attributes to your gear.",
          },
        },
        {
          attachTo: { element: "#notification-button-guide" },
          content: {
            title: "Game Log",
            description:
              "Real time log of your actions, events and notifications",
          },
        },
        {
          attachTo: { element: "#chat-button-guide" },
          content: {
            title: "Chat",
            description:
              "Chat with your fellow adventurers, share your stories and find out what's going on in Dragon Haven.",
          },
        },
        {
          attachTo: { element: "#your-character" },
          content: {
            title: "Your Hero",
            description:
              "Your hero's stats and equipped items are displayed here. You should set your hero's stats now to have a great start.",
          },
        },
      ];
    }

    onMounted(() => {
      store.commit("intro/setIsOnRampOpen", false);
    });

    const startTour = () => {
      // Closing Welcome Box
      isWelcomeBoxOpen.value = false;

      // Highlighting the first step
      // Adding class to the next element to be highlighted
      highlightStep(0);

      start();
    };
    const endTour = () => {
      isWelcomeBoxOpen.value = false;
      store.commit("intro/setIsGuideOpen", false);
      store.commit("intro/setIsOnRampOpen", true);
      store.commit("intro/setAfterStartingGuide", true);
      finish();
    };

    const processNext = (index, next, isLast) => {
      highlightStep(index + 1);
      next();
      if (isLast) {
        store.commit("intro/setIsGuideOpen", false);
        store.commit("intro/setIsOnRampOpen", true);
        store.commit("intro/setAfterStartingGuide", true);
      }
    };

    const processPrevious = (index, previous) => {
      highlightStep(index - 1);
      previous();
    };

    /**
     * Highlight the element in the step being passed
     * @param number index of the step being passed
     */
    const highlightStep = (index) => {
      console.log(index);
      if (index >= 0 || index <= steps.length) {
        // Removing all previously highlighted elements
        // But ignoring if it's the first element
        if (index !== 0) {
          document
            .querySelector(".animate-highlighted")
            .classList.remove("animate-highlighted");
        }

        // Adding class to the next element to be highlighted
        // But ignoring if it's the last element
        if (index !== steps.length) {
          document
            .querySelector(steps[index].attachTo.element)
            .classList.add("animate-highlighted");
        }

        // Moving ahead to the next step
      }
    };

    return {
      //data
      isWelcomeBoxOpen,
      wrapper,
      steps,
      options,
      // methods
      startTour,
      endTour,
      processNext,
      processPrevious,
    };
  },
};
</script>

<style lang="scss">
.animate-highlighted {
  animation: pulse 1s alternate infinite ease-in-out;
  z-index: 20;
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1.05);
  }
}
</style>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.welcome {
  width: 450px;
  background: #000000b8;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 20;
  padding: 2rem 2rem 2rem 200px;
  border-radius: 10px;
  font-family: "IM Fell English", serif;
  @media only screen and (max-width: 576px) {
    width: 78%;
    padding: 2rem;
  }
  &:after {
    content: "";
    background-image: url("https://cdn.dragoncrypto.io/assets/hero-npc.png");
    width: 418px;
    height: 500px;
    background-size: contain;
    background-position: left center;
    position: absolute;
    top: 0;
    left: -163px;
    background-repeat: no-repeat;
    z-index: -1;
    @media only screen and (max-width: 576px) {
      display: none;
    }
  }
  h3 {
    text-transform: capitalize;
    font-size: 2rem;
    margin-bottom: 2rem;
    @media only screen and (max-width: 576px) {
      font-size: 1.5rem;
    }
  }
  p {
    padding: 0;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    text-align: left;
    @media only screen and (max-width: 576px) {
      font-size: 1rem;
    }
  }
  .buttons {
    margin: 2rem 1rem 2rem;
  }
}

.cover {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 19;
  background: rgba(0, 0, 0, 0.449);
  top: 0;
  backdrop-filter: blur(2px);
  left: 0;
}
.cover-steps {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 19;
}

.guide-step {
  #arrow {
    visibility: hidden;
    position: absolute;
    width: 16px;
    height: 16px;
    background: inherit;
    &::before {
      position: absolute;
      width: 16px;
      height: 16px;
      background: inherit;
      visibility: visible;
      content: "";
      transform: rotate(45deg);
      background-color: #000;
    }
  }
  .box-container {
    border-radius: 5px;
    overflow: hidden;
  }
  div[data-popper-placement^="top"] > #arrow {
    bottom: -8px;
  }

  div[data-popper-placement^="bottom"] > #arrow {
    top: -8px;
  }

  div[data-popper-placement^="left"] > #arrow {
    right: -8px;
  }

  div[data-popper-placement^="right"] > #arrow {
    left: -8px;
  }
  .step-box {
    width: 20rem;
    background: #000;
    padding: 1rem;
    font-family: "IM Fell English", serif;
    h3 {
      margin-top: 0;
      text-transform: capitalize;
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 0.5rem;
    }
  }
  .buttons {
    background: #000000e3;
    padding: 1rem 0.5rem;
    border-top: 1px #262626 solid;
  }
  .step-count {
    position: relative;
    height: 2px;
    background: #000;
    transition: height 0.3s ease-in-out;
    overflow: hidden;
    &:hover {
      height: 15px;
      span {
        display: block;
      }
    }
    span {
      display: none;
      width: 100%;
      text-align: center;
      font-size: 0.8rem;
      z-index: 1;
      position: relative;
      color: rgb(91, 91, 91);
    }
    .progress {
      display: block;
      position: absolute;
      left: 0;
      height: 100%;
      background-color: #e5c355;
    }
  }
}
.finish {
  border-radius: 100%;
  font-size: 1.3rem;
  padding: 0px 6px;
  box-shadow: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  color: #ffffffad;
  &:hover {
    background: #636363;
    color: #fff;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
  }
}
</style>
