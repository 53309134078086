<template>
  <div :class="getClass">
    <template v-if="gatheringStart">
      <div :class="['inner-dialog-wrapper', toggleControls]">
        <GameMessage :heading="locationName" :icon="icon">
          <p>
            {{
              "You find a " +
              this.locationResourceDescription +
              " of " +
              this.resourceName +
              "!"
            }}
          </p>
          <p>How will you proceed?</p>
        </GameMessage>
        <div class="buttons">
          <DialogButton
            :requiresTurn="false"
            @buttonClick="$emit('gather', 0)"
            :buttonText="'Start ' + this.locationGatheringAction"
            :disabled="
              (isMines ? !canHarvest || !hasPickaxe : !canHarvest) ||
              !heroHasEnoughGathering
            "
          />
          <DialogButton
            v-if="isMines && hasPickaxe"
            @buttonClick="searchMines"
            :buttonText="'Search again (1 Turn)'"
            :class="hasTurns || !isLoading ? '' : ' disabled'"
            :disabled="!hasTurns || isLoading"
            :isLoading="isLoading"
          />
          <DialogButton
            v-if="isDeepwood"
            @buttonClick="searchDeepwood"
            :buttonText="'Search again (1 Turn)'"
            :class="hasTurns || !isLoading ? '' : ' disabled'"
            :disabled="!hasTurns || isLoading"
            :isLoading="isLoading"
          />
          <DialogButton
            @buttonClick="$emit('leave')"
            :buttonText="'Venture out'"
          />
        </div>
      </div>
    </template>
    <template v-if="isSearchingDeepwood || isSearchingMines">
      <GameMessage
        v-if="isSearchingDeepwood"
        heading="Searching the Deepwood"
        icon="Archerskill_29_nobg"
      >
        <p>Venturing deep into the woods</p>
      </GameMessage>
      <GameMessage
        v-if="isSearchingMines"
        heading="Searching the Mines"
        icon="Engineerskill_32_nobg"
      >
        <p>Venturing in to the mines</p>
      </GameMessage>
    </template>
  </div>
</template>
<script>
import { useStore } from "vuex";
import GameMessage from "./GameMessage.vue";
import DialogButton from "./DialogButton.vue";
import Constants from "../consts/constants";

export default {
  //TODO : Enable eslint
  /* eslint-disable */
  name: "GatheringStart",
  emits: ["gather", "searchMinesAgain", "searchDeepwoodAgain", "leave"],
  components: {
    GameMessage,
    DialogButton,
  },
  props: {},
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async searchMines() {
      this.isLoading = true;
      await this.sleep(500);
      this.$emit('searchMinesAgain');
      await this.sleep(500);
      this.isLoading = false;
    },
    async searchDeepwood() {
      this.isLoading = true;
      await this.sleep(500);
      this.$emit('searchDeepwoodAgain');
      await this.sleep(500);
      this.isLoading = false;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
  computed: {
    getClass() {
      let mainClass =
        "gather-wrapper wrap-content " +
        (this.gatheringStart ||
        this.isSearchingDeepwood ||
        this.isSearchingMines
          ? "show"
          : "hide");
      if (
        this.$store.state.gameState == Constants.gamemodes.gatheringStart ||
        this.isSearchingDeepwood ||
        this.isSearchingMines
      ) {
        if (this.isMines) {
          mainClass += " " + "mines";
        } else if (this.isDeepwood) {
          mainClass += " " + "deepwood";
        }
        // mainClass +=
        //   " " + (this.locationName ? this.locationName.toLowerCase() : "");
      }
      mainClass += " " + this.$store.state.timeOfDay;
      return mainClass;
    },
    toggleControls() {
      return this.$store.state.gameState == Constants.gamemodes.gatheringStart
        ? "show"
        : "hide";
    },
    isSearchingMines() {
      return this.$store.state.gameState === Constants.gamemodes.searchingMines;
    },
    isSearchingDeepwood() {
      return (
        this.$store.state.gameState === Constants.gamemodes.searchingDeepwood
      );
    },
    gatheringStart() {
      return this.$store.state.gameState === Constants.gamemodes.gatheringStart;
    },
    isGatheringSet() {
      return (
        this.$store.state.currentGathering &&
        this.$store.state.currentGathering.resource
      );
    },
    locationName() {
      if (!this.isGatheringSet) {
        return "";
      }

      if (
        this.$store.state.currentGathering &&
        this.$store.state.currentGathering.resource
      ) {
        if (this.isMines) {
          return "Mines";
        } else if (this.isDeepwood) {
          return "Deepwood";
        }
        // if (
        //   this.$store.state.currentGathering.resource.gatheringAction.toLowerCase() ==
        //     "woods" ||
        //   this.$store.state.currentGathering.resource.gatheringAction.toLowerCase() ===
        //     "harvesting"
        // ) {
        //   return "Deepwood";
        // }

        // if (
        //   this.$store.state.currentGathering.resource.gatheringAction
        //     .toLowerCase()
        //     .indexOf("mining") >= 0 ||
        //   this.$store.state.currentGathering.resource.gatheringAction.toLowerCase() ===
        //     "collecting"
        // ) {
        //   return "Mines";
        // }
      }

      return "";
    },
    gatheringRequired() {
      if (!this.isGatheringSet) {
        return "";
      }
      return this.$store.state.currentGathering.resource.gatheringRequired;
    },
    heroGatheringSkill() {
      if (this.player) {
        return this.player.stats.gathering;
      }
      return 0;
    },
    equipmentGatheringSkill() {
      if (this.player) {
        return this.$store.state.equipmentStats().gathering;
      }
      return 0;
    },
    heroHasEnoughGathering() {
      return ( this.heroGatheringSkill + this.equipmentGatheringSkill + Constants.gatheringBand ) >= this.gatheringRequired;
    },
    resourceName() {
      if (!this.isGatheringSet) {
        return "";
      }
      return this.$store.state.currentGathering.resource.name;
    },
    locationResourceDescription() {
      if (!this.isGatheringSet) {
        return "";
      }
      return this.$store.state.currentGathering.resource.resourceDescription;
    },
    locationGatheringAction() {
      if (!this.isGatheringSet) {
        return "";
      }
      return this.$store.state.currentGathering.resource.gatheringAction;
    },
    isMines() {
      return (
        this.$store.state.charLocation == Constants.resourceLocations.mines
      );
    },
    player() {
      return this.$store.state.characters[this.$store.state.currentCharacter];
    },
    hasPickaxe() {
      const currentChar =
        this.$store.state.characters[this.$store.state.currentCharacter];

      for (const equippedItemKey in currentChar.equipped) {
        if (Object.hasOwnProperty.call(currentChar.equipped, equippedItemKey)) {
          const equippedItem = currentChar.equipped[equippedItemKey];
          if (
            equippedItem &&
            equippedItem.name.toLowerCase().indexOf("pickaxe") >= 0
          ) {
            return true;
          }
        }
      }
      return false;
    },
    isDeepwood() {
      return (
        this.$store.state.charLocation == Constants.resourceLocations.deepwood
      );
    },
    canHarvest() {
      if (!this.isGatheringSet) {
        return "";
      }
      return this.$store.state.currentGathering.resource.canHarvest;
    },
    hasTurns() {
      return (
        this.$store.state.characters[this.$store.state.currentCharacter].stats
          .turns > 0
      );
    },
    icon() {
      if (!this.isGatheringSet) {
        return "";
      }
      let imageLocation =
        Constants.iconLocation +
        this.$store.state.currentGathering.resource.imageName;
      return imageLocation;
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";
.inner-dialog-wrapper {
  transition: opacity 0.35s linear;
  &.show {
    opacity: 1;
  }
  &.hide {
    opacity: 0;
  }
}
.gather-wrapper {
  z-index: 1;
  &.day {
    &.deepwood {
      background: no-repeat url("../assets/locationBackgrounds/deepwoods.webp")
        center center;
      background-size: cover;
    }
  }
  &.night {
    &.deepwood {
      background: no-repeat
        url("../assets/locationBackgrounds/deepwoods-night.webp") center center;
      background-size: cover;
    }
  }
  &.mines {
    background: no-repeat
      url("../assets/locationBackgrounds/MinesBG-large.webp") center center;
    background-size: cover;
  }
}
.mines {
  background: no-repeat
    url("https://cdn.dragoncrypto.io/locationbackgrounds/deepwoods.webp") top
    center;
  background-size: cover;
}

.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;
}

.highlight {
  font-weight: bold;
  color: $dmg;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  font-size: 90%;
}
</style>
