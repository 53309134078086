<template>
  <div :class="['quick-access-section', showQuickAccess ? 'show' : '']">
    <div v-if="onlyDuringWandering" :class="['quick-access-list']">
      <button
        :class="[showQuickAccess ? 'show' : '', 'toggle-quick-access']"
        @click="showQuickAccess = !showQuickAccess"
        title="Minimap Quick Access"
      >
        {{ showQuickAccess ? "X" : "Toggle Quick Map" }}
      </button>
      <button
        :class="['quick-access-button', getClass(quickAccessItem.name)]"
        v-for="quickAccessItem in quickAccessList"
        :key="quickAccessItem.id"
        :title="quickAccessItem.name"
        @click="scrollToLocation(quickAccessItem)"
      >
        {{ quickAccessItem.name }}
      </button>
    </div>
    <span class="place-image"></span>
  </div>
</template>
<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import gallop from "../../assets/sound/gallop.mp3";
import { useMixpanel } from "../../composables/mixpanel";
import { useSound } from "../../composables/sound";
import Constants from "../../consts/constants";

export default {
  name: "QuickAccess",
  components: {},
  setup() {
    const store = useStore();
    const showQuickAccess = ref(false);
    const hoveredPlace = ref("");
    const gallopSound = useSound(gallop, {
      interrupt: true,
    });
    const quickAccessList = [
      {
        name: "Healer's hut",
        id: "healers-hut",
      },
      {
        name: "Mines",
        id: "mines",
      },
      {
        name: "Plains",
        id: "search-the-plains",
      },
      {
        name: "Deepwood",
        id: "deepwood",
      },
      {
        name: "Marketplace",
        id: "marketplace",
      },
      {
        name: "Forge",
        id: "forge",
      },
      {
        name: "Wild Boar Inn",
        id: "inn",
      },
      {
        name: "Grom's Bank",
        id: "bank",
      },
      {
        name: "Your Stash",
        id: "your-stash",
      },
      {
        name: "Crafter's Guild",
        id: "crafters-guild",
      },
      {
        name: "Defender's Guild",
        id: "defenders-guild",
      },
    ];

    // Mixpanel Tracking
    const { trackEvent } = useMixpanel();

    const scrollToLocation = (location) => {
      gallopSound.play();
      showQuickAccess.value = !showQuickAccess.value;
      const element = document.getElementById(location.id);
      if (element !== null) {
        element.scrollIntoView(
          { behavior: "smooth", block: "center", inline: "center" },
          true
        );
        // Mixpanel tracking full screen
        trackEvent("Used Quickaccess", {
          state: location.name,
        });
      }
    };

    const getClass = (name) => {
      return name.toLowerCase().replace(" ", "-").replace("'", "");
    };

    const onlyDuringWandering = computed(() => {
      return store.state.gameState == Constants.gamemodes.wandering;
    });

    return {
      //data
      showQuickAccess,
      quickAccessList,
      hoveredPlace,
      //methods
      scrollToLocation,
      getClass,
      //computed
      onlyDuringWandering,
    };
  },
  props: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.quick-access-section {
  position: fixed;
  z-index: 14;
  top: 45px;
  right: -184px;
  max-width: 186px;
  transition: all 0.1s ease-out;
  display: block;
  @media only screen and (max-width: 576px) {
    bottom: -1px;
    top: auto;
    right: -189px;
  }
  &.show {
    right: 0;
  }
}
.quick-access-list {
  background-image: url("https://cdn.dragoncrypto.io/popup/popup-base.webp");
  background-position: center center;
  background-repeat: repeat;
  border: 10px solid;
  border-image-source: url("../../assets/popup/small-border.webp");
  border-image-outset: 0;
  border-image-slice: 53;
  border-image-repeat: repeat;
  background-color: #171110;
  display: block;

  button.toggle-quick-access {
    position: absolute;
    display: block;
    width: 44px;
    height: 44px;
    background: url("../../assets/ui/minimap.png") no-repeat;
    background-size: cover;
    box-shadow: none;
    border: none;
    opacity: 1;
    text-indent: -9999px;
    left: -60px;
    z-index: 5;
    transition: all 0.1s ease-out;

    cursor: url(https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png),
      auto;
    &:hover {
      transform: scale(1.2);
      transition: transform 0.2s ease-in-out;
    }
    &.show {
      transform: rotate(45deg);
    }
  }
  .quick-access-button {
    border: none;
    box-shadow: none;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    font-weight: 300;
    display: block;
    width: 100%;
    text-align: left;
    color: #fff;
    background: transparent;
    padding: 7px 25px;
    font-size: 0.8rem;
    text-transform: uppercase;
    border-bottom: 1px rgb(255 255 255 / 22%) solid;
    &:last-child {
      border-bottom: none;
    }
    &:after {
      content: "";
      width: 300px;
      height: calc(100% - 20px);
      position: absolute;
      background: rgb(28, 23, 22);
      display: none;
      z-index: 10;
      top: 0;
      pointer-events: none;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 77%;
      left: -174%;
      border: 10px solid;
      border-image-source: url("../../assets/popup/small-border.webp");
      border-image-outset: 0;
      border-image-slice: 53;
      border-image-repeat: repeat;
      background-color: #171110;
    }
    &:hover {
      color: $gold;
      &:after {
        display: block;
        @media only screen and (max-width: 576px) {
          display: none;
        }
      }
    }

    &.your-stash:after {
      background-image: url("../../assets/quickaccess-icons/your-stash.png");
    }
    &.healers-hut:after {
      background-image: url("../../assets/quickaccess-icons/healers-hut.png");
    }
    &.mines:after {
      background-image: url("../../assets/quickaccess-icons/mines.png");
    }
    &.plains:after {
      background-image: url("../../assets/quickaccess-icons/plains.png");
    }
    &.deepwood:after {
      background-image: url("../../assets/quickaccess-icons/deepwoods.png");
    }
    &.marketplace:after {
      background-image: url("../../assets/quickaccess-icons/marketplace.png");
    }
    &.forge:after {
      background-image: url("../../assets/quickaccess-icons/forge.png");
      background-position-y: 30%;
    }
    &.inn:after {
      background-image: url("../../assets/quickaccess-icons/inn.png");
    }
    &.groms-bank:after {
      background-image: url("../../assets/quickaccess-icons/bank.png");
    }
    &.crafters-guild:after {
      background-image: url("../../assets/quickaccess-icons/crafters-guild.png");
    }
    &.defenders-guild:after {
      background-image: url("../../assets/quickaccess-icons/defenders-guild.png");
    }
  }
}
</style>
