<template>
  <GuideWizard
    v-if="
      steps.length > 0 &&
      (showCombat ||
        showInn ||
        showEquipment ||
        showInventory ||
        showBank ||
        showStash ||
        showDead ||
        forResurrect ||
        showTurns ||
        forHealth ||
        showBlueprintFlow ||
        forSetStats ||
        forLetsGoToBattle ||
        forSearchingPlains ||
        forLootStash ||
        forGuildQuests)
    "
    :steps="steps"
    :options="options"
    @ended="ended"
    :key="steps"
  />
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Constants from "../../consts/constants";
import {
  BankSteps,
  CombatSteps,
  EquipmentSteps,
  InnSteps,
  InventorySteps,
  SingleStep,
  StashSteps,
  GuildQuestSteps,
} from "../../utils/tutorialSteps";
import GuideWizard from "./GuideWizard.vue";

export default {
  name: "CombatTour",
  components: {
    GuideWizard,
  },
  setup() {
    const store = useStore();
    const steps = ref([]);
    const options = ref({});
    const tutorialVisited = ref({});
    const showBlueprintFlow = ref(false);

    onMounted(() => {
      // const customBoundary = document.querySelector("#boundary");
      const storageVisitedData = localStorage.getItem("tutorialVisited");
      if (storageVisitedData) {
        tutorialVisited.value = JSON.parse(storageVisitedData);
      } else {
        tutorialVisited.value = {
          combatTutorial: false,
          innTutorial: false,
          equipmentTutorial: false,
          inventoryTutorial: false,
          bankTutorial: false,
          stashTutorial: false,
          deathTutorial: false,
          turnsTutorial: false,
          healTutorial: false,
          blueprintTutorial: false,
          setStatsTutorial: false,
          claimLootTutorial: false,
          guildQuestsTutorial: false,
        };
      }
      options.value = {
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 8],
              },
            },
            {
              name: "flip",
              options: {
                fallbackPlacements: ["top", "right", "bottom", "left"],
              },
            },
          ],
        },
        disableOverlay: true,
        scrollToStep: {
          enabled: true,
          options: {
            behavior: "smooth",
            block: "center",
            inline: "center",
          },
        },
      };
    });

    const saveTutorialVisited = () => {
      if (showCombat.value) {
        tutorialVisited.value.combatTutorial = true;
      } else if (showInn.value) {
        tutorialVisited.value.innTutorial = true;
        tutorialVisited.value.turnsTutorial = true;
      } else if (showEquipment.value) {
        tutorialVisited.value.equipmentTutorial = true;
        //   tutorialVisited.value.setStatsTutorial = true;
      } else if (showInventory.value) {
        tutorialVisited.value.inventoryTutorial = true;
      } else if (showBank.value) {
        tutorialVisited.value.bankTutorial = true;
      } else if (showStash.value) {
        tutorialVisited.value.stashTutorial = true;
      } else if (showBlueprintFlow.value) {
        showBlueprintFlow.value = false;
        tutorialVisited.value.blueprintTutorial = true;
        steps.value = [];
      } else if (forSearchingPlains.value) {
        tutorialVisited.value.setStatsTutorial = true;
      } else if (showDead.value) {
        tutorialVisited.value.deathTutorial = true;
      } else if (forHealth.value) {
        tutorialVisited.value.healTutorial = true;
      } else if (forLootStash.value) {
        tutorialVisited.value.claimLootTutorial = true;
      } else if (forGuildQuests.value) {
        tutorialVisited.value.guildQuestsTutorial = true;
      }
      saveTutorialStorage();
    };
    store.watch(
      (state) => state.gameState,
      async (gameState) => {
        if (
          gameState === Constants.gamemodes.combat &&
          !tutorialVisited.value.combatTutorial
        ) {
          if (store.state.intro.searchPlains) {
            store.commit("intro/setSearchPlains", false);
            await delay(200); // Waiting for unmounting
          }
          steps.value = CombatSteps;
        } else if (
          gameState === Constants.gamemodes.inn &&
          !tutorialVisited.value.innTutorial
        ) {
          // stopping no turns guide before going into inn
          steps.value = InnSteps;
        } else if (
          gameState === Constants.gamemodes.looting &&
          !tutorialVisited.value.claimLootTutorial
        ) {
          steps.value = SingleStep.lootClaim;
          store.commit("intro/setLootStash", true);
        } else if (
          gameState === Constants.gamemodes.bank &&
          !tutorialVisited.value.bankTutorial
        ) {
          steps.value = BankSteps;
        } else if (gameState === Constants.gamemodes.stash) {
          if (store.state.intro.lootStash) {
            store.commit("intro/setLootStash", false);
            tutorialVisited.value.claimLootTutorial = true;
            saveTutorialStorage();
            await delay(200); // Waiting for unmounting
          }
          if (!tutorialVisited.value.stashTutorial) {
            steps.value = StashSteps;
          }
        } else if (
          gameState === Constants.gamemodes.healers &&
          !tutorialVisited.value.deathTutorial
        ) {
          steps.value = SingleStep.resurrect;
        } else if (gameState === Constants.gamemodes.wandering) {
          if (
            store.state.characters[store.state.currentCharacter] &&
            store.state.characters[store.state.currentCharacter].stats.turns ===
              0 &&
            !tutorialVisited.value.turnsTutorial
          ) {
            steps.value = SingleStep.turns;
          }
          if (
            store.state.characters[store.state.currentCharacter].stats.hp <
              store.state.characters[store.state.currentCharacter].stats
                .totalHp &&
            store.state.gameState === Constants.gamemodes.wandering &&
            (store.state.prevState === Constants.gamemodes.combat ||
              store.state.prevState === Constants.gamemodes.looting) &&
            !tutorialVisited.value.healTutorial
          ) {
            steps.value = SingleStep.heal;
          }
          if (
            store.state.intro.afterStartingGuide &&
            store.state.characters[store.state.currentCharacter].stats
              .levelStatPoints > 0
          ) {
            steps.value = SingleStep.setStats;
          }
        } else if (gameState === "crafting") {
          if (showBlueprintFlow.value) {
            steps.value = [];
            await delay(200); // WAiting for crating popup to mount completely
            steps.value = SingleStep.selfCrafting;
          }
        } else if (gameState === Constants.gamemodes.defenderguild) {
          console.log("COMING IN DEFENDER GUILD");
          await delay(3000); // Waiting for unmounting
          steps.value = GuildQuestSteps;
        }
      }
    );

    const saveTutorialStorage = () => {
      console.log("SAVING", tutorialVisited.value);
      localStorage.setItem(
        "tutorialVisited",
        JSON.stringify(tutorialVisited.value)
      );
    };
    const ended = () => {
      steps.value = [];
      saveTutorialVisited();
    };

    /**
     * tutorial to start assigning stat points
     */
    store.watch(
      (state) => state.intro.afterStartingGuide,
      (afterStartingGuide) => {
        if (
          afterStartingGuide &&
          store.state.characters[store.state.currentCharacter].stats
            .levelStatPoints > 0
        ) {
          steps.value = SingleStep.setStats;
        }
      }
    );
    store.watch(
      (state) => state.intro.letsGoToBattle,
      (letsGoToBattle) => {
        if (letsGoToBattle) {
          steps.value = SingleStep.letsGoToBattle;
        }
      }
    );

    /**
     * Tutorial for claiming loot from stash
     */
    // store.watch(
    //   (state) => state.lootStash,
    //   (lootStash) => {
    //     console.log("LOOT", lootStash);
    //     if (!lootStash) {
    //       tutorialVisited.value.claimLootTutorial = true;
    //       saveTutorialStorage();
    //     }
    //   }
    // );

    store.watch(
      (state) => state.intro.searchPlains,
      (searchPlains) => {
        if (searchPlains) {
          steps.value = store.state.intro.isWarriorStats
            ? SingleStep.findMonsters
            : SingleStep.gatherWoods;
        } else {
          tutorialVisited.value.setStatsTutorial = true;
          saveTutorialStorage();
        }
      }
    );

    store.watch(
      (state) => state.isEquipmentVisible,
      (isEquipmentVisible) => {
        if (
          isEquipmentVisible
          // store.state.characters[store.state.currentCharacter].stats
          //   .levelStatPoints > 0
        ) {
          steps.value = EquipmentSteps;
        }
      }
    );
    store.watch(
      (state) => state.isInventoryVisible,
      (isInventoryVisible) => {
        if (isInventoryVisible && !store.state.marketplace.isMarketplaceOpen) {
          if (showBlueprintFlow.value) {
            steps.value = SingleStep.blueprintInventory;
          } else {
            steps.value = InventorySteps;
          }
        }
      }
    );
    store.watch(
      (state) => state.craftBlueprintItem,
      (craftBlueprintItem) => {
        if (craftBlueprintItem) {
          steps.value = SingleStep.blueprintResources;
        }
      }
    );
    const delay = async (time) => new Promise((res) => setTimeout(res, time));

    // state.characters[state.currentCharacter].stats.turns

    store.watch(
      (state) =>
        state.characters[state.currentCharacter] != null
          ? state.characters[state.currentCharacter].stats.turns
          : null,
      (turns) => {
        if (
          turns === 0 &&
          store.state.gameState === Constants.gamemodes.wandering
        ) {
          steps.value = SingleStep.turns;
        }
      }
    );

    /**
     * Watcher to monitor health
     */
    store.watch(
      (state) =>
        state.characters[state.currentCharacter] != null
          ? state.characters[state.currentCharacter].stats.hp
          : null,
      (health) => {
        if (
          health <
            store.state.characters[store.state.currentCharacter].stats
              .totalHp &&
          store.state.gameState === Constants.gamemodes.wandering &&
          (store.state.prevState === Constants.gamemodes.combat ||
            store.state.prevState === Constants.gamemodes.looting)
        ) {
          steps.value = SingleStep.heal;
        }
      }
    );
    // watch(
    //   showTurns,
    //   // (messages, prevMessages) => {
    //   () => {
    //     // store.commit("setCurrentSellQuantity", currentQuantity.value);
    //     steps.value = SingleStep.turns;
    //   }
    // );

    /**
     * Monitor is player dead
     */
    store.watch(
      (state) =>
        state.characters[state.currentCharacter] != null
          ? state.characters[state.currentCharacter].stats.isDead
          : null,
      (value) => {
        if (
          value &&
          (store.state.gameState === Constants.gamemodes.combat ||
            store.state.gameState === Constants.gamemodes.healers) &&
          showDead.value
        ) {
          steps.value = SingleStep.dead;
        }
      }
    );

    /**
     * Watching if player gets a blueprint in inventory
     * only trigger when user claims BP from stash and is in stash
     */
    store.watch(
      (state) => state.inventory,
      (inventory) => {
        const blueprintInInventory = inventory.filter(
          (item) => item.type === "blueprint"
        );
        if (
          blueprintInInventory.length > 0 &&
          store.state.gameState === Constants.gamemodes.stash &&
          !tutorialVisited.value.blueprintTutorial
        ) {
          steps.value = SingleStep.blueprint;
          showBlueprintFlow.value = true;
        }
      }
    );

    store.watch(
      (state) => state.intro.isInSelfCrafting,
      (isInSelfCrafting) => {
        if (isInSelfCrafting) {
          steps.value = SingleStep.dragBluePrint;
        }
      }
    );
    /**
     * Watching if player is dead
     */
    store.watch(
      (state) => state.intro.isDeadFlowComplete,
      (isDeadFlowComplete) => {
        if (isDeadFlowComplete) {
          tutorialVisited.value.deathTutorial = true;
          saveTutorialStorage();
          steps.value = [];
        }
      }
    );

    /**
     * watching if player is hurt
     */
    store.watch(
      (state) => state.intro.isHealFlowComplete,
      (isHealFlowComplete) => {
        if (isHealFlowComplete) {
          tutorialVisited.value.healTutorial = true;
          saveTutorialStorage();
          steps.value = [];
        }
      }
    );
    //data
    const showCombat = computed(() => {
      return (
        store.state.gameState === Constants.gamemodes.combat &&
        !tutorialVisited.value.combatTutorial &&
        !store.state.characters[store.state.currentCharacter].stats.isDead
      );
    });
    const showInn = computed(() => {
      return (
        store.state.gameState === Constants.gamemodes.inn &&
        !tutorialVisited.value.innTutorial
      );
    });
    const showEquipment = computed(() => {
      return (
        store.state.isEquipmentVisible &&
        store.state.characters[store.state.currentCharacter].stats
          .levelStatPoints > 0 &&
        !tutorialVisited.value.equipmentTutorial
      );
    });

    const forSetStats = computed(() => {
      return (
        store.state.intro.afterStartingGuide &&
        store.state.characters[store.state.currentCharacter].stats
          .levelStatPoints > 0 &&
        !tutorialVisited.value.setStatsTutorial &&
        !tutorialVisited.value.equipmentTutorial
      );
    });

    const showInventory = computed(() => {
      return (
        store.state.isInventoryVisible &&
        // !showBlueprintFlow.value &&
        !tutorialVisited.value.inventoryTutorial
      );
    });
    const showBank = computed(() => {
      return (
        store.state.gameState === Constants.gamemodes.bank &&
        !tutorialVisited.value.bankTutorial
      );
    });
    const showStash = computed(() => {
      return (
        store.state.gameState === Constants.gamemodes.stash &&
        !tutorialVisited.value.stashTutorial
      );
    });
    const showDead = computed(() => {
      return (
        store.state.gameState === Constants.gamemodes.combat &&
        store.state.characters[store.state.currentCharacter].stats.isDead &&
        !tutorialVisited.value.deathTutorial
      );
    });

    const forResurrect = computed(() => {
      return (
        store.state.gameState === Constants.gamemodes.healers &&
        store.state.characters[store.state.currentCharacter].stats.isDead &&
        !tutorialVisited.value.deathTutorial
      );
    });

    const showTurns = computed(() => {
      if (store.state.characters[store.state.currentCharacter] != null) {
        return (
          store.state.gameState === Constants.gamemodes.wandering &&
          store.state.characters[store.state.currentCharacter].stats.turns ===
            0 &&
          !tutorialVisited.value.turnsTutorial
        );
      } else {
        return false;
      }
    });

    const forHealth = computed(() => {
      if (store.state.characters[store.state.currentCharacter] != null) {
        return (
          store.state.gameState === Constants.gamemodes.wandering &&
          store.state.characters[store.state.currentCharacter].stats.hp <
            store.state.characters[store.state.currentCharacter].stats
              .totalHp &&
          (store.state.prevState === Constants.gamemodes.looting ||
            store.state.prevState === Constants.gamemodes.combat) &&
          !tutorialVisited.value.healTutorial
        );
      } else {
        return false;
      }
    });

    const forLetsGoToBattle = computed(() => {
      return (
        store.state.intro.letsGoToBattle &&
        !tutorialVisited.value.setStatsTutorial
      );
    });

    const forSearchingPlains = computed(() => {
      return (
        store.state.intro.searchPlains &&
        !tutorialVisited.value.setStatsTutorial
      );
    });

    const forLootStash = computed(() => {
      return (
        store.state.intro.lootStash &&
        store.state.gameState === Constants.gamemodes.looting &&
        !tutorialVisited.value.claimLootTutorial
      );
    });

    const forGuildQuests = computed(() => {
      return (
        store.state.gameState === Constants.gamemodes.defenderguild &&
        !tutorialVisited.value.guildQuestsTutorial
      );
    });

    return {
      //computed
      showCombat,
      showInn,
      showEquipment,
      showInventory,
      showBank,
      showStash,
      showDead,
      forResurrect,
      forHealth,
      forSetStats,
      forLetsGoToBattle,
      forSearchingPlains,
      forLootStash,
      forGuildQuests,
      //methods
      ended,
      //data
      steps,
      options,
      showTurns,
      showBlueprintFlow,
    };
  },
};
</script>

<style lang="scss" scoped></style>
