<template>
  <div :class="'crafting-wrapper ' + currentClass">
    <div class="crafting-menu">
      <div class="close-button button-small" @click="hide"><span>x</span></div>
      <div
        v-if="currentItem && currentItem.rarity"
        :class="'title ' + currentItem.rarity.toLowerCase()"
      >
        <div class="img-wrapper">
          <img :src="getImage(currentItem)" :alt="currentItem.name" />
        </div>
        <h2 :class="currentItem.rarity.toLowerCase()">
          {{ currentItem.name }}
          <span class="broken" v-if="itemStats && itemStats.isBroken"
            >[Broken]</span
          >
        </h2>
      </div>
      <div class="stats" v-if="itemStats">
        <div class="stat">
          <template v-if="itemStats.durability">
            <span class="desc">Durability</span>
            <span class="num"
              ><span
                class="addDurability"
                v-if="canUpgradeDurability"
                @click="showDurabilityUpgrade"
                ><img src="@/assets/plus.png" alt="plus" />{{
                  itemStats.durability
                }}/{{ itemStats.totalDurability }}</span
              >
              <span v-else>
                {{ itemStats.durability }}/{{ itemStats.totalDurability }}
              </span>
            </span>
          </template>
          <template v-if="itemStats.craftingRequired">
            <span class="desc craft">Crafting Required</span>
            <span class="num craft">{{ itemStats.craftingRequired }}</span>
          </template>
          <template v-if="isMissingDurability">
            <span class="desc craft">Repair Cost</span>
            <span class="num craft">{{ itemRepairCost }} DCAR</span>
          </template>
          <template
            v-if="
              itemStats.craftingSkills && itemStats.craftingSkills.length > 0
            "
          >
            <template v-for="skill in itemStats.craftingSkills" :key="skill">
              <span class="desc craft">{{ skill }} to Craft</span>
              <span class="num craft">{{
                itemStats.craftingSkills[skill]
              }}</span>
            </template>
          </template>
        </div>
      </div>
      <div class="set" v-if="itemSet">
        <span class="desc">{{ itemSet.desc }} Set</span>
        <span class="num">{{ itemSet.bonus }}% Per Piece</span>
      </div>
      <div class="buttons" v-if="currentItem && isWandering">
        <template v-if="isConsumable">
          <DialogButton :buttonText="'Use'" @click="$emit('use')" />
        </template>
        <template v-else>
          <div class="resources">
            <div
              :class="'item ' + (item.hasRequiredResource ? '' : 'not-enough')"
              v-for="item in repairResources"
              :key="item"
            >
              <img :src="item.image" :alt="item.name" />
              <span
                >{{ item.name }}
                <template v-if="isMissingDurability"
                  >X{{ item.quantity }}</template
                >
              </span>
            </div>
          </div>
          <template v-if="isMissingDurability && canRepair">
            <DialogButton
              v-if="!approvedResources"
              :buttonText="'Approve Resources'"
              @click="approveResources"
              :isLoading="isLoading"
            />
            <DialogButton
              v-else-if="
                parseFloat(itemRepairCost) > parseFloat(dcarApprovalAmount)
              "
              :buttonText="'Approve DCAR'"
              @click="approveDcar"
              :isLoading="isLoading"
            />
            <DialogButton
              v-else
              :buttonText="'Repair'"
              @click="repairItem"
              :isLoading="isLoading"
            />
            <DialogButton
              :buttonText="'Send to Repair Market'"
              @click="$emit('repairList')"
              :isLoading="isLoading"
            />
          </template>
          <template v-else>
            <DialogButton
              :buttonText="'Repair (' + cantRepairReason + ')'"
              class="disabled"
            />
            <DialogButton
              v-if="isMissingDurability"
              :buttonText="'Send to Repair Market'"
              @click="$emit('repairList')"
              :isLoading="isLoading"
            />
          </template>
          <div class="resources" v-if="breakdownResources">
            <div class="item" v-for="item in breakdownResources" :key="item">
              <img :src="item.image" :alt="item.name" />
              <span>{{ item.name }} X{{ item.quantity }}</span>
            </div>
          </div>
          <DialogButton
            v-if="approvedEquipment"
            :buttonText="'Break Down'"
            @click="breakDownItem"
            :isLoading="isLoading"
          />
          <DialogButton
            v-else
            :buttonText="'Approve NFT Burn'"
            @click="equipmentApproval"
            :isLoading="isLoading"
          />
          <span class="small-text" v-if="itemStats.isBroken"
            >Broken items receive no materials when broken down.</span
          >
        </template>
        <WaitingForTurn />
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import { io } from "socket.io-client";
import { useStore } from "vuex";
import { useUser } from "../composables/user";
import Constants from "../consts/constants";
import apiConnector from "../game/apiConnector";
import {
  getDCARContract,
  getDCGEquipmentContract,
  getDCGResourcesContract,
  getEquipmentBurnContract,
  getLoadRepair,
} from "../utils/getContract";
import { parseError } from "../utils/helpers";
import DialogButton from "./DialogButton.vue";
import WaitingForTurn from "./LoadingComponents/WaitingForTurn.vue";

export default {
  name: "PopupInfo",
  components: {
    DialogButton,
    WaitingForTurn,
  },
  emits: ["repairList", "craftBreak", "use"],
  props: {},
  data() {
    return {
      socket: io(Constants.apiUrl, { transports: ["websocket"] }),
      $store: {},
      isVisible: true,
      isInventoryOpen: false,
      approvedEquipment: false,
      approvedResources: false,
      approvedDCAR: false,
      dcarAmount: 0,
      dcarApprovalAmount: 0,
      itemRepairCost: 0,
      address: "",
      signer: "",
    };
  },
  methods: {
    async checkUserDcarBalance() {
      const DcarContract = getDCARContract(this.signer);
      const dcarBalance = await DcarContract.balanceOf(this.address);
      this.dcarAmount = ethers.utils.formatEther(dcarBalance);
    },
    async checkEquipmentApproval() {
      const EquipmentBurnContract = getEquipmentBurnContract(this.signer);
      const EquipmentContract = getDCGEquipmentContract(this.signer);
      const isBurningApproved = await EquipmentContract.isApprovedForAll(
        this.address,
        EquipmentBurnContract.address
      );

      console.log("isBurningApproved", isBurningApproved);
      if (isBurningApproved) {
        this.approvedEquipment = true;
      }
    },
    async checkResourcesApproval() {
      const RepairContract = getLoadRepair(this.signer);
      const ResourcesContract = getDCGResourcesContract(this.signer);
      const isResourcesApproved = await ResourcesContract.isApprovedForAll(
        this.address,
        RepairContract.address
      );

      console.log("isResourcesAppoved", isResourcesApproved);
      if (isResourcesApproved) {
        this.approvedResources = true;
      }
    },
    async checkDcarApproved() {
      const RepairContract = getLoadRepair(this.signer);
      const DcarContract = getDCARContract(this.signer);
      let dcarAllowance = await DcarContract.allowance(
        this.address,
        RepairContract.address
      );

      dcarAllowance = ethers.utils.formatUnits(dcarAllowance);
      console.log("dcar allowance", dcarAllowance);
      if (parseInt(dcarAllowance) > 0) {
        this.dcarApprovalAmount = dcarAllowance;
        this.approvedDCAR = true;
      }
    },
    async approveResources() {
      this.$store.commit("setTurnHappening", true);
      try {
        const ResourcesContract = getDCGResourcesContract(this.signer);
        const RepairContract = getLoadRepair(this.signer);
        const tx = await ResourcesContract.setApprovalForAll(
          RepairContract.address,
          true
        );
        const receipt = await tx.wait();
        if (receipt.status == 1) {
          this.approvedResources = true;
        }
        this.$store.commit("setTurnHappening", false);
      } catch (e) {
        console.log("error approving resources", e);
        this.$store.commit("setTurnHappening", false);
      }
    },
    async approveDcar() {
      this.$store.commit("setTurnHappening", true);
      try {
        const DcarContract = getDCARContract(this.signer);
        const RepairContract = getLoadRepair(this.signer);
        const tx = await DcarContract.approve(
          RepairContract.address,
          ethers.utils.parseEther("1000000000")
        );
        const receipt = await tx.wait();
        if (receipt.status == 1) {
          await this.checkDcarApproved();
        }
        this.$store.commit("setTurnHappening", false);
      } catch (e) {
        console.log("error approving dcar", e);
        this.$store.commit("setTurnHappening", false);
      }
    },
    async breakDownItem() {
      try {
        const EquipmentBurnContract = getEquipmentBurnContract(this.signer);
        this.$store.commit("setTurnHappening", true);
        const burnEquipmentTx = await EquipmentBurnContract.burnEquipment(
          this.currentItem.id
        );

        const receipt = await burnEquipmentTx.wait();
        if (receipt.status) {
          const itemArray = [this.currentItem.id];
          await this.$store.commit("setInventoryBreakdown", itemArray);
          this.$store.commit("setTurnHappening", false);
        } else {
          this.$store.commit("setTurnHappening", false);
          this.$store.commit("setNotification", "Transaction failed...");
        }
      } catch (e) {
        this.$store.commit("setTurnHappening", false);
        this.$store.commit("setNotification", parseError(e));
        console.log(e);
      }

      // $emit('craftBreak')
      //this.$store.commit("breakDownItem", this.currentItem);
    },
    async repairCost() {
      const totalDurability = this.currentItem.stats.totalDurability;
      const currentDurability = this.currentItem.stats.durability;
      const durabilityNeeded = totalDurability - currentDurability;
      const rarity = this.currentItem.rarity.toLowerCase();
      console.log(`durability needed ${durabilityNeeded}`);
      const RepairContract = getLoadRepair(this.signer);
      const repairCost = await RepairContract.priceForRepair(
        rarity,
        durabilityNeeded
      );
      this.itemRepairCost =
        Math.round(ethers.utils.formatEther(repairCost) * 100) / 100;
    },
    async signRepairItem() {
      const sessionId = localStorage.getItem("sessionId");
      const heroId = this.character.number;
      const equipmentId = this.currentItem.id;

      const response = await fetch(Constants.apiUrl + "contract/sign-repair", {
        method: "POST",
        body: JSON.stringify({
          sessionId,
          heroId,
          account: this.address,
          equipmentId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (!data.success) {
        this.$store.commit("setNotification", data.message);
      }
      console.log(data);
      return {
        repairId: data.repairId,
        dcarAmount: data.dcarAmount,
        resourceCount: data.resourceCount,
        resourceIds: data.resourceIds,
        quantities: data.quantities,
        signature: data.signature,
        nonce: data.nonce,
      };
    },
    async repairItem() {
      this.$store.commit("setTurnHappening", true);
      const RepairContract = getLoadRepair(this.signer);
      const {
        repairId,
        dcarAmount,
        resourceCount,
        resourceIds,
        quantities,
        signature,
        nonce,
      } = await this.signRepairItem();
      try {
        const tx = await RepairContract.repair(
          repairId,
          dcarAmount,
          resourceCount,
          resourceIds,
          quantities,
          signature,
          nonce
        );

        if (tx?.hash) {
          await apiConnector.handleTransactionHash(
            this.$store.state.account,
            localStorage.getItem("sessionId"),
            this.character.number,
            "repair",
            tx.hash
          );
        }

        const receipt = await tx.wait();

        if (receipt.status === 1) {
          const payload = {
            repairItem: this.currentItem,
            guildRepair: false,
            saleIndex: null,
            txHash: tx.hash,
          };
          this.$store.commit("setRepair", payload);
        }
      } catch (error) {
        console.log(error);
        this.$store.commit("setTurnHappening", false);
      }
    },
    async equipmentApproval() {
      try {
        this.$store.commit("setTurnHappening", true);
        const EquipmentBurnContract = getEquipmentBurnContract(this.signer);
        const EquipmentContract = getDCGEquipmentContract(this.signer);
        const txApproveEquipment = await EquipmentContract.setApprovalForAll(
          EquipmentBurnContract.address,
          true
        );
        await txApproveEquipment.wait();
        this.$store.commit("setTurnHappening", false);
        await this.checkEquipmentApproval();
      } catch (e) {
        this.$store.commit("setTurnHappening", false);
        this.$store.commit("setNotification", parseError(e));
        console.log(e);
      }
    },
    hovered() {
      this.$store.commit("setTooltipHovered");
    },
    unhovered() {
      this.$store.commit("setTooltipUnhovered");
    },
    hide() {
      this.$store.commit("hideCrafting");
    },
    getImage(item) {
      if (item.isBlueprint) {
        return (
          "https://ik.imagekit.io/dcg/blueprints/" + item.imageName + "?tr=w-90"
        );
      }

      return "https://ik.imagekit.io/dcg/equip/" + item.imageName + "?tr=w-90";
    },
    showDurabilityUpgrade() {
      this.$store.commit("setDurability");
    },
  },
  computed: {
    isRepairListShowing() {
      return this.$store.state.isRepairListing;
    },
    isLoading() {
      return this.$store.state.isTurnHappening;
    },
    hasTurns() {
      return (
        this.$store.state.characters[this.$store.state.currentCharacter].stats
          .turns > 0
      );
    },
    itemSet() {
      if (!this.itemStats || !this.itemStats.set) {
        return null;
      }

      const setDesc = this.$store.state.sets.find(
        (s) => s.id == this.itemStats.set
      );

      return setDesc
        ? { desc: setDesc.desc, bonus: this.itemStats.setBonus }
        : null;
    },
    hasEnoughCrafting() {
      const characterCraftingBonus =
        this.$store.state.equipmentStats().crafting;
      const characterCrafting = this.character.stats.crafting;

      return (
        this.itemStats &&
        this.itemStats.craftingRequired <=
          characterCraftingBonus + characterCrafting
      );
    },
    repairResources() {
      return this.$store.state.repairResources();
    },
    cantRepairReason() {
      if (!this.hasTurns) {
        return "No turns left";
      }
      if (!this.hasEnoughCrafting) {
        return "Not enough crafting";
      }
      if (!this.repairResources.every((r) => r.hasRequiredResource)) {
        return "Not enough resources";
      }
      if (this.dcarAmount < this.itemRepairCost) {
        return "Not enough DCAR";
      }
      if (!this.isMissingDurability) {
        return "Not damaged";
      }
      return "";
    },
    canRepair() {
      return (
        this.dcarAmount > this.itemRepairCost &&
        this.hasTurns &&
        this.repairResources.every((r) => r.hasRequiredResource) &&
        this.hasEnoughCrafting
      );
    },
    isMissingDurability() {
      if (!this.itemStats) {
        return false;
      }
      return this.itemStats.durability < this.itemStats.totalDurability;
    },
    character() {
      return this.$store.state.characters[this.$store.state.currentCharacter];
    },
    isWandering() {
      return this.$store.state.gameState == Constants.gamemodes.wandering;
    },
    isWeapon() {
      return this.$store.state.hoveredItem.type == Constants.slots.hand1;
    },
    breakdownResources() {
      return false;
    },
    canUpgradeDurability() {
      return !this.isConsumable && !this.currentItem.isBlueprint;
    },
    isConsumable() {
      return (
        this.$store.state.hoveredItem.type &&
        this.$store.state.hoveredItem.type == Constants.useable.consumable
      );
    },
    isResources() {
      if (this.itemStats && this.itemStats.resources) {
        return true;
      }

      return false;
    },
    currentClass() {
      return this.$store.state.isCrafting ? "show" : "hide";
    },
    currentItem() {
      return this.$store.state.hoveredItem;
    },
    itemStats() {
      if (!this.currentItem) {
        return null;
      }

      return this.currentItem.stats;
    },
  },
  async mounted() {
    this.$store = useStore();
    const { address, signer } = useUser();
    this.address = address;
    this.signer = signer;
    await this.repairCost();
    this.checkEquipmentApproval();
    this.checkResourcesApproval();
    this.checkDcarApproved();
    this.checkUserDcarBalance();

    this.socket.emit("join", { sessionId: localStorage.getItem("sessionId") });

    this.socket.on("connect", () => {
      console.log("connected to socket");
    });

    this.socket.on("repair", (results) => {
      if (results.success) {
        this.$store.commit(
          "setNotification",
          `Repaired ${this.currentItem.name}!`
        );
        this.$store.commit("setInventory", results.inventory);
        this.$store.commit("setHeroStats", results.heroStats);
        this.$store.commit("setTurnHappening", false);
      } else {
        this.$store.commit("setNotification", results.message);
      }
    });

    this.socket.io.on("error", () => {
      console.log(
        "Unable to connect to server. Please try again or let the team know"
      );
    });

    this.$store.watch(
      (state) => state.inventory,
      () => {
        this.hide();
      }
    );
  },
  unmounted() {
    this.socket.removeAllListeners("repair");
    this.socket.disconnect();
  },
};
</script>
<style lang="scss">
@import "../assets/scss/globals.scss";

.crafting-guild-wrapper > .crafting-dialog {
  @media only screen and (max-width: 576px) {
    width: calc(100% - 134px) !important;
    height: 60%;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin-left: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.addDurability {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex !important;
  gap: 5px;
  width: 100% !important;
  justify-content: right;
  align-items: center;
}

.addDurability img {
  width: 12px;
  height: 12px;
}

.addDurability:hover {
  text-decoration: underline;
}
.small-text {
  font-size: 80%;
}
.crafting-wrapper {
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;

  &.show {
    opacity: 1;
    pointer-events: all;

    transition: opacity 0.35s linear;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.35s linear;
  }
}

.crafting-menu {
  background: url("https://cdn.dragoncrypto.io/uiassets/tooltip.png") no-repeat
    top left;
  width: 298px;
  height: 334px;
  position: relative;

  .close-button {
    position: absolute;
    top: -2px;
    right: -2px;
  }

  .broken {
    color: $dmg;
  }

  .resources {
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      border-radius: 3px;
      border: 1px solid #3d3d40;
      padding: 4px;
      margin: 0 3px;
      display: flex;
      align-items: center;
      justify-content: start;

      flex-direction: column;

      font-size: 65%;

      &.not-enough {
        border: 1px solid $dmg;
        background: transparentize($color: $dmg, $amount: 0.5);
      }

      img {
        max-width: 36px;
      }
    }
  }

  .desc {
    text-align: left;

    &.healing {
      color: $text-highlight;
    }

    &.craft {
      color: $legendary;
    }
  }

  .num {
    text-align: right;

    &.healing {
      color: $text-highlight;
    }

    &.craft {
      color: $legendary;
    }
  }

  .bonuses {
    border-top: 1px solid #3d3d40;
    width: 85%;
    margin: 0 auto;
    margin-top: 12px;
    padding-top: 10px;
    font-size: 80%;
    color: #68b563;
    display: flex;
    flex-wrap: wrap;

    span {
      width: 33%;
    }
  }

  .stats {
    margin-top: 10px;
    color: #cac5c4;
    font-size: 80%;

    .stat {
      margin: 0 auto;
      width: 85%;
    }

    span {
      width: 49%;
      display: inline-block;
    }
  }

  .set {
    margin-top: 8px;
    color: $artefact;
    width: 85%;
    font-size: 80%;
    margin: 0 auto;

    span {
      width: 49%;
      display: inline-block;
    }
  }

  .title {
    padding-top: 8px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: center;
    border-bottom: 1px solid #3d3d40;

    &.legendary {
      // .img-wrapper{

      // }

      h2 {
        color: $legendary;
      }
    }

    &.uncommon {
      h2 {
        color: $uncommon;
      }
    }

    &.shoddy {
      h2 {
        color: $shoddy;
      }
    }

    &.rare {
      h2 {
        color: $rare;
      }
    }

    &.mythical {
      h2 {
        color: $mythical;
      }
    }

    &.epic {
      h2 {
        color: $epic;
      }
    }

    &.artefact {
      h2 {
        color: $artefact;
      }
    }

    img {
      max-width: 45px;
      margin-right: 12px;
    }

    h2 {
      color: #fff;
      text-align: left;
      font-family: "IM Fell English", serif;
      font-size: 90%;
      text-transform: none;
      width: 75%;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 9px;

    .dialog-button {
      margin-top: 0;
    }
  }
}
</style>
