<template>
  <div>
    <button @click="show" class="inventory-pet" v-if="location === 'inventory'">
      Pets
    </button>
    <button
      v-if="location === 'equipment'"
      class="open-pets-button"
      @click="show"
      title="Select your pet"
    >
      <SkeletalLoading width="44" height="44" v-if="!selectedPet" />
      <template v-else>
        <span
          :style="`background-image: url(${selectedPet.image})`"
          v-if="selectedPet !== null"
        />
        <p v-else>No Pet Selected</p>
      </template>
    </button>
    <BasicPopup v-if="petOpenState" header="Your Pets" class="pets-popup">
      <div class="close-button button-small" @click="hide">
        <span>x</span>
      </div>
      <div class="inner-wrapper">
        <div class="inner-grid">
          <GenericLoading v-if="loading" />
          <template v-else>
            <carousel
              id="pet-cards"
              :settings="settings"
              :breakpoints="breakpoints"
              snapAlign="center"
              v-if="nftDetails.length > 0"
            >
              <slide v-for="nft in nftDetails" :key="nft.id + nft.address">
                <PetCard @updatePets="getPets" :pet="nft" />
              </slide>
              <template #addons>
                <navigation />
              </template>
            </carousel>
            <p v-else>
              You don't have any pet nfts. You can start by getting
              <a
                href="https://nftrade.com/collection/tiny-dragon?traitIds="
                rel="nofollow"
                target="_blank"
                >Tiny Dragons</a
              >
            </p>
          </template>
        </div>
      </div>
    </BasicPopup>
  </div>
</template>

<script>
import { onMounted, computed, ref, onUnmounted } from "vue";
import { useStore } from "vuex";
import apiConnector from "../../game/apiConnector";
import Constants from "../../consts/constants";
import BasicPopup from "../Popup/BasicPopup.vue";
import GenericLoading from "../LoadingComponents/GenericLoading.vue";
// import DialogButton from "../DialogButton.vue";
import SkeletalLoading from "../LoadingComponents/SkeletalLoading.vue";
import { parseError } from "../../utils/helpers";
import PetCard from "./PetCard.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

import { useUser } from "../../composables/user";
// import { ethers } from "ethers";

import {
  getERC721Contract,
  getNFTFinderContract,
} from "../../utils/getContract";
import { ethers } from "ethers";
export default {
  name: "PetPopup",
  props: {
    location: {
      type: String,
    },
  },
  components: {
    BasicPopup,
    SkeletalLoading,
    GenericLoading,
    PetCard,
    Carousel,
    Slide,
    Navigation,
  },
  setup() {
    const store = useStore();
    // const nftDetails = ref([]);
    const loading = ref(false);
    const isEquipping = ref(false);
    const whitelistedNfts = ref([]);
    const nftIds = ref([]);
    const nftMetadata = ref([]);
    const slider = ref(null);

    const settings = {
      itemsToShow: 1,
      snapAlign: "center",
    };
    const breakpoints = {
      // 700px and up
      700: {
        itemsToShow: 3.5,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 4,
        snapAlign: "start",
      },
      1600: {
        itemsToShow: 5,
        snapAlign: "start",
      },
    };

    const { address, signer } = useUser();

    onMounted(async () => {
      await getWhitelistedNfts();
    });

    onUnmounted(() => {});

    /**
     * Methods
     */
    const getWhitelistedNfts = async () => {
      const response = await fetch(Constants.apiUrl + "get-whitelisted-nfts", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      whitelistedNfts.value = await response.json();
    };
    const getNFTIds = async (nftAddress) => {
      try {
        const NFTContract = getERC721Contract(signer.value, nftAddress);
        const NFTFinder = getNFTFinderContract(signer.value);
        const balance = await NFTContract.balanceOf(address.value);
        const balanceDec = ethers.utils.formatUnits(balance, 0);
        let nftArray = [];
        if (balanceDec > 0) {
          nftArray = await NFTFinder.findTokens(
            NFTContract.address,
            address.value
          );
          nftIds.value[nftAddress] = await Promise.all(nftArray);
          nftIds.value[nftAddress].forEach((id, index) => {
            nftIds.value[nftAddress][index] = ethers.utils.formatUnits(id, 0);
          });
        }
      } catch (error) {
        loading.value = false;
        console.log(error);
        store.commit("setNotification", parseError(error));
      }
    };

    const getNFTStats = async (nftAddress, nftIds) => {
      try {
        const sessionId = localStorage.getItem("sessionId");
        let heroId = character.value.number;
        const response = await fetch(Constants.apiUrl + "get-player-pet-nfts", {
          method: "POST",
          body: JSON.stringify({
            sessionId,
            nftIds,
            nftAddress,
            heroId,
            account: store.state.account,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        return data;
        // nftDetails.value.push(...data.pets);
      } catch (error) {
        loading.value = false;
        console.log(error);
        store.commit("setNotification", parseError(error));
      }
    };

    // TODO: Need to refactor this and make it async
    const equipPet = (pet) => {
      try {
        let nftId = pet.id; // TODO - Get id for NFT being equipped
        let currentAccount = store.state.account;
        let sessionId = store.state.getSessionId();
        let number = character.value.number;
        isEquipping.value = true;
        apiConnector
          .callEquipNft(currentAccount, sessionId, number, nftId, pet.address)
          .then((result) => {
            if (!result.success) {
              isEquipping.value = false;
              store.commit("setNotification", result.message);
              return;
            }
            if (result.success) {
              store.commit("pets/setSelectedPet", result.equippedNft);
              isEquipping.value = false;
            }
          })
          .catch((e) => {
            isEquipping.value = false;
            console.log(e);
          });
      } catch (error) {
        isEquipping.value = false;
        console.log(error);
        store.commit("setNotification", parseError(error));
      }
    };

    const hide = () => {
      store.commit("pets/setIsPetsOpen", false); // Hiding in mobile view as well
    };

    const show = async () => {
      store.commit("pets/setIsPetsOpen", true); // Hiding in mobile view as well
      await getPets();
    };
    const getPets = async () => {
      loading.value = true;
      const getNftArrayPromises = [];
      // nftDetails.value = [];
      // Getting IDS of all whitelisted NFTS
      whitelistedNfts.value.forEach((whitelisted) => {
        // getNftArrayPromises.push(getNFTs(whitelisted.address));
        getNftArrayPromises.push(getNFTIds(whitelisted.address));
      });
      await Promise.all(getNftArrayPromises);

      // Getting metadata of all ids fetched according to address
      const getMetadataPromises = [];
      whitelistedNfts.value.forEach((whitelisted) => {
        getMetadataPromises.push(
          getNFTStats(whitelisted.address, nftIds.value[whitelisted.address])
        );
      });
      const pets = await Promise.all(getMetadataPromises);

      // Filtering out equipped pets
      let filteredPets = pets[0].pets;

      store.commit("pets/setSelectedPet", pets[0].selectedPet);

      if (filteredPets) {
        filteredPets = pets[0].pets.filter((pet) => !pet.isEquipped);
      }

      // Adding equipped pet if it is equipped by the current player
      if (selectedPet.value) {
        filteredPets.unshift(selectedPet.value);
      }
      store.commit("pets/setPets", filteredPets);

      loading.value = false;
    };

    const selectNft = async (pet) => {
      // store.commit("pets/setSelectedPet", pet);
      if (!pet.isEquipped) {
        await equipPet(pet);
        hide();
      }
    };
    /**
     * Computed Methods
     */
    const nftDetails = computed(() => {
      return store.state.pets.pets;
    });
    const character = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });

    const selectedPet = computed(() => {
      return store.state.pets.selectedPet;
    });
    const petOpenState = computed(() => {
      return store.state.pets.isPetsOpen;
    });
    return {
      /* All the computed values */
      character,
      petOpenState,
      selectedPet,
      nftDetails,
      /* All the methods */
      hide,
      show,
      selectNft,
      getPets,
      /* All the data values */
      loading,
      nftMetadata,
      isEquipping,
      slider,
      settings,
      breakpoints,
    };
  },
};
</script>
<style lang="scss">
section#pet-cards {
  /* top: 44%; */
  /* transform: translateY(-50%); */
  .carousel__prev {
    left: -12px;
    background-color: #19486f;
    color: #fff;
    @media only screen and (max-width: 576px) {
      display: none;
    }
  }
  .carousel__next {
    right: -12px;
    background-color: #19486f;
    color: #fff;
    @media only screen and (max-width: 576px) {
      display: none;
    }
  }
  .carousel__prev--in-active,
  .carousel__next--in-active {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.open-pets-button {
  position: absolute;
  z-index: 10;
  border: none;
  box-shadow: none;
  padding: 0;
  height: 44px;
  display: grid;
  align-items: center;
  width: 44px;
  background: transparent;
  cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
    auto;
  span {
    width: 44px;
    height: 44px;
    background-size: cover;
    background-position: center center;
    &.skeleton-box {
      opacity: 0.1;
    }
  }
}
.close-button {
  position: absolute;
  top: -$popup-border-size;
  right: -$popup-border-size;
}
.inventory-pet {
  background-image: url("../../assets/ui/pet-inventory-button.png");
  width: 56px;
  height: 53px;
  border: none;
  text-indent: -9999px;
  background-color: transparent;
  cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
    auto;
}
.pets-popup {
  width: calc(90% - $popup-border-size - $popup-border-size);
  height: calc(600px - $popup-border-size - $popup-border-size);
  max-width: 1200px;
  min-width: 1000px;
  z-index: 21;
  @media only screen and (max-width: 576px) {
    min-width: calc(100% - $popup-border-size - $popup-border-size);
  }
  .inner-wrapper {
    width: calc(100% + $popup-border-offset + $popup-border-offset);
    @media only screen and (max-width: 576px) {
      height: calc(100% + $popup-border-offset + $popup-border-offset);
      overflow-y: scroll;
    }
    left: -$popup-border-offset;
    position: relative;
    top: -35px;
  }
  .inner-grid {
    position: relative;
    width: 94%;
    margin: 0 auto;
    @media only screen and (max-width: 576px) {
      /* grid-template-columns: 1fr; */
    }
  }
}
</style>
