<template>
  <div :class="getClass">
    <div class="content-wrapper">
      <p>{{ message }}</p>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";

export default {
  name: "Notifications",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    message() {
      return this.$store.state.notificationText;
    },
    getClass() {
      return (
        "notification-wrapper " +
        (this.$store.state.hasNotification ? "show" : "hide")
      );
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.notification-wrapper {
  pointer-events: none;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 50; //to ensure notification is always on top

  .content-wrapper {
    min-width: 40vw;
    color: #fff;
    background: rgba(0, 0, 0, 0.65);
    border-radius: 8px;

    p {
      margin: 12px;
      font-size: 80%;
    }
  }
}

.show {
  opacity: 1;
  pointer-events: all;
  top: 65px;

  transition: all 0.35s ease-in;
}

.hide {
  opacity: 0;
  pointer-events: none;
  top: -50px;
  transition: all 0.35s ease-out;
}
</style>
