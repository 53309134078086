<template>
  <div class="action-button">
    <span :class="alert ? 'alertUser' : 'hide'">{{
      alert ? "no" : "yes"
    }}</span>
    <img
      :src="buttonImage"
      :alt="buttonName"
      :class="glowEffect ? 'glow' : 'none'"
    />
  </div>
</template>

<script>
export default {
  name: "ActionButton",
  props: {
    buttonName: {
      type: String,
    },
    glowEffect: {
      type: Boolean,
    },
    alert: {
      type: Boolean,
    },
  },
  computed: {
    buttonImage() {
      return (
        "https://cdn.dragoncrypto.io/uiassets/button-" +
        this.buttonName +
        ".png"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.alertUser {
  background: #cf0303;
  width: 10px;
  height: 10px;
  display: grid;
  align-items: center;
  color: #fff;
  font-weight: bold;
  border-radius: 100%;
  font-size: 0.7rem;
  position: absolute;
  top: 2px;
  left: 6px;
  text-indent: -9999px;
}

.hide {
  display: none;
}

.action-button {
  /* cursor: pointer; */
  cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
    auto;

  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  margin-top: 9px;

  &:hover {
    transform: scale(1.2);
    transition: transform 0.2s ease-in-out;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
  }

  .glow {
    filter: drop-shadow(0 0 5px #fff);
  }
}
</style>
