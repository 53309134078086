<template>
  <button
    @click="dialogClick"
    :class="[
      'dialog-button ',
      buttonSize ? buttonSize.toLowerCase() : '',
      isActive ? '' : ' disabled',
      buttonTextTextWhitespaceOnly,
    ]"
    :disabled="isLoading || !isActive"
  >
    <span v-if="buttonText"
      >{{ completedButtonText }}<InlineLoading v-if="isLoading" />
    </span>
  </button>
</template>

<script>
import { useStore } from "vuex";
import InlineLoading from "./LoadingComponents/InlineLoading.vue";

export default {
  name: "DialogButton",
  props: {
    buttonText: {
      type: String,
    },
    buttonSize: {
      // med/small/large/tiny
      type: String,
    },
    requiresTurn: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
  },
  components: {
    InlineLoading,
  },
  data() {
    return {
      $store: {},
    };
  },
  computed: {
    buttonTextTextWhitespaceOnly() {
      if (this.buttonText) {
        return this.buttonText.replace(/[^a-zA-Z ]/g, "");
      }
      return this.buttonText;
    },
    completedButtonText() {
      if (
        this.hasCharacter &&
        this.requiresTurn &&
        this.$store.state.characters[this.$store.state.currentCharacter].stats
          .turns == 0
      ) {
        return "Not enough turns";
      }

      if (this.buttonText.length > 26) {
        return this.buttonText.substring(0, 26) + "..";
      }
      return this.buttonText;
    },
    hasCharacter() {
      return (
        this.$store.state.characters[this.$store.state.currentCharacter] !=
          null &&
        this.$store.state.characters[this.$store.state.currentCharacter] !=
          "undefined"
      );
    },
    isActive() {
      if (
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          null ||
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          "undefined"
      ) {
        return false;
      }
      if (this.requiresTurn) {
        return (
          this.$store.state.characters[this.$store.state.currentCharacter].stats
            .turns > 0
        );
      }

      return true;
    },
  },
  methods: {
    dialogClick() {
      if (this.isActive) {
        this.$emit("buttonClick");
      }
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.dialog-button {
  font-family: "Lato", sans-serif;
  transform-origin: center center;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
  margin-top: 9px;
  width: 280px;
  height: 45px;
  background: url("https://cdn.dragoncrypto.io/uiassets/button.png") no-repeat
    center center;
  opacity: 0.85;
  border: none;
  box-shadow: none;
  color: #fff;
  font-size: 1rem;
  span {
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
  }
  &:disabled {
    cursor: not-allowed;
    filter: grayscale(100%);
  }

  &.Fight {
    cursor: url("https://cdn.dragoncrypto.io/uiassets/attack_cursor.png"), auto;
  }

  &.loot {
    cursor: url("https://cdn.dragoncrypto.io/uiassets/loot_cursor.png"), auto;
  }

  &.med {
    margin-top: 4px;
    width: 191px;
    height: 41px;
    background: url("https://cdn.dragoncrypto.io/uiassets/button-med.png")
      no-repeat center center;

    span {
      line-height: 41px;
    }
  }

  &.small {
    margin-top: 4px;
    width: 147px;
    height: 41px;
    background: url("https://cdn.dragoncrypto.io/uiassets/dialog-button-small.png")
      no-repeat center center;

    span {
      line-height: 41px;
    }
  }
  &.tiny {
    margin-top: 4px;
    width: 70px;
    height: 41px;
    background: url("https://cdn.dragoncrypto.io/uiassets/dialog-button-small.png")
      no-repeat center center;

    span {
      line-height: 41px;
    }
  }

  &.disabled {
    filter: grayscale(100);
  }

  span {
    line-height: 45px;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 80%;
  }

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    opacity: 1;
  }
  .consumable-details {
    .button-group {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
  }
}
</style>
