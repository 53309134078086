import { Web3Provider } from "@ethersproject/providers";
import { BigNumber } from "ethers";
import { computed, markRaw, ref } from "vue";
import Constants from "../consts/constants.js";

const isActivated = ref(false);
const provider = ref(null);
const signer = ref(null);
const network = ref(null);
const address = ref("");
const balance = ref(BigInt(0));
const isCorrectChain = ref(false);

const deactivate = () => {
  isActivated.value = false;
  provider.value = null;
  signer.value = null;
  network.value = null;
  address.value = "";
  balance.value = BigInt(0);
};

/**
 * User Composable to initialise Ethers to access signers and providers all across application
 * @param {*} externalProvider
 */

async function activate(externalProvider) {
  if (!externalProvider)
    throw new Error("Failed to activate ethers: provider not found");

  const _provider = new Web3Provider(externalProvider);
  const _signer = _provider.getSigner();

  let _network = null;
  let _address = "";
  let _balance = BigNumber.from(0);

  // TODO: Need to fetch dcau and dcar balances on initialisation
  const getData = async () => {
    try {
      _network = await _provider.getNetwork();
      _address = await _signer.getAddress();
      _balance = await _signer.getBalance();
    } catch (err) {
      console.log(err);
    }
  };

  try {
    await getData();
  } catch (err) {
    throw new Error(err);
  }

  provider.value = markRaw(_provider);
  signer.value = markRaw(_signer);
  network.value = _network;
  address.value = _address;
  balance.value = _balance.toBigInt();
  isCorrectChain.value = network.value.chainId === parseInt(Constants.chainId);

  // const updateBalance = async (interval = 10000) => {
  //   setInterval(async () => {
  //     const _balance = await _signer.getBalance();
  //     balance.value = _balance.toBigInt();
  //   }, interval);
  // };

  // updateBalance();

  isActivated.value = true;
}

// onMounted(() => {
//   activate(window.ethereum, "any");
// });

export function useUser() {
  const chainId = computed(() => network.value?.chainId);

  return {
    // state
    isActivated,
    provider: provider, // for fixing index.d.ts compiled error, see issue/10:
    signer: signer,
    network,
    address,
    balance,
    isCorrectChain,

    // getters
    chainId,

    // methods
    activate,
    deactivate,
  };
}
