<template>
  <div class="autoplay" id="autoplay-button">
    <input
      id="autoplay"
      type="checkbox"
      hidden="hidden"
      @click="toggleAutoplay"
      v-model="autoplayState"
      :disabled="disabled || disableButton"
    />
    <label
      :class="disabled ? 'disabled' : ''"
      for="autoplay"
      id="autoplay-switch"
      ><span>Autoplay {{ disabled ? "Disabled" : "" }}</span></label
    >
  </div>
</template>
<script>
import { onMounted, ref, computed, watch, onUnmounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "AutoplayButton",
  components: {},
  props: {
    disabled: {
      type: Boolean,
    },
  },
  setup() {
    const store = useStore();
    const autoplayState = ref(false);
    const disableButton = ref(false);
    const delayTimer = ref(null);

    onMounted(() => {
      autoplayState.value = store.state.combat.isAutoplayOn;
    });

    onUnmounted(() => {
      clearTimeout(delayTimer.value);
    });

    const toggleAutoplay = () => {
      disableButton.value = true;
      delayTimer.value = setTimeout(() => {
        disableButton.value = false;
      }, 2500);

      if (autoplayState.value) {
        store.commit("combat/setIsAutoplayOn", false);
      } else {
        store.commit("combat/setIsAutoplayOn", true);
        store.dispatch("combat/autoplayAttack"); // Starting combat if autoplay turned on
      }
    };

    const isDead = computed(() => {
      return store.state.characters[store.state.currentCharacter].stats.isDead;
    });

    watch(isDead, (dead) => {
      if (dead) {
        store.commit("combat/setIsAutoplayOn", false);
      }
    });

    return {
      autoplayState,
      disableButton,
      toggleAutoplay,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.autoplay {
  position: absolute;
  bottom: 12px;
  right: 37px;
}
#autoplay-switch {
  display: inline-block;
  position: relative;
  width: 25px;
  height: 57px;
  border-radius: 2px;
  background: #3c3c3b;
  transition: background 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer;
  box-shadow: inset 0 0 4px 1px #000000c7;
  border-radius: 30px;
  &.disabled {
    filter: grayscale(100);
  }
  &:hover {
    span {
      display: block;
    }
  }
  span {
    position: absolute;
    left: 150%;
    transform: translateY(-50%);
    top: 50%;
    background: #000000b8;
    padding: 7px;
    font-size: 0.8rem;
    border-radius: 5px;
    display: none;
  }
}
#autoplay-switch::before {
  content: "";
  position: absolute;
  bottom: -4px;
  left: -5px;
  width: 35px;
  height: 35px;
  background: #231d19;
  background-image: url("../../assets/ui/autoplay-button.png");
  background-size: contain;
  border-radius: 50%;
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
    background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
#autoplay-switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
}
input:checked + #autoplay-switch {
  background: #72da67;
}
input:checked + #autoplay-switch::before {
  bottom: auto;
  top: -4px;
}
input:checked + #autoplay-switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
}
</style>
