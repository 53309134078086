<template>
  <div
    class="game-wrapper"
    @click="start"
    ref="gameWrapperRef"
    v-dragscroll:nochilddrag
  >
    <WorldMap
      @search="searchPlains"
      @healers="healersHut"
      @inn="inn"
      @marketplace="marketplace"
      @mines="searchMines"
      @deepwood="searchDeepwood"
      @forge="forge"
      @yourStash="stash"
      @craftersGuild="craftersGuild"
      @defendersGuild="defendersGuild"
      @bank="bank"
      ref="worldMapRef"
      data-dragscroll
    />
    <Hotbar />
    <ActionButtonBar
      @toggleEquipment="toggleEquipment"
      @toggleInventory="toggleInventory"
      @toggleQuests="toggleQuests"
      @toggleRankings="toggleRankings"
    />
    <Marketplace v-if="marketplaceOpenState" />
    <Forge
      v-if="forgeOpenState"
      @forgeReturn="forgeReturn"
      @forgeSmelt="forgeSmelt"
      @leave="leavePlains"
    />
    <HealersHut
      v-if="healersHutOpenState"
      @leaveHut="leavePlains"
      @leaveHutDead="leavePlains"
    />
    <Inn
      v-if="innOpenState"
      @leaveInn="leavePlains"
      @doQuestStepBard="questStepBard"
    />
    <WakeUp @inn="inn" @leave="leavePlains" />
    <BardsSong @inn="inn" @leave="leavePlains" />
    <CharacterInventory v-if="inventoryOpenState" />
    <StashInventory v-if="stashOpenState" />
    <Rankings v-if="rankingsOpenState" />
    <CharacterEquipped
      v-if="isEquipmentVisible"
      @increaseAttack="increaseStat('attack')"
      @increaseCrafting="increaseStat('crafting')"
      @increaseSpeed="increaseStat('speed')"
      @increaseEndurance="increaseStat('endurance')"
      @increaseGathering="increaseStat('gathering')"
      @increaseLuck="increaseStat('luck')"
      @increaseMagic="increaseStat('magic')"
      @increaseDefense="increaseStat('defense')"
    />
    <Searching />
    <Sleeping />

    <GatheringStart
      @gather="startGathering"
      @searchMinesAgain="searchMinesAgain"
      @searchDeepwoodAgain="searchDeepwoodAgain"
      @leave="leaveEncounter"
    />
    <Gathering
      @useGatheringTool="useGatheringTool"
      @gatherSearch="searchMines"
      @leave="leaveEncounter"
      @stopGathering="leaveEncounter"
      @searchMinesAgain="searchMinesAgain"
      @searchDeepwoodAgain="searchDeepwoodAgain"
    />
    <CombatStart @fight="fight" @flee="flee" />
    <Combat
      v-if="accountConnected && hasCharacter"
      @useSlot="useSlotCombat"
      @useFists="useFistsCombat"
      @leavePlainsDead="leavePlainsDead"
      @lootEnemy="loot"
      @healersHut="healersHut"
      @healersHutDead="healersDead"
      @fleeBattle="fleeBattle"
    />
    <LevelUp v-if="isLeveledUp" />

    <CharacterPanel />
    <Loot
      @search="searchPlains"
      @leavePlains="leavePlains"
      @healersHut="healersHut"
      @claimStash="stash"
    />
    <Multichoice
      @leave="leaveMultichoice"
      @searchAbandon="leaveMultichoiceSearch"
      @leaveFinished="leavePlains"
      @choice1="multichoice"
      @search="searchPlains"
    />
    <VolumeButton />
    <ChatBox v-if="accountConnected && hasCharacter" />
    <NotificationBox v-if="accountConnected && hasCharacter" />
    <CharacterSelect v-if="accountConnected" />
    <PopupInfo
      @use="useItem"
      @equip="equipInvItem"
      @equipHand2="equipInvItemHand2"
      @unequip="unequipInvItem"
      @crafting="craftingMenu"
      @marketSell="marketSellMenu"
      @repairList="repairListMenu"
      @craftList="craftListMenu"
      @openlootbox="openLootbox"
    />
    <Quests />

    <Crafting v-if="craftingOpenState" />

    <DailyQuest @accept="acceptDailyQuest" @decline="declineDailyQuest" />
    <GromsBank v-if="bankOpenState" />
    <CraftingMenu
      v-if="isCrafting"
      @craftBreak="craftBreak"
      @craftRepair="craftRepair"
      @repairList="repairListMenu"
    />
    <DurabilityMenu v-if="isDurability" />
    <QuestStart
      @takeQuest="takeQuest"
      @leave="leavePlains"
      @search="searchPlains"
    />
    <QuestAccepted @leave="leavePlains" @search="searchPlains" />
    <QuestCompletedBard @inn="inn" @leave="leavePlains" />

    <Notifications />
    <LoginLoading />
    <MiniPopup />
    <SessionExpired v-if="sessionExpiredState" />

    <!-- <Welcome /> -->
    <template v-if="isFirstTime">
      <IntroVideo v-if="isVideoOpen" />
      <Welcome v-if="isGuideOpen" />
    </template>
    <OnRamp v-if="accountConnected" />
    <Tutorial v-if="accountConnected" />
    <FullScreen />
    <QuickAccess />
    <QuestWidget v-if="accountConnected && hasCharacter" />
    <NestRoom v-if="isNestState" />
    <Repair v-if="isRepairState" />
    <Craft v-if="isCraftState" />
    <Guild :guildType="guildType" v-if="isGuildOpen" />
    <GuildWidget v-if="accountConnected" />
    <ListRepairItem v-if="isRepairListShowing" />
    <UpdateList v-if="accountConnected && !hasSeenUpdateList" />
  </div>
</template>

<script>
import * as XorShift from "xorshift";
import Constants from "../consts/constants";
import WorldMap from "../components/WorldMap.vue";
import Hotbar from "../components/Hotbar.vue";
import ActionButtonBar from "../components/ActionButtonBar.vue";
import CharacterPanel from "../components/CharacterPanel.vue";
import CharacterEquipped from "../components/CharacterEquipped.vue";
import CharacterInventory from "../components/CharacterInventory.vue";
import Searching from "../components/Searching.vue";
// import CombatStart from "../components/CombatStart.vue";
import CombatStart from "../components/Combat/CombatStart.vue";
// import Combat from "../components/Combat.vue";
import Combat from "../components/Combat/Combat.vue";
import LevelUp from "../components/Combat/LevelUp.vue";
import Loot from "../components/Loot.vue";
import HealersHut from "../components/HealersHut.vue";
import Inn from "../components/Inn.vue";
import Sleeping from "../components/Sleeping.vue";
import Multichoice from "../components/Multichoice.vue";
import WakeUp from "../components/WakeUp.vue";
import BardsSong from "../components/BardsSong.vue";
import PopupInfo from "../components/PopupInfo.vue";
import SessionExpired from "../components/SessionExpired.vue";
// import Loading from "../components/Loading.vue";
import LoginLoading from "../components/LoginLoading.vue";
import CraftingMenu from "../components/CraftingMenu.vue";
import DurabilityMenu from "../components/DurabilityMenu.vue";
// import MarketplaceOld from "../components/Marketplace.vue";
import Marketplace from "../components/MarketplaceComponents/Marketplace.vue";
// import MarketSellMenu from "../components/MarketSellMenu.vue";
// import ListMarketItem from "../components/MarketplaceComponents/ListMarketItem.vue";
import Notifications from "../components/Notifications.vue";
import QuestStart from "../components/QuestStart.vue";
import Quests from "../components/Quests.vue";
import QuestAccepted from "../components/QuestAccepted.vue";
import QuestCompletedBard from "../components/QuestCompleteBard.vue";
import GatheringStart from "../components/GatheringStart.vue";
import Gathering from "../components/Gathering.vue";
import Repair from "../components/CraftersGuild/Repair.vue";
import Craft from "../components/CraftersGuild/Craft/Craft.vue";
import Forge from "../components/Forge.vue";
import VolumeButton from "../components/VolumeButton.vue";
import ChatBox from "../components/Chat/ChatBox.vue";
import NotificationBox from "../components/Notifications/NotificationBox.vue";
import IntroVideo from "../components/Intro/IntroVideo.vue";
import Welcome from "../components/Intro/Welcome.vue";
import Tutorial from "../components/Intro/Tutorial.vue";
import FullScreen from "../components/FullScreen.vue";
import QuickAccess from "../components/Minimap/QuickAccess.vue";
import QuestWidget from "../components/Guild/QuestWidget.vue";
import CharacterSelect from "../components/CharacterComponents/CharacterSelect.vue";
import StashInventory from "../components/Stash/StashInventory.vue";
import MiniPopup from "../components/Popup/MiniPopup.vue";
import Rankings from "../components/Rankings/Rankings.vue";
import Crafting from "../components/Crafting.vue";
import DailyQuest from "../components/DailyQuest.vue";
import GromsBank from "../components/BankComponents/GromsBank.vue";
import OnRamp from "../components/Intro/OnRamp.vue";
import NestRoom from "../components/Nest/NestRoom.vue";
import Guild from "../components/Guild/Guild.vue";
import GuildWidget from "../components/Guild/GuildWidget.vue";
import ListRepairItem from "../components/CraftersGuild/ListRepairItem.vue";
import UpdateList from "../components/UpdateList.vue";
import { useStore } from "vuex";
import { inject, ref, onMounted, computed, watch } from "vue";
import {
  setDoc,
  doc,
  collection,
  getDocs,
  // onSnapshot,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { useMixpanel } from "../composables/mixpanel";

export default {
  name: "Game",
  components: {
    WorldMap,
    Hotbar,
    ActionButtonBar,
    CharacterPanel,
    CharacterEquipped,
    CharacterInventory,
    Searching,
    CombatStart,
    Combat,
    LevelUp,
    Loot,
    HealersHut,
    Inn,
    Sleeping,
    SessionExpired,
    Multichoice,
    PopupInfo,
    WakeUp,
    BardsSong,
    Repair,
    Craft,
    // Loading,
    LoginLoading,
    CraftingMenu,
    DurabilityMenu,
    // MarketplaceOld,
    Marketplace,
    // MarketSellMenu,
    // ListMarketItem,
    Notifications,
    QuestStart,
    Quests,
    QuestAccepted,
    QuestCompletedBard,
    GatheringStart,
    Gathering,
    Forge,
    VolumeButton,
    ChatBox,
    NotificationBox,
    CharacterSelect,
    MiniPopup,
    IntroVideo,
    Welcome,
    Tutorial,
    FullScreen,
    QuickAccess,
    QuestWidget,
    StashInventory,
    Rankings,
    Crafting,
    DailyQuest,
    GromsBank,
    OnRamp,
    NestRoom,
    Guild,
    GuildWidget,
    ListRepairItem,
    UpdateList,
  },
  data() {
    return {
      hasStarted: false,
      backGroundMusic: {},
      marketMusic: {},
      battleMusic: {},
      battleMusic2: {},
      currentBattle: {},
      deathMusic: {},
      innMusic: {},
      forgeMusic: {},
      deepwoodMusic: {},
      currentMusic: {},
      ambientSound: null,
      innAmbience: {},
      plainsAmbience: {},
      marketAmbience: {},
      $store: {},
      $firebase: {},
    };
  },
  setup() {
    // Config Variables
    const $firebase = inject("$firebase");
    const $store = useStore();

    const worldMapRef = ref(null);
    const gameWrapperRef = ref(null); // used for navigating map with arrow keys
    const ambientSound = ref(null);
    const currentMusic = ref(null);
    const hasStarted = ref(false);
    const backGroundMusic = ref(null);
    const battleMusic = ref(null);
    const battleMusic2 = ref(null);
    const deathMusic = ref(null);
    const innMusic = ref(null);
    const forgeMusic = ref(null);
    const marketMusic = ref(null);
    const bankMusic = ref(null);
    const craftersGuildMusic = ref(null);
    const innAmbience = ref(null);
    const minesMusic = ref(null);
    const deepwoodMusic = ref(null);
    const plainsAmbience = ref(null);
    const marketAmbience = ref(null);
    const defendersGuildMusic = ref(null);
    const { trackLocationClick } = useMixpanel();

    // const numerickeyHandler = ref({
    //   handleKey: (event) => {
    //     console.log(event);
    //   },
    // });

    const isEquipmentVisible = computed(() => {
      return $store.state.isEquipmentVisible;
    });
    const guildType = computed(() => {
      return $store.state.guild.guildType;
    });
    const isFirstTime = computed(() => {
      return $store.state.intro.isFirstTime;
    });
    const hasSeenUpdateList = computed(() => {
      return $store.state.intro.hasSeenUpdateList;
    });
    const marketplaceOpenState = computed(() => {
      return $store.state.gameState === Constants.gamemodes.marketplace;
    });
    const innOpenState = computed(() => {
      return $store.state.gameState === Constants.gamemodes.inn;
    });
    const healersHutOpenState = computed(() => {
      return $store.state.gameState === Constants.gamemodes.healers;
    });
    const craftingOpenState = computed(() => {
      return $store.state.gameState === Constants.gamemodes.crafting;
    });
    const forgeOpenState = computed(() => {
      return $store.state.gameState === Constants.gamemodes.forge;
    });
    const stashOpenState = computed(() => {
      return $store.state.stash.stashOpenStatus;
    });
    const inventoryOpenState = computed(() => {
      return $store.state.isInventoryVisible;
    });
    const bankOpenState = computed(() => {
      return $store.state.bank.isBankOpen;
    });
    const sessionExpiredState = computed(() => {
      return $store.state.sessionExpired;
    });
    const combatOpenState = computed(() => {
      return $store.state.gameState === Constants.gamemodes.combat;
    });
    const rankingsOpenState = computed(() => {
      return $store.state.rankings.rankingsOpenStatus;
    });
    const isCrafting = computed(() => {
      return $store.state.isCrafting;
    });
    const isDurability = computed(() => {
      return $store.state.isDurability;
    });
    const isRepairState = computed(() => {
      return $store.state.gameState === Constants.gamemodes.repair;
    });
    const isCraftState = computed(() => {
      return $store.state.gameState === Constants.gamemodes.crafters;
    });
    const isGuildOpen = computed(() => {
      return $store.state.guild.isGuildOpen;
    });
    const isVideoOpen = computed(() => {
      return $store.state.intro.isVideoOpen;
    });
    const isGuideOpen = computed(() => {
      return $store.state.intro.isGuideOpen;
    });
    const isNestState = computed(() => {
      return $store.state.gameState === Constants.gamemodes.nest;
    });
    const isLeveledUp = computed(() => {
      return $store.state.hasPlayerLeveledUp;
    });
    const character = computed(() => {
      return $store.state.characters[$store.state.currentCharacter];
    });
    const isRepairListShowing = computed(() => {
      return $store.state.isRepairListing;
    });

    const hasCharacter = computed(() => {
      const currentChar =
        $store.state.characters[$store.state.currentCharacter];
      if (!currentChar) {
        return false;
      } else {
        return true;
      }
    });

    const accountConnected = computed(() => {
      const address = $store.state.account;
      if (!address) {
        return false;
      } else {
        return true;
      }
    });

    watch(character, () => {
      saveDetails();
    });

    const inventory = computed(() => {
      return $store.state.inventory;
    });

    watch(inventory, () => {
      saveDetails();
    });

    // const doRightKeyPress = () => {
    //   if (!$store.state.chat.isChatOpen) {
    //     gameWrapperRef.value.focus();
    //     worldMapRef.value.move(true, -100);
    //   }
    // };

    // const doLeftKeyPress = () => {
    //   if (!$store.state.chat.isChatOpen) {
    //     gameWrapperRef.value.focus();
    //     worldMapRef.value.move(true, 100);
    //   }
    // };

    // const doUpKeyPress = () => {
    //   if (!$store.state.chat.isChatOpen) {
    //     gameWrapperRef.value.focus();
    //     worldMapRef.value.move(false, 100);
    //   }
    // };

    // const doDownKeyPress = () => {
    //   if (!$store.state.chat.isChatOpen) {
    //     gameWrapperRef.value.focus();
    //     worldMapRef.value.move(false, -100);
    //   }
    // };

    // const numericPress = (event) => {
    //   if (!$store.state.chat.isChatOpen) {
    //     gameWrapperRef.value.focus();
    //     numerickeyHandler.value.handleKey(event);
    //   }
    // };

    const craftingMenu = () => {
      $store.commit("setCrafting");
    };

    const repairListMenu = () => {
      $store.commit("setRepairListing");
    };

    const craftListMenu = () => {
      $store.commit("setCraftListing");
    };

    const marketSellMenu = () => {
      $store.commit("setMarketSelling");
    };
    const openLootbox = () => {
      $store.commit("setOpenLootbox");
    };

    const useGatheringTool = (number) => {
      const hotbarSlot = $store.state.hotbar()[number];
      $store.state.weaponName = null;
      if (hotbarSlot && hotbarSlot.type === Constants.slots.hand1) {
        $store.state.weaponName = $store.state.hotbar()[number].name;
        $store.state.itemStaticId = $store.state.hotbar()[number].id;
      }
      $store.commit("doGatherResource", $store.state);
    };
    const gatherSearch = () => {};
    const forgeSmelt = () => {
      $store.commit("craftForgeItems");
    };
    const forgeReturn = () => {
      $store.commit("removeForgeItems");
    };

    const buyMarketItem = () => {
      console.log("this shouldn't be called");
      const marketItem = $store.state.marketData.find(
        (i) => i.id == $store.state.marketBuyItemId
      );

      $store.commit("setBuyMarketItem", marketItem);

      deleteDoc(doc($firebase.db, "market", marketItem.id)).then();

      const walletQuery = query(
        collection($firebase.db, "wallets"),
        where("characterNumber", "==", marketItem.characterNumber)
      );

      getDocs(walletQuery).then((wallets) => {
        wallets.forEach((wallet) => {
          const currentDcau = wallet.data().dcau;
          setDoc(
            wallet.ref,
            { dcau: currentDcau + marketItem.sellPrice },
            { merge: true }
          );
        });
      });
    };

    const saveDetails = () => {
      if ($store.state.startSaving) {
        setDoc(doc($firebase.db, "playerData", $store.state.account), {
          inventory: $store.state.inventory,
          itemStats: $store.state.itemStats,
          characters: $store.state.characters,
          idTracker: $store.state.idTracker,
          forgeItems: $store.state.forgeItems,
          lastRefreshSeconds: $store.state.lastRefreshSeconds,
        }).then();
      }
    };

    /**
     * @TODO - for future
     */
    // const saveInventory = () => {};

    // const innSong = () => {
    //   $store.commit("setBardsSong", $store.state.account);
    // };

    const questStepBard = () => {
      $store.commit("setQuestStepBardTalkTo");
    };

    const acceptDailyQuest = () => {
      $store.commit("setAcceptDailyQuest");
    };

    const declineDailyQuest = () => {
      $store.commit("setDeclineDailyQuest");
    };

    const craftBreak = () => {
      $store.commit("setBreakdown", $store.state.account);
    };

    const craftRepair = () => {
      $store.commit("setRepair");
    };

    const takeQuest = () => {
      $store.commit("setAcceptQuest");
    };

    const useItem = () => {
      $store.commit("setConsumeItem", {
        staticId: $store.state.hoveredItem.staticId,
        quantity: 1,
      });
    };

    const equipInvItem = () => {
      $store.commit("equipInvItem");
    };
    const equipInvItemHand2 = () => {
      $store.commit("equipInvItemRight", "hand2");
    };

    const unequipInvItem = () => {
      $store.commit("unequipItem");
    };

    const multichoice = () => {
      $store.commit("setMultichoice", $store.state.account);
    };

    const marketplace = () => {
      $store.commit("setMarketplace");
      $store.commit("marketplace/setShowInventoryState", false); // not showing inventory by default for mobile

      trackLocationClick("Visiting Marketplace");
    };

    const bank = () => {
      $store.commit("setBank");
      $store.commit("bank/setIsBankOpen", true);
      trackLocationClick("Visiting Bank");
    };

    // const rest = () => {
    //   $store.commit("setSleeping", $store.state.account);
    // };

    const increaseStat = (stat) => {
      $store.commit("setIncreaseStat", stat);
    };

    const toggleEquipment = () => {
      $store.commit("setEquipmentVisible", !$store.state.isEquipmentVisible);
      playCharacterOpenSound();
    };

    const toggleInventory = () => {
      $store.commit("setInventoryVisible", !$store.state.isInventoryVisible);
      playInventorySound();
    };

    const toggleQuests = () => {
      $store.commit("setQuestsVisible", !$store.state.isQuestsVisible);
      playCharacterOpenSound();
    };

    const toggleRankings = () => {
      $store.commit(
        "rankings/setRankingsOpenStatusState",
        !$store.state.rankings.rankingsOpenStatus
      );
    };

    const playHitSound = () => {
      const hitSoundFileName =
        "hit" + (1 + Math.floor(XorShift.random() * 3)) + ".mp3";
      const hitSound = new Audio(
        "https://cdn.dragoncrypto.io/sound/" + hitSoundFileName
      );
      hitSound.volume = $store.state.soundVolume;
      hitSound.play();
    };

    const playDeathSound = () => {
      const deathSoundFileName =
        "mobdeath" + (1 + Math.floor(XorShift.random() * 5)) + ".wav";
      const deathSound = new Audio(
        "https://cdn.dragoncrypto.io/sound/" + deathSoundFileName
      );
      deathSound.volume = $store.state.soundVolume;
      deathSound.play();
    };

    const playHealingSound = () => {
      const healingSound = new Audio(
        "https://cdn.dragoncrypto.io/sound/healing.wav"
      );
      healingSound.volume = $store.state.soundVolume;
      healingSound.play();
    };

    const playCharacterOpenSound = () => {
      const characterSound = new Audio(
        "https://cdn.dragoncrypto.io/sound/character_open.wav"
      );
      characterSound.volume = $store.state.soundVolume;
      characterSound.play();
    };

    const playInventorySound = () => {
      const inventorySound = new Audio(
        "https://cdn.dragoncrypto.io/sound/inventory_open.wav"
      );
      inventorySound.volume = $store.state.soundVolume;
      inventorySound.play();
    };

    const playLevelUpSound = () => {
      const levelSound = new Audio(
        "https://cdn.dragoncrypto.io/sound/levelup.wav"
      );
      levelSound.volume = $store.state.soundVolume;
      levelSound.play();
    };

    const playCurrentSound = () => {
      const sound = new Audio(
        "https://cdn.dragoncrypto.io/sound/" + $store.state.soundToPlay
      );
      sound.volume = $store.state.soundVolume;
      sound.play();

      setTimeout(() => {
        $store.commit("clearSound");
      });
    };

    const leavePlains = () => {
      $store.commit("setWandering");
    };
    const leaveEncounter = () => {
      $store.dispatch("leaveEncounter", $store.state.account);
    };
    const searchMinesAgain = () => {
      $store
        .dispatch("leaveResourceEncounter", $store.state.account)
        .then(() => {
          $store.commit("setSearchingMines", $store.state.account);
        });
    };
    const searchDeepwoodAgain = () => {
      $store
        .dispatch("leaveResourceEncounter", $store.state.account)
        .then(() => {
          $store.commit("setSearchingDeepwood", $store.state.account);
        });
    };

    const leaveMultichoice = () => {
      $store.commit("setLeaveMultichoice");
    };

    const leaveMultichoiceSearch = () => {
      $store.commit("setLeaveMultichoiceSearch");
    };

    const leavePlainsDead = () => {
      $store.commit("setLeaveCombatDead");
    };

    const healersDead = () => {
      $store.commit("setLeaveCombatDeadHealers");
    };

    const inn = () => {
      $store.commit("setInn");

      // Mixpanel tracking user data and when visiting the inn
      trackLocationClick("Visiting Wild Boar Inn");
    };

    const leave = () => {
      $store.commit("setWandering");
    };

    const healersHut = () => {
      $store.commit("setHealers");

      // Mixpanel tracking user data and when healer's hut is clicked
      trackLocationClick("Visit Healer's Hut");
    };

    const loot = () => {
      playInventorySound();
      $store.commit("setLooting");
    };

    // const heal = () => {
    //   $store.commit("setHealing", $store.state.account);
    // };

    // const resurrect = () => {
    //   $store.commit("setResurrect");
    // };

    const useSlotCombat = (number) => {
      console.log("useSlotCombat", number);
      const hotbarSlot = $store.state.hotbar()[number];

      if (hotbarSlot.type === Constants.slots.hand1) {
        $store.state.doCombatDamage(number);
        return;
      }

      if (hotbarSlot.type === Constants.useable.consumable) {
        $store.commit("setConsumeItemCombat", hotbarSlot.staticId);
        return;
      }
    };

    const useFistsCombat = () => {
      $store.state.doCombatDamage(-1);
    };

    const fadeOut = (currentAudio, newAudio) => {
      const tickTime = 10;

      setTimeout(() => {
        if (currentAudio.volume >= 0.1) {
          currentAudio.volume -= 0.1;
          fadeOut(currentAudio, newAudio);
        } else {
          //TODO: does not work for now, just comment out, fix later
          //currentAudio.pause();
          currentAudio.currentTime = 0;

          if (newAudio) {
            newAudio.currentTime = 0;
            newAudio.volume = $store.state.soundVolume;
            newAudio.play();
          }
        }
      }, tickTime);
    };

    const fadeAmbient = (newAmbient) => {
      const tickTime = 10;
      setTimeout(() => {
        if (ambientSound.value) {
          if (ambientSound.value.volume >= 0.1) {
            ambientSound.value.volume -= 0.1;
            fadeAmbient(newAmbient);
          } else {
            if (ambientSound.value) ambientSound.value.pause();
            ambientSound.value.currentTime = 0;

            if (newAmbient) {
              ambientSound.value = newAmbient;
              ambientSound.value.currentTime = 0;
              ambientSound.value.volume = $store.state.soundVolume;
              //TODO: does not work for now, just comment out, fix later
              //ambientSound.value.play();
            }
          }
        } else {
          if (newAmbient) {
            ambientSound.value = newAmbient.value;
            ambientSound.value.currentTime = 0;
            ambientSound.value.volume = $store.state.soundVolume;
            ambientSound.value.play();
          }
        }
      }, tickTime);
    };

    const fadeMusic = (newAudio) => {
      const tickTime = 10;

      if (currentMusic.value) {
        setTimeout(() => {
          if (currentMusic.value.volume >= 0.1) {
            currentMusic.value.volume -= 0.1;
            fadeMusic(newAudio);
          } else {
            currentMusic.value.pause();
            currentMusic.value.currentTime = 0;

            if (newAudio) {
              currentMusic.value = newAudio.value;
              currentMusic.value.currentTime = 0;
              currentMusic.value.volume = $store.state.soundVolume;
              currentMusic.value.play();
            }
          }
        }, tickTime);
      }
    };

    const start = () => {
      if (!hasStarted.value) {
        if ($store.state.isMusicOn) {
          currentMusic.value = backGroundMusic.value;
          currentMusic.value.volume = $store.state.soundVolume;
          currentMusic.value.play();
        }
        hasStarted.value = true;
      }
    };

    const searchPlains = async () => {
      $store.commit("setSearching", $store.state.account);

      // Mixpanel tracking user data and when search the plains is clicked
      trackLocationClick("Search The Plains");
    };

    const searchMines = () => {
      $store.commit("setSearchingMines", $store.state.account);

      // Mixpanel tracking user data and when search the mines is clicked
      trackLocationClick("Search The Mines");
    };

    const searchDeepwood = () => {
      $store.commit("setSearchingDeepwood", $store.state.account);

      // Mixpanel tracking user data and when search the mines is clicked
      trackLocationClick("Search Deepwood");
    };

    const forge = () => {
      $store.commit("setForge");

      // Mixpanel tracking user data and when forge is clicked
      trackLocationClick("Open Forge");
    };

    const stash = () => {
      console.log("stash clicked");
      $store.dispatch("stash/openStashWindow");

      // $store.commit("setStashWindow");

      // $store.commit("stash/setStashOpenStatusState", true);

      // Mixpanel tracking user data and when stash is clicked
      trackLocationClick("Open Stash");
    };

    const craftersGuild = () => {
      $store.commit("setOpenCraftersGuild", $store.state);
      trackLocationClick("Open Crafting");
    };

    const defendersGuild = () => {
      $store.commit("guild/setIsGuildOpen", true); // Hiding in mobile view as well
      $store.commit("guild/setGuildType", "defenders"); // Hiding in mobile view as well
      trackLocationClick("Open Defender Guild");
    };

    const fight = () => {
      $store.commit("setFightEncounter");
    };

    const startGathering = (number) => {
      $store.commit("setGathering", $store.state.account);

      const hotbarSlot = $store.state.hotbar()[number];
      $store.state.weaponName = null;
      if (hotbarSlot && hotbarSlot.type === Constants.slots.hand1) {
        $store.state.weaponName = $store.state.hotbar()[number].name;
        $store.state.itemStaticId = $store.state.hotbar()[number].id;
      }
      $store.commit("doGatherResource", $store.state);
    };

    const flee = () => {
      $store.commit("setFightFlee");
    };

    const fleeBattle = () => {
      $store.commit("setFightFlee");
    };

    /**
     * Method to reset localstorage and saved session
     */
    const checkStorageVer = () => {
      // Checking and comparing stored localstorage varaivle with storageVer in constants.
      // If different, clear localstorage.
      const storageVer = localStorage.getItem("storageVer");
      if (storageVer !== Constants.storageVer.toString()) {
        console.log("Clearing Localstorage");
        localStorage.clear();
        localStorage.setItem("storageVer", Constants.storageVer);
      }
      const updateVer = localStorage.getItem("updateVer");
      if (updateVer !== Constants.updateVer.toString()) {
        console.log("Clearing Update Visited LocalStorage");
        localStorage.removeItem("seenUpdateList");
        localStorage.setItem("updateVer", Constants.updateVer);
      }
    };
    /**
     * @description - Doing stuff on being mounted
     */
    onMounted(() => {
      // const store = $store;

      checkStorageVer();

      // numerickeyHandler.value.handleKey = function (event) {
      //   const keyValue = event.event.key * 1 - 1;
      //   store.commit("setUseHotbarItem", keyValue);
      // };

      backGroundMusic.value = new Audio(
        "https://cdn.dragoncrypto.io/music/wandering.mp3"
      );
      backGroundMusic.value.loop = true;

      battleMusic.value = new Audio(
        "https://cdn.dragoncrypto.io/music/battle1.mp3"
      );
      battleMusic.value.loop = true;

      battleMusic2.value = new Audio(
        "https://cdn.dragoncrypto.io/music/battle2.mp3"
      );
      battleMusic2.value.loop = true;

      deathMusic.value = new Audio(
        "https://cdn.dragoncrypto.io/music/death.mp3"
      );
      deathMusic.value.loop = true;

      innMusic.value = new Audio(
        "https://cdn.dragoncrypto.io/music/tavern_mastered.mp3"
      );
      innMusic.value.loop = true;

      forgeMusic.value = new Audio(
        "https://cdn.dragoncrypto.io/music/forge.mp3"
      );
      forgeMusic.value.loop = true;

      marketMusic.value = new Audio(
        "https://cdn.dragoncrypto.io/music/market_mastered.mp3"
      );
      marketMusic.value.loop = true;

      bankMusic.value = new Audio(require("../assets/music/groms-bank.mp3"));
      bankMusic.value.loop = true;

      craftersGuildMusic.value = new Audio(
        require("../assets/music/crafters-guild.mp3")
      );
      craftersGuildMusic.value.loop = true;

      defendersGuildMusic.value = new Audio(
        require("../assets/music/defender-guild.mp3")
      );
      defendersGuildMusic.value.loop = true;

      innAmbience.value = new Audio(
        "https://cdn.dragoncrypto.io/sound/inn-ambient.mp3"
      );
      innAmbience.value.loop = true;

      minesMusic.value = new Audio(
        "https://cdn.dragoncrypto.io/music/mines.mp3"
      );
      minesMusic.value.loop = true;

      //TODO : Need to replace with specific deepwood music in future
      deepwoodMusic.value = new Audio(
        "https://cdn.dragoncrypto.io/music/deepwood.mp3"
      );
      deepwoodMusic.value.loop = true;

      plainsAmbience.value = new Audio(
        require("../assets/sound/plains-ambience.mp3")
      );
      plainsAmbience.value.loop = true;

      marketAmbience.value = new Audio(
        "https://cdn.dragoncrypto.io/sound/market-ambience.mp3"
      );
      marketAmbience.value.loop = true;

      setInterval(() => {
        $store.commit("setCheckRefresh");
        console.log("refreshing");
      }, 300000);

      // $store.watch(
      //   (state) => state.currentEncounter.isTakingDamage,
      //   (value) => {
      //     if (value) {
      //       playHitSound();
      //     }
      //   }
      // );

      // let hasDoneAccessCode = false;

      // $store.watch(
      //   (state) => state.account,
      //   (value) => {
      //     // if (value && !hasDoneAccessCode) {
      //     //   onSnapshot(
      //     //     doc($firebase.db, "wallets", $store.state.account),
      //     //     (querySnapshot) => {
      //     //       $store.commit("setDcauInWallet", querySnapshot.data().dcau);
      //     //     }
      //     //   );
      //     //   hasDoneAccessCode = true;
      //     // }
      //   }
      // );

      $store.watch(
        (state) => state.sessionExpired,
        (value) => {
          if (value) {
            console.log("Session Expired");
            // router.push("/login");
          }
        }
      );

      $store.watch(
        (state) => state.hasPendingDcauReward,
        (value) => {
          if (value) {
            const newDcauTotal =
              $store.state.dcauInWallet + $store.state.pendingDcauReward;

            setDoc(
              doc($firebase.db, "wallets", $store.state.account),
              { dcau: newDcauTotal },
              { merge: true }
            );

            $store.commit("setPendingRewardClaimed");
          }
        }
      );

      $store.watch(
        (state) => state.hasPendingDcauSpend,
        (value) => {
          if (value) {
            const newDcauTotal =
              $store.state.dcauInWallet - $store.state.pendingDcauSpend;

            setDoc(
              doc($firebase.db, "wallets", $store.state.account),
              { dcau: newDcauTotal },
              { merge: true }
            );

            $store.commit("setPendingSpendProcessed");
          }
        }
      );

      // $store.watch(
      //   (state) => state.currentEncounter.isDead,
      //   (value) => {
      //     if (value) {
      //       playDeathSound();
      //     }
      //   }
      // );

      // $store.watch(
      //   (state) =>
      //     state.characters[state.currentCharacter] != null
      //       ? state.characters[state.currentCharacter].isTakingDamage
      //       : null,
      //   (value) => {
      //     if (value) {
      //       playHitSound();
      //     }
      //   }
      // );

      $store.watch(
        (state) =>
          state.characters[state.currentCharacter] != null
            ? state.characters[state.currentCharacter].stats.isDead
            : null,
        (value) => {
          if (value) {
            fadeMusic(deathMusic);
          } else {
            fadeMusic(backGroundMusic);
          }
        }
      );

      $store.watch(
        (state) => state.hasPlayerLeveledUp,
        (value) => {
          if (value) {
            fadeMusic(backGroundMusic);
          }
        }
      );

      $store.watch(
        (state) =>
          state.characters[state.currentCharacter] != null
            ? state.characters[state.currentCharacter].isHealing
            : null,
        (value) => {
          if (value) {
            playHealingSound();
          }
        }
      );

      $store.watch(
        (state) => state.lastRefreshSeconds,
        (value) => {
          if (value) {
            saveDetails();
          }
        }
      );

      $store.watch(
        (state) =>
          state.characters[state.currentCharacter] &&
          state.characters[state.currentCharacter].stats != null
            ? state.characters[state.currentCharacter].stats.level
            : null,
        (value) => {
          if (value) {
            playLevelUpSound();
          }
        }
      );

      $store.watch(
        (state) => state.playSound,
        (value) => {
          if (value) {
            playCurrentSound();
          }
        }
      );

      $store.watch(
        (state) => state.isMusicOn,
        (value) => {
          if (!value) {
            currentMusic.value.stop();
          }
        }
      );

      /*
        Watching sound volume change via slider in VolumeButton
        and setting the new volume
       */
      $store.watch(
        (state) => state.soundVolume,
        (value) => {
          if (currentMusic.value !== null) currentMusic.value.volume = value;
          plainsAmbience.value.volume = value;
          backGroundMusic.value.volume = value;
        }
      );

      $store.watch(
        (state) => state.gameState,
        (value) => {
          if (value) {
            if (value == Constants.gamemodes.searching) {
              const searchingSound = new Audio(
                "https://cdn.dragoncrypto.io/sound/searching.wav"
              );
              searchingSound.volume = $store.state.soundVolume;
              searchingSound.play();

              setTimeout(() => {
                fadeOut(searchingSound, null);
              }, 2000);
            }

            if ($store.state.isMusicOn) {
              switch (value) {
                case Constants.gamemodes.healers:
                  if (
                    !$store.state.characters[$store.state.currentCharacter]
                      .stats.isDead &&
                    $store.state.prevState != Constants.gamemodes.wandering
                  )
                    fadeMusic(backGroundMusic);
                  break;
                case Constants.gamemodes.wandering:
                  if (
                    $store.state.prevState != Constants.gamemodes.healers &&
                    $store.state.prevState != Constants.gamemodes.stash
                  ) {
                    fadeAmbient(plainsAmbience);
                    fadeMusic(backGroundMusic);
                  }
                  break;
                case Constants.gamemodes.marketplace: {
                  fadeAmbient(marketAmbience);
                  fadeMusic(marketMusic);
                  break;
                }
                case Constants.gamemodes.bank: {
                  fadeAmbient(bankMusic);
                  fadeMusic(bankMusic);
                  break;
                }
                case Constants.gamemodes.crafting: {
                  fadeAmbient(craftersGuildMusic);
                  fadeMusic(craftersGuildMusic);
                  break;
                }
                case Constants.gamemodes.defenderguild: {
                  fadeAmbient(defendersGuildMusic);
                  fadeMusic(defendersGuildMusic);
                  break;
                }
                case Constants.gamemodes.combat: {
                  fadeOut(ambientSound);
                  const battleMusicRandom = Math.floor(Math.random() * 2) - 1;

                  if (battleMusicRandom == 0) {
                    fadeMusic(battleMusic);
                  } else {
                    fadeMusic(battleMusic2);
                  }
                  break;
                }
                case Constants.gamemodes.stash:
                  if ($store.state.prevState == Constants.gamemodes.looting) {
                    fadeMusic(backGroundMusic);
                  }
                  break;
                case Constants.gamemodes.searchingMines:
                  {
                    const prevState = $store.state.prevState;
                    if (
                      prevState != Constants.gamemodes.gatheringStart &&
                      prevState != Constants.gamemodes.gathering
                    ) {
                      fadeOut(ambientSound);
                      fadeMusic(minesMusic);
                    }
                  }
                  break;
                case Constants.gamemodes.searchingDeepwood:
                  {
                    const prevState = $store.state.prevState;
                    if (
                      prevState != Constants.gamemodes.gatheringStart &&
                      prevState != Constants.gamemodes.gathering
                    ) {
                      fadeOut(ambientSound);
                      fadeMusic(deepwoodMusic);
                    }
                  }
                  break;
                case Constants.gamemodes.inn:
                  fadeAmbient(innAmbience);
                  fadeMusic(innMusic);
                  break;
                case Constants.gamemodes.forge:
                  fadeOut(ambientSound);
                  fadeMusic(forgeMusic);
                  break;
                case Constants.gamemodes.sleeping:
                  fadeOut(ambientSound);
                  fadeOut(currentMusic);
                  break;
                default:
                  break;
              }
            }
          }
        }
      );
    });

    return {
      character,
      hasCharacter,
      accountConnected,
      inventory,
      // doRightKeyPress,
      // doLeftKeyPress,
      // doUpKeyPress,
      // doDownKeyPress,
      worldMapRef,
      gameWrapperRef,
      craftingMenu,
      marketSellMenu,
      repairListMenu,
      craftListMenu,
      openLootbox,
      useGatheringTool,
      gatherSearch,
      forgeSmelt,
      forgeReturn,
      buyMarketItem,
      saveDetails,
      // innSong,
      questStepBard,
      craftBreak,
      craftRepair,
      takeQuest,
      equipInvItem,
      equipInvItemHand2,
      marketplace,
      bank,
      toggleEquipment,
      toggleInventory,
      toggleQuests,
      toggleRankings,
      fight,
      playHitSound,
      playDeathSound,
      playHealingSound,
      playCharacterOpenSound,
      playInventorySound,
      playLevelUpSound,
      playCurrentSound,
      leaveMultichoice,
      leaveMultichoiceSearch,
      leavePlains,
      leaveEncounter,
      searchMinesAgain,
      searchDeepwoodAgain,
      leavePlainsDead,
      healersDead,
      inn,
      leave,
      healersHut,
      stash,
      craftersGuild,
      defendersGuild,
      loot,
      useFistsCombat,
      start,
      searchPlains,
      searchMines,
      searchDeepwood,
      forge,
      startGathering,
      flee,
      fleeBattle,
      useItem,
      unequipInvItem,
      multichoice,
      // rest,
      increaseStat,
      useSlotCombat,
      isFirstTime,
      hasSeenUpdateList,
      isVideoOpen,
      isCrafting,
      isDurability,
      isRepairState,
      isCraftState,
      isGuildOpen,
      guildType,
      isEquipmentVisible,
      isGuideOpen,
      isNestState,
      marketplaceOpenState,
      healersHutOpenState,
      sessionExpiredState,
      craftingOpenState,
      innOpenState,
      forgeOpenState,
      stashOpenState,
      inventoryOpenState,
      bankOpenState,
      combatOpenState,
      rankingsOpenState,
      acceptDailyQuest,
      declineDailyQuest,
      isLeveledUp,
      isRepairListShowing,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";
.game-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: scroll;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &:active {
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_grabby_cursor_gray.png"),
      auto;
  }
}
</style>
