// initial state
import Constants from "../../consts/constants";

const state = () => ({
  isRepairOpen: false,
  listedItems: {},
  marketData: [],
  myListedItemsData: [],
  showInventory: false,
  page: 1,
  sortBy: "createdAt",
  order: "desc",
  searchTerm: "",
  dcarApproved: false,
  resourcesApproved: false,
});

const actions = {
  processSorting({ commit }, value) {
    switch (value) {
      case "Recent":
        commit("setSortBy", "createdAt");
        commit("setOrder", "desc");
        break;
      case "Oldest":
        commit("setSortBy", "createdAt");
        commit("setOrder", "asc");
        break;
      case "Reward Desc":
        commit("setSortBy", "rewardPrice");
        commit("setOrder", "desc");
        break;
      case "Reward Asc":
        commit("setSortBy", "rewardPrice");
        commit("setOrder", "asc");
        break;
      case "Rarity Desc":
        commit("setSortBy", "rarity");
        commit("setOrder", "desc");
        break;
      case "Rarity Asc":
        commit("setSortBy", "rarity");
        commit("setOrder", "asc");
        break;
      default:
        break;
    }
  },
  loadMoreMarketItems({ commit, state }, items) {
    const currentItems = state.marketData;
    currentItems.push(...items);
    commit("setMarketData", currentItems);
  },
  loadMoreMyListedItems({ commit, state }, items) {
    const currentItems = state.myListedItemsData;
    currentItems.push(...items);
    commit("setMyListedItemsData", currentItems);
  },
  async updateMyListedItems({ commit, state, rootState }) {
    const repairApiQuery = `my-repair-guild-contracts?page=1&sortBy=${state.sortBy}&order=${state.order}`;
    const response = await fetch(Constants.apiUrl + repairApiQuery, {
      method: "POST",
      body: JSON.stringify({
        account: rootState.account,
        sessionId: localStorage.getItem("sessionId"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    if (data.success) {
      commit("setMyListedItemsData", data.repairItems);
    }
  },
  async updateMyCanRepairItems({ commit, state, rootState }) {
    const repairApiQuery = `repair-guild-contracts?page=1&sortBy=${state.sortBy}&order=${state.order}`;
    const response = await fetch(Constants.apiUrl + repairApiQuery, {
      method: "POST",
      body: JSON.stringify({
        account: rootState.account,
        sessionId: localStorage.getItem("sessionId"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    if (data.success) {
      const myCrafting =
        rootState.characters[rootState.currentCharacter].stats.crafting;
      const characterCraftingBonus = rootState.equipmentStats().crafting;
      const totalCrafting = myCrafting + characterCraftingBonus;
      const items = data.repairItems.filter((item) => {
        return (
          item.data.stats.craftingRequired <= totalCrafting &&
          rootState.hasRequiredResources(item.data)
        );
      });
      commit("setMarketData", items);
    }
  },
  async updateRepairItems({ commit, state, rootState }) {
    const repairApiQuery = `repair-guild-contracts?search=${state.searchTerm}&page=1&sortBy=${state.sortBy}&order=${state.order}`;
    const response = await fetch(Constants.apiUrl + repairApiQuery, {
      method: "POST",
      body: JSON.stringify({
        account: rootState.account,
        sessionId: localStorage.getItem("sessionId"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (data.success) {
      commit("setMarketData", data.repairItems);
    }
  },
};

// mutations
const mutations = {
  setDcarApproved(state, value) {
    state.dcarApproved = value;
  },
  setResourcesApproved(state, value) {
    state.resourcesApproved = value;
  },
  setPage(state, page) {
    state.page = page;
  },
  setSortBy(state, sortBy) {
    state.sortBy = sortBy;
  },
  setOrder(state, order) {
    state.order = order;
  },
  setRepairOpenState(state, status) {
    state.isRepairOpen = status;
  },
  setListedItemsState(state, payload) {
    if (payload && payload.id) {
      state.listedItems[payload.id] = payload;
    }
  },
  setMarketData(state, payload) {
    state.marketData = payload;
  },
  setMyListedItemsData(state, payload) {
    state.myListedItemsData = payload;
  },
  setShowInventoryState(state, status) {
    state.showInventory = status;
  },
  setSearchTerm(state, term) {
    state.searchTerm = term;
  },
  /**
   * Deleting Item from Listed Item State
   * @param {*} state
   * @param {*} id
   */
  deleteBoughtItem(state, id) {
    delete state.listedItems[id];
  },
};

export default {
  namespaced: true,
  state,
  //   getters,
  actions,
  mutations,
};
