import { inject } from "vue";
import { useStore } from "vuex";

export function useMixpanel() {
  const mixpanel = inject("mixpanel");
  const store = useStore();

  if (!mixpanel) {
    throw new Error("Mixpanel is not available");
  }

  const trackLocationClick = (eventName, data) => {
    const properties = typeof data !== "undefined" ? data : {};
    properties["hero"] =
      store.state.characters[store.state.currentCharacter].number;
    properties["hp"] =
      store.state.characters[store.state.currentCharacter].stats.hp;
    properties["turns"] =
      store.state.characters[store.state.currentCharacter].stats.turns;
    mixpanel.track(eventName, properties);
  };

  const trackEvent = (eventName, data) => {
    try {
      mixpanel.track(eventName, data);
    } catch (e) {
      console.log("Mixpanel Error", e);
    }
  };

  const trackIdentity = (userAddress, data) => {
    try {
      // Tracking basic Mixpanel Identity
      mixpanel.identify(userAddress);
      mixpanel.people.set(data);
      mixpanel.people.increment("signed in");
    } catch (e) {
      console.log("Mixpanel Error", e);
    }
  };

  return { trackEvent, trackLocationClick, trackIdentity };
}
