import AVVY from "@avvy/client";
import { ethers } from "ethers";
import Constants from "../consts/constants";

export function useAvvy() {
  const resolveAddressToName = async (address) => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(Constants.rpcUrl);
      const avvy = new AVVY(provider);
      const hash = await avvy.reverse(AVVY.RECORDS.EVM, address);

      if (hash == null) {
        return (
          address.substr(0, 5) +
          "..." +
          address.substr(address.length - 5, address.length)
        );
      }

      const lookup = await hash.lookup();
      return lookup.name;
    } catch (error) {
      return (
        address.substr(0, 5) +
        "..." +
        address.substr(address.length - 5, address.length)
      );
    }
  };

  return { resolveAddressToName };
}
