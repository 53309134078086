<template>
  <div :class="currentClass">
    <BasicPopup header="Grom's Bank" class="bank-popup">
      <div class="close-button button-small" @click="hide">
        <span>x</span>
      </div>
      <div class="inner-wrapper">
        <div class="passbook">
          <div class="message-box" id="groms-bank">
            <div :class="['goblin', mood]"></div>
            <!-- <div class="grid">
              <div>
                <h4>Welcome to Grom's Bank, slayer</h4>
                <p v-if="isRewardActive">
                  Bond sale round <strong>{{ currentRound }}</strong> is
                  currently on. You are allowed to buy
                  <strong
                    >{{ quantityPerWallet }} bonds per wallet. The discount you
                    will receive is
                    <strong>{{ discountPercentage }}</strong></strong
                  >%.
                </p>
                <p v-else>
                  Bond sale round is currently closed. Please check back later.
                </p>
              </div>
            </div> -->
          </div>
          <div class="balances-container">
            <h4>Your Token Balances</h4>
            <div class="grid balances">
              <div class="token-container">
                <div class="token-balance">
                  <div class="dcau token" id="dcau-balance">
                    <p v-if="!isFetchingDcauBalance">
                      {{ formatNumber(dcauBalance) }}
                      <span>(${{ formatNumber(dcauDollarAmount) }})</span>
                    </p>
                    <p v-else>
                      <SkeletalLoading width="100" height="20" />
                    </p>
                  </div>
                </div>
                <div class="links">
                  <p>
                    <a href="#" target="_blank">Buy</a> |
                    <a href="#" target="_blank">Swap</a> |
                    <button @click="addDcauToMetamask">
                      Add To <span class="metamask">Metamask</span>
                    </button>
                  </p>
                </div>
              </div>
              <div class="token-container">
                <div class="token-balance">
                  <div class="dcar token" id="dcar-balance">
                    <p v-if="!isFetchingDcarBalance">
                      {{ formatNumber(dcarBalance) }}
                      <span>(${{ formatNumber(dcarDollarAmount) }})</span>
                    </p>
                    <p v-else>
                      <SkeletalLoading width="100" height="20" />
                    </p>
                  </div>
                </div>
                <div class="links">
                  <p>
                    <a href="#" target="_blank">Buy</a> |
                    <a href="#" target="_blank">Swap</a> |
                    <button @click="addDcarToMetamask">
                      Add To <span class="metamask">Metamask</span>
                    </button>
                  </p>
                </div>
              </div>
              <div class="token-container">
                <div class="token-balance">
                  <div class="copper token" id="copper-balance">
                    <p v-if="!isFetchingCopperBalance">
                      {{ formatNumber(copperBalance) }} <span>(Copper)</span>
                    </p>
                    <p v-else>
                      <SkeletalLoading width="100" height="20" />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <template v-if="!isMainnet && false">
              <h4>Faucets</h4>
              <div class="grid buttons">
                <DialogButton
                  buttonSize="small"
                  :disabled="!canClaimDCAUFaucet || isFaucetLoading"
                  :buttonText="'DCAU Faucet'"
                  :isLoading="isFaucetLoading"
                  @click="claimDCAUFaucet"
                />
                <DialogButton
                  buttonSize="small"
                  :disabled="!canClaimDCARFaucet || isFaucetLoading"
                  :buttonText="'DCAR Faucet'"
                  :isLoading="isFaucetLoading"
                  @click="claimDCARFaucet"
                />
              </div>
            </template>
          </div>
        </div>
        <div class="inner-grid">
          <div class="teller">
            <div v-if="showBondSale" class="bond-details">
              <div class="grid">
                <div>
                  <h4 id="sale-status">
                    Bond Sale
                    <span
                      :class="['status', isRewardActive ? 'live' : 'closed']"
                    >
                      <span class="pulsating-circle"></span>
                      {{ isRewardActive ? "Live" : "Closed" }}
                    </span>
                  </h4>
                  <div class="details" id="bond-details">
                    <div class="row">
                      <span>Current Round</span>
                      <span v-if="!isFetchingCurrentRoundDetails">{{
                        currentRound
                      }}</span>
                      <span v-else><SkeletalLoading width="50" /></span>
                    </div>
                    <div class="row">
                      <span>Bonds Purchased</span>
                      <span v-if="!isFetchingCurrentRoundDetails">{{
                        bondsPurchased
                      }}</span>
                      <span v-else>
                        <SkeletalLoading width="50" />
                      </span>
                    </div>
                    <div class="row">
                      <span>Quantity Per Wallet</span>
                      <span v-if="!isFetchingCurrentRoundDetails">
                        {{ quantityPerWallet }}</span
                      >
                      <span v-else><SkeletalLoading width="50" /></span>
                    </div>
                    <div class="row">
                      <span>Tokens Remaining</span>
                      <span v-if="!isFetchingCurrentRoundDetails">{{
                        tokensRemaining
                      }}</span>
                      <span v-else><SkeletalLoading width="50" /></span>
                    </div>
                    <div class="row">
                      <span>Discount</span>
                      <span v-if="!isFetchingCurrentRoundDetails"
                        >{{ discountPercentage }}%</span
                      >
                      <span v-else><SkeletalLoading width="50" /></span>
                    </div>
                    <div class="row">
                      <span>Reward/Bond</span>
                      <span v-if="!isFetchingCurrentRoundDetails">{{
                        tokenRewardPerBond
                      }}</span>
                      <span v-else><SkeletalLoading width="50" /></span>
                    </div>
                    <div class="row">
                      <span>Start</span>
                      <span v-if="!isFetchingCurrentRoundDetails">{{
                        emissionStart
                      }}</span>
                      <span v-else><SkeletalLoading width="50" /></span>
                    </div>
                    <div class="row">
                      <span>End</span>
                      <span v-if="!isFetchingCurrentRoundDetails">{{
                        emissionEnd
                      }}</span>
                      <span v-else><SkeletalLoading width="50" /></span>
                    </div>
                  </div>
                </div>
                <div class="interact">
                  <div class="purchase" id="purchase-bond">
                    <h4>Purchase Bonds</h4>
                    <div class="grid">
                      <label for="">Quantity</label>
                      <input
                        type="number"
                        placeholder="1"
                        v-model="quantityToBuy"
                        min="0"
                        :max="quantityPerWallet"
                        :disabled="tokensRemaining === 0"
                      />
                      <p class="price">{{ bondPrice }} DCAU / bond</p>
                    </div>
                    <DialogButton
                      v-if="bondAllowance == 0"
                      @click="approveContract"
                      buttonSize="med"
                      :buttonText="'Approve'"
                      :isLoading="isBuyingBond"
                      :disabled="!isRewardActive || isBuyingBond"
                    />
                    <DialogButton
                      v-else
                      @click="buyBond"
                      buttonSize="med"
                      :buttonText="
                        tokensRemaining === 0 ? 'Sold Out' : 'Buy Bond'
                      "
                      :isLoading="isBuyingBond"
                      :disabled="
                        !isRewardActive || isBuyingBond || tokensRemaining === 0
                      "
                    />
                  </div>
                  <div class="claim" id="claim-rewards">
                    <h4>Claim Reward</h4>
                    <p class="reward">
                      {{ rewardAmount }}
                    </p>
                    <DialogButton
                      @click="claimRewardAmount"
                      buttonSize="med"
                      :buttonText="'Claim Reward'"
                      :isLoading="isClaimingReward"
                      :disabled="rewardAmount == 0 || isClaimingReward"
                    />
                    <p class="claim-info" v-if="numberOfBondsOwned > 1">
                      Claiming your bond rewards will require
                      {{ numberOfBondsOwned }} transactions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="vaults">
              <template v-if="!isFetchingStakingInfo">
                <div class="col">
                  <div class="tabs">
                    <button
                      :class="showTab === 'stake' ? 'active' : ''"
                      @click="showTab = 'stake'"
                      id="stake-tab"
                    >
                      Stake
                    </button>
                    <button
                      :class="showTab === 'unstake' ? 'active' : ''"
                      @click="showTab = 'unstake'"
                      id="unstake-tab"
                    >
                      Unstake
                    </button>
                    <button
                      :class="showTab === 'locked' ? 'active' : ''"
                      @click="showLocked"
                      id="locked-tab"
                    >
                      Locked
                    </button>
                  </div>
                  <div class="brown-box">
                    <template v-if="showTab === 'stake'">
                      <div class="grid-amount">
                        <div>
                          <p
                            class="quick-balance"
                            v-if="!isFetchingDcarBalance"
                            @click="
                              () => {
                                dcarAmount = parseFloat(dcarBalance.toString());
                              }
                            "
                          >
                            Balance: {{ dcarBalance }}
                          </p>
                          <p v-else>
                            <SkeletalLoading width="100" height="20" />
                          </p>
                          <div
                            :class="[
                              'stake-amount',
                              isStaking ? 'disabled' : '',
                            ]"
                          >
                            <input
                              type="number"
                              placeholder="Enter Amount"
                              v-model="dcarAmount"
                              min="1"
                              :disabled="isStaking"
                            />
                          </div>
                        </div>
                        <div>
                          <p
                            class="quick-balance"
                            title="s"
                            @mouseenter="showLockPeriodPopper = true"
                            @mouseleave="showLockPeriodPopper = false"
                          >
                            <Popper
                              arrow
                              placement="bottom"
                              :show="showLockPeriodPopper"
                              class="popper-container"
                              content="You lock your DCAR and will be able to unstake it after the lock period ends. You get instant Copper Gifts for locking."
                            >
                              Lock Period
                            </Popper>
                          </p>
                          <div
                            class="dropdown"
                            @click.stop="isDropdownActive = !isDropdownActive"
                            :class="{ active: isDropdownActive }"
                          >
                            <div class="select">
                              <span>{{
                                getLockStakeDurationText(lockStakeDuration)
                              }}</span>
                              <i class="fa fa-chevron-left"></i>
                            </div>
                            <input
                              type="hidden"
                              name="gender"
                              v-model="lockStakeDuration"
                            />
                            <ul class="dropdown-menu" v-if="isDropdownActive">
                              <li @click.stop="selectLockPeriod('0')">None</li>
                              <li @click.stop="selectLockPeriod('1')">
                                1 Month
                              </li>
                              <li @click.stop="selectLockPeriod('2')">
                                3 Months
                              </li>
                              <li @click.stop="selectLockPeriod('3')">
                                6 Months
                              </li>
                              <li @click.stop="selectLockPeriod('4')">
                                1 Year
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="apr-value">
                          <p
                            class="quick-balance"
                            @mouseenter="showAprPopper = true"
                            @mouseleave="showAprPopper = false"
                          >
                            <Popper
                              arrow
                              placement="bottom"
                              :show="showAprPopper"
                              class="popper-container"
                              content="Combined APR of DCAU and DCAR rewards you receive from staking."
                            >
                              Total APR
                            </Popper>
                          </p>
                          <span>{{ totalApr }}%</span>
                        </div>
                        <div class="apr-value">
                          <p
                            class="quick-balance"
                            @mouseenter="showCopperGiftPopper = true"
                            @mouseleave="showCopperGiftPopper = false"
                          >
                            <Popper
                              arrow
                              placement="bottom"
                              :show="showCopperGiftPopper"
                              class="popper-container"
                              content="You get instant Copper Bonus on locking DCAR for certain periods."
                            >
                              Copper Gift
                            </Popper>
                          </p>
                          <span>
                            {{ copperInstantPayout }}
                          </span>
                        </div>
                      </div>
                      <DialogButton
                        v-if="stakeAllowance == 0"
                        @click="approveVault"
                        buttonSize="med"
                        :buttonText="'Approve'"
                        :isLoading="isStaking"
                      />
                      <DialogButton
                        v-else
                        @click="depositDcar"
                        buttonSize="med"
                        :buttonText="`Stake $DCAR`"
                        :isLoading="isStaking"
                        :disabled="isStaking || dcarAmount == 0"
                      />
                    </template>
                    <template v-else-if="showTab === 'unstake'">
                      <div class="grid-amount single">
                        <div>
                          <p
                            class="quick-balance"
                            v-if="!isFetchingDcarBalance"
                            @click="
                              () => {
                                dcarAmount = parseFloat(
                                  stakedInfo.stakedAmount.toString()
                                );
                              }
                            "
                          >
                            Balance: {{ stakedInfo.stakedAmount }}
                          </p>
                          <p v-else>
                            <SkeletalLoading width="100" height="20" />
                          </p>
                          <div
                            :class="[
                              'stake-amount',
                              isStaking ? 'disabled' : '',
                            ]"
                          >
                            <input
                              type="number"
                              placeholder="Enter Amount"
                              v-model.number="dcarAmount"
                              min="1"
                              :disabled="isStaking"
                            />
                          </div>
                        </div>
                      </div>
                      <DialogButton
                        @click="withdrawDcar"
                        buttonSize="med"
                        :buttonText="`Unstake $DCAR`"
                        :isLoading="isStaking"
                        :disabled="isStaking || stakedInfo.stakedAmount == 0.0"
                      />
                    </template>
                    <template v-else-if="showTab === 'locked'">
                      <div class="lock-period">
                        <div class="lock-wrapper">
                          <!-- <div class="options-grid hide">
                          <button
                            :class="lockStakeDuration === 1 ? 'active' : ''"
                            @click="fetchLockedForPeriod(1)"
                          >
                            1 Month
                          </button>
                          <button
                            :class="lockStakeDuration === 2 ? 'active' : ''"
                            @click="fetchLockedForPeriod(2)"
                          >
                            3 Months
                          </button>
                          <button
                            :class="lockStakeDuration === 3 ? 'active' : ''"
                            @click="fetchLockedForPeriod(3)"
                          >
                            6 Months
                          </button>
                          <button
                            :class="lockStakeDuration === 4 ? 'active' : ''"
                            @click="fetchLockedForPeriod(4)"
                          >
                            1 Year
                          </button>
                        </div> -->
                          <div class="locked-data">
                            <template v-if="!isFetchingLockedValue">
                              <template v-if="lockedDetails.length > 0">
                                <div
                                  class="locked-details"
                                  v-for="(lockedDetail, index) in lockedDetails"
                                  :key="index"
                                >
                                  <div>
                                    <p>
                                      <span>Locked On</span>
                                      {{ formatDate(lockedDetail.timestamp) }}
                                    </p>
                                  </div>
                                  <div>
                                    <p>
                                      <span>Locked DCAR</span>
                                      {{ lockedDetail.amount }}
                                    </p>
                                  </div>

                                  <div
                                    v-if="
                                      currentTimestamp <
                                      lockPeriod(
                                        lockedDetail.timestamp,
                                        lockedDetail.period
                                      )
                                    "
                                  >
                                    <p>
                                      <span>Unlocks in</span
                                      >{{
                                        unlocksOn(
                                          lockedDetail.timestamp,
                                          lockedDetail.period
                                        )
                                      }}
                                    </p>
                                  </div>
                                  <div v-else>
                                    <p>
                                      <span>Unlocked</span
                                      ><DialogButton
                                        @click="
                                          withdrawLockedDcar(
                                            lockedDetail.amount
                                          )
                                        "
                                        buttonSize="med"
                                        :buttonText="`Unstake`"
                                        :isLoading="isStaking"
                                        :disabled="isStaking"
                                      />
                                    </p>
                                  </div>
                                  <!-- <div>
                              <div class="token-balance">
                                <div class="dcar token" id="dcar-balance">
                                  <p>Locked DCAR</p>
                                  <p>
                                    {{ lockedDetails.amount }}
                                  </p>
                                </div>
                              </div>
                            </div> -->
                                </div>
                              </template>
                              <p v-else class="message">
                                No DCAR locked for this period.
                              </p>
                            </template>
                            <div v-else class="locked-details">
                              <SkeletalLoading width="100" height="20" />
                              <SkeletalLoading width="100" height="20" />
                              <SkeletalLoading width="100" height="20" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div
                    :class="['brown-box', showTab === 'locked' ? 'hide' : '']"
                    id="claim-vault-rewards"
                  >
                    <h4>
                      Claim Rewards
                      <span
                        :class="[
                          'status',
                          isCurrentlyStaking ? 'live' : 'closed',
                        ]"
                      >
                        <span class="pulsating-circle"></span>
                        {{ isCurrentlyStaking ? `Active` : "Not Staking" }}
                      </span>
                    </h4>
                    <div class="grid-rewards balances" v-if="pendingRewards">
                      <div>
                        <div class="token-balance">
                          <div class="dcau token" id="dcau-balance">
                            <p v-if="!isFetchingStakingInfo">
                              {{ pendingRewards.dcau }}
                            </p>
                            <p v-else>
                              <SkeletalLoading width="100" height="20" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="token-balance">
                          <div class="dcar token" id="dcar-balance">
                            <p v-if="!isFetchingStakingInfo">
                              {{ pendingRewards.dcar }}
                            </p>
                            <p v-else>
                              <SkeletalLoading width="100" height="20" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="token-balance">
                          <div class="copper token" id="copper-balance">
                            <p>
                              {{ pendingRewards.copper }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <DialogButton
                      @click="claimRewards"
                      buttonSize="med"
                      :buttonText="`Claim Rewards`"
                      :isLoading="isClaiming"
                      :disabled="isClaiming || !isCurrentlyStaking"
                    />
                  </div>
                </div>
                <div class="col">
                  <TokensBar :barType="`full-width`" />
                  <div class="brown-box main">
                    <h4>TOTAL APR</h4>
                    {{ totalApr }}%
                  </div>
                  <div class="grid-meta" v-if="stakedInfo" id="staked-meta">
                    <div class="brown-box">
                      <h4>Your Staked</h4>
                      {{ formatNumber(stakedInfo.stakedAmount) }}
                    </div>
                    <div class="brown-box locked">
                      <h4>Your Locked</h4>
                      <span>{{ formatNumber(stakedInfo.lockedAmount) }}</span>
                    </div>
                    <div class="brown-box">
                      <h4>Total Staked</h4>
                      {{ formatNumber(stakedInfo.totalStaked) }}
                    </div>
                    <div class="brown-box locked">
                      <h4>Total Locked</h4>
                      <span>{{ formatNumber(stakedInfo.totalLocked) }}</span>
                    </div>
                  </div>
                </div>
              </template>
              <GenericLoading v-else />
            </div>
          </div>
        </div>
      </div>
      <DialogButton
        class="nest-button"
        @click="openNest"
        :disabled="isBuyingBond || isClaimingReward"
        :buttonText="'Nest'"
        buttonSize="small"
      />
    </BasicPopup>
  </div>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import Constants from "../../consts/constants";
import { parseError } from "../../utils/helpers";
import DialogButton from "../DialogButton.vue";
import SkeletalLoading from "../LoadingComponents/SkeletalLoading.vue";
import GenericLoading from "../LoadingComponents/GenericLoading.vue";
import BasicPopup from "../Popup/BasicPopup.vue";
import TokensBar from "./TokensBar.vue";
import Popper from "vue3-popper";

import { ethers } from "ethers";
import { usePrice } from "../../composables/price";
import { useUser } from "../../composables/user";

dayjs.extend(relativeTime);

import {
  getDCARContract,
  getDCAUContract,
  getDCGBondContract,
  // getLoadRewardHandlerContract,
  getLoadPriceHandlerContract,
  getDCGTripleVault,
} from "../../utils/getContract";
export default {
  name: "Marketplace",
  components: {
    BasicPopup,
    DialogButton,
    SkeletalLoading,
    GenericLoading,
    TokensBar,
    Popper,
  },
  setup() {
    const store = useStore();
    const isBuyingBond = ref(false);
    const isClaimingReward = ref(false);
    const isRewardActive = ref(false);
    const currentRound = ref(0);
    const quantityPerWallet = ref(0);
    const quantityToBuy = ref(1);
    const tokensRemaining = ref(0);
    const discountPercentage = ref(0);
    const tokenRewardPerBond = ref(0);
    const emissionStart = ref("");
    const emissionEnd = ref("");
    const tokensPerSecond = ref(0);
    const dcauDollarAmount = ref(0);
    const dcarDollarAmount = ref(0);
    const rewardAmount = ref(0);
    const numberOfBondsOwned = ref(0);
    const bondPrice = ref(0);
    const canClaimDCAUFaucet = ref(false);
    const canClaimDCARFaucet = ref(false);
    const refreshTimer = ref(null);
    const isFaucetLoading = ref(false);
    const bondAllowance = ref(0);
    const bondsPurchased = ref(0);

    // Stake related
    const isFetchingStakingInfo = ref(true);
    const stakeAllowance = ref(0);
    const lockStakeDuration = ref(1);
    const isStaking = ref(false);
    const isClaiming = ref(false);
    const isFetchingCopperBalance = ref(true);
    const dcarAmount = ref(0);
    const showTab = ref("stake");
    const stakedInfo = ref(null);
    const copperBalance = ref(0);
    const pendingRewards = ref(null);
    const totalApr = ref(0);
    const isDropdownActive = ref(false);
    const lockedDetails = ref([]);
    const isFetchingLockedValue = ref(true);
    const dcauUSD = ref(0);
    const dcarUSD = ref(0);
    const copperGiftPercentages = {
      1: 0.002,
      2: 0.006,
      3: 0.012,
      4: 0.024,
    };
    const showCopperGiftPopper = ref(false);
    const showLockPeriodPopper = ref(false);
    const showAprPopper = ref(false);

    // Loading Flags
    const isFetchingDcauBalance = ref(true);
    const isFetchingDcarBalance = ref(true);
    const isFetchingCurrentRoundDetails = ref(true);

    // Toggle Flags
    const showBondSale = ref(false);

    // const currentNFT = ref(2);

    const { address, signer } = useUser();
    const {
      updateBalances,
      getDCAUBalance,
      getDCARBalance,
      dcauBalance,
      dcarBalance,
      getDCAUDollarBalance,
      getDCARDollarBalance,
      getDCAUPricePerDollar,
      getDCARPricePerDollar,
    } = usePrice();
    const BondContract = getDCGBondContract(signer.value);
    const DCAUContract = getDCAUContract(signer.value);
    const DCARContract = getDCARContract(signer.value);
    const DCGTripleVaultContract = getDCGTripleVault(signer.value);
    // const RewardHandlerContract = getLoadRewardHandlerContract(signer.value);
    const PriceHandlerContract = getLoadPriceHandlerContract(signer.value);
    onMounted(async () => {
      if (showBondSale.value) {
        await getBondAllowance();
        await refreshDetails();
        await pendingRewardAmount();
        await getBondsPurchased();
        await getFaucetStatus();
      } else {
        await refreshStakingDetails();
        await getStakeAllowance();
        await fetchLockedForPeriod(1); // Fetching for 1 month by default
      }
    });

    onUnmounted(() => {
      clearTimeout(refreshTimer.value);
    });

    /**
     * Methods
     */
    const addDcauToMetamask = async () => {
      const tokenAddress = DCAUContract.address;
      const tokenSymbol = await DCAUContract.symbol();
      const tokenDecimals = await DCAUContract.decimals();
      const tokenImage =
        "https://assets.coingecko.com/coins/images/20226/small/7f7e57.jpeg?1656977391";

      try {
        const status = await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image: tokenImage,
            },
          },
        });
        if (status) {
          store.commit("setNotification", "Added DCAU to your Metamask wallet");
        } else {
          store.commit(
            "setNotification",
            "Error in adding DCAU to your Metamask wallet"
          );
        }
      } catch (error) {
        console.log(error);
        store.commit("setNotification", parseError(error));
      }
    };

    const addDcarToMetamask = async () => {
      const tokenAddress = DCARContract.address;
      const tokenSymbol = await DCARContract.symbol();
      const tokenDecimals = await DCARContract.decimals();
      const tokenImage =
        "https://assets.coingecko.com/coins/images/26613/small/dcar%283%29.png?1659067097";

      try {
        const status = await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image: tokenImage,
            },
          },
        });
        if (status) {
          store.commit("setNotification", "Added DCAR to your Metamask wallet");
        } else {
          store.commit(
            "setNotification",
            "Error in adding DCAR to your Metamask wallet"
          );
        }
      } catch (error) {
        console.log(error);
        store.commit("setNotification", parseError(error));
      }
    };
    const refreshStakingDetails = async () => {
      await getDCAUBalance();
      await getDCARBalance();
      dcauDollarAmount.value = await getDCAUDollarBalance();
      const dcauPrice = await getDCAUPricePerDollar();
      dcauUSD.value = 1 / dcauPrice;
      isFetchingDcauBalance.value = false;
      dcarDollarAmount.value = await getDCARDollarBalance();
      const dcarPrice = await getDCARPricePerDollar();
      dcarUSD.value = 1 / dcarPrice;
      isFetchingDcarBalance.value = false;
      await getStakedInfo();
    };
    const refreshDetails = async () => {
      await getCurrentRound();
      await getCurrentRoundDetails();
      await getDCAUBalance();
      await getDCARBalance();
      dcauDollarAmount.value = await getDCAUDollarBalance();
      isFetchingDcauBalance.value = false;
      dcarDollarAmount.value = await getDCARDollarBalance();
      isFetchingDcarBalance.value = false;
    };
    const getFaucetStatus = async () => {
      try {
        const dcauFaucetStatus = await DCAUContract.canClaimFaucet();
        canClaimDCAUFaucet.value = dcauFaucetStatus;
        const dcarFaucetStatus = await DCARContract.canClaimFaucet();
        canClaimDCARFaucet.value = dcarFaucetStatus;
      } catch (error) {
        console.log(error);
        store.commit("setNotification", parseError(error));
      }
    };
    const claimRewardAmount = async () => {
      isClaimingReward.value = true;
      try {
        const userBonds = await getOwnerBondsWithRewards();
        if (userBonds.length > 0) {
          for (let i = 0; i < userBonds.length; i++) {
            const getRewardsTx = await BondContract.claimBondRewards(
              userBonds[i].toString()
            );
            const receipt = await getRewardsTx.wait();
            if (receipt.status === 1) {
              await updateBalances();
              await refreshDetails();
            }
          }
        }
        isClaimingReward.value = false;
      } catch (e) {
        console.log(e);
        isClaimingReward.value = false;
      }
    };
    const claimDCAUFaucet = async () => {
      isFaucetLoading.value = true;

      try {
        const claimFaucetTx = await DCAUContract.claimFaucet();
        const receipt = await claimFaucetTx.wait();
        if (receipt.status === 1) {
          await updateBalances();
          await refreshDetails();
          store.commit("setNotification", "Successfully claimed DCAU faucet");
          isFaucetLoading.value = false;
        }
      } catch (e) {
        isFaucetLoading.value = false;
        store.commit("setNotification", parseError(e));
      }
    };
    const claimDCARFaucet = async () => {
      try {
        isFaucetLoading.value = true;
        const claimFaucetTx = await DCARContract.claimFaucet();
        const receipt = await claimFaucetTx.wait();
        if (receipt.status === 1) {
          await updateBalances();
          await refreshDetails();
          store.commit("setNotification", "Successfully claimed DCAR faucet");
          isFaucetLoading.value = false;
        }
      } catch (e) {
        isFaucetLoading.value = false;
        store.commit("setNotification", parseError(e));
      }
    };
    const getCurrentRound = async () => {
      const round = await BondContract.CURRENT_ROUND_COUNTER();
      currentRound.value = parseFloat(ethers.utils.formatUnits(round, 0));
    };
    const ownerBalanceOf = async () => {
      return await BondContract.balanceOf(address.value);
    };
    const ownerBonds = async (count) => {
      const ownedBonds = [];
      if (count > 0) {
        for (let i = 0; i < count; i++) {
          const bond = await BondContract.tokenOfOwnerByIndex(address.value, i);
          ownedBonds.push(parseFloat(bond));
        }
      }
      return ownedBonds;
    };
    const bondHasRewards = async (id) => {
      const rewards = await BondContract.pendingDCARForBond(id.toString());
      if (parseFloat(rewards) > 0) {
        return true;
      } else {
        return false;
      }
    };
    const getOwnerBondsWithRewards = async () => {
      const count = await ownerBalanceOf();
      const ownedBonds = await ownerBonds(count);
      const bondsWithRewards = [];
      for (let i = 0; i < ownedBonds.length; i++) {
        const hasRewards = await bondHasRewards(ownedBonds[i]);
        if (hasRewards) {
          bondsWithRewards.push(ownedBonds[i]);
        }
      }
      return bondsWithRewards;
    };
    const getOwnerBonds = async () => {
      const count = await ownerBalanceOf();
      const ownedBonds = await ownerBonds(count);
      return ownedBonds;
    };
    const pendingRewardAmount = async () => {
      let totalPendingRewards = 0;
      const userBonds = await getOwnerBonds();
      if (userBonds.length > 0) {
        for (let i = 0; i < userBonds.length; i++) {
          const pendingReward = await BondContract.pendingDCARForBond(
            userBonds[i].toString()
          );
          totalPendingRewards += parseFloat(
            ethers.utils.formatEther(pendingReward)
          );
        }
      }
      rewardAmount.value = Math.round(totalPendingRewards * 100) / 100;
      await getCurrentNumberOfBonds();
      refreshTimer.value = setTimeout(async () => {
        await pendingRewardAmount();
      }, 20000);
    };
    const getCurrentNumberOfBonds = async () => {
      const numberOfBonds = await getOwnerBondsWithRewards();
      numberOfBondsOwned.value = numberOfBonds.length;
    };
    const getBondsPurchased = async () => {
      bondsPurchased.value = await BondContract.ROUND_BUYS_PER_WALLET(
        currentRound.value,
        address.value
      );
    };
    const getCurrentRoundDetails = async () => {
      isFetchingCurrentRoundDetails.value = true;
      const details = await BondContract.ROUND_DETAILS(
        ethers.utils.formatUnits(currentRound.value, 0)
      );
      quantityPerWallet.value = parseFloat(
        ethers.utils.formatUnits(details[0], 0)
      );
      tokensRemaining.value = parseFloat(
        ethers.utils.formatUnits(details[1], 0)
      );
      discountPercentage.value =
        parseFloat(ethers.utils.formatUnits(details[2], 0)) / 10;
      tokenRewardPerBond.value = parseFloat(
        ethers.utils.formatEther(details[3])
      );

      const startDate = new Date(
        parseInt(ethers.utils.formatUnits(details[4], 0)) * 1000
      );
      emissionStart.value = dayjs(startDate).fromNow();

      const endDate = new Date(
        parseInt(ethers.utils.formatUnits(details[5], 0)) * 1000
      );
      emissionEnd.value = dayjs(endDate).fromNow();

      console.log(endDate.getTime());
      console.log(new Date().getTime());
      console.log(startDate.getTime());

      const currentTime = new Date();
      // Checking if current time is withing start and end time
      if (
        endDate.getTime() > currentTime.getTime() &&
        startDate <= currentTime.getTime()
      ) {
        isRewardActive.value = true;
      } else {
        isRewardActive.value = false;
      }

      tokensPerSecond.value = parseFloat(
        ethers.utils.formatUnits(details[6], 0)
      );

      bondPrice.value = ethers.utils.formatEther(
        await PriceHandlerContract.tokenCostDCARBond(
          DCAUContract.address,
          discountPercentage.value * 10
        )
      );
      isFetchingCurrentRoundDetails.value = false;
    };
    const getBondPurchaseSignature = async () => {
      const sessionId = localStorage.getItem("sessionId");
      const heroId = character.value.number;
      const quantity = quantityToBuy.value;
      const tokenToBuyWith = DCAUContract.address;
      const response = await fetch(
        Constants.apiUrl + "contract/sign-buy-bond",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId,
            heroId,
            quantity,
            tokenToBuyWith,
            account: address.value,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      return {
        tokenToBuyWith,
        quantity,
        signature: data.signature,
        nonce: data.nonce,
      };
    };
    const getBondAllowance = async () => {
      const allowance = await DCAUContract.allowance(
        address.value,
        BondContract.address
      );
      bondAllowance.value = allowance;
    };
    const approveContract = async () => {
      try {
        isBuyingBond.value = true;
        if (parseFloat(bondAllowance.value) === 0.0) {
          const txApproveInn = await DCAUContract.approve(
            BondContract.address,
            ethers.utils.parseEther("1000000000")
          );
          const tx = await txApproveInn.wait();
          if (tx.status === 1) {
            await getBondAllowance();
            await buyBond();
            isBuyingBond.value = false;
          }
        }
      } catch (error) {
        console.log(error);
        isBuyingBond.value = false;
        store.commit("setNotification", parseError(error));
      }
    };
    const getStakeAllowance = async () => {
      const allowance = await DCARContract.allowance(
        address.value,
        DCGTripleVaultContract.address
      );
      stakeAllowance.value = ethers.utils.formatEther(allowance);
      console.log("STAKE ALLOWANCE:", stakeAllowance.value);
    };
    const approveVault = async () => {
      isStaking.value = true;
      try {
        if (parseFloat(stakeAllowance.value) === 0.0) {
          const txApproveVault = await DCARContract.approve(
            DCGTripleVaultContract.address,
            ethers.utils.parseEther("1000000000")
          );
          const tx = await txApproveVault.wait();
          if (tx.status === 1) {
            await getStakeAllowance();
          }
        }
      } catch (error) {
        console.log(error);
        store.commit("setNotification", parseError(error));
      }
      isStaking.value = false;
    };
    const getStakedInfo = async () => {
      isFetchingStakingInfo.value = true;
      isFetchingCopperBalance.value = true;
      try {
        const userInfo = await DCGTripleVaultContract.userInfo(address.value);
        const totalStaked = await DCGTripleVaultContract.totalStaked();
        const totalLocked = await DCGTripleVaultContract.totalLocked();
        const pendingRewardsTokens =
          await DCGTripleVaultContract.pendingRewards(address.value);
        const pendingRewardsCopper =
          await DCGTripleVaultContract.pendingCopperRewards(address.value);
        console.log("PENDING REWARDS COPPER", pendingRewardsCopper);
        const dcauEmissionRate =
          await DCGTripleVaultContract.dcauEmissionRate();
        const dcarEmissionRate =
          await DCGTripleVaultContract.dcarEmissionRate();
        // const copperEmissionRate =
        //   await DCGTripleVaultContract.copperEmissionRate();
        stakedInfo.value = {
          stakedAmount: parseFloat(
            ethers.utils.formatEther(userInfo.stakedAmount)
          ).toFixed(2),
          lockedAmount: parseFloat(
            ethers.utils.formatEther(userInfo.lockedAmount)
          ).toFixed(2),
          rewardDebtDCAR: parseFloat(
            ethers.utils.formatEther(userInfo.rewardDebtDCAR)
          ).toFixed(2),
          rewardDebtDCAU: parseFloat(
            ethers.utils.formatEther(userInfo.rewardDebtDCAU)
          ).toFixed(2),
          rewardDebtCOPPER: parseFloat(
            ethers.utils.formatEther(userInfo.rewardDebtCOPPER)
          ).toFixed(2),
          copperBalance: parseFloat(
            ethers.utils.formatEther(userInfo.copperBalance)
          ).toFixed(2),
          totalStaked: parseFloat(
            ethers.utils.formatEther(totalStaked)
          ).toFixed(2),
          totalLocked: parseFloat(
            ethers.utils.formatEther(totalLocked)
          ).toFixed(2),
          // dcauEmissionRate: parseFloat(
          //   ethers.utils.formatEther(dcauEmissionRate)
          // ).toFixed(2),
          // dcarEmissionRate: parseFloat(
          //   ethers.utils.formatEther(dcarEmissionRate)
          // ).toFixed(2),
          // copperEmissionRate: parseFloat(
          //   ethers.utils.formatEther(copperEmissionRate)
          // ).toFixed(2),
        };
        pendingRewards.value = {
          dcar: parseFloat(
            ethers.utils.formatEther(pendingRewardsTokens.dcarPending)
          ).toFixed(2),
          dcau: parseFloat(
            ethers.utils.formatEther(pendingRewardsTokens.dcauPending)
          ).toFixed(2),
          copper: parseFloat(
            ethers.utils.formatEther(pendingRewardsCopper)
          ).toFixed(2),
        };
        const totalEffectiveStaked =
          parseFloat(ethers.utils.formatEther(totalStaked)) +
          parseFloat(ethers.utils.formatEther(totalLocked));
        const stakedTvlAmount = totalEffectiveStaked * dcarUSD.value;

        /**
         * Calculating APR
         */
        const dcarRewardsPerYear =
          parseFloat(ethers.utils.formatEther(dcarEmissionRate)) *
          60 *
          60 *
          24 *
          365 *
          dcarUSD.value;

        const dcauRewardsPerYear =
          parseFloat(ethers.utils.formatEther(dcauEmissionRate)) *
          60 *
          60 *
          24 *
          365 *
          dcauUSD.value;

        const totalRewardsPerYear = dcauRewardsPerYear + dcarRewardsPerYear;

        totalApr.value = parseFloat(
          (totalRewardsPerYear / stakedTvlAmount) * 100
        ).toFixed(2);

        copperBalance.value = stakedInfo.value.copperBalance;
      } catch (error) {
        console.log(error);
        store.commit("setNotification", parseError(error));
      }
      isFetchingStakingInfo.value = false;
      isFetchingCopperBalance.value = false;
    };
    const showLocked = () => {
      showTab.value = "locked";
      fetchLockedVesting();
    };

    /**
     * Methods to fetch/update data in staking UI
     */

    const formatDate = (timestamp) => {
      return new Date(timestamp * 1000).toLocaleDateString();
      // return timestamp;
    };

    // fetching entire locking activity along with vesting data
    const fetchLockedVesting = async () => {
      isFetchingLockedValue.value = true;
      let count = 0;
      lockedDetails.value = [];
      do {
        try {
          const lockedForPeriod = await DCGTripleVaultContract.userLocks(
            address.value,
            count
          );
          lockedDetails.value.push({
            amount: parseFloat(
              ethers.utils.formatEther(lockedForPeriod.amount)
            ).toFixed(2),
            timestamp: parseFloat(
              ethers.utils.formatUnits(lockedForPeriod.timestamp, 0)
            ),
            period: lockedForPeriod.period,
          });
        } catch (error) {
          console.log(error);
          break;
          // store.commit("setNotification", parseError(error));
        }
        count++;
      } while (count > 0);
      isFetchingLockedValue.value = false;
    };
    /**
     * Method to stake DCAR in the triple vault contract
     */
    const depositDcar = async () => {
      isStaking.value = true;
      try {
        if (typeof dcarAmount.value !== "number") {
          store.commit("setNotification", "DCAR amount can only be a number");
          return;
        }
        if (dcarAmount.value < 0) {
          dcarAmount.value = 1;
          store.commit("setNotification", "DCAR amount cannot be less than 1");
          return;
        }
        dcarAmount.value = Math.floor(parseFloat(dcarAmount.value)); // rounding off to floor
        if (parseFloat(dcarAmount.value) > parseFloat(stakeAllowance.value)) {
          const txApproveVault = await DCARContract.approve(
            DCGTripleVaultContract.address,
            ethers.utils.parseEther("1000000000")
          );
          await txApproveVault.wait();
        }

        const tx = await DCGTripleVaultContract.deposit(
          ethers.utils.parseEther(`${dcarAmount.value}`),
          ethers.utils.parseUnits(`${lockStakeDuration.value}`, 0)
        );
        const receipt = await tx.wait();
        // Only process Heal if the transaction was successful!
        if (receipt.status === 1) {
          isStaking.value = false;
          store.commit("setNotification", "Successfully staked!");
          lockStakeDuration.value = 1;
          dcarAmount.value = 0;
          await refreshStakingDetails();
          await updateBalances();
        }
      } catch (error) {
        console.log(error);
        store.commit("setNotification", parseError(error));
      }
      isStaking.value = false;
    };

    /**
     * Method to claim rewards
     */
    const claimRewards = async () => {
      isClaiming.value = true;
      try {
        const tx = await DCGTripleVaultContract.harvestRewards();
        const receipt = await tx.wait();
        // Only process Heal if the transaction was successful!
        if (receipt.status === 1) {
          console.log("Success!~", receipt);
          await refreshStakingDetails();
          await updateBalances();
        }
      } catch (error) {
        console.log(error);
        store.commit("setNotification", parseError(error));
      }
      isClaiming.value = false;
    };

    /**
     * Method to unstake DCAR from the triple vault contract
     */
    const withdrawDcar = async () => {
      isStaking.value = true;
      try {
        const tx = await DCGTripleVaultContract.withdraw(
          ethers.utils.parseEther(`${dcarAmount.value}`),
          false
        );
        const receipt = await tx.wait();
        if (receipt.status === 1) {
          lockStakeDuration.value = 1;
          dcarAmount.value = 0;
          await refreshStakingDetails();
          await updateBalances();
        }
      } catch (error) {
        store.commit("setNotification", parseError(error));
      }
      isStaking.value = false;
    };
    /**
     * Method to unstake Locked DCAR from the triple vault contract
     */
    const withdrawLockedDcar = async (amount) => {
      isStaking.value = true;
      try {
        const tx = await DCGTripleVaultContract.withdraw(
          ethers.utils.parseEther(amount),
          true
        );
        const receipt = await tx.wait();
        if (receipt.status === 1) {
          await fetchLockedVesting();
          await updateBalances();
        }
      } catch (error) {
        store.commit("setNotification", parseError(error));
      }
      isStaking.value = false;
    };
    /**
     * Helper methods for Staking UI
     */
    const selectLockPeriod = (period) => {
      lockStakeDuration.value = period;
      isDropdownActive.value = false;
    };
    const formatNumber = (num) => {
      if (num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return num;
    };
    const fromTime = (timestamp) => {
      const d = new Date(timestamp * 1000);
      return dayjs(d).fromNow();
    };
    const unlocksOn = (timestamp, period) => {
      let timeToAdd = 0;
      switch (parseInt(period)) {
        case 0:
          timeToAdd = 0;
          break;
        case 1:
          timeToAdd = 1 * 30 * 24 * 60 * 60 * 1000;
          break;
        case 2:
          timeToAdd = 3 * 30 * 24 * 60 * 60 * 1000;
          break;
        case 3:
          timeToAdd = 6 * 30 * 24 * 60 * 60 * 1000;
          break;
        case 4:
          timeToAdd = 12 * 30 * 24 * 60 * 60 * 1000;
          break;
        default:
          timeToAdd = 0;
          break;
      }
      const d = new Date(timestamp * 1000 + timeToAdd);
      return dayjs(d).fromNow();
    };
    const getLockStakeDurationText = (period) => {
      switch (parseInt(period)) {
        case 0:
          return "None";
        case 1:
          return "1 Month";
        case 2:
          return "3 Months";
        case 3:
          return "6 Months";
        case 4:
          return "1 Year";
        default:
          return "None";
      }
    };

    /**
     * Methods to fetch/update data in staking UI
     */
    const fetchLockedForPeriod = async (period) => {
      lockStakeDuration.value = period;
      isFetchingLockedValue.value = true;
      try {
        if (period < 1 && period > 4) {
          store.commit("setNotification", "Bond quantity can only be a number");
          return;
        }
        const lockedForPeriod = await DCGTripleVaultContract.userLocks(
          address.value,
          period
        );
        lockedDetails.value = {
          amount: parseFloat(
            ethers.utils.formatEther(lockedForPeriod.amount)
          ).toFixed(2),
          timestamp: parseFloat(
            ethers.utils.formatUnits(lockedForPeriod.timestamp, 0)
          ),
          period: lockedForPeriod.period,
        };
      } catch (error) {
        console.log(error);
        lockedDetails.value = null;
        // store.commit("setNotification", parseError(error));
      }
      isFetchingLockedValue.value = false;
    };

    const buyBond = async () => {
      isBuyingBond.value = true;

      if (typeof quantityToBuy.value !== "number") {
        store.commit("setNotification", "Bond quantity can only be a number");
        isBuyingBond.value = false;
        return;
      }

      if (quantityToBuy.value < 0) {
        quantityToBuy.value = 0;
        store.commit("setNotification", "Bond quantity cannot be less than 1");
        isBuyingBond.value = false;
        return;
      } else if (quantityToBuy.value > quantityPerWallet.value) {
        quantityToBuy.value = quantityPerWallet.value;
        store.commit(
          "setNotification",
          "Bond quantity cannot be more than per wallet quantity allowed"
        );
        isBuyingBond.value = false;
        return;
      }

      const { tokenToBuyWith, quantity, signature, nonce } =
        await getBondPurchaseSignature();
      try {
        // approving DCAU if DCAU isn't approved for Healer's Hut and Reward handler for user

        // Buying bond
        const tx = await BondContract.buyBond(
          tokenToBuyWith,
          quantity,
          signature,
          nonce,
          false
        );
        const receipt = await tx.wait();
        // Only process Heal if the transaction was successful!
        if (receipt.status === 1) {
          await refreshDetails();
          await updateBalances();
          await getBondsPurchased();
        }
        isBuyingBond.value = false;
      } catch (error) {
        console.log(error);
        store.commit("setNotification", parseError(error));
        isBuyingBond.value = false;
      }
    };

    const hide = () => {
      store.commit("hideBank");
      store.commit("bank/setIsBankOpen", false); // Hiding in mobile view as well
    };

    const openNest = () => {
      store.commit("setNest");
    };

    /**
     * Computed Methods
     */
    const copperInstantPayout = computed(() => {
      const duration = parseInt(lockStakeDuration.value);
      console.log("Duration", duration);
      if (duration > 0) {
        console.log(copperGiftPercentages[duration], dcarAmount.value);
        return (
          copperGiftPercentages[duration] * parseFloat(dcarAmount.value)
        ).toFixed(2);
      }
      return 0;
    });
    const isCurrentlyStaking = computed(() => {
      if (stakedInfo.value) {
        if (
          stakedInfo.value.stakedAmount > 0 ||
          stakedInfo.value.lockedAmount > 0
        ) {
          return true;
        }
      }
      return false;
    });
    const mood = computed(() => {
      const duration = parseInt(lockStakeDuration.value);
      if (duration === 0) {
        return "angry";
      } else if (duration > 0 && duration <= 2) {
        return "neutral";
      }
      return "happy";
    });
    const character = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });
    const currentClass = computed(() => {
      return (
        "bank-wrapper wrap-content " +
        (store.state.gameState == Constants.gamemodes.bank ? "show" : "hide")
      );
    });
    const isMainnet = computed(() => {
      return Constants.chainId == 43114;
    });

    const currentTimestamp = computed(() => {
      return Math.floor(Date.now() / 1000);
    });

    const lockPeriod = (lockTime, period) => {
      let seconds = 86400;
      if (period == 1) {
        return lockTime + seconds * 30;
      } else if (period == 2) {
        return lockTime + seconds * 90;
      } else if (period == 3) {
        return lockTime + seconds * 180;
      } else if (period == 4) {
        return lockTime + seconds * 365;
      }
      return lockTime;
    };

    return {
      /* All the computed values */
      currentClass,
      isFetchingDcauBalance,
      isFetchingDcarBalance,
      isFetchingCurrentRoundDetails,
      isMainnet,
      showBondSale,
      mood,
      isCurrentlyStaking,
      copperInstantPayout,
      /* All the methods */
      approveContract,
      buyBond,
      hide,
      claimRewardAmount,
      addDcauToMetamask,
      addDcarToMetamask,
      openNest,
      approveVault,
      claimDCAUFaucet,
      claimDCARFaucet,
      depositDcar,
      claimRewards,
      selectLockPeriod,
      fetchLockedForPeriod,
      withdrawDcar,
      withdrawLockedDcar,
      fromTime,
      getLockStakeDurationText,
      formatNumber,
      unlocksOn,
      showLocked,
      formatDate,
      /* All the data values */
      isBuyingBond,
      isClaimingReward,
      currentRound,
      quantityPerWallet,
      tokensRemaining,
      discountPercentage,
      tokenRewardPerBond,
      quantityToBuy,
      emissionStart,
      emissionEnd,
      tokensPerSecond,
      dcauBalance,
      dcarBalance,
      copperBalance,
      dcauDollarAmount,
      dcarDollarAmount,
      rewardAmount,
      bondPrice,
      isRewardActive,
      canClaimDCAUFaucet,
      canClaimDCARFaucet,
      numberOfBondsOwned,
      isFaucetLoading,
      bondAllowance,
      bondsPurchased,
      // Staking related
      stakeAllowance,
      lockStakeDuration,
      isStaking,
      isClaiming,
      dcarAmount,
      showTab,
      stakedInfo,
      pendingRewards,
      isFetchingStakingInfo,
      isFetchingCopperBalance,
      totalApr,
      isDropdownActive,
      lockedDetails,
      isFetchingLockedValue,
      showCopperGiftPopper,
      showLockPeriodPopper,
      showAprPopper,
      currentTimestamp,
      lockPeriod,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.bank-wrapper :deep(.popper) {
  background: #2c2c2c;
  padding: 20px;
  border-radius: 5px;
  color: #fff;
  z-index: 20;
  font-size: 0.9rem;
  font-family: "Lato", sans-serif;
  width: 330px;
  line-height: 1.2rem;
  text-align: center;
  text-transform: none;
  @media only screen and (max-width: 576px) {
    width: 95%;
    font-size: 0.8rem;
  }
}

.bank-wrapper :deep(.popper #arrow::before) {
  background: #2c2c2c;
}

.bank-wrapper :deep(.popper:hover),
.bank-wrapper :deep(.popper:hover > #arrow::before) {
  background: #2c2c2c;
}

.bank-wrapper {
  background: no-repeat url("../../assets/locationBackgrounds/groms-bank.webp")
    center center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.396);
  z-index: 6 !important;
  @media only screen and (max-width: 576px) {
    z-index: 6 !important;
  }
  .bank-popup {
    width: calc(80% - $popup-border-size - $popup-border-size);
    height: calc(600px - $popup-border-size - $popup-border-size);
    max-width: 1018px;
    min-width: 1018px;
    @media only screen and (max-width: 576px) {
      min-width: calc(100% - $popup-border-size - $popup-border-size);
    }
    .inner-wrapper {
      width: calc(100% + $popup-border-offset + $popup-border-offset);
      @media only screen and (max-width: 576px) {
        height: calc(100% + $popup-border-offset + $popup-border-offset);
        overflow-y: scroll;
      }
      left: -$popup-border-offset;
      position: relative;
      top: -35px;
    }
    .nest-button {
      position: absolute;
      top: -41px;
      right: -36px;
      z-index: 5;
      @media only screen and (max-width: 576px) {
        top: -114px;
        right: 50%;
        z-index: 5;
        transform: translateX(50%);
      }
    }
    .passbook {
      padding: 20px 20px 20px 200px;
      top: -16px;
      position: relative;
      background: #171312;
      box-shadow: 0 0 8px 2px #00000070;
      @media only screen and (max-width: 576px) {
        top: 0;
        /* margin-top: 20px; */
        margin-bottom: 1rem;
        padding: 20px;
      }
      h3 {
        margin-top: 0;
      }
      h4 {
        margin-top: 0;
        text-align: left;
        border-bottom: 1px solid #ffffff52;
        padding-bottom: 0.5rem;
      }
      .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1rem;
        justify-items: center;
        align-items: center;
        position: relative;
        @media only screen and (max-width: 576px) {
          grid-template-columns: 1fr;
        }
      }
      .buttons {
        margin: 20px 0;
      }
      .message-box {
        margin: 0 20px;
        text-align: left;
        padding: 0;
        position: absolute;
        left: 0;
        top: -20px;
        border-radius: 100%;
        width: 140px;
        height: 140px;
        @media only screen and (max-width: 576px) {
          display: none;
        }
        &:hover {
          .goblin {
            background-position: center right;
          }
        }
        h4 {
          margin: 0;
          font-size: 1.3rem;
        }
        p {
          margin: 10px 0 0 !important;
          font-size: 1.1rem;
        }
        .grid {
          display: grid;
          grid-template-columns: 150px 1fr;
          grid-gap: 1rem;
          align-items: center;
          @media only screen and (max-width: 576px) {
            grid-template-columns: 1fr;
          }
        }
        .goblin {
          background-image: url("../../assets/goblin-sprite.png");
          width: 137.5px;
          height: 200px;
          display: block;
          background-size: cover;
          background-position: center right;
          position: absolute;
          top: -54px;
          left: 50%;
          transform: translateX(-50%);
          position: relative;
          &.angry {
            background-position: center left;
          }
          &.neutral {
            background-position: center center;
          }
          &.happy {
            background-position: center right;
          }
          @media only screen and (max-width: 576px) {
            display: none;
          }
        }
      }
      .transactions {
        p {
          margin: 2px 0;
        }
        h5 {
          margin: 10px 0 0;
          text-align: left;
          color: #fff;
        }
        .transaction {
          text-align: left;
          .credit,
          .debit {
            font-size: 1.5rem;
            width: 15px;
            height: 15px;
            display: inline-block;
            text-align: center;
          }
          .credit {
            color: green;
          }
          .debit {
            color: red;
          }
          .amount {
            font-size: 1rem;
          }
          .time {
            font-size: 0.8rem;
            margin-left: 5px;
            color: #ffffff44;
          }
        }
        &:after {
          content: "Coming Soon";
          display: grid;
          width: 100%;
          height: 100%;
          background: #00000036;
          color: #e5c356;
          top: 0;
          left: 0;
          position: absolute;
          align-items: center;
          font-family: Cinzel, serif;
          backdrop-filter: blur(1.3px);
        }
      }
    }
    .balances {
      div.token {
        display: grid;
        grid-template-columns: 46px 1fr;
        grid-gap: 1px;
        align-items: center;
        justify-items: flex-start;
        font-size: 1.2rem;
        /* margin-top: 20px; */
        &:before {
          background-size: contain;
          content: "";
          display: inline-block;
          width: 46px;
          height: 46px;
          line-height: 0;
          margin-top: -8px;
          padding: 0;
          top: 7px;
          position: relative;
          margin-right: 4px;
        }
        p {
          margin: 0;
          span {
            font-size: 0.7rem;
            display: block;
            margin: 2px 0 0;
            color: #ffffff85;
          }
        }
      }
      .dcau {
        &:before {
          background: url("../../assets/ui/dcau.png") no-repeat left center;
        }
      }
      .dcar {
        &:before {
          background: url("../../assets/ui/dcar.png") no-repeat left center;
        }
      }

      .copper {
        &:before {
          background: url("../../assets/ui/copper.png") no-repeat left center;
          width: 40px !important;
          height: 40px !important;
        }
      }
    }
    .token-container:hover {
      .links p {
        display: block;
      }
    }
    .links p {
      margin-bottom: 0;
      display: none;
      position: absolute;
      background: #000;
      padding: 5px 10px;
      box-shadow: 0 0 4px 1px #0000008f;
      z-index: 5;
      border-radius: 5px;
      bottom: -45px;
      &:before {
        content: "";
        width: 100%;
        position: absolute;
        display: block;
        background: transparent;
        top: -20px;
        height: 20px;
        left: 0;
      }
      a {
        font-size: 0.8rem;
        text-decoration: none;
        cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
          auto;
      }
      button {
        font-size: 0.8rem;
        text-decoration: none;
        background: none;
        color: #e5c356;
        border: none;
        margin: 0;
        padding: 0;
        cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
          auto;

        span.metamask {
          background: url("../../assets/ui/metamask.svg") no-repeat center
            center;
          width: 20px;
          height: 20px;
          display: inline-block;
          text-indent: -9999px;
          top: -2px;
          position: relative;
        }
      }
    }
    .inner-grid {
      .transactions-grid {
        text-align: center;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-gap: 1rem;
      }

      .teller {
        .bond-details {
          margin: 0 20px;
          h4 {
            margin-bottom: 0;
            text-align: left;
            border-bottom: 1px solid #ffffff52;
            padding-bottom: 0.5rem;
            .status {
              font-size: 0.8rem;
              text-transform: capitalize;
              font-family: "Lato", sans-serif;
              padding: 0 5px 1px;
              border-radius: 4px;
              margin: 0 0 0 5px;
              color: #fff;
              .pulsating-circle {
                border-radius: 100%;
                padding: 0;
                width: 8px;
                height: 8px;
                display: inline-block;
                margin: 0 5px 0 0;
                animation: pulse-dot 1.25s
                  cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
              }
              &.live {
                background-color: rgb(0 255 0 / 42%);
                .pulsating-circle {
                  background-color: #00ff00;
                }
              }
              &.closed {
                background-color: rgb(255 0 0 / 42%);
                .pulsating-circle {
                  background-color: #ff0000;
                }
              }
              &.upcoming {
                .pulsating-circle {
                  background-color: #ff0000;
                }
              }
            }
          }
          .details {
            margin: 20px 0 0;
            .row {
              display: grid;
              grid-template-columns: 55% 1fr;
              align-items: center;
              grid-gap: 1rem;
              margin: 0 0;
              padding: 10px 5px;
              background-color: #ffffff10;
              &:nth-child(2n) {
                background-color: #ffffff05;
              }
              overflow: hidden;
            }
          }
          .grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1rem;
            @media only screen and (max-width: 576px) {
              grid-template-columns: 1fr;
            }
          }
          .interact {
            .purchase {
              .grid {
                margin: 20px auto 10px;
                justify-items: center;
                width: 100%;
                align-items: center;
                grid-gap: 0;
                grid-template-columns: 80px 70px 1fr;
              }
              input {
                width: 50px;
                text-align: center;
                margin: 0 10px;
              }
              p {
                margin: 0;
                font-size: 0.9rem;
                color: #ffffffa8;
              }
            }
            .claim {
              .reward {
                font-size: 1.8rem;
                font-weight: bold;
                margin: 25px auto 20px;
                &:before {
                  background: url("../../assets/ui/dcar.png") no-repeat left
                    center;
                  background-size: contain;
                  content: "";
                  display: inline-block;
                  width: 30px;
                  height: 30px;
                  line-height: 0;
                  margin-top: -8px;
                  padding: 0;
                  top: 7px;
                  position: relative;
                  margin-right: 4px;
                }
              }

              .claim-info {
                font-size: 0.85rem;
                padding-top: 0px;
                font-style: italic;
              }
            }
          }
        }
        .vaults {
          margin: 0 20px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 1rem;
          position: relative;
          @media only screen and (max-width: 576px) {
            grid-template-columns: 1fr;
            margin: 0;
          }
          h4 {
            margin-bottom: 0;
            text-align: left;
            border-bottom: 1px solid #ffffff52;
            padding-bottom: 0.5rem;
            .status {
              font-size: 0.8rem;
              text-transform: capitalize;
              font-family: "Lato", sans-serif;
              padding: 0 5px 1px;
              border-radius: 4px;
              margin: 0 0 0 5px;
              color: #fff;
              .pulsating-circle {
                border-radius: 100%;
                padding: 0;
                width: 8px;
                height: 8px;
                display: inline-block;
                margin: 0 5px 0 0;
                animation: pulse-dot 1.25s
                  cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
              }
              &.live {
                background-color: rgb(0 255 0 / 42%);
                .pulsating-circle {
                  background-color: #00ff00;
                }
              }
              &.closed {
                background-color: rgb(255 0 0 / 42%);
                .pulsating-circle {
                  background-color: #ff0000;
                }
              }
              &.upcoming {
                .pulsating-circle {
                  background-color: #ff0000;
                }
              }
            }
          }
          .details {
            margin: 20px 0 0;
            .row {
              display: grid;
              grid-template-columns: 55% 1fr;
              align-items: center;
              grid-gap: 1rem;
              margin: 0 0;
              padding: 10px 5px;
              background-color: #ffffff10;
              &:nth-child(2n) {
                background-color: #ffffff05;
              }
              overflow: hidden;
            }
          }
          .grid-rewards {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            justify-items: center;
            margin: 1rem 0;
            .token-balance {
              p {
                font-size: 0.9rem;
              }
            }
          }
          .locked-details {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            justify-items: center;
            margin: 0 0 0.5rem;
            padding: 0.5rem 0;
            &:last-child {
              margin-bottom: 0;
            }
            &:nth-child(even) {
              background-color: #f2f2f217; /* or any color for odd divs */
            }

            p {
              margin: 0;
              span {
                color: #ffffff6b;
                text-transform: uppercase;
                font-size: 0.8rem;
                margin: 0;
                display: block;
              }
            }
          }
          .message {
            color: #ffffff6b;
            text-transform: uppercase;
            font-size: 0.8rem;
            margin: 0;
          }
          .brown-box.main {
            box-shadow: none;
            background: rgba(57, 56, 54, 0.4196078431);
            padding: 0 0 15px 0;
            border-radius: 5px;
            margin-bottom: 1rem;
            overflow: hidden;
            font-size: 1.3rem;
            @media only screen and (max-width: 576px) {
              font-size: 1rem;
            }
            h4 {
              font-family: "Lato";
              color: rgba(255, 255, 255, 0.4901960784);
              border-bottom: none;
              background: #3f3b39;
              padding: 10px 0;
              font-size: 0.8rem;
              text-align: center;
              margin-bottom: 15px;
            }
          }
          .grid-meta {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            grid-gap: 0.5rem;
            @media only screen and (max-width: 576px) {
              grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
            }
            &.one-col {
              grid-template-columns: 1fr;
            }
            .brown-box {
              box-shadow: none;
              background: rgba(57, 56, 54, 0.4196078431);
              padding: 0 0 15px 0;
              border-radius: 5px;
              margin-bottom: 0;
              overflow: hidden;
              font-size: 1.3rem;
              @media only screen and (max-width: 576px) {
                font-size: 1rem;
              }
              h4 {
                font-family: "Lato";
                color: rgba(255, 255, 255, 0.4901960784);
                border-bottom: none;
                background: #3f3b39;
                padding: 10px 0;
                font-size: 0.8rem;
                text-align: center;
                margin-bottom: 15px;
              }
              &.locked {
                span:before {
                  background: url("../../assets/ui/locked.svg") no-repeat center
                    center;
                  content: "";
                  position: relative;
                  display: inline-block;
                  width: 15px;
                  height: 15px;
                  background-size: cover;
                }
              }
            }
          }
          .grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1rem;
            @media only screen and (max-width: 576px) {
              grid-template-columns: 1fr;
            }
          }
          .grid-amount {
            display: grid;
            grid-template-columns: 1fr 20% 15% 15%;
            grid-gap: 1rem;
            align-items: center;
            margin-bottom: 1rem;
            .apr-value {
              text-align: right;
              span {
                display: grid;
                font-size: 0.9rem;
                color: #fff;
                height: 30px;
                align-items: center;
                background: transparent;
              }
            }
            @media only screen and (max-width: 576px) {
              grid-template-columns: 1fr 30%;
            }
            &.single {
              grid-template-columns: 1fr;
            }
          }
          .interact {
            .purchase {
              .grid {
                margin: 20px auto 10px;
                justify-items: center;
                width: 100%;
                align-items: center;
                grid-gap: 0;
                grid-template-columns: 80px 70px 1fr;
              }
              input {
                width: 50px;
                text-align: center;
                margin: 0 10px;
              }
              p {
                margin: 0;
                font-size: 0.9rem;
                color: #ffffffa8;
              }
            }
            .claim {
              .reward {
                font-size: 1.8rem;
                font-weight: bold;
                margin: 25px auto 20px;
                &:before {
                  background: url("../../assets/ui/dcar.png") no-repeat left
                    center;
                  background-size: contain;
                  content: "";
                  display: inline-block;
                  width: 30px;
                  height: 30px;
                  line-height: 0;
                  margin-top: -8px;
                  padding: 0;
                  top: 7px;
                  position: relative;
                  margin-right: 4px;
                }
              }

              .claim-info {
                font-size: 0.85rem;
                padding-top: 0px;
                font-style: italic;
              }
            }
          }

          .tabs {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 2px;
            overflow: hidden;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.4392156863);

            button {
              background-color: #171312;
              border: none;
              box-shadow: none;
              padding: 10px 0;
              color: #fff;
              font-family: "IM Fell English", serif;
              text-transform: uppercase;
              border-bottom: 2px solid #181716;
              cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
                auto;
              &.active {
                border-bottom: 2px #e5c356 solid;
              }
            }
          }
          .brown-box {
            background: #171312;
            box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.4392156863);
            padding: 15px;
            margin-bottom: 1rem;
            &.hide {
              display: none;
            }
            h4 {
              margin-top: 0;
              margin-bottom: 0.5rem;
            }
            p.quick-balance {
              font-size: 0.7rem;
              text-transform: uppercase;
              font-family: Lato;
              text-align: right;
              margin: 0 0 5px;
              color: #b2b1b1;
              cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
                auto;
            }
            .stake-amount {
              position: relative;
              input {
                width: calc(100% - 10px);
                padding: 5px;
              }
              &.disabled {
                &:after {
                  opacity: 0.2;
                }
              }
              &:after {
                content: "DCAR";
                background: white;
                position: absolute;
                right: -3px;
                height: 27px;
                font-weight: bold;
                display: grid;
                color: #727272;
                border-left: 1px solid #727272;
                top: 1px;
                padding: 0 7px 0 7px;
                align-items: center;
              }
            }

            .lock-period {
              /* display: grid;
              grid-template-columns: 50px 1fr;
              grid-gap: 5px; */
              margin-bottom: 0;
              height: 306px;
              overflow-y: scroll;
              &::-webkit-scrollbar-track {
                /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
                background-color: transparent;
              }

              &::-webkit-scrollbar {
                width: 4px;
                background-color: transparent;
              }

              &::-webkit-scrollbar-thumb {
                background-color: #000000f2;
              }
              label {
                font-size: 0.8rem;
                font-family: "IM Fell English", serif;
              }
              .options-grid {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
                margin-bottom: 1rem;
                button {
                  background: #443c3b;
                  border: none;
                  color: #fff;
                  padding: 10px 0;
                  display: block;
                  font-size: 0.7rem;
                  text-transform: uppercase;
                  font-family: "Lato", sans-serif;
                  cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
                    auto;
                  &.active {
                    background: #382b2a;
                    color: #e6c356;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .close-button {
    position: absolute;
    top: -$popup-border-size;
    right: -$popup-border-size;
  }
}
.dropdown {
  width: 100%;
  display: inline-block;
  background-color: #fff;
  box-shadow: 0 0 2px rgb(204, 204, 204);
  transition: all 0.5s ease;
  position: relative;
  font-size: 14px;
  color: #474747;
  height: 28px;
  text-align: left;

  .select {
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    display: block;
    padding: 5px;
    text-transform: uppercase;

    > i {
      cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
        auto;
      transition: all 0.3s ease-in-out;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 10px solid #888;
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
    }
  }

  &:hover {
    box-shadow: 0 0 4px rgb(204, 204, 204);
  }

  &:active {
    background-color: #f8f8f8;
  }

  &.active:hover,
  &.active {
    box-shadow: 0 0 4px rgb(204, 204, 204);
    border-radius: 2px 2px 0 0;
    background-color: #f8f8f8;
  }

  &.active .select > i {
    transform: rotate(-90deg);
  }

  .dropdown-menu {
    position: absolute;
    background-color: #fff;
    width: 100%;
    left: 0;
    margin-top: 1px;
    box-shadow: 0 1px 2px rgb(204, 204, 204);
    border-radius: 0 1px 2px 2px;
    overflow: hidden;
    display: block;
    overflow-y: auto;
    z-index: 9;
    padding: 0;
    list-style: none;

    li {
      padding: 10px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: #f2f2f2;
      }

      &:active {
        background-color: #e2e2e2;
      }
    }
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
@keyframes changeEmotion {
  0% {
    background-position: center right;
  }
  50% {
    background-position: center left;
  }
  100% {
    background-position: center right;
  }
}
</style>
