<template>
  <button class="round-button" :class="size">
    <slot />
    <span class="loader"></span>
  </button>
</template>

<script>
export default {
  name: "RoundButton",
  props: {
    size: {
      // small || extra-small || default large
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

$mobileWidth: 30px;
$extraSmall: 25px;
$small: 30px;

.round-button {
  display: block;
  background: url("../../assets/ui/round-button-sprite.png") no-repeat center
    top;
  background-size: 100%;
  width: 50px;
  height: 50px;
  font-size: 2rem;
  color: #ffffffd6;
  &.small {
    width: $small;
    height: $small;
    font-size: 1.2rem;
    line-height: 1.4rem;

    &:hover {
      background-position: center calc(-1 * $small);
    }
    &:active {
      background-position: center calc(-2 * $small);
    }
    &:disabled {
      background-position: center bottom;
    }
    &.inlineright {
      display: inline !important;
      left: 4px;
    }
    &.inlineleft {
      display: inline !important;
      margin-right: 4px;
    }
  }
  &.extra-small {
    width: $extraSmall;
    height: $extraSmall;
    font-size: 0.8rem;
    &:hover {
      background-position: center $extraSmall;
    }
    &:active {
      background-position: center calc($extraSmall * 2);
    }
  }

  @media only screen and (max-width: 576px) {
    width: $mobileWidth;
    height: $mobileWidth;
  }
  box-shadow: none;
  border: none;
  margin: 0 auto;
  position: relative;
  color: #e1f1ff;
  text-transform: uppercase;
  font-size: 1.1rem;
  text-shadow: 1px 1px 2px #000;
  transition: transform 0.1s ease-in-out;
  /* &:after {
    content: "";
    background: url("https://cdn.dragoncrypto.io/blue-button-medium.webp") no-repeat center top;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    background-size: 100%;
    left: 0;
    position: absolute;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    z-index: 0;
  } */
  &:hover {
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    background-position: center -50px;
    @media only screen and (max-width: 576px) {
      background-position: center calc(-1 * $mobileWidth);
    }
    &:after {
      opacity: 0;
    }
    &:disabled {
      cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
        auto;
      background-position: center bottom;
      cursor: not-allowed;
    }
  }
  &:active {
    background-position: center -140px;
    @media only screen and (max-width: 576px) {
      background-position: center calc(-2 * calc($mobileWidth / 5.0857142857));
    }
    transform: scale(0.99);
  }
  &:disabled {
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    background-position: center bottom;
    cursor: not-allowed;
  }
  .loader {
    display: none;
  }
  &.loading {
    .loader {
      display: block;
    }
  }
}
.loader {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  right: 4rem;
  @media only screen and (max-width: 576px) {
    left: 46%;
    transform: translateX(-50%);
    top: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 20px;
    border: 5px solid rgba(255, 255, 255, 0.379);
    border-top-color: #e0c95e;
    animation: loading 2s linear infinite;
  }
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
