// initial state
import { useAvvy } from "../../composables/avvy";
const { resolveAddressToName } = useAvvy();

const state = () => ({
  addressToNames: {},
});

// mutations
const mutations = {
  getOrSetAvvyName(state, address) {
    if (state.addressToNames[address]) {
      return state.addressToNames[address];
    }

    resolveAddressToName(address).then((name) => {
      state.addressToNames[address] = name;
    });
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  // actions,
  mutations,
};
