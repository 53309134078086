<template>
  <!-- <div :class="['stash-inventory wrapper', isStashVisible]"> -->
  <BasicPopup
    :class="[isRankingsVisible, 'rankings']"
    :header="`Top Rankings for ${selectedOptionLabel}`"
    :popupType="'rankings'"
  >
    <div :class="['rankings-panel']">
      <div class="close-button button-small" @click="closeRankings">
        <span>x</span>
      </div>
    </div>
    <div class="filters">
      <div class="filters-content">
        <select
          v-model="filterType"
          @change="filterRankings"
          :class="['filter-type', filterType]"
        >
          <template v-for="(option, index) in options">
            <option v-if="option.value" :value="option.value" :key="index">
              {{ option.label }}
            </option>
            <option v-else disabled :key="`${index}-disabled`">
              {{ option.label }}
            </option>
          </template>
        </select>
      </div>
    </div>
    <div class="items">
      <h3 v-if="currentRankings.length === 0">No Rankings Listed</h3>
      <table v-else class="rankings-container">
        <tr>
          <th>Rank</th>
          <th>Hero</th>
          <th class="stat-header">{{ selectedOptionLabel }}</th>
          <th>Owner</th>
        </tr>
        <tr class="item" v-for="(item, index) in currentRankings" :key="index">
          <td class="item-rank">
            <span>{{ index + 1 }}</span>
          </td>
          <td class="hero-id">
            <div>
              <img
                :src="
                  'https://ik.imagekit.io/dcg/' + item.imageName + '?tr=w-166'
                "
                :alt="'#' + item.id"
              />
              <span>#{{ item.id }}</span>
            </div>
          </td>
          <td class="item-stat">
            <span>{{ item.stat ? Math.trunc(item.stat * 100) / 100 : 0 }}</span>
          </td>
          <td class="owner-address">
            <span>{{ avvyNameForAddress(item.ownerAddress) }}</span>
          </td>
        </tr>
      </table>
    </div>
  </BasicPopup>
</template>
<script>
import { useStore } from "vuex";
import { ref, onMounted, computed } from "vue";
import BasicPopup from "../Popup/BasicPopup.vue";

export default {
  name: "Rankings",
  components: {
    BasicPopup,
  },
  props: {},
  setup() {
    const store = useStore();
    const sessionId = ref(null);
    const filterType = ref("level");

    const options = [
      { label: "──── CHARACTER RANKINGS ────", value: null },
      { label: "Level", value: "level" },
      { label: "Kills", value: "kills" },
      { label: "Deaths", value: "deaths" },
      { label: "──── STATS RANKINGS ────", value: null },
      { label: "Attack", value: "attack" },
      { label: "Defense", value: "defense" },
      { label: "Magic", value: "magic" },
      { label: "Gathering", value: "gathering" },
      { label: "Crafting", value: "crafting" },
      { label: "Speed", value: "speed" },
      { label: "Luck", value: "luck" },
      { label: "Endurance", value: "endurance" },
      { label: "──── MASTERY RANKINGS ────", value: null },
      { label: "Ranged Mastery", value: "ranged" },
      { label: "Blade Mastery", value: "blade" },
      { label: "Axe Mastery", value: "axe" },
      { label: "Heavy Mastery", value: "heavy" },
      { label: "Close Combat Mastery", value: "close Combat" },
      { label: "Magic Mastery", value: "magicm" },
      { label: "──── GUILD RANKINGS ────", value: null },
      { label: "Crafter's IP", value: "influencePoints.craftersGuild" },
      { label: "Defender's IP", value: "influencePoints.defendersGuild" },
      { label: "Max Quest Points", value: "maxContractQuestPoints" },
    ];

    onMounted(async () => {
      sessionId.value = localStorage.getItem("sessionId");
      store.commit("rankings/setFilterTypeState", filterType.value);
    });

    /**
     * Methods
     */
    const closeRankings = () => {
      store.commit("rankings/setRankingsOpenStatusState", false);
    };
    const filterRankings = () => {
      store.commit("rankings/setFilterTypeState", filterType.value);
    };

    /**
     * Computed
     */
    const isRankingsVisible = computed(() => {
      return store.state.rankings.rankingsOpenStatus ? "show" : "";
    });

    const currentRankings = computed(() => {
      return store.state.rankings.currentRankings;
    });
    const selectedOptionLabel = computed(() => {
      // Find the selected option in the options list
      const selectedOption = options.find(
        (option) => option.value === filterType.value
      );

      // Return the label of the selected option
      return selectedOption ? selectedOption.label : "";
    });

    const avvyNameForAddress = (address) => {
      store.commit("avvynames/getOrSetAvvyName", address);
      return store.state.avvynames.addressToNames[address];
    };

    return {
      // data
      filterType,
      options,
      // methods
      filterRankings,
      closeRankings,
      avvyNameForAddress,
      // Computed
      currentRankings,
      isRankingsVisible,
      selectedOptionLabel,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.rankings {
  width: calc(388px - $popup-border-size - $popup-border-size);
  height: calc(450px - $popup-border-size - $popup-border-size);
  display: none;
  font-family: "Lato", serif;
  z-index: 4;
  &.show {
    display: block;
  }
  .close-button {
    top: -80px;
    right: -80px;
    position: absolute;
  }

  .filters select {
    width: 100%;
    margin-bottom: 16px;
    padding: 8px;
    background: #242424;
    color: #d79867;
    border: 1px solid #d79867;
    &:active {
      outline: none;
    }
  }

  .items {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    .stat-header {
      min-width: 183px;
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 10px;
    }
  }

  .rankings-container {
    width: 100%;
    border-collapse: collapse;

    tr:nth-child(2),
    tr:nth-child(3),
    tr:nth-child(4) {
      td.item-rank {
        span {
          width: 45px;
          height: 49px;
          display: inline-block;
          background-size: cover;
          background-position: center center;
          text-indent: -9999px;
        }
      }
    }
    tr:nth-child(2) {
      td.item-rank span {
        background-image: url("../../assets/ui/first.svg");
      }
    }
    tr:nth-child(3) {
      td.item-rank span {
        background-image: url("../../assets/ui/second.svg");
      }
    }
    tr:nth-child(4) {
      td.item-rank span {
        background-image: url("../../assets/ui/third.svg");
      }
    }

    td,
    th {
      padding: 4px;
      border-bottom: 1px solid #d79867;
    }
    .item {
      width: 100%;
      margin-bottom: 5px;

      div {
        padding: 5px;
      }

      .item-rank {
        font-size: 180%;
        font-family: "IM Fell English", serif;
        font-weight: bold;
      }

      .hero-id {
        div {
          display: flex;
          flex-direction: column;
          text-align: center;

          img {
            max-width: 96px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
</style>
