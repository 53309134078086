// import constants from "../consts/constants";

export default {
  generateCharacterFromData: function (index, id, characterData) {
    return {
      id: index,
      number: id,
      image:
        "https://ik.imagekit.io/dcg/" + characterData.imageName + "?tr=w-224",
      isSelected: false,
      isTakingDamage: false,
      damageTaken: 0,
      isHealing: false,
      healAmount: 0,
      isDead: false,
      equipped: {},
      quests: [],
      rank: characterData.rank,
      stats: characterData,
    };
  },
};
