<template>
  <button class="blue-button" :class="size" :disabled="isLoading">
    <slot /><InlineLoading v-if="isLoading" />
    <span class="loader"></span>
  </button>
</template>

<script>
import InlineLoading from "../LoadingComponents/InlineLoading.vue";

export default {
  name: "BlueButton",
  components: {
    InlineLoading,
  },
  props: {
    size: {
      // small || extra-small || default large
      type: String,
    },
    isLoading: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

$mobileWidth: 275px;
$extraSmall: 150px;
$small: 200px;

.blue-button {
  display: block;
  background: url("https://cdn.dragoncrypto.io/assets/blue-button-medium.webp")
    no-repeat center top;
  background-size: 100%;
  width: 356px;
  height: 70px;
  &.small {
    width: $small;
    height: calc($small / 5.0857142857);
    font-size: 0.9rem;
    line-height: 1.4rem;

    &:hover {
      background-position: center calc(-1 * calc($small / 5.0857142857));
    }
    &:active {
      background-position: center calc(-2 * calc($small / 5.0857142857));
    }
  }
  &.extra-small {
    width: $extraSmall;
    height: calc($extraSmall / 5.0857142857);
    font-size: 0.8rem;
    &:hover {
      background-position: center calc(-1 * calc($extraSmall / 5.0857142857));
    }
    &:active {
      background-position: center calc(-2 * calc($extraSmall / 5.0857142857));
    }
  }

  @media only screen and (max-width: 576px) {
    width: $mobileWidth;
    height: calc($mobileWidth / 5.0857142857);
  }
  box-shadow: none;
  border: none;
  margin: 0 auto;
  position: relative;
  color: #e1f1ff;
  text-transform: uppercase;
  font-size: 1.1rem;
  text-shadow: 1px 1px 2px #000;
  transition: transform 0.1s ease-in-out;
  /* &:after {
    content: "";
    background: url("https://cdn.dragoncrypto.io/blue-button-medium.webp") no-repeat center top;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    background-size: 100%;
    left: 0;
    position: absolute;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    z-index: 0;
  } */
  &:hover {
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    background-position: center -70px;
    @media only screen and (max-width: 576px) {
      background-position: center calc(-1 * calc($mobileWidth / 5.0857142857));
    }
    &:after {
      opacity: 0;
    }
    &:disabled {
      filter: grayscale(100%);
      cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
        auto;
      background-position: center bottom;
      cursor: not-allowed;
    }
  }
  &:active {
    background-position: center -140px;
    @media only screen and (max-width: 576px) {
      background-position: center calc(-2 * calc($mobileWidth / 5.0857142857));
    }
    transform: scale(0.99);
  }
  &:disabled {
    filter: grayscale(100%);
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    background-position: center bottom;
    cursor: not-allowed;
  }
  .loader {
    display: none;
  }
  &.loading {
    .loader {
      display: block;
    }
  }
}
.loader {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  right: 4rem;
  @media only screen and (max-width: 576px) {
    left: 46%;
    transform: translateX(-50%);
    top: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 20px;
    border: 5px solid rgba(255, 255, 255, 0.379);
    border-top-color: #e0c95e;
    animation: loading 2s linear infinite;
  }
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
