<template>
  <div class="skeleton-box" v-if="isLoading">
    <div class="square loading" style="width: 70px; height: 70px"></div>
    <div class="details">
      <div
        class="rectangle loading"
        style="width: 100%; height: 20px; margin-bottom: 10px"
      ></div>
      <div class="rectangle loading" style="width: 100%; height: 20px"></div>
    </div>
  </div>
  <template v-else>
    <div class="market-item" v-if="item">
      <div class="product-info">
        <ItemTile
          :source="'inv'"
          :size="'large'"
          :item="item"
          :isMarketplace="true"
        />
        <div class="details">
          <h3 :class="item.rarity.toLowerCase()">
            {{ item.name }}
          </h3>
          <div class="price-details">
            <span
              :class="[
                'price',
                currentCategory !== 'equipment' ? 'DCAR' : 'DCAU',
              ]"
              >{{ item.priceInDCAU }}
              <span>{{
                currentCategory !== "equipment" ? "DCAR" : "DCAU"
              }}</span></span
            >
            <span v-if="!isLoadingDollarPrice" class="dollar-value"
              >(${{ currentPriceDollar }})</span
            >
            <SkeletalLoading v-else width="40" />
          </div>
          <DialogButton
            v-if="
              !isDCAUApproved && currentCategory === 'equipment' && !isOwner
            "
            :buttonSize="'med'"
            :buttonText="'Approve DCAU'"
            @click="approveDCAU"
            :isLoading="isApprovingDCAU"
          />
          <DialogButton
            v-else-if="
              !isDCARApproved && currentCategory !== 'equipment' && !isOwner
            "
            :buttonSize="'med'"
            :buttonText="'Approve DCAR'"
            @click="approveDCAR"
            :isLoading="isApprovingDCAR"
          />
          <template v-else>
            <DialogButton
              v-if="!isOwner"
              :buttonSize="'med'"
              :buttonText="'Buy'"
              @click="processBuy"
              :isLoading="isBuying"
            />
            <DialogButton
              v-else
              :buttonSize="'med'"
              :buttonText="'Cancel Listing'"
              @click="cancelListing"
              :isLoading="isRemoving"
            />
          </template>
        </div>
      </div>
      <div class="product-meta">
        <span v-if="!isOwner" class="address">{{ sellerAddress }}</span>
        <span class="listed-time">{{ fromTime }}</span>
      </div>
    </div>
  </template>
</template>
<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Constants from "../../consts/constants";
import DialogButton from "../DialogButton.vue";
import SkeletalLoading from "../LoadingComponents/SkeletalLoading.vue";
import ItemTile from "./ItemTile.vue";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useMixpanel } from "../../composables/mixpanel";
import { usePrice } from "../../composables/price";
import { parseError } from "../../utils/helpers";

import { ethers } from "ethers";
import { useUser } from "../../composables/user";
import {
  getDCARContract,
  getDCAUContract,
  getLoadMarketplaceContract,
} from "../../utils/getContract";

dayjs.extend(relativeTime);

export default {
  name: "ListedItem",

  props: {
    item: {},
    isOwner: { type: Boolean, default: false },
  },
  components: {
    DialogButton,
    ItemTile,
    SkeletalLoading,
  },
  setup(props) {
    /**
     * Data
     */
    const marketItem = ref(null);
    const store = useStore();
    const isLoading = ref(false);
    const isBuying = ref(false);
    const isLoadingDollarPrice = ref(true);
    const isDCAUApproved = ref(false);
    const isDCARApproved = ref(false);
    const isApprovingDCAU = ref(false);
    const isApprovingDCAR = ref(false);
    const isRemoving = ref(false);
    const dcauPerDollar = ref(0);
    const dcarPerDollar = ref(0);
    const currentPriceDollar = ref(0);
    const { address, signer } = useUser();
    const { getDCAUPricePerDollar, getDCARPricePerDollar, updateBalances } =
      usePrice();

    const DCAUContract = getDCAUContract(signer.value);
    const DCARContract = getDCARContract(signer.value);
    const MarketplaceContract = getLoadMarketplaceContract(signer.value);

    // Mixpanel Tracking
    const { trackEvent } = useMixpanel();

    onMounted(async () => {
      isLoading.value = false;
      await checkIsDCAUApproved();
      await checkIsDCARApproved();
      marketItem.value = props.item;
      isLoadingDollarPrice.value = true;
      dcauPerDollar.value = await getDCAUPricePerDollar();
      dcarPerDollar.value = await getDCARPricePerDollar();
      await updateCurrentPriceDollar();
      isLoadingDollarPrice.value = false;
    });

    /**
     * Methods
     */
    const updateCurrentPriceDollar = async () => {
      await delay(250);
      // console.log("currentPrice", props.item.priceInDCAU);
      // console.log("dcauPerDollar.value", dcauPerDollar.value);
      // console.log("dcarPerDollar.value", dcarPerDollar.value);
      if (currentCategory.value === "equipment") {
        currentPriceDollar.value = (
          props.item.priceInDCAU / dcauPerDollar.value
        ).toFixed(2);
      } else if (
        currentCategory.value === "resource" ||
        currentCategory.value === "consumable"
      ) {
        currentPriceDollar.value = (
          props.item.priceInDCAU / dcarPerDollar.value
        ).toFixed(2);
      }
    };
    // Helper method to delay stuff
    const delay = async (time) => new Promise((res) => setTimeout(res, time));

    const playOnBuy = () => {
      const sound = new Audio(
        "https://cdn.dragoncrypto.io/sound/cash-register.mp3"
      );
      sound.volume = store.state.soundVolume;
      sound.play();
    };

    const checkIsDCAUApproved = async () => {
      let allowanceMarketplace = await DCAUContract.allowance(
        address.value,
        MarketplaceContract.address
      );
      allowanceMarketplace = ethers.utils.formatEther(allowanceMarketplace);
      if (
        parseFloat(allowanceMarketplace) >= parseFloat(props.item.priceInDCAU)
      ) {
        isDCAUApproved.value = true;
        // store.commit("marketplace/setIsDCAUApproved", false);
      } else {
        isDCAUApproved.value = false;
        // store.commit("marketplace/setIsDCAUApproved", true);
      }
    };

    const avvyNameForAddress = (address) => {
      store.commit("avvynames/getOrSetAvvyName", address);
      return store.state.avvynames.addressToNames[address];
    };

    const checkIsDCARApproved = async () => {
      let allowanceMarketplace = await DCARContract.allowance(
        address.value,
        MarketplaceContract.address
      );
      allowanceMarketplace = ethers.utils.formatEther(allowanceMarketplace);

      if (
        parseFloat(allowanceMarketplace) >= parseFloat(props.item.priceInDCAU) // The market data returns dcar price also as priceindcau. TODO need to fix this later
      ) {
        isDCARApproved.value = true;
        // store.commit("marketplace/setIsDCARApproved", false);
      } else {
        isDCARApproved.value = false;
        // store.commit("marketplace/setIsDCARApproved", true);
      }
    };

    const approveDCAU = async () => {
      isApprovingDCAU.value = true;
      try {
        let txApproveMarketplaceSpend = await DCAUContract.approve(
          MarketplaceContract.address,
          ethers.utils.parseEther("1000000000")
        );
        const receipt = await txApproveMarketplaceSpend.wait();
        if (receipt.status === 1) {
          const allowanceMarketplace = ethers.utils.formatEther(
            receipt.events[0].args.value
          );
          isDCAUApproved.value =
            parseFloat(allowanceMarketplace) >=
            parseFloat(props.item.priceInDCAU);
        }
      } catch (error) {
        store.commit("setNotification", parseError(error));
      }
      isApprovingDCAU.value = false;
    };

    const approveDCAR = async () => {
      isApprovingDCAR.value = true;
      try {
        let txApproveMarketplaceSpend = await DCARContract.approve(
          MarketplaceContract.address,
          ethers.utils.parseEther("1000000000")
        );
        const receipt = await txApproveMarketplaceSpend.wait();
        if (receipt.status === 1) {
          const allowanceMarketplace = ethers.utils.formatEther(
            receipt.events[0].args.value
          );
          isDCARApproved.value =
            parseFloat(allowanceMarketplace) >=
            parseFloat(props.item.priceInDCAU);
        }
      } catch (error) {
        store.commit("setNotification", parseError(error));
      }
      isApprovingDCAR.value = false;
    };

    const buyEquipment = async (marketItemId, itemId, saleIndex) => {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const account = accounts.length > 0 ? accounts[0] : "";
      const sessionId = localStorage.getItem("sessionId");

      if (!account || !sessionId) {
        return;
      }

      try {
        // Doing the contract transaction
        // Executing Marketplace Listing Transaction
        const tx = await MarketplaceContract.buyNFT(
          ethers.utils.formatUnits(saleIndex, 0)
        );
        const receipt = await tx.wait();
        // If transaction successful, update game data
        if (receipt.status === 1) {
          updateBalances(); //Asynchronously updating token  balances in game
          const response = await fetch(
            Constants.apiUrl +
              "marketplace/buy-item?type=" +
              store.state.marketFilter +
              "&page=1" +
              "&sortBy=createdAt&sortOrder=desc",
            {
              method: "POST",
              body: JSON.stringify({
                sessionId,
                account,
                marketItemId,
                saleIndex,
                blockNumber: receipt.blockNumber,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const result = await response.json();
          store.commit("marketplace/setMarketData", result.marketplaceItems);
          store.commit("setInventory", result.inventory);
          playOnBuy(); // only on successful buy
          // Mixpanel Tracking
          trackEvent("Marketplace - Buy Item", {
            itemId: props.item.itemId,
            type: props.item.itemType,
            name: props.item.name,
            rarity: props.item.rarity,
            quantity: props.item.quantity,
            price: props.item.priceInDCAU,
            seller: props.item.ownerAddress,
          });

          store.commit("setRemoveTooltip");
        }
      } catch (error) {
        store.commit("setNotification", parseError(error));
      }
    };

    const cancelEquipment = async (marketItemId, saleIndex) => {
      isRemoving.value = true;
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const account = accounts.length > 0 ? accounts[0] : "";
      const sessionId = localStorage.getItem("sessionId");

      if (!account || !sessionId) {
        return;
      }

      try {
        // Doing the contract transaction
        // Executing Marketplace Cancel Transaction
        const tx = await MarketplaceContract.removeNFT(
          ethers.utils.formatUnits(saleIndex, 0)
        );
        const receipt = await tx.wait();
        // If transaction successful, update game data
        if (receipt.status === 1) {
          const response = await fetch(
            Constants.apiUrl +
              "marketplace/cancel-item?type=" +
              store.state.marketFilter +
              "&page=1" +
              "&sortBy=createdAt&sortOrder=desc",
            {
              method: "POST",
              body: JSON.stringify({
                sessionId,
                account,
                marketItemId,
                blockNumber: receipt.blockNumber,
                saleIndex,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const result = await response.json();
          store.commit("marketplace/setMarketData", result.marketplaceItems);
          await store.dispatch("marketplace/updateMyListedItems");

          // store.dispatch("updateInventory");
          store.commit("setInventory", result.inventory);

          // Mixpanel Tracking
          trackEvent("Marketplace - Cancelled Item Listing", {
            itemId: props.item.itemId,
            type: props.item.itemType,
            name: props.item.name,
            rarity: props.item.rarity,
            quantity: props.item.quantity,
            price: props.item.priceInDCAU,
            seller: props.item.ownerAddress,
          });

          store.commit("setRemoveTooltip");
        }
        isRemoving.value = false;
      } catch (error) {
        store.commit("setNotification", parseError(error));
        isRemoving.value = true;
      }
    };

    const processBuy = async () => {
      isBuying.value = true;
      const marketItemId = props.item._id;
      await buyEquipment(marketItemId, props.item.itemId, props.item.saleIndex);

      isBuying.value = false;
    };
    const cancelListing = async () => {
      isBuying.value = true;
      const marketItemId = props.item._id;
      await cancelEquipment(marketItemId, props.item.saleIndex);
      isBuying.value = false;
    };
    /**
     * Computed Methods
     */
    // const isDCAUApproved = computed(() => {
    //   return store.state.marketplace.isDCAUApproved;
    // });
    // const isDCARApproved = computed(() => {
    //   return store.state.marketplace.isDCARApproved;
    // });
    const computedItemData = computed(() => {
      return marketItem.value;
    });
    const sellerAddress = computed(() => {
      return avvyNameForAddress(props.item.ownerAddress);
    });
    const fromTime = computed(() => {
      const d = new Date(props.item.createdAt);
      return dayjs(d).fromNow();
    });
    const currentCategory = computed(() => {
      if (props.item.itemType === "consumable") return "consumable";
      else if (props.item.itemType === "resource") return "resource";
      else return "equipment";
    });

    return {
      /**
       * Data
       */
      isLoading,
      isBuying,
      computedItemData,
      isDCAUApproved,
      isDCARApproved,
      isApprovingDCAU,
      isApprovingDCAR,
      isRemoving,
      currentCategory,
      currentPriceDollar,
      isLoadingDollarPrice,
      /**
       * Methods
       */
      processBuy,
      cancelListing,
      approveDCAU,
      approveDCAR,
      avvyNameForAddress,
      /**
       * Computed
       */
      sellerAddress,
      fromTime,
    };
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/globals.scss";
.market-item {
  padding: 10px;
  background: #171110;
  border-radius: 5px;

  .product-info {
    display: grid;
    grid-template-columns: 72px 1fr;
    grid-gap: 1rem;
    align-items: start;
  }
  .product-meta {
    display: grid;
    grid-template-columns: 1fr auto;
    font-size: 0.8rem;
    color: #ffffff59;
    padding: 5px 0 0;
    border-top: 1px solid #ffffff17;
    margin-top: 5px;
    justify-items: baseline;
    .address {
      text-align: left;
      &:before {
        background: url("https://cdn.dragoncrypto.io/uiassets/user.svg")
          no-repeat left center;
        background-size: contain;
        margin-right: 4px;
        content: "";
        opacity: 0.4;
        height: 15px;
        width: 15px;
        display: inline-block;
        margin-top: -3px;
        top: 3px;
        position: relative;
      }
    }
    .listed-time {
      text-align: right;
      &:before {
        background: url("https://cdn.dragoncrypto.io/uiassets/clock.svg")
          no-repeat left center;
        background-size: contain;
        margin-right: 4px;
        content: "";
        opacity: 0.4;
        height: 14px;
        width: 14px;
        display: inline-block;
        margin-top: -3px;
        top: 3px;
        position: relative;
      }
    }
  }

  &:hover {
    background: #251c1a;
  }
  .details {
    justify-items: left;
    .price-details {
      text-align: left;
    }
    span.price {
      font-size: 0.7rem;
      font-weight: bold;
      display: inline-block;
      text-align: left;
      margin-left: 10px;
      span {
        opacity: 0.5;
      }
      &:after {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 0;
        margin-top: -8px;
        padding: 0;
        top: 7px;
        position: relative;
        margin-left: 4px;
      }
      &.DCAR:after {
        background: url("../../assets/ui/dcar.png") no-repeat left center;
        background-size: contain;
      }
      &.DCAU:after {
        background: url("../../assets/ui/dcau.png") no-repeat left center;
        background-size: contain;
      }
    }
    span.dollar-value {
      font-size: 0.65rem;
      display: inline-block;
      margin: 2px 0 0 5px;
      color: rgba(255, 255, 255, 0.5215686275);
    }
    span.skeleton-box {
      display: inline-block !important;
      top: 3px;
      position: relative;
      margin-left: 5px;
      opacity: 0.2;
    }
    .dialog-button {
      @media only screen and (max-width: 576px) {
        transform: scale(0.8);
      }
    }
    h3 {
      color: #fff;
      text-align: left;
      font-family: "IM Fell English", serif;
      font-size: 90%;
      text-transform: none;
      margin: 3px 3px 3px 10px;

      &.rare {
        color: $rare;
      }

      &.epic {
        color: $epic;
      }

      &.mythical {
        color: $mythical;
      }

      &.legendary {
        color: $legendary;
      }

      &.fabled {
        color: $fabled;
      }

      &.artefact {
        color: $artefact;
      }

      &.uncommon {
        color: $uncommon;
      }
    }
  }
}

.skeleton-box {
  position: relative;
  display: grid;
  grid-template-columns: 72px 1fr;
  grid-gap: 1rem;
  .square,
  .rectangle {
    display: block;
    position: relative;
    overflow: hidden;
    background: #464646;
    animation: skeleton 1s ease-in-out forwards infinite;
    animation-direction: alternate;
  }
  .details {
    display: grid;
    align-items: center;
  }

  /* .loading::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    animation: 2s shimmer linear 0.5s infinite;
    background: linear-gradient(90deg, transparent, #ededed, transparent);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    z-index: 1;
  } */

  @keyframes skeleton {
    0% {
      opacity: 0.3;
      transform: translateY(3px) scale(0.98);
    }
    100% {
      opacity: 0.6;
      transform: translateY(0px) scale(1);
    }
  }
}
</style>
