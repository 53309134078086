import Constants from "../../consts/constants";

const state = () => ({
  isGuildOpen: false,
  guildType: "defenders",
  isGuildInventoryOpen: false,
  isGuildRewardsOpen: false,
  contractCraftersQuests: null,
  contractWarriorsQuests: null,
  selectedQuestForSubmit: null,
  selectedEquipment: [],
  selectedResources: [],
  selectedResourcesQuantities: [],
  guildQuestRewards: null,
});

// // getters
// const getters = {};

// // actions
const actions = {
  /**
   * Toggle the selected status of an item and fill respective selected stash category
   */
  toggleItem({ commit, state }, payload) {
    console.log(payload);
    const item = payload.item;
    const itemType = payload.itemType;
    switch (itemType) {
      case Constants.filterTypes.equipments: {
        let equipmentSelected = state.selectedEquipment;
        console.log(equipmentSelected);
        if (equipmentSelected.includes(item.id)) {
          // If item is getting deselected
          equipmentSelected = equipmentSelected.filter((i) => {
            return i !== item.id;
          });
        } else {
          if (
            state.selectedQuestForSubmit &&
            equipmentSelected.length >= state.selectedQuestForSubmit.quantity
          ) {
            break;
          }
          equipmentSelected.push(item.id);
        }
        console.log(equipmentSelected);
        commit("setSelectedEquipment", equipmentSelected);
        break;
      }
      case Constants.filterTypes.resources: {
        let resourcesSelected = state.selectedResources;
        let resourcesQuantities = state.selectedResourcesQuantities;
        if (resourcesSelected.includes(item.id)) {
          // If item is getting deselected
          const indexOfId = resourcesSelected.indexOf(item.id);
          resourcesSelected = resourcesSelected.filter((i) => {
            return i !== item.id;
          });
          resourcesQuantities = resourcesQuantities.filter(
            (quantity, index) => {
              return index !== indexOfId;
            }
          );
        } else {
          if (
            state.selectedQuestForSubmit &&
            item.quantity < state.selectedQuestForSubmit.quantity
          ) {
            break;
          }
          resourcesSelected.push(item.id);
          resourcesQuantities.push(state.selectedQuestForSubmit.quantity); // ONLY ASSIGNING THE REQUIRED QUANTITY OUT OF THE TOTAL
        }
        commit("setSelectedResources", resourcesSelected);
        commit("setSelectedResourcesQuantities", resourcesQuantities);
        break;
      }
      default:
        break;
    }
  },
  /**
   * Reset all selected states in stash
   */
  resetItems({ commit }) {
    commit("setSelectedEquipment", []);
    commit("setSelectedResources", []);
    commit("setSelectedResourcesQuantities", []);
  },
};

// mutations
const mutations = {
  setGuildType(state, value) {
    state.guildType = value;
  },
  setIsGuildOpen(state, value) {
    state.isGuildOpen = value;
  },
  setContractCraftersQuests(state, value) {
    state.contractCraftersQuests = value;
  },
  setContractWarriorsQuests(state, value) {
    state.contractWarriorsQuests = value;
  },
  setIsGuildInventoryOpen(state, value) {
    state.isGuildInventoryOpen = value;
  },
  setIsGuildRewardsOpen(state, value) {
    state.isGuildRewardsOpen = value;
  },
  setGuildQuestRewards(state, value) {
    state.guildQuestRewards = value;
  },
  setSelectedQuestForSubmit(state, value) {
    state.selectedQuestForSubmit = value;
  },
  setSelectedEquipment(state, value) {
    state.selectedEquipment = value;
  },
  setSelectedResources(state, value) {
    state.selectedResources = value;
  },
  setSelectedResourcesQuantities(state, quantities) {
    state.selectedResourcesQuantities = quantities;
  },
};

export default {
  namespaced: true,
  state,
  //   getters,
  actions,
  mutations,
};
