import Constants from "../consts/constants";

export default {
  async callProcessLogin(account, signature) {
    const response = await fetch(Constants.apiUrl + "processlogin", {
      method: "POST",
      body: JSON.stringify({ account, signature }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async fetchWithTimeout(resource, options = {}) {
    const { timeout = Constants.timeout } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);
    return response;
  },
  async callGetHeroes(account, sessionId) {
    const response = await fetch(Constants.apiUrl + "heroesforuser", {
      method: "POST",
      body: JSON.stringify({ account, sessionId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callGetHeroStats(account, sessionId, heroIds) {
    const response = await fetch(Constants.apiUrl + "statsforheroes", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroIds }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callSwitchCharactersCombat(account, sessionId, heroId) {
    const response = await fetch(Constants.apiUrl + "switchcharacterscombat", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callResurrectHero(account, sessionId, heroId, blockNumber) {
    const response = await fetch(Constants.apiUrl + "resurrecthero", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId, blockNumber }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callResurrectHeroFree(account, sessionId, heroId) {
    const response = await fetch(Constants.apiUrl + "freeresurrecthero", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callHealHero(account, sessionId, heroId, api, blockNumber) {
    const response = await fetch(Constants.apiUrl + api, {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId, blockNumber }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callLeaveMultichoice(account, sessionId, heroId) {
    const response = await fetch(Constants.apiUrl + "leavemultichoice", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callMakeMultichoice(account, sessionId, heroId) {
    const response = await fetch(Constants.apiUrl + "makechoice", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callMineResource(account, sessionId, heroId, api, slot, encounterId) {
    const response = await this.fetchWithTimeout(Constants.apiUrl + api, {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId, slot, encounterId }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    return result;
  },
  async leaveEncounter(account, sessionId, heroId, api) {
    const response = await this.fetchWithTimeout(Constants.apiUrl + api, {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    return result;
  },
  async callSearchForEncounter(account, sessionId, heroId, api) {
    const response = await this.fetchWithTimeout(Constants.apiUrl + api, {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    return result;
  },
  async callRestAtInn(account, sessionId, heroId, blockNumber) {
    const response = await fetch(Constants.apiUrl + "innrest", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId, blockNumber }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callBardAtInn(account, sessionId, heroId, api, blockNumber) {
    const response = await fetch(Constants.apiUrl + api, {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId, blockNumber }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callGetContractQuests(account, sessionId, heroId, questType) {
    const response = await fetch(Constants.apiUrl + "contractquests", {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        heroId,
        questType,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callStartContractQuest(account, sessionId, heroId, contractQuestId) {
    const response = await fetch(Constants.apiUrl + "startcontractquest", {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        heroId,
        contractQuestId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callFinishContractEquipmentQuest(
    account,
    sessionId,
    heroId,
    contractQuestId,
    txHash
  ) {
    const response = await fetch(
      Constants.apiUrl + "finish-contract-equip-quest",
      {
        method: "POST",
        body: JSON.stringify({
          account,
          sessionId,
          heroId,
          contractQuestId,
          txHash,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const result = await response.json();

    return result;
  },
  async callFinishContractResourceQuest(
    account,
    sessionId,
    heroId,
    contractQuestId,
    txHash
  ) {
    const response = await fetch(
      Constants.apiUrl + "finish-contract-resource-quest",
      {
        method: "POST",
        body: JSON.stringify({
          account,
          sessionId,
          heroId,
          contractQuestId,
          txHash,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const result = await response.json();

    return result;
  },
  async callFinishContractKillQuest(
    account,
    sessionId,
    heroId,
    contractQuestId
  ) {
    const response = await fetch(
      Constants.apiUrl + "finish-contract-kill-quest",
      {
        method: "POST",
        body: JSON.stringify({
          account,
          sessionId,
          heroId,
          contractQuestId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const result = await response.json();

    return result;
  },
  async callGetDailyQuest(account, sessionId, heroId) {
    const response = await fetch(Constants.apiUrl + "dailyqueststatus", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callGetQuestsForHero(account, sessionId, heroId) {
    const response = await fetch(Constants.apiUrl + "questsforhero", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callClaimQuestRewards(account, sessionId, heroId, questId) {
    const response = await fetch(Constants.apiUrl + "claimquestreward", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId, questId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async resetStatPoints(account, sessionId, heroId, api) {
    const response = await fetch(Constants.apiUrl + api, {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async claimLootChestStatus(account, sessionId, heroId) {
    const response = await fetch(Constants.apiUrl + "claim-loot-chest-status", {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        heroId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async claimLootChest(
    account,
    sessionId,
    heroId,
    chestId,
    quantity,
    blockNumber
  ) {
    const response = await fetch(Constants.apiUrl + "claim-loot-chest", {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        heroId,
        chestId,
        quantity,
        blockNumber,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callAcceptDailyQuest(account, sessionId, heroId, questId) {
    const response = await fetch(Constants.apiUrl + "acceptdailyquest", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId, questId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callGetSmeltResult(
    account,
    sessionId,
    heroId,
    api,
    itemsForSmeltingIds
  ) {
    const response = await fetch(Constants.apiUrl + api, {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId, itemsForSmeltingIds }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async smelt(account, sessionId, heroId, api, itemsForSmeltingIds) {
    const response = await fetch(Constants.apiUrl + api, {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        heroId,
        itemsForSmeltingIds,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callGetStaticResourceList() {
    const response = await fetch(Constants.apiUrl + "static/resources", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callRankings(stat) {
    const response = await fetch(Constants.apiUrl + "rankings?stat=" + stat, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async breakdownItem(account, sessionId, heroId, api, itemIds, isStash, type) {
    const response = await fetch(Constants.apiUrl + api, {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        heroId,
        itemIds,
        isStash,
        type,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },

  async callGetCraftingSlots(
    account,
    sessionId,
    heroId,
    api,
    blueprintItemId,
    amountToCraft
  ) {
    const response = await fetch(Constants.apiUrl + api, {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        heroId,
        blueprintItemId,
        amountToCraft,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },

  async callGetCreateRepair(
    account,
    sessionId,
    heroId,
    api,
    blockNumber,
    itemToRepair,
    txHash
  ) {
    const response = await fetch(Constants.apiUrl + api, {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        heroId,
        blockNumber,
        itemToRepair,
        txHash,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },

  async callGetCreateCraft(
    account,
    sessionId,
    heroId,
    api,
    blockNumber,
    itemForCraft
  ) {
    const response = await fetch(Constants.apiUrl + api, {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        heroId,
        blockNumber,
        itemForCraft,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },

  async callGetCraftingResult(
    account,
    sessionId,
    heroId,
    api,
    blueprintItemId,
    inputResourceIds,
    amountToCraft
  ) {
    const response = await fetch(Constants.apiUrl + api, {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        heroId,
        blueprintItemId,
        inputResourceIds,
        amountToCraft,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },

  async callCraftItemFromBlueprint(
    account,
    sessionId,
    heroId,
    api,
    blueprintToCraftId,
    inputResourceIds,
    amountToCraft,
    targetAddress,
    saleIndex
  ) {
    const response = await fetch(Constants.apiUrl + api, {
      method: "POST",
      body: JSON.stringify({
        account,
        heroId,
        sessionId,
        blueprintToCraftId,
        inputResourceIds,
        amountToCraft,
        targetAddress,
        saleIndex,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async handleTransactionHash(account, sessionId, heroId, type, txHash) {
    const response = await fetch(Constants.apiUrl + "handleTransactionHash", {
      method: "POST",
      body: JSON.stringify({
        account,
        heroId,
        sessionId,
        type,
        txHash,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },

  async repairItems(
    account,
    sessionId,
    heroId,
    api,
    itemsForRepair,
    guildRepair,
    saleIndex,
    txHash
  ) {
    const response = await fetch(Constants.apiUrl + api, {
      method: "POST",
      body: JSON.stringify({
        account,
        heroId,
        sessionId,
        itemsForRepair,
        guildRepair,
        saleIndex,
        txHash,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callGetInventory(account, sessionId) {
    const response = await fetch(Constants.apiUrl + "inventory", {
      method: "POST",
      body: JSON.stringify({ account, sessionId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callGetStash(account, sessionId) {
    const response = await fetch(Constants.apiUrl + "stash", {
      method: "POST",
      body: JSON.stringify({ account, sessionId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callClaimStash(
    account,
    sessionId,
    type,
    itemIds,
    quantities,
    blockNumber,
    txHash
  ) {
    const response = await fetch(Constants.apiUrl + "claim-stash", {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        type,
        itemIds,
        quantities,
        blockNumber,
        txHash,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callBreakdownStash(account, sessionId, type, itemIds, heroId) {
    console.log("Called API");
    const response = await fetch(
      Constants.apiUrl + Constants.apiURLs.breakdownStash,
      {
        method: "POST",
        body: JSON.stringify({
          account,
          sessionId,
          type,
          itemIds,
          heroId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const result = await response.json();

    return result;
  },
  async callFinishFight(account, sessionId, heroId, encounterId) {
    const response = await fetch(Constants.apiUrl + "finishfight", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId, encounterId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callEquipitem(account, sessionId, heroId, slot, equipmentId) {
    const response = await fetch(Constants.apiUrl + "equipitem", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId, slot, equipmentId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callEquipNft(account, sessionId, heroId, nftId, nftAddress) {
    const response = await fetch(Constants.apiUrl + "equip-nft", {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        heroId,
        nftId,
        nftAddress,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callUnequipNft(account, sessionId, heroId) {
    console.log("Coming here");
    const response = await fetch(Constants.apiUrl + "unequip-nft", {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        heroId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callUnequipitem(account, sessionId, heroId, slot, equipmentId) {
    const response = await fetch(Constants.apiUrl + "unequipitem", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId, slot, equipmentId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callUnequipAllItems(account, sessionId, heroId) {
    const response = await fetch(Constants.apiUrl + "unequip-all-items", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },

  async callConsumeItem(
    account,
    quantity,
    sessionId,
    heroId,
    consumableId,
    txHash = null
  ) {
    const response = await fetch(Constants.apiUrl + "useconsumable", {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        heroId,
        consumableId,
        quantity,
        txHash,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callConsumeItemStatusUpdate(
    account,
    sessionId,
    heroId,
    status,
    consumableId,
    quantity
  ) {
    const response = await fetch(
      Constants.apiUrl + "updateConsumableTransactionStatus",
      {
        method: "POST",
        body: JSON.stringify({
          account,
          sessionId,
          status,
          heroId,
          consumableId,
          quantity,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const result = await response.json();
    return result;
  },
  async callConsumeItemCombat(
    account,
    sessionId,
    heroId,
    consumableId,
    txHash
  ) {
    const response = await fetch(Constants.apiUrl + "useconsumablecombat", {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        heroId,
        consumableId,
        txHash,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callLeaveCombatDead(account, sessionId, heroId) {
    const response = await fetch(Constants.apiUrl + "leavecombatdead", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callGetEquipment(account, sessionId, heroId) {
    const response = await fetch(Constants.apiUrl + "equipped", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callGetEquippedPet(account, sessionId, heroId) {
    const response = await fetch(Constants.apiUrl + "equippedPet", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callFightHit(account, sessionId, heroId, slot, encounterId, isAbility) {
    const response = await fetch(Constants.apiUrl + "fighthit", {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        heroId,
        encounterId,
        slot,
        isAbility,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callRejectedClaim(account, sessionId, blockNumber, type) {
    try {
      const response = await fetch(Constants.apiUrl + "rejectclaim", {
        method: "POST",
        body: JSON.stringify({ account, sessionId, blockNumber, type }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();

      return result;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async callPetHit(
    account,
    sessionId,
    heroId,
    petId,
    nftAddress,
    slot,
    encounterId
  ) {
    const response = await fetch(Constants.apiUrl + "pet-hit", {
      method: "POST",
      body: JSON.stringify({
        account,
        sessionId,
        heroId,
        petId,
        nftAddress,
        slot,
        encounterId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
  async callFightFlee(account, sessionId, heroId, encounterId) {
    const response = await fetch(Constants.apiUrl + "encounterflee", {
      method: "POST",
      body: JSON.stringify({ account, sessionId, heroId, encounterId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    return result;
  },
};
