<script setup>
import { onMounted, onUnmounted, computed, ref, defineEmits } from "vue";
import { useStore } from "vuex";
import DialogButton from "./DialogButton.vue";
import EquipmentTile from "./EquipmentTile.vue";
import Constants from "../consts/constants";
import { useUser } from "../composables/user";
import { io } from "socket.io-client";
import { getDCGResourcesContract } from "../utils/getContract";

const store = useStore();
const socket = io(Constants.apiUrl, { transports: ["websocket"] });
const { address, signer } = useUser();

const approvedResources = ref(false);
const approvedResourcesShow = ref(false);
const isLoading = ref(false);
const successForge = ref(false);
const ResourcesContract = getDCGResourcesContract(signer.value);
const socketCompleted = ref(false);

const emit = defineEmits(["forgeSmelt", "forgeReturn", "leave"]);

onMounted(() => {
  store.watch(
    (state) => state.forgeItems,
    () => {
      store.state.getForgeCurrentResult();
    },
    { deep: true }
  );
  store.watch(
    (state) => state.currentCharacterId,
    () => {
      store.state.getForgeCurrentResult();
    },
    { deep: true }
  );

  socket.emit("join", { sessionId: localStorage.getItem("sessionId") });

  socket.on("connect", () => {
    console.log("connected to socket");
  });

  socket.on("forge", (results) => {
    if (results.success) {
      emit("forgeReturn");
      store.commit(
        "setNotification",
        `${results.item.name} Forged! Check your stash to claim!`
      );
      store.commit("setInventory", results.inventory);
      store.commit("setStash", results.stash);
      store.commit("setHeroStats", results.heroStats);
      store.commit("setTurnHappening", false);
      socketCompleted.value = true;
      successForge.value = true;
      isLoading.value = false;
    } else {
      store.commit("setNotification", results.message);
    }
  });

  socket.io.on("error", () => {
    console.log(
      "Unable to connect to server. Please try again or let the team know"
    );
  });

  // check crafting approval
  checkResourceApproval();
});

onUnmounted(() => {
  socket.removeAllListeners("forge");
  socket.disconnect();
});

const checkResourceApproval = async () => {
  let isBurningApproved = await ResourcesContract.isApprovedForAll(
    address.value,
    ResourcesContract.address
  );

  if (isBurningApproved) {
    approvedResources.value = true;
    approvedResourcesShow.value = true;
  }
};

const approveResources = async () => {
  try {
    isLoading.value = true;
    const txApproveResources = await ResourcesContract.setApprovalForAll(
      ResourcesContract.address,
      true
    );
    await txApproveResources.wait();
    isLoading.value = false;
    await checkResourceApproval();
  } catch (e) {
    isLoading.value = false;
    console.log(e);
  }
};

const signForge = async (ids, quantities) => {
  const sessionId = localStorage.getItem("sessionId");
  const heroId = character.value.number;
  socketCompleted.value = false;

  const response = await fetch(Constants.apiUrl + "contract/sign-forge", {
    method: "POST",
    body: JSON.stringify({
      sessionId,
      heroId,
      ids,
      quantities,
      account: address.value,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  console.log(data);
  return data.success;
};

const burnResources = async () => {
  isLoading.value = true;
  successForge.value = false;
  const ids = forgeIds.value.map((id) => id);
  let quantities = forgeQuantities.value.map((quantity) => quantity);
  const lowestQuantity = Math.min(...quantities);
  quantities = quantities.map(
    (quantity) => quantity - (quantity - lowestQuantity)
  );

  const sign = await signForge(ids, quantities);
  if (!sign) {
    isLoading.value = false;
    return;
  }

  try {
    const txBurnResources = await ResourcesContract.burnBatch(
      address.value,
      ids,
      quantities
    );
    const receipt = await txBurnResources.wait();
    if (receipt.status) {
      //await delay(20000);
      /*if (socketCompleted.value) {
        isLoading.value = false;
        return;
      }*/
      emit("forgeSmelt");
      await store.dispatch("updateInventory");
      emit("forgeReturn");
      isLoading.value = false;
      successForge.value = true;
      socketCompleted.value = true;
    }
  } catch (e) {
    isLoading.value = false;
    console.log(e);
  }
};

const returnToForge = () => {
  store.dispatch("updateInventory");
  emit("forgeReturn");
};

//const delay = async (time) => new Promise((res) => setTimeout(res, time));

const forgeResult = computed(() => {
  return store.state.forgeResult;
});

const character = computed(() => {
  return store.state.characters[store.state.currentCharacter];
});

/*
const isTurnHappening = computed(() => {
  return store.state.isTurnHappening;
});

const getMessage = computed(() => {
  return store.state.forgeMessage;
});
*/
const heroTurns = computed(() => {
  return store.state.characters[store.state.currentCharacter].stats.turns > 0;
});

const forgeSlots = computed(() => {
  return store.state.forgeItems;
});

const forgeIds = computed(() => {
  return store.state.forgeIds;
});

const forgeQuantities = computed(() => {
  return store.state.forgeQuantities;
});

const getClass = computed(() => {
  return (
    "forge-main-wrapper wrap-content " +
    (store.state.gameState == Constants.gamemodes.forge ? "show" : "hide")
  );
});
</script>

<template>
  <div :class="getClass">
    <div class="inner-dialog-wrapper">
      <div class="forge-container-wrapper">
        <div class="forge-items">
          <div class="content-wrapper">
            <div
              class="warning"
              v-if="forgeResult && forgeResult.notSkilledEnough"
            >
              WARNING: You do not have enough skill for this, you WILL lose
              materials if you proceed with the forging.
            </div>
            <div v-if="!heroTurns" class="warning">
              You have no turns remaining.
            </div>
            <div class="forge-wrapper">
              <div class="forge-item">
                <EquipmentTile
                  :isForge="true"
                  :size="'med'"
                  :item="forgeSlots[0]"
                />
              </div>
              <div class="forge-item">
                <EquipmentTile
                  :isForge="true"
                  :size="'med'"
                  :item="forgeSlots[1]"
                />
              </div>
              <div class="forge-item">
                <EquipmentTile
                  :isForge="true"
                  :size="'med'"
                  :item="forgeSlots[2]"
                />
              </div>
              <div
                :class="'forge-item result ' + (forgeResult ? 'visible' : '')"
              >
                <EquipmentTile
                  v-if="forgeResult"
                  isForgeResult="true"
                  :size="'large'"
                  :item="forgeResult.item"
                />
              </div>
            </div>
            <div class="buttons">
              <DialogButton
                v-if="forgeResult && !approvedResources"
                @click="approveResources()"
                :buttonText="'Approve NFT'"
                :isLoading="isLoading"
                :disabled="isLoading"
                :buttonSize="'small'"
              />
              <DialogButton
                v-if="forgeResult && approvedResources"
                :disabled="isLoading || !heroTurns"
                :requiresTurn="true"
                @click="burnResources()"
                :buttonText="'Smelt (1 Turn)'"
                :buttonSize="'medium'"
                :isLoading="isLoading"
              />
              <DialogButton
                @click="returnToForge"
                :buttonText="'Remove'"
                :buttonSize="'small'"
                :disabled="isLoading"
              />
              <DialogButton
                @click="$emit('leave')"
                :buttonText="'Leave'"
                :buttonSize="'small'"
                :disabled="isLoading"
              />
            </div>
            <div class="noResult" v-if="!forgeResult && forgeSlots[0]">
              No results for this combination.
            </div>
            <div class="noResult" v-if="forgeResult && !approvedResources">
              You must approve the NFT before you can smelt it.
            </div>
            <div class="noResult" v-if="successForge">
              You successfully forged items. Check your stash to claim.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.forge-main-wrapper {
  background: no-repeat url("../assets/locationBackgrounds/ForgeBG.webp") center
    center;
  background-size: cover;
}

.inner-dialog-wrapper {
  margin-left: 400px;
}

.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;
}

.highlight {
  font-weight: bold;
  color: $epic;
}

.forge-container-wrapper {
  .forge-items {
    width: 403px;
    height: 300px;
    position: relative;
    background: url("https://cdn.dragoncrypto.io/uiassets/the-forge.png")
      no-repeat top left;
    margin-top: 20px;

    .content-wrapper {
      padding: 74px 24px 38px 24px;

      .forge-wrapper {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .warning {
        color: red;
        justify-content: center;
        font-size: 12px;
      }

      .forge-item {
        border: 1px solid #3d3d40;
        background: #151413;
        border-radius: 2px;
        margin-bottom: 3px;
        display: flex;
        margin-right: 9px;
        margin-left: 9px;
        flex-wrap: nowrap;
        align-items: flex-start;

        &.result {
          display: none;

          &.visible {
            display: flex;

            &::before {
              position: absolute;
              content: "=";
              font-size: 130%;
              top: 14px;
              left: -16px;
            }
          }

          flex-wrap: wrap;
          position: relative;

          img {
            max-width: 56px;
          }

          span {
            position: absolute;
            width: 100%;
            bottom: 20px;
            text-align: center;
            font-size: 90%;
            text-shadow: 0 0 2px #000, 0 1px 3px #000;
          }
        }
      }
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

p {
  font-size: 90%;
}

.noResult {
  text-align: center;
  font-size: 80%;
  margin-top: 10px;
}
</style>
