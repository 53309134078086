<template>
  <BasicPopup
    :header="isChestOpened ? 'Congratulations!' : 'Loot Chests'"
    class="loot-chest-popup"
  >
    <div class="close-button button-small" @click="hide">
      <span>x</span>
    </div>
    <div
      :class="[
        'close-button',
        'button-small',
        isTurnHappening ? 'loading' : '',
      ]"
      @click="hide"
      :title="isTurnHappening ? 'Complete transaction first' : 'Close'"
    >
      <span>x</span>
    </div>
    <div class="inner-wrapper">
      <ShowLoot @refresh="refresh" v-if="isChestOpened" />
      <template v-else>
        <GenericLoading v-if="isFetching" />

        <div v-else>
          <p>
            All your loot chests are listed here. Click on the chest to open it.
          </p>
          <div class="inner-grid">
            <LootChest
              v-for="chest in lootChests"
              :key="chest.id"
              :chest="chest"
            />
          </div>
        </div>
      </template>
    </div>
  </BasicPopup>
</template>

<script>
import { ethers } from "ethers";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useUser } from "../../composables/user";
import GenericLoading from "../LoadingComponents/GenericLoading.vue";
import BasicPopup from "../Popup/BasicPopup.vue";
import LootChest from "./LootChest.vue";
import ShowLoot from "./ShowLoot.vue";

import { getDCGLootChestsContract } from "../../utils/getContract";

export default {
  name: "LootChests",
  components: {
    BasicPopup,
    LootChest,
    GenericLoading,
    ShowLoot,
  },
  setup() {
    const store = useStore();
    const isFetching = ref(true);
    const isClaiming = ref(false);
    const lootChestIds = [1, 2, 3, 4, 5];
    const lootChests = ref([]);

    const { address, signer } = useUser();

    const DCGLootChestsContract = getDCGLootChestsContract(signer.value);

    onMounted(async () => {
      await getLootChestsBalance();
    });

    const getLootChestsBalance = async () => {
      try {
        isFetching.value = true;
        const balanceOfIdsPromises = [];
        lootChestIds.forEach((id) => {
          balanceOfIdsPromises.push(
            DCGLootChestsContract.balanceOf(
              address.value,
              ethers.utils.formatUnits(id, 0)
            )
          );
        });
        const balanceOfIds = await Promise.all(balanceOfIdsPromises);
        lootChests.value = balanceOfIds.map((balance, index) => {
          return {
            id: index + 1,
            balance: parseInt(ethers.utils.formatUnits(balance, 0)),
          };
        });
        isFetching.value = false;
      } catch (error) {
        isFetching.value = false;
        console.log(error);
        store.commit("setNotification", error);
      }
    };
    const hide = () => {
      store.commit("lootChests/setIsChestOpened", false);
      store.commit("lootChests/setIsLootPopupOpen", false);
    };
    const refresh = async () => {
      await getLootChestsBalance();
    };

    const isChestOpened = computed(() => {
      return store.state.lootChests.isChestOpened;
    });

    const isTurnHappening = computed(() => {
      return store.state.isTurnHappening;
    });

    return {
      // Data
      lootChests,
      isFetching,
      // Methods
      hide,
      isClaiming,
      refresh,
      // Computed
      isChestOpened,
      isTurnHappening,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.loot-chest-popup {
  width: calc(60% - $popup-border-size - $popup-border-size);
  height: calc(650px - $popup-border-size - $popup-border-size);
  max-width: 600px;
  min-width: 600px;
  @media only screen and (max-width: 576px) {
    min-width: calc(100% - $popup-border-size - $popup-border-size);
  }
  .close-button {
    position: absolute;
    top: -$popup-border-size;
    right: -$popup-border-size;
    &.loading {
      filter: grayscale(100);
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .inner-wrapper {
    width: calc(100% + $popup-border-offset + $popup-border-offset);
    height: calc(100% + $popup-border-offset + $popup-border-offset);
    display: grid;
    align-items: center;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 15px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-button {
      background-color: transparent;
      width: 15px;
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      background: url("https://cdn.dragoncrypto.io/uiassets/scrollbar-slider.png")
        repeat-y;
    }

    @media only screen and (max-width: 576px) {
      height: calc(100% + $popup-border-offset + $popup-border-offset);
      overflow-y: scroll;
    }
    left: -$popup-border-offset;
    position: relative;
    top: -35px;
    p {
      width: 70%;
      margin: 0 auto 1.2rem;
    }
  }
  .nest-button {
    position: absolute;
    top: -41px;
    right: -36px;
    z-index: 5;
  }
  .inner-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
    position: relative;
    @media only screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }
}
</style>
