<template>
  <div class="mobile-btn-container">
    <DialogButton
      :buttonSize="'med'"
      :buttonText="
        isInventoryVisibleMobile ? 'Hide Inventory' : 'Show Inventory'
      "
      class="toggle-inventory"
      @click="toggleInventory"
      v-if="buttonVisible"
    />
    <DialogButton
      :buttonSize="'med'"
      :buttonText="viewMyItems ? 'Go Back' : 'My Listings'"
      class="toggle-mobile-repair"
      @click="toggleRepairView"
      v-if="buttonVisible"
    />
    <DialogButton
      :buttonSize="'med'"
      :buttonText="viewCanRepairItems ? 'All' : 'Can Repair'"
      class="toggle-mobile-repair"
      @click="toggleCanRepairView"
      v-if="buttonVisible"
    />
  </div>
  <div :class="currentClass">
    <div class="inner-dialog-wrapper">
      <BasicPopup
        header="Repair Marketplace"
        :class="'repair-content ' + (isInventoryOpen ? 'inventory' : '')"
      >
        <div class="close-button button-small" @click="hide">
          <span>x</span>
        </div>
        <DialogButton
          class="back-to-menu"
          @click="hide"
          buttonSize="small"
          buttonText="Back"
          :disabled="isTurnHappening"
        />
        <div class="sorting-filters">
          <button
            :class="['toggle-repair', viewMyItems ? 'back' : '']"
            @click="toggleRepairView"
          >
            {{ viewMyItems ? "Go Back" : "My Listings" }}
          </button>
          <button
            :class="['toggle-repair', viewCanRepairItems ? 'back' : '']"
            @click="toggleCanRepairView"
          >
            {{ viewCanRepairItems ? "All" : "Can Repair" }}
          </button>
          <div class="order">
            <label for="order-by">
              Order by
              <select v-model="searchSorting" id="order-by" name="order-by">
                <option>Recent</option>
                <option>Oldest</option>
                <option>Reward Desc</option>
                <option>Reward Asc</option>
                <option>Rarity Desc</option>
                <option>Rarity Asc</option>
              </select>
            </label>
          </div>
          <div class="search">
            <label for="search">
              <span>Search</span>
              <input
                v-model="currentSearchTerm"
                name="search"
                id="search"
                type="text"
                @keyup="searchTimeOut"
                placeholder="Search By Name"
              />
            </label>
          </div>
        </div>
        <div v-if="viewMyItems" class="items">
          <h3 v-if="currentMyListedItems.length === 0">No Items Listed</h3>
          <div class="grid">
            <ListedItem
              v-for="marketItem in currentMyListedItems"
              :item="marketItem"
              :key="marketItem.id"
              :isOwner="true"
            />
          </div>
          <DialogButton
            v-if="
              currentMyListedItems.length !== 0 &&
              currentMyListedItems.length >= 16
            "
            :buttonSize="'med'"
            :buttonText="loadedAllItems ? 'No More Items' : 'Load More'"
            class="load-more"
            :disabled="loadedAllItems"
            @click="loadMoreMyListedItems"
            :isLoading="loadingMoreItems"
          />
        </div>
        <div v-else :class="['items']">
          <template v-if="!loading">
            <h3 v-if="currentMarketInventory.length === 0">No Items Listed</h3>
            <div class="grid">
              <ListedItem
                v-for="marketItem in currentMarketInventory"
                :item="marketItem"
                :key="marketItem.id"
              />
            </div>
            <DialogButton
              v-if="
                currentMarketInventory.length !== 0 &&
                currentMarketInventory.length >= 16
              "
              :buttonSize="'med'"
              :buttonText="loadedAllItems ? 'No More Items' : 'Load More'"
              class="load-more"
              :disabled="loadedAllItems"
              @click="loadMoreMarketItems"
              :isLoading="loadingMoreItems"
            />
          </template>
          <GenericLoading v-else />
        </div>
      </BasicPopup>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import BasicPopup from "../Popup/BasicPopup.vue";
import DialogButton from "../DialogButton.vue";
import Constants from "../../consts/constants";
// import { ethers } from "ethers";
// import { useUser } from "../../composables/user";
import ListedItem from "./ListedItem.vue";
import GenericLoading from "../LoadingComponents/GenericLoading.vue";
export default {
  name: "Repair Room",
  props: {},
  components: {
    BasicPopup,
    GenericLoading,
    DialogButton,
    ListedItem,
  },
  setup() {
    const store = useStore();
    const character = ref(null);
    const account = ref("");
    const isLoading = ref(true);
    const viewMyItems = ref(false);
    const viewCanRepairItems = ref(false);
    const loading = ref(false);
    const searchSorting = ref("Recent");
    const currentSearchTerm = ref("");
    const loadingMoreItems = ref(false);
    const page = ref(1);
    const loadedAllItems = ref(false);
    const searchDelayTimer = ref(null);
    onMounted(() => {
      // Set the character to the current character from store
      currentCharacter();
      account.value = store.state.account;

      getRepairItems();
    });

    watch(
      searchSorting,
      (value) => {
        store.dispatch("repair/processSorting", value);
        getRepairItems();
      },
      { deep: true }
    );

    /**
     * Methods
     */
    const hide = () => {
      console.log("hide");
      store.commit("hideRepairRoom");
    };
    const refresh = async () => {
      isLoading.value = true;

      //isLoading.value = false;
    };
    const toggleInventory = () => {
      const inventoryOpen = store.state.isInventoryVisible;
      if (!inventoryOpen) {
        store.commit("setInventoryVisible", true);
        store.commit("repair/setShowInventoryState", true);
      } else {
        store.commit(
          "repair/setShowInventoryState",
          !store.state.repair.showInventory
        ); // not showing inventory by default for mobile
      }
    };

    const toggleRepairView = async () => {
      viewMyItems.value = !viewMyItems.value;
      viewCanRepairItems.value = false;
      if (viewMyItems.value) {
        await myListedItems();
      } else {
        await getRepairItems();
      }
      resetFilter();
    };

    const toggleCanRepairView = async () => {
      viewCanRepairItems.value = !viewCanRepairItems.value;
      viewMyItems.value = false;
      if (viewCanRepairItems.value) {
        await myCanRepairItems();
      } else {
        await getRepairItems();
      }
      resetFilter();
    };

    const currentCharacter = () => {
      character.value = store.state.characters[store.state.currentCharacter];
    };

    const myListedItems = async () => {
      loading.value = true;
      await store.dispatch("repair/updateMyListedItems");
      loading.value = false;
      resetFilter();
    };

    const myCanRepairItems = async () => {
      loading.value = true;
      await store.dispatch("repair/updateMyCanRepairItems");
      loading.value = false;
      resetFilter();
    };

    const getRepairItems = async () => {
      loading.value = true;
      await store.dispatch("repair/updateRepairItems");
      loading.value = false;
      resetFilter();
    };

    const resetFilter = () => {
      loadedAllItems.value = false;
      page.value = 1;
    };

    const searchTimeOut = () => {
      if (searchDelayTimer.value) {
        clearTimeout(searchDelayTimer.value);
        searchDelayTimer.value = null;
      }
      searchDelayTimer.value = setTimeout(() => {
        console.log(currentSearchTerm.value);
        store.commit("repair/setSearchTerm", currentSearchTerm.value);
        getRepairItems();
      }, 500);
    };

    const loadMoreMarketItems = async () => {
      loadingMoreItems.value = true;
      const repairApiQuery = `repair-guild-contracts?page=${++page.value}&sortBy=${
        sortBy.value
      }&order=${order.value}`;
      const response = await fetch(Constants.apiUrl + repairApiQuery, {
        method: "POST",
        body: JSON.stringify({
          account: account.value,
          sessionId: localStorage.getItem("sessionId"),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success) {
        if (data.repairItems.length === 0) {
          loadedAllItems.value = true;
        } else {
          store.dispatch("repair/loadMoreMarketItems", data.repairItems);
        }
      }
      loadingMoreItems.value = false;
    };

    const loadMoreMyListedItems = async () => {
      loadingMoreItems.value = true;

      const repairApiQuery = `my-repair-guild-contracts?page=${++page.value}&sortBy=${
        sortBy.value
      }&order=${order.value}`;
      const response = await fetch(Constants.apiUrl + repairApiQuery, {
        method: "POST",
        body: JSON.stringify({
          account: account.value,
          sessionId: localStorage.getItem("sessionId"),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (data.success) {
        if (data.repairItems.length === 0) {
          loadedAllItems.value = true;
        } else {
          store.dispatch("repair/loadMoreMyListedItems", data.repairItems);
        }
      }

      loadingMoreItems.value = false;
    };

    /**
     * Computed methods
     */
    const isInventoryVisibleMobile = computed(() => {
      return store.state.repair.showInventory;
    });

    const buttonVisible = computed(() => {
      return store.state.gameState == Constants.gamemodes.repair;
    });

    const currentClass = computed(() => {
      return (
        "repair-wrapper wrap-content " +
        (store.state.gameState == Constants.gamemodes.repair ? "show" : "hide")
      );
    });

    const isInventoryOpen = computed(() => {
      return store.state.isInventoryVisible;
    });

    const currentMyListedItems = computed(() => {
      let results = store.state.repair.myListedItemsData;
      return results;
    });

    const currentMarketInventory = computed(() => {
      let results = store.state.repair.marketData;
      return results;
    });

    const sortBy = computed(() => {
      return store.state.repair.sortBy;
    });

    const order = computed(() => {
      return store.state.repair.order;
    });

    const isTurnHappening = computed(() => {
      return store.state.isTurnHappening;
    });

    return {
      //data
      isLoading,
      loading,
      searchSorting,
      currentSearchTerm,
      // methods
      hide,
      refresh,
      toggleInventory,
      myListedItems,
      toggleRepairView,
      toggleCanRepairView,
      searchTimeOut,
      loadMoreMarketItems,
      loadMoreMyListedItems,
      // computed
      isInventoryVisibleMobile,
      buttonVisible,
      currentClass,
      viewMyItems,
      viewCanRepairItems,
      isInventoryOpen,
      currentMyListedItems,
      currentMarketInventory,
      isTurnHappening,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.repair-wrapper {
  background: no-repeat
    url("../../assets/locationBackgrounds/crafters-guild.webp") center center;
  background-size: cover;
}

.wrap-content {
  z-index: 5 !important; // making repair on top of everything
}
.toggle-inventory {
  display: none;
  @media only screen and (max-width: 576px) {
    position: fixed;
    bottom: 20px;
    left: 10px;
    transform: scale(1) !important;
    opacity: 1 !important;
    z-index: 6;
    display: block;
  }
}
.toggle-mobile-repair {
  display: none;
  @media only screen and (max-width: 576px) {
    position: fixed;
    bottom: 20px;
    right: 10px;
    transform: scale(1) !important;
    opacity: 1 !important;
    z-index: 6;
    display: block;
  }
}
.repair-content {
  width: calc(756px - $popup-border-size - $popup-border-size);
  height: calc(600px - $popup-border-size - $popup-border-size);
  transition: left 0.3s ease-out;
  @media only screen and (max-width: 576px) {
    width: calc(100% - 134px);
    height: 60%;
  }

  &.inventory {
    left: 64%;
    transition: left 0.3s ease-out;
    @media only screen and (max-width: 576px) {
      left: 50%;
    }
  }
  button.toggle-repair {
    border: none;
    background-color: rgb(45 13 9);
    color: #d89967;
    padding: 7px;
    box-shadow: 0 0 1px 2px #000000a6;
    font-family: "IM Fell English", serif;
    font-size: 0.9rem;
    z-index: 6;
    width: 100px;
    font-weight: bold;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    &.back {
      background-color: #d89967;
      color: #000;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: rgb(82, 82, 82);
      color: #fff;
    }
    @media only screen and (max-width: 576px) {
      display: none;
    }
  }

  .items {
    width: calc(100% + $popup-border-offset);
    margin: 20px auto 0;
    left: -$popup-border-offset;
    position: relative;
    padding: 0 20px;
    overflow-y: scroll;
    height: 100%;
    align-items: start;
    align-content: start;
    .grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-gap: 1rem;
    }
    .load-more {
      margin: 20px 0;
    }
    &.loading {
      display: grid;
      align-items: center;
      justify-items: center;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 50px;
        background: red;
      }
    }
    @media only screen and (max-width: 576px) {
      height: 88%;
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 10px;
    }
  }
  .sorting-filters {
    display: grid;
    grid-gap: 1rem;
    position: absolute;
    grid-template-columns: 100px 100px auto 1fr;
    height: 44px;
    width: calc(100% + $popup-border-offset * 2 - 20px);
    padding: 0 10px;
    left: -$popup-border-offset;
    top: 9px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    background: rgba(0, 0, 0, 0.5);
    font-size: 0.9rem;
    font-family: "IM Fell English", sans-serif;
    @media only screen and (max-width: 576px) {
      grid-template-columns: 1fr 1fr;
    }

    .search {
      width: 100%;
      span {
        @media only screen and (max-width: 576px) {
          display: none;
        }
      }
    }
    select,
    input {
      margin-left: 10px;
      font-family: "IM Fell English", sans-serif;
      font-size: 105%;
      padding: 4px;
    }

    input {
      background: rgba(0, 0, 0, 0.4);
      color: $game-heading;
      border: 1px solid transparentize($color: $game-heading, $amount: 0.5);
      border-radius: 2px;
    }

    select {
      background: transparent;
      border: none;
      color: $game-heading;

      option {
        color: #000;
        border: none;
      }
    }

    div + div {
      margin-left: 12px;
    }
  }

  .close-button {
    position: absolute;
    top: -$popup-border-size;
    right: -$popup-border-size;
  }
  .back-to-menu {
    position: absolute;
    top: -40px;
    left: -36px;
    z-index: 5;

    @media only screen and (max-width: 576px) {
      top: -124px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;
}

/* .filters { */
/* position: absolute;
  right: -54px;
  width: 58px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 576px) {
    position: absolute;
    width: calc(100% + 38px + 38px);
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    bottom: -38px;
    z-index: 20;
  } */

.filters-content {
  border: 1px solid #393939;
  background: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 58px;
  position: absolute;
  right: -127px;
  top: 50%;
  transform: translateY(-50%);

  @media only screen and (max-width: 576px) {
    border: 1px solid #393939;
    background: #00000080;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    width: calc(100% + 38px + 38px);
    height: 58px;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -38px;
    z-index: 20;
    top: auto;
  }
  /* } */

  img {
    cursor: pointer;
  }

  .selected {
    filter: brightness(2);
  }
}
</style>
