<template>
  <div class="wrapper">
    <div
      :class="[
        'equipment-panel',
        !isVisible ? 'hidden' : '',
        isInventoryVisible ? 'inventory' : '',
      ]"
    >
      <div
        id="stats-close-button"
        class="close-button button-small"
        @click="hide"
      >
        <span>x</span>
      </div>

      <span
        v-if="this.currentCharacter != null"
        id="points-to-spend"
        class="rank"
        >Rank:
        {{
          this.currentCharacter != null
            ? this.currentCharacter.rank
            : "No hero selected"
        }}
        <template v-if="hasLevelUp">
          | Stat Points to Spend: {{ levelStatPoints }}</template
        ></span
      >

      <span v-if="this.currentCharacter != null" class="details">
        <span class="number">
          #{{
            this.currentCharacter != null ? this.currentCharacter.number : 0
          }}
        </span>
        <span class="level">
          Level {{ this.currentCharacter.stats.level }}
        </span>
      </span>
      <div
        v-if="
          this.currentCharacter != null && currentCharacter.equipped != null
        "
        class="equipment"
        id="equipment-step"
      >
        <div class="bottom">
          <EquipmentTile
            :source="'char'"
            :slotName="'hand1'"
            :isEquipmentSlot="true"
            :isTwoHandedSlot="true"
            :types="'hand1'"
            :class="['hand1', highlightSlot('hand1')]"
            :item="currentCharacter.equipped.hand1"
            title="Hand 1"
            @click="filterInventory('hand1')"
          />
          <EquipmentTile
            :source="'char'"
            :slotName="'hand2'"
            :isEquipmentSlot="true"
            :types="'hand1,hand2'"
            :class="['hand2', highlightSlot('hand2')]"
            :item="currentCharacter.equipped.hand2"
            title="Hand 2"
            @click="filterInventory('hand2')"
          />
          <div class="utility-pet">
            <EquipmentTile
              :source="'char'"
              :slotName="'utility'"
              :isEquipmentSlot="true"
              :types="'utility'"
              class="utility"
              :item="currentCharacter.equipped.utility"
              title="Utility"
            />
            <PetPopup location="equipment" />
          </div>
        </div>

        <div class="left">
          <EquipmentTile
            :source="'char'"
            :slotName="'helmet'"
            :isEquipmentSlot="true"
            :types="'helmet'"
            :class="['helmet', highlightSlot('helmet')]"
            :item="currentCharacter.equipped.helmet"
            title="Headwear"
            @click="filterInventory('helmet')"
          />
          <EquipmentTile
            :source="'char'"
            :slotName="'neck'"
            :isEquipmentSlot="true"
            :types="'neck'"
            :class="['neck', highlightSlot('neck')]"
            :item="currentCharacter.equipped.neck"
            title="Neck"
            @click="filterInventory('neck')"
          />
          <EquipmentTile
            :source="'char'"
            :slotName="'shoulder'"
            :isEquipmentSlot="true"
            :types="'shoulder'"
            :class="['shoulder', highlightSlot('shoulder')]"
            :item="currentCharacter.equipped.shoulder"
            title="Shoulders"
            @click="filterInventory('shoulder')"
          />
          <EquipmentTile
            :source="'char'"
            :slotName="'chest'"
            :isEquipmentSlot="true"
            :types="'chest'"
            :class="['chest', highlightSlot('chest')]"
            :item="currentCharacter.equipped.chest"
            title="Chest"
            @click="filterInventory('chest')"
          />
          <EquipmentTile
            :source="'char'"
            :slotName="'belt'"
            :isEquipmentSlot="true"
            :types="'belt'"
            :class="['belt', highlightSlot('belt')]"
            :item="currentCharacter.equipped.belt"
            title="Belt"
            @click="filterInventory('belt')"
          />
          <EquipmentTile
            :source="'char'"
            :slotName="'legs'"
            :isEquipmentSlot="true"
            :types="'legs'"
            :class="['legs', highlightSlot('legs')]"
            :item="currentCharacter.equipped.legs"
            title="Legs"
            @click="filterInventory('legs')"
          />
          <EquipmentTile
            :source="'char'"
            :slotName="'boots'"
            :isEquipmentSlot="true"
            :types="'boots'"
            :class="['boots', highlightSlot('boots')]"
            :item="currentCharacter.equipped.boots"
            title="Footwear"
            @click="filterInventory('boots')"
          />
        </div>

        <div class="right">
          <EquipmentTile
            :source="'char'"
            :slotName="'arms'"
            :isEquipmentSlot="true"
            :types="'arms'"
            :class="['arms', highlightSlot('arms')]"
            :item="currentCharacter.equipped.arms"
            title="Arms"
            @click="filterInventory('arms')"
          />
          <EquipmentTile
            :source="'char'"
            :slotName="'gloves'"
            :isEquipmentSlot="true"
            :types="'gloves'"
            :class="['gloves', highlightSlot('gloves')]"
            :item="currentCharacter.equipped.gloves"
            title="Gloves"
            @click="filterInventory('gloves')"
          />
          <EquipmentTile
            :source="'char'"
            :slotName="'back'"
            :isEquipmentSlot="true"
            :types="'back'"
            :class="['back', highlightSlot('back')]"
            :item="currentCharacter.equipped.back"
            title="Back"
            @click="filterInventory('back')"
          />
          <EquipmentTile
            :source="'char'"
            :slotName="'ring1'"
            :isEquipmentSlot="true"
            :types="'ring'"
            :class="['ring1', highlightSlot('ring')]"
            :item="currentCharacter.equipped.ring1"
            title="Ring"
            @click="filterInventory('ring')"
          />
          <EquipmentTile
            :source="'char'"
            :slotName="'ring2'"
            :isEquipmentSlot="true"
            :types="'ring'"
            :class="['ring2', highlightSlot('ring')]"
            :item="currentCharacter.equipped.ring2"
            title="Ring"
            @click="filterInventory('ring')"
          />
        </div>
        <button
          id="unequip-all"
          title="Unequip all items"
          size="small"
          @click="unequipAllItems"
          :disabled="isUnEquipping"
        ></button>
      </div>

      <div class="stats-container" v-if="this.currentCharacter != null">
        <div
          :class="
            'stat-set-container primary-stats' +
            (hasLevelUp ? ' level-up' : '') +
            (isPrimaryExpanded ? ' expanded' : '')
          "
        >
          <h5 class="small-header" @click="clickPrimaryStats">
            Character Stats
          </h5>
          <div class="collapses" id="allocate-stats">
            <span class="desc">
              <span class="increase" @click="increasePendingAttack">+</span
              ><Tooltip text="Attack increases the damage of physical weapons."
                >Attack
              </Tooltip></span
            >
            <span class="points">
              {{ this.currentCharacter.stats.attack + this.pendingAttack }}
            </span>
            <BonusStat :stat="currentBonusStats.attack" />

            <span class="desc">
              <span class="increase" @click="increasePendingDefense">+</span>
              <Tooltip
                text="Defense reduces the amount of damage received from all attacks."
                >Defense</Tooltip
              ></span
            ><span class="points"
              >{{
                this.currentCharacter.stats.defense + this.pendingDefense
              }} </span
            ><BonusStat :stat="currentBonusStats.defense" />

            <span class="desc"
              ><span class="increase" @click="increasePendingSpeed">+</span
              ><Tooltip text="Speed affects who gets the first strike."
                >Speed</Tooltip
              ></span
            ><span class="points"
              >{{
                this.currentCharacter.stats.speed + this.pendingSpeed
              }} </span
            ><BonusStat :stat="currentBonusStats.speed" />

            <span class="desc"
              ><span class="increase" @click="increasePendingEndurance">+</span
              ><Tooltip
                text="Endurance increases your health by 100 for every point."
                >Endurance</Tooltip
              ></span
            ><span class="points"
              >{{
                this.currentCharacter.stats.endurance + this.pendingEndurance
              }} </span
            ><BonusStat :stat="currentBonusStats.endurance" />

            <span class="desc"
              ><span class="increase" @click="increasePendingLuck">+</span
              ><Tooltip
                text="Luck increases the amount and quality of loot received from enemies/mines."
                >Luck</Tooltip
              ></span
            ><span class="points"
              >{{ this.currentCharacter.stats.luck + this.pendingLuck }} </span
            ><BonusStat :stat="currentBonusStats.luck" />

            <span class="desc"
              ><span class="increase" @click="increasePendingMagic">+</span
              ><Tooltip text="Magic increases the damage done by magic weapons."
                >Magic</Tooltip
              ></span
            ><span class="points"
              >{{
                this.currentCharacter.stats.magic + this.pendingMagic
              }} </span
            ><BonusStat :stat="currentBonusStats.magic" />

            <span class="desc"
              ><span class="increase" @click="increasePendingCrafting">+</span
              ><Tooltip
                text="Crafting decides what you can repair/craft and also the quality of what you craft."
                >Crafting</Tooltip
              ></span
            ><span class="points"
              >{{
                this.currentCharacter.stats.crafting + this.pendingCrafting
              }} </span
            ><BonusStat :stat="currentBonusStats.crafting" />

            <span class="desc"
              ><span class="increase" @click="increasePendingGathering">+</span
              ><Tooltip
                text="Gathering increases the quantity and type of materials gathered."
                >Gathering</Tooltip
              ></span
            ><span class="points"
              >{{
                this.currentCharacter.stats.gathering + this.pendingGathering
              }} </span
            ><BonusStat :stat="currentBonusStats.gathering" />
          </div>
        </div>
        <div
          :class="
            'stat-set-container misc-stats' +
            (hasLevelUp ? ' level-up' : '') +
            (isMiscExpanded ? ' expanded' : '')
          "
          v-if="this.currentCharacter != null"
        >
          <h5 class="small-header" @click="clickMiscStats">Misc Stats</h5>
          <div class="collapses">
            <span class="desc"
              ><Tooltip
                text="Critical hit chance improves your critical hit chance, which gives you massive bonus damage"
                >Critical Hit Chance</Tooltip
              ></span
            ><span class="points"
              >{{ this.currentCharacter.stats.criticalChance }}% </span
            ><BonusStat
              :stat="currentBonusStats.criticalChance"
              :hasPercentage="true"
            />
            <span class="desc"
              ><Tooltip
                text="Axe Mastery gives a damage bonus when wielding axes"
                >Axe Mastery</Tooltip
              ></span
            ><span class="points"
              >{{
                this.currentCharacter.stats.axe
                  ? Math.trunc(this.currentCharacter.stats.axe * 100) / 100
                  : 0
              }}%
            </span>
            <span class="bonus"></span>
            <span class="desc"
              ><Tooltip
                text="Blade Mastery gives a damage bonus when wielding bladed weapons"
                >Blade Mastery</Tooltip
              ></span
            ><span class="points"
              >{{
                this.currentCharacter.stats.blade
                  ? Math.trunc(this.currentCharacter.stats.blade * 100) / 100
                  : 0
              }}%
            </span>
            <span class="bonus"></span>
            <span class="desc"
              ><Tooltip
                text="Close Quarters Mastery gives a damage bonus when wielding close combat weaponary"
                >Close Quarters Mastery</Tooltip
              ></span
            ><span class="points"
              >{{
                this.currentCharacter.stats["close quarters"]
                  ? Math.trunc(
                      this.currentCharacter.stats["close quarters"] * 100
                    ) / 100
                  : 0
              }}%
            </span>
            <span class="bonus"></span>
            <span class="desc"
              ><Tooltip
                text="Heavy Weapon Mastery gives a damage bonus when wielding heavy weaponary"
                >Heavy Mastery</Tooltip
              ></span
            ><span class="points"
              >{{
                this.currentCharacter.stats.heavy
                  ? Math.trunc(this.currentCharacter.stats.heavy * 100) / 100
                  : 0
              }}%
            </span>
            <span class="bonus"></span>
            <span class="desc"
              ><Tooltip
                text="Magic Mastery gives a damage bonus when wielding magical arms"
                >Magic Mastery</Tooltip
              ></span
            ><span class="points"
              >{{
                this.currentCharacter.stats.magicm
                  ? Math.trunc(this.currentCharacter.stats.magicm * 100) / 100
                  : 0
              }}%
            </span>
            <span class="bonus"></span>
            <span class="desc"
              ><Tooltip
                text="Polearm Mastery gives a damage bonus when wielding pole arms"
                >Polearm Mastery</Tooltip
              ></span
            ><span class="points"
              >{{
                this.currentCharacter.stats.polearm
                  ? Math.trunc(this.currentCharacter.stats.polearm * 100) / 100
                  : 0
              }}%
            </span>
            <span class="bonus"></span>
            <span class="desc"
              ><Tooltip
                text="Ranged Mastery gives a damage bonus when using ranged weapons"
                >Ranged Mastery</Tooltip
              ></span
            ><span class="points"
              >{{
                this.currentCharacter.stats.ranged
                  ? Math.trunc(this.currentCharacter.stats.ranged * 100) / 100
                  : 0
              }}%
            </span>
            <span class="bonus"></span>
          </div>
        </div>
      </div>

      <span class="buttons">
        <div>
          <DialogButton
            v-if="pendingSpentPoints > 0 || levelStatPoints > 0"
            :buttonSize="'small'"
            :buttonText="'Apply'"
            @click="applyPendingPoints"
            id="apply-stats-button"
            :disabled="!isApplyRevertPointsShowing || isSpendingPoints"
            :isLoading="isSpendingPoints"
          />
          <DialogButton
            v-if="pendingSpentPoints > 0 || levelStatPoints > 0"
            :buttonSize="'small'"
            :buttonText="'Revert'"
            @click="revertPendingPoints"
            id="revert-stats-button"
          />
          <DialogButton
            v-if="isSelectedCharacterLevel1"
            :buttonSize="'small'"
            :buttonText="'Reset stats'"
            @click="resetStatPoints"
            id="revert-stats-button"
          />
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import PetPopup from "../components/Pets/PetPopup.vue";
import Constants from "../consts/constants";
import BonusStat from "./BonusStat.vue";
import DialogButton from "./DialogButton.vue";
import EquipmentTile from "./EquipmentTile.vue";
import Tooltip from "./Tooltip.vue";

export default {
  name: "CharacterEquipped",
  components: {
    EquipmentTile,
    BonusStat,
    DialogButton,
    Tooltip,
    PetPopup,
  },
  props: {},
  data() {
    return {
      $store: {},
      isVisible: true,
      isInventoryOpen: false,
      pendingCrafting: 0,
      pendingGathering: 0,
      pendingAttack: 0,
      pendingDefense: 0,
      pendingSpeed: 0,
      pendingEndurance: 0,
      pendingLuck: 0,
      pendingMagic: 0,
      pendingSpentPoints: 0,
      isPrimaryExpanded: true,
      isMiscExpanded: false,
      isSpendingPoints: false,
    };
  },
  methods: {
    unequipAllItems() {
      this.$store.commit("unequipAllItem");
    },
    filterInventory(item) {
      if (this.$store.state.inventoryEquipFilter == item) {
        this.$store.commit("setInventoryEquipFilter", "");
      } else {
        this.$store.commit("setInventoryEquipFilter", item);
      }
    },
    highlightSlot(item) {
      if (this.$store.state.inventoryEquipFilter == item) {
        return "highlighted";
      }
      return "";
    },
    hide() {
      this.$store.commit("setEquipmentVisible", false);
      if (this.$store.state.intro.letsGoToBattle) {
        this.$store.commit("intro/setLetsGoToBattle", false);
        this.$store.commit("intro/setSearchPlains", true);
      }
    },

    async callPostSpendPoints(sessionId, heroId, levelPointsSpent) {
      const account = this.$store.state.account;

      const response = await fetch(Constants.apiUrl + "spendlevelpoints", {
        method: "POST",
        body: JSON.stringify({ account, sessionId, heroId, levelPointsSpent }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    },
    async applyPendingPoints() {
      this.isSpendingPoints = true;
      const spendPoints = {
        crafting: this.pendingCrafting,
        gathering: this.pendingGathering,
        attack: this.pendingAttack,
        defense: this.pendingDefense,
        speed: this.pendingSpeed,
        endurance: this.pendingEndurance,
        luck: this.pendingLuck,
        magic: this.pendingMagic,
      };

      const newStats = await this.callPostSpendPoints(
        localStorage.getItem("sessionId"),
        this.currentCharacter.number,
        spendPoints
      );

      this.$store.commit("setCurrentPlayerStats", newStats.newHeroStats);

      this.isSpendingPoints = false;

      // Tutorial Flags
      this.$store.commit("intro/setLetsGoToBattle", true);
      this.$store.commit("intro/setAfterStartingGuide", false);

      if (
        spendPoints.attack > spendPoints.gathering ||
        spendPoints.magic > spendPoints.gathering
      ) {
        this.$store.commit("intro/setIsWarriorStats", true);
      } else {
        this.$store.commit("intro/setIsWarriorStats", false);
      }
    },
    clickMiscStats() {
      this.isMiscExpanded = !this.isMiscExpanded;

      if (this.isMiscExpanded) {
        this.isPrimaryExpanded = false;
      }
    },
    clickPrimaryStats() {
      this.isPrimaryExpanded = !this.isPrimaryExpanded;

      if (this.isPrimaryExpanded) {
        this.isMiscExpanded = false;
      }
    },
    resetStatPoints() {
      this.$store.commit("resetStatPoints");
    },
    revertPendingPoints() {
      this.pendingCrafting = 0;
      this.pendingGathering = 0;
      this.pendingAttack = 0;
      this.pendingDefense = 0;
      this.pendingSpeed = 0;
      this.pendingEndurance = 0;
      this.pendingLuck = 0;
      this.pendingMagic = 0;
      this.pendingSpentPoints = 0;
    },
    increasePendingCrafting() {
      this.pendingCrafting++;
      this.pendingSpentPoints++;
    },
    increasePendingGathering() {
      this.pendingGathering++;
      this.pendingSpentPoints++;
    },
    increasePendingAttack() {
      this.pendingAttack++;
      this.pendingSpentPoints++;
    },
    increasePendingDefense() {
      this.pendingDefense++;
      this.pendingSpentPoints++;
    },
    increasePendingSpeed() {
      this.pendingSpeed++;
      this.pendingSpentPoints++;
    },
    increasePendingEndurance() {
      this.pendingEndurance++;
      this.pendingSpentPoints++;
    },
    increasePendingLuck() {
      this.pendingLuck++;
      this.pendingSpentPoints++;
    },
    increasePendingMagic() {
      this.pendingMagic++;
      this.pendingSpentPoints++;
    },
  },
  computed: {
    isUnEquipping() {
      return this.$store.state.isUnEquipping;
    },
    isInventoryVisible() {
      return this.$store.state.isInventoryVisible;
    },
    isApplyRevertPointsShowing() {
      if (
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          null ||
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          "undefined"
      ) {
        return false;
      }
      return (
        this.$store.state.characters[this.$store.state.currentCharacter].stats
          .levelStatPoints > 0 &&
        this.$store.state.characters[this.$store.state.currentCharacter].stats
          .levelStatPoints == this.pendingSpentPoints
      );
    },
    currentCharacter() {
      return this.$store.state.characters[this.$store.state.currentCharacter];
    },
    isSelectedCharacterLevel1() {
      if (
        this.$store.state.characters[this.$store.state.currentCharacter] &&
        this.$store.state.characters[this.$store.state.currentCharacter].stats
      ) {
        let stats =
          this.$store.state.characters[this.$store.state.currentCharacter]
            .stats;

        return stats.level < 4 && stats.levelStatPoints === 0;
      } else {
        return false;
      }
    },
    currentBonusStats() {
      return this.$store.state.equipmentStats();
    },
    hasLevelUp() {
      if (
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          null ||
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          "undefined"
      ) {
        return false;
      }
      return (
        this.$store.state.characters[this.$store.state.currentCharacter].stats
          .levelStatPoints -
          this.pendingSpentPoints >
        0
      );
    },
    levelStatPoints() {
      if (
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          null ||
        this.$store.state.characters[this.$store.state.currentCharacter] ==
          "undefined"
      ) {
        return false;
      } else {
        return (
          this.$store.state.characters[this.$store.state.currentCharacter].stats
            .levelStatPoints - this.pendingSpentPoints
        );
      }
    },
  },
  mounted() {
    this.$store = useStore();

    //Creating a lot of watchers here to watch for various events - Only called after character loaded in "LoginLoading.vue"
    // this.$store.watch(
    //   (state) => state.isEquipmentVisible,
    //   (value) => {
    //     this.isVisible = value;
    //   }
    // );

    this.$store.watch(
      (state) => state.isInventoryVisible,
      (value) => {
        this.isInventoryOpen = value;
      }
    );

    this.$store.watch(
      (state) => state.currentCharacter,
      () => {
        this.revertPendingPoints();
      }
    );
    this.$store.watch(
      (state) =>
        state.characters[state.currentCharacter] != null
          ? state.characters[state.currentCharacter].stats
          : null,
      () => {
        // console.log(value);
        this.revertPendingPoints();
      }
    );

    this.isVisible = this.$store.state.isEquipmentVisible;
  },
  beforeUnmount() {
    // This code will be executed just before the component is unmounted.
    this.$store.commit("setInventoryEquipFilter", "");
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

$itemSpacing: 51px;
$mobileItemSpacing: 45px;

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1;

  @media only screen and (max-width: 576px) {
    overflow-y: scroll;
  }
  #unequip-all {
    position: absolute;
    bottom: 3px;
    left: 12px;
    width: 26px;
    height: 26px;
    border: none;
    background: url("../assets/ui/unequip-button.png") no-repeat center center;
    background-size: cover;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    &:hover {
      transform: scale(1.1);
    }
    &:disabled {
      animation: rotateAnimation 300ms linear infinite;
    }
  }
  .equipment-panel {
    width: 757px;
    height: 721px;
    background: url("https://cdn.dragoncrypto.io/uiassets/equipment.png")
      no-repeat top left;
    position: relative;
    transition: all 0.25s linear;
    opacity: 1;
    pointer-events: all;

    @media only screen and (max-width: 576px) {
      width: 100%;
      height: 1309px;
      background: url("https://cdn.dragoncrypto.io/uiassets/equipment-mobile.png")
        no-repeat top left;
      background-size: contain;
      top: 0;
      position: absolute;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    &.inventory {
      margin-left: 420px;
      transition: all 0.25s linear;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.25s linear;
    }
    div#equipment-step {
      position: absolute;
      width: 451px;
      height: 511px;
      top: 188px;
      left: 19px;
    }
    .equipment {
      position: absolute;
      width: 451px;
      height: 511px;
      top: 188px;
      left: 19px;

      &.animate-highlighted {
        background: rgb(255 189 0 / 20%);
        border: 5px #ffa900 dashed;
        display: grid;
        align-items: center;
        justify-items: center;
        &:after {
          content: "Your equipped items and various areas are shown here";
          font-size: 1.8rem;
          color: #ffda91c2;
          width: 80%;
          font-family: Cinzel, serif;
        }
      }
      @media only screen and (max-width: 576px) {
        width: 100%;
        min-height: 500px;
        position: absolute;
        top: 138px;
      }

      .item {
        position: absolute;
      }

      .bottom {
        position: absolute;
        bottom: 54px;
        left: 150px;
        @media only screen and (max-width: 576px) {
          left: 32%;
          top: 77.5%;
        }

        .hand1 {
          @media only screen and (max-width: 576px) {
            left: 0;
          }
        }

        .hand2 {
          left: $itemSpacing;
          @media only screen and (max-width: 576px) {
            left: calc($mobileItemSpacing * 2);
          }
        }

        .utility-pet {
          position: absolute;
          display: block;
          width: 44px;
          height: 44px;
          left: calc($itemSpacing * 2);
          @media only screen and (max-width: 576px) {
            left: calc($mobileItemSpacing * 3);
          }
        }
        /* .utility {
          left: calc($itemSpacing * 2);
          @media only screen and (max-width: 576px) {
            left: calc($mobileItemSpacing * 3);
          }
        } */
      }

      .left {
        top: 87px;
        position: absolute;
        left: 3px;

        .neck {
          top: $itemSpacing;
          @media only screen and (max-width: 576px) {
            top: $mobileItemSpacing;
          }
        }

        .shoulder {
          top: calc($itemSpacing * 2);
          @media only screen and (max-width: 576px) {
            top: calc($mobileItemSpacing * 2);
          }
        }

        .chest {
          top: calc($itemSpacing * 3);
          @media only screen and (max-width: 576px) {
            top: calc($mobileItemSpacing * 3);
          }
        }

        .belt {
          top: calc($itemSpacing * 4);
          @media only screen and (max-width: 576px) {
            top: calc($mobileItemSpacing);
          }
        }

        .legs {
          top: calc($itemSpacing * 5);
          @media only screen and (max-width: 576px) {
            top: calc($mobileItemSpacing * 5);
          }
        }

        .boots {
          top: calc($itemSpacing * 6);
          @media only screen and (max-width: 576px) {
            top: calc($mobileItemSpacing * 6);
          }
        }
      }

      .right {
        top: 143px;
        position: absolute;
        right: $itemSpacing;
        @media only screen and (max-width: 576px) {
          top: $mobileItemSpacing;
        }

        .arms {
          @media only screen and (max-width: 576px) {
            top: $mobileItemSpacing;
          }
        }
        .gloves {
          top: $itemSpacing;
          @media only screen and (max-width: 576px) {
            top: $mobileItemSpacing;
          }
        }

        .back {
          top: calc($itemSpacing * 2);
          @media only screen and (max-width: 576px) {
            top: calc($mobileItemSpacing * 2);
          }
        }

        .ring1 {
          top: calc($itemSpacing * 3);
          @media only screen and (max-width: 576px) {
            top: calc($mobileItemSpacing * 3);
          }
        }

        .ring2 {
          top: calc($itemSpacing * 4);
          @media only screen and (max-width: 576px) {
            top: calc($mobileItemSpacing * 4);
          }
        }
      }
    }

    span {
      display: block;
      position: absolute;
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;
      vertical-align: middle;
      align-content: center;

      width: 210px;
      right: 41px;
      bottom: 50px;
    }

    .stats-container {
      font-size: 70%;
      position: absolute;
      width: 264px;
      right: 18px;
      top: 186px;
      display: flex;
      flex-direction: column;
      z-index: 5;

      @media only screen and (max-width: 576px) {
        top: 48.5%;
        left: 56%;
        transform: translateX(-50%);
      }

      .stat-set-container {
        position: relative;
        display: block;
        width: 100%;
        max-height: 35px;
        transition: max-height 0.35s linear;
        overflow-y: clip;

        &.level-up {
          .increase {
            display: inline-block;
          }
        }

        &.expanded {
          max-height: 600px;
          .small-header::after {
            transform-origin: center 55%;
            transform: rotate(180deg);
            transition: transform 0.25s linear;
          }
        }

        .small-header::after {
          content: "";
          position: absolute;
          width: 18px;
          height: 15px;
          right: 10px;
          top: 8px;
          background: url("https://cdn.dragoncrypto.io/uiassets/accordian-handle.png")
            no-repeat center;
          transition: transform 0.25s linear;
        }

        .increase {
          display: none;
          cursor: pointer;
          left: -22px;
          top: 0;
          font-weight: bold;
          background: #562020;
          border-radius: 10px;
          padding: 0px 5px 2px 5px;
          animation: glowing-sm 5s infinite;
          position: absolute;
        }

        .points,
        .desc,
        .points,
        .bonus {
          display: inline-block;
        }

        .desc,
        .points {
          margin-bottom: 5px;
          border-bottom: 1px solid #2d2a27;
          padding-bottom: 6px;
          position: relative;
        }

        .desc {
          font-weight: bold;
          color: #928174;
          width: 45%;
          margin-left: 6%;
          text-align: left;
          position: relative;
        }

        .points {
          width: 27%;
          text-align: right;
          position: relative;
        }

        .bonus {
          width: 14%;
          text-align: left;
          position: relative;
          padding-left: 3%;
        }
      }

      h5.small-header {
        cursor: pointer;
        font-size: 150%;
        height: 30px;
        background: url("https://cdn.dragoncrypto.io/uiassets/small-header-bg.webp")
          repeat center center;
        display: grid;
        align-items: center;
        box-shadow: inset 0 0 10px 0 #000;
        border: 2px #4e4134 solid;
        margin: 0 0 8px;
        color: #be9177;
        font-family: "IM Fell English", serif;
        text-transform: capitalize;
        font-weight: normal;
        text-shadow: 0 1px 3px #000;
      }
    }

    .rank {
      color: #8eacbb;
      top: 135px;
      width: 100%;
      @media only screen and (max-width: 576px) {
        width: 100%;
        top: 8.5%;
      }
    }

    .details {
      width: 480px;
      left: 0;
      top: 200px;
      @media only screen and (max-width: 576px) {
        width: 100%;
        top: 13%;
      }

      span {
        color: #8eacbb;
        display: block;
        position: relative;
        width: 100%;
      }

      .level {
        font-size: 80%;
        color: #739eb1;
      }
    }

    .close-button {
      position: absolute;
      top: 48px;
      right: 0;
    }
  }
}
@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
