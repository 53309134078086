<template>
  <span class="inline-loading">
    <span class="bounce1"></span>
    <span class="bounce2"></span>
  </span>
</template>

<script>
export default {
  name: "GenericLoading",
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.inline-loading {
  width: 15px !important;
  height: 15px;
  display: inline-block;
  align-items: center;
  justify-items: center;
  position: relative;
  background: #0000003d;
  z-index: 10;
  top: 3px;
  .bounce1,
  .bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    animation: sk-bounce 2s infinite ease-in-out;
  }
  .bounce1 {
    background-color: #ff9f00;
  }
  .bounce2 {
    animation-delay: -1s;
    background-color: #fabc90;
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
</style>
