<template>
  <div class="attack-buttons-container">
    <button
      id="attack-button-left"
      :class="[
        'combat-button attack left',
        disableButtons ? 'loading' : '',
        isBagOpen ? 'hide' : '',
      ]"
      :disabled="disableButtons"
      @mouseleave="resetHoveredDesc()"
      @mouseenter="
        hasLeftHandWeapon
          ? processMouseOver(`Use ` + getSlotDesc(0))
          : processMouseOver('Use Your Fists')
      "
      @click="useSlotAttack('left')"
      :title="
        hasLeftHandWeapon
          ? `${leftHandWeaponDurability}% weapon health left before it breaks`
          : ''
      "
    >
      <span :style="`background-image: url(${leftWeaponImage})`"></span>
      <span class="charged-up"></span>
      <span class="durability" v-if="hasLeftHandWeapon && !disableButtons">
        <svg :class="leftHandWeaponDurability < 25 ? 'low' : 'high'">
          <circle cx="70" cy="70" r="66"></circle>
          <circle
            cx="70"
            cy="70"
            r="66"
            :style="`--percent: ${leftHandWeaponDurability}`"
          ></circle>
        </svg>
      </span>
      Attack
    </button>
    <div :class="['menu', disableButtons ? 'loading' : '']">
      <input
        type="checkbox"
        href="#"
        class="menu-open"
        name="menu-open"
        ref="toggleBag"
        id="menu-open"
      />
      <label
        for="menu-open"
        id="menu-open-step"
        @click="toggleBagOpen"
        @mouseenter="
          processMouseOverWithSecondaryDesc(
            `Combat Bag`,
            `Contains Your Consumables`
          )
        "
        @mouseleave="resetHoveredDesc()"
        :class="[
          'menu-open-button',
          'combat-button',
          disableButtons ? 'loading' : '',
        ]"
      >
        <span
          :style="
            'background-image: url(' +
            'https://cdn.dragoncrypto.io/equip/bag.png' +
            ')'
          "
        ></span>
        Open Bag
      </label>
      <template v-for="(item, index) in getHotBarItems" :key="item.id">
        <template v-if="index === 0 && hasLeftHandWeapon"> </template>
        <template v-else-if="index === 1 && hasRightHandWeapon"> </template>
        <template v-else>
          <button
            :class="['menu-item', 'combat-button']"
            @click="processBagItemClick(index)"
            @mouseenter="
              processMouseOverWithSecondaryDesc(
                `Use ${getSlotDesc(index)}`,
                `Heals ${getSlotEffectDesc(index)}% of HP`
              )
            "
            @mouseleave="resetHoveredDesc()"
            :disabled="disableButtons"
            :title="getSlotDesc(index)"
          >
            <span
              class="image"
              :style="'background-image: url(' + getSlotImage(index) + ')'"
            >
            </span>
            {{ getSlotQuantity(index) }}
          </button>
        </template>
      </template>
    </div>

    <button
      id="attack-button-right"
      v-if="hasRightHandWeapon"
      :class="[
        'combat-button attack right',
        disableButtons ? 'loading' : '',
        isBagOpen ? 'hide' : '',
      ]"
      :disabled="disableButtons"
      @mouseenter="processMouseOver('Use ' + getSlotDesc(1))"
      @mouseleave="resetHoveredDesc()"
      @click="useSlotAttack('right')"
      :title="
        hasRightHandWeapon
          ? `${rightHandWeaponDurability}% weapon health left before it breaks`
          : ''
      "
    >
      <span :style="`background-image: url(${rightWeaponImage})`"></span>
      <span class="durability" v-if="hasRightHandWeapon && !disableButtons">
        <svg :class="rightHandWeaponDurability < 25 ? 'low' : 'high'">
          <circle cx="70" cy="70" r="66"></circle>
          <circle
            cx="70"
            cy="70"
            r="66"
            :style="`--percent: ${rightHandWeaponDurability}`"
          ></circle>
        </svg>
      </span>
      Attack
    </button>

    <!-- Flee Button -->
    <button
      id="flee-button"
      :class="[
        'combat-button',
        'flee',
        disableButtons ? 'loading' : '',
        isBagOpen ? 'hide' : '',
        hasRightHandWeapon ? 'smaller' : '',
      ]"
      :disabled="disableButtons"
      @click="fleeBattle"
      @mouseenter="processMouseOver('Flee From The Battle!')"
      @mouseleave="resetHoveredDesc()"
    >
      <span
        :style="
          'background-image: url(' +
          'https://cdn.dragoncrypto.io/equip/flee.png' +
          ')'
        "
      ></span>

      Flee
    </button>

    <button
      v-if="disableButtons"
      :class="['combat-button loading-icon loading']"
      disabled
    >
      <!-- <span :style="`background-image: url(${loadingImage})`"></span> -->
      <div class="clock">
        <div class="minutes"></div>
        <div class="hours"></div>
      </div>
      Loading...
    </button>
    <div v-if="!disableButtons" class="item-description">
      <div class="text">
        {{ hoveredItem }}
        <span v-if="hoveredItemDesc !== ''">{{ hoveredItemDesc }}</span>
      </div>
    </div>
    <div class="energy-bar">
      <EnergyBeam
        v-if="hasLeftHandWeapon"
        :energy="parseFloat((heroStats.energy / heroStats.totalEnergy) * 100)"
      />
      <span
        :class="['energy', !hasLeftHandWeapon ? 'disabled' : '']"
        :style="{ width: energyPercentage + '%' }"
      ></span>
      <!-- @click="consumeEnergy" -->
      <button
        id="use-energy-button"
        @click="useAbility('left')"
        :title="
          heroStats.energy < 25
            ? `Not Enough Energy: ${heroStats.energy}/${heroStats.totalEnergy}`
            : 'Use your energy in the next hit'
        "
        v-if="hasLeftHandWeapon"
        :disabled="
          toggleUseEnergy ||
          disableButtons ||
          !hasLeftHandWeapon ||
          heroStats.energy < 25
        "
        :class="[
          'combat-button',
          'consume-energy',
          disableButtons ? 'loading' : '',
          isBagOpen ? 'hide' : '',
        ]"
        @mouseenter="
          processMouseOverWithSecondaryDesc(
            getHoverMessage(0),
            `Costs 25 Energy. You Have ${heroStats.energy}/${heroStats.totalEnergy}`
          )
        "
        @mouseleave="resetHoveredDesc()"
      >
        <span :style="`background-image: url(${masteryIcon});`"></span>
      </button>
    </div>
    <AutoplayButton :disabled="isConsuming" />

    <button
      id="pet-button"
      v-if="selectedPet"
      @click="usePetAttack()"
      @mouseenter="processMouseOver('Use Your Pet')"
      @mouseleave="resetHoveredDesc()"
      :title="
        hasUsedPet ? 'You have already used your pet once' : 'Use your pet'
      "
      :disabled="disableButtons || hasUsedPet"
      :class="[
        'combat-button',
        'pet-attack',
        disableButtons ? 'loading' : '',
        isBagOpen ? 'hide' : '',
      ]"
    >
      <span :style="`background-image: url(${selectedPet.image})`"></span>
    </button>
  </div>
  <!-- filters -->
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
    <defs>
      <filter id="shadowed-goo">
        <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
        <feColorMatrix
          in="blur"
          mode="matrix"
          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
          result="goo"
        />
        <feGaussianBlur in="goo" stdDeviation="3" result="shadow" />
        <feColorMatrix
          in="shadow"
          mode="matrix"
          values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 -0.2"
          result="shadow"
        />
        <feOffset in="shadow" dx="1" dy="1" result="shadow" />
        <feComposite in2="shadow" in="goo" result="goo" />
        <feComposite in2="goo" in="SourceGraphic" result="mix" />
      </filter>
      <filter id="goo">
        <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
        <feColorMatrix
          in="blur"
          mode="matrix"
          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
          result="goo"
        />
        <feComposite in2="goo" in="SourceGraphic" result="mix" />
      </filter>
    </defs>
  </svg>
</template>

<script>
import { computed, ref } from "vue";
import { useKeypress } from "vue3-keypress";
import { useStore } from "vuex";
import { useSound } from "../../composables/sound";
import Constants from "../../consts/constants";
import AutoplayButton from "./AutoplayButton.vue";
import EnergyBeam from "./EnergyBeam.vue";

import bagOpen from "../../assets/sound/bag-open.mp3";
import hoverSound from "../../assets/sound/break.mp3";
import activateEnergy from "../../assets/sound/energy.mp3";
import constants from "../../consts/constants";

export default {
  name: "AttackButton",
  components: {
    AutoplayButton,
    EnergyBeam,
  },
  props: {
    size: {
      type: String,
    },
  },
  emits: ["combatAction", "flee"],
  setup() {
    const store = useStore();
    const toggleBag = ref(null);
    const isBagOpen = ref(false);
    const hoveredItem = ref("");
    const hoveredItemDesc = ref("");
    const toggleUseEnergy = ref(false);

    const numericPress = (event) => {
      console.log(event.event.key);
      if (!store.state.combat.isAutoplayOn) {
        // Dont allow keyboard shortcuts if buttons disabled or chat open
        if (!disableButtons.value && !store.state.chat.isChatOpen) {
          const keyValue = event.event.key * 1 - 1;
          useSlotCombat(keyValue);
        }
      } else {
        store.commit(
          "setNotification",
          "Please disable autoplay to use keyboard shortcuts"
        );
      }
    };
    useKeypress({
      keyEvent: "keyup",
      keyBinds: [
        {
          keyCode: 49,
          success: numericPress,
        },
        {
          keyCode: 50,
          success: numericPress,
        },
        {
          keyCode: 51,
          success: numericPress,
        },
        {
          keyCode: 52,
          success: numericPress,
        },
        {
          keyCode: 53,
          success: numericPress,
        },
        {
          keyCode: 54,
          success: numericPress,
        },
        {
          keyCode: 55,
          success: numericPress,
        },
        {
          keyCode: 56,
          success: numericPress,
        },
      ],
    });

    /**
     * Methods
     */
    const playOnHover = useSound(
      // "https://cdn.dragoncrypto.io/sound/break.mp3",
      hoverSound,
      {
        volume: store.state.soundVolume,
        interrupt: true,
      }
    );
    const playBagSound = useSound(
      // "https://cdn.dragoncrypto.io/sound/bag-open.mp3",
      bagOpen,
      { volume: store.state.soundVolume }
    );

    const toggleBagOpen = () => {
      isBagOpen.value = !isBagOpen.value;
      playBagSound.play();
    };

    const playEnergyUse = useSound(
      // "https://cdn.dragoncrypto.io/sound/break.mp3",
      activateEnergy,
      {
        volume: store.state.soundVolume,
        interrupt: true,
      }
    );

    const consumeEnergy = () => {
      playEnergyUse.play();
      toggleUseEnergy.value = true;
    };

    const getSlotImage = (index) => {
      const slotItem = getHotBarItems.value[index];
      if (slotItem) {
        return `https://cdn.dragoncrypto.io/equip/${slotItem.imageName}`;
      } else {
        return "";
      }
    };

    const processBagItemClick = (index) => {
      useSlotCombat(index);
    };

    // Use Weapon in the hotbar
    const useSlotAttack = (hand) => {
      if (hand === "left") {
        if (hasLeftHandWeapon.value) {
          store.state.doCombatDamage(0);
        } else {
          // Use Fists
          store.state.doCombatDamage(-1);
        }
      } else if (hand === "right" && hasRightHandWeapon.value) {
        store.state.doCombatDamage(1);
      }
    };

    // Only using ability with the left handed weapon
    const useAbility = (hand) => {
      if (hand === "left" && hasLeftHandWeapon.value) {
        store.state.doCombatDamage(0, true); // passing use ability as true
      }
      // else if (hand === "right" && hasRightHandWeapon.value) {  // In case right handed ability is ever required
      //   store.state.doCombatDamage(1, true);
      // }
    };

    const usePetAttack = () => {
      store.state.doPetDamage();
    };

    // Use a particular slot from the hotbar
    const useSlotCombat = (number) => {
      // close bag if open before processing anything
      isBagOpen.value = false;
      toggleBag.value.checked = false;

      const hotbarSlot = store.state.hotbar()[number];
      if (hotbarSlot && hotbarSlot.type === Constants.slots.hand1) {
        store.state.doCombatDamage(number);
        return;
      }

      if (hotbarSlot && hotbarSlot.type === Constants.useable.consumable) {
        if (!isDead.value && isResurrectionItem(hotbarSlot)) {
          store.commit("setNotification", "You are not dead 🙈");
          return;
        }
        store.commit("setConsumeItemCombat", hotbarSlot.staticId);
        return;
      }
    };
    const fleeBattle = () => {
      store.commit("setFightFlee");
    };
    const getSlotDesc = (number) => {
      if (getHotBarItems.value[number]) {
        return getHotBarItems.value[number].name;
      }
      return "";
    };
    const getHoverMessage = (number) => {
      if (hasAbilityReady.value) {
        return "Unleash " + abilityName.value;
      }
      if (getHotBarItems.value[number]) {
        return "Use " + getHotBarItems.value[number].name;
      }

      return "Use Fists";
    };
    const isResurrectionItem = (item) => {
      return item.stats.action == Constants.consumableActions.resurrection;
    };
    const isDead = computed(() => {
      return store.state.characters[store.state.currentCharacter].stats.isDead;
    });
    const selectedPet = computed(() => {
      return store.state.pets.selectedPet;
    });
    const getSlotEffectDesc = (number) => {
      // TODO: right now only returning heal amount. Refactor to return effect desc for any type of slot item i.e. weapon, consumable etc
      if (getHotBarItems.value[number]) {
        if (getHotBarItems.value[number].stats.amount) {
          return getHotBarItems.value[number].stats.amount;
        }
      }
      return "";
    };
    const getSlotQuantity = (number) => {
      if (getHotBarItems.value[number]) {
        // console.log(getHotBarItems.value[number]);
        return getHotBarItems.value[number].quantity;
      }
      return "";
    };
    const resetHoveredDesc = () => {
      hoveredItem.value = "";
      hoveredItemDesc.value = "";
    };
    const processMouseOver = (desc) => {
      playOnHover.play();
      hoveredItem.value = desc;
    };
    const processMouseOverWithSecondaryDesc = (desc, secondary) => {
      playOnHover.play();
      hoveredItem.value = desc;
      hoveredItemDesc.value = secondary;
    };
    /**
     * Computed
     */
    // One generic loading method to disable buttons
    const disableButtons = computed(() => {
      return (
        waitingForTurn.value ||
        isTurnHappening.value ||
        isConsuming.value ||
        isFleeing.value
      );
    });
    const getHotBarItems = computed(() => {
      return store.state
        .hotbar()
        .filter(
          (item) =>
            !item.stats.action ||
            item.stats.action == Constants.consumableActions.heal
        );
    });
    const hasAbilityReady = computed(() => {
      return getHotBarItems.value[0] && heroStats.value.energy >= 25;
    });
    const hasLeftHandWeapon = computed(() => {
      return (
        getHotBarItems.value[0] &&
        getHotBarItems.value[0].type === Constants.slots.hand1
      );
    });
    const weaponMastery = computed(() => {
      if (hasLeftHandWeapon.value && getHotBarItems.value[0].stats.mastery) {
        const weapon = store.state.hotbar()[0];
        return weapon.stats.mastery[0];
      }
      return "";
    });
    const abilityName = computed(() => {
      if (constants.masteryAbilities[weaponMastery.value]) {
        return constants.masteryAbilities[weaponMastery.value];
      }
      return "";
    });
    const hasRightHandWeapon = computed(() => {
      return (
        getHotBarItems.value[1] &&
        getHotBarItems.value[1].type === Constants.slots.hand1
      );
    });
    const leftHandWeaponDurability = computed(() => {
      if (hasLeftHandWeapon.value) {
        const weapon = store.state.hotbar()[0];
        return Math.ceil(
          (weapon.stats.durability / weapon.stats.totalDurability) * 100
        );
      }
      return 0;
    });
    const rightHandWeaponDurability = computed(() => {
      if (hasRightHandWeapon.value) {
        const weapon = store.state.hotbar()[1];
        return Math.ceil(
          (weapon.stats.durability / weapon.stats.totalDurability) * 100
        );
      }
      return 0;
    });
    const leftWeaponImage = computed(() => {
      const weapon = store.state.hotbar()[0];
      if (hasLeftHandWeapon.value) {
        return `https://cdn.dragoncrypto.io/equip/${weapon.imageName}`;
      } else {
        return `https://cdn.dragoncrypto.io/equip/fist.png`;
      }
    });
    const rightWeaponImage = computed(() => {
      if (hasRightHandWeapon.value) {
        const weapon = store.state.hotbar()[1];
        return `https://cdn.dragoncrypto.io/equip/${weapon.imageName}`;
      } else {
        return "";
      }
    });
    const loadingImage = computed(() => {
      return `https://cdn.dragoncrypto.io/icons/hourglass.png`;
    });
    const isTurnHappening = computed(() => {
      // return store.state.isTurnHappening;
      return (
        store.state.combat.isHeroAttacking ||
        store.state.combat.isEnemyAttacking ||
        store.state.combat.isPetAttacking
      );
    });
    const masteryIcon = computed(() => {
      return (
        "https://cdn.dragoncrypto.io/mastery/" +
        weaponMastery.value.toLowerCase() +
        ".png"
      );
    });
    const waitingForTurn = computed(() => {
      return store.state.isTurnHappening;
    });
    const isConsuming = computed(() => {
      return store.state.isUsingConsumable;
    });
    const isFleeing = computed(() => {
      return store.state.isFleeing;
    });
    const hasUsedPet = computed(() => {
      const petId = selectedPet.value?.id;
      return !petId || store.state.currentEncounter.petsUsed?.includes(petId);
    });
    const heroStats = computed(() => {
      return store.state.characters[store.state.currentCharacter].stats;
    });
    const energyPercentage = computed(() => {
      return (heroStats.value.energy / heroStats.value.totalEnergy) * 100;
    });

    return {
      //Data
      toggleBag,
      isBagOpen,
      hoveredItem,
      hasLeftHandWeapon,
      hasRightHandWeapon,
      hoveredItemDesc,
      toggleUseEnergy,
      //Methods
      playOnHover,
      toggleBagOpen,
      getSlotImage,
      useSlotCombat,
      usePetAttack,
      useSlotAttack,
      useAbility,
      isConsuming,
      processBagItemClick,
      fleeBattle,
      getSlotDesc,
      getHoverMessage,
      getSlotEffectDesc,
      getSlotQuantity,
      processMouseOver,
      processMouseOverWithSecondaryDesc,
      resetHoveredDesc,
      consumeEnergy,
      // Computed
      leftWeaponImage,
      rightWeaponImage,
      loadingImage,
      getHotBarItems,
      isTurnHappening,
      waitingForTurn,
      disableButtons,
      isFleeing,
      selectedPet,
      hasUsedPet,
      leftHandWeaponDurability,
      rightHandWeaponDurability,
      hasAbilityReady,
      heroStats,
      energyPercentage,
      weaponMastery,
      abilityName,
      masteryIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
@import "~@/assets/scss/globals.scss";

.attack-buttons-container {
  display: block;
  /* grid-template-columns: 1fr 1fr 1fr; */
  align-items: center;
  justify-items: center;
  position: absolute;
  bottom: 0;
  width: 500px;
  height: 298px;
  background-image: url("https://cdn.dragoncrypto.io/uiassets/combat-area.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  @media only screen and (max-width: 576px) {
    width: 300px;
    height: 159px;
  }
  .combat-button {
    background-color: #1d1712;
    position: absolute;
    width: 130px;
    height: 130px;
    border-radius: 100%;
    text-indent: -9999px;
    border: none;
    display: block;
    transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
    transition-duration: 200ms;
    opacity: 1;

    /* &.ability-ready {
      animation: glowing 1s ease-in-out infinite alternate;
    } */
    .durability {
      svg {
        width: 142px;
        height: 142px;
        @media only screen and (max-width: 576px) {
          /* width: 62px;
          height: 62px; */
          transform: scale(0.45) translate(0, 0) rotate(-90deg);
          left: -105%;
          top: -107%;
        }
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        &.low {
          circle {
            &:last-of-type {
              animation: pulseStrokeDanger 2s ease infinite;
            }
          }
        }
        circle {
          width: 100%;
          height: 100%;
          fill: none;
          stroke: #2c333c;
          stroke-width: 2;
          stroke-linecap: round;

          &:last-of-type {
            stroke-dasharray: 414.69px;
            stroke-dashoffset: calc(
              414.69px - (414.69px * var(--percent)) / 100
            );
            stroke: #0ea400;
            transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
            transition-duration: 400ms;
          }
        }
      }
    }
    @media only screen and (max-width: 576px) {
      width: 60px;
      height: 60px;
    }
    &.loading-icon {
      bottom: 120px;
      left: 50%;
      z-index: 5;
      @media only screen and (max-width: 576px) {
        bottom: 60px;
      }
      &:before {
        animation: none !important;
      }
      transform: translateX(-50%);
      &:hover {
        transform: translateX(-50%);
      }
      .clock {
        position: absolute;
        width: 96px;
        height: 96px;
        border-radius: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #151c2c;
        background: radial-gradient(
          circle,
          #151c2c 0%,
          #112946 36%,
          #204872 66%,
          #28588e 99%
        );
        background-size: 400% 400%;
        animation: gradient 10s ease infinite;
        @media only screen and (max-width: 576px) {
          width: 44px;
          height: 44px;
        }
        .hours {
          position: absolute;
          width: 50px;
          height: 5px;
          // background: red;
          top: 45px;
          left: 26px;
          animation: clock-spin 5s linear 0s infinite;
          @media only screen and (max-width: 576px) {
            top: 20px;
            left: -3px;
          }
          &:before {
            content: "";
            height: 6px;
            width: 36px;
            position: absolute;
            right: 20px;
            background: rgba(255, 255, 255, 0.735);
            border-radius: 5px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
            @media only screen and (max-width: 576px) {
              width: 16px;
            }
          }
        }
        .minutes {
          position: absolute;
          width: 50px;
          height: 5px;
          // background: blue;
          top: 45px;
          left: 25px;
          animation: clock-spin 0.8s linear 0s infinite;
          @media only screen and (max-width: 576px) {
            top: 20px;
            left: -3px;
          }
          &:before {
            content: "";
            height: 6px;
            width: 45px;
            position: absolute;
            right: 22px;
            background: rgba(255, 255, 255, 0.735);
            border-radius: 5px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
            @media only screen and (max-width: 576px) {
              width: 25px;
            }
          }
        }
      }
    }
    &.attack {
      bottom: 120px;
      @media only screen and (max-width: 576px) {
        bottom: 60px;
      }

      &.left {
        left: 46px;
        &.loading {
          left: 50%;
          transform: translateX(-50%);
          /* animation: flyRight 200ms ease-in-out; */
        }
      }
      &.right {
        right: 46px;
        &.loading {
          right: 50%;
          transform: translateX(50%);
          /* animation: flyRight 200ms ease-in-out; */
        }
      }
    }
    &.flee {
      background-image: url("https://cdn.dragoncrypto.io/equip/flee.png");
      right: 46px;
      bottom: 120px;
      @media only screen and (max-width: 576px) {
        bottom: 60px;
      }
      &.loading {
        right: 50%;
        transform: translateX(50%);
        /* animation: flyLeft 200ms ease-in-out; */
      }
      &.smaller {
        left: 50%;
        transform: translateX(-50%);
        right: auto !important;
        width: 80px;
        height: 80px;
        @media only screen and (max-width: 576px) {
          bottom: 40px;
          width: 60px;
          height: 60px;
        }
        &:hover {
          transform: scale(1.1, 1.1) translateX(-48%);
        }
      }
    }
    &.pet-attack {
      left: 24px;
      bottom: 11px;
      width: 75px;
      height: 75px;

      @media only screen and (max-width: 576px) {
        width: 60px;
        height: 60px;
        bottom: 0;
      }
      &.loading {
        left: 50%;
        bottom: 40%;
        transform: translate(-50%, -50%);
      }
    }
    &.hide {
      opacity: 0;
      transform: scale(0.7, 0.7);
      display: none;
    }
    &:disabled {
      cursor: not-allowed;
      filter: grayscale(100);
    }
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &:before {
      content: "";
      border: none;
      background-image: url("https://cdn.dragoncrypto.io/uiassets/attack-button.png");
      background-size: contain;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition-duration: 400ms;
      transform: scale(1, 1) translate3d(0, 0, 0);
    }
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    span {
      width: 76%;
      height: 76%;
      background-size: contain;
      background-position: center center;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 100%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    &:after {
      background-image: url("https://cdn.dragoncrypto.io/uiassets/attack-button-shine.png");
      top: 0;
      left: 0;
      z-index: 2;
      background-size: cover;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition-duration: 400ms;
    }
    /* &.loading {
      &:before {
        animation: rotate 0.5s infinite linear;
      }
    } */
    &:hover {
      transform: scale(1.1, 1.1) translate3d(0, 0, 0);
      &:after {
        transform: rotate(10deg);
      }
    }
  }
  .energy-bar {
    width: 289px;
    height: 10px;
    background: #4b4b4b;
    bottom: 13px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: inset 0 0 16px 9px rgb(0 0 0 / 56%);
    border: 1px #3a3a3a solid;
    display: grid;
    align-items: center;
    grid-template-rows: 1fr;
    @media only screen and (max-width: 576px) {
      width: 153px;
      height: 10px;
    }
    .consume-energy {
      span {
        background-size: 95% !important;
      }
    }
    p {
      font-size: 0.7rem;
      z-index: 2;
      margin: 0;
      text-shadow: 0 0 2px #000, 0 0 2px #000, 0 1px 3px #000;
      position: relative;
      top: -2px;
      opacity: 0.7;
    }
    span.energy {
      &.disabled {
        animation: none;
      }
      animation: pulse 3s infinite linear;
      width: 50%;
      height: 100%;
      display: block;
      position: absolute;
    }
    .consume-energy {
      left: 50%;
      bottom: -12px;
      width: 40px;
      height: 40px;
      z-index: 2;
      transform: translateX(-50%);
      span {
        background: url("../../assets/ui/bolt.svg") no-repeat center center;
        background-size: 60%;
      }
    }
  }
  .item-description {
    position: absolute;
    bottom: 37px;
    width: 280px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.2rem;
    text-shadow: 0 0 2px #000, 0 0 2px #000, 0 1px 3px #000;
    height: 75px;
    display: grid;
    align-items: center;
    padding: 0 10px;
    @media only screen and (max-width: 576px) {
      display: none;
    }
    span {
      font-size: 0.9rem;
      display: block;
      margin: 5px 0 0;
      opacity: 0.6;
    }
  }
}

//vars
$fg: #1d1712;
$bg: #8bc34a;
$pi: 3.14;

//config
$menu-items: 6+1;
$open-distance: 100px;
$open-distance-mobile: 70px;
$opening-angle: $pi * 2;

%goo {
  /* filter: url("#goo"); */
  // debug
  // background:rgba(255,0,0,0.2);
}
%ball {
  background: $fg;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  margin-left: -40px;
  position: absolute;
  top: 20px;
  color: white;
  text-align: center;
  line-height: 80px;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
}
%smallerBall {
  background: $fg;
  border-radius: 100%;
  width: 90px !important;
  height: 90px !important;
  margin-left: -40px;
  position: absolute !important;
  top: 20px;
  color: white;
  text-align: center;
  line-height: 80px;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
  @media only screen and (max-width: 576px) {
    width: 60px !important;
    height: 60px !important;
  }
}
.menu-open {
  display: none;
}
.menu-item {
  @extend %smallerBall;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: 6px;
  top: 15px;
  @media only screen and (max-width: 576px) {
    margin-left: 0;
    top: 0;
  }
  span {
    width: 70% !important;
    height: 70% !important;
  }
  &:before {
    background-image: url("https://cdn.dragoncrypto.io/uiassets/consumable-button.png") !important;
  }
}

/* .menu-open:checked + .menu-open-button {
  .hamburger-1 {
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  .hamburger-2 {
    transform: translate3d(0, 0, 0) scale(0.1, 1);
  }
  .hamburger-3 {
    transform: translate3d(0, 0, 0) rotate(-45deg);
  }
} */
.menu {
  @extend %goo;
  /* $width: 380px; */
  /* $height: 250px; */
  position: absolute;
  /* width: $width; */
  /* height: $height; */
  box-sizing: border-box;
  font-size: 20px;
  text-align: left;
  bottom: 212px;
  width: 130px;
  height: 130px;
  left: 50%;
  transform: translateX(-50%);
  transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
  transition-duration: 200ms;
  @media only screen and (max-width: 576px) {
    bottom: 107px;
    width: 60px;
    height: 60px;
  }
  &.loading {
    bottom: 120px;
    @media only screen and (max-width: 576px) {
      bottom: 60px;
    }
  }
}

.menu-item {
  &:hover {
    background-color: #2f2b2b;
    color: $fg;
  }
  @for $i from 1 through $menu-items {
    &:nth-child(#{$i + 2}) {
      transition-duration: 180ms;
    }
  }
}

.menu-open-button {
  @extend %ball;
  z-index: 2;
  transition-property: all;
  margin-left: 0 !important;
  top: 0;
  /* transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); */
  /* transition-duration: 400ms; */
  transform: scale(1, 1) translate3d(0, 0, 0);
  cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
    auto;
  &.loading {
    position: absolute;
    bottom: 120px !important;
  }
}
.menu-open-button:hover {
  transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}
.menu-open:checked + .menu-open-button {
  transition-timing-function: linear;
  transition-duration: 200ms;
  transform: scale(0.7, 0.7) translate3d(0, 0, 0);
  animation: rotateTurns 200ms ease-in-out;
  top: 10px !important;
  &:after {
    display: none;
  }
}

.menu-open:checked ~ .menu-item {
  margin-left: 21px;
  top: 31px;
  transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
  @media only screen and (max-width: 576px) {
    margin-left: 0;
    top: 10px;
  }
  @for $i from 1 through $menu-items {
    $angle: (($pi - $opening-angle)/2)+
      (($opening-angle/($menu-items - 1)) * ($i - 1));

    &:nth-child(#{$i + 2}) {
      transition-duration: 80ms+ (100ms * $i);
      transform: translate3d(
        math.cos($angle) * $open-distance,
        math.sin($angle) * $open-distance,
        0
      );
    }
  }
  @media only screen and (max-width: 576px) {
    @for $i from 1 through $menu-items {
      $angle: (($pi - $opening-angle)/2)+
        (($opening-angle/($menu-items - 1)) * ($i - 1));

      &:nth-child(#{$i + 2}) {
        transition-duration: 80ms+ (100ms * $i);
        transform: translate3d(
          math.cos($angle) * $open-distance-mobile,
          math.sin($angle) * $open-distance-mobile,
          0
        );
      }
    }
  }
}

.ability-ready {
  span.charged-up {
    position: absolute;
    width: 100px;
    height: 100px;
    background: #8a48f466;
    border-radius: 100%;
    z-index: 0;
    animation: fading-glow 2s ease-in-out infinite;
    z-index: 0;
  }

  /* &:before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    border-radius: 50%;
    border: 3px solid #fff;
    box-sizing: border-box;
    box-shadow: 0 0 10px #fff;
    animation: rotating-border 1s linear infinite,
      fading-glow 1s linear infinite; 
    transform: rotate(0deg);
    box-shadow: 0 0 2px 1px #8855ff;
  } */
}
@keyframes rotating-border {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fading-glow {
  0% {
    background-color: #8a48f446;
  }
  50% {
    background-color: #a775f866;
  }
  100% {
    background-color: #8a48f446;
  }
}
@keyframes pulseStrokeDanger {
  0% {
    stroke: #b31414;
  }
  50% {
    stroke: #e31313;
  }
  100% {
    stroke: #b31414;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes rotateTurns {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}
@keyframes flyRight {
  0% {
    left: 46px;
    transform: translateX(-50%);
  }
  100% {
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
  }
}
@keyframes glowing {
  from {
    box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px rgb(8, 136, 242),
      0 0 20px #0ff, 0 0 25px #8a00e6, 0 0 30px #a100e6, 0 0 35px #007fe6;
  }
  to {
    box-shadow: 0 0 10px #fff, 0 0 15px #a64dff, 0 0 20px #914dff,
      0 0 25px #9d4dff, 0 0 30px #8b4dff, 0 0 35px #9d4dff, 0 0 40px #8b4dff;
  }
}
@keyframes flyLeft {
  0% {
    right: 46px;
    left: auto;
    transform: translateX(-50%);
  }
  100% {
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    background: rgb(121, 43, 255);
    box-shadow: inset 0px 0px 10px 2px rgba(177, 117, 255, 0.5),
      0px 0px 30px 2px rgba(105, 135, 255, 0.8);
  }
  50% {
    background: rgb(121, 43, 255, 0.7);
    box-shadow: inset 0px 0px 10px 2px rgba(177, 117, 255, 0.5),
      0px 0px 20px 2px rgba(105, 135, 255, 0.3);
  }
  100% {
    background: rgb(121, 43, 255);
    box-shadow: inset 0px 0px 10px 2px rgba(177, 117, 255, 0.5),
      0px 0px 30px 2px rgba(105, 135, 255, 0.8);
  }
}
@keyframes clock-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
