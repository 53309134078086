import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const getFirebase = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyCCfR6xm6cHUsU4B90zHAzR49xBmi7cgvI",
    authDomain: "aurum-dfe01.firebaseapp.com",
    projectId: "aurum-dfe01",
    storageBucket: "aurum-dfe01.appspot.com",
    messagingSenderId: "575225965222",
    appId: "1:575225965222:web:bd21e65a40a459f460a267",
    measurementId: "G-6QV9R34QLV",
  };

  const app = initializeApp(firebaseConfig);

  const db = getFirestore(app);

  return { app, db };
};

export default getFirebase();
