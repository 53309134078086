<template>
  <div class="nest-room-wrapper">
    <BasicPopup header="Nest Supporter's Room" class="nest-popup">
      <div class="close-button button-small" @click="hide">
        <span>x</span>
      </div>
      <div class="inner-wrapper">
        <GenericLoading v-if="isLoading" />
        <div v-else class="nest-staking">
          <div v-if="nftCount > 0" class="grid">
            <NestItem
              :isStaked="true"
              class="staked"
              v-for="id in stakedNftIds"
              :id="id"
              :key="id"
              @refresh="refresh"
            />
            <NestItem
              :isStaked="false"
              v-for="id in nestNftIds"
              :id="id"
              :key="id"
              @refresh="refresh"
            />
          </div>
          <div v-else class="no-nest-message">
            <p>You don't own any Dragon Crypto Nest Supporters NFT.</p>
            <p>
              You can purchase at
              <a
                href="https://nftrade.com/collection/dragon-nest-supporters"
                target="_blank"
                >NFTrade</a
              >.
            </p>
          </div>
        </div>
      </div>
      <DialogButton
        class="back-button"
        @click="hide"
        :buttonText="'Back'"
        buttonSize="small"
      />
    </BasicPopup>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import DialogButton from "../DialogButton.vue";
import BasicPopup from "../Popup/BasicPopup.vue";
import NestItem from "./NestItem.vue";

import { ethers } from "ethers";
import { useUser } from "../../composables/user";
import GenericLoading from "../LoadingComponents/GenericLoading.vue";

import {
  getDCGNestContract,
  getLoadRewardHandlerContract,
  getNFTFinderContract,
} from "../../utils/getContract";
export default {
  name: "Nest",
  props: {},
  components: {
    BasicPopup,
    NestItem,
    GenericLoading,
    DialogButton,
  },
  setup() {
    const store = useStore();
    const stakedNests = ref(0);
    const nestCount = ref(0);
    const nestNftIds = ref([]);
    const stakedNftIds = ref([]);
    const isLoading = ref(true);
    const { signer, address } = useUser();
    const RewardHandlerContract = getLoadRewardHandlerContract(signer.value);
    const DCGNestContract = getDCGNestContract(signer.value);

    onMounted(async () => {
      await refresh();
    });
    /**
     * Methods
     */
    const hide = () => {
      store.commit("hideNestRoom");
    };
    const refresh = async () => {
      isLoading.value = true;
      await getNestCount();
      await getNestStakedDetails();
      await getNestNfts();
      await getStakedNestNfts();
      isLoading.value = false;
    };
    const getNestStakedDetails = async () => {
      try {
        console.log(RewardHandlerContract);
        stakedNests.value = await RewardHandlerContract.NESTS_STAKED();
      } catch (error) {
        console.log(error);
      }
    };

    const getNestCount = async () => {
      nestCount.value = await DCGNestContract.balanceOf(address.value);
    };

    const getNestNfts = async () => {
      const NFTFinder = getNFTFinderContract(signer.value);
      const balance = nestCount.value;
      if (balance > 0) {
        const nestArray = await NFTFinder.findTokens(
          DCGNestContract.address,
          address.value
        );
        nestNftIds.value = await Promise.all(nestArray);
        nestNftIds.value = nestNftIds.value.map((nftId) =>
          ethers.utils.formatUnits(nftId, 0)
        );
        console.log(nestNftIds.value);
      }
    };

    const getStakedNestNfts = async () => {
      try {
        stakedNftIds.value = await RewardHandlerContract.stakedNestsForAddress(
          address.value
        );
        stakedNftIds.value = stakedNftIds.value.map((id) =>
          ethers.utils.formatUnits(id, 0)
        );
        console.log(stakedNftIds.value);
      } catch (error) {
        console.log(error);
      }
    };

    const nftCount = computed(() => {
      return nestNftIds.value.length + stakedNftIds.value.length;
    });
    /**
     * Computed methods
     */

    return {
      //data
      isLoading,
      nestNftIds,
      stakedNests,
      stakedNftIds,

      // methods
      hide,
      refresh,
      // computed
      nftCount,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.nest-room-wrapper {
  background: no-repeat url("../../assets/locationBackgrounds/groms-bank.webp")
    center center;
  background-size: cover;
  background-color: #b04621;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: 0;
  z-index: 6;
}
.nest-popup {
  width: calc(80% - $popup-border-size - $popup-border-size);
  height: calc(600px - $popup-border-size - $popup-border-size);
  max-width: 1018px;
  min-width: 1018px;
  .back-button {
    position: absolute;
    top: -41px;
    left: -36px;
    z-index: 5;
  }
  .close-button {
    position: absolute;
    top: -$popup-border-size;
    right: -$popup-border-size;
  }
  @media only screen and (max-width: 576px) {
    min-width: calc(100% - $popup-border-size - $popup-border-size);
  }
  .no-nest-message {
    display: block;
    font-size: 1.5rem;
    width: 55%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .inner-wrapper {
    width: calc(100% + $popup-border-offset + $popup-border-offset);
    height: calc(100% + $popup-border-offset + $popup-border-offset);
    display: block;
    left: -38px;
    position: relative;
    top: -35px;
    @media only screen and (max-width: 576px) {
      height: calc(100% + $popup-border-offset + $popup-border-offset);
      overflow-y: scroll;
    }
    left: -$popup-border-offset;
    position: relative;
    top: -35px;
    .nest-staking .grid,
    .nest-staked .grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      grid-gap: 0.5rem;
    }
  }
}
</style>
