<template>
  <BasicPopup
    v-if="isOnRampOpen && isActivated && balance < rampLimit"
    :header="!toggleTransak ? `You Need Gas` : `Buy AVAX`"
    id="buy-gas"
  >
    <div class="close-button button-small" @click="closeOnRamp">
      <span>x</span>
    </div>
    <template v-if="!toggleTransak">
      <span class="avax"></span>
      <p>
        You don't have enough AVAX to interact with the blockchain aspects of
        the game. Please purchase some AVAX to have the complete blockchain
        gaming experience.
      </p>
      <p>
        We support all leading payment options to buy AVAX.
        <a href="https://www.avax.network/" target="_blank"
          >Learn more about Avalanche</a
        >
      </p>
      <BlueButton @click="toggleTransak = !toggleTransak" :size="`extra-small`"
        >Buy Avax</BlueButton
      >
      <BlueButton class="buy-later" @click="closeOnRamp" :size="`extra-small`"
        >Later</BlueButton
      >
    </template>
    <div class="transak" v-else>
      <span class="loading">Loading Transak...</span>
      <iframe
        height="550"
        title="Transak On/Off Ramp Widget"
        src="https://global.transak.com/?apiKey=13806b00-b05e-4782-85dd-8c56fc3d5526&cryptoCurrencyCode=AVAX&network=avalanche&themeColor=1c4768&isFeeCalculationHidden=true&hideMenu=true&exchangeScreenTitle=Buy%20AVAX%20For%20Your%20Wallet"
        frameborder="no"
        allowtransparency="true"
        allowfullscreen=""
        style="display: block; width: 100%; max-height: 625px; max-width: 500px"
      >
      </iframe>
    </div>
  </BasicPopup>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { usePrice } from "../../composables/price";
import { useUser } from "../../composables/user";
import Constants from "../../consts/constants";
import BlueButton from "../Buttons/BlueButton.vue";
import BasicPopup from "../Popup/BasicPopup.vue";

export default {
  name: "OnRamp",
  components: {
    BlueButton,
    BasicPopup,
  },
  setup() {
    // Data and Refs
    const balance = ref(0);
    const { getGasBalance } = usePrice();
    const { isActivated } = useUser();
    const toggleTransak = ref(false);
    const store = useStore();

    onMounted(async () => {
      // Muting all game music
      //   video.value.currentTime = 140; // TODO Remove
      await fetchBalance();
    });

    // Computed Methods
    const isOnRampOpen = computed(() => {
      return store.state.intro.isOnRampOpen;
    });
    const isGuideOpen = computed(() => {
      return store.state.intro.isGuideOpen;
    });
    const rampLimit = computed(() => {
      return Constants.onRampThreshold;
    });

    // Methods
    const closeOnRamp = () => {
      store.commit("intro/setIsOnRampOpen", false);
    };
    const fetchBalance = async () => {
      balance.value = await getGasBalance();
      if (balance.value < rampLimit.value && !isGuideOpen.value) {
        store.commit("intro/setIsOnRampOpen", true);
      }
    };
    const buyAvax = () => {
      window.open(
        "https://global.transak.com/?apiKey=13806b00-b05e-4782-85dd-8c56fc3d5526&cryptoCurrencyCode=AVAX&network=avalanche&themeColor=1c4768&exchangeScreenTitle=Buy%20AVAX%20To%20Experience%20Complete%20Blockchain%20Gaming%20Experience%20Of%20Legend%20Of%20Aurum%20Draconis",
        "_blank"
      );
    };
    watch(
      isActivated,
      async (activated) => {
        console.log("ACTIVATION", activated);
        if (activated) {
          await fetchBalance();
        }
      },
      { deep: true }
    );

    return {
      // data
      balance,
      isActivated,
      toggleTransak,
      //methods
      buyAvax,
      closeOnRamp,
      //computed
      isOnRampOpen,
      rampLimit,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

#buy-gas {
  .close-button {
    top: -80px;
    right: -80px;
    position: absolute;
  }
  button {
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px;
  }
  span.avax {
    display: block;
    background-image: url("../../assets/ui/avax-logo.svg");
    background-size: contain;
    background-position: center center;
    width: 150px;
    height: 150px;
    margin: -10px auto 40px;
  }
  p {
    max-width: 350px;
    margin-bottom: 30px;
  }
  .transak {
    width: 400px;
    margin-top: -20px;
    position: relative;
    .loading {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      font-size: 1.2rem;
      transform: translate(-50%, -50%);
    }
  }
  .buy-later {
    filter: grayscale(100%);
  }
}
</style>
