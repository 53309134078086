<template>
  <div v-if="isTurnHappening" class="loading">
    <span>Waiting For Action To Complete </span>
    <div class="loading-inner"></div>
  </div>
</template>
<script>
import { useStore } from "vuex";
export default {
  name: "WaitingForTurn",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    isTurnHappening() {
      return this.$store.state.isTurnHappening;
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.loading {
  display: grid;
  background: linear-gradient(
    90deg,
    #0000 0%,
    #00000080 5%,
    #00000080 50%,
    #00000080 95%,
    #0000 100%
  );
  width: 80%;
  height: 50px;
  position: relative;
  z-index: 3;
  bottom: 0;
  text-align: center;
  align-items: center;
  span {
    color: #dc9c6a;
    z-index: 5;
    font-family: "IM Fell English", serif;
    font-size: 1.2rem;
  }
  .loading-inner {
    position: absolute;
    width: 0px;
    height: 3px;
    left: 0px;
    bottom: 0px;
    background: rgb(254, 167, 15);
    background: linear-gradient(
      -90deg,
      rgba(254, 167, 15, 1) 0%,
      rgba(255, 149, 12, 1) 36%,
      rgba(217, 142, 20, 1) 56%,
      rgba(195, 79, 6, 1) 80%,
      rgba(79, 21, 1, 1) 99%
    );
    opacity: 0.4;
    z-index: 4;
    animation: 1s infinite linear loadingAnimation;
  }
}
</style>
