<template>
  <div class="content-wrapper">
    <div :class="'message-top' + (this.icon ? '' : ' no-icon')">
      <div v-if="icon" class="icon-wrapper">
        <img :src="getIcon" :alt="this.heading" />
      </div>
    </div>
    <div class="message">
      <div class="background">
        <div class="slot-wrapper">
          <div class="inner-content">
            <h2>{{ this.heading }}</h2>
            <slot> </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import tippy from 'tippy.js'
import { useStore } from "vuex";

export default {
  name: "GameMessage",
  props: {
    heading: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    getIcon() {
      //Not sure why this is hardcoded here but just changing the info returned
      if (this.icon.includes("equip")) {
        if (this.icon.endsWith(".png")) {
          return "https://cdn.dragoncrypto.io/" + this.icon;
        } else {
          return "https://cdn.dragoncrypto.io/" + this.icon + ".png";
        }
      } else {
        return "https://cdn.dragoncrypto.io/icons/" + this.icon + ".png";
      }
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.content-wrapper {
  width: 553px;
  margin-bottom: 30px;
}

.slot-wrapper {
  margin-left: -100px;
  margin-right: -100px;
  @media only screen and (max-width: 576px) {
    width: 80%;
    margin: 0 auto;
  }
}

.message {
  z-index: 1;

  position: relative;
  width: 100%;

  text-shadow: 0 0 2px #000, 0 1px 3px #000;

  h2 {
    font-family: "IM Fell English", serif;
    text-transform: none;

    margin: 5px;
    color: $game-heading;
    font-size: 130%;
  }

  .background {
    width: 80%;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 5%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.5) 95%,
      rgba(0, 0, 0, 0) 100%
    );
    margin: 0 auto;
    margin-top: -2px;
    padding: 16px;
    padding-bottom: 4px;
  }

  &::after {
    content: "";
    background: url("https://cdn.dragoncrypto.io/uiassets/message-bottom.png")
      no-repeat bottom center;
    display: block;
    position: absolute;
    bottom: -42px;
    width: 100%;
    height: 45px;
  }
}

.message-top {
  width: 100%;
  background: url("https://cdn.dragoncrypto.io/uiassets/message-top.png")
    no-repeat bottom center;
  z-index: 100;
  position: relative;
  min-height: 45px;

  &.no-icon {
    background: url("https://cdn.dragoncrypto.io/uiassets/message-top-no-icon.png")
      no-repeat bottom center;
  }

  .icon-wrapper {
    width: 100%;
    height: 100%;
    background: url("https://cdn.dragoncrypto.io/uiassets/message-icon-bg.png")
      no-repeat center center;
  }

  img {
    margin-top: 10px;
    width: 64px;
    border-radius: 100px;
    margin-bottom: 10px;
  }
}
</style>
