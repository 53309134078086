import { v4 as uuidv4 } from "uuid";
import * as XorShift from "xorshift";
import Constants from "../consts/constants";

export default {
  findMedian(arr = []) {
    const sorted = arr.slice().sort((a, b) => {
      return a - b;
    });
    if (sorted.length % 2 === 0) {
      const first = sorted[sorted.length / 2 - 1];
      const second = sorted[sorted.length / 2];
      return (first + second) / 2;
    } else {
      const mid = Math.floor(sorted.length / 2);
      return sorted[mid];
    }
  },
  generateRandom(maxNumber) {
    return Math.floor(XorShift.random() * maxNumber);

    // const randomRuns = XorShift.random() * 4;

    // let randoms = [];

    // for (let r = 0; r < randomRuns; r++) {
    //     randoms.push( XorShift.random() * maxNumber );
    // }

    // const randomTotal = Math.floor( this.findMedian( randoms ) );

    // return randomTotal;
  },
  generateLoot(luckFactor, characterLevel) {
    let randomAmount = this.generateRandom(3);

    if (randomAmount == 0) randomAmount = 1;

    let randomItems = [];

    const calculatedLuckFactor = this.calculateLuckFactors(
      luckFactor,
      characterLevel
    );

    for (let index = 0; index < randomAmount; index++) {
      randomItems.push(this.generateItem(calculatedLuckFactor));
    }

    randomItems.push(this.generateConsumable(calculatedLuckFactor));

    return { items: randomItems };
  },
  calculateLuckFactors(luckFactor, characterLevel) {
    let luckFactorChange = 0;

    if (luckFactor > 0) {
      luckFactorChange += luckFactor / 2;
    }

    luckFactorChange += characterLevel;

    const luckFactorPercentage = luckFactorChange / 100;

    const luckFactorNumber = luckFactorPercentage * 1000;

    return luckFactorNumber;
  },
  generateFromCollection(luckFactor, collection) {
    const rarityWithLuck = collection.filter((r) => {
      return r.rareTier;
    });
    const nonRares = collection.filter((r) => {
      return !r.rareTier;
    });

    const nonRaresChange = luckFactor / nonRares.length;
    const raresChange = luckFactor / rarityWithLuck.length;

    let randomNo = this.generateRandom(1000);

    let currentRarityCounter = 0;

    var foundItem = null;

    for (let index = 0; index < collection.length; index++) {
      const element = collection[index];

      const floor = currentRarityCounter;

      let ceiling = floor + element.dropChance;

      if (!element.rareTier) {
        ceiling -= nonRaresChange;
      } else {
        ceiling += raresChange;
      }

      if (randomNo >= floor && randomNo < ceiling) {
        foundItem = element;
      }

      currentRarityCounter = ceiling;
    }

    if (foundItem) {
      return foundItem;
    }

    return collection[0];
  },
  getStatsOfResource(item) {
    let itemStats = {
      id: item.staticId,
      type: "resource",
    };

    if (item.craftingRequired) {
      itemStats.craftingRequired = item.craftingRequired;
    }

    if (item.gatheringRequired) {
      itemStats.gatheringRequired = item.gatheringRequired;
    }

    itemStats.skill = item.skill;

    return itemStats;
  },
  getStatsOfConsumable(item) {
    if (item.action == Constants.consumableActions.heal) {
      return {
        amount: item.healing,
        id: item.staticId,
        type: "consumable",
        action: item.action,
      };
    }

    return {};
  },
  getStatsOfItem(item) {
    let bonusAttack = 0;
    let bonusDefense = 0;
    let bonusTotalHp = 0;
    let bonusSpeed = 0;
    let bonusCrafting = 0;
    let bonusGathering = 0;
    let bonusMagic = 0;
    let bonusLuck = 0;
    let bonusCritical = 0;

    if (item.luck && item.luck > 0) {
      bonusLuck += item.luck;
    }

    if (item.attack && item.attack > 0) {
      bonusAttack += item.attack;
    }

    if (item.defense && item.defense > 0) {
      bonusDefense += item.defense;
    }

    if (item.totalHp && item.totalHp > 0) {
      bonusTotalHp += item.totalHp;
    }

    if (item.speed && item.speed > 0) {
      bonusSpeed += item.speed;
    }

    if (item.crafting && item.crafting > 0) {
      bonusCrafting += item.crafting;
    }

    if (item.gathering && item.gathering > 0) {
      bonusGathering += item.gathering;
    }

    if (item.magic && item.magic > 0) {
      bonusMagic += item.magic;
    }

    if (item.criticalChance && item.criticalChance > 0) {
      bonusCritical += item.criticalChance;
    }

    return {
      attack: bonusAttack,
      defense: bonusDefense,
      totalHp: bonusTotalHp,
      speed: bonusSpeed,
      crafting: bonusCrafting,
      gathering: bonusGathering,
      magic: bonusMagic,
      luck: bonusLuck,
      criticalChance: bonusCritical,
    };
  },
  combineStatsWithBonus(item1, item2, bonusPercent) {
    let finalCombined = {};

    for (const key in item1) {
      if (
        Object.hasOwnProperty.call(item1, key) ||
        Object.hasOwnProperty.call(item2, key)
      ) {
        let number = 0;

        if (Object.hasOwnProperty.call(item1, key)) {
          const propertyVal = item1[key];
          if (propertyVal > 0) {
            number += propertyVal * (1 + bonusPercent / 100);
          }
        }

        if (Object.hasOwnProperty.call(item2, key)) {
          const propertyVal2 = item2[key];

          if (propertyVal2 > 0) {
            number += propertyVal2 * (1 + bonusPercent / 100);
          }
        }

        if (number > 0) {
          finalCombined[key] = Math.ceil(number);
        }
      }
    }

    return finalCombined;
  },
  createSpecificResource(id, quantity) {
    const resource = this.resources.find((r) => r.staticId == id);
    console.log(resource);
    const itemStats = this.getStatsOfResource(resource);

    let item = {
      id: itemStats.id,
      name: resource.name,
      type: resource.type,
      image: "https://ik.imagekit.io/dcg/equip/" + resource.imageName + ".png",
      rarity: resource.rarity,
      quantity,
    };

    return { item, itemStats };
  },
  generateConsumable(luckFactors) {
    const consumable = this.generateFromCollection(
      luckFactors,
      this.consumables
    );

    let itemStats = this.getStatsOfConsumable(consumable);

    let item = {
      id: itemStats.id,
      name: consumable.name,
      type: consumable.type,
      image:
        "https://ik.imagekit.io/dcg/equip/" + consumable.imageName + ".png",
      rarity: consumable.rarity,
      quantity: 1,
    };

    return { item, itemStats };
  },
  generateItem(luckFactors) {
    const type = this.generateFromCollection(luckFactors, this.rarityTypes);
    const prefix = this.generateFromCollection(luckFactors, this.prefixes);
    const suffix = this.generateFromCollection(luckFactors, this.suffixes);

    // const itemRandom = this.generateRandom( this.baseItems.length );

    const groupedByChance = this.groupBy(this.baseItems, "dropChance");

    let total = 0;

    for (const key in groupedByChance) {
      if (Object.hasOwnProperty.call(groupedByChance, key)) {
        total += 100 / key;
      }
    }

    const groupRandom = this.generateRandom(100);

    let item = null;

    for (const key in groupedByChance) {
      if (Object.hasOwnProperty.call(groupedByChance, key)) {
        const chance = 100 / key;

        const chancePercent = (chance / total) * 100;

        if (groupRandom > chancePercent) {
          const items = groupedByChance[key];

          const itemsRandom = this.generateRandom(items.length);

          item = items[itemsRandom];

          break;
        }
      }
    }

    if (!item) {
      for (const key in groupedByChance) {
        const first = groupedByChance[key];
        const itemsRandom = this.generateRandom(first.length);
        item = first[itemsRandom];
        break;
      }
    }

    let finalItemName = "";

    if (!type.hide) {
      finalItemName += type.name + " ";
    }

    if (!prefix.hide) {
      finalItemName += prefix.name + " ";
    }

    finalItemName += item.name;

    if (!suffix.hide) {
      finalItemName += " of " + suffix.name;
    }

    let totalBonusPercent = 0;

    if (type.bonusPercent && type.bonusPercent > 0) {
      totalBonusPercent += type.bonusPercent;
    }

    if (prefix.bonusPercent && prefix.bonusPercent > 0) {
      totalBonusPercent += prefix.bonusPercent;
    }

    let bonusSuffixStats = this.getStatsOfItem(suffix);
    let itemStats = this.getStatsOfItem(item);

    let finalItem = {
      id: uuidv4(),
      name: finalItemName,
      type: item.type,
      rarity: type.name,
      image: "https://ik.imagekit.io/dcg/equip/" + item.imageName + ".png",
    };

    let finalStats = this.combineStatsWithBonus(
      bonusSuffixStats,
      itemStats,
      totalBonusPercent
    );

    finalStats.id = finalItem.id;

    finalStats.totalDurability = type.baseDurability;

    if (type.bonusPercent > 0) {
      finalStats.totalDurability =
        type.baseDurability * (1 + type.bonusPercent / 100);
      finalStats.isBroken = false;
    }

    finalStats.durability = finalStats.totalDurability;

    if (type.craftingRequired && type.craftingRequired > 0) {
      finalStats.craftingRequired = type.craftingRequired;
    }

    if (item.resources) {
      finalStats.resources = item.resources;
    }

    if (item.isTwoHanded) {
      finalItem.isTwoHanded = item.isTwoHanded;
    }

    return { item: finalItem, itemStats: finalStats };
  },
  groupBy(data, key) {
    return data.reduce(function (storage, item) {
      var group = item[key];
      storage[group] = storage[group] || [];
      storage[group].push(item);
      return storage;
    }, {});
  },
  rarityTypes: [
    {
      name: "Shoddy",
      code: "shoddy",
      dropChance: 500,
      breaks: true,
      bonusPercent: 0,
      baseDurability: 50,
      craftingRequired: 1,
    },
    {
      name: "Normal",
      code: "normal",
      dropChance: 279,
      breaks: true,
      hide: true,
      bonusPercent: 25,
      baseDurability: 100,
      craftingRequired: 10,
    },
    {
      name: "Uncommon",
      code: "uncommon",
      dropChance: 80,
      breaks: true,
      bonusPercent: 50,
      rareTier: true,
      baseDurability: 150,
      craftingRequired: 30,
    },
    {
      name: "Rare",
      code: "rare",
      dropChance: 60,
      breaks: true,
      bonusPercent: 100,
      rareTier: true,
      baseDurability: 200,
      craftingRequired: 50,
    },
    {
      name: "Epic",
      code: "epic",
      dropChance: 50,
      breaks: false,
      bonusPercent: 150,
      rareTier: true,
      baseDurability: 250,
      craftingRequired: 75,
    },
    {
      name: "Legendary",
      code: "legendary",
      dropChance: 20,
      breaks: false,
      bonusPercent: 200,
      rareTier: true,
      baseDurability: 300,
      craftingRequired: 100,
    },
    {
      name: "Artefact",
      code: "artefact",
      dropChance: 10,
      breaks: false,
      hasSets: true,
      bonusPercent: 250,
      rareTier: true,
      baseDurability: 400,
      craftingRequired: 150,
    },
    {
      name: "Mythical",
      code: "mythical",
      dropChance: 1,
      breaks: false,
      hasSets: true,
      bonusPercent: 400,
      rareTier: true,
      baseDurability: 600,
      craftingRequired: 250,
    },
    {
      name: "Fabled",
      code: "fabled",
      dropChance: 0.5,
      breaks: false,
      hasSets: true,
      bonusPercent: 550,
      rareTier: true,
      baseDurability: 800,
      craftingRequired: 350,
    },
  ],
  prefixes: [
    {
      name: "Normal",
      bonusPercent: 0,
      dropChance: 700,
      hide: true,
      rareTier: false,
    },
    { name: "Great", bonusPercent: 100, dropChance: 150, rareTier: true },
    { name: "Enchanted", bonusPercent: 200, dropChance: 100, rareTier: true },
    { name: "Superior", bonusPercent: 300, dropChance: 50, rareTier: true },
  ],
  suffixes: [
    { name: "Normal", dropChance: 600, hide: true, rareTier: false },
    { name: "Luck", luck: 1, dropChance: 50, rareTier: true },
    { name: "Speed", speed: 1, dropChance: 50, rareTier: true },
    { name: "Defense", defense: 1, dropChance: 50, rareTier: true },
    { name: "Magic", magic: 1, dropChance: 50, rareTier: true },
    { name: "Health", totalHp: 100, dropChance: 50, rareTier: true },
    { name: "The Crafter", crafting: 1, dropChance: 50, rareTier: true },
    { name: "The Gatherer", gathering: 1, dropChance: 50, rareTier: true },
    { name: "Precision", criticalChance: 1, dropChance: 50, rareTier: true },
  ],
  sets: [
    { name: "None", bonusPercent: 0, code: "none", dropChance: 995 },
    {
      name: "Blood Assassin",
      bonusPercent: 3,
      code: "bloodassassin",
      weaponType: "magic",
      dropChance: 5,
    },
  ],
  resources: [
    {
      name: "Leather",
      type: "resource",
      imageName: "skinning_01_piece_of_leather",
      staticId: 20000,
      rareTier: false,
      rarity: "Common",
      skill: Constants.skills.gathering,
      gatheringRequired: 5,
      locations: [Constants.resourceLocations.combat],
    },
    {
      name: "Cloth",
      type: "resource",
      imageName: "Tailoring_11_white_clothroll",
      staticId: 20001,
      rareTier: false,
      rarity: "Common",
      skill: Constants.skills.crafting,
      craftingRequired: 5,
      craftingResources: [20011],
    },
    {
      name: "Iron Bar",
      type: "resource",
      imageName: "Res_01",
      staticId: 20002,
      rareTier: false,
      rarity: "Common",
      skill: Constants.skills.crafting,
      craftingRequired: 10,
      craftingResources: [20007],
      locations: [Constants.resourceLocations.forge],
    },
    {
      name: "Steel Bar",
      type: "resource",
      imageName: "Res_Steel",
      staticId: 20005,
      rareTier: true,
      rarity: "Uncommon",
      skill: Constants.skills.crafting,
      craftingRequired: 30,
      craftingResources: [20006, 20007],
      locations: [Constants.resourceLocations.forge],
    },
    {
      name: "Coal",
      type: "resource",
      imageName: "Mining_24_piece_of_coal",
      staticId: 20006,
      rareTier: true,
      rarity: "Uncommon",
      skill: Constants.skills.gathering,
      gatheringRequired: 10,
      locations: [Constants.resourceLocations.mines],
    },
    {
      name: "Iron Ore",
      type: "resource",
      imageName: "Mining_05_ironore",
      staticId: 20007,
      rareTier: false,
      rarity: "Common",
      skill: Constants.skills.gathering,
      gatheringRequired: 5,
      locations: [Constants.resourceLocations.mines],
    },
    {
      name: "Wood",
      type: "resource",
      imageName: "Res_04",
      staticId: 20003,
      rareTier: false,
      rarity: "Common",
      skill: Constants.skills.gathering,
      gatheringRequired: 5,
      locations: [Constants.resourceLocations.deepwood],
    },
    {
      name: "Gem Shards",
      type: "resource",
      imageName: "Quest_137",
      staticId: 20004,
      rareTier: true,
      rarity: "Rare",
      skill: Constants.skills.gathering,
      gatheringRequired: 50,
      locations: [Constants.resourceLocations.mines],
    },
    {
      name: "Gold Ore",
      type: "resource",
      imageName: "Mining_08_goldore",
      staticId: 20008,
      rareTier: true,
      rarity: "Epic",
      skill: Constants.skills.gathering,
      gatheringRequired: 70,
      locations: [Constants.resourceLocations.mines],
    },
    {
      name: "Gold Bar",
      type: "resource",
      imageName: "Res_03",
      staticId: 20009,
      rareTier: true,
      rarity: "Epic",
      skill: Constants.skills.crafting,
      craftingRequired: 70,
      craftingResources: [20008],
      locations: [Constants.resourceLocations.forge],
    },
    {
      name: "Bone",
      type: "resource",
      imageName: "Loot_23",
      staticId: 20010,
      rareTier: true,
      rarity: "Uncommon",
      skill: Constants.skills.gathering,
      gatheringRequired: 15,
      locations: [
        Constants.resourceLocations.deadlands,
        Constants.resourceLocations.mines,
      ],
    },
    {
      name: "Cotton",
      type: "resource",
      imageName: "res_cotton",
      staticId: 20011,
      rareTier: false,
      rarity: "Normal",
      skill: Constants.skills.gathering,
      gatheringRequired: 1,
      locations: [Constants.resourceLocations.deepwood],
    },
    {
      name: "Runite Ore",
      type: "resource",
      imageName: "Mining_14_manastoune",
      staticId: 20012,
      rareTier: true,
      rarity: "Rare",
      skill: Constants.skills.gathering,
      gatheringRequired: 40,
      locations: [Constants.resourceLocations.mines],
    },
    {
      name: "Runite Bar",
      type: "resource",
      imageName: "Mining_56_runite_bar",
      staticId: 20013,
      rareTier: true,
      rarity: "Rare",
      skill: Constants.skills.crafting,
      craftingRequired: 45,
      resources: [20012, 20006],
      locations: [Constants.resourceLocations.forge],
    },
    {
      name: "Blue Magical Dust",
      type: "resource",
      imageName: "Enchantment_06_magicdust",
      staticId: 20014,
      rareTier: true,
      rarity: "Legendary",
      skill: Constants.skills.gathering,
      gatheringRequired: 150,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Purple Magical Dust",
      type: "resource",
      imageName: "Enchantment_05_magicdust",
      staticId: 20015,
      rareTier: true,
      rarity: "Legendary",
      skill: Constants.skills.gathering,
      gatheringRequired: 150,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Green Magical Dust",
      type: "resource",
      imageName: "Enchantment_07_magicdust",
      staticId: 20016,
      rareTier: true,
      rarity: "Legendary",
      skill: Constants.skills.gathering,
      gatheringRequired: 150,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Orange Magical Dust",
      type: "resource",
      imageName: "Enchantment_08_magicdust",
      staticId: 20017,
      rareTier: true,
      rarity: "Legendary",
      skill: Constants.skills.gathering,
      gatheringRequired: 150,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Red Magical Dust",
      type: "resource",
      imageName: "Enchantment_09_magicdust",
      staticId: 20018,
      rareTier: true,
      rarity: "Legendary",
      skill: Constants.skills.gathering,
      gatheringRequired: 150,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
  ],
  gemstones: [
    {
      name: "Enchanted Magical Stone",
      type: "resource",
      resourceDescription: "glowing pile",
      gatheringAction: "Gathering",
      imageName: "Enchantment_46_stoune",
      staticId: 20025,
      rareTier: true,
      isSocketItem: true,
      rarity: Constants.rarities.normal,
      skill: Constants.skills.gathering,
      maxGather: 1,
      bonuses: {
        magic: 1,
      },
      gatheringRequired: 75,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Enchanted Lesser Stone of Chaos",
      type: "resource",
      resourceDescription: "glowing pile",
      gatheringAction: "Gathering",
      imageName: "Enchantment_47_rune_stoune",
      staticId: 20026,
      baseTypeId: 20025,
      rareTier: true,
      isSocketItem: true,
      rarity: Constants.rarities.rare,
      skill: Constants.skills.gathering,
      maxGather: 1,
      bonuses: {
        magic: 2,
      },
      element: Constants.elements.chaos,
      gatheringRequired: 95,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Enchanted Stone of Chaos",
      type: "resource",
      resourceDescription: "glowing pile",
      gatheringAction: "Gathering",
      imageName: "Enchantment_48_rune_stoune",
      staticId: 20027,
      baseTypeId: 20025,
      isSocketItem: true,
      rareTier: true,
      rarity: Constants.rarities.epic,
      skill: Constants.skills.gathering,
      maxGather: 1,
      bonuses: {
        magic: 3,
      },
      element: Constants.elements.chaos,
      gatheringRequired: 115,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Enchanted Lesser Stone of the Mind",
      type: "resource",
      resourceDescription: "glowing pile",
      gatheringAction: "Gathering",
      imageName: "Enchantment_49_rune_stoune",
      staticId: 20028,
      baseTypeId: 20025,
      rareTier: true,
      isSocketItem: true,
      rarity: Constants.rarities.rare,
      skill: Constants.skills.gathering,
      maxGather: 1,
      bonuses: {
        magic: 2,
      },
      element: Constants.elements.mind,
      gatheringRequired: 95,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Enchanted Lesser Stone of Ice",
      type: "resource",
      resourceDescription: "glowing pile",
      gatheringAction: "Gathering",
      imageName: "Enchantment_51_rune_stoune",
      staticId: 20029,
      baseTypeId: 20025,
      rareTier: true,
      isSocketItem: true,
      rarity: Constants.rarities.rare,
      skill: Constants.skills.gathering,
      maxGather: 1,
      bonuses: {
        magic: 2,
      },
      element: Constants.elements.ice,
      gatheringRequired: 95,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Enchanted Lesser Stone of Venom",
      type: "resource",
      resourceDescription: "glowing pile",
      gatheringAction: "Gathering",
      imageName: "Enchantment_53_rune_stoune",
      staticId: 20030,
      baseTypeId: 20025,
      rareTier: true,
      isSocketItem: true,
      rarity: Constants.rarities.rare,
      skill: Constants.skills.gathering,
      maxGather: 1,
      bonuses: {
        magic: 2,
      },
      element: Constants.elements.venom,
      gatheringRequired: 95,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Enchanted Stone of the Mind",
      type: "resource",
      resourceDescription: "glowing pile",
      gatheringAction: "Gathering",
      imageName: "Enchantment_50_rune_stoune",
      staticId: 20031,
      baseTypeId: 20025,
      isSocketItem: true,
      rareTier: true,
      rarity: Constants.rarities.epic,
      skill: Constants.skills.gathering,
      maxGather: 1,
      bonuses: {
        magic: 3,
      },
      element: Constants.elements.mind,
      gatheringRequired: 115,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Enchanted Stone of Ice",
      type: "resource",
      resourceDescription: "glowing pile",
      gatheringAction: "Gathering",
      imageName: "Enchantment_52_rune_stoune",
      staticId: 20032,
      baseTypeId: 20025,
      isSocketItem: true,
      rareTier: true,
      rarity: Constants.rarities.epic,
      skill: Constants.skills.gathering,
      maxGather: 1,
      bonuses: {
        magic: 3,
      },
      element: Constants.elements.ice,
      gatheringRequired: 115,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Enchanted Stone of Venom",
      type: "resource",
      resourceDescription: "glowing pile",
      gatheringAction: "Gathering",
      imageName: "Enchantment_54_rune_stoune",
      staticId: 20033,
      baseTypeId: 20025,
      isSocketItem: true,
      rareTier: true,
      rarity: Constants.rarities.epic,
      skill: Constants.skills.gathering,
      maxGather: 1,
      bonuses: {
        magic: 3,
      },
      element: Constants.elements.venom,
      gatheringRequired: 115,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Enchanted Stone of Light",
      type: "resource",
      resourceDescription: "glowing pile",
      gatheringAction: "Gathering",
      imageName: "Enchantment_rune_light",
      staticId: 20034,
      baseTypeId: 20025,
      isSocketItem: true,
      rareTier: true,
      rarity: Constants.rarities.epic,
      skill: Constants.skills.gathering,
      maxGather: 1,
      bonuses: {
        magic: 3,
      },
      element: Constants.elements.light,
      gatheringRequired: 115,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Enchanted Lesser Stone of Light",
      type: "resource",
      resourceDescription: "glowing pile",
      gatheringAction: "Gathering",
      imageName: "Enchantment_rune_light_lesser",
      staticId: 20035,
      baseTypeId: 20025,
      rareTier: true,
      isSocketItem: true,
      rarity: Constants.rarities.rare,
      skill: Constants.skills.gathering,
      maxGather: 1,
      bonuses: {
        magic: 2,
      },
      element: Constants.elements.light,
      gatheringRequired: 95,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Enchanted Stone of Water",
      type: "resource",
      resourceDescription: "glowing pile",
      gatheringAction: "Gathering",
      imageName: "Enchantment_rune_water",
      staticId: 20036,
      baseTypeId: 20025,
      isSocketItem: true,
      rareTier: true,
      rarity: Constants.rarities.epic,
      skill: Constants.skills.gathering,
      maxGather: 1,
      bonuses: {
        magic: 3,
      },
      element: Constants.elements.water,
      gatheringRequired: 115,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Enchanted Lesser Stone of Water",
      type: "resource",
      resourceDescription: "glowing pile",
      gatheringAction: "Gathering",
      imageName: "Enchantment_rune_water_lesser",
      staticId: 20037,
      baseTypeId: 20025,
      rareTier: true,
      isSocketItem: true,
      rarity: Constants.rarities.rare,
      skill: Constants.skills.gathering,
      maxGather: 1,
      bonuses: {
        magic: 2,
      },
      element: Constants.elements.water,
      gatheringRequired: 95,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Enchanted Stone of Earth",
      type: "resource",
      resourceDescription: "glowing pile",
      gatheringAction: "Gathering",
      imageName: "Enchantment_rune_earth",
      staticId: 20038,
      baseTypeId: 20025,
      isSocketItem: true,
      rareTier: true,
      rarity: Constants.rarities.epic,
      skill: Constants.skills.gathering,
      maxGather: 1,
      bonuses: {
        magic: 3,
      },
      element: Constants.elements.earth,
      gatheringRequired: 115,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Enchanted Lesser Stone of Earth",
      type: "resource",
      resourceDescription: "glowing pile",
      gatheringAction: "Gathering",
      imageName: "Enchantment_rune_earth_lesser",
      staticId: 20039,
      baseTypeId: 20025,
      rareTier: true,
      isSocketItem: true,
      rarity: Constants.rarities.rare,
      skill: Constants.skills.gathering,
      maxGather: 1,
      bonuses: {
        magic: 2,
      },
      element: Constants.elements.earth,
      gatheringRequired: 95,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
    {
      name: "Gemstone of Precision",
      type: "resource",
      resourceDescription: "glowing gem inflused rocks",
      gatheringAction: "Mining",
      imageName: "gem_precision",
      staticId: 20040,
      baseTypeId: 20025,
      isSocketItem: true,
      rareTier: true,
      rarity: Constants.rarities.legendary,
      skill: Constants.skills.gathering,
      maxGather: 1,
      bonuses: {
        attack: 5,
        criticalChance: 5,
      },
      gatheringRequired: 120,
      locations: [
        Constants.resourceLocations.mines,
        Constants.resourceLocations.deepwood,
      ],
    },
  ],
  consumables: [
    {
      name: "Bandages",
      type: "consumable",
      healing: 5,
      imageName: "Tailoring_51_easybandages",
      dropChance: 700,
      action: Constants.consumableActions.heal,
      rareTier: false,
      staticId: 10000,
      rarity: "Common",
    },
    {
      name: "Lesser Healing Potion",
      type: "consumable",
      healing: 10,
      imageName: "Res_44",
      dropChance: 150,
      action: Constants.consumableActions.heal,
      rareTier: false,
      staticId: 10001,
      rarity: "Normal",
    },
    {
      name: "Healing Potion",
      type: "consumable",
      healing: 15,
      imageName: "Res_40",
      dropChance: 80,
      action: Constants.consumableActions.heal,
      rareTier: true,
      staticId: 10002,
      rarity: "Uncommon",
    },
    {
      name: "Greater Healing Potion",
      type: "consumable",
      healing: 50,
      imageName: "Res_48",
      dropChance: 40,
      action: Constants.consumableActions.heal,
      rareTier: true,
      staticId: 10003,
      rarity: "Epic",
    },
    {
      name: "Master Healing Potion",
      type: "consumable",
      healing: 75,
      imageName: "Res_49",
      dropChance: 20,
      action: Constants.consumableActions.heal,
      rareTier: true,
      staticId: 10004,
      rarity: "Legendary",
    },
    {
      name: "Legendary Healing Potion",
      type: "consumable",
      healing: 100,
      imageName: "Potion_06",
      dropChance: 10,
      action: Constants.consumableActions.heal,
      rareTier: true,
      staticId: 10005,
      rarity: "Mythical",
    },
  ],
  baseItems: [
    {
      name: "Cloth Belt",
      type: "belt",
      defense: 1,
      imageName: "1_Cloth_belt",
      dropChance: 1,
      resources: [20001],
    },
    {
      name: "Cloth Boots",
      type: "boots",
      defense: 1,
      imageName: "1_Cloth_Boots",
      dropChance: 1,
      resources: [20001],
    },
    {
      name: "Cloth Bracers",
      type: "arms",
      defense: 1,
      imageName: "1_Cloth_bracers",
      dropChance: 1,
      resources: [20001],
    },
    {
      name: "Cloth Chest",
      type: "chest",
      defense: 2,
      imageName: "1_Cloth_Chest",
      dropChance: 1,
      resources: [20001],
    },
    {
      name: "Cloth Gloves",
      type: "gloves",
      defense: 1,
      imageName: "1_Cloth_gloves",
      dropChance: 1,
      resources: [20001],
    },
    {
      name: "Cloth Hood",
      type: "helmet",
      defense: 1,
      imageName: "1_Cloth_Head",
      dropChance: 1,
      resources: [20001],
    },
    {
      name: "Cloth Leggings",
      type: "legs",
      defense: 1,
      imageName: "1_Cloth_Pants",
      dropChance: 1,
      resources: [20001],
    },
    {
      name: "Cloth Shoulders",
      type: "shoulder",
      defense: 1,
      imageName: "1_Cloth_sloulder",
      dropChance: 1,
      resources: [20001],
    },
    {
      name: "Leather Belt",
      type: "belt",
      defense: 2,
      imageName: "1_Leather_belt",
      dropChance: 2,
      resources: [20000],
    },
    {
      name: "Leather Boots",
      type: "boots",
      defense: 2,
      imageName: "1_Leather_boots",
      dropChance: 2,
      resources: [20000],
    },
    {
      name: "Leather Bracers",
      type: "arms",
      defense: 2,
      imageName: "1_Leather_bracers",
      dropChance: 2,
      resources: [20000],
    },
    {
      name: "Leather Chest",
      type: "chest",
      defense: 3,
      imageName: "1_Leather_Chest",
      dropChance: 2,
      resources: [20000],
    },
    {
      name: "Leather Gloves",
      type: "gloves",
      defense: 2,
      imageName: "1_Leather_gloves",
      dropChance: 2,
      resources: [20000],
    },
    {
      name: "Leather Helmet",
      type: "helmet",
      defense: 2,
      imageName: "1_Leather_head",
      dropChance: 2,
      resources: [20000],
    },
    {
      name: "Leather Leggings",
      type: "legs",
      defense: 2,
      imageName: "1_Leather_Pants",
      dropChance: 2,
      resources: [20000],
    },
    {
      name: "Leather Shoulders",
      type: "shoulder",
      defense: 2,
      imageName: "1_Leather_Shoulder",
      dropChance: 2,
      resources: [20000],
    },
    {
      name: "Reinforced Leather Belt",
      type: "belt",
      defense: 3,
      imageName: "2_Leather_belt",
      dropChance: 3,
      resources: [20000, 20002],
    },
    {
      name: "Reinforced Leather Boots",
      type: "boots",
      defense: 3,
      imageName: "2_Leather_boots",
      dropChance: 3,
      resources: [20000, 20002],
    },
    {
      name: "Reinforced Leather Bracers",
      type: "arms",
      defense: 3,
      imageName: "2_Leather_bracers",
      dropChance: 3,
      resources: [20000, 20002],
    },
    {
      name: "Reinforced Leather Chest",
      type: "chest",
      defense: 4,
      imageName: "2_Leather_Chest",
      dropChance: 3,
      resources: [20000, 20002],
    },
    {
      name: "Reinforced Leather Gloves",
      type: "gloves",
      defense: 3,
      imageName: "2_Leather_gloves",
      dropChance: 3,
      resources: [20000, 20002],
    },
    {
      name: "Reinforced Leather Helmet",
      type: "helmet",
      defense: 3,
      imageName: "2_Leather_head",
      dropChance: 3,
      resources: [20000, 20002],
    },
    {
      name: "Reinforced Leather Leggings",
      type: "legs",
      defense: 3,
      imageName: "2_Leather_Pants",
      dropChance: 3,
      resources: [20000, 20002],
    },
    {
      name: "Reinforced Leather Shoulders",
      type: "shoulder",
      defense: 3,
      imageName: "2_Leather_Shoulder",
      dropChance: 3,
      resources: [20000, 20002],
    },
    {
      name: "Plate Belt",
      type: "belt",
      defense: 4,
      imageName: "1_Mail_belt",
      dropChance: 4,
      resources: [20002],
    },
    {
      name: "Plate Boots",
      type: "boots",
      defense: 4,
      imageName: "1_Mail_Boots",
      dropChance: 4,
      resources: [20002],
    },
    {
      name: "Plate Bracers",
      type: "arms",
      defense: 4,
      imageName: "1_Mail_bracers",
      dropChance: 4,
      resources: [20002],
    },
    {
      name: "Plate Chest",
      type: "chest",
      defense: 5,
      imageName: "1_Mail_Chest",
      dropChance: 4,
      resources: [20002],
    },
    {
      name: "Plate Gloves",
      type: "gloves",
      defense: 4,
      imageName: "1_Mail_gloves",
      dropChance: 4,
      resources: [20002],
    },
    {
      name: "Plate Helmet",
      type: "helmet",
      defense: 4,
      imageName: "1_Mail_head",
      dropChance: 4,
      resources: [20002],
    },
    {
      name: "Plate Leggings",
      type: "legs",
      defense: 4,
      imageName: "1_mail_Pants",
      dropChance: 4,
      resources: [20002],
    },
    {
      name: "Plate Shoulders",
      type: "shoulder",
      defense: 4,
      imageName: "1_Mail_Shoulder",
      dropChance: 4,
      resources: [20002],
    },
    {
      name: "Steel Plate Belt",
      type: "belt",
      defense: 5,
      imageName: "2_Mail_belt",
      dropChance: 6,
      resources: [20005],
    },
    {
      name: "Steel Plate Boots",
      type: "boots",
      defense: 5,
      imageName: "2_Mail_Boots",
      dropChance: 6,
      resources: [20005],
    },
    {
      name: "Steel Plate Bracers",
      type: "arms",
      defense: 5,
      imageName: "2_Mail_bracers",
      dropChance: 6,
      resources: [20005],
    },
    {
      name: "Steel Plate Chest",
      type: "chest",
      defense: 6,
      imageName: "2_Mail_Chest",
      dropChance: 6,
      resources: [20005],
    },
    {
      name: "Steel Plate Gloves",
      type: "gloves",
      defense: 5,
      imageName: "2_Mail_gloves",
      dropChance: 6,
      resources: [20005],
    },
    {
      name: "Steel Plate Helmet",
      type: "helmet",
      defense: 5,
      imageName: "2_Mail_head",
      dropChance: 6,
      resources: [20005],
    },
    {
      name: "Steel Plate Leggings",
      type: "legs",
      defense: 5,
      imageName: "2_mail_Pants",
      dropChance: 6,
      resources: [20005],
    },
    {
      name: "Steel Plate Shoulders",
      type: "shoulder",
      defense: 5,
      imageName: "2_Mail_Shoulder",
      dropChance: 6,
      resources: [20005],
    },
    {
      name: "Mages Cloth Belt",
      type: "belt",
      magic: 1,
      defense: 1,
      imageName: "2_Cloth_belt",
      dropChance: 3,
      resources: [20001, 20004],
    },
    {
      name: "Mages Cloth Boots",
      type: "boots",
      magic: 1,
      defense: 1,
      imageName: "2_Cloth_Boots",
      dropChance: 3,
      resources: [20001, 20004],
    },
    {
      name: "Mages Cloth Bracers",
      type: "arms",
      magic: 1,
      defense: 1,
      imageName: "2_Cloth_bracers",
      dropChance: 3,
      resources: [20001, 20004],
    },
    {
      name: "Mages Cloth Chest",
      type: "chest",
      magic: 1,
      defense: 2,
      imageName: "2_Cloth_Chest",
      dropChance: 3,
      resources: [20001, 20004],
    },
    {
      name: "Mages Cloth Gloves",
      type: "gloves",
      magic: 1,
      defense: 1,
      imageName: "2_Cloth_gloves",
      dropChance: 3,
      resources: [20001, 20004],
    },
    {
      name: "Mages Cloth Hat",
      type: "helmet",
      magic: 2,
      defense: 1,
      imageName: "2_Cloth_Head",
      dropChance: 3,
      resources: [20001, 20004],
    },
    {
      name: "Mages Cloth Leggings",
      type: "legs",
      magic: 1,
      defense: 1,
      imageName: "2_Cloth_Pants",
      dropChance: 3,
      resources: [20001, 20004],
    },
    {
      name: "Mages Cloth Shoulders",
      type: "shoulder",
      magic: 1,
      defense: 1,
      imageName: "2_Cloth_sloulder",
      dropChance: 3,
      resources: [20001, 20004],
    },
    {
      name: "Worn Axe",
      type: "hand1",
      attack: 2,
      imageName: "Axe_01",
      dropChance: 1,
      resources: [20003, 20002],
    },
    {
      name: "Worn Double Axe",
      type: "hand1",
      attack: 4,
      imageName: "Axe_02",
      dropChance: 1,
      isTwoHanded: true,
      resources: [20003, 20002],
    },
    {
      name: "Woodcutters Hatchet",
      type: "hand1",
      attack: 2,
      imageName: "Axe_v2_04",
      dropChance: 1,
      resources: [20003, 20002],
    },
    {
      name: "Gem Infused Axe",
      type: "hand1",
      attack: 4,
      imageName: "Axe_v2_18",
      dropChance: 1,
      resources: [20003, 20002, 20004],
    },
    {
      name: "Knuckle Duster",
      type: "hand1",
      attack: 1,
      speed: 1,
      imageName: "BrassKnuckles_v2_01",
      dropChance: 1,
      resources: [20002],
    },
    {
      name: "Crossbow",
      type: "hand1",
      attack: 3,
      defense: 1,
      speed: 1,
      imageName: "Crossbow_01",
      dropChance: 1,
      isTwoHanded: true,
      resources: [20003],
    },
    {
      name: "Bow",
      type: "hand1",
      attack: 2,
      speed: 1,
      defense: 1,
      imageName: "Bow_01",
      dropChance: 1,
      isTwoHanded: true,
      resources: [20003],
    },
    {
      name: "Ice Infused Bow",
      type: "hand1",
      attack: 4,
      speed: 1,
      defense: 3,
      magic: 3,
      imageName: "Bow_v2_06",
      dropChance: 5,
      isTwoHanded: true,
      resources: [20003, 20014, 20004],
    },
    {
      name: "Bone Club",
      type: "hand1",
      attack: 2,
      imageName: "Club_v2_01",
      dropChance: 1,
      resources: [20010],
    },
    {
      name: "Sword",
      type: "hand1",
      attack: 2,
      imageName: "Sword_01",
      dropChance: 1,
      resources: [20004],
    },
    {
      name: "Steel Sword",
      type: "hand1",
      attack: 3,
      imageName: "Sword_16",
      dropChance: 2,
      resources: [20005],
    },
    {
      name: "Runite Sword",
      type: "hand1",
      attack: 4,
      speed: 2,
      imageName: "Sword_24",
      dropChance: 4,
      resources: [20013, 20005],
    },
    {
      name: "Wand",
      type: "hand1",
      attack: 1,
      magic: 2,
      isMagicWeapon: true,
      imageName: "Wand_v2_01",
      dropChance: 1,
      resources: [20003, 20004],
    },
    {
      name: "Short Spear",
      type: "hand1",
      attack: 2,
      defense: 1,
      imageName: "Spear_01",
      dropChance: 1,
      resources: [20003, 20002],
    },
    {
      name: "Trident",
      type: "hand1",
      attack: 5,
      defense: 1,
      isTwoHanded: true,
      imageName: "Spear_04",
      dropChance: 3,
      resources: [20003, 20002],
    },
    {
      name: "Mages Staff",
      attack: 3,
      defense: 1,
      magic: 3,
      type: "hand1",
      isMagicWeapon: true,
      isTwoHanded: true,
      imageName: "staff_1",
      dropChance: 2,
      resources: [20003, 20004],
    },
    {
      name: "Wooden Shield",
      type: "hand2",
      defense: 2,
      imageName: "shield_01",
      dropChance: 1,
      resources: [20003],
    },
    {
      name: "Metal Plated Shield",
      type: "hand2",
      defense: 3,
      imageName: "shield_06",
      dropChance: 2,
      resources: [20002],
    },
    {
      name: "Steel Heater Shield",
      type: "hand2",
      defense: 4,
      imageName: "shield_steel",
      dropChance: 3,
      resources: [20005],
    },
    {
      name: "Scythe",
      type: "hand1",
      attack: 4,
      defense: 1,
      imageName: "Scythe_01",
      dropChance: 1,
      isTwoHanded: true,
      resources: [20003, 20002],
    },
    {
      name: "Pickaxe",
      type: "hand1",
      attack: 2,
      defense: 1,
      imageName: "Mining_18_pickaxe",
      dropChance: 1,
      resources: [20003, 20002],
    },
    {
      name: "Flintlock Pistol",
      type: "hand1",
      attack: 2,
      defense: 2,
      imageName: "Gun_v2_01",
      dropChance: 1,
      resources: [20003, 20002],
    },
    {
      name: "Dagger",
      type: "hand1",
      attack: 1,
      speed: 2,
      imageName: "Dagger_01",
      dropChance: 1,
      resources: [20003, 20002],
    },
    {
      name: "Necklace",
      type: "neck",
      magic: 1,
      speed: 1,
      attack: 1,
      defense: 1,
      imageName: "necklace_01",
      dropChance: 2,
      resources: [20002, 20004],
    },
    {
      name: "Gold Chain Necklace",
      type: "neck",
      magic: 2,
      speed: 2,
      attack: 2,
      defense: 2,
      imageName: "necklace_08",
      dropChance: 3,
      resources: [20009],
    },
    {
      name: "Cape",
      type: "back",
      magic: 1,
      speed: 1,
      attack: 1,
      defense: 1,
      imageName: "Back_01",
      dropChance: 2,
      resources: [20001, 20004],
    },
    {
      name: "Silk Belt",
      type: "belt",
      defense: 1,
      speed: 1,
      magic: 1,
      imageName: "3_Cloth_belt",
      dropChance: 2,
      resources: [20001, 20004],
    },
    {
      name: "Silk Boots",
      type: "boots",
      defense: 1,
      magic: 1,
      imageName: "3_Cloth_Boots",
      dropChance: 2,
      resources: [20001, 20004],
    },
    {
      name: "Silk Bracers",
      type: "arms",
      defense: 1,
      speed: 1,
      magic: 1,
      imageName: "3_Cloth_bracers",
      dropChance: 2,
      resources: [20001, 20004],
    },
    {
      name: "Silk Chest",
      type: "chest",
      defense: 2,
      speed: 1,
      magic: 1,
      imageName: "3_Cloth_Chest",
      dropChance: 2,
      resources: [20001, 20004],
    },
    {
      name: "Silk Gloves",
      type: "gloves",
      defense: 1,
      speed: 1,
      magic: 1,
      imageName: "3_Cloth_gloves",
      dropChance: 2,
      resources: [20001, 20004],
    },
    {
      name: "Silk Hood",
      type: "helmet",
      defense: 1,
      speed: 1,
      magic: 1,
      imageName: "3_Cloth_Head",
      dropChance: 2,
      resources: [20001, 20004],
    },
    {
      name: "Silk Leggings",
      type: "legs",
      defense: 1,
      speed: 1,
      magic: 1,
      imageName: "3_Cloth_Pants",
      dropChance: 2,
      resources: [20001, 20004],
    },
    {
      name: "Silk Shoulders",
      type: "shoulder",
      defense: 1,
      speed: 1,
      magic: 1,
      imageName: "3_Cloth_sloulder",
      dropChance: 2,
      resources: [20001, 20004],
    },
    {
      name: "Power Ring",
      type: "ring",
      attack: 2,
      luck: 2,
      imageName: "Ring_44",
      dropChance: 3,
      resources: [20004, 20009],
    },
    {
      name: "Lion Ring",
      type: "ring",
      attack: 3,
      luck: 4,
      imageName: "Ring_16",
      dropChance: 4,
      resources: [20004, 20009],
    },
    {
      name: "Warriors Band",
      type: "ring",
      attack: 2,
      defense: 2,
      imageName: "Ring_46",
      dropChance: 4,
      resources: [20004, 20009],
    },
  ],
};
