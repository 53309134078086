<template>
  <div :class="['quest-list-widget', showQuickAccess ? 'show' : '']">
    <div v-if="onlyDuringWandering" :class="['quick-access-list']">
      <button
        :class="[showQuickAccess ? 'show' : '', 'toggle-quick-access']"
        @click="openQuestList()"
        title="Quick Access - Accepted Quest List"
      >
        {{ showQuickAccess ? "X" : "Toggle Quick Map" }}
      </button>
      <div class="wrapper">
        <GenericLoading class="loading" v-if="isLoading" />
        <template v-else>
          <template
            v-if="guildContractWarriorsQuests?.acceptedQuests?.length > 0"
          >
            <div
              :class="[
                'quest-item',
                'defender',
                isKillQuestComplete(quest) ? 'finished' : '',
                aboutToExpire(quest) ? 'ending' : '',
              ]"
              :key="quest.id"
              v-for="(
                quest, index
              ) in guildContractWarriorsQuests.acceptedQuests"
              @click="toggleDefenderItemButton(index)"
            >
              <div>
                <p class="name">
                  <img
                    :src="getQuestImage(quest)"
                    :alt="quest.name"
                    class="questImage"
                  />
                  {{ quest.name }}
                </p>
                <span class="icon"></span>
                <p class="time grid-item">
                  <span
                    class="checkmark-container"
                    v-if="isKillQuestComplete(quest) && showQuickAccess"
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 98.5 98.5"
                      enable-background="new 0 0 98.5 98.5"
                      xml:space="preserve"
                    >
                      <path
                        class="checkmark"
                        fill="none"
                        stroke-width="8"
                        stroke-miterlimit="10"
                        d="M81.7,17.8C73.5,9.3,62,4,49.2,4
      C24.3,4,4,24.3,4,49.2s20.3,45.2,45.2,45.2s45.2-20.3,45.2-45.2c0-8.6-2.4-16.6-6.5-23.4l0,0L45.6,68.2L24.7,47.3"
                      />
                    </svg>
                  </span>
                  <span class="desc">Expires </span
                  ><span class="value">{{ getFinishDate(quest.endDate) }}</span>
                </p>
                <div class="progress" v-if="quest.goalType === 'monsterKill'">
                  <span
                    >{{ quest.kills === undefined ? 0 : quest.kills }}/{{
                      quest.quantity
                    }}</span
                  >
                  <div
                    class="bar"
                    :style="`width: ${
                      (quest.kills === undefined
                        ? 0
                        : quest.kills / quest.quantity) * 100
                    }%`"
                  ></div>
                </div>
              </div>
              <template v-if="showButtonDefender[index]">
                <DialogButton
                  v-if="isClaimableQuest(quest)"
                  buttonSize="med"
                  @click.stop="finishKillQuest(quest)"
                  :buttonText="'Complete Quest'"
                  :disabled="!isKillQuestComplete(quest) || acceptingQuest"
                  :isLoading="acceptingQuest"
                />
                <DialogButton
                  v-else
                  buttonSize="med"
                  @click.stop="submitItems(quest)"
                  :buttonText="'Submit Items'"
                  :isLoading="acceptingQuest"
                />
              </template>
            </div>
          </template>
          <template
            v-if="guildContractCraftersQuests?.acceptedQuests?.length > 0"
          >
            <div
              :class="[
                'quest-item',
                'crafter',
                quest.kills !== undefined && quest.kills === quest.quantity
                  ? 'finished'
                  : '',
                aboutToExpire(quest) ? 'ending' : '',
              ]"
              :key="quest.id"
              @click="toggleCrafterItemButton(index)"
              v-for="(
                quest, index
              ) in guildContractCraftersQuests.acceptedQuests"
            >
              <div>
                <p class="name">
                  <img
                    :src="getQuestImage(quest)"
                    :alt="quest.name"
                    class="questImage"
                  />
                  {{ quest.name }}
                </p>
                <p class="time grid-item">
                  <!-- <span
                    class="checkmark-container"
                    v-if="
                      quest.kills !== undefined &&
                      quest.kills === quest.quantity
                    "
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 98.5 98.5"
                      enable-background="new 0 0 98.5 98.5"
                      xml:space="preserve"
                    >
                      <path
                        class="checkmark"
                        fill="none"
                        stroke-width="8"
                        stroke-miterlimit="10"
                        d="M81.7,17.8C73.5,9.3,62,4,49.2,4
    C24.3,4,4,24.3,4,49.2s20.3,45.2,45.2,45.2s45.2-20.3,45.2-45.2c0-8.6-2.4-16.6-6.5-23.4l0,0L45.6,68.2L24.7,47.3"
                      />
                    </svg>
                  </span> -->
                  <span class="desc">Expires </span
                  ><span class="value">{{ getFinishDate(quest.endDate) }}</span>
                </p>
              </div>
              <DialogButton
                v-if="showButtonCrafter[index]"
                buttonSize="med"
                @click.stop="submitItems(quest)"
                :buttonText="'Submit Items'"
                :isLoading="acceptingQuest"
              />
            </div>
          </template>
          <p
            class="no-quests"
            v-else-if="
              guildContractCraftersQuests?.acceptedQuests?.length === 0 &&
              guildContractWarriorsQuests?.acceptedQuests?.length === 0
            "
          >
            You haven't accepted any quests yet. Visit Defender's Or Crafter's
            Guild
          </p>
        </template>
      </div>
    </div>
  </div>
  <GuildItemInventory
    @refreshContracts="getGuildContracts"
    v-if="isGuildInventoryOpen"
  />
  <GuildRewards
    @refreshContracts="getGuildContracts"
    v-if="isGuildRewardsOpen"
  />
</template>
<script>
import { computed, ref, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
// import { useMixpanel } from "../../composables/mixpanel";
import apiConnector from "../../game/apiConnector";
import Constants from "../../consts/constants";
import GenericLoading from "../LoadingComponents/GenericLoading.vue";
import GuildRewards from "./GuildRewards.vue";
import GuildItemInventory from "./GuildItemInventory.vue";
import DialogButton from "../../components/DialogButton.vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export default {
  name: "QuickAccess",
  components: {
    GenericLoading,
    DialogButton,
    GuildRewards,
    GuildItemInventory,
  },
  setup() {
    const store = useStore();
    const showQuickAccess = ref(false);
    const isLoading = ref(false);
    const acceptingQuest = ref(false);
    const showButtonDefender = ref([]);
    const showButtonCrafter = ref([]);

    // Mixpanel Tracking
    // const { trackEvent } = useMixpanel();

    // store unwatchers
    let unWatchCharacterId = null;
    let unWatchShowStatus = null;

    onMounted(async () => {
      // Getting guild quests the first time quest list is mounted
      // isLoading.value = true;
      // await getGuildContractDefenders();
      // await getGuildContractCrafters();
      // isLoading.value = true;
      unWatchCharacterId = store.watch(
        (state) => state.currentCharacterId,
        async () => {
          if (showQuickAccess.value) {
            console.log("CHARACTER CHANGED UPDATEDING QUESTS");
            isLoading.value = true;
            await getGuildContractDefenders();
            await getGuildContractCrafters();
            isLoading.value = false;
          }
        }
      );

      unWatchShowStatus = watch(onlyDuringWandering, async (showStatus) => {
        if (showStatus && showQuickAccess.value) {
          isLoading.value = true;
          await getGuildContractDefenders();
          await getGuildContractCrafters();
          isLoading.value = false;
        }
      });
    });
    onUnmounted(() => {
      unWatchCharacterId();
      unWatchShowStatus();
    });

    const openQuestList = async () => {
      showQuickAccess.value = !showQuickAccess.value;
      await getGuildContracts();
    };

    store.watch(
      (state) => state.currentCharacterId,
      async () => {
        await getGuildContracts();
      }
    );

    const toggleDefenderItemButton = (index) => {
      showButtonDefender.value[index] = !showButtonDefender.value[index];
      // showButtonDefender.value = showButtonDefender.value.map((_, i) =>
      //   i === index ? showButtonDefender.value[index] : false
      // );
      // showButtonCrafter.value = showButtonCrafter.value.map(
      //   (_, i) => i === index
      // );
    };

    const toggleCrafterItemButton = (index) => {
      showButtonCrafter.value[index] = !showButtonCrafter.value[index];
      // showButtonCrafter.value = showButtonCrafter.value.map((_, i) =>
      //   i === index ? showButtonCrafter.value[index] : false
      // );
      // showButtonDefender.value = showButtonDefender.value.map(
      //   (_, i) => i === index
      // );
    };

    /**
     *
     * @param {object} quest
     * Return true if the remaining time is less than 3 hours.
     */
    const aboutToExpire = (quest) => {
      const now = new Date();
      const targetTime = new Date(quest.endDate).getTime() * 1000;
      const currentTime = now.getTime();
      const timeDiffInMillis = targetTime - currentTime;
      const hoursLeft = Math.floor(timeDiffInMillis / (1000 * 60 * 60));
      if (hoursLeft < 3) {
        return true;
      }
      return false;
    };
    const submitItems = (quest) => {
      store.commit("guild/setSelectedQuestForSubmit", quest);
      store.commit("guild/setIsGuildInventoryOpen", true);
      console.log(quest);
    };

    const finishKillQuest = async (quest) => {
      console.log("Finishing Kill Quest", quest);
      store.commit("setTurnHappening", true);
      acceptingQuest.value = true;
      try {
        if (
          quest.goalType === Constants.contractQuestsGoalTypes.monsterKill ||
          quest.goalType === Constants.contractQuestsGoalTypes.bounty ||
          quest.goalType === Constants.contractQuestsGoalTypes.raid ||
          quest.goalType === Constants.contractQuestsGoalTypes.artefactRecovery
        ) {
          const finishQuest = await apiConnector.callFinishContractKillQuest(
            store.state.account,
            localStorage.getItem("sessionId"),
            character.value.number,
            quest.id
          );
          console.log(finishQuest);
          if (finishQuest.success) {
            store.commit("guild/setGuildQuestRewards", finishQuest.reward);
            store.commit("guild/setIsGuildRewardsOpen", true);
            store.commit("setHeroStats", finishQuest.heroStats);
          } else {
            store.commit("setNotification", finishQuest.message);
          }
        }
      } catch (error) {
        store.commit("setNotification", error);
        console.log(error);
      }
      store.commit("setTurnHappening", false);
      acceptingQuest.value = false;
    };

    const getGuildContracts = async () => {
      isLoading.value = true;
      await getGuildContractDefenders();
      await getGuildContractCrafters();
      isLoading.value = false;
    };

    const getGuildContractDefenders = async () => {
      try {
        const contractQuestsWarriors = await apiConnector.callGetContractQuests(
          store.state.account,
          localStorage.getItem("sessionId"),
          character.value.number,
          Constants.contractQuestTypes.defendersGuild
        );

        // store.commit("guild/setContractCraftersQuests", contractQuestsCraft);
        store.commit("guild/setContractWarriorsQuests", contractQuestsWarriors);
      } catch (error) {
        console.log(error);
      }
    };

    const getGuildContractCrafters = async () => {
      try {
        const contractQuestsCrafters = await apiConnector.callGetContractQuests(
          store.state.account,
          localStorage.getItem("sessionId"),
          character.value.number,
          Constants.contractQuestTypes.craftersGuild
        );
        // store.commit("guild/setContractCraftersQuests", contractQuestsCraft);
        store.commit("guild/setContractCraftersQuests", contractQuestsCrafters);
      } catch (error) {
        console.log(error);
      }
    };

    const getFinishDate = (timestamp) => {
      const d = new Date(timestamp * 1000);
      return dayjs(d).fromNow();
    };

    const isKillQuestComplete = (quest) => {
      if (quest.goalType === Constants.contractQuestsGoalTypes.monsterKill) {
        return (quest.kills === undefined ? 0 : quest.kills) === quest.quantity;
      } else if (
        quest.goalType == Constants.contractQuestsGoalTypes.raid ||
        quest.goalType == Constants.contractQuestsGoalTypes.bounty ||
        quest.goalType == Constants.contractQuestsGoalTypes.artefactRecovery
      ) {
        return quest.isCompleted;
      } else {
        return false;
      }
    };

    const getQuestImage = (quest) => {
      if (quest.type === Constants.contractQuestTypes.defendersGuild) {
        if (quest.goalType === Constants.contractQuestsGoalTypes.monsterKill) {
          return "https://ik.imagekit.io/dcg/equip/Warriorskill_28_nobg.png?tr=w-30";
        } else if (
          quest.goalType ===
          Constants.contractQuestsGoalTypes.equipmentCollection
        ) {
          return "https://ik.imagekit.io/dcg/equip/Tailoring_44_little_bag.png?tr=w-30";
        } else if (
          quest.goalType === Constants.contractQuestsGoalTypes.raid ||
          quest.goalType === Constants.contractQuestsGoalTypes.bounty
        ) {
          return `https://cdn.dragoncrypto.io/encounters/${quest.image}?tr=w-30`;
        } else if (
          quest.goalType === Constants.contractQuestsGoalTypes.artefactRecovery
        ) {
          return `https://cdn.dragoncrypto.io/equip/${quest.image}?tr=w-30`;
        }
      } else if (quest.type === Constants.contractQuestTypes.craftersGuild) {
        return quest.image !== undefined
          ? `https://ik.imagekit.io/dcg/equip/${quest.image}?tr=w-30`
          : "https://ik.imagekit.io/dcg/equip/Tailoring_44_little_bag.png?tr=w-30";
      }
    };

    const isClaimableQuest = (quest) => {
      return (
        quest.goalType === Constants.contractQuestsGoalTypes.monsterKill ||
        quest.goalType === Constants.contractQuestsGoalTypes.raid ||
        quest.goalType === Constants.contractQuestsGoalTypes.artefactRecovery ||
        quest.goalType === Constants.contractQuestsGoalTypes.bounty
      );
    };

    // Computed
    const guildContractCraftersQuests = computed(() => {
      return store.state.guild.contractCraftersQuests;
    });

    const guildContractWarriorsQuests = computed(() => {
      return store.state.guild.contractWarriorsQuests;
    });

    const character = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });

    const onlyDuringWandering = computed(() => {
      return store.state.gameState == Constants.gamemodes.wandering;
    });

    const isGuildRewardsOpen = computed(() => {
      return store.state.guild.isGuildRewardsOpen;
    });

    const isGuildInventoryOpen = computed(() => {
      return store.state.guild.isGuildInventoryOpen;
    });

    return {
      //data
      showQuickAccess,
      isLoading,
      acceptingQuest,
      showButtonDefender,
      showButtonCrafter,
      //methods
      getFinishDate,
      openQuestList,
      getQuestImage,
      getGuildContracts,
      finishKillQuest,
      submitItems,
      toggleDefenderItemButton,
      toggleCrafterItemButton,
      isClaimableQuest,
      aboutToExpire,
      isKillQuestComplete,
      //computed
      guildContractCraftersQuests,
      guildContractWarriorsQuests,
      onlyDuringWandering,
      isGuildRewardsOpen,
      isGuildInventoryOpen,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

$circle-size: 30px;
$stroke-width: 2;

.quest-list-widget {
  position: fixed;
  z-index: 12;
  top: 105px;
  right: -300px;
  width: 300px;
  transition: all 0.1s ease-out;
  display: block;
  p.no-quests {
    color: #ffffff87;
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
  .wrapper {
    max-height: 300px;
    overflow-y: scroll;
    position: relative;
    min-height: 70.3px;
    .loading {
      position: absolute;
      width: 70px;
      height: 70px;
    }
    /* scrollbar */
    &::-webkit-scrollbar {
      width: 2px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background: transparent;
    }
  }
  @media only screen and (max-width: 576px) {
    bottom: -1px;
    top: 56%;
    right: -306px;
  }
  &.show {
    right: 0;
  }
  .quest-item {
    background: rgba(0, 0, 0, 0.34);
    padding: 10px;
    margin: 0 0 5px;
    border-radius: 6px;
    position: relative;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: "";
      height: 20px;
      width: 18px;
      position: absolute;
      display: block;
      top: 5px;
      right: 5px;
      opacity: 1;
      z-index: 1;
    }
    &.defender {
      &:before {
        background: url("../../assets/ui/defenders-influence-points.png")
          no-repeat left center;
        background-size: contain;
      }
    }
    &.crafter {
      &:before {
        background: url("../../assets/ui/crafters-guild-influence-points.png")
          no-repeat left center;
        background-size: contain;
      }
    }
    &.ending {
      background-color: rgb(126 1 1 / 34%);
      &:hover {
        background: rgba(216, 11, 0, 0.2);
      }
    }
    &.finished {
      position: relative;
      background: rgb(0 198 19 / 11%);
      &:hover {
        background: rgb(0 198 19 / 20%);
      }
      .progress {
        .bar {
          background: rgb(75, 144, 48);
          background: linear-gradient(
            0deg,
            rgb(48, 144, 58) 0%,
            rgb(43, 168, 47) 48%,
            rgb(15, 99, 46) 100%
          );
        }
      }
    }
    &:hover {
      background: rgba(0, 0, 0, 0.6);
    }
    .name {
      font-size: 0.9rem;
      text-align: left;
      margin: 0;
      width: 93%;
      display: grid;
      grid-template-columns: 30px 1fr;
      grid-gap: 5px;
      img {
        width: 30px;
      }
    }
    .time {
      &:before {
        display: none;
      }
      text-align: left;
      font-size: 0.8rem;
      opacity: 0.5;
      margin: 5px 0;
    }
  }
  /* .progress {
    width: 100%;
    position: relative;
    left: 0;
    height: 20px;
    bottom: 0;
    margin: 1rem 0 0;
    border: 1px solid rgb(188 179 60 / 31%);
    span {
      display: block;
    }
  } */
  .progress {
    width: 100%;
    height: 4px;
    /* background: rgba(255, 255, 255, 0.1490196078); */
    background: rgb(53 53 53 / 80%);
    position: relative;
    border: 1px solid rgb(188 179 60 / 31%);
    border-left: none;
    border-right: none;
    span {
      display: none;
    }
    span {
      position: absolute;
      left: 50%;
      font-size: 0.8rem;
      text-shadow: 0 2px 2px #000000;
      text-transform: uppercase;
      color: #fff;
      border-radius: 4px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .bar {
      height: 100%;
      background: rgb(144, 52, 48);
      background: linear-gradient(
        0deg,
        rgba(144, 52, 48, 1) 0%,
        rgba(168, 48, 43, 1) 48%,
        rgba(99, 18, 15, 1) 100%
      );
    }
  }
}
.quick-access-list {
  background-image: url("https://cdn.dragoncrypto.io/popup/popup-base.webp");
  background-position: center center;
  background-repeat: repeat;
  border: 10px solid;
  border-image-source: url("../../assets/popup/small-border.webp");
  border-image-outset: 0;
  border-image-slice: 53;
  border-image-repeat: repeat;
  background-color: #171110;
  display: block;

  button.toggle-quick-access {
    position: absolute;
    display: block;
    width: 44px;
    height: 44px;
    background: url("../../assets/ui/quest-list.png") no-repeat;
    background-size: cover;
    box-shadow: none;
    border: none;
    opacity: 1;
    text-indent: -9999px;
    left: -60px;
    z-index: 5;
    transition: all 0.1s ease-out;

    cursor: url(https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png),
      auto;
    &:hover {
      transform: scale(1.2);
      transition: transform 0.2s ease-in-out;
    }
    &.show {
      transform: rotate(45deg);
    }
  }
  .checkmark-container {
    width: 15px;
    margin: 0 2px 0 0;
    display: inline-block;
    position: relative;
    top: 3px;
    .checkmark {
      stroke: green;
      stroke-dashoffset: 745.74853515625;
      stroke-dasharray: 745.74853515625;
      background-color: red;
      animation: dash 2s ease-out forwards 2;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 745.74853515625;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  .quick-access-button {
    border: none;
    box-shadow: none;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    font-weight: 300;
    display: block;
    width: 100%;
    text-align: left;
    color: #fff;
    background: transparent;
    padding: 7px 25px;
    font-size: 0.8rem;
    text-transform: uppercase;
    border-bottom: 1px rgb(255 255 255 / 22%) solid;
    &:last-child {
      border-bottom: none;
    }
    &:after {
      content: "";
      width: 300px;
      height: calc(100% - 20px);
      position: absolute;
      background: rgb(28, 23, 22);
      display: none;
      z-index: 10;
      top: 0;
      pointer-events: none;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 77%;
      left: -174%;
      border: 10px solid;
      border-image-source: url("../../assets/popup/small-border.webp");
      border-image-outset: 0;
      border-image-slice: 53;
      border-image-repeat: repeat;
      background-color: #171110;
    }
    &:hover {
      color: $gold;
      &:after {
        display: block;
        @media only screen and (max-width: 576px) {
          display: none;
        }
      }
    }
  }
}
</style>
