<template>
  <div :class="getClass">
    <div class="inner-dialog-wrapper">
      <GameMessage
        :heading="
          locationGatheringAction +
          ' ' +
          locationResourceDescription +
          ' of ' +
          resourceName
        "
      >
        <div id="gathering-log" class="gathering-log">
          <p
            v-for="combatLogEntry in combatLog"
            v-html="combatLogEntry"
            :key="combatLogEntry"
          ></p>
        </div>
      </GameMessage>
      <WaitingForTurn />
      <div
        :class="
          'buttons ' + (isTurnHappening ? 'hide-buttons' : 'show-buttons')
        "
      >
        <template v-if="!isDepleted && !isExhuasted">
          <DialogButton
            @click="startGather"
            :buttonText="'(1) Use ' + this.getSlotDesc(0)"
            :disabled="isLoading || !heroHasEnoughGathering"
            :isLoading="isLoading"
          />
          <DialogButton
            @click="stopGather"
            :buttonText="'Abandon ' + locationResourceDescription"
            :disabled="isLoading"
            :isLoading="isLoading"
          />
        </template>
        <template v-else>
          <DialogButton
            v-if="isMines"
            @buttonClick="searchMines"
            :disabled="isLoading"
            :isLoading="isLoading"
            :buttonText="'Keep Searching'"
            :requiresTurn="true"
          />
          <DialogButton
            v-if="isDeepwood"
            @buttonClick="searchDeepwood"
            :disabled="isLoading"
            :isLoading="isLoading"
            :buttonText="'Keep Searching'"
            :requiresTurn="true"
          />
          <DialogButton @click="$emit('leave')" :buttonText="'Leave'" />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import GameMessage from "./GameMessage.vue";
import DialogButton from "./DialogButton.vue";
import WaitingForTurn from "./LoadingComponents/WaitingForTurn.vue";
import Constants from "../consts/constants";

export default {
  //TODO : Enable eslint
  /* eslint-disable */
  name: "Gathering",
  emits: [
    "searchMinesAgain",
    "searchDeepwoodAgain",
    "stopGathering",
    "useGatheringTool",
  ],
  components: {
    GameMessage,
    DialogButton,
    WaitingForTurn,
  },
  props: {},
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    getSlotDesc(number) {


      if (this.getHotbar[number] && this.getHotbar[number].type != Constants.useable.consumable) {
        return this.getHotbar[number].name;
      }

      return "Fists";
    },
    async stopGather() {
      this.isLoading = true;
      this.$emit("stopGathering");
      this.isLoading = false;
    },
    async startGather() {
      this.isLoading = true;
      this.$emit("useGatheringTool", 0);
      this.isLoading = false;
    },
    async searchMines() {
      this.isLoading = true;
      this.$emit("searchMinesAgain");
      this.isLoading = false;
    }, 
    async searchDeepwood() {
      this.isLoading = true;
      this.$emit("searchDeepwoodAgain");
      this.isLoading = false;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    doScroll() {
      if (this.$store.state.gameState == Constants.gamemodes.gathering) {
        const logElement = document.getElementById("gathering-log");
        logElement.scroll({ top: logElement.scrollHeight, behavior: "smooth" });
      }
    },
  },
  computed: {
    isTurnHappening() {
      return this.$store.state.isTurnHappening;
    },
    getCharacter() {
      return this.$store.state.characters[this.$store.state.currentCharacter];
    },
    isDepleted() {
      return (
        this.$store.state.currentGathering &&
        this.$store.state.currentGathering.isDepleted
      );
    },
    isExhuasted() {
      return (
        this.$store.state.currentGathering &&
        this.$store.state.currentGathering.isExhausted
      );
    },
    getClass() {
      let mainClass =
        "gathering-wrapper wrap-content " +
        (this.$store.state.gameState == Constants.gamemodes.gathering
          ? "show"
          : "hide");
      if (this.$store.state.gameState == Constants.gamemodes.gathering) {
        if (this.isMines) {
          mainClass += " " + "mines";
        } else if (this.isDeepwood) {
          mainClass += " " + "deepwood";
        }
        // mainClass +=
        //   " " + (this.locationName ? this.locationName.toLowerCase() : "");
      }
      mainClass += " " + this.$store.state.timeOfDay;

      return mainClass;
    },

    isGatheringSet() {
      return (
        this.$store.state.currentGathering &&
        this.$store.state.currentGathering.resource
      );
    },
    locationName() {
      if (!this.isGatheringSet) {
        return "";
      }
      if (
        this.$store.state.miningEncounter &&
        this.$store.state.miningEncounter.locationName
      ) {
        if (this.isMines) {
          return "Mines";
        } else if (this.isDeepwood) {
          return "Deepwood";
        }
        // if (
        //   this.$store.state.miningEncounter.locationName.toLowerCase() ==
        //   "woods"
        // ) {
        //   return "Deepwood";
        // }

        // if (
        //   this.$store.state.miningEncounter.locationName
        //     .toLowerCase()
        //     .indexOf("mines") >= 0
        // ) {
        //   return "Mines";
        // }
      }

      if (
        this.$store.state.currentEncounter &&
        this.$store.state.currentEncounter.locationName
      ) {
        if (
          this.$store.state.currentEncounter.locationName.toLowerCase() ==
          "woods"
        ) {
          return "Deepwood";
        }

        if (
          this.$store.state.currentEncounter.locationName
            .toLowerCase()
            .indexOf("mines") >= 0
        ) {
          return "Mines";
        }
      }

      return this.$store.state.currentEncounter.locationName;
    },
    isMines() {
      return (
        this.$store.state.charLocation == Constants.resourceLocations.mines
      );
    },
    isDeepwood() {
      return (
        this.$store.state.charLocation == Constants.resourceLocations.deepwood
      );
    },
    gatheringRequired() {
      if (!this.isGatheringSet) {
        return "";
      }
      return this.$store.state.currentGathering.resource.gatheringRequired;
    },
    heroGatheringSkill() {
      if (this.player) {
        return this.player.stats.gathering;
      }
      return 0;
    },
    equipmentGatheringSkill() {
      if (this.player) {
        return this.$store.state.equipmentStats().gathering;
      }
      return 0;
    },
    heroHasEnoughGathering() {
      return ( this.heroGatheringSkill + this.equipmentGatheringSkill + Constants.gatheringBand ) >= this.gatheringRequired;
    },
    resourceName() {
      if (!this.isGatheringSet) {
        return "";
      }
      return this.$store.state.currentGathering.resource.name;
    },
    locationResourceDescription() {
      if (!this.isGatheringSet) {
        return "";
      }

      return this.$store.state.currentGathering.resource.resourceDescription;
    },
    locationGatheringAction() {
      if (!this.isGatheringSet) {
        return "";
      }
      return this.$store.state.currentGathering.resource.gatheringAction;
    },
    icon() {
      if (!this.isGatheringSet) {
        return "";
      }
      let imageLocation =
        Constants.iconLocation +
        this.$store.state.currentGathering.resource.imageName;
      return imageLocation;
    },
    combatLog() {
      return this.$store.state.combatLog;
    },
    player() {
      return this.$store.state.characters[this.$store.state.currentCharacter];
    },
    getHotbar() {
      return this.$store.state.hotbar();
    },
  },
  watch: {
    combatLog: {
      handler: function () {
        setTimeout(() => {
          this.doScroll();
        }, 30);
      },
      deep: true,
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

/* .mines {
  background: no-repeat
    url("https://cdn.dragoncrypto.io/locationbackgrounds/MinesBG-large.webp")
    top center;
  background-size: cover;
} */
.gathering-wrapper {
  &.day {
    &.deepwood {
      background: no-repeat url("../assets/locationBackgrounds/deepwoods.webp")
        center center;
      background-size: cover;
    }
  }
  &.night {
    &.deepwood {
      background: no-repeat
        url("../assets/locationBackgrounds/deepwoods-night.webp") center center;
      background-size: cover;
    }
  }
  &.mines {
    background: no-repeat
      url("../assets/locationBackgrounds/MinesBG-large.webp") center center;
    background-size: cover;
  }
}

.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;
}

.damage {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 80px;
  color: $dmg;
  font-weight: bold;
  text-shadow: 0 0 2px #000, 0 2px 3px #000, 0 2px 3px #000;

  &.hide {
    transition: opacity 0.1s linear;
    opacity: 0;
  }

  &.show {
    transition: opacity 0.1s linear;
    opacity: 1;
    animation: fadeUp 1s;
  }
}

.gathering-log {
  min-height: 160px;
  height: 160px;
  overflow-y: auto;
  width: 430px;
  margin: 16px auto;
  padding: 8px;
  font-size: 90%;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }
}

.inner-content {
  position: relative;
}

.hide .buttons.show-buttons {
  pointer-events: none;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &.show-buttons {
    opacity: 1;
    pointer-events: all;

    transition: opacity 0.15s linear;
  }

  &.hide-buttons {
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.15s linear;
  }
}

p {
  font-size: 90%;
}

@keyframes opacityChange {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.85;
  }
}
</style>
