import axios from "axios";
import { createApp } from "vue";
import { dragscrollNext } from "vue-dragscroll";
import VueMixpanel from "vue-mixpanel";
import App from "./App.vue";
import firebase from "./plugins/firebase";
import router from "./router";
import store from "./store";

const app = createApp(App);
app
  .provide("$firebase", firebase)
  .use(store)
  .use(router)
  .use(VueMixpanel, {
    token: "6d760ec9983b6682fdf5b4996d67162f",
    config: {
      debug: false,
    },
  })
  .provide("axios", axios)
  .directive("dragscroll", dragscrollNext)
  .mount("#app");

app.config.errorHandler = (err, instance, info) => {
  //TODO: handle error, e.g. report to a service
  console.log("ERROR", err, instance, info);
};
