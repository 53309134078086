<template>
  <div :class="'crafting-wrapper ' + currentClass">
    <div class="crafting-menu">
      <div class="close-button button-small" @click="hide"><span>x</span></div>
      <div
        v-if="currentItem && currentItem.rarity"
        :class="'title ' + currentItem.rarity.toLowerCase()"
      >
        <div class="img-wrapper">
          <img :src="getImage(currentItem)" :alt="currentItem.name" />
        </div>
        <h2 :class="currentItem.rarity.toLowerCase()">
          {{ currentItem.name }} Durability Upgrade
        </h2>
      </div>
      <div class="stats" v-if="itemStats">
        <div class="stat">
          <template v-if="itemStats.totalDurability">
            <span class="desc">Durability</span>
            <span :class="success ? 'success' : ''" class="num"
              >{{ itemStats.durability }}/{{ itemStats.totalDurability }}</span
            >
            <span class="desc">Price per durability</span>
            <span class="num">${{ upgradePrice }}</span>
            <span class="desc">Item Rarity</span>
            <span class="num">{{ currentItem.rarity }}</span>
            <span class="desc">Max Durability</span>
            <span class="num">{{ maxDurability }}</span>
          </template>
        </div>
      </div>
      <div class="control-item">
        <div :class="['sell-price', 'inputs', 'dcar']">
          <label for="sell-price"
            >Upgrade Durability<input
              type="number"
              name="sell-price"
              :min="1"
              :max="maxDurability - itemStats.totalDurability"
              step="1"
              v-model="currentPrice"
              @keyup="updateCurrentPriceDollar"
            /><button class="maxButton" @click="enterMax">Max</button></label
          >
        </div>
      </div>
      <div class="control-item">
        <div class="sell-price-listing dcar">
          <label for="total-cost">Total Cost: ${{ totalPrice }}</label>
          <label for="total-dcar">Total DCAR: {{ totalDcarNeeded }}</label>
        </div>
      </div>

      <DialogButton
        v-if="totalDcarNeeded > dcarApprovalAmount"
        :buttonText="'Approve DCAR'"
        @click="approveDcar"
        :isLoading="isLoading"
      />
      <DialogButton
        v-else-if="canUpgradeDurability"
        :buttonText="'Upgrade Durability'"
        @click="upgradeDurability"
        :isLoading="isLoading"
      />
      <DialogButton v-else :buttonText="reason" :disabled="true" />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import DialogButton from "./DialogButton.vue";
import Constants from "../consts/constants";
import { useUser } from "../composables/user";
import loot from "../game/loot";
import { usePrice } from "../composables/price";
//import { parseError } from "../utils/helpers";
import { ethers } from "ethers";
import {
  getDCARContract,
  getDCGEquipmentUpgradeContract,
} from "../utils/getContract";

export default {
  name: "DurabilityMenu",
  components: {
    DialogButton,
  },
  data() {
    return {
      $store: {},
      isVisible: true,
      isInventoryOpen: false,
      approvedDCAR: false,
      upgradePrice: 0,
      dcarAmount: 0,
      totalPrice: 0.01,
      currentPrice: 1,
      dcarPerDollar: 0,
      dcarApprovalAmount: 0,
      success: false,
      address: "",
      signer: "",
    };
  },
  methods: {
    async checkUserDcarBalance() {
      const DcarContract = getDCARContract(this.signer);
      const dcarBalance = await DcarContract.balanceOf(this.address);
      this.dcarAmount = ethers.utils.formatEther(dcarBalance);
    },

    async checkDcarApproved() {
      const UpgradeContract = getDCGEquipmentUpgradeContract(this.signer);
      const DcarContract = getDCARContract(this.signer);
      let dcarAllowance = await DcarContract.allowance(
        this.address,
        UpgradeContract.address
      );

      dcarAllowance = ethers.utils.formatUnits(dcarAllowance);

      if (parseInt(dcarAllowance) > 0) {
        this.dcarApprovalAmount = dcarAllowance;
        this.approvedDCAR = true;
      }
    },
    async approveDcar() {
      this.$store.commit("setTurnHappening", true);
      try {
        const DcarContract = getDCARContract(this.signer);
        const UpgradeContract = getDCGEquipmentUpgradeContract(this.signer);
        const tx = await DcarContract.approve(
          UpgradeContract.address,
          ethers.utils.parseEther("1000000000")
        );
        const receipt = await tx.wait();
        if (receipt.status == 1) {
          await this.checkDcarApproved();
        }
        this.$store.commit("setTurnHappening", false);
      } catch (e) {
        console.log("error approving dcar", e);
        this.$store.commit("setTurnHappening", false);
      }
    },
    async signUpgradeItem() {
      const sessionId = localStorage.getItem("sessionId");
      const heroId = this.character.number;
      const equipmentId = this.currentItem.id;
      const durability = Math.ceil(this.currentPrice);

      const response = await fetch(
        Constants.apiUrl + "contract/sign-equipment-upgrade",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId,
            heroId,
            account: this.address,
            equipmentId,
            durability,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      return {
        equipmentId: data.equipmentId,
        durability: data.durability,
      };
    },
    async upgradeDurability() {
      this.success = false;
      this.$store.commit("setTurnHappening", true);
      const heroId = this.character.number;
      const sessionId = localStorage.getItem("sessionId");
      try {
        const UpgradeContract = getDCGEquipmentUpgradeContract(this.signer);
        const { equipmentId, durability } = await this.signUpgradeItem();
        const tx = await UpgradeContract.upgradeDurability(
          durability,
          equipmentId
        );
        const receipt = await tx.wait();
        if (receipt.status == 1) {
          const response = await fetch(
            Constants.apiUrl + "createEquipmentUpgrade",
            {
              method: "POST",
              body: JSON.stringify({
                sessionId,
                heroId,
                account: this.address,
                equipmentId,
                blockNumber: receipt.blockNumber,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();

          if (data.success) {
            this.$store.commit("setInventoryItemStats", data.item);
            this.currentPrice = this.tillMax;
            this.success = true;
          }

          this.$store.commit("setTurnHappening", false);
        }
      } catch (e) {
        console.log("error upgrading durability", e);
        this.$store.commit("setTurnHappening", false);
      }
    },

    async getUpradePrice() {
      const UpgradeContract = getDCGEquipmentUpgradeContract(this.signer);
      const upgradePrice = await UpgradeContract.pricePerDurability();
      this.upgradePrice = ethers.utils.formatEther(upgradePrice);
    },
    updateCurrentPriceDollar() {
      if (this.currentPrice < 0) {
        this.currentPrice = this.currentPrice * -1;
      }
      if (this.currentPrice > this.tillMax) {
        this.currentPrice = this.tillMax;
      }

      if (this.currentPrice < 0) {
        this.currentPrice = 0;
      }

      this.currentPrice = Math.ceil(this.currentPrice);
      this.totalPrice =
        Math.round(this.currentPrice * this.upgradePrice * 1000) / 1000;
    },

    enterMax() {
      this.currentPrice = this.tillMax;
      this.totalPrice =
        Math.round(this.currentPrice * this.upgradePrice * 1000) / 1000;
    },
    hovered() {
      this.$store.commit("setTooltipHovered");
    },
    unhovered() {
      this.$store.commit("setTooltipUnhovered");
    },
    hide() {
      this.$store.commit("hideDurability");
    },
    getImage(item) {
      if (item.isBlueprint) {
        return (
          "https://ik.imagekit.io/dcg/blueprints/" + item.imageName + "?tr=w-90"
        );
      }

      return "https://ik.imagekit.io/dcg/equip/" + item.imageName + "?tr=w-90";
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.isTurnHappening;
    },
    character() {
      return this.$store.state.characters[this.$store.state.currentCharacter];
    },
    tillMax() {
      if (this.itemStats.totalDurability) {
        return this.maxDurability - this.itemStats.totalDurability;
      }
      return 0;
    },
    canUpgradeDurability() {
      return (
        !this.isConsumable &&
        !this.currentItem.isBlueprint &&
        this.dcarAmount >= this.totalDcarNeeded &&
        this.currentPrice + this.itemStats.totalDurability <=
          this.maxDurability &&
        this.tillMax > 0
      );
    },
    isConsumable() {
      return (
        this.$store.state.hoveredItem.type &&
        this.$store.state.hoveredItem.type == Constants.useable.consumable
      );
    },
    isResources() {
      if (this.itemStats && this.itemStats.resources) {
        return true;
      }

      return false;
    },
    baseDurability() {
      const rarity = this.currentItem.rarity;
      const base = loot.rarityTypes.find((r) => r.name == rarity);
      return base.baseDurability * (1 + base.bonusPercent / 100);
    },
    maxDurability() {
      const baseDurability = this.baseDurability;
      const mainMax = baseDurability * Constants.maxDurabilityMultiplier;
      return this.itemStats.totalDurability > mainMax
        ? this.itemStats.totalDurability
        : mainMax;
    },
    totalDcarNeeded() {
      return Math.round(this.totalPrice * this.dcarPerDollar * 100) / 100;
    },
    currentClass() {
      return this.$store.state.isDurability ? "show" : "hide";
    },
    currentItem() {
      return this.$store.state.hoveredItem;
    },
    reason() {
      if (this.isConsumable) {
        return "No Consumables";
      }
      if (this.currentItem.isBlueprint) {
        return "No Bluprints";
      }
      if (this.dcarAmount < this.totalDcarNeeded) {
        return "Need More DCAR";
      }
      if (
        this.currentPrice + this.itemStats.totalDurability >
        this.maxDurability
      ) {
        return "Too Much Durability";
      }

      if (this.tillMax <= 0) {
        return "Already Max Durability";
      }
      return null;
    },
    itemStats() {
      if (!this.currentItem) {
        return null;
      }

      return this.currentItem.stats;
    },
  },
  async mounted() {
    this.$store = useStore();
    const { address, signer } = useUser();
    this.address = address;
    this.signer = signer;
    const { getDCARPricePerDollar } = usePrice();
    this.dcarPerDollar = await getDCARPricePerDollar();
    await this.checkUserDcarBalance();
    await this.checkDcarApproved();
    await this.getUpradePrice();
    await this.updateCurrentPriceDollar();

    this.sucess = false;

    this.$store.watch(
      (state) => state.inventory,
      () => {
        this.hide();
      }
    );
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.addDurability {
  background-color: #68b563;
  color: #fff;
  border: 1px solid #357031;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 900;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 10px !important;
  margin-right: 5px;
}

.success {
  color: green;
  font-weight: bold;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}

.sell-price label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sell-price-listing label {
  display: block;
  margin-bottom: 8px;
}

.control-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 0.8rem;
}

.addDurability:hover {
  background-color: #2a5a26;
}
.small-text {
  font-size: 80%;
}
.crafting-wrapper {
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;

  &.show {
    opacity: 1;
    pointer-events: all;

    transition: opacity 0.35s linear;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.35s linear;
  }
}

.crafting-menu {
  background: url("https://cdn.dragoncrypto.io/uiassets/tooltip.png") no-repeat
    top left;
  width: 298px;
  height: 334px;
  position: relative;

  .close-button {
    position: absolute;
    top: -2px;
    right: -2px;
  }

  .broken {
    color: $dmg;
  }

  .resources {
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      border-radius: 3px;
      border: 1px solid #3d3d40;
      padding: 4px;
      margin: 0 3px;
      display: flex;
      align-items: center;
      justify-content: start;

      flex-direction: column;

      font-size: 65%;

      &.not-enough {
        border: 1px solid $dmg;
        background: transparentize($color: $dmg, $amount: 0.5);
      }

      img {
        max-width: 36px;
      }
    }
  }

  .desc {
    text-align: left;

    &.healing {
      color: $text-highlight;
    }

    &.craft {
      color: $legendary;
    }
  }

  .num {
    text-align: right;

    &.healing {
      color: $text-highlight;
    }

    &.craft {
      color: $legendary;
    }
  }

  .bonuses {
    border-top: 1px solid #3d3d40;
    width: 85%;
    margin: 0 auto;
    margin-top: 12px;
    padding-top: 10px;
    font-size: 80%;
    color: #68b563;
    display: flex;
    flex-wrap: wrap;

    span {
      width: 33%;
    }
  }

  .stats {
    margin-top: 10px;
    color: #cac5c4;
    font-size: 80%;
    border-bottom: 1px solid #3d3d40;
    padding-bottom: 10px;

    .stat {
      margin: 0 auto;
      width: 85%;
    }

    span {
      width: 49%;
      display: inline-block;
    }
  }

  .set {
    margin-top: 8px;
    color: $artefact;
    width: 85%;
    font-size: 80%;
    margin: 0 auto;

    span {
      width: 49%;
      display: inline-block;
    }
  }

  .title {
    padding-top: 8px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: center;
    border-bottom: 1px solid #3d3d40;

    &.legendary {
      // .img-wrapper{

      // }

      h2 {
        color: $legendary;
      }
    }

    &.uncommon {
      h2 {
        color: $uncommon;
      }
    }

    &.shoddy {
      h2 {
        color: $shoddy;
      }
    }

    &.rare {
      h2 {
        color: $rare;
      }
    }

    &.mythical {
      h2 {
        color: $mythical;
      }
    }

    &.epic {
      h2 {
        color: $epic;
      }
    }

    &.artefact {
      h2 {
        color: $artefact;
      }
    }

    img {
      max-width: 45px;
      margin-right: 12px;
    }

    h2 {
      color: #fff;
      text-align: left;
      font-family: "IM Fell English", serif;
      font-size: 90%;
      text-transform: none;
      width: 75%;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 9px;

    .dialog-button {
      margin-top: 0;
    }
  }
}
</style>
