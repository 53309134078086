<template>
  <span
    class="skeleton-box"
    :style="`width:${width}px; height:${height}px;`"
  ></span>
</template>

<script>
export default {
  name: "SkeletalLoading",
  props: {
    width: {
      type: String,
      default: "100",
    },
    height: {
      type: String,
      default: "15",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.skeleton-box {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd2f;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.4) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
