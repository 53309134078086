// initial state
import Constants from "../../consts/constants";

const state = () => ({
  isMarketplaceOpen: false,
  listedItems: {},
  marketData: [],
  myListedItemsData: [],
  showInventory: false,
  page: 1,
  sortBy: "createdAt",
  marketFilter: Constants.filterTypes.all,
  order: "desc",
  searchTerm: "",
  isDCAUApproved: false,
  isDCARApproved: false,
});

// // getters
// const getters = {};

// // actions
const actions = {
  processSorting({ commit }, value) {
    switch (value) {
      case "Recent":
        commit("setSortBy", "createdAt");
        commit("setOrder", "desc");
        break;
      case "Oldest":
        commit("setSortBy", "createdAt");
        commit("setOrder", "asc");
        break;
      case "Price Desc":
        commit("setSortBy", "priceInDCAU");
        commit("setOrder", "desc");
        break;
      case "Price Asc":
        commit("setSortBy", "priceInDCAU");
        commit("setOrder", "asc");
        break;
      case "Rarity Desc":
        commit("setSortBy", "rarity");
        commit("setOrder", "desc");
        break;
      case "Rarity Asc":
        commit("setSortBy", "rarity");
        commit("setOrder", "asc");
        break;
      case "Attack Desc":
        commit("setSortBy", "stats.attack");
        commit("setOrder", "desc");
        break;
      case "Defense Desc":
        commit("setSortBy", "stats.defense");
        commit("setOrder", "desc");
        break;
      case "Speed Desc":
        commit("setSortBy", "stats.speed");
        commit("setOrder", "desc");
        break;
      case "Magic Desc":
        commit("setSortBy", "stats.magic");
        commit("setOrder", "desc");
        break;
      case "Crafting Desc":
        commit("setSortBy", "stats.crafting");
        commit("setOrder", "desc");
        break;
      case "Gathering Desc":
        commit("setSortBy", "stats.gathering");
        commit("setOrder", "desc");
        break;
      case "Health Desc":
        commit("setSortBy", "stats.totalHp");
        commit("setOrder", "desc");
        break;
      case "Crit Chance Desc":
        commit("setSortBy", "stats.criticalChance");
        commit("setOrder", "desc");
        break;
      default:
        break;
    }
  },
  loadMoreMarketItems({ commit, state }, items) {
    const currentItems = state.marketData;
    currentItems.push(...items);
    commit("setMarketData", currentItems);
  },
  loadMoreMyListedItems({ commit, state }, items) {
    const currentItems = state.myListedItemsData;
    currentItems.push(...items);
    commit("setMyListedItemsData", currentItems);
  },
  async updateMyListedItems({ commit, state, rootState }) {
    const marketplaceApiQuery = `marketplace/my-listed-items?type=${state.marketFilter}&page=1&sortBy=${state.sortBy}&order=${state.order}`;
    const response = await fetch(Constants.apiUrl + marketplaceApiQuery, {
      method: "POST",
      body: JSON.stringify({
        account: rootState.account,
        sessionId: localStorage.getItem("sessionId"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    if (data.success) {
      commit("setMyListedItemsData", data.marketplaceItems);
    }
  },
  async updateMarketplaceItems({ commit, state }) {
    const marketplaceApiQuery = `marketplace/get-items?type=${state.marketFilter}&search=${state.searchTerm}&page=1&sortBy=${state.sortBy}&order=${state.order}`;
    const response = await fetch(Constants.apiUrl + marketplaceApiQuery);
    const data = await response.json();
    if (data.success) {
      commit("setMarketData", data.marketplaceItems);
    }
  },
  async updateMarketplaceItemsByAddress({ commit, state }, address) {
    const marketplaceApiQuery = `marketplace/get-items-by-address?address=${address}&type=${state.marketFilter}&search=${state.searchTerm}&page=1&sortBy=${state.sortBy}&order=${state.order}`;
    const response = await fetch(Constants.apiUrl + marketplaceApiQuery);
    const data = await response.json();
    console.log("SELLER DATA", data);
    if (data.success) {
      commit("setMarketData", data.marketplaceItems);
    }
  },
};

// mutations
const mutations = {
  setPage(state, page) {
    state.page = page;
  },
  setSortBy(state, sortBy) {
    state.sortBy = sortBy;
  },
  setOrder(state, order) {
    state.order = order;
  },
  setMarketFilter(state, filter) {
    state.marketFilter = filter;
  },
  setMarketplaceOpenState(state, status) {
    state.isMarketplaceOpen = status;
  },
  setListedItemsState(state, payload) {
    if (payload && payload.id) {
      state.listedItems[payload.id] = payload;
    }
  },
  setMarketData(state, payload) {
    state.marketData = payload;
  },
  setMyListedItemsData(state, payload) {
    state.myListedItemsData = payload;
  },
  setShowInventoryState(state, status) {
    state.showInventory = status;
  },
  setSearchTerm(state, term) {
    state.searchTerm = term;
  },
  /**
   * Deleting Item from Listed Item State
   * @param {*} state
   * @param {*} id
   */
  deleteBoughtItem(state, id) {
    delete state.listedItems[id];
  },
  setIsDCAUApproved(state, status) {
    state.isDCAUApproved = status;
  },
  setIsDCARApproved(state, status) {
    state.isDCARApproved = status;
  },
};

export default {
  namespaced: true,
  state,
  //   getters,
  actions,
  mutations,
};
