<template>
  <router-view />
</template>
<script>
export default {
  name: "App",
  computed: {
    currentRouteName() {
      if (this.$route.name) {
        return this.$route.name.toLowerCase();
      }
      return "";
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/globals.scss";
@import url("https://fonts.googleapis.com/css2?family=Cormorant:wght@300;400;600;700&display=swap");
body,
html {
  background: #1c2023;
  margin: 0;
  padding: 0;
  height: 100%;
}
.button-small {
  background: url("https://cdn.dragoncrypto.io/uiassets/button-small.png")
    no-repeat center center;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: scale(1);
  transform-origin: center;
  transition: scale 0.1s ease-out;

  &:hover {
    transform: scale(1.1);

    transition: transform 0.1s ease-out;
  }

  span {
    color: #000;
    text-shadow: 0 0 2px #fff, 0 0 2px #fff, 0 1px 3px #fff;
    font-size: 110%;
    height: 24px;
    font-weight: bold;
  }
}
h1,
h2,
h3,
h4,
h5,
a {
  color: $gold;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 100;
  font-family: "Cinzel", serif;
  text-transform: uppercase;
}
h1 {
  font-size: 200%;
}
h2 {
  font-size: 190%;
}
h3 {
  font-size: 170%;
}

#app {
  overflow-x: hidden;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  min-height: 100%;
  .hero {
    background: url("https://cdn.dragoncrypto.io/assets/map.jpg") no-repeat
      center center;
    height: 140vh;
    background-size: cover;

    &.mint,
    &.mintstarterkit,
    &.heroes {
      background: none;
      height: auto;
      overflow: hidden;

      .arrow {
        display: none;
      }
    }

    &.alpha,
    &.play,
    &.play-beta {
      display: none;
      background: none;
      height: auto;

      .arrow {
        display: none;
      }
    }
  }
}

.arrow {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 95%;
  transform-origin: 50% 50%;
  transform: translate3d(-50%, -50%, 0);
}

.arrow-first {
  animation: arrow-movement $ani-speed ease-in-out infinite;
}
.arrow-second {
  animation: arrow-movement $ani-speed $ani-delay ease-in-out infinite;
}

.arrow:before,
.arrow:after {
  background: $gold;
  content: "";
  display: block;
  height: 3px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.55);
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
}

.arrow:before {
  transform: rotate(45deg) translateX(-23%);
  transform-origin: top left;
}

.arrow:after {
  transform: rotate(-45deg) translateX(23%);
  transform-origin: top right;
}

// Animation
@keyframes arrow-movement {
  0% {
    opacity: 0;
    top: 90%;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

header {
  display: flex;
  align-items: center;
  position: fixed;
  flex-wrap: wrap;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  margin-bottom: 920px;
  h1 {
    text-align: center;
    flex-grow: 1;
    margin: 0.5rem 0.5rem -1.8rem 0.5rem;
    padding: 0;

    @include respond-above(sm) {
      text-align: left;
      margin: 0.75rem 2rem 0.5rem 2rem;
    }

    a {
      margin: 0;
      padding: 0;
    }
    img {
      max-height: 78px;
    }
  }
}

#nav,
.footer-nav {
  flex-grow: 1;
  text-align: center;

  margin: 2rem 0.5rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include respond-above(sm) {
    margin: 2rem;
    display: block;
  }

  a {
    margin: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;

    &.router-link-exact-active {
      color: #f1e3b6;
    }
  }
}

.footer-nav {
  margin: 1rem 0.5rem;
}

@include respond-above(sm) {
  #nav,
  .footer-nav {
    text-align: right !important;
  }
}

footer {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.5rem;

  &.alpha {
    display: none;
  }

  @include respond-above(sm) {
    padding: 1.5rem;
  }

  .column {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    .home-link {
      margin: 0 auto;
    }
    .logo {
      max-height: 64px;
    }
  }
  .copy {
    width: 33%;
    margin-left: auto;
    text-align: right;
    flex-grow: 1;
    padding-right: 1.5rem;
    .socials {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 55%;
      margin-left: auto;
      a:first-child {
        img {
          width: 79%;
        }
      }
      img {
        width: 66%;
      }
    }
  }
  .dragon-logo {
    margin-right: 1rem;
    flex-grow: 1;
    img {
      margin-top: -10px;
      max-width: 120px;
    }
  }
}

@include respond-above(sm) {
  footer {
    flex-wrap: nowrap !important;
    .column {
      flex-wrap: nowrap !important;
      .home-link {
        margin: 0 !important;
      }

      .copy,
      .dragon-logo {
        flex-grow: 0;
      }
    }
  }
}
</style>
