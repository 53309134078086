<template>
  <div
    v-if="type === 'pet'"
    id="pet"
    :class="[
      'character-wrapper pet-container',
      'smaller',
      isPetAttacking ? 'attack-pet' : '',
      isTurnHappening ? 'disabled' : '',
    ]"
  >
    <div :class="['portrait-container']">
      <img
        :class="['portrait', isEnemyAttacking ? ' take-damage' : '']"
        :src="pet.image"
        :alt="pet.name"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "HeroPet",
  components: {},
  props: {
    pet: {},
    enemy: {},
    type: {
      type: String,
    },
  },
  setup() {
    const store = useStore();
    /**
     * Computed
     */
    const isPetAttacking = computed(() => {
      return store.state.combat.isPetAttacking;
    });

    const isTurnHappening = computed(() => {
      if (
        store.state.isTurnHappening ||
        store.state.isUsingConsumable ||
        store.state.isFleeing ||
        store.state.combat.isHeroAttacking ||
        store.state.combat.isEnemyAttacking ||
        store.state.combat.isPetAttacking
      ) {
        return true;
      } else {
        return false;
      }
    });

    const isEnemyAttacking = computed(() => {
      return store.state.combat.isEnemyAttacking;
    });

    return {
      // data
      // methods
      // computed
      isPetAttacking,
      isTurnHappening,
      isEnemyAttacking,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
@import "./combat-animations.scss";

/* $characterMoveSpeed: 1s;
$animateDelay: 0.5s;
$arrowMoveSpeed: 1s; */
$characterMoveSpeed: 0.5s;
$animateDelay: 0.5s;
$arrowMoveSpeed: 0.5s;

.pet-container {
  /* .fireworks {
    display: block;
    position: absolute;
    height: 500px;
    z-index: 0;
    width: 500px;
  } */
  .encounter-status,
  .character {
    display: grid;
    align-items: center;
    justify-items: center;
  }

  .portrait-container {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    display: grid;
    align-items: center;
    justify-items: center;
    background: #311a0b;
    top: 10rem;
    left: 60px;
    z-index: 5;
    transform: translateX(-50%);
    transition: all 0.5s ease-in-out;
    @media only screen and (max-width: 576px) {
      width: 50px;
      height: 50px;
      top: 6.5rem;
      left: 20px;
    }
    &:after {
      background: url("https://cdn.dragoncrypto.io/uiassets/character-frame.png")
        center center no-repeat;
      background-size: cover;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    img {
      width: 90%;
    }
    &.critical-hit {
      &:before {
        content: "";
        background: url("https://cdn.dragoncrypto.io/uiassets/blood-splatter.png")
          center center no-repeat;
        background-color: rgba(255, 0, 0, 0.419);
        mix-blend-mode: darken;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        animation: bloodSplatter 1.5s ease-in-out;
        animation-delay: $animateDelay;
      }
    }
  }
  .damage,
  .healing {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 0 0 2px #000, 0 2px 3px #000, 0 2px 3px #000;
    font-size: 2.5rem;
    @media only screen and (max-width: 576px) {
      font-size: 1.5rem;
    }
  }
  .damage {
    opacity: 0;
    span {
      font-size: 1.2rem;
      display: block;
      text-align: center;
      color: rgba(255, 254, 254, 0.887);
      text-shadow: 0 0 2px #000, 0 2px 3px #000, 0 2px 3px #000;
    }
    &.hide {
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
    }
    &.show {
      transition: opacity 0.3s ease-in-out;
      animation: fadeUp 1s;
      animation-delay: $animateDelay;
    }
  }
  .damage {
    color: $dmg;
  }
  .healing {
    color: $uncommon;
  }
  /* .take-damage {
    animation: shake 0.55s;
    animation-delay: $animateDelay;
  } */
}
.take-damage {
  animation: shakeCombat 0.55s;
  animation-delay: $animateDelay;
}

.attack-pet {
  .portrait-container {
    /* animation-name: shakePet, attackPet;
    animation-duration: 500ms, $characterMoveSpeed;
    animation-iteration-count: 1, 1;
    animation-delay: 0, 1s; */
    animation: attackPet $characterMoveSpeed;
    z-index: 5;
    @media only screen and (max-width: 576px) {
      animation: attackPetMobile $characterMoveSpeed;
    }
    @media only screen and (min-width: 3500px) {
      animation: attackPet4k $characterMoveSpeed;
    }
  }
}
.disabled {
  cursor: not-allowed;
}
</style>
