// initial state
const state = () => ({
  isFirstTime: false,
  isVideoOpen: false,
  isGuideOpen: false,
  isOnRampOpen: false,
  isDeadFlowComplete: false,
  isHealFlowComplete: false,
  isInSelfCrafting: false,
  afterStartingGuide: false,
  letsGoToBattle: false,
  searchPlains: false,
  lootStash: false,
  isWarriorStats: false,
  hasSeenUpdateList: false,
});

// // getters
// const getters = {};

// // actions
const actions = {
  loadIntro({ commit }) {
    const alreadyLoaded = localStorage.getItem("alreadyPlayedOnce");
    // console.log(alreadyLoaded === null);
    if (alreadyLoaded === null) {
      commit("setIsFirstTime", true);
      commit("setIsVideoOpen", true);
      localStorage.setItem("alreadyPlayedOnce", true);
    } else {
      // Loading onRamp everytime
      // commit("setIsOnRampOpen", true);
    }

    const seenUpdateList = localStorage.getItem("seenUpdateList");
    if (seenUpdateList === null) {
      commit("setHasSeenUpdateList", false);
    } else if (seenUpdateList === "true") {
      commit("setHasSeenUpdateList", true);
    }
  },
};

// mutations
const mutations = {
  setIsFirstTime(state, status) {
    state.isFirstTime = status;
  },
  setIsVideoOpen(state, status) {
    state.isVideoOpen = status;
  },
  setIsGuideOpen(state, status) {
    state.isGuideOpen = status;
  },
  setIsOnRampOpen(state, status) {
    state.isOnRampOpen = status;
  },
  setIsDeadFlowComplete(state, status) {
    state.isDeadFlowComplete = status;
  },
  setIsHealFlowComplete(state, status) {
    state.isHealFlowComplete = status;
  },
  setIsInSelfCrafting(state, status) {
    state.isInSelfCrafting = status;
  },
  setAfterStartingGuide(state, status) {
    state.afterStartingGuide = status;
  },
  setLetsGoToBattle(state, status) {
    state.letsGoToBattle = status;
  },
  setSearchPlains(state, status) {
    state.searchPlains = status;
  },
  setLootStash(state, status) {
    state.lootStash = status;
  },
  setIsWarriorStats(state, status) {
    state.isWarriorStats = status;
  },
  setHasSeenUpdateList(state, status) {
    state.hasSeenUpdateList = status;
  },
};

export default {
  namespaced: true,
  state,
  //   getters,
  actions,
  mutations,
};
