<template>
  <BasicPopup v-if="guildOpenState" :header="getTitle()" class="guild-popup">
    <div class="close-button button-small" @click="hide">
      <span>x</span>
    </div>
    <div class="player-meta">
      <DialogButton
        buttonSize="med"
        @click="reRollQuest"
        :buttonText="reRollingPrice + ' - Refresh'"
        :isLoading="reRollingQuests"
        :disabled="reRollingQuests || copperBalance < reRollingPrice"
        id="re-roll-quests"
      />
      <div class="tabs">
        <button
          :class="['toggle-accepted', 'tab-button', viewAccepted ? 'back' : '']"
          id="your-accepted-quests"
          @click="toggleAcceptedView"
        >
          {{ viewAccepted ? "Go Back" : "Accepted" }}
        </button>
      </div>
      <Popper
        arrow
        :show="showCopperPopup"
        content="This is your copper balance. You can use copper to refresh your quests and other various in-game activities. You earn copper by staking DCAR at Grom's Bank."
      >
        <span
          v-if="guildContracts"
          id="your-copper-points"
          class="copper-points points"
          @mouseenter="showCopperPopup = true"
          @mouseleave="showCopperPopup = false"
        >
          {{ copperBalance }}
        </span>
      </Popper>
      <Popper
        arrow
        :show="showQPPopup"
        content="These are your quest points (QP). Please note that QP is the cost for starting your quests, and they will naturally regenerate over time."
      >
        <span
          v-if="guildContracts"
          id="your-quest-points"
          class="quest-points points"
          @mouseenter="showQPPopup = true"
          @mouseleave="showQPPopup = false"
        >
          {{ guildContracts.questPoints }}/{{ guildContracts.maxQuestPoints }}
        </span>
      </Popper>
      <Popper
        arrow
        :show="showIPPopup"
        :content="`These are your ${guildType} guild influencer points (IP). You gain ${guildType} influencer points by completing ${guildType} quests and lose them if you complete ${
          guildType === 'defenders' ? 'crafters' : 'defenders'
        } guild quests.`"
      >
        <span
          v-if="guildContracts"
          id="your-influence-points"
          :class="['influence-points points', guildType]"
          @mouseenter="showIPPopup = true"
          @mouseleave="showIPPopup = false"
        >
          {{ guildContracts.influencePoints }}
        </span>
      </Popper>
    </div>
    <div class="inner-wrapper">
      <div class="inner-grid">
        <GenericLoading v-if="isLoading" />
        <template v-else-if="!viewAccepted">
          <template v-if="guildContracts?.quests.length > 0">
            <div
              :class="[
                'guild-contract',
                selected === quest.id ? 'selected' : '',
              ]"
              :key="quest.id"
              v-for="(quest, index) in guildContracts.quests"
              @click="
                selected === quest.id ? (selected = '') : (selected = quest.id)
              "
              :id="`guild-quest-list-${index}`"
            >
              <div>
                <div class="has-image">
                  <img
                    :src="getQuestImage(quest)"
                    :alt="quest.name"
                    class="questImage"
                  />
                  <h3>{{ quest.name }}</h3>
                </div>
                <div class="grid">
                  <p :class="['grid-item']">
                    <span class="desc">Rarity</span>
                    <span
                      :class="[
                        'value',
                        'quest-type',
                        quest.rarity.toLowerCase(),
                      ]"
                      >{{ quest.rarity }}</span
                    >
                  </p>
                  <p class="cost grid-item">
                    <span class="desc">Cost</span>
                    <span class="value points">{{ quest.cost }} </span>
                  </p>
                  <p :class="['influence-points', 'grid-item', guildType]">
                    <span class="desc">Required</span>
                    <span class="value points">{{
                      quest.requiredInfluencePoints
                    }}</span>
                  </p>
                  <p class="balances grid-item">
                    <span class="desc">Reward</span
                    ><span class="value dcar">{{
                      quest.dcarReward.toFixed(2)
                    }}</span>
                  </p>
                  <p
                    v-if="quest.lootItem && quest.lootItem.type == 'resource'"
                    class="additional grid-item"
                  >
                    <span class="desc">Additional</span>
                    <span class="value">
                      <span>
                        <img
                          :src="`https://ik.imagekit.io/dcg/equip/${quest.lootItem.imageName}?tr=w-25`"
                        />
                        {{ quest.lootItem.quantity }}
                      </span>
                    </span>
                  </p>
                  <p
                    v-else-if="
                      quest.lootItem && quest.lootItem.type == 'consumable'
                    "
                    class="additional grid-item"
                  >
                    <span class="desc">Additional</span>
                    <span class="value">
                      <span>
                        <img
                          :src="`https://ik.imagekit.io/dcg/equip/${quest.lootItem.imageName}?tr=w-25`"
                        />
                        {{ quest.lootItem.quantity }}
                      </span>
                    </span>
                  </p>
                  <p
                    v-else-if="
                      quest.lootItem && quest.lootItem.type == 'blueprint'
                    "
                    class="additional grid-item"
                  >
                    <span class="desc">Additional</span>
                    <span class="value">
                      <span>
                        <img
                          :src="`https://ik.imagekit.io/dcg/blueprints/${quest.lootItem.imageName}?tr=w-25`"
                        />
                        BP
                      </span>
                    </span>
                  </p>
                  <p class="time grid-item">
                    <span class="desc">Expires In</span
                    ><span class="value">{{
                      getFinishDate(quest.endDate)
                    }}</span>
                  </p>
                </div>
                <div class="buttons">
                  <DialogButton
                    buttonSize="med"
                    @click.stop="acceptQuest(quest)"
                    :buttonText="'Accept'"
                    :loading="acceptingQuest"
                    :disabled="
                      guildContracts.questPoints < quest.cost || acceptingQuest
                    "
                  />
                </div>
              </div>
            </div>
          </template>
          <h3 v-else>No Quests</h3>
        </template>
        <template v-else-if="viewAccepted">
          <template v-if="guildContracts.acceptedQuests.length > 0">
            <div
              :class="[
                'guild-contract',
                selected === quest.id ? 'selected' : '',
                quest.image !== undefined ? 'has-image' : '',
              ]"
              :key="quest.id"
              v-for="quest in guildContracts.acceptedQuests"
              @click="
                selected === quest.id ? (selected = '') : (selected = quest.id)
              "
            >
              <div>
                <div class="has-image">
                  <img :src="getQuestImage(quest)" :alt="quest.name" />
                  <h3>{{ quest.name }}</h3>
                </div>
                <div class="grid">
                  <p :class="['grid-item']">
                    <span class="desc">Rarity</span>
                    <span
                      :class="[
                        'value',
                        'quest-type',
                        quest.rarity.toLowerCase(),
                      ]"
                      >{{ quest.rarity }}</span
                    >
                  </p>
                  <p class="cost grid-item">
                    <span class="desc">Costed</span>
                    <span class="value points">{{ quest.cost }} </span>
                  </p>
                  <p :class="['influence-points', 'grid-item', guildType]">
                    <span class="desc">Required</span>
                    <span class="value points">{{
                      quest.requiredInfluencePoints
                    }}</span>
                  </p>
                  <p class="balances grid-item">
                    <span class="desc">Reward</span
                    ><span class="value dcar">{{
                      quest.dcarReward.toFixed(2)
                    }}</span>
                  </p>
                  <p
                    v-if="quest.lootItem && quest.lootItem.type == 'resource'"
                    class="additional grid-item"
                  >
                    <span class="desc">Additional</span>
                    <span class="value">
                      <span>
                        <img
                          :src="`https://ik.imagekit.io/dcg/equip/${quest.lootItem.imageName}?tr=w-25`"
                        />
                        {{ quest.lootItem.quantity }}
                      </span>
                    </span>
                  </p>
                  <p
                    v-else-if="
                      quest.lootItem && quest.lootItem.type == 'consumable'
                    "
                    class="additional grid-item"
                  >
                    <span class="desc">Additional</span>
                    <span class="value">
                      <span>
                        <img
                          :src="`https://ik.imagekit.io/dcg/equip/${quest.lootItem.imageName}?tr=w-25`"
                        />
                        {{ quest.lootItem.quantity }}
                      </span>
                    </span>
                  </p>
                  <p
                    v-else-if="
                      quest.lootItem && quest.lootItem.type == 'blueprint'
                    "
                    class="additional grid-item"
                  >
                    <span class="desc">Additional</span>
                    <span class="value">
                      <span>
                        <img
                          :src="`https://ik.imagekit.io/dcg/blueprints/${quest.lootItem.imageName}?tr=w-25`"
                        />
                        BP
                      </span>
                    </span>
                  </p>
                  <p class="time grid-item">
                    <span class="desc">Expires In</span
                    ><span class="value">{{
                      getFinishDate(quest.endDate)
                    }}</span>
                  </p>
                </div>
                <div class="progress" v-if="isMonsterKillQuest(quest)">
                  <span
                    >{{ quest.kills === undefined ? 0 : quest.kills }}/{{
                      quest.quantity
                    }}</span
                  >
                  <div
                    class="bar"
                    :style="`width: ${
                      (quest.kills === undefined
                        ? 0
                        : quest.kills / quest.quantity) * 100
                    }%`"
                  ></div>
                </div>
                <div class="buttons">
                  <DialogButton
                    v-if="isClaimableQuest(quest)"
                    buttonSize="med"
                    @click.stop="finishKillQuest(quest)"
                    :buttonText="'Complete Quest'"
                    :disabled="!isKillQuestComplete(quest) || acceptingQuest"
                    :isLoading="acceptingQuest"
                  />
                  <DialogButton
                    v-else
                    buttonSize="med"
                    @click.stop="submitItems(quest)"
                    :buttonText="'Submit Items'"
                    :isLoading="acceptingQuest"
                  />
                </div>
              </div>
            </div>
            <!-- <div
              :class="[
                'guild-contract',
                selected === quest.id ? 'selected' : '',
              ]"
              :key="quest.id"
              v-for="quest in guildContracts.acceptedQuests"
              @click="
                selected === quest.id ? (selected = '') : (selected = quest.id)
              "
            >
              <h3>{{ quest.name }}</h3>
              <span :class="['quest-type', quest.rarity.toLowerCase()]">{{
                quest.rarity
              }}</span>
              <div class="grid">
                <p class="cost grid-item">
                  <span>Cost</span>
                  {{ quest.cost }} <strong>Quest Points</strong>
                </p>
                <p :class="['influence-points', 'grid-item', guildType]">
                  <span>Required IP</span>
                  {{ quest.requiredInfluencePoints }}
                </p>
                <p class="time grid-item">
                  <span>Ends In</span>{{ getFinishDate(quest.endDate) }}
                </p>
              </div>
              <div class="progress" v-if="isMonsterKillQuest(quest)">
                <span
                  >{{ quest.kills === undefined ? 0 : quest.kills }}/{{
                    quest.quantity
                  }}</span
                >
                <div
                  class="bar"
                  :style="`width: ${
                    (quest.kills === undefined
                      ? 0
                      : quest.kills / quest.quantity) * 100
                  }%`"
                ></div>
              </div>
              <div class="buttons">
                <DialogButton
                  v-if="isMonsterKillQuest(quest)"
                  buttonSize="med"
                  @click.stop="finishKillQuest(quest)"
                  :buttonText="'Complete Quest'"
                  :disabled="!isKillQuestComplete || acceptingQuest"
                  :isLoading="acceptingQuest"
                />
                <DialogButton
                  v-else
                  buttonSize="med"
                  @click.stop="submitItems(quest)"
                  :buttonText="'Submit Items'"
                  :isLoading="acceptingQuest"
                />
              </div>
            </div> -->
          </template>
          <h3 v-else>No Accepted Quests</h3>
        </template>
      </div>
    </div>
  </BasicPopup>
  <GuildItemInventory
    @refreshContracts="getGuildContracts"
    :guildType="guildType"
    v-if="isGuildInventoryOpen"
  />
  <GuildRewards
    @refreshContracts="getGuildContracts"
    v-if="isGuildRewardsOpen"
  />
  <QuestAccepted
    v-if="questAcceptedToggle && acceptedQuest"
    :quest="acceptedQuest"
    @toggleAccepted="resetAccepted"
  />
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import DialogButton from "../../components/DialogButton.vue";
import Constants from "../../consts/constants";
import apiConnector from "../../game/apiConnector";
import GuildItemInventory from "./GuildItemInventory.vue";
import GenericLoading from "../LoadingComponents/GenericLoading.vue";
import BasicPopup from "../Popup/BasicPopup.vue";
import GuildRewards from "./GuildRewards.vue";
import QuestAccepted from "./QuestAccepted.vue";
import { useSound } from "../../composables/sound";
import startQuest from "../../assets/sound/start-quest.wav";
import Popper from "vue3-popper";
dayjs.extend(relativeTime);

import { ethers } from "ethers";
import { parseError } from "../../utils/helpers";
import { useUser } from "../../composables/user";
import { getDCGTripleVault } from "../../utils/getContract";
import { usePrice } from "../../composables/price";

export default {
  name: "Guild",
  components: {
    BasicPopup,
    GenericLoading,
    DialogButton,
    GuildItemInventory,
    GuildRewards,
    QuestAccepted,
    Popper,
  },
  props: {
    guildType: {
      type: String,
      default: "defenders",
    },
  },
  setup(props) {
    const store = useStore();
    const isLoading = ref(false);
    const guildContracts = ref(null);
    const selectedType = ref("Warrior");
    const selected = ref("");
    const acceptingQuest = ref(false);
    const viewAccepted = ref(false);
    const showQPPopup = ref(false);
    const showCopperPopup = ref(false);
    const showIPPopup = ref(false);
    const questAcceptedToggle = ref(false);
    const acceptedQuest = ref(null);
    const reRollingQuests = ref(false);
    const reRollingPricePerLevel = ref(0.5);
    const reRollingPrice = ref(0);

    const { signer } = useUser();
    const DCGTripleVaultContract = getDCGTripleVault(signer.value);

    const { getCopperBalance, copperBalance } = usePrice();

    const playOnQuestStart = useSound(startQuest, {
      volume: store.state.soundVolume,
      interrupt: true,
    });

    const unWatchCharacterId = store.watch(
      (state) => state.currentCharacterId,
      async () => {
        console.log("changed character", character.value.number);
        await getGuildContracts();
      }
    );

    onMounted(async () => {
      store.commit("setGameState", Constants.gamemodes.defenderguild);
      reRollingPrice.value =
        reRollingPricePerLevel.value * character.value.stats.level;
      await getGuildContracts();
      await getCopperBalance();
    });

    onUnmounted(() => {
      unWatchCharacterId();
    });

    /**
     * Methods
     */
    const getTitle = () => {
      console.log(`guildType: ${props.guildType}`);
      if (props.guildType === "defenders") {
        return "Defender's Guild";
      } else if (props.guildType === "crafters") {
        return "Crafter's Guild";
      }
    };
    const getQuestImage = (quest) => {
      if (quest.type === Constants.contractQuestTypes.defendersGuild) {
        if (quest.goalType === Constants.contractQuestsGoalTypes.monsterKill) {
          return "https://ik.imagekit.io/dcg/equip/Warriorskill_28_nobg.png?tr=w-150";
        } else if (
          quest.goalType ===
          Constants.contractQuestsGoalTypes.equipmentCollection
        ) {
          return "https://ik.imagekit.io/dcg/equip/Tailoring_44_little_bag.png?tr=w-150";
        } else if (
          quest.goalType === Constants.contractQuestsGoalTypes.raid ||
          quest.goalType === Constants.contractQuestsGoalTypes.bounty
        ) {
          return `https://cdn.dragoncrypto.io/encounters/${quest.image}?tr=w-150`;
        } else if (
          quest.goalType === Constants.contractQuestsGoalTypes.artefactRecovery
        ) {
          return `https://cdn.dragoncrypto.io/equip/${quest.image}?tr=w-150`;
        }
      } else if (quest.type === Constants.contractQuestTypes.craftersGuild) {
        return quest.image !== undefined
          ? `https://ik.imagekit.io/dcg/equip/${quest.image}?tr=w-150`
          : "https://ik.imagekit.io/dcg/equip/Tailoring_44_little_bag.png?tr=w-150";
      }
    };
    const finishKillQuest = async (quest) => {
      console.log("Finishing Kill Quest");
      store.commit("setTurnHappening", true);
      acceptingQuest.value = true;
      try {
        if (
          quest.goalType === Constants.contractQuestsGoalTypes.monsterKill ||
          quest.goalType === Constants.contractQuestsGoalTypes.bounty ||
          quest.goalType === Constants.contractQuestsGoalTypes.raid ||
          quest.goalType === Constants.contractQuestsGoalTypes.artefactRecovery
        ) {
          const finishQuest = await apiConnector.callFinishContractKillQuest(
            store.state.account,
            localStorage.getItem("sessionId"),
            character.value.number,
            quest.id
          );
          console.log(finishQuest);
          if (finishQuest.success) {
            store.commit("guild/setGuildQuestRewards", finishQuest.reward);
            store.commit("guild/setIsGuildRewardsOpen", true);
            store.commit("setHeroStats", finishQuest.heroStats);
          } else {
            store.commit("setNotification", finishQuest.message);
          }
        }
      } catch (error) {
        store.commit("setNotification", error);
        console.log(error);
      }
      store.commit("setTurnHappening", false);
      acceptingQuest.value = false;
    };

    const toggleAcceptedView = async () => {
      viewAccepted.value = !viewAccepted.value;
      if (viewAccepted.value) {
        console.log("Switching to accepted quests");
      } else {
        console.log("Switching to all quests");
      }
    };

    const filterContracts = (type) => {
      selectedType.value = type;
      if (type === "crafters") {
        guildContracts.value = guildContractCraftersQuests.value;
      } else if (type === "warriors") {
        guildContracts.value = guildContractWarriorsQuests.value;
      }
    };

    /**
     * Reroll Quests
     */
    const reRollQuest = async () => {
      try {
        reRollingQuests.value = true;
        // const { reason, amount, questType } = await signCopperSpend();
        const signedData = await signCopperSpend();
        const { reason, amount, questType } = signedData;
        const txReRoll = await DCGTripleVaultContract.spendCopper(
          amount,
          ethers.utils.formatBytes32String(reason)
        );

        if (txReRoll?.hash) {
          await apiConnector.handleTransactionHash(
            store.state.account,
            localStorage.getItem("sessionId"),
            character.value.number,
            "copper",
            txReRoll.hash
          );
        }

        const tx = await txReRoll.wait();
        if (tx.status === 1) {
          console.log("Rerolling Quests");
          const response = await fetch(
            Constants.apiUrl + "finish-copper-spend",
            {
              method: "POST",
              body: JSON.stringify({
                sessionId: localStorage.getItem("sessionId"),
                heroId: character.value.number,
                account: store.state.account,
                questType,
                txHash: txReRoll.hash,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();

          if (data.success) {
            await getGuildContracts();
            await getCopperBalance();
          } else {
            store.commit("setNotification", parseError(data.message));
          }
        } else {
          store.commit("setNotification", parseError(tx));
        }
      } catch (error) {
        console.log(error);
        store.commit("setNotification", parseError(error));
      }
      reRollingQuests.value = false;
    };

    const signCopperSpend = async () => {
      try {
        const sessionId = localStorage.getItem("sessionId");
        const heroId = character.value.number;
        console.log("heroId", heroId);
        console.log("sessionId", sessionId);

        let reason = "";
        let type = "";
        if (props.guildType === "defenders") {
          reason = "defendersroll";
          type = "defendersGuild";
        } else if (props.guildType === "crafters") {
          reason = "craftersroll";
          type = "craftersGuild";
        }
        reason = reason + "-" + character.value.number;

        console.log("reason", reason);
        console.log("type", type);

        const response = await fetch(
          Constants.apiUrl + "contract/sign-spend-copper",
          {
            method: "POST",
            body: JSON.stringify({
              sessionId: localStorage.getItem("sessionId"),
              heroId: character.value.number,
              account: store.state.account,
              reason,
              amount: ethers.utils.parseEther(reRollingPrice.value.toString()),
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log("response", response);
        const data = await response.json();
        console.log("'signed spend'", data);

        if (data.success) {
          return {
            reason: data.reason,
            amount: data.amount,
            questType: type,
          };
        } else {
          store.commit("setNotification", parseError(data.message));
        }
      } catch (error) {
        store.commit("setNotification", parseError(error));
      }
    };

    const getGuildContracts = async () => {
      if (props.guildType === "defenders") {
        await getGuildContractDefenders();
      } else if (props.guildType === "crafters") {
        await getGuildContractCrafters();
      }
    };

    const getGuildContractDefenders = async () => {
      isLoading.value = true;
      try {
        const contractQuestsWarriors = await apiConnector.callGetContractQuests(
          store.state.account,
          localStorage.getItem("sessionId"),
          character.value.number,
          Constants.contractQuestTypes.defendersGuild
        );

        if (contractQuestsWarriors.success) {
          // store.commit("guild/setContractCraftersQuests", contractQuestsCraft);
          store.commit(
            "guild/setContractWarriorsQuests",
            contractQuestsWarriors
          );
          guildContracts.value = guildContractWarriorsQuests.value; // Assigning warriors as default filter
          console.log("Assigning defenders quests:", contractQuestsWarriors);
        }
      } catch (error) {
        console.log(error);
      }
      isLoading.value = false;
    };

    const getGuildContractCrafters = async () => {
      isLoading.value = true;
      try {
        const contractQuestsCrafters = await apiConnector.callGetContractQuests(
          store.state.account,
          localStorage.getItem("sessionId"),
          character.value.number,
          Constants.contractQuestTypes.craftersGuild
        );
        if (contractQuestsCrafters.success) {
          // store.commit("guild/setContractCraftersQuests", contractQuestsCraft);
          store.commit(
            "guild/setContractCraftersQuests",
            contractQuestsCrafters
          );
          guildContracts.value = guildContractCraftersQuests.value; // Assigning warriors as default filter
          console.log("crafters quests:", contractQuestsCrafters);
        }
      } catch (error) {
        console.log(error);
      }
      isLoading.value = false;
    };

    const resetAccepted = () => {
      acceptedQuest.value = null;
      questAcceptedToggle.value = false;
    };

    const acceptQuest = async (contractQuest) => {
      try {
        acceptingQuest.value = true;
        const acceptQuest = await apiConnector.callStartContractQuest(
          store.state.account,
          localStorage.getItem("sessionId"),
          character.value.number,
          contractQuest.id
        );
        playOnQuestStart.play();
        console.log("Quest Accepted", acceptQuest);

        if (acceptQuest.success) {
          // store.commit(
          //   "setNotification",
          //   'You accepted the quest! You can view it in your "Accepted" quests'
          // );
          acceptedQuest.value = contractQuest;
          questAcceptedToggle.value = true;
          await getGuildContracts();
        } else {
          store.commit("setNotification", acceptQuest.message);
        }
      } catch (error) {
        console.log(error);
      }
      acceptingQuest.value = false;
    };

    const completeQuest = () => {
      console.log(completeQuest);
    };

    /**
     *
     * @param {*} timestamp
     * Returns human readable version of the timestamp sent in finishBy
     */
    const getFinishDate = (timestamp) => {
      const d = new Date(timestamp * 1000);
      return dayjs(d).fromNow();
    };
    const hide = () => {
      store.commit("guild/setIsGuildOpen", false); // Hiding in mobile view as well
      store.commit("setGameState", Constants.gamemodes.wandering);
    };

    const isMonsterKillQuest = (quest) => {
      return quest.goalType === Constants.contractQuestsGoalTypes.monsterKill;
    };

    const isClaimableQuest = (quest) => {
      return (
        quest.goalType === Constants.contractQuestsGoalTypes.monsterKill ||
        quest.goalType === Constants.contractQuestsGoalTypes.raid ||
        quest.goalType === Constants.contractQuestsGoalTypes.artefactRecovery ||
        quest.goalType === Constants.contractQuestsGoalTypes.bounty
      );
    };

    const submitItems = (quest) => {
      store.commit("guild/setSelectedQuestForSubmit", quest);
      store.commit("guild/setIsGuildInventoryOpen", true);
      console.log(quest);
    };
    const isKillQuestComplete = (quest) => {
      if (quest.goalType === Constants.contractQuestsGoalTypes.monsterKill) {
        return (quest.kills === undefined ? 0 : quest.kills) === quest.quantity;
      } else if (
        quest.goalType == Constants.contractQuestsGoalTypes.raid ||
        quest.goalType == Constants.contractQuestsGoalTypes.bounty ||
        quest.goalType == Constants.contractQuestsGoalTypes.artefactRecovery
      ) {
        return quest.isCompleted;
      } else {
        return false;
      }
    };

    /**
     * Computed Methods
     */
    const guildOpenState = computed(() => {
      return store.state.guild.isGuildOpen;
    });

    const guildContractCraftersQuests = computed(() => {
      return store.state.guild.contractCraftersQuests;
    });

    const guildContractWarriorsQuests = computed(() => {
      return store.state.guild.contractWarriorsQuests;
    });

    const character = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });

    const isGuildInventoryOpen = computed(() => {
      return store.state.guild.isGuildInventoryOpen;
    });
    const isGuildRewardsOpen = computed(() => {
      return store.state.guild.isGuildRewardsOpen;
    });

    return {
      /* All the computed values */
      guildOpenState,
      guildContractCraftersQuests,
      guildContractWarriorsQuests,
      isGuildInventoryOpen,
      isGuildRewardsOpen,
      questAcceptedToggle,
      acceptedQuest,
      /* All the methods */
      hide,
      getFinishDate,
      filterContracts,
      acceptQuest,
      toggleAcceptedView,
      isMonsterKillQuest,
      isClaimableQuest,
      completeQuest,
      submitItems,
      finishKillQuest,
      isKillQuestComplete,
      getGuildContracts,
      getTitle,
      getQuestImage,
      resetAccepted,
      reRollQuest,
      /* All the data values */
      isLoading,
      guildContracts,
      selectedType,
      selected,
      acceptingQuest,
      viewAccepted,
      showCopperPopup,
      showQPPopup,
      showIPPopup,
      reRollingQuests,
      reRollingPrice,
      copperBalance,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

$mobileWidth: 275px;
$extraSmall: 150px;
$small: 200px;

.guild-popup :deep(.popper) {
  background: #3b3b3b;
  padding: 15px;
  border-radius: 20px;
  color: #fff;
  font-weight: normal;
  font-family: Lato, sans-serif;
  font-size: 0.9rem;
  max-width: 250px;
  line-height: 1.2rem;
}

.guild-popup :deep(.popper #arrow::before) {
  background: #3b3b3b;
}

.guild-popup :deep(.popper:hover),
.guild-popup :deep(.popper:hover > #arrow::before) {
  background: #3b3b3b;
}
.guild-button {
  position: absolute;
  left: 2681px;
  z-index: 10;
  background: red;
  width: 100px;
  height: 30px;
  color: #fff;
}
button#re-roll-quests {
  position: absolute;
  top: -50px;
  right: 0;
  @media only screen and (max-width: 576px) {
    position: absolute;
    top: -122px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
  &:before {
    background: url("../../assets/ui/copper.png") no-repeat left center;
    width: 15px;
    height: 15px;
    background-size: contain;
    content: "";
    display: inline-block;
    line-height: 0;
    margin-top: -3px;
    padding: 0;
    top: 4px;
    position: relative;
    margin-right: 6px;
  }
}
.guild-popup {
  width: calc(70% - $popup-border-size - $popup-border-size);
  height: calc(650px - $popup-border-size - $popup-border-size);
  max-width: 600px;
  min-width: 600px;
  @media only screen and (max-width: 576px) {
    min-width: calc(100% - $popup-border-size - $popup-border-size);
  }
  .close-button {
    position: absolute;
    top: -$popup-border-size;
    right: -$popup-border-size;
    &.loading {
      filter: grayscale(100);
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .filters-content {
    display: grid;
    width: 56px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -121px;
    grid-gap: 0;
    img {
      &:hover {
        cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
          auto;
      }
    }
  }
  .inner-wrapper {
    width: calc(100% + $popup-border-offset + $popup-border-offset);
    height: calc(100% + $popup-border-offset) !important;
    /* display: grid;
    align-items: center; */
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 15px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-button {
      background-color: transparent;
      width: 15px;
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      background: url("https://cdn.dragoncrypto.io/uiassets/scrollbar-slider.png")
        repeat-y;
    }

    @media only screen and (max-width: 576px) {
      height: calc(100% + $popup-border-offset + $popup-border-offset);
      overflow-y: scroll;
    }
    left: -$popup-border-offset;
    position: relative;
    top: 0;
    p {
      width: 70%;
      margin: 0 auto 1.2rem;
    }
  }
  .inner-grid {
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0; */
    position: relative;
    @media only screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }
}
.player-meta {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 120px 120px 120px;
  grid-gap: 5px;
  width: calc(100% + $popup-border-offset + $popup-border-offset - 1rem);
  margin-top: -52px;
  left: calc(-1 * $popup-border-offset);
  z-index: 1000;
  padding: 0 0 0 1rem;
  height: 44px;
  align-items: center;
  justify-content: center;
  justify-items: left;
  background: rgba(0, 0, 0, 0.5);
  font-size: 0.9rem;
  font-family: "IM Fell English", sans-serif;
  .points {
    justify-items: center;
    width: calc(100% - 20px);
    height: 100%;
    padding: 0 10px;
    margin: -18px auto 0;
    font-weight: bold;
    font-size: 0.9rem;
    font-family: Lato, sans-serif;
    color: #fff;
    min-height: 30px;
    display: block;
    &:before {
      top: 10px;
      position: relative;
    }

    &.influence-points {
      &.defenders {
        &:before {
          background: url("../../assets/ui/defenders-influence-points.png")
            no-repeat left center;
          background-size: contain;
        }
      }
      &.crafters {
        &:before {
          background: url("../../assets/ui/crafters-guild-influence-points.png")
            no-repeat left center;
          background-size: contain;
        }
      }
      &:before {
        margin-right: 4px;
        content: "";
        height: 30px;
        width: 30px;
        display: block;
        position: relative;
        display: inline-block;
      }
    }
    &.copper-points {
      &:before {
        background: url("../../assets/ui/copper.png") no-repeat left center;
        background-size: 92%;
        margin-right: 4px;
        content: "";
        height: 30px;
        width: 30px;
        display: block;
        position: relative;
        display: inline-block;
      }
    }
    &.quest-points {
      &:before {
        background: url("../../assets/ui/quest-points.png") no-repeat left
          center;
        background-size: contain;
        margin-right: 4px;
        content: "";
        height: 30px;
        width: 30px;
        display: block;
        position: relative;
        display: inline-block;
      }
    }
  }
  button.tab-button {
    border: none;
    background-color: rgb(45 13 9);
    color: #d89967;
    padding: 7px;
    box-shadow: 0 0 1px 2px #000000a6;
    font-family: "IM Fell English", serif;
    font-size: 0.9rem;
    z-index: 6;
    width: auto;
    font-weight: bold;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;

    &.back {
      background-color: #d89967;
      color: #000;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: rgb(82, 82, 82);
      color: #fff;
    }
    @media only screen and (max-width: 576px) {
      display: none;
    }
  }
}
.guild-contract {
  display: block;
  margin: 1rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.0196078431);
  border-radius: 10px;
  position: relative;
  transition: height 0.25s ease-in-out;
  overflow: hidden;
  .has-image {
    display: grid;
    grid-template-columns: 96px 1fr;
    align-items: center;
    margin-bottom: 1rem;
    grid-gap: 1rem;
    img {
      border: 3px solid #e5c35614;
      border-radius: 3px;
      background-color: #1b1b1b;
      width: 90px;
      height: 90px;
    }
  }
  &:hover {
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
  }
  h3 {
    font-size: 1.3rem;
    text-align: left;
    margin: 0;
    padding: 0;
  }
  .quest-type {
    text-align: center;
    width: 100%;
    display: block;
    padding: 7px 0;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    /* position: absolute;
    top: 5px;
    left: -8px; */
    min-width: 60px;
    border-radius: 3px;
    margin: 0 !important;
    &.shoddy,
    &.common,
    &.normal {
      /* border: 1px solid $shoddy; */
      background: #7d7d7d;
    }

    &.epic {
      background-color: $epic;
      color: #000;
    }

    &.legendary {
      background-color: $legendary;
      color: #000;
    }

    &.mythical {
      background-color: $mythical;
    }

    &.fabled {
      background-color: $fabled;
      color: #000;
    }

    &.unique {
      background-color: $unique;
      color: #000;
    }

    &.rare {
      background-color: $rare;
    }

    &.artefact {
      background-color: $artefact;
      color: #000;
    }

    &.junk {
      background: #000000a7;
    }
    &.uncommon {
      background: $uncommon;
      color: #000;
    }
  }

  &.defender {
    // TODO: ADD STYLING FOR DEFENDER LOCATION
    position: relative;
  }
  &.crafters {
    //TODO: ADD STYLING FOR CRAFTER LOCATION
    position: relative;
  }
  .time {
    text-align: center;
    display: grid;
    align-items: center;
    &:before {
      background: url("https://cdn.dragoncrypto.io/uiassets/clock.svg")
        no-repeat left center;
      background-size: contain;
      margin-right: 4px;
      content: "";
      opacity: 0.4;
      height: 14px;
      width: 14px;
      display: block;
      margin-top: -2px;
      top: 3px;
      position: relative;
      display: inline-block;
    }
  }

  .additional {
    position: relative;
    /* top: -4px; */
    text-align: center;
    display: grid;
    align-items: center;
    span.value {
      span {
        display: block;
        padding: 0;
        margin: 0;
        top: -5px;
        position: relative;
      }
    }
  }

  .additional img {
    position: relative;
    top: 6px;
  }
  .cost {
    strong {
      display: none;
    }
    &:before {
      background: url("../../assets/ui/quest-points.png") no-repeat left center;
      background-size: contain;
      margin-right: 4px;
      content: "";
      height: 25px;
      width: 25px;
      display: block;
      margin-top: -2px;
      top: 3px;
      position: relative;
      display: inline-block;
    }
  }
  .influence-points {
    &.defenders {
      &:before {
        background: url("../../assets/ui/defenders-influence-points.png")
          no-repeat left center;
        background-size: contain;
      }
    }
    &.crafters {
      &:before {
        background: url("../../assets/ui/crafters-guild-influence-points.png")
          no-repeat left center;
        background-size: contain;
      }
    }
    &:before {
      margin-right: 4px;
      content: "";
      height: 25px;
      width: 25px;
      display: block;
      margin-top: -2px;
      top: 3px;
      position: relative;
      display: inline-block;
    }
  }
  .buttons {
    display: none;
  }
  &.selected {
    background: #0e0b0a;
    .quest-type {
      display: grid;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      min-height: 32px;
      padding: 2px 4px !important;
      font-size: 0.8rem;
      align-items: center;
      font-weight: bold;
      border-radius: 0;
    }
    .buttons {
      display: block;
      width: 100%;
      padding: 0.5rem 0 0;
      border-top: 1px solid rgba(255, 255, 255, 0.11);
      position: relative;
      margin: 1rem 0 0;
    }
    .time {
      &:before {
        display: none;
      }
      text-align: center;
      display: grid;
      align-items: center;
    }
    .additional {
      &:before {
        display: none;
      }
      strong {
        display: inline;
      }
      text-align: center;
      display: grid;
      align-items: center;
    }

    .additional img {
      position: relative;
      top: 6px;
    }
    .cost {
      &:before {
        display: none;
      }
      strong {
        display: inline;
      }
      span.value:before {
        background: url("../../assets/ui/quest-points.png") no-repeat left
          center;
        background-size: contain;
        margin-right: 4px;
        content: "";
        height: 25px;
        width: 25px;
        display: inline-block;
        top: 8px;
        position: relative;
        display: inline-block;
      }
    }
    .influence-points {
      &.defenders {
        span:before {
          background: url("../../assets/ui/defenders-influence-points.png")
            no-repeat left center;
          background-size: contain;
        }
      }
      &.crafters {
        span:before {
          background: url("../../assets/ui/crafters-guild-influence-points.png")
            no-repeat left center;
          background-size: contain;
        }
      }
      &:before {
        display: none;
      }
      span.value:before {
        margin-right: 4px;
        content: "";
        height: 25px;
        width: 25px;
        display: inline-block;
        top: 8px;
        position: relative;
        display: inline-block;
      }
    }
    .progress {
      width: 100%;
      position: relative;
      left: 0;
      height: 20px;
      bottom: 0;
      margin: 1rem 0 0;
      border: 1px solid rgb(188 179 60 / 31%);
      span {
        display: block;
      }
    }
    .grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
      align-items: center;
      grid-gap: 1px;
      overflow: hidden;
      border-radius: 5px;
      position: relative;
      width: 100%;
      margin-bottom: 5px;
      text-align: center;
      .grid-item {
        padding: 0;
        font-size: 0.9rem;
        margin-bottom: 0;
        background: #28221f;
        border: 1px #271e1e solid;
        min-height: 66px;
        &.balances {
          .value {
            display: block;
            top: 4px;
            position: relative;
          }
        }
        span.desc {
          padding: 5px 0;
          display: block;
          margin: 0;
          background-color: #0e0b0a8f;
          color: #a4a4a4;
          font-family: "IM Fell English", sans-serif;
          font-size: 1rem;
        }
        span.value {
          padding: 2px;
          display: grid;
          align-items: center;
          min-height: 32px;
          &.points {
            display: block;
          }
        }
      }
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    grid-gap: 1px;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    width: 100%;
    right: 0;
    justify-items: center;
    text-align: center;
    align-items: center;
    padding: 0;
    .grid-item {
      padding: 0 0 3px;
      font-size: 0.9rem;
      margin-bottom: 0;
      background: transparent;
      border-top: none;
      display: block;
      width: 100%;
      span.desc {
        display: none;
      }
      span.value {
        &.points {
          top: -4px;
          position: relative;
        }
      }
    }
    .balances {
      .value {
        grid-gap: 1px;
        align-items: center;
        justify-items: flex-start;
        font-size: 0.9rem;
        &:before {
          content: "";
          display: inline-block;
          width: 25px;
          height: 25px;
          line-height: 0;
          margin-top: -8px;
          padding: 0;
          top: 7px;
          position: relative;
          margin-right: 4px;
        }
        /* p {
          margin: 0;
          span {
            font-size: 0.7rem;
            display: block;
            margin: 2px 0 0;
            color: #ffffff85;
          }
        } */
      }
      .dcau {
        &:before {
          background: url("../../assets/ui/dcau.png") no-repeat left center;
          background-size: contain;
        }
      }
      .dcar {
        &:before {
          background: url("../../assets/ui/dcar.png") no-repeat left center;
          background-size: contain;
        }
      }
    }
  }
  .progress {
    width: calc(100% + 2rem);
    height: 10px;
    /* background: rgba(255, 255, 255, 0.1490196078); */
    background: rgb(53 53 53 / 80%);
    left: -1rem;
    position: relative;
    bottom: -1rem;
    border: 1px solid rgb(188 179 60 / 31%);
    border-left: none;
    border-right: none;
    span {
      display: none;
    }
    span {
      position: absolute;
      left: 50%;
      font-size: 0.8rem;
      text-shadow: 0 2px 2px #000000;
      text-transform: uppercase;
      color: #fff;
      border-radius: 4px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .bar {
      height: 100%;
      background: rgb(144, 52, 48);
      background: linear-gradient(
        0deg,
        rgba(144, 52, 48, 1) 0%,
        rgba(168, 48, 43, 1) 48%,
        rgba(99, 18, 15, 1) 100%
      );
    }
  }
}
.blue-button {
  display: block;
  background: url("https://cdn.dragoncrypto.io/assets/blue-button-medium.webp")
    no-repeat center top;
  background-size: 100%;
  width: 356px;
  height: 70px;
  &.small {
    width: $small;
    height: calc($small / 5.0857142857);
    font-size: 0.9rem;
    line-height: 1.4rem;

    &:hover {
      background-position: center calc(-1 * calc($small / 5.0857142857));
    }
    &:active {
      background-position: center calc(-2 * calc($small / 5.0857142857));
    }
  }
  &.extra-small {
    width: $extraSmall;
    height: calc($extraSmall / 5.0857142857);
    font-size: 0.8rem;
    &:hover {
      background-position: center calc(-1 * calc($extraSmall / 5.0857142857));
    }
    &:active {
      background-position: center calc(-2 * calc($extraSmall / 5.0857142857));
    }
  }

  @media only screen and (max-width: 576px) {
    width: $mobileWidth;
    height: calc($mobileWidth / 5.0857142857);
  }
  box-shadow: none;
  border: none;
  margin: 0 auto;
  position: relative;
  color: #e1f1ff;
  text-transform: uppercase;
  font-size: 1.1rem;
  text-shadow: 1px 1px 2px #000;
  transition: transform 0.1s ease-in-out;
  /* &:after {
    content: "";
    background: url("https://cdn.dragoncrypto.io/blue-button-medium.webp") no-repeat center top;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    background-size: 100%;
    left: 0;
    position: absolute;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    z-index: 0;
  } */
  &:hover {
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    background-position: center -70px;
    @media only screen and (max-width: 576px) {
      background-position: center calc(-1 * calc($mobileWidth / 5.0857142857));
    }
    &:after {
      opacity: 0;
    }
    &:disabled {
      cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
        auto;
      background-position: center bottom;
      cursor: not-allowed;
    }
  }
  &:active {
    background-position: center -140px;
    @media only screen and (max-width: 576px) {
      background-position: center calc(-2 * calc($mobileWidth / 5.0857142857));
    }
    transform: scale(0.99);
  }
  &:disabled {
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
    background-position: center bottom;
    cursor: not-allowed;
  }
  .loader {
    display: none;
  }
  &.loading {
    .loader {
      display: block;
    }
  }
}
.loader {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  right: 4rem;
  @media only screen and (max-width: 576px) {
    left: 46%;
    transform: translateX(-50%);
    top: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 20px;
    border: 5px solid rgba(255, 255, 255, 0.379);
    border-top-color: #e0c95e;
    animation: loading 2s linear infinite;
  }
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
