import { ethers } from "ethers";
import ContractQuestItemBurnAbi from "../abi/ContractQuestItemBurn.json";
import DCARAbi from "../abi/DCAR.json";
import DCARBurnBattleAbi from "../abi/DCARBurnBattle.json";
import DCGDragonFireLotteryAbi from "../abi/DCGDragonFireLottery.json";
import DCGEquipmentUpgradeAbi from "../abi/DCGEquipmentUpgrade.json";
import DCGHeroesRedeemAbi from "../abi/DCGHeroesFromDistantLandsMintToken.json";
import DCGTokenHeroExchangeAbi from "../abi/DCGTokenHeroExchange.json";
import DCGArgentiBond from "../abi/DragonCryptoArgentiBond.json";
import DCGConsumablesAbi from "../abi/DragonCryptoConsumables.json";
import DCGEquipmentAbi from "../abi/DragonCryptoEquipment.json";
import DCGLootChestsAbi from "../abi/DragonCryptoLootChests.json";
import DCGResourcesAbi from "../abi/DragonCryptoResources.json";
import DCGNestAbi from "../abi/DragonNest.json";
import ERC721Abi from "../abi/ERC721.json";
import EquipmentBurnAbi from "../abi/EquipmentBurn.json";
import LoadCraftingAbi from "../abi/LoadCrafting.json";
import LoadCraftingGuildAbi from "../abi/LoadCraftingGuild.json";
import LoadHealersHutAbi from "../abi/LoadHealersHut.json";
import LoadInnAbi from "../abi/LoadInn.json";
import LoadMarketplaceAbi from "../abi/LoadMarketplace.json";
import LoadPriceHandlerAbi from "../abi/LoadPriceHandler.json";
import LoadRepair from "../abi/LoadRepair.json";
import LoadRepairGuildAbi from "../abi/LoadRepairGuild.json";
import LoadRewardHandlerAbi from "../abi/LoadRewardHandler.json";
import NFTFinderAbi from "../abi/NFTFinder.json";
import DCGHeroesAbi from "../abi/TestDragonCryptoHero.json";
import DCGVaultAbi from "../abi/TripleRewardsDividendVault.json";
import DCAUAbi from "../abi/dcau.json";
import Constants from "../consts/constants";

export function getDCAUContract(signer) {
  let contractAddress = 0;
  if (Constants.dcauContract) {
    contractAddress = Constants.dcauContract;
  }
  return new ethers.Contract(contractAddress, DCAUAbi, signer);
}

export function getDCARContract(signer) {
  let contractAddress = 0;
  if (Constants.dcarContract) {
    contractAddress = Constants.dcarContract;
  }
  return new ethers.Contract(contractAddress, DCARAbi, signer);
}

export function getDCGHeroesContract(signer) {
  let contractAddress = 0;
  if (Constants.heroMintContract) {
    contractAddress = Constants.heroMintContract;
  }
  return new ethers.Contract(contractAddress, DCGHeroesAbi, signer);
}

export function getDCGEquipmentContract(signer) {
  let contractAddress = 0;
  if (Constants.dcgEquipmentContract) {
    contractAddress = Constants.dcgEquipmentContract;
  }
  return new ethers.Contract(contractAddress, DCGEquipmentAbi, signer);
}

export function getDCGResourcesContract(signer) {
  let contractAddress = 0;
  if (Constants.dcgResourceContract) {
    contractAddress = Constants.dcgResourceContract;
  }
  return new ethers.Contract(contractAddress, DCGResourcesAbi, signer);
}

export function getDCGConsumablesContract(signer) {
  let contractAddress = 0;
  if (Constants.dcgConsumablesContract) {
    contractAddress = Constants.dcgConsumablesContract;
  }
  return new ethers.Contract(contractAddress, DCGConsumablesAbi, signer);
}

export function getLoadPriceHandlerContract(signer) {
  let contractAddress = 0;
  if (Constants.loadPriceHandlerContract) {
    contractAddress = Constants.loadPriceHandlerContract;
  }
  return new ethers.Contract(contractAddress, LoadPriceHandlerAbi, signer);
}

export function getLoadRewardHandlerContract(signer) {
  let contractAddress = 0;
  if (Constants.loadRewardHandlerContract) {
    contractAddress = Constants.loadRewardHandlerContract;
  }
  return new ethers.Contract(contractAddress, LoadRewardHandlerAbi, signer);
}

export function getLoadInnContract(signer) {
  const contractAddress = Constants.loadInnContract
    ? Constants.loadInnContract
    : 0;
  return new ethers.Contract(contractAddress, LoadInnAbi, signer);
}

export function getLoadHealersHutContract(signer) {
  const contractAddress = Constants.loadHealersHutContract
    ? Constants.loadHealersHutContract
    : 0;
  return new ethers.Contract(contractAddress, LoadHealersHutAbi, signer);
}

export function getLoadMarketplaceContract(signer) {
  const contractAddress = Constants.loadMarketplaceContract
    ? Constants.loadMarketplaceContract
    : 0;
  return new ethers.Contract(contractAddress, LoadMarketplaceAbi, signer);
}

export function getDCGBondContract(signer) {
  const contractAddress = Constants.argentiBondContract
    ? Constants.argentiBondContract
    : 0;
  return new ethers.Contract(contractAddress, DCGArgentiBond, signer);
}

export function getLoadCraftingGuildContract(signer) {
  const contractAddress = Constants.loadCraftingGuildContract
    ? Constants.loadCraftingGuildContract
    : 0;
  return new ethers.Contract(contractAddress, LoadCraftingGuildAbi, signer);
}

export function getLoadCraftingContract(signer) {
  const contractAddress = Constants.craftingContract
    ? Constants.craftingContract
    : 0;
  return new ethers.Contract(contractAddress, LoadCraftingAbi, signer);
}

export function getEquipmentBurnContract(signer) {
  const contractAddress = Constants.equipmentBurnContract
    ? Constants.equipmentBurnContract
    : 0;
  return new ethers.Contract(contractAddress, EquipmentBurnAbi, signer);
}

export function getDCGNestContract(signer) {
  let contractAddress = 0;
  if (Constants.nestSupporterContract) {
    contractAddress = Constants.nestSupporterContract;
  }
  return new ethers.Contract(contractAddress, DCGNestAbi, signer);
}

export function getERC721Contract(signer, address) {
  return new ethers.Contract(address, ERC721Abi, signer);
}

export function getNFTFinderContract(signer) {
  const contractAddress = Constants.nftFinderContract
    ? Constants.nftFinderContract
    : 0;
  return new ethers.Contract(contractAddress, NFTFinderAbi, signer);
}

export function getRepairGuildContract(signer) {
  const contractAddress = Constants.loadRepairGuildContract
    ? Constants.loadRepairGuildContract
    : 0;
  return new ethers.Contract(contractAddress, LoadRepairGuildAbi, signer);
}
export function getDCGLootChestsContract(signer) {
  const contractAddress = Constants.lootChestsContract
    ? Constants.lootChestsContract
    : 0;
  return new ethers.Contract(contractAddress, DCGLootChestsAbi, signer);
}
export function getDCGEquipmentUpgradeContract(signer) {
  const contractAddress = Constants.equipmentUpgradeContract
    ? Constants.equipmentUpgradeContract
    : 0;
  return new ethers.Contract(contractAddress, DCGEquipmentUpgradeAbi, signer);
}
export function getQuestItemBurnContract(signer) {
  const contractAddress = Constants.contractQuestItemBurn
    ? Constants.contractQuestItemBurn
    : 0;
  return new ethers.Contract(contractAddress, ContractQuestItemBurnAbi, signer);
}
export function getRedeemTokenContract(signer) {
  const contractAddress = Constants.redeemTokenContract
    ? Constants.redeemTokenContract
    : 0;
  return new ethers.Contract(contractAddress, ERC721Abi, signer);
}
export function getDCGTokenHeroExchange(signer) {
  const contractAddress = Constants.tokenHeroExchangeContract
    ? Constants.tokenHeroExchangeContract
    : 0;
  return new ethers.Contract(contractAddress, DCGTokenHeroExchangeAbi, signer);
}
export function getLoadRepair(signer) {
  const contractAddress = Constants.loadRepair ? Constants.loadRepair : 0;
  return new ethers.Contract(contractAddress, LoadRepair, signer);
}
export function getDCARBurnBattleContract(signer) {
  const contractAddress = Constants.dcarBurnBattle
    ? Constants.dcarBurnBattle
    : 0;
  return new ethers.Contract(contractAddress, DCARBurnBattleAbi, signer);
}
export function getDCGDragonFireLotteryContract(signer) {
  const contractAddress = Constants.dragonFireLottery
    ? Constants.dragonFireLottery
    : 0;
  return new ethers.Contract(contractAddress, DCGDragonFireLotteryAbi, signer);
}
export function getDCGHeroesRedeemTokenContract(signer) {
  const contractAddress = Constants.heroesRedeemContract
    ? Constants.heroesRedeemContract
    : 0;
  return new ethers.Contract(contractAddress, DCGHeroesRedeemAbi, signer);
}
export function getDCGHeroesRedeemTokenPart2Contract(signer) {
  const contractAddress = Constants.heroesRedeemContractSecond
    ? Constants.heroesRedeemContractSecond
    : 0;
  return new ethers.Contract(contractAddress, ERC721Abi, signer);
}
export function getDCGDistantLandsHeroExchange(signer) {
  const contractAddress = Constants.distantLandsHeroesExchangeContract
    ? Constants.distantLandsHeroesExchangeContract
    : 0;
  return new ethers.Contract(contractAddress, DCGTokenHeroExchangeAbi, signer);
}
export function getDCGTripleVault(signer) {
  const contractAddress = Constants.dcgTripleVaultContract
    ? Constants.dcgTripleVaultContract
    : 0;
  return new ethers.Contract(contractAddress, DCGVaultAbi, signer);
}
export function getDCGDistantLandsHeroExchangePart2(signer) {
  const contractAddress = Constants.distantLandsHeroesExchangeSecondContract
    ? Constants.distantLandsHeroesExchangeSecondContract
    : 0;
  return new ethers.Contract(contractAddress, DCGTokenHeroExchangeAbi, signer);
}
