<template>
  <div :class="getClass">
    <div class="inner-dialog-wrapper">
      <GameMessage
        :heading="'The Healer\'s Hut'"
        :icon="'Assassinskill_29_nobg'"
      >
        <template v-if="isDead">
          <p>Ahh I see ye' there ghost, I can bring you back for a fee...</p>
        </template>
        <template v-else>
          <template v-if="needsHeal">
            <p>
              A battle hardened man appears behind the counter. "Ahh, I can heal
              ye...for a fee."
            </p>
          </template>
          <template v-else>
            <p>
              A battle hardened man appears behind the counter looking
              irritated. "What are ye doing here warrior?"
            </p>
          </template>
        </template>
      </GameMessage>
      <div class="buttons">
        <template v-if="isDead">
          <DialogButton
            v-if="!isDCAUApproved && character.stats.level > 3"
            @click="approveDCAU()"
            :buttonText="'Approve DCAU'"
            :isLoading="isApprovingDCAU"
          />
          <template v-else-if="character.stats.level < 4">
            <DialogButton
              id="resurrect-button"
              @click="processFreeResurrect()"
              buttonText="Ressurrect for free"
              :isLoading="isResurrecting || isFetchingResurrectPrice"
              :disabled="isTurnHappening"
            />
          </template>
          <template v-else>
            <DialogButton
              id="resurrect-button"
              v-if="
                (hasDCAU &&
                  parseFloat(currentDCAU) >= parseFloat(resurrectPrice)) ||
                isFetchingResurrectPrice ||
                isResurrecting
              "
              @click="processResurrect()"
              :buttonText="
                resurrectPrice > 0
                  ? 'Resurrect (' + resurrectPrice + ' DCAU)'
                  : 'Calculating Price...'
              "
              :isLoading="isResurrecting || isFetchingResurrectPrice"
              :disabled="isTurnHappening"
            />
            <template
              v-else-if="parseFloat(currentDCAU) < parseFloat(resurrectPrice)"
            >
              <DialogButton
                class="buy-note res"
                :buttonText="resurrectPrice + ' DCAU to Resurrect'"
                disabled
              />
              <DialogButton
                class="buy-note res"
                @click="openBuyDCAULink"
                buttonText="Buy DCAU"
              />
            </template>
          </template>
          <DialogButton
            :disabled="
              isLoading || isResurrecting || isHealing || isTurnHappening
            "
            @click="$emit('leaveHutDead')"
            :buttonText="'Leave'"
          />
        </template>
        <template v-else>
          <template v-if="needsHeal">
            <DialogButton
              v-if="!isDCAUApproved"
              @click="approveDCAU()"
              :buttonText="'Approve DCAU'"
              :isLoading="isApprovingDCAU"
            />
            <template v-else>
              <DialogButton
                v-if="
                  (hasDCAU &&
                    parseFloat(currentDCAU) >= parseFloat(healPrice)) ||
                  isHealing ||
                  isFetchingHealPrice
                "
                @click="processHeal()"
                :buttonText="
                  healPrice > 0
                    ? 'Heal (' + healPrice + ' DCAU)'
                    : 'Calculating Price...'
                "
                :isLoading="isHealing || isFetchingHealPrice || isHealing"
                :disabled="isTurnHappening"
              />
              <template
                v-else-if="parseFloat(currentDCAU) < parseFloat(healPrice)"
              >
                <DialogButton
                  class="buy-note"
                  :buttonText="healPrice + ' DCAU to Heal'"
                  disabled
                />
                <DialogButton
                  @click="openBuyDCAULink"
                  class="buy-note"
                  buttonText="Buy DCAU"
                />
              </template>
            </template>
          </template>
          <DialogButton
            @click="leave"
            :disabled="isTurnHappening"
            :buttonText="'Leave'"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref, onUnmounted } from "vue";
import { useStore } from "vuex";
import Constants from "../consts/constants";
import DialogButton from "./DialogButton.vue";
import GameMessage from "./GameMessage.vue";

import { ethers } from "ethers";
import { usePrice } from "../composables/price";
import { useUser } from "../composables/user";
import {
  getDCAUContract,
  getLoadHealersHutContract,
} from "../utils/getContract";
import { parseError } from "../utils/helpers";

export default {
  name: "HealersHut",
  components: {
    GameMessage,
    DialogButton,
  },
  emits: ["res", "heal", "leaveHutDead", "leaveHut"],
  props: {},
  setup(props, { emit }) {
    const store = useStore();
    const { address, signer } = useUser();
    const healPrice = ref(0);
    const resurrectPrice = ref(0);
    const isHealing = ref(false);
    const isResurrecting = ref(false);
    const isApprovingDCAU = ref(false);
    const isDCAUApproved = ref(true);
    const { updateBalances } = usePrice();
    const isFetchingHealPrice = ref(true);
    const isFetchingResurrectPrice = ref(true);

    const DCAUContract = getDCAUContract(signer.value);
    const HealersHutContract = getLoadHealersHutContract(signer.value);

    // store unwatchers
    let unWatchCharacterId = null;

    onMounted(async () => {
      await checkIsDCAUApproved();

      unWatchCharacterId = store.watch(
        (state) => state.currentCharacterId,
        async (value) => {
          console.log(`Current Character ${value}`);
          if (isDead.value) {
            await getResurrectPrice();
          } else {
            await getHealPrice();
          }
        }
      );
    });

    onUnmounted(() => {
      unWatchCharacterId();
    });

    const openBuyDCAULink = () => {
      window.open(
        "https://traderjoexyz.com/avalanche/trade?inputCurrency=0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e&outputCurrency=0x100cc3a819dd3e8573fd2e46d1e66ee866068f30",
        "_blank"
      );
    };
    const leave = () => {
      store.commit("intro/setIsHealFlowComplete", true);
      emit("leaveHut");
    };

    /**
     * Method to check if DCAU is approved
     */
    const checkIsDCAUApproved = async () => {
      let amountToCheck = 0;
      if (isDead.value) {
        await getResurrectPrice();
        amountToCheck = resurrectPrice.value;
      } else {
        await getHealPrice();
        amountToCheck = healPrice.value;
      }
      let allowanceHealersHut = await DCAUContract.allowance(
        address.value,
        HealersHutContract.address
      );
      allowanceHealersHut = ethers.utils.formatEther(allowanceHealersHut);
      if (parseFloat(allowanceHealersHut) >= parseFloat(amountToCheck)) {
        isDCAUApproved.value = true;
      } else {
        isDCAUApproved.value = false;
      }
      updateBalances();
    };

    /**
     * Approve DCAU Method
     */
    const approveDCAU = async () => {
      isApprovingDCAU.value = true;
      try {
        let txApproveHealersHutSpend = await DCAUContract.approve(
          HealersHutContract.address,
          ethers.utils.parseEther("1000000000")
        );
        await txApproveHealersHutSpend.wait();
        isDCAUApproved.value = true;
      } catch (error) {
        console.log(error);
      }
      isApprovingDCAU.value = false;
    };

    /**
     * Fetching inn rest price via server cause level is needed
     */
    const getHealPrice = async () => {
      isFetchingHealPrice.value = true;
      const sessionId = localStorage.getItem("sessionId");
      const heroId = character.value.number;
      const response = await fetch(
        Constants.apiUrl + "contract/get-heal-price",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId,
            heroId,
            account: address.value,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        console.log(`Heal Price: ${data.price}`);
        healPrice.value = parseFloat(data.price).toFixed(6);
      }
      isFetchingHealPrice.value = false;
    };

    /**
     * Fetching inn rest price via server cause level is needed
     */
    const getResurrectPrice = async () => {
      isFetchingResurrectPrice.value = true;
      const sessionId = localStorage.getItem("sessionId");
      const heroId = character.value.number;
      const response = await fetch(
        Constants.apiUrl + "contract/get-resurrect-price",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId,
            heroId,
            account: address.value,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        console.log(data.price);
        resurrectPrice.value = parseFloat(data.price).toFixed(6);
      }
      isFetchingResurrectPrice.value = false;
    };

    const getSignedDataResurrect = async () => {
      const sessionId = localStorage.getItem("sessionId");
      const heroId = character.value.number;
      const resurrectId = "1";
      const response = await fetch(
        Constants.apiUrl + "contract/sign-resurrect",
        {
          method: "POST",
          body: JSON.stringify({
            sessionId,
            heroId,
            resurrectId,
            account: address.value,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      return {
        level: character.value.stats.level,
        heroId,
        resurrectId,
        signature: data.signature,
        nonce: data.nonce,
      };
    };

    const getSignedDataHeal = async () => {
      const sessionId = localStorage.getItem("sessionId");
      const heroId = character.value.number;
      const healId = "1";
      const response = await fetch(Constants.apiUrl + "contract/sign-heal", {
        method: "POST",
        body: JSON.stringify({
          sessionId,
          heroId,
          healId,
          account: address.value,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      return {
        healAmount: Math.ceil(currentHealHp.value),
        heroId,
        healId,
        signature: data.signature,
        nonce: data.nonce,
      };
    };
    /**
     * Method to interact with contract to heal
     */
    const processHeal = async () => {
      isHealing.value = true;
      store.commit("setTurnHappening", true);
      const { healAmount, heroId, healId, signature, nonce } =
        await getSignedDataHeal();
      try {
        // Executing Heal Transaction
        const tx = await HealersHutContract.heal(
          healAmount,
          heroId,
          healId,
          signature,
          nonce
        );
        const receipt = await tx.wait();
        // Only process Heal if the transaction was successful!
        if (receipt.status === 1) {
          updateBalances(); // Aynschronously updating token balances
          // TODO: MOVE THE HEAL API CALL TO SERVER, VERIFY TRANSACTION AND THEN PROCESS
          // emit("heal");
          const payload = {
            heroId,
            blockNumber: receipt.blockNumber,
          };
          store.commit("setHealing", payload);
        }
        isHealing.value = false;
      } catch (error) {
        console.log(error);
        store.commit("setNotification", parseError(error));
        isHealing.value = false;
        store.commit("setTurnHappening", false);
      }
    };

    /**
     * Method to interact with contract to resurrect
     */
    const processResurrect = async () => {
      isResurrecting.value = true;
      store.commit("setTurnHappening", true);
      const { level, heroId, resurrectId, signature, nonce } =
        await getSignedDataResurrect();
      console.log(level, heroId, resurrectId, signature, nonce);
      try {
        // Executing Resurrect Transaction
        const tx = await HealersHutContract.resurrect(
          level,
          heroId,
          resurrectId,
          signature,
          nonce
        );
        const receipt = await tx.wait();
        console.log(receipt);
        // Only process Resurrect if the transaction was successful!
        if (receipt.status === 1) {
          updateBalances(); // Aynschronously updating token balances
          // TODO: MOVE THE RESURRECT API CALL TO SERVER, VERIFY TRANSACTION AND THEN PROCESS
          // emit("res");
          const payload = {
            heroId,
            blockNumber: receipt.blockNumber,
          };
          store.commit("setResurrect", payload);
        }
        isResurrecting.value = false;

        // Updating tutorial event that res is done
        store.commit("intro/setIsDeadFlowComplete", true);
      } catch (error) {
        console.log(error);
        store.commit("setNotification", parseError(error));
        isResurrecting.value = false;
        store.commit("setTurnHappening", false);
      }
    };

    const processFreeResurrect = async () => {
      const heroId = character.value.number;
      isResurrecting.value = true;
      store.commit("setTurnHappening", true);

      try {
        store.commit("setResurrectFree", heroId);

        isResurrecting.value = false;

        // Updating tutorial event that res is done
        store.commit("intro/setIsDeadFlowComplete", true);
      } catch (error) {
        console.log(error);
        store.commit("setNotification", parseError(error));
        isResurrecting.value = false;
        store.commit("setTurnHappening", false);
      }
    };

    /**
     * Computed Methods
     */
    const character = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });
    const currentHealHp = computed(() => {
      return (
        store.state.characters[store.state.currentCharacter].stats.totalHp -
        store.state.characters[store.state.currentCharacter].stats.hp
      );
    });
    const needsHeal = computed(() => {
      if (
        store.state.characters[store.state.currentCharacter] == null ||
        store.state.characters[store.state.currentCharacter] == "undefined"
      ) {
        return false;
      }
      return (
        store.state.characters[store.state.currentCharacter].stats.hp <
        store.state.characters[store.state.currentCharacter].stats.totalHp
      );
    });

    const healFee = computed(() => {
      return currentHealHp.value * Constants.healCostMultiplier;
    });

    const isTurnHappening = computed(() => {
      return store.state.isTurnHappening;
    });

    const isDead = computed(() => {
      if (
        store.state.characters[store.state.currentCharacter] == null ||
        store.state.characters[store.state.currentCharacter] == "undefined"
      ) {
        return false;
      }
      return store.state.characters[store.state.currentCharacter].stats.isDead;
    });

    const currentDCAU = computed(() => {
      return store.state.dcauInWallet;
    });

    const hasDCAU = computed(() => {
      return store.state.dcauInWallet > 0.0;
    });

    const isLoading = computed(() => {
      return store.state.isTurnHappening;
    });

    const getClass = computed(() => {
      return (
        "healers-wrapper wrap-content " +
        (store.state.gameState == Constants.gamemodes.healers ? "show" : "hide")
      );
    });

    return {
      // Data
      healPrice,
      resurrectPrice,
      isHealing,
      isResurrecting,
      isDCAUApproved,
      isApprovingDCAU,
      isFetchingHealPrice,
      isFetchingResurrectPrice,
      // Methods
      processHeal,
      processResurrect,
      processFreeResurrect,
      approveDCAU,
      leave,
      openBuyDCAULink,
      // Computed
      isLoading,
      currentHealHp,
      character,
      currentDCAU,
      needsHeal,
      healFee,
      isDead,
      isTurnHappening,
      getClass,
      hasDCAU,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.healers-wrapper {
  background: no-repeat url("../assets/locationBackgrounds/HealersHutBG.webp")
    center center;
  background-size: cover;
}

.buy-note {
  &:hover {
    &:after {
      content: "You need DCAU to pay for heal";
      display: block;
      background: rgba(0, 0, 0, 0.6196078431);
      padding: 5px;
      border-radius: 4px;
      top: 45px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 78%;
      font-size: 0.8rem;
      font-family: "Lato", sans-serif;
      padding: 8px 0;
    }
  }
  &.res {
    &:hover {
      &:after {
        content: "You need DCAU to pay for resurrection";
        width: 85%;
      }
    }
  }
}

.show {
  opacity: 1;
  pointer-events: all;

  transition: opacity 0.35s linear;
}

.hide {
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.35s linear;
}

.highlight {
  font-weight: bold;
  color: $dmg;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  font-size: 90%;
}
</style>
