<template>
  <BasicPopup
    :header="
      totalSelected === 0
        ? 'Select Your Heroes'
        : `Heroes Selected ${totalSelected}/5`
    "
    popup-type="full"
    class="character-select"
    v-if="isOpen"
  >
    <div class="toggle-view-container">
      <input type="checkbox" v-model="showCarousel" class="toggle-view" />
    </div>
    <template v-if="showCarousel">
      <carousel
        id="character-cards"
        :settings="settings"
        :breakpoints="breakpoints"
        snapAlign="center"
        v-if="allCharacters.length > 0"
      >
        <slide v-for="character in allCharacters" :key="character.number">
          <CharacterCard class="character" :character="character" />
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
      <div v-else>
        <h3>You don't own any heroes.</h3>
        <p>Continue exploring Dragon Haven without heroes or buy a hero.</p>
        <p>
          You can buy a hero at
          <a
            href="https://nftrade.com/assets/avalanche/0x7a420aeff902aaa2c85a190d7b91ce8beffffe14"
            target="_blank"
            >NFTrade</a
          >
          or
          <a
            href="https://joepegs.com/collections/0x7a420aeff902aaa2c85a190d7b91ce8beffffe14"
            target="_blank"
            >Joepegs</a
          >
        </p>
        <br />
        <BlueButton @click="close" size="small">Close</BlueButton>
      </div>
    </template>

    <div class="character-grid-wrapper" v-else>
      <div id="character-grid" v-if="allCharacters.length > 0">
        <CharacterCardGrid
          v-for="character in allCharacters"
          :key="character.number"
          class="character"
          :character="character"
        />
      </div>
      <div v-else>
        <h3>You don't own any heroes.</h3>
        <p>Continue exploring Dragon Haven without heroes or buy a hero.</p>
        <p>
          You can buy a hero at
          <a
            href="https://nftrade.com/assets/avalanche/0x7a420aeff902aaa2c85a190d7b91ce8beffffe14"
            target="_blank"
            >NFTrade</a
          >
          or
          <a
            href="https://joepegs.com/collections/0x7a420aeff902aaa2c85a190d7b91ce8beffffe14"
            target="_blank"
            >Joepegs</a
          >
        </p>
        <br />
        <BlueButton @click="close" size="small">Close</BlueButton>
      </div>
    </div>
    <div class="bottom-buttons">
      <DialogButton
        :disabled="totalSelected === 0"
        @click="processSelection"
        :buttonSize="'small'"
        :buttonText="`Confirm`"
      />
      <DialogButton
        :disabled="totalSelected === 0"
        @click="unselectSelection"
        :buttonSize="'small'"
        :buttonText="'Clear'"
      />
    </div>
  </BasicPopup>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import BasicPopup from "../Popup/BasicPopup.vue";
import { useStore } from "vuex";
import CharacterCard from "./CharacterCard.vue";
import CharacterCardGrid from "./CharacterCardGrid.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
// import BlueButton from "../Buttons/BlueButton.vue";
import DialogButton from "../DialogButton.vue";

export default {
  name: "CharacterSelect",
  components: {
    BasicPopup,
    CharacterCard,
    Carousel,
    Slide,
    Navigation,
    DialogButton,
    CharacterCardGrid,
  },
  setup() {
    const store = useStore();
    const allCharacters = ref([]);
    const slider = ref(null);
    const showCarousel = ref(false);

    const settings = {
      itemsToShow: 1,
      snapAlign: "center",
    };

    const breakpoints = {
      // 700px and up
      700: {
        itemsToShow: 3.5,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 4,
        snapAlign: "start",
      },
      1600: {
        itemsToShow: 5,
        snapAlign: "start",
      },
    };

    const selectedCard = () => {
      console.log("selected this card");
    };

    // Watching change in character to ensure the current character data is being used.
    store.watch(
      (state) => state.characterSelect.selectedCharacters,
      (value) => {
        console.log("Selected Characters:", value);
      }
    );

    const playOnClose = () => {
      const onSelectSound = new Audio(
        "https://cdn.dragoncrypto.io/sound/deep-breath.mp3"
      );
      onSelectSound.volume = store.state.soundVolume;
      onSelectSound.play();
    };

    const isOpen = computed(() => {
      return store.state.characterSelect.isSelectScreenOpen;
    });

    const totalSelected = computed(() => {
      return store.state.characterSelect.selectedCharacters.length;
    });

    const close = () => {
      store.commit("characterSelect/closeSelectionScreen", false);
    };

    const processSelection = () => {
      if (totalSelected.value > 0) {
        store.dispatch("characterSelect/setActiveCharacters");
        store.commit("characterSelect/closeSelectionScreen", false);
        playOnClose();
      } else {
        store.commit(
          "setNotification",
          "You need to select at least one character"
        );
      }
    };
    const unselectSelection = () => {
      store.commit("characterSelect/setSelectedCharacters", []);
    };

    onMounted(() => {
      allCharacters.value = store.state.characterSelect.allCharacters;
      showCarousel.value = allCharacters.value.length < 5; // Showing default carousel view for players with less than 5 heroes.
    });

    return {
      //data
      showCarousel,
      allCharacters,
      slider,
      settings,
      breakpoints,
      totalSelected,
      isOpen,
      // methods
      selectedCard,
      processSelection,
      close,
      unselectSelection,
    };
  },
};
</script>

<style lang="scss">
section#character-cards {
  top: 44%;
  transform: translateY(-50%);
  .carousel__prev {
    left: -12px;
    background-color: #19486f;
    color: #fff;
    @media only screen and (max-width: 576px) {
      display: none;
    }
  }
  .carousel__next {
    right: -12px;
    background-color: #19486f;
    color: #fff;
    @media only screen and (max-width: 576px) {
      display: none;
    }
  }
  .carousel__prev--in-active,
  .carousel__next--in-active {
    display: none;
  }
}
</style>

<style scoped lang="scss">
@import "../../assets/scss/globals.scss";

.character-select {
  z-index: 16;
  .card-holder {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1rem;
    overflow-y: scroll;
    height: 100%;
  }
  .bottom-buttons {
    position: absolute;
    display: flex;
    bottom: -39px;
    height: 70px;
    background: #0000008a;
    width: calc(100% + 76px);
    left: -38px;
    align-items: center;
    justify-content: center;
  }
}
.toggle-view-container {
  position: absolute;
  top: -28px;
  left: 0;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr 60px 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  justify-items: center;
  @media only screen and (max-width: 576px) {
    grid-template-columns: 1fr;
    left: -35px;
    top: -35px;
  }
  &:before,
  &:after {
    @media only screen and (max-width: 576px) {
      display: none;
    }
    display: inline-block;
    width: 20px;
    height: 20px;
    content: "";
    position: relative;
    opacity: 0.5;
  }
  &:before {
    background-image: url("../../assets/ui/grid.svg");
    background-size: cover;
  }
  &:after {
    background-image: url("../../assets/ui/carousel.svg");
    background-size: cover;
  }

  .toggle-view {
    @media only screen and (max-width: 576px) {
      transform: scale(0.8);
    }
    position: relative;
    width: 60px;
    height: 20px;
    -webkit-appearance: none;
    appearance: none;
    background: #ffffff40;
    outline: none;
    border-radius: 2rem;
    cursor: pointer;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);

    &::before {
      content: "";
      width: 30px;
      height: 30px;
      border-radius: 50%;
      box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.5);
      background: rgb(20, 34, 52);
      background: linear-gradient(
        180deg,
        rgba(20, 34, 52, 1) 0%,
        rgba(15, 38, 62, 1) 50%,
        rgba(32, 85, 124, 1) 100%
      );
      border: 1px #4d77994f solid;
      position: absolute;
      top: -6px;
      left: 0;
      transition: 0.5s;
    }

    &:checked::before {
      transform: translateX(100%);
      /* background: #fff; */
    }

    /* &:checked {
      background: #00ed64;
    } */
  }
}
.character-grid-wrapper {
  width: calc(100% + $popup-border-offset);
  height: 100%;
  padding: 0 calc($popup-border-offset / 2);
  &::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000f2;
  }
  @media only screen and (max-width: 576px) {
    height: calc(100% + $popup-border-offset + $popup-border-offset);
    overflow-y: scroll;
  }
  left: -$popup-border-offset;
  position: relative;
  top: -35px;
  overflow-y: scroll;
  #character-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }
}
</style>
