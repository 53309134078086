<template>
  <div class="level-up-message">
    <div class="message">
      <h1>Level Up!</h1>

      <p>✨ You have gained a level! ✨</p>
      <div class="shield"></div>
      <h2>
        #{{ getCharacterStats.id }} is now level {{ getCharacterStats.level }}!
      </h2>
      <p>Visit your character screen to spend your points.</p>
      <div class="buttons">
        <DialogButton
          :isLoading="isLoading"
          buttonSize="small"
          class="share-item"
          @click="shareItem"
          buttonText="Share"
        />

        <DialogButton
          @click="continueFromLevelup"
          buttonSize="small"
          buttonText="Continue"
        />
      </div>
    </div>
    <Celebrate type="stars2" />
  </div>
  <div v-if="shareImage !== '' && isShareOpen" class="share-wrapper">
    <div class="share-image">
      <img :src="shareImage" alt="Share" />
      <p>
        Right click and copy image. Then click on tweet to open twitter compose.
        Paste image and share.
      </p>
      <a
        class="twitter-share-button"
        :href="getShareLink()"
        data-size="large"
        data-text="Check out this cool item I just got in #AurumDraconis by @DCGGameFi! #BlockchainGaming. Going live soon: https://aurumdraconis.dragoncrypto.io/"
        :data-url="shareImage"
        data-hashtags="aurumdraconis,dcg,load"
        data-via="load"
        data-related="gamefi,crypto,twitter"
        target="_blank"
      >
        Tweet
      </a>
      <button class="close" @click="isShareOpen = false">Close</button>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useSound } from "../../composables/sound";
import DialogButton from "../DialogButton.vue";
// import GenericLoading from "./LoadingComponents/GenericLoading.vue";
import Constants from "../../consts/constants";
import Celebrate from "../Celebrate/Celebrate.vue";

import levelUpSound from "../../assets/sound/level-up.mp3";

export default {
  name: "LevelUp",
  components: {
    DialogButton,
    // GenericLoading,
    Celebrate,
  },
  props: {
    item: {},
  },
  setup() {
    const store = useStore();
    const shareImage = ref("");
    const isShareOpen = ref(false);
    const isLoading = ref(false);

    onMounted(async () => {
      await delay(500);
      levelUpCelebrationSound.play();
    });

    const shareItem = async () => {
      isLoading.value = true;
      try {
        const response = await fetch(Constants.apiUrl + "helper/share-hero", {
          method: "POST",
          body: JSON.stringify({
            heroId: getCharacterStats.value.id,
            account: store.state.account,
            sessionId: localStorage.getItem("sessionId"),
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const result = await response.json();
        console.log(result);
        if (result.success) {
          shareImage.value = result.image;
          isShareOpen.value = true;
        }
        isLoading.value = false;
      } catch (error) {
        console.log(error);
        isLoading.value = false;
      }
    };

    const getShareLink = () => {
      const share = [
        "https://twitter.com/intent/tweet?text=My%20character%20just%20leveled%20up%20in%20%23AurumDraconis%20by%20%40DCGGameFi%20%F0%9F%90%89%F0%9F%92%AA%20%0A%0AJoin%20me%20on%20this%20epic%20adventure%21%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
        "https://twitter.com/intent/tweet?text=Leveled%20up%20and%20feeling%20unstoppable%20in%20%23AurumDraconis%20by%20%40DCGGameFi%21%20%E2%9A%94%EF%B8%8F%0A%0ABegin%20your%20own%20legendary%20journey%3A%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
        "https://twitter.com/intent/tweet?text=Hitting%20new%20heights%20in%20%23AurumDraconis%20by%20%40DCGGameFi%20%F0%9F%93%88%0A%0AStart%20your%20own%20epic%20quest%3A%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
        "https://twitter.com/intent/tweet?text=I%20just%20reached%20a%20new%20milestone%20in%20%23AurumDraconis%20by%20%40DCGGameFi%20%F0%9F%8F%86%0A%0AReady%20to%20embark%20on%20your%20own%20heroic%20adventure%3F%20%0A%0AGet%20started%3A%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
        "https://twitter.com/intent/tweet?text=Unlocking%20my%20character%27s%20potential%20in%20%23AurumDraconis%20by%20%40DCGGameFi%21%20%F0%9F%97%9D%EF%B8%8F%F0%9F%8E%AE%20%0A%0AJoin%20the%20action%20and%20start%20your%20own%20journey%3A%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
        "https://twitter.com/intent/tweet?text=Powering%20up%20my%20hero%20in%20%23AurumDraconis%20by%20%40DCGGameFi%21%20%E2%9A%94%EF%B8%8F%20%0A%0ADive%20into%20the%20adventure%20and%20forge%20your%20own%20story%3A%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
        "https://twitter.com/intent/tweet?text=Leveling%20up%20like%20a%20boss%20in%20%23AurumDraconis%20by%20%40DCGGameFi%21%20%F0%9F%94%A5%20%0A%0ABecome%20a%20part%20of%20the%20legend%3A%20https%3A%2F%2Faurumdraconis.dragoncrypto.io%2Fgetting-started",
        "https://twitter.com/intent/tweet?text=Just%20leveled%20up%20in%20%23AurumDraconis%20by%20%40DCGGameFi%20and%20loving%20every%20minute%20of%20it%21%20%F0%9F%8E%AE%20%0A%0AConnect%20with%20fellow%20adventurers%20in%20our%20Discord%20community%3A%20https%3A%2F%2Fdragoncrypto.io%2Fcommunity",
        "https://twitter.com/intent/tweet?text=I%27ve%20reached%20new%20heights%20in%20%23AurumDraconis%20by%20%40DCGGameFi%21%20%F0%9F%90%89%0A%0ACome%20chat%2C%20strategize%2C%20and%20share%20your%20experiences%20with%20us%20on%20Discord%3A%20https%3A%2F%2Fdragoncrypto.io%2Fcommunity",
      ];
      const index = Math.floor(Math.random() * (share.length - 1));
      return share[index];
    };

    const levelUpCelebrationSound = useSound(
      // "https://cdn.dragoncrypto.io/sound/break.mp3",
      levelUpSound,
      {
        volume: store.state.soundVolume,
        interrupt: true,
      }
    );
    // Helper method to delay stuff
    const delay = async (time) => new Promise((res) => setTimeout(res, time));

    const continueFromLevelup = () => {
      store.commit("setHasPlayerLeveledUp", false);
    };

    const getCharacterStats = computed(() => {
      if (store.state.gameState === Constants.gamemodes.combat) {
        return store.state.leveledUpHeroStats;
      }
      return store.state.characters[store.state.currentCharacter].stats;
    });

    return {
      // Data
      shareImage,
      isShareOpen,
      isLoading,
      // Methods
      continueFromLevelup,
      getShareLink,
      shareItem,
      // Computed
      getCharacterStats,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";
.level-up-message {
  background: rgba(0, 0, 0, 0.8549019608);
  width: 100%;
  height: 100%;
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  .message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h1 {
      font-size: 3rem;
      margin: 0;
    }
    h2 {
      margin: 0 0 10px;
    }
    p {
      font-size: 1.2rem;
      margin: 0 0 20px;
    }
    .shield {
      width: 400px;
      height: 400px;
      background-size: cover;
      background-image: url("../../assets/ui/dragon-shield-small.png");
      margin: 20px auto;
    }
  }
  div.item-image {
    width: 200px;
    height: 200px;
    display: block;
    margin: 15px auto;
    background-size: cover;
    position: relative;
    .loading {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      position: absolute;
      animation: hideAnimation 0s ease-in 5s; /** hiding loading after 5 seconds in case it's visible in transparent image */
      animation-fill-mode: forwards;
    }
  }
  p.message {
    line-height: 1.4rem;
    width: 70%;
    margin: 0 auto 10px;
    span {
      color: $gold;
    }
  }
  p.rarity {
    margin: 20px auto 40px;
    span {
      padding: 5px 10px;
      font-weight: bold;
      &.shoddy {
        border: 1px solid $shoddy;
        padding: 5px 10px;
      }

      &.epic {
        background-color: $epic;
        color: #000;
      }

      &.legendary {
        background-color: $legendary;
        color: #000;
      }

      &.mythical {
        background-color: $mythical;
      }

      &.fabled {
        background-color: $fabled;
      }

      &.unique {
        background-color: $unique;
      }

      &.rare {
        background-color: $rare;
      }

      &.artefact {
        background-color: $artefact;
        color: #000;
      }

      &.junk {
        border: 1px solid #000;
      }

      &.uncommon {
        border: 1px solid $uncommon;
      }
    }
  }
}
.share-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  display: grid;
  align-items: center;
  justify-items: center;
  background: #000000db;
  p {
    max-width: 600px;
    font-size: 1.2rem;
    color: #ffffffb5;
  }
  .twitter-share-button {
    background-color: #5081ff;
    display: inline-block;
    width: 75px;
    padding: 5px 0;
    font-weight: bold;
    text-decoration: none;
    border-radius: 6px;
    color: #fff;
    margin: 10px auto;
  }
  .close {
    background-color: #777777;
    display: inline-block;
    width: 75px;
    padding: 8px 0;
    font-weight: bold;
    text-decoration: none;
    border-radius: 6px;
    color: #fff;
    margin: 10px 5px;
    border: none;
    top: -2px;
    position: relative;
  }
}
@keyframes hideAnimation {
  to {
    opacity: 0;
  }
}
</style>
