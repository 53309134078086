import { utils } from "ethers";
import { useUser } from "../composables/user";

export const parseError = (error) => {
  const { chainId } = useUser();
  let errorData = 0;
  if (error.data) {
    if (chainId.value === 43113 || chainId.value === 43114) {
      errorData = error.data.data; // the structure returned by error on Avax. On Poly it was error.data.data.data
    } else {
      errorData = error.data.data.data; // the structure returned by error on Avax. On Poly it was error.data.data.data
    }
    console.log(errorData);
    if (errorData.startsWith("0x08c379a0")) {
      // decode Error(string)

      const content = `0x${errorData.substring(10)}`;
      const reason = utils.defaultAbiCoder.decode(["string"], content);
      return reason[0]; // reason: string; for standard revert error string
    }

    if (errorData.startsWith("0x4e487b71")) {
      // decode Panic(uint)
      const content = `0x${errorData.substring(10)}`;
      const code = utils.defaultAbiCoder.decode(["uint"], content);

      return code[0];
    }
  }
  return error.message;
};
