// initial state
import Constants from "../../consts/constants";

const state = () => ({
  isBankOpen: false,
});

// // getters
// const getters = {};

// // actions
const actions = {
  processSorting({ commit }, value) {
    switch (value) {
      case "Recent":
        commit("setSortBy", "createdAt");
        commit("setOrder", "desc");
        break;
      case "Oldest":
        commit("setSortBy", "createdAt");
        commit("setOrder", "asc");
        break;
      case "Price Desc":
        commit("setSortBy", "priceInDCAU");
        commit("setOrder", "desc");
        break;
      case "Price Asc":
        commit("setSortBy", "priceInDCAU");
        commit("setOrder", "asc");
        break;
      case "Rarity Desc":
        commit("setSortBy", "rarity");
        commit("setOrder", "desc");
        break;
      case "Rarity Asc":
        commit("setSortBy", "rarity");
        commit("setOrder", "asc");
        break;
      default:
        break;
    }
  },
  loadMoreMarketItems({ commit, state }, items) {
    const currentItems = state.marketData;
    currentItems.push(...items);
    commit("setMarketData", currentItems);
  },
  loadMoreMyListedItems({ commit, state }, items) {
    const currentItems = state.myListedItemsData;
    currentItems.push(...items);
    commit("setMyListedItemsData", currentItems);
  },
  async updateMyListedItems({ commit, state, rootState }) {
    const marketplaceApiQuery = `marketplace/my-listed-items?type=${state.marketFilter}&page=1&sortBy=${state.sortBy}&order=${state.order}`;
    const response = await fetch(Constants.apiUrl + marketplaceApiQuery, {
      method: "POST",
      body: JSON.stringify({
        account: rootState.account,
        sessionId: localStorage.getItem("sessionId"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    if (data.success) {
      commit("setMyListedItemsData", data.marketplaceItems);
    }
  },
  async updateMarketplaceItems({ commit, state }) {
    const marketplaceApiQuery = `marketplace/get-items?type=${state.marketFilter}&search=${state.searchTerm}&page=1&sortBy=${state.sortBy}&order=${state.order}`;
    const response = await fetch(Constants.apiUrl + marketplaceApiQuery);
    const data = await response.json();
    if (data.success) {
      commit("setMarketData", data.marketplaceItems);
    }
  },
};

// mutations
const mutations = {
  setIsBankOpen(state, value) {
    state.isBankOpen = value;
  },
};

export default {
  namespaced: true,
  state,
  //   getters,
  actions,
  mutations,
};
