<template>
  <div
    class="notifications-container"
    :class="[toggle ? 'active' : '', pinned ? 'pinned' : '']"
    @mouseenter="delayedScrollToBottom(200)"
  >
    <button class="pin" @click="pinned = !pinned">
      {{ pinned ? "pinned" : "pin" }}
    </button>
    <div class="notifications-box">
      <div class="notifications-area">
        <Notification
          v-for="{
            id,
            heroId,
            textContent,
            type,
            walletAddress,
            created,
          } in notifications"
          :key="id"
          :notificationId="id"
          :nftid="`${heroId}`"
          :walletAddress="walletAddress"
          :createdAt="created"
          :type="type"
          :text="textContent"
        />
      </div>
      <div ref="bottom" />
    </div>
  </div>
  <button
    @click="toggleNotifications"
    id="notification-button-guide"
    class="notification-button"
  >
    <span
      :class="
        alreadyReadNotifications ? 'hide' : 'unread' // if chat is visible or no messages are unread
      "
      >{{ alreadyReadNotifications ? "no" : "yes" }}</span
    >
  </button>
</template>

<script>
import { io } from "socket.io-client";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useMixpanel } from "../../composables/mixpanel";
import Constants from "../../consts/constants";
import Notification from "./Notification.vue";

export default {
  name: "NotificationBox",
  components: { Notification },
  setup() {
    const store = useStore();
    const notifications = ref([]);
    const message = ref("");
    const bottom = ref(null);
    const disableForm = ref(false);
    const toggle = ref(false);
    const character = ref(null);
    const account = ref("");
    const socket = io(Constants.apiUrl, { transports: ["websocket"] });
    const pinned = ref(false);

    // Joining socket but only listening to this particular session ID
    socket.emit("join", { sessionId: localStorage.getItem("sessionId") });

    const chatInputBox = ref(null);
    const { trackEvent } = useMixpanel();

    onUnmounted(() => {
      socket.removeAllListeners("new-message");
      socket.disconnect();
    });

    onMounted(async () => {
      // Set the character to the current character from store
      getCharacter();
      account.value = store.state.account;

      /**
       * Pooling server to catch any new message event being generated
       * Refetch messages if a new message is generated
       */
      socket.on("notification", (data) => {
        // console.log(data);
        // getNotifications();
        pushNewNotification(data);
      });

      socket.io.on("error", () => {
        console.log(
          "Unable to connect to chat server. Please try again or let the team know"
        );
      });
      await getNotifications();

      if (notifications.value.length > 0) {
        // Calling initial load action to check to verify if messages are read
        store.dispatch(
          "notifications/loadInitialReadStatus",
          notifications.value[notifications.value.length - 1].id
        );
      }
    });

    const alreadyReadNotifications = computed(() => {
      return store.state.notifications.readNotifications;
    });

    // Watching change in character to ensure the current character data is being used.
    store.watch(
      (state) => state.characters[state.currentCharacter],
      (value) => {
        character.value = value;
      }
    );

    // Watching change in account to ensure the current account data is being used.
    store.watch(
      (state) => state.account,
      (value) => {
        account.value = value;
      }
    );

    /**
     * Watching messages.
     * After every change in message, scrolling to the bottom of the message area.
     * Also assigning read message to the latest message count if chat open
     */
    watch(
      notifications,
      // (messages, prevMessages) => {
      () => {
        if (toggle.value) {
          delayedScrollToBottom(200);
        }
      },
      { deep: true }
    );
    const getCharacter = () => {
      character.value = store.state.characters[store.state.currentCharacter];
    };

    /**
     * Helper Methods
     */
    const toggleNotifications = () => {
      toggle.value = !toggle.value;
      store.commit("notifications/setNotificationsOpenState", toggle.value);

      // Focusing on chat input box when chat opens
      if (toggle.value) {
        delayedScrollToBottom(500);

        store.commit(
          "notifications/setLastReadNotificationId",
          notifications.value[notifications.value.length - 1].id
        );
        store.commit("notifications/setReadNotifications", true);

        // Mixpanel tracking chat open
        trackEvent("Notifications Opened", {
          state: store.state.gameState,
        });
      }
    };

    const delayedScrollToBottom = (delay) => {
      setTimeout(() => {
        bottom.value.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }, delay);
    };

    const pushNewNotification = (notification) => {
      if (typeof notification.created === "object") {
        const seconds = new Date(notification.created).getTime() / 1000; // Converting timestamp returned via socket to epoch seconds
        notification.created = { _seconds: seconds };
      }
      notifications.value.push(notification);
      // console.log(notifications.value);

      // console.log(store.state.notifications.isNotificationsOpen);
      if (!store.state.notifications.isNotificationsOpen) {
        store.commit("notifications/setReadNotifications", false);
      } else {
        delayedScrollToBottom(200);
      }
    };

    const getNotifications = async () => {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts.length > 0 ? accounts[0] : "";
      const sessionId = localStorage.getItem("sessionId");

      const response = await fetch(Constants.apiUrl + "get-notifications", {
        method: "POST",
        body: JSON.stringify({
          sessionId,
          account,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      notifications.value = data.notifications.reverse();
      const prevReadMessageId =
        store.state.notifications.lastReadNotificationId;

      /**
       * Checking if the last message is from the same sender or not. If it isn't, set read status to false
       * otherwise check whether the message id is same or not. If it is, set read status to true.
       *
       * The first check is done cause when the user sends a message, we are predicting success and pushing the message in the frontend.
       * Once the server updates the latest message, the last message id is updated. If we don't check for the address, then it will be
       * taken as a new message even though the sender only sent the message.
       */
      // console.log(prevReadMessageId);
      // console.log(notifications.value[notifications.value.length - 1].id);

      // console.log(
      //   "prev read",
      //   prevReadMessageId,
      //   "notifs",
      //   notifications.value
      // );

      if (
        prevReadMessageId &&
        notifications.value.length > 0 &&
        prevReadMessageId ===
          notifications.value[notifications.value.length - 1].id
      ) {
        store.commit("notifications/setReadNotifications", true);
      }
    };

    return {
      pinned,
      notifications,
      bottom,
      message,
      disableForm,
      toggle,
      alreadyReadNotifications,
      toggleNotifications,
      chatInputBox,
      delayedScrollToBottom,
    };
  },
};
</script>
<style lang="scss">
.notifications-container {
  &:hover,
  &.pinned {
    .notifications-area {
      .notification {
        display: block;
        .icon {
          opacity: 0.7;
          &.leveling {
            filter: invert(11%) sepia(77%) saturate(4190%) hue-rotate(1deg)
              brightness(112%) contrast(128%);
          }

          &.skillIncrease {
            filter: invert(11%) sepia(77%) saturate(4190%) hue-rotate(1deg)
              brightness(112%) contrast(128%);
          }

          &.negative {
            filter: invert(51%) sepia(83%) saturate(5976%) hue-rotate(342deg)
              brightness(100%) contrast(101%);
          }

          &.positive {
            filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
              brightness(118%) contrast(119%);
          }

          &.info {
            filter: invert(37%) sepia(49%) saturate(5005%) hue-rotate(208deg)
              brightness(101%) contrast(96%);
          }
        }
      }
    }
  }
  .notifications-area .notification {
    display: none;
    &:last-child {
      display: block;
      animation: addNotification 1s ease-in-out;
    }
  }
}
@keyframes addNotification {
  0% {
    background-color: none;
  }

  50% {
    background-color: rgba(255, 200, 0, 0.354);
  }
  100% {
    background-color: none;
  }
}
</style>

<style lang="scss" scoped>
.notification-button {
  display: block;
  width: 44px;
  height: 44px;
  position: fixed;
  bottom: 105px;
  left: 20px;
  z-index: 8;
  @media only screen and (max-width: 576px) {
    z-index: 4;
  }
  background: url("https://cdn.dragoncrypto.io/uiassets/notification-button.png")
    no-repeat;
  background-size: cover;
  box-shadow: none;
  border: none;
  opacity: 1;
  &:hover {
    transform: scale(1.2);
    transition: transform 0.2s ease-in-out;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
  }
  span {
    background: #cf0303;
    width: 10px;
    height: 10px;
    display: grid;
    align-items: center;
    color: #fff;
    font-weight: bold;
    border-radius: 100%;
    font-size: 0.7rem;
    position: absolute;
    top: 0;
    right: 5px;
    text-indent: -9999px;
    &.hide {
      display: none;
    }
  }
}
.notifications-container {
  width: 500px;
  max-height: 130px;
  position: fixed;
  z-index: 8;
  background: #000000d6;
  background: linear-gradient(
    0deg,
    #0000001f 0%,
    rgba(0, 0, 0, 0.7161239495798319) 100%
  );
  height: auto;
  display: grid;
  grid-template-rows: 1fr;
  padding: 10px 0;
  transition: all 0.2s ease-in-out;
  left: 50%;
  transform: translateX(-50%);
  top: -100%;

  .pin {
    display: none;
  }
  &:after {
    content: "";
    display: block;
    width: 100%;
    background-image: url("https://cdn.dragoncrypto.io/uiassets/message-bottom.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 30px;
    position: absolute;
    bottom: -29px;
    display: none;
  }
  @media only screen and (max-width: 576px) {
    width: calc(100% - 30px);
    right: 0;
    background: #000000d6;
    height: 50px;
  }
  &.active {
    top: 0;
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 23px;
      position: absolute;
      top: -20px;
    }
    &:hover,
    &.pinned {
      backdrop-filter: blur(2px);
      background-color: #00000096;
      height: 130px;
      top: 15px !important;
      box-shadow: 0 0px 5px 6px #00000054;
      &:after {
        display: block;
      }
      .pin {
        display: block;
        background-image: url("https://cdn.dragoncrypto.io/uiassets/pin.svg");
        background-repeat: no-repeat;
        background-position: 6px center;
        width: auto;
        height: 22px;
        padding-left: 20px;
        position: absolute;
        top: -16px;
        border: 1px solid #515151;
        background-color: #3a3a3a;
        background-size: 9px;
        color: #fff;
        text-align: right;
        font-size: 0.8rem;
        border-radius: 2px;
        left: 50%;
        transform: translateX(-50%);
        cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
          auto;
        &:hover {
          background-color: #555454;
        }
      }
    }
  }
  .close-btn {
    border: none;
    position: absolute;
    top: -15px;
    right: -15px;
  }
  .notifications-box {
    overflow-y: scroll;
    &::-webkit-scrollbar-track {
      /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000000f2;
    }
  }
}
</style>
