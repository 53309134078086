<template>
  <BasicPopup
    v-if="hasCharacters"
    :class="[isDailyQuestVisible, 'daily-quest', isGuideOpen ? 'hide' : '']"
    :header="'Daily Quest'"
  >
    <div class="quest-panel">
      <h3>{{ questTitle }}</h3>
      <div class="description">
        <img
          :src="'https://cdn.dragoncrypto.io/encounters/' + questImage + '.png'"
          :alt="questTitle"
        />
        <p>{{ questDescLine1 }}</p>
        <p v-if="questDescLine2">{{ questDescLine2 }}</p>
      </div>
      <div class="buttons">
        <DialogButton
          @click="$emit('accept')"
          :buttonText="'Accept'"
          :buttonSize="'small'"
        />
        <DialogButton
          @click="$emit('decline')"
          :buttonText="'Decline'"
          :buttonSize="'small'"
        />
      </div>
    </div>
  </BasicPopup>
</template>

<script>
import { useStore } from "vuex";
import BasicPopup from "./Popup/BasicPopup.vue";
import DialogButton from "./DialogButton.vue";

export default {
  name: "DailyQuest",
  components: {
    BasicPopup,
    DialogButton,
  },
  props: {},
  setup() {},
  data() {
    return {};
  },
  mounted() {
    this.$store = useStore();
  },
  computed: {
    isGuideOpen() {
      return this.$store.state.intro.isGuideOpen;
    },
    questTitle() {
      if (!this.$store.state.dailyQuest) {
        return "";
      }

      return this.$store.state.dailyQuest.name;
    },
    hasCharacters() {
      return this.$store.state.characters.length > 0;
    },
    questDescLine1() {
      if (!this.$store.state.dailyQuest) {
        return "";
      }
      return this.$store.state.dailyQuest.descLine1;
    },
    questDescLine2() {
      if (!this.$store.state.dailyQuest) {
        return "";
      }

      return this.$store.state.dailyQuest.descLine2;
    },
    questImage() {
      if (!this.$store.state.dailyQuest) {
        return "";
      }

      return this.$store.state.dailyQuest.imageName;
    },
    isDailyQuestVisible() {
      return this.$store.state.isDailyQuestVisible ? "show" : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.daily-quest {
  max-width: 400px;
  display: none;
  padding-top: 16px;
  &.hide {
    display: none !important;
  }

  h3 {
    font-family: "IM Fell English", sans-serif;
    text-transform: none;
    color: #d79867;
    margin-bottom: 12px;
  }

  &.show {
    display: block;
  }
}

.description {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: row;
  margin-bottom: 36px;
  padding-bottom: 12px;
  border-bottom: 1px solid #d79867;

  img {
    max-width: 64px;
    border: 1px solid #d79867;
    margin: 16px;
  }
}
</style>
