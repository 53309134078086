<template>
  <div
    :class="[
      'character-panel',
      inMarketplace ? 'in-marketplace' : '',
      inCrafting ? 'in-crafting' : '',
    ]"
    @keydown="selectCharacterByKey"
  >
    <template v-if="hasCharacters">
      <Character
        class="character-item"
        v-for="char in currentCharacters"
        :key="char.number"
        :character="char"
      />
    </template>
    <template v-else>
      <BuyCharacter />
    </template>
    <button
      id="select-characters"
      class="select-characters-button"
      :disabled="inBattle"
      @click="toggleCharacterSelect"
    ></button>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";
import { useMixpanel } from "../composables/mixpanel";
import Constants from "../consts/constants";
import Character from "./Character.vue";
import BuyCharacter from "./CharacterComponents/BuyCharacter.vue";

export default {
  name: "CharacterPanel",
  components: {
    Character,
    BuyCharacter,
  },
  props: {},
  setup() {
    const store = useStore();

    onMounted(() => {
      window.addEventListener("keyup", onKeyUp);
    });

    onUnmounted(() => {
      window.removeEventListener("keyup", onKeyUp);
    });

    // Mixpanel Tracking
    const { trackEvent } = useMixpanel();

    const currentCharacters = computed(() => {
      return store.state.characters;
    });
    const hasCharacters = computed(() => {
      return store.state.characters.length > 0;
    });
    const currentCharacter = computed(() => {
      return store.state.characters[store.state.currentCharacter];
    });

    const inBattle = computed(() => {
      return store.state.gameState == Constants.gamemodes.combat;
    });

    const isWandering = computed(() => {
      return store.state.gameState == Constants.gamemodes.wandering;
    });

    const inMarketplace = computed(() => {
      return store.state.gameState == Constants.gamemodes.marketplace;
    });

    const inCrafting = computed(() => {
      return store.state.gameState == Constants.gamemodes.crafting;
    });

    const isGatheringStart = computed(() => {
      return store.state.gameState == Constants.gamemodes.gatheringStart;
    });

    const isGathering = computed(() => {
      return store.state.gameState == Constants.gamemodes.gathering;
    });

    const isTurnHappening = computed(() => {
      if (
        store.state.isTurnHappening ||
        store.state.isUsingConsumable ||
        store.state.isFleeing ||
        store.state.combat.isHeroAttacking ||
        store.state.combat.isEnemyAttacking ||
        store.state.combat.isPetAttacking
      ) {
        return true;
      } else {
        return false;
      }
    });

    const toggleCharacterSelect = () => {
      store.commit("characterSelect/openSelectionScreen", true);

      // Mixpanel tracking when character select is clicked and char status
      try {
        trackEvent("Character Select", {
          state: store.state.gameState,
          hero: store.state.characters[store.state.currentCharacter].number,
          hp: store.state.characters[store.state.currentCharacter].stats.hp,
          turns:
            store.state.characters[store.state.currentCharacter].stats.turns,
        });
      } catch (e) {
        console.log(e);
      }
    };

    const selectCharacterByKey = (event) => {
      const key = event.key;
      const charIndex = {
        q: 1,
        w: 2,
        e: 3,
        r: 4,
      }[key.toLowerCase()];
      if (
        charIndex &&
        charIndex < store.state.characterSelect.selectedCharacters.length
      ) {
        const characterId =
          store.state.characterSelect.selectedCharacters[charIndex].id;
        switchCharacterById(characterId);
      }
    };

    const switchCharacterById = async (characterId) => {
      if (isGatheringStart.value || isGathering.value) {
        store.state.doNotification(
          "You can't switch characters while gathering resources!"
        );
        return;
      }

      if (store.state.chat.isChatOpen) {
        return;
      }

      if (isTurnHappening.value) {
        store.state.doNotification(
          "You can't switch characters while a turn is happening!"
        );
        return;
      }

      if (!inBattle.value && !isWandering.value) {
        return;
      }

      if (characterId !== store.state.currentCharacterId) {
        store.commit("setTurnHappening", true);
        if (store.state.gameState !== Constants.gamemodes.combat) {
          const characterIndex =
            store.state.characterSelect.selectedCharacters.findIndex(
              (element) => element.id === characterId
            );

          var newMainChar =
            store.state.characterSelect.selectedCharacters[characterIndex];
          var charBeingReplaced =
            store.state.characterSelect.selectedCharacters[0];

          store.state.characterSelect.selectedCharacters[characterIndex] =
            charBeingReplaced;
          store.state.characterSelect.selectedCharacters[0] = newMainChar;

          localStorage.setItem(
            "selectedCharacters",
            JSON.stringify(store.state.characterSelect.selectedCharacters, [
              "id",
            ])
          );

          const character = store.state.characterSelect.selectedCharacters.find(
            (element) => element.id === characterId
          );

          store.commit("setCurrentCharacterByNumber", character.number);
          store.commit("setCurrentCharacter", characterId);
        } else {
          store.commit("setCurrentCharacterCombat", characterId);
        }
        setTimeout(() => {
          store.commit("setTurnHappening", false);
        }, 100);
      }
    };

    const onKeyUp = (event) => {
      if (
        event.code === "KeyQ" ||
        event.code === "KeyW" ||
        event.code === "KeyE" ||
        event.code === "KeyR"
      ) {
        selectCharacterByKey(event);
      }
    };

    return {
      //Computed
      currentCharacters,
      hasCharacters,
      currentCharacter,
      inBattle,
      inMarketplace,
      inCrafting,
      isGathering,
      isGatheringStart,
      //Methods
      toggleCharacterSelect,
      selectCharacterByKey,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";
.character-panel {
  position: fixed;
  top: 10px;
  left: 6px;
  padding-top: 90px;
  padding-left: 0;
  text-align: center;
  width: 300px;
  z-index: 6;

  .main {
    position: fixed;
    top: 12px;
    left: 6px;
  }
  button.select-characters-button {
    display: inline-block;
    width: 47px;
    height: 47px;
    border-radius: 100%;
    position: relative;
    background: #474a4a;
    margin: 0 2px;
    top: -22px;
    left: 0;
    border: 3px #322922 solid;
    &:nth-child(1) {
      top: 20px;
      left: 20px;
      position: absolute;
    }
    &:nth-child(2) {
      top: 0;
    }
    &:after {
      content: "...";
      display: block;
      position: absolute;
      top: 27%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2rem;
      line-height: 0;
      color: #ffffff69;
    }
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
  }
}
@media only screen and (max-width: 576px) {
  .character-panel {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    &.in-marketplace,
    &.in-crafting {
      display: none;
    }
  }
}
</style>
