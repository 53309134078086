export const updates = [
  {
    updateTime: "August 2023",
    updates: [
      {
        category: "Introducing Staking and Copper",
        list: [
          "🔒 Players locking DCAR can now receive triple rewards in the form of DCAU, DCAR and COPPER.",
          "🔓 Players locking DCAR can now receive instant Copper rewards.",
          "🔁 Copper can now be used to reroll Defender and Crafter Quests.",
          "⭐️ Future utilities of Copper coming in game.",
        ],
      },
      {
        category: "Guild Enhancements",
        list: [
          "🏰 Players can now access the Defender's Guild or Crafter's Guild by clicking on the icon near the character panel itself, providing quick access to guilds.",
        ],
      },
      {
        category: "Inventory and Equipment Improvements",
        list: [
          "🎒 Added the ability to filter items in the inventory based on the clicked slot in the CharacterEquipped screen. For example, clicking on a helmet will only show non-broken helmet items in the adjacent inventory window. Additionally, the CharacterEquipped component now conditionally mounts and is not present all the time.",
          "👕 An un-equip all button has been added to the CharacterEquipped component. This allows players to unequip all items only if the player isn't in a dying state. (corresponding hero-api PR is also open).",
          "⚔️ Added secondary buttons for equipping weapons. One button equips to the left, and the other equips to the right. Two-handed weapons now automatically show only one button.",
          "⚡ Made fighting animations quicker.",
        ],
      },
      {
        category: "Communication Enhancements",
        list: [
          "💬 Introduced in-game Discord chat.",
          "🔔 An Update List pop-up (change log) has been added. It will only appear once for every player. You can control it using the updateVer variable in constants.js. Whenever the value of updateVer changes, the update pop-up will be displayed. We can conveniently copy and paste Discord updates here.",
        ],
      },
      {
        category: "Character Selection and Views",
        list: [
          "🎭 Added toggle view for character selection. The character select screen can now switch between slider and grid views. The slider is suitable for players with fewer heroes, while the grid view is ideal for quickly browsing through a large collection and selecting different heroes. A 'Clear All Selection' button has also been added.",
        ],
      },
      {
        category: "Item and Rarity Adjustments",
        list: [
          "🌟 New unique items can now only be looted by players at level 90 and above. These items are better than Fabled items and will never have a quality below superior.",
          "💎 Fabled items can only be found at levels 60 and above.",
          "🔮 Mythical items now drop at levels 30 and above.",
          "🛡️ On higher rarity items, players now have a higher chance of obtaining better quality items (e.g., Superior, Enchanted, etc.).",
        ],
      },
      {
        category: "Gameplay and Balancing Changes",
        list: [
          "🐉 Updated the names of some mobs.",
          "🪓 Adjusted the maximum amount of resources harvested for certain common resources.",
          "⚖️ Tweaked the weighting of items for the Crafter's Guild to reduce the likelihood of obtaining stone.",
          "🎯 Introduced missing shots to combat mechanics: the worse your speed is compared to your enemy, the higher the chance of missing.",
          "🔧 Equipment items now permanently break based on rarity and durability upgrades. Ranging from Shoddy with a 30% break chance to Artefact with 0.1% break chance.",
        ],
      },
      {
        category: "Quests and Rewards",
        list: [
          "🔁 Copper can now be used to reroll Defender and Crafter Quests.",
          "🔓 Players locking DCAR can now receive instant Copper rewards. The reward percentage ranges from 0.2% to 2.4% based on the length of the lock.",
        ],
      },
      {
        category: "Bug Fixes and Miscellaneous",
        list: [
          "🐲 Fixed an issue where raid bosses were not being counted after certain wins.",
          "🏞️ Increased the amount of resources given for higher-level quests.",
          "🏅 Decreased rewards for Defender Guilds based on quest type. Finding artifacts should not reward the same amount as killing a raid boss.",
          "📈 Updated how the quantity of resources is calculated based on level instead of daily quantity.",
          "🛠️ Decreased the number of Defenders Guild Equipment Quests.",
          "🍀 Increased luck on blueprint generation for Crafter's Guild Quests.",
          "💼 The staking contract is now tracked instead of DCAR snapshot for bonus rewards.",
          "🛡️ Introduced equipment item caps. For example, Shoddy level 1 has a general cap of 6 for all stat categories, and Fabled - level 90 has a general cap of 60.",
          "🚫 Limited average player level to a maximum of 80 for bonus rewards.",
          "🐞 Fixed a bug where heroStats were not being passed to the frontend from the contractQuest endpoint.",
          "🛠️ Addressed various issues with the resource burn API endpoint. Improved user error messages when the sync event takes longer than expected to finish.",
          "⚔️ Fixed an issue where multiple kill events were being counted simultaneously instead of one at a time.",
          "🛡️ Adjusted the equipment caps for Normal and Common items to be identical instead of incrementing upwards.",
          "👀 Carefully examined the average player level of 80 to ensure it's not significantly reducing the required DCAR amount.",
        ],
      },
    ],
  },
];

// export const updates = [
//   {
//     updateTime: "August, 2023",
//     updates: [
//       "Now users can open Defender's Guild or Crafter's Guild by clicking on the icon near the character panel itself. Quick access to guilds",
//       "Update List pop up (change log). Only shows once to every player. Can be controlled using the updateVer variable in constants.js. Whenever we change the value of updateVer, the update popup will be displayed. We can just copy paste discord updates here.",
//       "Added ability to filter items in inventory based on the slot clicked in CharacterEquipped screen. Ex: clicking on helmet will only show, non broken, helmet items in the adjacent inventory window. Also made the CharacterEquipped component conditionally mount and not be present all the time.",
//       "Added number formatting to guild quest widget. It shows as k in terms of thousands.",
//       "Added an un-equip all button in CharacterEquipped component. This allows the player to unequip all items only if the player isn't dying. (corresponding hero-api PR also open).",
//       "Added secondary button for equipping weapons. One button equips to left and the other equips to the right. Two-handed weapons automatically show only one button.",
//       "Made fighting animations quicker.",
//       "Added in-game discord chat.",
//       "Added toggle view for character select. Now character select screen can be toggled between slider and a grid view. Slider is good for players with less quantity of heroes whereas grid view is good for players who quickly want to browse through their large collection and select different heroes. Added clear all selection button.",
//       "New unique items can only be looted by level 90's and above; they are better than Fabled and will never quality below superior.",
//       "Fabled can only be found at levels 60 and above.",
//       "Mythical only drops at levels 30 and above.",
//       "On higher rarity items, you are more likely to get a better quality (e.g., Superior, Enchanted, etc.).",
//       "Updated some mob names.",
//       "Changed the max amount harvested for some common resources.",
//       "Changed the weighting on items for the Crafter's Guild so it's less likely to be stone.",
//       "Adding missing shots to combat: the worse speed you have compared to your enemy, the higher chance of missing.",
//       "Equipment items permanently break based on the rarity and durability upgrade. Shoddy 30% all the way to Artefact with 0.1. I will probably adjust to break more often",
//       "Copper can now reroll Defenders and Crafter Quests",
//       "Instant Copper rewards for locking DCAR. 0.2% - 2.4% based on length of lock.",
//       "Fixed an issue where raid bosses were not counting after certain wins",
//       "Increased resources given to higher level quests",
//       "Decreased rewards for defender guilds based on quest type. Finding artefacts should not reward the same amount as killing a raid boss.",
//       "Updated how the quantity of resources are calculated based on level instead of day quantity",
//       "Decreased amount of Defenders Guild Equipment Quest",
//       "Increased luck on blueprint generation for Crafter's Guild Quests",
//       "Staking contract is now tracked instead of DCAR snapshot for bonus rewards",
//       "Equipment item caps have been introduced. Example: Shoddy level 1, general cap of 6 for all stat categories.  Fabled - level 90 general cap 60",
//       "Limit average player level to a max of 80 for bonus rewards",
//       "Fixed a bug where heroStats were not being passed to the frontend from the contractQuest endpoint",
//       "Fixed various issues with resource burn api endpoint moving remove signature to bottom of sync event, provided better user error message when sync event is taking longer than expected to finish.",
//       "Fixed an issue where multiple kill events were counting at once instead of one at a time.",
//       "the equipment caps Normal and Common should be identical instead of incrementing up",
//       "Look at the average player level of 80 to make sure it's not decreasing required dcar amount too drastically.",
//     ],
//   },
// ];
