<template>
  <div class="map-bg">
    <div class="viewport">
      <div :class="[timeOfDay, 'world-map']" data-dragscroll>
        <video class="night" autoplay muted loop>
          <source
            src="https://cdn.dragoncrypto.io/map/Dcg%20Map%20Animated%20Night%20V1_4K.m4v"
            type="video/mp4"
          />
        </video>
        <video class="day" :style="{ opacity: dayOpacity }" autoplay muted loop>
          <source
            src="https://cdn.dragoncrypto.io/map/Dcg%20Map%20Animated%20Day%20V1_4K.m4v"
            type="video/mp4"
          />
        </video>
      </div>
      <!-- <img
        src="https://cdn.dragoncrypto.io/map/worldmap-test.jpeg"
        alt="The World of Aurum Draconis"
        class="world-map"
      /> -->
      <WorldMapLocation
        id="search-the-plains"
        @clickLocation="$emit('search')"
        :locationName="'Search the Plains'"
        :requiresTurnNotHappening="true"
        :requiresTurns="true"
        :mapX="600"
        :mapY="500"
      />
      <WorldMapLocation
        id="healers-hut"
        @clickLocation="$emit('healers')"
        :locationName="'Healer\'s Hut'"
        :requiresTurnNotHappening="true"
        :canUseDead="true"
        :mapX="990"
        :mapY="200"
      />
      <WorldMapLocation
        id="bank"
        @clickLocation="$emit('bank')"
        :locationName="'Grom\'s Bank'"
        :requiresTurnNotHappening="true"
        :canUseDead="true"
        :mapX="1900"
        :mapY="250"
      />
      <WorldMapLocation
        id="forge"
        @clickLocation="$emit('forge')"
        :locationName="'The Forge'"
        :requiresTurnNotHappening="true"
        :requiresTurns="true"
        :mapX="1690"
        :mapY="940"
      />
      <WorldMapLocation
        id="inn"
        @clickLocation="$emit('inn')"
        :locationName="'Wild Boar Inn'"
        :requiresTurnNotHappening="true"
        :mapX="1920"
        :mapY="495"
      />
      <WorldMapLocation
        id="marketplace"
        @clickLocation="$emit('marketplace')"
        :locationName="'Marketplace'"
        :requiresTurnNotHappening="true"
        :canUseDead="true"
        :mapX="1560"
        :mapY="695"
      />
      <WorldMapLocation
        id="mines"
        @clickLocation="$emit('mines')"
        :locationName="'Search The Mines'"
        :requiresItemEquippedName="['pickaxe']"
        :requiresTurnNotHappening="true"
        :requiresTurns="true"
        :mapX="420"
        :mapY="260"
      />
      <WorldMapLocation
        id="deepwood"
        @clickLocation="$emit('deepwood')"
        :locationName="'Deepwood'"
        :requiresItemEquippedName="['axe', 'hatchet']"
        :requiresTurnNotHappening="true"
        :requiresTurns="true"
        :mapX="1200"
        :mapY="50"
      />
      <!-- TODO: Put the below code back in above -->
      <!--:requiresItemEquippedName="'pickaxe'" -->
      <WorldMapLocation
        :locationName="'Deadlands - Closed'"
        :requiresTurns="true"
        :mapX="0"
        :mapY="700"
      />
      <WorldMapLocation
        id="crafters-guild"
        @clickLocation="$emit('crafters-guild')"
        :requiresTurnNotHappening="true"
        :requiresTurns="false"
        :locationName="'Crafter\'s Guild'"
        :mapX="2400"
        :mapY="165"
      />
      <WorldMapLocation
        id="defenders-guild"
        @clickLocation="$emit('defenders-guild')"
        :requires-turn-not-happening="true"
        :requiresTurns="false"
        :locationName="'Defender\'s Guild'"
        :mapX="1500"
        :mapY="400"
      />
      <WorldMapLocation
        id="your-stash"
        @clickLocation="$emit('your-stash')"
        :requiresTurnNotHappening="true"
        :locationName="'Your Stash'"
        :mapX="2065"
        :mapY="125"
      />
      <Death />
    </div>
  </div>
</template>

<script>
import WorldMapLocation from "./WorldMapLocation.vue";
import { useStore } from "vuex";
import Constants from "../consts/constants";
import Death from "../components/Death.vue";

export default {
  name: "WorldMap",
  components: { WorldMapLocation, Death },
  emits: [
    "search",
    "healers",
    "bank",
    "forge",
    "inn",
    "marketplace",
    "mines",
    "deepwood",
    "crafters-guild",
    "your-stash",
    "defenders-guild",
  ],
  data() {
    return {
      currentX: 0,
      currentY: 0,
      dayOpacity: 1.0,
      $store: {},
      timeOfDay: "",
    };
  },
  methods: {
    move(isX, amount) {
      if (this.$store.state.gameState == Constants.gamemodes.wandering) {
        if (isX) {
          if (this.currentX + amount <= 0) this.currentX += amount;
        } else {
          if (this.currentY + amount <= 0) this.currentY += amount;
        }
      }
    },
  },
  computed: {
    currentPositionX() {
      return this.currentX + "px";
    },
    currentPositionY() {
      return this.currentY + "px";
    },
  },
  mounted() {
    this.$store = useStore();

    let gameTick = this.$store.state.gameTick;

    setInterval(() => {
      let gameMinutes = this.$store.state.gameMinutes;
      let gameHours = this.$store.state.gameHours;

      const isDusk = gameHours == 18;
      const isDawn = gameHours == 6;

      const isDay = gameHours > 6 && gameHours < 18;
      const isNight = gameHours < 6 || gameHours > 18;

      if (isDay) {
        this.dayOpacity = 1.0;
        this.timeOfDay = "day";
        this.$store.commit("setTimeOfDay", "day");
      } else if (isNight) {
        this.dayOpacity = 0.0;
        this.timeOfDay = "night";
        this.$store.commit("setTimeOfDay", "night");
      } else if (isDusk) {
        this.dayOpacity = 1.0 - (1.0 / 59) * gameMinutes;
      } else if (isDawn) {
        this.dayOpacity = (1.0 / 59) * gameMinutes;
      }
      // }

      // if (isDusk) {
      //   this.dayOpacity = 1.0 - (1.0 / 59) * gameMinutes;
      // }

      // if (isDawn) {
      //   this.dayOpacity = (1.0 / 59) * gameMinutes;
      // }

      gameMinutes++;

      if (gameMinutes === 60) {
        gameHours++;

        if (gameHours === 24) {
          gameHours = 0;
        }

        this.$store.commit("setGameHours", gameHours);
        gameMinutes = 0;
      }

      this.$store.commit("setGameMinutes", gameMinutes);
      // console.log(gameHours, gameMinutes);
    }, gameTick);
  },
};
</script>

<style lang="scss">
#healers-hut,
#bank,
#marketplace {
  z-index: 2;
}
</style>
<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

.map-bg {
  width: 100vh;
  height: 100vh;
  position: relative;
  user-select: none;

  .viewport {
    position: absolute;
    /* top: v-bind(currentPositionY); */
    /* left: v-bind(currentPositionX); */
    top: 0;
    left: 0;
    transition: all 0.35s ease-out;
    z-index: 0;
  }
  .world-map {
    width: 5142px;
    height: 2160px;
    background-size: 100%;
    &.day {
      background-image: url("https://cdn.dragoncrypto.io/map/map-day-static.jpg");
    }
    &.night {
      background-image: url("https://cdn.dragoncrypto.io/map/map-night-static.jpg");
    }
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
    .day {
      transition: opacity 0.55s ease-out;
    }
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 5142px;
      height: 2160px;
    }
  }
  img {
    max-height: 2160px;
  }
  /* &:after {
    content: "";
    display: block;
    width: 69px;
    height: 59px;
    position: absolute;
    top: 0px;
    left: 1521px;
    background: url("https://cdn.dragoncrypto.io/map/waterfall-loop.png") repeat-y bottom center;
    background-size: 100%;
    animation: waterfall 5s infinite linear;
  } */
  /* &:before {
  content: "";
  display: block;
  width: 97px;
  height: 194px;
  position: absolute;
  top: 200px;
  left: 1521px;
  background: url("https://cdn.dragoncrypto.io/map/tree-1.png") no-repeat center center;
  background-size: 100%;
  top: 668px;
  left: 1211px;
  z-index: 1;
  animation: sway1 5s ease-in-out forwards infinite;
  transform-origin: bottom center;
} */
  @keyframes waterfall {
    from {
      background-position: 0 0;
    }
    to {
      background-position: 0 1112px;
    }
  }
  @keyframes sway1 {
    0%,
    100% {
      transform: rotate(-1deg);
    }
    50% {
      transform: rotate(1deg);
    }
  }
}
</style>
