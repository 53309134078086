<template>
  <div class="dashboard">
    <img
      class="logo"
      src="@/assets/legend-of-aurum-draconis-smaller.png"
      alt="legend of aurum draconis"
    />
    <h2>Dashboard</h2>
    <ConnectButton id="connect-button" size="small" />

    <GenericLoading v-if="isLoading" />
    <div v-else class="data">
      <template v-if="isVerified">
        <div :class="['avax', avaxBalance < 1.5 ? 'red' : ' green']">
          {{ formatNumber(avaxBalance.toFixed(2)) }}
          <span>AVAX in Treasury</span>
        </div>
        <div :class="isRPCOnline ? 'on' : 'off'">
          {{ isRPCOnline ? "Online" : "Offline" }}
          <span>RPC Status</span>
        </div>
        <div :class="isWsRPCOnline ? 'on' : 'off'">
          {{ isWsRPCOnline ? "Online" : "Offline" }}
          <span>WS RPC Status</span>
        </div>
        <div :class="['sessions', liveSessions > 0 ? 'green' : 'red']">
          {{ formatNumber(liveSessions) }}
          <span>Live Sessions</span>
        </div>
        <div
          :class="['dcau-change', DCAUTreasuryProfit < 0 ? 'red' : ' green']"
        >
          {{ DCAUTreasuryProfit.toFixed(2) }}%
          <span>DCAU Treasury Change</span>
        </div>
        <div
          :class="['dcar-change', DCARTreasuryProfit < 0 ? 'red' : ' green']"
        >
          {{ DCARTreasuryProfit.toFixed(2) }}%
          <span>DCAR Treasury Change</span>
        </div>
      </template>
      <div class="dcau">
        {{ formatNumber(DCAUTreasury.toFixed(2)) }}
        <span v-if="!isLoadingDollarPrice" class="dollar-value"
          >${{ formatNumber(DCAUTreasuryDollar) }}</span
        >
        <SkeletalLoading v-else width="40" />
        <span>DCAU in Treasury</span>
      </div>
      <div class="dcar">
        {{ formatNumber(DCARTreasury.toFixed(2)) }}
        <span v-if="!isLoadingDollarPrice" class="dollar-value"
          >${{ formatNumber(DCARTreasuryDollar) }}</span
        >
        <SkeletalLoading v-else width="40" />
        <span>DCAR in Treasury</span>
      </div>
      <div class="dcau">
        {{ formatNumber(DCAUBurned.toFixed(2)) }}

        <span v-if="!isLoadingDollarPrice" class="dollar-value"
          >${{ formatNumber(DCAUBurnedDollar) }}</span
        >
        <SkeletalLoading v-else width="40" />

        <span>DCAU Burned</span>
      </div>
      <div class="dcar">
        {{ formatNumber(DCARBurned.toFixed(2)) }}

        <span v-if="!isLoadingDollarPrice" class="dollar-value"
          >${{ formatNumber(DCARBurnedDollar) }}</span
        >
        <SkeletalLoading v-else width="40" />

        <span>DCAR Burned</span>
      </div>
      <div>
        {{ formatNumber(totalHeroesMinted) }} <span>Total Heroes Minted</span>
      </div>
      <div>
        {{ formatNumber(totalEquipmentMinted) }}
        <span>Total Equipment Minted </span>
      </div>
      <!-- <div>{{ totalResourcesMinted }} <span>Total Resources Minted</span></div>
      <div>
        {{ totalConsumablesMinted }} <span>Total Consumables Minted</span>
      </div> -->
      <div>
        {{ formatNumber(consumableBurnCount) }}
        <span>Total Consumables Burned</span>
      </div>
      <div>
        {{ formatNumber(totalItemsListedInMarketplace) }}
        <span>Items Listed In Marketplace</span>
      </div>
      <div>
        {{ formatNumber(totalItemsTraderInMarketplace) }}
        <span>Total Items Traded In Marketplace</span>
      </div>
      <div class="dcau">
        {{ formatNumber(totalMarketplaceVolumeDcau.toFixed(2)) }}
        <span v-if="!isLoadingDollarPrice" class="dollar-value"
          >${{ formatNumber(totalMarketplaceVolumeDcauDollar) }}</span
        >
        <SkeletalLoading v-else width="40" />
        <span>Total DCAU Marketplace Volume</span>
      </div>
      <div class="dcar">
        {{ formatNumber(totalMarketplaceVolumeDcar.toFixed(2)) }}
        <span v-if="!isLoadingDollarPrice" class="dollar-value"
          >${{ formatNumber(totalMarketplaceVolumeDcarDollar) }}</span
        >
        <SkeletalLoading v-else width="40" />
        <span>Total DCAR Marketplace Volume</span>
      </div>
      <div>
        {{ formatNumber(totalEncounters) }}
        <span>Total Battles</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import ConnectButton from "../components/Buttons/ConnectButton.vue";
import GenericLoading from "../components/LoadingComponents/GenericLoading.vue";
import SkeletalLoading from "../components/LoadingComponents/SkeletalLoading.vue";
import { usePrice } from "../composables/price";
import { useUser } from "../composables/user";
import Constants from "../consts/constants";

import {
  getDCARContract,
  getDCAUContract,
  getDCGEquipmentContract,
  // getDCGConsumablesContract,
  // getDCGResourcesContract,
  getDCGHeroesContract,
  getLoadMarketplaceContract,
  getLoadRewardHandlerContract,
} from "../utils/getContract";

export default {
  components: { GenericLoading, ConnectButton, SkeletalLoading },
  setup() {
    const {
      getDCAUPricePerDollarViaProvider,
      getDCARPricePerDollarViaProvider,
    } = usePrice();
    const store = useStore();
    const isVerified = ref(false);
    const isConnected = ref(false);
    const isRPCOnline = ref(false);
    const isWsRPCOnline = ref(false);
    const { address } = useUser();
    const isLoading = ref(true);
    const isLoadingDollarPrice = ref(true);
    const account = ref(null);
    const DCARTreasury = ref(0);
    const DCARTreasuryProfit = ref(0);
    const DCARTreasuryDollar = ref(0);
    const DCAUTreasury = ref(0);
    const DCAUTreasuryProfit = ref(0);
    const DCAUTreasuryDollar = ref(0);

    const DCARBurned = ref(0);
    const DCARBurnedDollar = ref(0);

    const DCAUBurned = ref(0);
    const DCAUBurnedDollar = ref(0);
    const totalEquipmentMinted = ref(0);
    // const totalConsumablesMinted = ref(0);
    // const totalResourcesMinted = ref(0);
    const consumableBurnCount = ref(0);
    const totalHeroesMinted = ref(0);
    const totalItemsListedInMarketplace = ref(0);
    const totalItemsTraderInMarketplace = ref(0);
    const totalEncounters = ref(0);
    const totalMarketplaceVolumeDcau = ref(0);
    const totalMarketplaceVolumeDcauDollar = ref(0);
    const totalMarketplaceVolumeDcar = ref(0);
    const totalMarketplaceVolumeDcarDollar = ref(0);
    const liveSessions = ref(0);
    const avaxBalance = ref(0);
    const syncTimer = ref(null);
    const ws = new WebSocket(Constants.wsRpcUrl);
    const whitelisted = [
      "0xea8261Abb433f9460Da8d53b9F27698114BFECb0".toLowerCase(), // Sam
      "0x34d4075D46291e0B5C673cC58a96a6602e94ce08".toLowerCase(), // Polypup
      "0x4822Bb150527cFCc7f53a201517284a124Ffd621".toLowerCase(), // Neo
      "0xF6a45E07Ed53C988E9aCa441bB9F73Fb36C4579a".toLowerCase(), // Paul
      "0x2577739a40FB5a673D445326b671691933922c6B".toLowerCase(), // Heat
      "0x7B4D5B9fd327866d0EdCC7CD324e10E61626d546".toLowerCase(), // Glen
    ];
    const initialDCARTreasury = 176931.698;
    const initialDCAUTreasury = 10874.321;

    // Contracts
    const rpcProvider = new ethers.providers.JsonRpcProvider(Constants.rpcUrl);
    let RewardHandlerContract = null;
    let DCARContract = null;
    let DCAUContract = null;
    let EquipmentContract = null;
    // let ConsumablesContract = null;
    // let ResourcesContract = null;
    let HeroesContract = null;
    let MarketplaceContract = null;

    onMounted(async () => {
      await loadContracts(rpcProvider);
      await fetchAnalyticsData();
      isLoading.value = false;
      await updateCurrentPriceDollar();
    });

    onUnmounted(async () => {
      clearTimeout(syncTimer.value);
      ws.removeEventListener("open");
      ws.removeEventListener("close");
      ws.removeEventListener("error");
      unWatchSessionActive();
    });

    const unWatchSessionActive = store.watch(
      () => store.state.isSessionActive,
      async (isSessionActive) => {
        if (isSessionActive) {
          validateAddress(address.value);
        }
      }
    );

    function formatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    // Helper method to delay stuff
    const delay = async (time) => new Promise((res) => setTimeout(res, time));
    const updateCurrentPriceDollar = async () => {
      isLoadingDollarPrice.value = true;
      DCAUBurnedDollar.value = await getDCAUPriceInDollar(DCAUBurned.value);
      DCARBurnedDollar.value = await getDCARPriceInDollar(DCARBurned.value);
      totalMarketplaceVolumeDcauDollar.value = await getDCAUPriceInDollar(
        totalMarketplaceVolumeDcau.value
      );
      totalMarketplaceVolumeDcarDollar.value = await getDCARPriceInDollar(
        totalMarketplaceVolumeDcar.value
      );
      DCARTreasuryDollar.value = await getDCARPriceInDollar(DCARTreasury.value);
      DCAUTreasuryDollar.value = await getDCAUPriceInDollar(DCAUTreasury.value);
      isLoadingDollarPrice.value = false;
    };
    const getDCAUPriceInDollar = async (amount) => {
      await delay(250);
      const dcauPerDollar = await getDCAUPricePerDollarViaProvider(rpcProvider);
      return (parseFloat(amount) / dcauPerDollar).toFixed(2);
    };
    const getDCARPriceInDollar = async (amount) => {
      await delay(250);
      const dcarPerDollar = await getDCARPricePerDollarViaProvider(rpcProvider);

      return (parseFloat(amount) / dcarPerDollar).toFixed(2);
    };

    const loadContracts = async (provider) => {
      try {
        RewardHandlerContract = getLoadRewardHandlerContract(provider);
        DCARContract = getDCARContract(provider);
        DCAUContract = getDCAUContract(provider);
        HeroesContract = getDCGHeroesContract(provider);
        EquipmentContract = getDCGEquipmentContract(provider);
        // ResourcesContract = getDCGResourcesContract(provider);
        // ConsumablesContract = getDCGConsumablesContract(provider);
        MarketplaceContract = getLoadMarketplaceContract(provider);

        // Getting Treasury Values

        const treasuryAddress = await RewardHandlerContract.TREASURY();
        DCARTreasury.value = parseFloat(
          ethers.utils.formatEther(
            await DCARContract.balanceOf(treasuryAddress)
          )
        );
        DCAUTreasury.value = parseFloat(
          ethers.utils.formatEther(
            await DCAUContract.balanceOf(treasuryAddress)
          )
        );
        DCAUTreasuryProfit.value = calculateProfitLoss(
          initialDCAUTreasury,
          DCAUTreasury.value
        );
        DCARTreasuryProfit.value = calculateProfitLoss(
          initialDCARTreasury,
          DCARTreasury.value
        );

        avaxBalance.value = parseFloat(
          ethers.utils.formatEther(
            await rpcProvider.getBalance(treasuryAddress)
          )
        );
        // Getting Burned Values
        const burnAddress = await RewardHandlerContract.BURN_ADDRESS();
        DCARBurned.value = parseFloat(
          ethers.utils.formatEther(await DCARContract.balanceOf(burnAddress))
        );
        DCAUBurned.value = parseFloat(
          ethers.utils.formatEther(await DCAUContract.balanceOf(burnAddress))
        );

        // Getting NFT Values

        totalEquipmentMinted.value = await EquipmentContract.totalSupply();
        totalHeroesMinted.value = await HeroesContract.totalSupply();
        // totalConsumablesMinted.value = await ConsumablesContract.totalSupply();
        // totalResourcesMinted.value = await ResourcesContract.totalSupply();

        // Get Marketplac Data
        totalItemsListedInMarketplace.value =
          await MarketplaceContract.onSaleNftAmount();
        totalItemsTraderInMarketplace.value =
          await MarketplaceContract.getSaleNFTLength();

        // console.log(treasuryAddress);
        // console.log(RewardHandlerContract, DCARContract, DCAUContract);
      } catch (error) {
        console.log(error);
      }
    };

    function calculateProfitLoss(previousValue, currentValue) {
      const change = currentValue - previousValue;
      const percentChange = (change / previousValue) * 100;
      return percentChange;
    }

    const checkRPCStatus = async () => {
      console.log(ws);
      isWsRPCOnline.value = ws.readyState === 1;
      isRPCOnline.value = await checkStatus(Constants.rpcUrl);
      ws.addEventListener("open", () => {
        isWsRPCOnline.value = true;
      });
      ws.addEventListener("close", () => {
        isWsRPCOnline.value = false;
      });
      ws.addEventListener("error", () => {
        isWsRPCOnline.value = false;
      });
      syncTimer.value = setInterval(async () => {
        try {
          console.log("Checking Status");
          isWsRPCOnline.value = ws.readyState === 1;
          isRPCOnline.value = await checkStatus(Constants.rpcUrl);
        } catch (error) {
          console.log(error);
        }
      }, 10000);
    };

    const fetchAnalyticsData = async () => {
      const response = await fetch(Constants.apiUrl + "game-data", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      if (result) {
        consumableBurnCount.value = result.consumableBurnCount;
        totalEncounters.value = result.totalEncounters;
        totalMarketplaceVolumeDcau.value = result.totalMarketplaceVolumeDcau;
        totalMarketplaceVolumeDcar.value = result.totalMarketplaceVolumeDcar;
        liveSessions.value = result.sessions;
      }
    };

    const checkStatus = async (url) => {
      try {
        const response = await fetch(url);
        if (response.status === 200 || response.status === 405) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
      }
    };

    const connect = async () => {
      if (window.ethereum) {
        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          if (accounts.length > 0) {
            isConnected.value = true;
            account.value = accounts[0];
            validateAddress(account.value);
            if (isVerified.value) {
              await checkRPCStatus();
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    const validateAddress = async (address) => {
      if (whitelisted.includes(address.toLowerCase())) {
        isVerified.value = true;
      } else {
        isVerified.value = false;
      }
    };

    // const DCAUContract = getDCAUContract(provider);
    // const HealersHutContract = getLoadHealersHutContract(provider);

    // const isConnected = computed(() => {
    //   if (account.value !== null) return true;
    //   else return false;
    // });

    // const accountConnected = computed(() => {
    //   console.log(store);
    //   const address = store.state.account;
    //   if (!address) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // });

    const isSessionActive = computed(() => {
      return store.state.isSessionActive;
    });

    return {
      // Data
      account,
      DCARTreasury,
      DCARTreasuryDollar,
      DCAUTreasury,
      DCAUTreasuryDollar,
      DCAUBurned,
      DCAUBurnedDollar,
      DCARBurned,
      DCARBurnedDollar,
      totalEquipmentMinted,
      isLoading,
      isVerified,
      avaxBalance,
      isRPCOnline,
      isWsRPCOnline,
      isConnected,
      isLoadingDollarPrice,
      liveSessions,
      // totalConsumablesMinted,
      // totalResourcesMinted,
      totalHeroesMinted,
      totalItemsListedInMarketplace,
      totalItemsTraderInMarketplace,
      consumableBurnCount,
      totalEncounters,
      totalMarketplaceVolumeDcau,
      totalMarketplaceVolumeDcauDollar,
      totalMarketplaceVolumeDcar,
      totalMarketplaceVolumeDcarDollar,
      DCAUTreasuryProfit,
      DCARTreasuryProfit,
      // Methods
      connect,
      formatNumber,
      //Computed
      isSessionActive,
    };
  },
};
</script>
<style lang="scss">
#connect-button {
  position: absolute;
  top: 20px;
  right: 20px;
}
.dashboard.hero {
  display: none;
}
.connect-button {
  position: absolute !important;
  top: 25px;
  right: 25px;
}
footer.dashboard {
  display: none;
}
</style>
<style lang="scss" scoped>
.logo {
  width: 300px;
  margin: 2rem 0 0;
}
.dashboard {
  h2 {
    margin-top: 0;
  }
}
.data {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  width: 98;
  margin: 0 auto;
  div {
    font-size: 4rem;
    color: #fff;
    padding: 2rem 0;
    background: #ffffff0f;
    border: 2px #1c2023 solid;
    &.red,
    &.off {
      background: #8e0000;
      &:hover {
        background: #a00101;
      }
    }
    &.on,
    &.green {
      background: #005a00;
      &:hover {
        background: #017201;
      }
    }
    &:hover {
      background: #ffffff1c;
    }
    &.dcau,
    &.dcar {
      span:before {
        background-image: url("../assets/ui/dcau.png");
        width: 40px;
        height: 40px;
        content: "";
        display: inline-block;
        top: 10px;
        background-size: cover;
        position: relative;
        margin-right: 5px;
      }
    }
    &.dcar {
      span:before {
        background-image: url("../assets/ui/dcar.png");
        background-size: cover;
      }
    }
    span {
      display: block;
      font-size: 1.2rem;
      color: #e5c356;
      &.dollar-value {
        &:before {
          display: none;
        }
        color: #ffffff75 !important;
      }
      &.skeleton-box {
        &:before {
          display: none;
        }
        margin: 0 auto;
      }
    }
  }
}
</style>
