<template>
  <div :class="getItemClass" @mouseenter="hovered" @mouseleave="unhovered">
    <div :class="item.rarity.toLowerCase() + ' item-container'" v-if="true">
      <img :id="'equip-item' + item.id" :src="getItemImage" :alt="item.name" />

      <div v-if="item.quantity" class="quantity">
        {{ item.quantity }}
      </div>
    </div>
  </div>
</template>
<script>
// import tippy from 'tippy.js'
import { useStore } from "vuex";

export default {
  name: "EquipmentTile",
  props: {
    item: {},
    types: String,
    isEquipmentSlot: Boolean,
    slotName: String,
    source: String,
    size: String,
    isMarketplace: Boolean,
    isTwoHandedSlot: Boolean,
    isForge: Boolean,
  },
  data() {
    return {
      isHidingItem: false,
      isDraggedOver: false,
      isCorrectDropTarget: false,
      $store: {},
    };
  },
  methods: {
    hovered(e) {
      if (this.item)
        this.$store.commit("setHovered", {
          item: this.item,
          event: e,
          isMarketItem: this.isMarketplace,
        });
    },
    unhovered() {
      if (this.item) this.$store.commit("setUnhovered");
    },
    dragStart() {
      this.$store.commit("setTooltipUnhovered");
      this.$store.commit("setUnhovered");
      const currentStore = this.$store;
      setTimeout(() => {
        this.isHidingItem = true;
        currentStore.commit("setDragging", this.item);
        currentStore.commit("setDragSource", this.source);
        currentStore.commit("setDragFromSlot", this.slotName);
      }, 0);
    },
    dragEnd() {
      this.isHidingItem = false;
      this.$store.commit("setDragging", {});
      this.$store.commit("setDragSource", "");
      this.$store.commit("setDragFromSlot", "");
    },
    dragOver(e) {
      e.preventDefault();
    },
    dragEnter(e) {
      e.preventDefault();
      this.isDraggedOver = true;

      const dragItem = this.$store.state.draggingItem;
      const dragItemStats = dragItem.stats;

      if (dragItem.isBlueprint) {
        this.isCorrectDropTarget = false;
        return;
      }

      if (this.isForge) {
        if (
          this.$store.state.forgeItems.find(
            (f) => f != null && f.id == dragItem.id
          )
        ) {
          this.isCorrectDropTarget = false;
          return;
        }

        this.isCorrectDropTarget =
          dragItem.name.toLowerCase().indexOf("ore") >= 0 ||
          dragItem.name.toLowerCase().indexOf("coal") >= 0;
        return;
      }

      if (dragItem.isTwoHanded) {
        this.isCorrectDropTarget = this.isTwoHandedSlot;
        return;
      }

      if (this.types.indexOf(dragItem.type) >= 0 && !dragItemStats.isBroken) {
        this.isCorrectDropTarget = true;
      } else {
        this.isCorrectDropTarget = false;
      }
    },
    dragLeave() {
      this.isDraggedOver = false;
    },
    dragDrop() {
      if (this.isCorrectDropTarget && this.isForge) {
        this.$store.commit("sendItemToForge", this.$store.state.draggingItem);
      }

      if (this.isCorrectDropTarget && this.isEquipmentSlot) {
        this.$store.commit("equipItem", this.slotName);
      }

      this.isDraggedOver = false;
      this.isHidingItem = false;
    },
  },
  computed: {
    getQuantity() {
      return null;
    },
    getItemClass() {
      return (
        "item" +
        (this.item ? " background" : "") +
        (this.isHidingItem ? " dragging" : "") +
        (this.isDraggedOver ? " drag-over" : "") +
        (this.isDraggedOver && !this.isCorrectDropTarget ? " invalid" : "") +
        (this.size ? " " + this.size : "") +
        (this.isHighlighted ? " highlighted" : "")
      );
    },
    getItemImage() {
      if (!this.item || !this.item.imageName) {
        return "";
      }
      if (this.item.itemType === "blueprint") {
        return (
          "https://ik.imagekit.io/dcg/blueprints/" +
          this.item.imageName +
          "?tr=w-90"
        );
      }
      return (
        "https://ik.imagekit.io/dcg/equip/" + this.item.imageName + "?tr=w-90"
      );
    },
    isHighlighted() {
      if (this.isEquipmentSlot) {
        const dragItem = this.$store.state.draggingItem;

        if (dragItem) {
          const dragItemStats = dragItem.stats;

          return (
            this.types.indexOf(dragItem.type) >= 0 && !dragItemStats.isBroken
          );
        }
      }

      return false;
    },
  },
  mounted() {
    this.$store = useStore();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.item {
  width: 44px;
  height: 44px;
  @media only screen and (max-width: 576px) {
    width: 32px;
    height: 32px;
  }
  cursor: pointer;
  position: relative;
  $borderWidth: 2px;

  &.large {
    width: 72px;
    height: 72px;
  }

  &.med {
    width: 56px;
    height: 56px;
  }

  .item-container {
    width: 100%;
    height: 100%;
    border-radius: calc($borderWidth * 1.5);
    pointer-events: none;
    transition: all 50ms linear;

    &::after {
      pointer-events: none;
      z-index: 1;
      position: absolute;
      content: "";
      top: calc(-1 * $borderWidth);
      left: calc(-1 * $borderWidth);
      width: calc(100% + $borderWidth * 2);
      height: calc(100% + $borderWidth * 2);
      background-size: 300% 300%;
      animation: moveGradient 3.5s alternate-reverse infinite;
      border-radius: calc($borderWidth * 1.5);
    }
  }

  .quantity {
    position: absolute;
    top: 0;
    right: 2px;
    width: 100%;
    text-align: right;
    height: 12px;
    color: $text-highlight;
    z-index: 100;
    font-size: 80%;
    font-weight: bold;
    text-shadow: 0 0 2px #000, 0 0 2px #000, 0 1px 3px #000;
  }

  &.drag-over {
    background: rgba(194, 153, 123, 0.5) !important;

    img {
      background: rgba(194, 153, 123, 0.8) !important;
    }

    &.invalid {
      background: rgba(200, 0, 0, 0.5) !important;

      img {
        background: rgba(200, 0, 0, 0.8) !important;
      }
    }
  }

  &.dragging {
    cursor: pointer;

    div {
      display: none;
    }
  }

  &.highlighted {
    box-shadow: 0 0 6px $legendary, 0 0 6px $legendary;
    transition: box-shadow 50ms linear;
  }

  &.background {
    background: rgba(0, 0, 0, 0.8);
  }

  .shoddy {
    border: 1px solid $shoddy;
  }

  .epic {
    &::after {
      background-image: linear-gradient(
        60deg,
        lighten($epic, 50%),
        $epic,
        darken($epic, 20%),
        $epic
      );
    }
  }

  .legendary {
    &::after {
      background-image: linear-gradient(
        60deg,
        lighten($legendary, 20%),
        $legendary,
        darken($legendary, 20%),
        $legendary
      );
    }
  }

  .mythical {
    &::after {
      background-image: linear-gradient(
        60deg,
        lighten($mythical, 20%),
        $mythical,
        darken($mythical, 20%),
        $mythical
      );
    }
  }

  .fabled {
    &::after {
      background-image: linear-gradient(
        60deg,
        lighten($fabled, 20%),
        $fabled,
        darken($fabled, 20%),
        $fabled
      );
    }
  }

  .unique {
    &::after {
      background-image: linear-gradient(
        60deg,
        lighten($unique, 20%),
        $unique,
        darken($unique, 40%),
        $unique
      );

      animation: moveGradient 1s alternate-reverse infinite;
    }
  }

  .rare {
    &::after {
      background-image: linear-gradient(
        60deg,
        lighten($rare, 30%),
        $rare,
        darken($rare, 30%),
        $rare
      );
    }
  }

  .artefact {
    &::after {
      background-image: linear-gradient(
        60deg,
        lighten($artefact, 50%),
        $artefact,
        darken($artefact, 30%),
        $artefact
      );
    }
  }

  .junk {
    border: 1px solid #000;
  }

  .uncommon {
    border: 1px solid $uncommon;
  }

  img {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 2;
    background: rgba(0, 0, 0, 0.8);
    pointer-events: none;
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 100%;
  }
}
</style>
