<template>
  <svg class="svg" :style="`width: calc(${energy}% + 9px)`">
    <defs>
      <filter id="glow" x="-100%" y="-100%" width="300%" height="300%">
        <feDropShadow id="feDropShadow" dx="0" dy="0" stdDeviation="3" />
      </filter>
    </defs>
    <path id="path" style="filter: url(#glow)" :d="pathD" />
  </svg>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  name: "EnergyBeam",
  props: {
    energy: {
      type: Number,
    },
  },
  setup() {
    const pathD = ref("");
    let opts = {
      lineWidth: 4,
      numberOfPoints: 20,
      amplitude: 15,
      spacing: 10,
      margin: 10,
      fixedWidth: 300,
    };

    onMounted(() => {
      const animate = () => {
        let points = [];
        for (let i = opts.numberOfPoints; i--; ) points.push(i);

        let width =
          opts.fixedWidth ||
          (opts.numberOfPoints - 1) * opts.spacing + opts.margin * 2;
        let height = opts.amplitude + opts.margin * 2;

        let coords = points.map((n) => {
          let first = n == 0;
          let last = n == opts.numberOfPoints - 1;
          let x =
            ((width - opts.margin * 2) / (opts.numberOfPoints - 1)) * n +
            opts.margin;
          let y =
            first || last
              ? height / 2
              : (height - opts.amplitude) / 2 + Math.random() * opts.amplitude;

          return { x, y };
        });

        pathD.value =
          "M" + coords.map((coord) => coord.x + "," + coord.y).join(" L");

        requestAnimationFrame(animate);
      };

      requestAnimationFrame(animate);
    });

    return {
      pathD,
    };
  },
};
</script>

<style scoped lang="scss">
.svg {
  align-self: center;
  height: 100px;
  top: -12px;
  position: absolute;
  z-index: 1;
  left: -7px;
  opacity: 0.7;
}

#path {
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

#glow #feDropShadow {
  flood-color: #d9b7ff;
}
</style>
