<template>
  <div class="chat-container discord" :class="toggle ? 'active' : ''">
    <button @click.prevent="toggleChat" class="button-small close-btn">
      X
    </button>
    <template v-if="!showDiscordChat">
      <div class="chat-box">
        <div class="message-area">
          <Message
            v-for="{
              id,
              imageURL,
              nftid,
              text,
              address,
              createdAt,
            } in messages"
            :key="id"
            :messageId="id"
            :nftid="nftid"
            :image="imageURL"
            :sender="address"
            :createdAt="createdAt"
            :admin="isAdmin"
            @delete-message="handleDeleteMessage"
          >
            {{ text }}
          </Message>
        </div>
        <div ref="bottom" />
      </div>
      <div class="bottom send-message">
        <form @submit.prevent="handleSendMessage">
          <input
            :disabled="disableForm"
            v-model="message"
            type="text"
            placeholder="Message"
            required
            ref="chatInputBox"
          />
          <button :disabled="disableForm" type="submit">Send</button>
        </form>
      </div>
    </template>
    <template v-else>
      <iframe
        ref="widgetBot"
        src="https://e.widgetbot.io/channels/887555671963340862/1140330630136213545"
        id="#discord-widget"
      ></iframe>
    </template>
  </div>
  <button @click="toggleChat" id="chat-button-guide" class="chat-button">
    <span
      :class="
        alreadyReadMessages ? 'hide' : 'unread' // if chat is visible or no messages are unread
      "
      >{{ alreadyReadMessages ? "no" : "yes" }}</span
    >
  </button>
</template>

<script>
import { onUnmounted, ref, watch, onMounted, computed } from "vue";
import Message from "./Message.vue";
import Filter from "bad-words";
import { useStore } from "vuex";
import Constants from "../../consts/constants";
import { io } from "socket.io-client";
import { useMixpanel } from "../../composables/mixpanel";

export default {
  name: "ChatBox",
  components: { Message },
  setup() {
    const store = useStore();
    const filter = new Filter();
    const messages = ref([]);
    const message = ref("");
    const bottom = ref(null);
    const disableForm = ref(false);
    const toggle = ref(false);
    const character = ref(null);
    const account = ref("");
    const isAdmin = ref(false);
    const socket = io(Constants.apiUrl, { transports: ["websocket"] });
    const chatInputBox = ref(null);
    const { trackEvent } = useMixpanel();
    const widgetBot = ref(null);
    const showDiscordChat = ref(true);

    onUnmounted(() => {
      socket.removeAllListeners("new-message");
      socket.disconnect();
    });

    onMounted(async () => {
      if (!showDiscordChat.value) {
        // Set the character to the current character from store
        getCharacter();
        account.value = store.state.account;

        /**
         * Pooling server to catch any new message event being generated
         * Refetch messages if a new message is generated
         */
        socket.on("new-message", () => {
          getMessagesViaServer();
        });

        socket.io.on("error", () => {
          console.log(
            "Unable to connect to chat server. Please try again or let the team know"
          );
        });
        if (account.value) {
          checkIfAdmin();
        }
        await getMessagesViaServer();

        if (messages.value.length > 0) {
          // Calling initial load action to check to verify if messages are read
          store.dispatch(
            "chat/loadInitialReadStatus",
            messages.value[messages.value.length - 1].id
          );
        }
      }
    });

    const alreadyReadMessages = computed(() => {
      return store.state.chat.readMessages;
    });

    // Watching change in character to ensure the current character data is being used.
    store.watch(
      (state) => state.characters[state.currentCharacter],
      (value) => {
        character.value = value;
      }
    );

    // Watching change in account to ensure the current account data is being used.
    store.watch(
      (state) => state.account,
      (value) => {
        account.value = value;
      }
    );

    /**
     * Watching messages.
     * After every change in message, scrolling to the bottom of the message area.
     * Also assigning read message to the latest message count if chat open
     */
    watch(
      messages,
      // (messages, prevMessages) => {
      () => {
        if (toggle.value) {
          delayedScrollToBottom(200);
        }
      },
      { deep: true }
    );
    const getCharacter = () => {
      character.value = store.state.characters[store.state.currentCharacter];
    };

    /**
     * Helper Methods
     */
    const loginDiscord = () => {
      var newWindow = window.open(
        "https://discord.com/oauth2/authorize?client_id=543225764036870167&redirect_uri=https://stonks.widgetbot.io/api/auth/discord/cb&response_type=code&prompt=none&scope=identify",
        "popupWindow",
        "width=600,height=400"
      );
      newWindow.focus();
    };
    const toggleChat = () => {
      toggle.value = !toggle.value;
      store.commit("chat/setChatOpenState", toggle.value);

      // Focusing on chat input box when chat opens
      if (toggle.value) {
        chatInputBox.value.focus();
        delayedScrollToBottom(500);

        store.commit(
          "chat/setLastReadMessageId",
          messages.value[messages.value.length - 1].id
        );
        store.commit("chat/setReadMessages", true);

        // Mixpanel tracking chat open
        trackEvent("Chat Opened", {
          state: store.state.gameState,
        });
      }
    };

    const cleanText = () => {
      try {
        let text = message.value.trim();
        // Checking if string contains any alphanumeric characters. Otherwise the bad words filter breaks lol
        if (/[a-zA-Z0-9]/g.test(text)) {
          text = filter.clean(text);
          text = text.replace(/(?:https?|ftp):\/\/[\n\S]+/g, "");
        }
        return text;
      } catch (error) {
        console.log(error);
        disableForm.value = false;
      }
    };

    const delayedScrollToBottom = (delay) => {
      setTimeout(() => {
        bottom.value.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }, delay);
    };

    //TODO: shift to game server for extra security
    const checkIfAdmin = async () => {
      // const accounts = await window.ethereum.request({
      //   method: "eth_requestAccounts",
      // });

      // const account = accounts.length > 0 ? accounts[0] : "";
      const sessionId = localStorage.getItem("sessionId");

      const response = await fetch(Constants.apiUrl + "is-admin", {
        method: "POST",
        body: JSON.stringify({
          sessionId,
          account: account.value,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const status = await response.json();

      isAdmin.value = status.adminStatus;
    };

    const handleSendMessage = async () => {
      disableForm.value = true;

      const text = cleanText(message.value);

      if (text !== "") {
        sendMessageViaServer(
          localStorage.getItem("sessionId"),
          character.value.number,
          character.value.image,
          text
        );

        // Mixpanel tracking chat sent
        trackEvent("Sent A Message");

        message.value = "slow mode 5 seconds";
        setTimeout(() => {
          message.value = "";
          disableForm.value = false;
        }, 5000);
      }
      disableForm.value = false;
      message.value = "";
    };

    const handleDeleteMessage = async (value) => {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const account = accounts.length > 0 ? accounts[0] : "";

      const sessionId = localStorage.getItem("sessionId");
      if (isAdmin.value)
        await fetch(Constants.apiUrl + "delete-message", {
          method: "POST",
          body: JSON.stringify({
            sessionId,
            value,
            account,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
    };

    const getMessagesViaServer = async () => {
      const response = await fetch(Constants.apiUrl + "get-messages");
      const data = await response.json();
      messages.value = data.messages.reverse();

      const prevReadMessageId = store.state.chat.lastReadMessageId;

      /**
       * Checking if the last message is from the same sender or not. If it isn't, set read status to false
       * otherwise check whether the message id is same or not. If it is, set read status to true.
       *
       * The first check is done cause when the user sends a message, we are predicting success and pushing the message in the frontend.
       * Once the server updates the latest message, the last message id is updated. If we don't check for the address, then it will be
       * taken as a new message even though the sender only sent the message.
       */
      if (messages.value.length > 0) {
        if (
          account.value !== messages.value[messages.value.length - 1].address
        ) {
          store.commit("chat/setReadMessages", false);
        } else if (
          prevReadMessageId === messages.value[messages.value.length - 1].id
        ) {
          store.commit("chat/setReadMessages", true);
        }
      }
    };

    const sendMessageViaServer = async (sessionId, nftid, imageURL, text) => {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const account = accounts.length > 0 ? accounts[0] : "";

      /**
       * Mocking UI as the updated message is from the current client. So instantly
       * updating it in the messages window and scrolling to the bottom.
       * Update local state assuming the message will persist to remove lag
       */
      messages.value.push({
        sessionId,
        nftid,
        imageURL,
        text,
        address: account,
        createdAt: { _seconds: Date.now() / 1000 },
      });
      delayedScrollToBottom(500);

      const response = await fetch(Constants.apiUrl + "send-message", {
        method: "POST",
        body: JSON.stringify({
          account,
          sessionId,
          nftid,
          imageURL,
          text,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    };

    return {
      messages,
      handleSendMessage,
      bottom,
      message,
      disableForm,
      toggle,
      alreadyReadMessages,
      toggleChat,
      handleDeleteMessage,
      isAdmin,
      chatInputBox,
      loginDiscord,
      widgetBot,
      showDiscordChat,
    };
  },
};
</script>

<style lang="scss" scoped>
.chat-button {
  display: block;
  width: 44px;
  height: 44px;
  position: fixed;
  bottom: 55px;
  left: 20px;
  z-index: 8;
  @media only screen and (max-width: 576px) {
    z-index: 4;
  }
  background: url("https://cdn.dragoncrypto.io/uiassets/chat.png") no-repeat;
  box-shadow: none;
  background-size: cover;
  border: none;
  opacity: 1;
  &:hover {
    transform: scale(1.2);
    transition: transform 0.2s ease-in-out;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
  }
  span {
    background: #cf0303;
    width: 10px;
    height: 10px;
    display: grid;
    align-items: center;
    color: #fff;
    font-weight: bold;
    border-radius: 100%;
    font-size: 0.7rem;
    position: absolute;
    top: 0;
    right: 5px;
    text-indent: -9999px;
    &.hide {
      display: none;
    }
  }
}
.chat-container {
  width: 500px;
  position: fixed;
  z-index: 10;
  background: #000000d6;
  backdrop-filter: blur(2px);
  bottom: 50%;
  left: 0;
  height: 80%;
  display: grid;
  grid-template-rows: 1fr 40px;
  transform: translateY(50%);
  padding: 10px 0 0;
  left: -500px;

  transition: left 100ms linear;
  &:before {
    content: "";
    display: block;
    width: 65px;
    height: 46px;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background: #303339;
  }
  @media only screen and (max-width: 576px) {
    width: 95%;
    left: -95%;
  }
  &.discord {
    grid-template-rows: 1fr;
  }
  iframe {
    width: 100%;
    height: calc(100% + 10px);
    border: none;
    margin-top: -10px;
  }
  &.active {
    left: 0;
  }
  .close-btn {
    border: none;
    position: absolute;
    top: -15px;
    right: -15px;
    z-index: 100;
  }
  .chat-box {
    padding: 0 10px;
    /* overflow-y: scroll; */
  }
  .send-message form {
    display: grid;
    grid-template-columns: 1fr 85px;
    input[type="text"] {
      height: 35px;
      display: block;
      width: 98%;
      border: 1px solid #c2bfbf;
      background: #fff;
      color: #000;
      &:disabled {
        opacity: 0.6;
      }
      &:focus {
        outline: none;
      }
    }
    button {
      background: rgb(27, 69, 102);
      background: linear-gradient(
        3deg,
        rgba(27, 69, 102, 1) 0%,
        rgba(16, 28, 40, 1) 100%
      );
      color: #fff;
      border: 1px solid #3a668a;
      &:disabled {
        opacity: 0.6;
      }
    }
  }
}
</style>
